/**
 * Temporary workaround to reproduce native features
 */

import color from "color";

// import { Platform, Dimensions, PixelRatio, StatusBar } from "react-native";
// import Roboto from "../Fonts/Roboto.ttf";
// import Ionicons from "../Fonts/Ionicons.ttf";

// const deviceHeight = Dimensions.get("window").height;
// const deviceWidth = Dimensions.get("window").width;
// const platform = Platform.OS;
const platformStyle = "material";
// const isPad = platform.isPad;
// const isIphoneX =
//   platform === "ios" &&
//   (deviceHeight === 812 ||
//     deviceWidth === 812 ||
//     deviceHeight === 896 ||
//     deviceWidth === 896);

export default {
  platformStyle,
  // platform,

  // Android
  androidRipple: true,
  androidRippleColor: "rgba(256, 256, 256, 0.3)",
  androidRippleColorDark: "rgba(0, 0, 0, 0.15)",
  btnUppercaseAndroidText: true,

  // Badge
  badgeBg: "#ED1727",
  badgeColor: "#fff",
  badgePadding: 0,

  // Button
  //btnFontFamily: "Roboto",
  btnFontFamily: "System",
  btnDisabledBg: "#b5b5b5",
  buttonPadding: 6,
  get btnPrimaryBg(): string {
    return this.brandPrimary;
  },
  get btnPrimaryColor(): string {
    return this.inverseTextColor;
  },
  get btnInfoBg(): string {
    return this.brandInfo;
  },
  get btnInfoColor(): string {
    return this.inverseTextColor;
  },
  get btnSuccessBg(): string {
    return this.brandSuccess;
  },
  get btnSuccessColor(): string {
    return this.inverseTextColor;
  },
  get btnDangerBg(): string {
    return this.brandDanger;
  },
  get btnDangerColor(): string {
    return this.inverseTextColor;
  },
  get btnWarningBg(): string {
    return this.brandWarning;
  },
  get btnWarningColor(): string {
    return this.inverseTextColor;
  },
  get btnTextSize(): number {
    return this.fontSizeBase - 1;
  },
  get btnTextSizeLarge(): number {
    return this.fontSizeBase * 1.5;
  },
  get btnTextSizeSmall(): number {
    return this.fontSizeBase * 0.8;
  },
  get borderRadiusLarge(): number {
    return this.fontSizeBase * 3.8;
  },
  get iconSizeLarge(): number {
    return this.iconFontSize * 1.5;
  },
  get iconSizeSmall(): number {
    return this.iconFontSize * 0.6;
  },

  // Card
  cardDefaultBg: "#fff",
  cardBorderColor: "#ccc",

  // CheckBox
  CheckboxRadius: 0,
  CheckboxBorderWidth: 2,
  CheckboxPaddingLeft: 2,
  CheckboxPaddingBottom: 5,
  CheckboxIconSize: 16,
  CheckboxIconMarginTop: 1,
  CheckboxFontSize: 17,
  DefaultFontSize: 17,
  checkboxBgColor: "#039BE5",
  checkboxSize: 20,
  checkboxTickColor: "#fff",

  // Color
  brandPrimary: "#007aff",
  brandInfo: "#62B1F6",
  brandSuccess: "#5cb85c",
  brandDanger: "#d9534f",
  brandWarning: "#f0ad4e",
  brandDark: "#000",
  brandLight: "#f4f4f4",

  // Font
  //fontFamily: platform === "ios" ? "System" : "Roboto",
  fontFamily: "System",
  fontSizeBase: 15,
  get fontSizeH1(): string {
    return `${this.fontSizeBase * 1.8}px`;
  },
  get fontSizeH2(): string {
    return `${this.fontSizeBase * 1.6}px`;
  },
  get fontSizeH3(): string {
    return `${this.fontSizeBase * 1.4}px`;
  },
  get fontSizeH4(): string {
    return `${this.fontSizeBase * 1.2}px`;
  },
  get fontSizeH5(): string {
    return `${this.fontSizeBase * 1.1}px`;
  },

  // Footer
  // footerHeight: isIphoneX ? 89 : 55,
  footerDefaultBg: "#007aff",
  // footerPaddingBottom: isIphoneX ? 34 : 0,

  // FooterTab
  tabBarTextColor: "#bfc6ea",
  tabBarTextSize: 11,
  activeTab: "#fff",
  sTabBarActiveTextColor: "#007aff",
  tabBarActiveTextColor: "#fff",
  tabActiveBgColor: "#007aff",

  // Header
  navBarHeight: 48,
  toolbarBtnColor: "#fff",
  toolbarDefaultBg: "#fff",
  // toolbarHeight: platform === "ios" ? 48 : 56,
  toolbarSearchIconSize: 23,
  toolbarInputColor: "#fff",
  // searchBarHeight: platform === "ios" ? 30 : 40,
  // searchBarInputHeight: platform === "ios" ? 40 : 50,
  toolbarBtnTextColor: "#fff",
  toolbarDefaultBorder: "#007aff",
  iosStatusbar: "light-content",
  // statusBarHeight:
  //   platform === "ios" ? (isIphoneX ? 45 : 20) : StatusBar.currentHeight,
  get statusBarColor(): string {
    return color(this.toolbarDefaultBg)
      .darken(0.2)
      .hex();
  },
  get darkenHeader(): string {
    return color(this.tabBgColor)
      .darken(0.03)
      .hex();
  },

  // Icon
  iconFamily: "Ionicons",
  iconFontSize: 28,
  iconHeaderSize: 24,

  // InputGroup
  inputFontSize: 17,
  inputBorderColor: "#D9D5DC",
  inputSuccessBorderColor: "#2b8339",
  inputErrorBorderColor: "#ed2f2f",
  inputHeightBase: 50,
  get inputColor(): string {
    return this.textColor;
  },
  get inputColorPlaceholder(): string {
    return "#575757";
  },

  // Line Height
  btnLineHeight: 19,
  lineHeightH1: 32,
  lineHeightH2: 27,
  lineHeightH3: 22,
  lineHeight: 24,

  // List
  listBg: "transparent",
  listBorderColor: "#c9c9c9",
  listDividerBg: "#f4f4f4",
  listBtnUnderlayColor: "#DDD",
  listItemPadding: 12,
  listNoteColor: "#808080",
  listNoteSize: 13,

  // Progress Bar
  defaultProgressColor: "#E4202D",
  inverseProgressColor: "#1A191B",

  // Radio Button
  radioBtnSize: 40,
  radioSelectedColorAndroid: "#007aff",
  radioBtnLineHeight: 40,
  get radioColor(): string {
    return this.brandPrimary;
  },

  // Segment
  segmentBackgroundColor: "#007aff",
  segmentActiveBackgroundColor: "#fff",
  segmentTextColor: "#fff",
  segmentActiveTextColor: "#007aff",
  segmentBorderColor: "#fff",
  segmentBorderColorMain: "#007aff",

  // Spinner
  defaultSpinnerColor: "#45D56E",
  inverseSpinnerColor: "#1A191B",

  // Tab
  tabDefaultBg: "#007aff",
  topTabBarTextColor: "#b3c7f9",
  topTabBarActiveTextColor: "#fff",
  topTabBarBorderColor: "#fff",
  topTabBarActiveBorderColor: "#fff",

  // Tabs
  tabBgColor: "#F8F8F8",
  tabFontSize: 15,

  // Text
  textColor: "#000",
  inverseTextColor: "#fff",
  noteFontSize: 14,
  get defaultTextColor(): string {
    return this.textColor;
  },

  // Title
  //titleFontfamily: "Roboto",
  titleFontfamily: "System",
  titleFontSize: 19,
  subTitleFontSize: 14,
  subtitleColor: "#FFF",
  titleFontColor: "#FFF",

  // Other
  borderRadiusBase: 2,
  // borderWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  contentPadding: 10,
  dropdownLinkColor: "#414142",
  inputLineHeight: 24,
  // deviceWidth,
  // deviceHeight,
  // isIphoneX,
  // isPad,
  inputGroupRoundedBorderRadius: 30,
};
