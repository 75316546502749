import { MentionUser, MentionLink } from "../../../types";

export function parseMentions<T>(
  convertedObject: Omit<T, "mentions" | "linksMentions"> & {
    mentions?: string[];
    linksMentions?: string[];
  }
): T {
  const mentionsString = convertedObject && convertedObject["mentions"];
  const mentions =
    mentionsString &&
    mentionsString.map(
      (mentionString: string): MentionUser => JSON.parse(mentionString)
    );

  const linkMentionsString =
    convertedObject && convertedObject["linksMentions"];
  const linkMentions =
    linkMentionsString &&
    linkMentionsString.map(
      (linkMention: string): MentionLink => JSON.parse(linkMention)
    );

  const convertedObjectWithMentions = ({
    ...convertedObject,
    mentions: mentions,
    linksMentions: linkMentions,
  } as unknown) as T;

  return convertedObjectWithMentions;
}
