import React from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { InvoicesLayoutProps as PaymentInvoiceListProps } from "../../../../containers/Payment/Invoices";

import { InfiniteScroll } from "../..";

import { PaymentInvoiceCard, PaymentInvoiceCardPlaceholder } from ".";

type Props = PaymentInvoiceListProps;

export const PaymentInvoiceList: React.FC<Props> = ({
  data,
  error,
  hasMore,
  refreshing: isRefreshing,
  loading,
  reFetch,
}) => {
  const { t } = useTranslation("Payment");

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const content =
    data?.map((item, index) => (
      <PaymentInvoiceCard
        key={item.id}
        item={item}
        id={`invoiceCard${index}`}
      />
    )) || null;

  return (
    <>
      {errorElement}
      <InfiniteScroll
        emptyText={t("Invoice.List.Not.Found")}
        hasMore={hasMore}
        onLoadMore={handleLoadMore}
        isLoading={!!loading}
        isRefreshing={isRefreshing}
        placeholder={<PaymentInvoiceCardPlaceholder />}>
        {content}
      </InfiniteScroll>
    </>
  );
};
