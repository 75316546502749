import { createSelector } from "reselect";
import { RootState, DictStrict, PostItem, SettingState } from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";
import { Image } from "../../types/Post/Image";

// selector

const getPostsSetting = (state: RootState): SettingState => state.setting;

const getPosts = (state: RootState): DictStrict<PostItem> | null =>
  state.post.mixedList.items;

const getPostsHasMore = (state: RootState): boolean =>
  state.post.mixedList.hasMore;

const getGroupPosts = (
  state: RootState,
  groupId: string
): DictStrict<PostItem> | null => state.post.groupsList[groupId]?.items || null;

const getGroupPostsHasMore = (state: RootState, groupId: string): boolean =>
  state.post.groupsList[groupId]?.hasMore || false;

const getUserPosts = (
  state: RootState,
  userId: string
): DictStrict<PostItem> | null => state.post.usersList[userId]?.items || null;

const getUserPostsHasMore = (state: RootState, userId: string): boolean =>
  state.post.usersList[userId]?.hasMore || false;

const getGroupImages = (
  state: RootState,
  groupId: string
): DictStrict<Image> | null => state.post.imagesList[groupId]?.items || null;

const getGroupImagesHasMore = (state: RootState, groupId: string): boolean =>
  state.post.imagesList[groupId]?.hasMore || false;

const getGroupFiles = (
  state: RootState,
  groupId: string
): DictStrict<Image> | null => state.post.filesList[groupId]?.items || null;

const getGroupFilesHasMore = (state: RootState, groupId: string): boolean =>
  state.post.filesList[groupId]?.hasMore || false;

// reselect function

export const getPostsState = createSelector([getPosts], items =>
  getDictDefinedValues(items)
);

export const getPostsHasMoreState = createSelector(
  [getPostsHasMore],
  hasMore => hasMore
);

export const getGroupPostsState = createSelector([getGroupPosts], items =>
  getDictDefinedValues(items)
);
export const getGroupPostsHasMoreState = createSelector(
  [getGroupPostsHasMore],
  hasMore => hasMore
);

export const getUserPostsState = createSelector([getUserPosts], items =>
  getDictDefinedValues(items)
);
export const getUserPostsHasMoreState = createSelector(
  [getUserPostsHasMore],
  hasMore => hasMore
);

export const getGroupImagesState = createSelector([getGroupImages], items =>
  getDictDefinedValues(items)
);
export const getGroupImagesHasMoreState = createSelector(
  [getGroupImagesHasMore],
  hasMore => hasMore
);

export const getGroupFilesState = createSelector([getGroupFiles], items =>
  getDictDefinedValues(items)
);
export const getGroupFilesHasMoreState = createSelector(
  [getGroupFilesHasMore],
  hasMore => hasMore
);

export const getPostsSettingState = createSelector(
  [getPostsSetting],
  setting => ({
    announcementLabel: setting?.config?.announcementLabel || "",
    confettiEffectActions: setting?.setting?.confetti_effect_actions || [],
    ClientHostName: setting?.localConfig?.client_url || "",
  })
);
