import React, { useState } from "react";

import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import { EULAModalLayoutProps } from "../../../containers/EULA";
import MediaContainer from "../../../containers/Media";

import { useTheme } from "../../hooks/useTheme";

import { Media } from "../Media";
import { Heading } from "../UI/Heading";
import { HTML } from "..";

export const ModalEula: React.FC<EULAModalLayoutProps> = ({
  data,
  video,

  agree,
  reject,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const modalEulaStyles = {
    button: {
      backgroundColor: buttonBackgroundColor,
      color: buttonColor,
      minWidth: 120,
    },
    buttonsContainer: {
      marginTop: 20,
    },
    link: {
      justifyContent: "center",
    },
  };
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { body, buttons, link, link_title = "", title } = data || {};

  const onAgree = (): void => {
    agree();
    setIsOpen(false);
  };

  const onReject = (): void => {
    reject();
    setIsOpen(false);
  };

  const videoElement = isOpen && video && (
    <MediaContainer video={video} Layout={Media} />
  );

  const linkElement = !!link && (
    <ModalHeader style={modalEulaStyles.link}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Button color="danger">
          {/* eslint-disable-next-line @typescript-eslint/camelcase  */}
          {link_title}
        </Button>
      </a>
    </ModalHeader>
  );

  return (
    <Modal isOpen={isOpen} centered={true} scrollable={true}>
      <ModalHeader>
        <Heading large bold text={title} />
      </ModalHeader>
      <ModalBody>
        <HTML body={body} className="" />
        {videoElement}
        {linkElement}
      </ModalBody>
      <ModalFooter className="border-top">
        <Button onClick={onAgree} style={modalEulaStyles.button}>
          {buttons.agree}
        </Button>
        {!!buttons.reject && (
          <Button onClick={onReject} style={modalEulaStyles.button}>
            {buttons.reject}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
