import _ from "underscore";
import { VideoUpload } from "../../../types/VideoLibrary/VideoUpload";

const convertToImageUrl = (url: string): string => {
  if (_.isEmpty(url)) {
    return "";
  }
  const parts = url.split(".");
  if (parts.length > 0) {
    const extension = parts[parts.length - 1];
    return url.replace("." + extension, ".png");
  }
  return "";
};

export const prepareVideo = (
  video: VideoUpload,
  saveToAdminServer: boolean
): VideoUpload | void => {
  console.debug("[Service] prepareVideo", video);

  if (_.isEmpty(video)) {
    return;
  }
  if ((video.fromLibrary && video.id) || video.uploaded) {
    return video;
  }

  return {
    serviceName: "Cloudinary",
    serviceId: video.public_id,
    size:
      video.bytes && (saveToAdminServer ? video.bytes.toString() : video.bytes),
    width:
      video.width && (saveToAdminServer ? video.width.toString() : video.width),
    height:
      video.height &&
      (saveToAdminServer ? video.height.toString() : video.height),
    duration:
      video.duration &&
      (saveToAdminServer ? video.duration.toString() : video.duration),
    format: video.format,
    formats: ["dash", "hls", video.format as string],
    title: video.original_filename,
    url: video.secure_url,
    thumbUrl: convertToImageUrl(video.secure_url || ""),
  };
};
