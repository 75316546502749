import { AppItem, ReactionType } from "../../../types";

export const getNextItemLikeCount = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  item,
  newReaction,
  dataKeyName,
}: {
  item: ItemType;
  newReaction: ReactionType;
  dataKeyName: DataKey;
}): number =>
  (item[dataKeyName].likeCount || 0) +
  (newReaction === "unlike" ? -1 : item.isLiked ? 0 : 1);
