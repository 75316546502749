import React from "react";

import { useAnnouncementAnchorProps } from "../../../hooks";

import { AnnouncementCard, AnnouncementCardProps } from ".";

export type AnnouncementCardAnchorProps = AnnouncementCardProps;

/**
 * @TODO
 * Consider to create HOC to pass announcementAnchorProps
 * to AnnouncementCard
 */
export const AnnouncementCardAnchor: React.FC<AnnouncementCardAnchorProps> = props => {
  const announcementAnchorProps = useAnnouncementAnchorProps(props.item);

  return (
    <AnnouncementCard
      {...props}
      showReadMoreButton={true}
      showSubTitle={true}
      {...announcementAnchorProps}
    />
  );
};
