import { AppItem, ReactionType } from "../../../types";
import { getNextItemReactionCount, getNextItemLikeCount } from ".";

export type GetNextReactedItem = {
  <DataKey extends string, ItemType extends AppItem<DataKey>>({
    item,
    newReaction,
    dataKeyName,
  }: {
    item: ItemType;
    newReaction: ReactionType;
    dataKeyName: DataKey;
  }): ItemType;
  <DataKey extends string, ItemType extends AppItem<DataKey>>({
    item,
    newReaction,
    dataKeyName,
  }: {
    item: undefined;
    newReaction: ReactionType;
    dataKeyName: DataKey;
  }): undefined;
  <DataKey extends string, ItemType extends AppItem<DataKey>>({
    item,
    newReaction,
    dataKeyName,
  }: {
    item: ItemType | undefined;
    newReaction: ReactionType;
    dataKeyName: DataKey;
  }): ItemType | undefined;
};

export const getNextReactedItem: GetNextReactedItem = <DataKey extends string>({
  item,
  newReaction,
  dataKeyName,
}: {
  /** Item to update */
  item: any;
  /** Currently set ReactionType */
  newReaction: ReactionType;
  /**
   * Name of property storing nested entity data (and optional
   * reactionCount property)
   *
   * @FIXME
   * We have to specify this key name because of inconsistency in
   * all available Items shapes (nested data is stored under entity
   * name property). Unifing that property name and setting it
   * for example to "data" would simplify app data structures.
   */
  dataKeyName: DataKey;
}) =>
  item
    ? {
        ...item,
        reaction: newReaction,
        isLiked: newReaction !== "unlike",
        [dataKeyName]: {
          ...item[dataKeyName],
          reactionCount: getNextItemReactionCount({
            item: item,
            newReaction: newReaction,
            dataKeyName: dataKeyName,
          }),
          likeCount: getNextItemLikeCount({
            item: item,
            newReaction: newReaction,
            dataKeyName: dataKeyName,
          }),
        },
      }
    : undefined;
