import { Group } from "../../types/Group";
import {
  GroupByStatusData,
  GroupByTypeData,
  GroupDataBySections,
} from "./types";

export function isDictionary(
  groupData: Group[] | GroupDataBySections
): groupData is GroupDataBySections {
  return (groupData as GroupDataBySections)[0]?.data !== undefined;
}

export function isGroupDataByStatus(
  groupData: GroupByStatusData | GroupByTypeData
): groupData is GroupByStatusData {
  return (groupData as GroupByStatusData)?.data !== undefined;
}

export const filterGroupNameByQuery = (query: string) => (
  groupData: Group
): boolean => groupData.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
