import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { Dict, Member, MemberGroupMember } from "../../../types";

import { runQuery } from "../../http";

import { customProfile, findAll } from "..";

export const getGroupMembers = async (
  groupId: string
): Promise<Dict<Member>> => {
  console.debug("[Service] getGroupMembers", groupId);

  const resp: Parse.Object<MemberGroupMember>[] = await runQuery(
    "GroupMember",
    (query: Parse.Query) => {
      query.equalTo(
        "group",
        new (Parse.Object.extend("Group"))({ id: groupId })
      );
      query.equalTo("status", "active");
      query.include(["user", "user.profile"]);

      return findAll(query);
    },
    { requireUser: true }
  );

  return _.object(
    _.map(resp, value => {
      const user = customProfile(convertObject(value.get("user")));
      return [user.id, user];
    })
  );
};
