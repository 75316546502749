import React, { Component } from "react";
import { connect } from "react-redux";

import { getGroupMemberSetting, saveGroupMemberSetting } from "./action";
import { isEmpty, isUndefined } from "underscore";
import {
  EmptyObject,
  MatchProps,
  RootState,
  GroupMember,
  Callback,
} from "../../types";
import { UserDataState } from "../../types/User/UserDataState";
import { withTranslation, WithTranslation } from "react-i18next";

export type GroupSettingLayoutProps = {
  error: Error | null;
  refreshing: boolean;
  sending?: boolean;
  user: UserDataState | EmptyObject;
  notificationSettings:
    | Partial<
        GroupMember & {
          mobileNotification: "all" | "mention" | "nothing";
          desktopNotification: "all" | "mention" | "nothing";
          mute: boolean;
        }
      >
    | EmptyObject;
  notificationSettingsDefault: {
    mobileNotification: "all" | "mention" | "nothing";
    desktopNotification: "all" | "mention" | "nothing";
    mute: boolean;
  };
  notificationSettingsOptions: {
    value: "all" | "mention" | "nothing";
    key: string;
  }[];
  onChangeNotificationSettings: <
    K extends keyof GroupSettingState["notificationSettings"],
    V extends GroupSettingState["notificationSettings"][K]
  >(
    name: K,
    value: V
  ) => void;
};

export type GroupSettingOwnProps = {
  Layout: React.ComponentType<GroupSettingLayoutProps>;
} & MatchProps<"id">;

export type GroupSettingStateProps = {
  notificationSettings: GroupMember | EmptyObject;
  user: UserDataState | EmptyObject;
};

export type GroupSettingDispatchProps = {
  getNotificationSettings: (
    groupId: string,
    refresh?: boolean
  ) => Promise<unknown>;
  saveNotificationSettings: (
    groupId: string,
    data: {}
  ) => Promise<{
    data: { id: string; data: {} };
  }>;
};

export type GroupSettingProps = GroupSettingOwnProps &
  GroupSettingStateProps &
  GroupSettingDispatchProps;

export type GroupSettingState = {
  error: Error | null;
  notificationSettings:
    | Partial<
        GroupMember & {
          mobileNotification: "all" | "mention" | "nothing";
          desktopNotification: "all" | "mention" | "nothing";
          mute: boolean;
        }
      >
    | EmptyObject;
  notificationSettingsDefault: {
    mobileNotification: "all" | "mention" | "nothing";
    desktopNotification: "all" | "mention" | "nothing";
    mute: boolean;
  };
  notificationSettingsOptions: {
    value: "all" | "mention" | "nothing";
    key: string;
  }[];
  refreshing: boolean;
  sending?: boolean;
};

class GroupSetting extends Component<
  GroupSettingProps & WithTranslation,
  GroupSettingState
> {
  state: GroupSettingState = {
    error: null,
    refreshing: false,
    notificationSettings: {},
    notificationSettingsDefault: {
      mobileNotification: "all",
      desktopNotification: "all",
      mute: false,
    },
    notificationSettingsOptions: [
      { value: "all", key: this.props.t("Container.Setting.Option.All") },
      { value: "mention", key: this.props.t("Container.Setting.Option.Just") },
      {
        value: "nothing",
        key: this.props.t("Container.Setting.Option.Nothing"),
      },
    ],
  };

  static getDerivedStateFromProps(
    nextProps: GroupSettingProps,
    prevState: GroupSettingState
  ): {
    notificationSettings: GroupMember | EmptyObject;
  } | null {
    if (
      nextProps.notificationSettings &&
      isEmpty(prevState.notificationSettings)
    ) {
      return {
        notificationSettings: nextProps.notificationSettings,
      };
    }

    return null;
  }

  componentDidMount = (): void => {
    this.getNotificationSettings();
  };

  onChangeNotificationSettings = <
    K extends keyof GroupSettingState["notificationSettings"],
    V extends GroupSettingState["notificationSettings"][K]
  >(
    name: K,
    value: V
  ): void => {
    const { notificationSettings } = this.state;

    /**
     * Didn't it mutate container state?
     */
    // notificationSettings[name] = value;
    // this.setState({ notificationSettings });

    this.setState(
      {
        notificationSettings: {
          ...notificationSettings,
          [name]: value,
        },
      },
      () => {
        this.saveNotificationSettings();
      }
    );
  };

  getNotificationSettings = async (
    refresh?: boolean,
    callback?: Callback
  ): Promise<false | void> => {
    try {
      const { getNotificationSettings, match } = this.props;
      const { refreshing } = this.state;

      if (refreshing) {
        return false;
      }

      this.setState({ refreshing: true });
      const groupId = match.params?.id;

      /**
       * That could be probably undefined
       */
      if (!groupId) {
        throw Error("Missing group ID");
      }

      await getNotificationSettings(groupId, refresh);

      this.setState({ refreshing: false, error: null });

      // callback && callback();
      callback?.();
    } catch (error) {
      this.setState({ refreshing: false, error: error });
    }
  };

  saveNotificationSettings = async (
    refresh?: boolean,
    callback?: Callback
  ): Promise<false | void> => {
    try {
      const { saveNotificationSettings, match } = this.props;
      const {
        sending,
        notificationSettings,
        notificationSettingsDefault,
      } = this.state;

      if (sending) {
        return false;
      }

      this.setState({ sending: true });

      const groupId = match && match.params && match.params.id;

      /**
       * That could be probably undefined
       */
      if (!groupId) {
        throw Error("Missing group ID");
      }

      await saveNotificationSettings(groupId, {
        mobileNotification: (!isEmpty(notificationSettings?.mobileNotification)
          ? notificationSettings
          : notificationSettingsDefault
        ).mobileNotification,

        desktopNotification: (!isEmpty(notificationSettings.desktopNotification)
          ? notificationSettings
          : notificationSettingsDefault
        ).desktopNotification,

        mute: (!isUndefined(notificationSettings.mute)
          ? notificationSettings
          : notificationSettingsDefault
        ).mute,
      });

      this.setState({ sending: false, error: null });

      callback && callback();
    } catch (error) {
      this.setState({ sending: false, error: error });
    }
  };

  render = (): JSX.Element => {
    const { Layout, user } = this.props;
    const {
      refreshing,
      error,
      notificationSettings,
      notificationSettingsOptions,
      sending,
      notificationSettingsDefault,
    } = this.state;

    return (
      <Layout
        error={error}
        refreshing={refreshing}
        sending={sending}
        user={user}
        notificationSettings={notificationSettings}
        notificationSettingsDefault={notificationSettingsDefault}
        notificationSettingsOptions={notificationSettingsOptions}
        onChangeNotificationSettings={this.onChangeNotificationSettings}
      />
    );
  };
}
const mapStateToProps = (
  state: RootState,
  ownProps: GroupSettingOwnProps
): GroupSettingStateProps => {
  const groupId = ownProps.match.params?.id;

  return {
    notificationSettings: (groupId && state.group.setting[groupId]) || {},
    user: state.user.data || {},
  };
};

const mapDispatchToProps = {
  getNotificationSettings: getGroupMemberSetting,
  saveNotificationSettings: saveGroupMemberSetting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Group")(GroupSetting));
