import React from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { ReceiptsLayoutProps as PaymentReceiptListProps } from "../../../../containers/Payment/Receipts";

import { InfiniteScroll } from "../..";

import { PaymentInvoiceCardPlaceholder } from "..";

import { PaymentReceiptCard } from ".";

type Props = PaymentReceiptListProps;

export const PaymentReceiptList: React.FC<Props> = ({
  data,
  error,
  hasMore,
  refreshing: isRefreshing,
  loading,
  reFetch,
}) => {
  const { t } = useTranslation("Payment");

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const content =
    data?.map((item, index) => (
      <PaymentReceiptCard
        key={item.id}
        item={item}
        id={`receiptCard${index}`}
      />
    )) || null;

  return (
    <>
      {errorElement}
      <InfiniteScroll
        emptyText={t("Receipt.List.Not.Found")}
        hasMore={hasMore}
        onLoadMore={handleLoadMore}
        isLoading={!!loading}
        isRefreshing={isRefreshing}
        /** I think placeholders for invoices and receipts are the same */
        placeholder={<PaymentInvoiceCardPlaceholder />}>
        {content}
      </InfiniteScroll>
    </>
  );
};
