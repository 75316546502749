import React, { FC, useState, SyntheticEvent, useEffect } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import { textThemingWorkaround } from "../../../constants";

import MentionInputContainer from "../../../containers/Mention";

import { cleanHTMLTags, fromNow, parseJSONToObject } from "../../../lib/utils";

import {
  CommentResponse,
  CommentRequest,
  MentionUser,
  MentionLink,
} from "../../../types";

import { useTheme, useAnchorProps } from "../../hooks";

import { Avatar, MentionDisplay, MentionInput } from "..";

export type CommentCardProps = {
  className?: string;
  comment: CommentResponse;
  commentPage?: boolean;
  disabled?: boolean;
  setting: {
    ClientHostName?: string;
    confettiEffectActions?: [];
  };
  style: React.CSSProperties;

  openActions?: (comment: CommentResponse) => void;
  deleteComment?: (data: CommentRequest, callback?: () => void) => void;
  updateComment?: (
    data: CommentRequest,
    cancel: boolean,
    callback?: () => void
  ) => void;
};

export const CommentCard: FC<CommentCardProps> = ({
  className,
  comment,
  disabled,
  style,

  deleteComment,
  updateComment,
}) => {
  const { author, createdAt, body, edited } = comment;

  const [commentBody, setCommentBody] = useState(body);

  useEffect(() => {
    if (body !== commentBody) {
      setCommentBody(body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  const mentionsObjectArray = comment.mentions
    ? comment.mentions.map(item => parseJSONToObject<MentionUser>(item))
    : [];
  const linksMentionsObjectArray = comment.linksMentions
    ? comment.linksMentions.map(item => parseJSONToObject<MentionLink>(item))
    : [];

  const [mentions, setMentions] = useState(mentionsObjectArray);

  const [linkMentions, setLinkMentions] = useState(linksMentionsObjectArray);

  const [isCommentInEditMode, setIsCommentInEditMode] = useState(false);

  const { t } = useTranslation("Comment");

  const {
    button: { backgroundColor: buttonBackgroundColor },
    list: listStyle,
    comment: { itemBackgroundColor, commentButton: commentButtonStyles },
    text: { listTitle, subHeader, cardText },
  } = useTheme();

  const toggle = (): void => setIsCommentInEditMode(!isCommentInEditMode);

  const handleChange = (text: string): void => {
    setCommentBody(text);
  };

  const handleClickSave = (e: SyntheticEvent): void => {
    e.preventDefault();

    updateComment?.(
      {
        ...comment,
        linksMentions: linkMentions,
        mentions: mentions,
        text: commentBody,
      },
      false
    );

    toggle();
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (commentBody.trim() !== "" && e.which === 13 && !e.shiftKey) {
      updateComment?.(
        {
          ...comment,
          linksMentions: linkMentions,
          mentions: mentions,
          text: commentBody,
        },
        false
      );

      toggle();
    }
  };

  const handleClickDelete = (e: SyntheticEvent): void => {
    e.preventDefault();

    deleteComment?.(comment);
  };

  const commentCardStyles = {
    divHeader: { justifyContent: "space-between", marginBottom: 5 },
    mediaBody: {
      marginLeft: 10,
      backgroundColor: itemBackgroundColor || "#f3f3f3",
      borderRadius: 5,
      paddingBottom: 5,
    },
    mediaLeft: { alignSelf: "flex-start", paddingTop: 5 },
    mediaMain: {
      ...listStyle,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
      ...style,
    },
    mentionDisplay: { ...cardText, ...textThemingWorkaround },
    spanCreatedAt: {
      marginTop: -2,
      ...subHeader,
      ...textThemingWorkaround,
    },
    spanName: { ...listTitle, ...textThemingWorkaround },
  } as const;

  const authorId = author.id || author.objectId;

  const name = `${author.firstName} ${author.lastName}`;
  const imageUrl = author.profile?.thumbUrl;

  const memberAnchorProps = useAnchorProps(`/member/${authorId}`);

  const enterEditMode = (): void => {
    setIsCommentInEditMode(true);
  };

  const exitEditMode = (): void => {
    setIsCommentInEditMode(false);
    setCommentBody(comment.body);
  };

  const editorMenuDropdownElement = (updateComment || deleteComment) && (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle
        id={`commentUpdateDropdown${authorId}`}
        color="link"
        className="text-decoration-none py-0 flex"
        disabled={disabled}
        style={{ height: "18px", width: "auto" }}>
        <i
          className="icon-options"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        />
      </DropdownToggle>
      <DropdownMenu>
        {updateComment && (
          <DropdownItem
            id={`commentEditButton${authorId}`}
            onClick={enterEditMode}>
            {t("Card.Button.Edit")}
          </DropdownItem>
        )}
        {deleteComment && (
          <DropdownItem
            id={`commentDeleteButton${authorId}`}
            className="text-danger"
            onClick={handleClickDelete}>
            {t("Card.Button.Delete")}
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const publishedDateText = `${fromNow(createdAt)} ${
    edited ? " (edited) " : ""
  }`;

  const isSaveButtonDisabled = commentBody.trim() === "";

  const commentContentElement = isCommentInEditMode ? (
    <>
      <div
        className="comment-card"
        style={{
          backgroundColor: commentButtonStyles.backgroundColor,
          borderRadius: 5,
          paddingLeft: 5,
          paddingRight: 5,
        }}>
        <MentionInputContainer
          Layout={MentionInput}
          /**
           * @FIXME
           * Following web specific props are not declared in
           * container props type
           */
          // type="text"
          // name="comment"
          id={`commentEditInput${authorId}`}
          value={commentBody ?? "empty comment message"}
          mentionUser={setMentions}
          mentionLink={setLinkMentions}
          usersMentioned={mentions}
          linksMentioned={linkMentions}
          onChangeText={handleChange}
          disabled={disabled || !isCommentInEditMode}
          minHeight={35}
          maxHeight={150}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="text-right">
        <Button className="py-1" color="link" onClick={exitEditMode}>
          <span
            style={{
              fontSize: 17,
              color: buttonBackgroundColor,
            }}>
            {t("Input.Button.Cancel")}
          </span>
        </Button>
        <Button
          id={`commentEditSaveButton${authorId}`}
          className="py-1"
          color="link"
          disabled={isSaveButtonDisabled}
          onClick={handleClickSave}
          type="submit">
          <span
            style={{
              fontSize: 17,
              color: buttonBackgroundColor,
            }}>
            {t("Input.Button.Save")}
          </span>
        </Button>
      </div>
    </>
  ) : (
    <MentionDisplay
      allowRenderVideo={true}
      disabled={disabled}
      linkMentions={comment.linksMentions}
      mentions={comment.mentions}
      text={cleanHTMLTags(commentBody || "")}
      viewMore={{ numberOfLines: 3 }}
      style={commentCardStyles.mentionDisplay}
    />
  );

  return (
    <Media
      style={commentCardStyles.mediaMain}
      className={classnames(className)}>
      <Media
        left
        {...(disabled ? {} : memberAnchorProps)}
        style={commentCardStyles.mediaLeft}>
        <Avatar src={imageUrl} name={name} round={true} size="35" />
      </Media>
      <Media body style={commentCardStyles.mediaBody}>
        <div className="d-flex">
          <div style={commentCardStyles.divHeader}>
            <span style={commentCardStyles.spanName}>{name}</span>
            {editorMenuDropdownElement}
          </div>
          <div className="flex-fill text-right text-muted small">
            <span style={commentCardStyles.spanCreatedAt}>
              {publishedDateText}
            </span>
          </div>
        </div>
        <div className={"mb-1"}>{commentContentElement}</div>
      </Media>
    </Media>
  );
};
