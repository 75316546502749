import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { getVideoLibrary } from "./action";
import { MediaVideoLibrarySetAction } from "./types";
import { MediaVideo } from "../../types/Media/MediaVideo";
import { RootState } from "../../types";

type StateProps = {
  data: MediaVideo[] | null;
};

type DispatchProps = {
  search: (
    refresh?: boolean,
    query?: string
  ) => Promise<MediaVideoLibrarySetAction | { data: MediaVideo[] | null }>;
};

type Props = {
  // @TODO add typing when typing components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Layout: ComponentType<any>;
  onSelect: (image: MediaVideo) => void;
} & DispatchProps &
  StateProps;

type State = {
  error: null;
  loading: boolean;
  refreshing: boolean;
  query?: string;
};

class SearchVideoLibrary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      refreshing: false,
    };
  }

  componentDidMount(): void {
    this.reFetch(true);
  }

  reFetch = (refresh: boolean, callback?: () => void): void => {
    const { search } = this.props;
    const { refreshing, loading, query } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        await search(refresh, query);
        this.setState({ refreshing: false, loading: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, data, onSelect } = this.props;
    const { loading, error, refreshing } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        data={data}
        onSelect={onSelect}
        reFetch={this.reFetch}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    data: state.media.videoLibrary,
  };
};

const mapDispatchToProps = {
  search: getVideoLibrary,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchVideoLibrary);
