import _ from "underscore";
import { saveData } from "../../http";
import { ChangeAttendee } from "../../../types/Event/ChangeAttendee";
import { EventAttendee } from "../../../types/Event/EventAttendee";
import { UserDataState } from "../../../types/User/UserDataState";

export const updateEventAttendee = async (
  id: string,
  data: ChangeAttendee
): Promise<EventAttendee> => {
  const {
    guests,
    notifyMe,
    note,
    response = "no",
    partnerResponse,
    guestMembers,
    partners,
    partnerMembers,
  } = data;

  const attendee = new (Parse.Object.extend("Attendee"))({ id: id });
  const value = {
    guests: guests || [],
    partners: partners || [],
    notifyMe: notifyMe || false,
    note,
    response,
    partnerResponse,
    responseAt: new Date(),
  };
  _.forEach(guestMembers as UserDataState[], guestMember => {
    attendee.relation("guestMembers").add(guestMember);
  });
  _.forEach(partnerMembers as { objectId?: string }[], partnerMember => {
    attendee.relation("partnerMembers").add(partnerMember);
  });
  if (response === "no") {
    value["partnerResponse"] = "no";
  }
  return saveData(attendee, value, { spinner: false });
};
