import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";

import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

import { imageUploadConfig } from "../../../../constants";
import { Area, MediaInputFileProcessed } from "../../../../types";
import { logException } from "../../../../lib/utils";

import {
  getCroppedImg,
  convertFileToBase64,
  resizeImage,
} from "../../../services";

import { useTheme } from "../../../hooks";

export type MediaCropImageProps = {
  file: File;

  onChangeAvatar: (avatar: MediaInputFileProcessed | null) => void;
  setCropAvatarImageModalOpen: (boolean: boolean) => void;
  setImageLoading: (boolean: boolean) => void;
};

export const MediaCropImage: React.FC<MediaCropImageProps> = ({
  file,

  onChangeAvatar,
  setImageLoading,
  setCropAvatarImageModalOpen,
}) => {
  const { t } = useTranslation("Media");

  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const styles = {
    button: {
      backgroundColor: buttonBackgroundColor,
      color: buttonColor,
      borderWidth: 0,
    },
  };

  const [cropPosition, setCropPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [fileUri, setFileuri] = useState<string>();

  const convertFile = useCallback(
    async (file: File): Promise<void> => {
      setImageLoading(true);
      const base64 = await convertFileToBase64(file);
      setFileuri(base64);
      setImageLoading(false);
    },
    [setImageLoading]
  );

  useEffect(() => {
    convertFile(file);
  }, [file, convertFile]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const croppImage = useCallback(async () => {
    setImageLoading(true);
    setCropAvatarImageModalOpen(false);

    if (file && fileUri && croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(fileUri, croppedAreaPixels);

        const result: MediaInputFileProcessed = {
          file: file,
        };

        resizeImage(
          {
            base64URI: croppedImage,
            name: file.name,
          },
          resizedImageResponse => {
            Object.assign(result, {
              file: resizedImageResponse.file,
              fileUrl: resizedImageResponse.base64URI,
            });

            onChangeAvatar(result);
          },
          {
            maxHeight: imageUploadConfig.profile.maxHeight,
            maxWidth: imageUploadConfig.profile.maxWidth,
          }
        );
      } catch (e) {
        logException(e);
      }
      setImageLoading(false);
    }
  }, [
    croppedAreaPixels,
    file,
    fileUri,
    onChangeAvatar,
    setCropAvatarImageModalOpen,
    setImageLoading,
  ]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setZoom(parseInt(e.currentTarget.value) / 10);
  };

  return (
    <div>
      <div
        className="crop-container"
        style={{ position: "relative", height: "300px" }}>
        <Cropper
          image={fileUri}
          crop={cropPosition}
          cropShape={"round"}
          zoom={zoom}
          aspect={1}
          onCropChange={setCropPosition}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="m-2">
        <Form>
          <FormGroup>
            <Label for="exampleRange">{t("Media.Crop.Image.Zoom")}</Label>
            <Input
              type="range"
              name="range"
              id="CropAvatarImageZoomRange"
              value={zoom * 10}
              min={10}
              max={30}
              step={1}
              onInput={handleInputChange}
            />
          </FormGroup>
        </Form>
      </div>
      <div className="text-right my-2">
        <Button
          block
          id={"saveNewAvatarButton"}
          onClick={croppImage}
          style={styles.button}
          variant="contained">
          {t("Media.Crop.Image.Save.New.Avatar.Button")}
        </Button>
      </div>
    </div>
  );
};
