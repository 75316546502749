import React, { PropsWithChildren } from "react";

import { Container, Row, Col } from "reactstrap";

import { BackgroundFixed } from "..";

export type TemplateAppPreviewProps = PropsWithChildren<{}>;

export const TemplateAppPreview: React.FC<TemplateAppPreviewProps> = ({
  children,
}) => (
  <>
    <BackgroundFixed />
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col className="gf-main-content-preview">{children}</Col>
      </Row>
    </Container>
  </>
);
