import { getDictDefinedValues } from "../../lib/utils/store";

import { GlobalAction, CommentResponse, Dict } from "../../types";

import {
  ANNOUNCEMENT_ALL_SET,
  ANNOUNCEMENT_SET,
} from "../Announcement/constants";
import { EVENT_SET } from "../Event/constants";
import {
  POST_GROUP_SET,
  POST_ALL_SET,
  POST_USER_SET,
  POST_SET,
} from "../Post/constants";

import {
  COMMENT_DELETE,
  COMMENT_RESET,
  COMMENT_SET,
  COMMENT_UPDATE,
  REACTION_SET,
  SEEN_SET,
} from "./constants";
import Store from "./store";
import { CommentState } from "./types";

export const initialState = Store;

export default function reducer(
  state: CommentState = initialState,
  action: GlobalAction
): CommentState {
  switch (action.type) {
    case COMMENT_RESET:
      return initialState;

    case COMMENT_SET:
      if (action.data) {
        return {
          ...state,
          commentsList: {
            ...state.commentsList,
            [action.data.id]: action.data.data,
          },
        };
      }

      return state;

    case COMMENT_UPDATE:
      if (action.data) {
        const { id, data } = action.data;

        const itemCommentList = state.commentsList[id];

        if (itemCommentList) {
          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              [id]: {
                ...itemCommentList,
                items: {
                  ...itemCommentList.items,
                  [data.id]: data,
                },
              },
            },
          };
        }
      }

      return state;

    case COMMENT_DELETE:
      if (action.data) {
        const { id, data } = action.data;

        const itemCommentList = state.commentsList[id];
        const itemCommentListItems = state.commentsList[id]?.items;

        if (data.id && itemCommentList && itemCommentListItems) {
          const newCommentListItems = Object.values(itemCommentListItems)
            .filter(comment => comment.id !== data.id)
            .reduce<Dict<CommentResponse>>((prevValue, currentValue) => {
              prevValue[currentValue.id] = currentValue;

              return prevValue;
            }, {});

          return {
            ...state,
            commentsList: {
              ...state.commentsList,
              [id]: {
                ...itemCommentList,
                items: newCommentListItems,
              },
            },
          };
        }
      }

      return state;

    case REACTION_SET:
      if (action.data) {
        return {
          ...state,
          reactionsList: {
            ...state.reactionsList,
            [action.data.id]: action.data.data,
          },
        };
      }

      return state;

    case SEEN_SET:
      if (action.data) {
        return {
          ...state,
          seensList: {
            ...state.seensList,
            [action.data.id]: action.data.data,
          },
        };
      }

      return state;

    /** Announcements */

    case ANNOUNCEMENT_SET:
      if (action.data) {
        const item = action.data;
        const hasMore = item.comments.length < item.announcement.commentCount;

        const newCommentItems = item.comments.reduce(
          (prevComment, currentComment) => ({
            ...prevComment,
            [currentComment.objectId]: currentComment,
          }),
          {}
        );

        return {
          ...state,
          commentsList: {
            ...state.commentsList,
            [item.id]: {
              page: 0,
              hasMore,
              items: newCommentItems,
            },
          },
        };
      }

      return state;

    case ANNOUNCEMENT_ALL_SET:
      if (action.data.items) {
        const updatedCommentsListItems = getDictDefinedValues(
          action.data.items
        ).reduce(
          (prevItem, currentItem) => {
            const entity =
              currentItem.announcement.event ?? currentItem.announcement;

            const hasMore = currentItem.comments.length < entity.commentCount;

            const newCommentItems = currentItem.comments.reduce(
              (prevComment, currentComment) => ({
                ...prevComment,
                [currentComment.objectId]: currentComment,
              }),
              {}
            );

            return {
              ...prevItem,
              [entity.objectId]: {
                page: 0,
                hasMore,
                items: newCommentItems,
              },
            };
          },
          { ...state.commentsList }
        );

        return {
          ...state,
          commentsList: updatedCommentsListItems,
        };
      }

      return state;

    /** Events */

    case EVENT_SET:
      if (action.data) {
        const item = action.data;
        const hasMore = item.comments.length < item.event.commentCount;

        const newCommentItems = item.comments.reduce(
          (prevComment, currentComment) => ({
            ...prevComment,
            [currentComment.objectId]: currentComment,
          }),
          {}
        );

        return {
          ...state,
          commentsList: {
            ...state.commentsList,
            [item.id]: {
              page: 0,
              hasMore,
              items: newCommentItems,
            },
          },
        };
      }

      return state;

    /** Posts */
    case POST_ALL_SET:
    case POST_USER_SET:
    case POST_GROUP_SET:
      if (action.data) {
        const newItems = action.data.items;

        if (newItems) {
          const newCommentList = getDictDefinedValues(newItems).reduce(
            (prevVal, currentVal) => {
              const newCommentItems = currentVal.comments.reduce(
                (prevComment, currentComment) => ({
                  ...prevComment,
                  [currentComment.objectId]: currentComment,
                }),
                {}
              );

              const hasMore =
                currentVal.comments.length < currentVal.post.commentCount;

              prevVal[currentVal.post.id] = {
                hasMore,
                items: newCommentItems,
                page: 0,
              };

              return prevVal;
            },
            { ...state.commentsList }
          );

          return {
            ...state,
            commentsList: newCommentList,
          };
        }
      }

      return state;

    case POST_SET:
      if (action.data) {
        const item = action.data;
        const hasMore = item.comments.length < item.post.commentCount;

        const newCommentItems = item.comments.reduce(
          (prevComment, currentComment) => ({
            ...prevComment,
            [currentComment.objectId]: currentComment,
          }),
          {}
        );

        return {
          ...state,
          commentsList: {
            ...state.commentsList,
            [item.post.id]: {
              page: 0,
              hasMore,
              items: newCommentItems,
            },
          },
        };
      }

      return state;
    default:
      return state;
  }
}
