import React, { Component, ComponentType } from "react";

import { connect } from "react-redux";
import { take } from "underscore";

import {
  AnnouncementItem,
  EventItem,
  PostItem,
  RootState,
  SearchTypeDict,
  SearchTypeKey,
} from "../../types";

import { searchFullTextAnnouncements } from "../Announcement/action";
import { searchFullTextEvents } from "../Event/action";
import { searchFullTextPosts } from "../Post/action";

type StateProps = {
  setting: {};
  histories: string[];
  data: AnnouncementItem[] | EventItem[] | PostItem[] | null;
  hasMore: boolean;
};

type DispatchProps = {
  searchFullTextAnnouncements: (
    search: string,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
  searchFullTextEvents: (
    search: string,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
  searchFullTextPosts: (
    search: string,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
};

/** @TODO Missing Layout props type */
type SearchTargetLayoutProps = any;

type OwnProps = {
  Layout: ComponentType<SearchTargetLayoutProps>;
  query?: string;
  searchType: SearchTypeKey;
  searchTypes?: SearchTypeDict;
  onRef: (ref: null | SearchTargetToEnhance) => void;
  changeQuery: (query: string) => void;
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  hasMore: boolean;
  query?: string;
};

export class SearchTargetToEnhance extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      refreshing: false,
      hasMore: false,
    };
  }

  componentDidMount(): void {
    this.props.onRef(this);

    // Doesn't impact native as it doesn't pass query prop
    if (this.props.query && this.state.query !== this.props.query)
      this.setState({ query: this.props.query });
  }

  componentDidUpdate(): void {
    // Doesn't impact native as it doesn't pass query prop
    if (this.props.query && this.state.query !== this.props.query)
      this.setState({ query: this.props.query });
  }

  componentWillUnmount(): void {
    this.props.onRef(null);
  }

  reFetch = async (next: boolean, refresh: boolean): Promise<void> => {
    const {
      searchFullTextEvents,
      searchFullTextAnnouncements,
      searchFullTextPosts,
      searchType,
    } = this.props;
    const { refreshing, loading, query } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState({ refreshing: refresh, loading: next }, async () => {
      try {
        let search;
        if (searchType === "announcement") {
          search = searchFullTextAnnouncements;
        } else if (searchType === "event") {
          search = searchFullTextEvents;
        } else if (searchType === "post") {
          search = searchFullTextPosts;
        }
        if (!search) {
          return;
        }

        await search(query as string, next, refresh);

        this.setState({
          loading: false,
          refreshing: false,
          error: null,
        });
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  onSearch = (query?: string): void => {
    if (this.state.query !== query) {
      this.setState({ query }, () => {
        this.reFetch(false, true);
      });
    }
  };

  onSearchWeb = (): void => {
    this.reFetch(false, true);
  };

  onSearchFromHistory = (query: string): void => {
    this.setState({ query }, () => {
      this.reFetch(false, true);
    });
    this.props.changeQuery(query);
  };

  render = (): JSX.Element => {
    const {
      Layout,
      data,
      hasMore,
      histories,
      searchType,
      searchTypes,
      setting,
    } = this.props;
    const { loading, error, refreshing, query } = this.state;

    return (
      <Layout
        data={data}
        error={error}
        hasMore={hasMore}
        histories={histories}
        loading={loading}
        query={query}
        reFetch={this.reFetch}
        refreshing={refreshing}
        searchFromHistory={this.onSearchFromHistory}
        searchType={searchType}
        searchTypes={searchTypes}
        setting={setting}
      />
    );
  };
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const searchType = ownProps.searchType;
  const { items, hasMore } = state[searchType].searchList;

  const history = state[searchType].history;
  return {
    data: items && Object.values(items),
    hasMore,
    histories: history && take(Object.keys(history).reverse(), 10),
    setting: {},
  };
};

const mapDispatchToProps = {
  searchFullTextAnnouncements,
  searchFullTextEvents,
  searchFullTextPosts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchTargetToEnhance);
