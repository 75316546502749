import { toast } from "react-toastify";
import i18n from "../../../middlewares/i18next";

export const checkFileSize = (file: File, maxSize: number): boolean => {
  if (file.size / Math.pow(1024, 2) > (maxSize || 1024)) {
    toast.error(
      i18n.t("Media:Picker.FallBack.File.Greater.Than", {
        maxSize: maxSize || 1024,
      })
    );
    return true;
  } else {
    return false;
  }
};
