import * as React from "react";

import classnames from "classnames";
import throttle from "lodash/throttle";

import { LoadingSpinner, Placeholder, Empty } from ".";

export type InfiniteScrollComponentType = React.ComponentType<
  React.PropsWithChildren<{
    className?: string;
    id?: string;
    sentinel: JSX.Element;
    style?: React.CSSProperties;
  }>
>;

export type InfiniteScrollProps =
  | {
      /** Children */
      children: JSX.Element[] | null;

      /** Class name */
      className?: string;

      /**
       * A React component to act as wrapper
       */
      component?: InfiniteScrollComponentType;

      emptyText?: string;

      /**
       * Does the resource have more entities
       */
      hasMore: boolean | undefined;

      /** Element displayed above the list */
      headerElement?: React.ReactNode;

      /** Element id */
      id?: string;

      /** Should show <LoadingSpinner /> at the bottom */
      isLoading: boolean;

      /**
       * Is data refreshing
       */
      isRefreshing?: boolean;

      placeholder?: React.ReactNode;

      /**
       * Scroll threshold
       */
      threshold?: number;

      /**
       * Throttle rate
       */
      throttle?: number;

      /**
       * Optional scrollable container reference to add scroll event
       * listener
       */
      scrollableRef?: React.RefObject<HTMLDivElement>;

      /**
       * CSS inline styles
       */
      style?: React.CSSProperties;

      /**
       * Callback to load more entities
       */
      onLoadMore?: () => void;

      /**
       * Callback for convenient inline rendering and wrapping
       */
      render?: (a: object) => any;
    }
  | {
      /** Children */
      children: JSX.Element[] | null;

      /** Class name */
      className?: string;

      /**
       * A React component to act as wrapper
       */
      component?: InfiniteScrollComponentType;

      emptyText?: string;

      /**
       * Does the resource have more entities
       */
      hasMore?: undefined;

      /** Element displayed above the list */
      headerElement?: React.ReactNode;

      /** Element id */
      id?: string;

      /**
       * Should show loading
       */
      isLoading: boolean;

      /**
       * Is data refreshing
       */
      isRefreshing?: boolean;

      placeholder?: React.ReactNode;

      /**
       * Scroll threshold
       */
      threshold?: undefined;

      /**
       * Throttle rate
       */
      throttle?: undefined;

      /**
       * Optional scrollable container reference to add scroll event
       * listener
       */
      scrollableRef?: undefined;

      /**
       * CSS inline styles
       */
      style?: React.CSSProperties;

      /**
       * Callback to load more entities
       */
      onLoadMore?: undefined;

      /**
       * Callback for convenient inline rendering and wrapping
       */
      render?: (a: object) => any;
    };

export class InfiniteScroll extends React.Component<InfiniteScrollProps, {}> {
  private scrollableBox?: HTMLDivElement | Window;
  private sentinel: HTMLDivElement | null = null;

  private getScrollableBottom = (): number => 0;
  private resizeHandler = (): void => undefined;
  private scrollHandler = (): void => undefined;

  constructor(props: InfiniteScrollProps) {
    super(props);

    if (this.props.onLoadMore) {
      this.scrollableBox = this.props.scrollableRef?.current || window;

      this.scrollHandler = throttle(
        this.checkWindowScroll,
        this.props.throttle || 150
      );

      this.resizeHandler = throttle(
        this.checkWindowScroll,
        this.props.throttle || 150
      );

      if (this.scrollableBox) {
        this.getScrollableBottom = (): number =>
          this.scrollableBox
            ? this.scrollableBox instanceof HTMLDivElement
              ? this.scrollableBox.clientHeight +
                this.scrollableBox.getBoundingClientRect().top
              : this.scrollableBox.innerHeight
            : 0;
      }
    }
  }

  getSentinelTop = (): number =>
    this.sentinel?.getBoundingClientRect().top || 0;

  componentDidMount(): void {
    if (this.scrollableBox) {
      this.scrollableBox.addEventListener("scroll", this.scrollHandler);
      this.scrollableBox.addEventListener("resize", this.resizeHandler);
    }
  }

  componentWillUnmount(): void {
    if (this.scrollableBox) {
      this.scrollableBox.removeEventListener("scroll", this.scrollHandler);
      this.scrollableBox.removeEventListener("resize", this.resizeHandler);
    }
  }

  shouldComponentUpdate(nextProps: Readonly<InfiniteScrollProps>): boolean {
    if (
      this.props.scrollableRef?.current &&
      this.props.scrollableRef?.current !== this.scrollableBox
    ) {
      this.scrollableBox = this.props.scrollableRef?.current;
      this.scrollableBox.addEventListener("scroll", this.scrollHandler);
      return true;
    }
    return !!nextProps.children;
  }

  checkWindowScroll = (): void => {
    if (this.props.isLoading) {
      return;
    }

    if (
      this.props.hasMore &&
      this.sentinel &&
      this.sentinel.getBoundingClientRect().top - this.getScrollableBottom() <
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (this.props.threshold || 700)!
    ) {
      this.props.onLoadMore && this.props.onLoadMore();
    }
  };

  render(): JSX.Element {
    const {
      className,
      children,
      component,
      emptyText,
      headerElement,
      id,
      isLoading,
      isRefreshing,
      placeholder,
      render,
      style,
    } = this.props;

    const sentinel = (
      <div
        ref={(i: HTMLDivElement | null): HTMLDivElement | null =>
          (this.sentinel = i)
        }
      />
    );

    const mainProps = {
      id: id,
      className: classnames(className),
      style: style,
    } as const;

    const content =
      isRefreshing || children === null ? (
        placeholder || <Placeholder />
      ) : children.length > 0 ? (
        <>
          {children}
          {isLoading && <LoadingSpinner id="infiniteScrollLoadingSpinner" />}
        </>
      ) : (
        <Empty text={emptyText} />
      );

    const contentWithExtraFeatures = (
      <>
        {headerElement}
        {content}
      </>
    );

    if (render) {
      return render({
        sentinel,
        children: contentWithExtraFeatures,
        ...mainProps,
      });
    }

    if (component && (isRefreshing || (children && children.length > 0))) {
      const Component = component;

      return (
        <Component sentinel={sentinel} {...mainProps}>
          {contentWithExtraFeatures}
        </Component>
      );
    }

    return (
      <div {...mainProps}>
        {contentWithExtraFeatures}
        {sentinel}
      </div>
    );
  }
}
