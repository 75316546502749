import _ from "underscore";

import { convertObject } from "../../../lib/utils";

import { GroupMember } from "../../../types";

import { runQuery } from "../../http";

export const getGroupMemberSetting = async (
  groupId: string,
  userId: string
): Promise<GroupMember> => {
  console.debug("[Service] getGroupMemberSetting");
  const response = await runQuery(
    "GroupMember",
    (query: Parse.Query) => {
      query
        .equalTo("user", new Parse.User({ id: userId }))
        .equalTo("status", "active");

      if (!_.isEmpty(groupId)) {
        query.equalTo(
          "group",
          new (Parse.Object.extend("Group"))({ id: groupId })
        );
      }

      return query
        .include(["position", "user", "user.profile"])
        .include("group")
        .first();
    },
    { requireUser: true }
  );

  return convertObject(response);
};
