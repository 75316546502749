import React from "react";

import { connect } from "react-redux";
import { sortBy } from "underscore";

import {
  Callback,
  EmptyObject,
  Group,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";

import { getUserGroups } from "./action";

export type UserGroupsLayoutProps = {
  data: Group[] | null;
  error: Error | null;
  loading: boolean;
  setting: SettingState | EmptyObject;
  user: UserDataState | EmptyObject;
  userId?: string;
  reFetch?: (data: string, callback?: Callback) => void;
};

export type UserGroupsOwnProps = {
  Layout: React.ComponentType<UserGroupsLayoutProps>;
  userId: string;
};

export type UserGroupsStateProps = {
  data: Group[] | null;
  setting: SettingState | EmptyObject;
  user: UserDataState | EmptyObject;
};

export type UserGroupsDispatchProps = {
  getGroups: (userId: string, refresh?: boolean) => Promise<unknown>;
};

export type UserGroupsProps = UserGroupsOwnProps &
  UserGroupsStateProps &
  UserGroupsDispatchProps;

export type UserGroupsState = {
  error: Error | null;
  loading: boolean;
};

class UserGroups extends React.Component<UserGroupsProps, UserGroupsState> {
  state: UserGroupsState = {
    error: null,
    loading: false,
  };

  componentDidMount = (): void => {
    const { data, userId } = this.props;

    if (!data && userId) {
      this.fetchData(userId);
    }
  };

  componentDidUpdate = (prevProps: UserGroupsProps): void => {
    if (prevProps.userId !== this.props.userId) {
      this.fetchData(this.props.userId);
    }
  };

  fetchData = (userId: string, callback?: Callback): void => {
    const { getGroups } = this.props;

    this.setState({ loading: true }, async () => {
      try {
        await getGroups(userId);

        this.setState({ loading: false, error: null });

        callback?.();
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, user, data, setting, userId } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        data={data}
        error={error}
        loading={loading}
        setting={setting}
        user={user}
        userId={userId}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: UserGroupsOwnProps
): UserGroupsStateProps => {
  const { users: groupsByUser } = state.group;

  return {
    user: state.user.data || {},
    setting: state.setting || {},
    data:
      groupsByUser &&
      groupsByUser[ownProps.userId] &&
      sortBy(Object.assign(groupsByUser[ownProps.userId]), "name"),
  };
};

const mapDispatchToProps = {
  getGroups: getUserGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
