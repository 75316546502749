import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { RootState } from "../../types";
import { SubscriptionAlertData } from "../../types/Subscription";
import { denySubscription } from "./action";

type StateProps = {
  data: SubscriptionAlertData | null;
};

export type SubscriptionAlertLayoutProps = {
  data: SubscriptionAlertData | null;
  denySubscription: () => void;
};

type DispatchProps = {
  denySubscription: () => void;
};

type OwnProps = {
  Layout: ComponentType<SubscriptionAlertLayoutProps>;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {};

class SubscriptionAlert extends Component<Props, State> {
  state: State = {};

  denySubscription = (): void => {
    this.props.denySubscription();
  };

  render = (): JSX.Element => {
    const { Layout, data } = this.props;
    return <Layout data={data} denySubscription={this.denySubscription} />;
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  const { setting } = state;
  const data = setting.setting?.subscriptions_alert || null;

  return {
    data,
  };
};

const mapDispatchToProps = {
  denySubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionAlert);
