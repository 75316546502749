import React from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import {
  AnnouncementItem,
  CommentType,
  EmptyObject,
  PostGroup,
  PostItem,
  ReactionType,
  UserDataState,
} from "../../../../types";

import { AnnouncementPinAnchor, InfiniteScroll, NewPostButton } from "../..";

import { PostCard, PostCardPlaceholder } from "..";

export type PostListProps = {
  data: PostItem[] | null | undefined;
  error: string | null;
  groupId?: string;
  hasMore: boolean;
  isMixed?: boolean;
  loading: boolean;
  pins?: AnnouncementItem[] | null;
  refreshing: boolean;
  setting: {
    ClientHostName?: string;
    announcementLabel?: string;
    confettiEffectActions?: [];
  };
  user: UserDataState | EmptyObject;
  userId?: string;

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>;
  onScroll?: () => void;
  reFetch: (
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean
  ) => void;
  removePost?: (
    post: {
      id: string;
      group: PostGroup & { id?: string };
    },
    callback?: (text: string) => void
  ) => Promise<void>;
};

export const PostList: React.FC<PostListProps> = ({
  data,
  error,
  groupId,
  hasMore,
  isMixed,
  loading: isLoading,
  pins,
  refreshing: isRefreshing,
  setting,
  user,
  userId,

  doReaction,
  /**
   * @FIXME
   * Investigate should it be used in web version.
   * Probably related to native specific features.
   */
  onScroll,
  reFetch,
  removePost,
}) => {
  const { t } = useTranslation("Post");

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const stickyNavbarElement = !!groupId && (
    <div className="mt-2">
      <NewPostButton user={user} groupId={groupId} />
    </div>
  );

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const announcementPinElements = pins?.map((announcementItem, index) => (
    <AnnouncementPinAnchor
      customLabel={setting.announcementLabel}
      item={announcementItem}
      key={index}
    />
  ));

  const postCardElements =
    data?.map(postItem => (
      <PostCard
        key={postItem.post.id}
        item={postItem}
        isMixed={isMixed}
        setting={setting}
        user={user}
        doReaction={doReaction}
        removePost={removePost}
      />
    )) || null;

  return (
    <>
      {stickyNavbarElement}
      {errorElement}
      <InfiniteScroll
        emptyText={t("No posts found")}
        hasMore={!!hasMore}
        headerElement={announcementPinElements}
        isLoading={!!isLoading}
        isRefreshing={isRefreshing}
        placeholder={<PostCardPlaceholder />}
        onLoadMore={handleLoadMore}>
        {postCardElements}
      </InfiniteScroll>
    </>
  );
};
