import React from "react";

import { RouteComponentProps } from "react-router-dom";

import { MembersListWithFilterTabs, MemberMixed } from "../../components";
import { useSettingConfig } from "../../hooks";
import MembersContainer from "../../../containers/Member";
import { Tab } from "../../../containers/Member/types";

export type RouteMemberListProps = RouteComponentProps;

export const RouteMemberList: React.FC<RouteMemberListProps> = props => {
  const { blenderboxMembersTabs, blenderboxImprovements } =
    useSettingConfig() || {};

  const blenderBoxTabs: Tab[] = [
    { title: "Directory", icon: "", id: "members" },
    { title: "Business Map", icon: "", id: "maps" },
  ];

  const tabs: Tab[] | null = blenderboxMembersTabs
    ? blenderboxMembersTabs
    : blenderboxImprovements
    ? blenderBoxTabs
    : null;

  if (tabs) {
    return <MemberMixed {...props} tabs={tabs} />;
  }

  return (
    <MembersContainer
      {...props}
      forceRefresh={true}
      Layout={MembersListWithFilterTabs}
    />
  );
};
