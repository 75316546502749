import { createSelector } from "reselect";
import {
  RootState,
  NotificationLandingMessageWithAuthor,
  NotifiacationLandingTweet,
  EmptyObject,
  NotificationAlertData,
} from "../../types";
import { getSettingState } from "../Setting/selector";

const getLandingMessagesData = (
  state: RootState
): NotificationLandingMessageWithAuthor[] | null =>
  state.notification.landingMessages || null;

const getLandingTweetsData = (
  state: RootState
): NotifiacationLandingTweet[] | null =>
  state.notification.landingTweets || null;

const getAlertData = (state: RootState): NotificationAlertData | EmptyObject =>
  state.notification.alert;

export const getLandingMessagesDataState = createSelector(
  [getLandingMessagesData],
  items => items
);

export const getLandingTweetsDataState = createSelector(
  [getLandingTweetsData],
  items => items
);

export const getLandingNotificationSettingState = createSelector(
  [getSettingState],
  setting => ({
    logo:
      setting.setting?.logo_large || setting.setting?.landing_page_logo || "",
    backgroundImage: setting.setting?.landing_page_image || "",
  })
);

export const getAlertDataState = createSelector([getAlertData], items => items);
