import React from "react";

import classnames from "classnames";
import { CardText } from "reactstrap";

import { toCapitalizeCase } from "../../../lib/utils";
import { textThemingWorkaround } from "../../../constants/Theme";
import Variables from "../../../theme/variables/materialWeb";

import { useTheme } from "../../hooks";

import { Icon, MentionDisplay } from "..";

export type EventVirtualLocationProps = {
  className?: string;
  details:
    | {
        video_url: string;
        video_details: string;
        phone_number: string;
        audio_details: string;
      }
    | undefined;
  type: string | undefined;
};

export const EventVirtualLocation: React.FC<EventVirtualLocationProps> = ({
  className,
  details,
  type,
}) => {
  const {
    text: { listText },
  } = useTheme();

  const eventVirtualLocationStyles = {
    listText: {
      ...listText,
      ...textThemingWorkaround,
      padding: 0,
    },
    mentionDisplayTitle: {
      a: {
        ...listText,
        ...textThemingWorkaround,
        padding: 0,
        color: Variables.brandInfo,
      },
    },
  } as const;

  const locationContent =
    type === "audio"
      ? ({
          title: details?.["phone_number"],
          body: details?.["audio_details"],
          icon: "phone",
        } as const)
      : type === "video"
      ? ({
          title: details?.["video_url"],
          body: details?.["video_details"],
          icon: "video-camera",
        } as const)
      : ({
          icon: "map-marker-alt",
        } as const);

  return (
    <div id="eventDetailVirtualLocation" className={classnames(className)}>
      <CardText>
        <Icon
          className="mr-2"
          style={eventVirtualLocationStyles.listText}
          type="FontAwesome"
          name={locationContent.icon}
        />
        <MentionDisplay
          style={eventVirtualLocationStyles.mentionDisplayTitle}
          text={`${locationContent.title}`}
        />
      </CardText>

      <CardText
        style={eventVirtualLocationStyles.listText}
        id="eventDetailVirtualLocationText"
        className={classnames(className)}
        tag="div">
        <MentionDisplay
          text={toCapitalizeCase(locationContent.body)}
          viewMore={{ numberOfLines: 3 }}
        />
      </CardText>
    </div>
  );
};
