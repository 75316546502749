import React, { Component, ComponentType } from "react";

import { connect } from "react-redux";

import { RootState, NotificationUploadData } from "../../types";

import { cancelUploading } from "./action";

export type UploadingStateProps = {
  uploadStatus: NotificationUploadData | null;
};

export type UploadingDispatchProps = {
  cancelUploading: () => Promise<void>;
};

export type UploadingLayoutProps = {
  className?: string;
  status: NotificationUploadData | null;
  style?: {};

  cancel: () => void;
};

export type UploadingProps = {
  Layout: ComponentType<UploadingLayoutProps>;
  className?: string;
  style?: {};
} & UploadingDispatchProps &
  UploadingStateProps;

export type UploadingState = {};

class Uploading extends Component<UploadingProps, UploadingState> {
  constructor(props: UploadingProps) {
    super(props);
    this.state = {};
  }

  onCancel = (): void => {
    this.props.cancelUploading();
  };

  render = (): JSX.Element => {
    const { Layout, className, uploadStatus, style } = this.props;
    return (
      <Layout
        className={className}
        status={uploadStatus}
        style={style}
        cancel={this.onCancel}
      />
    );
  };
}

const mapStateToProps = (state: RootState): UploadingStateProps => {
  return {
    uploadStatus: state.notification.upload,
  };
};

const mapDispatchToProps = {
  cancelUploading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploading);
