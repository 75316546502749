import React from "react";

import { useTranslation } from "react-i18next";
import {
  Alert,
  Card,
  CardBody,
  Table,
  CustomInput,
  Input,
  Button,
} from "reactstrap";

import { currencyFormat } from "../../../../lib/utils";
import { textThemingWorkaround } from "../../../../constants/Theme";
import { EventAttendeeItem } from "../../../../types";

import { useTheme } from "../../../hooks";

import { Heading } from "../..";

export type EventAttendeeSelectOptionsProps = {
  items: EventAttendeeItem[] | undefined;
  sending: boolean | undefined;
  title: string | undefined;
  totalFees: number;

  onChangeOptionsAttendee: <T extends keyof EventAttendeeItem>(
    index: number,
    name: T,
    value: EventAttendeeItem[T]
  ) => void;
  onSubmit: () => void;
};

/**
 * @TODO EventAttendeeOptions could be a better name
 *
 * This component's API is different from the native component's API.
 * I passed here only necessary data what seems to be a better solution:
 * - title instead of whole item
 * - totalFees instead of whole rsvpData
 * - items instead of whole attendee
 */
export const EventAttendeeSelectOptions: React.FC<EventAttendeeSelectOptionsProps> = ({
  items,
  sending,
  title,
  totalFees,

  onChangeOptionsAttendee,
  onSubmit,
}) => {
  const { t } = useTranslation("Event");

  const {
    button,
    text: { header, subHeader, cardText },
  } = useTheme();

  const eventAttendeeSelectOptionsStyles = {
    header: {
      ...header,
      ...textThemingWorkaround,
    },
    subHeader: {
      ...subHeader,
      ...textThemingWorkaround,
    },
    cardText: {
      ...cardText,
      ...textThemingWorkaround,
      fontWeight: "normal",
    },
    inputQuantity: {
      ...cardText,
      ...textThemingWorkaround,
      fontWeight: "normal",
      padding: 10,
    },
    options: {
      ...cardText,
      ...textThemingWorkaround,
      fontWeight: "normal",
      paddingLeft: 10,
      paddingRight: 25,
    },
    thead: {
      backgroundColor: "#f8f8f8",
      paddingHorizontal: 5,
      paddingVertical: 10,
    },
    theadText: {
      ...subHeader,
      ...textThemingWorkaround,
      padding: undefined,
      margin: undefined,
      borderBottom: 0,
      borderTop: 0,
    },
    theadEmpty: {
      borderBottom: 0,
      borderTop: 0,
    },
    quantitySubHeader: {
      ...subHeader,
      ...textThemingWorkaround,
      padding: undefined,
      margin: undefined,
      borderBottom: 0,
      borderTop: 0,
      width: "20%",
    },
    payButton: {
      ...button,
      borderColor: button.backgroundColor,
      height: 40,
      float: "right",
    },
    payButtonText: {
      ...button.text,
      ...textThemingWorkaround,
      textAlign: undefined,
    },
  } as const;

  const titleElement = title && (
    <Heading
      style={eventAttendeeSelectOptionsStyles.header}
      className="mb-4"
      text={title}
    />
  );

  /**
   * @TODO This logic is duplicated in native and web components.
   * It should be moved to container.
   */
  const tableItems = (items || [])
    .filter(({ options }) => options.length > 0)
    .map((item, index) => {
      // const defaultValue = item.required ? 1 : 0;
      return (
        <tr key={index}>
          <th
            style={eventAttendeeSelectOptionsStyles.cardText}
            scope="row"
            className="align-middle">
            {item.description}
          </th>
          <td className="align-middle">
            <CustomInput
              type="select"
              style={eventAttendeeSelectOptionsStyles.options}
              value={item.selectedOption}
              id={`eventAttendeeOptionsSelect-${index}`}
              onChange={(e): void => {
                onChangeOptionsAttendee(
                  index,
                  "selectedOption",
                  parseInt(e.target.value)
                );
              }}>
              {item.options.map((option, index) => (
                <option
                  key={index}
                  value={index}
                  selected={item.selectedOption === index}>
                  {option.name}
                </option>
              ))}
            </CustomInput>
          </td>
          <td className="align-middle">
            <Input
              style={eventAttendeeSelectOptionsStyles.inputQuantity}
              type="number"
              defaultValue={item.quantity}
              onChange={(e): void =>
                onChangeOptionsAttendee(
                  index,
                  "quantity",
                  parseInt(e.target.value)
                )
              }
              step={1}
              min={item.required ? 1 : 0}
              max={item.maximum}
            />
          </td>
          <td
            style={eventAttendeeSelectOptionsStyles.cardText}
            className="align-middle">
            ${currencyFormat(item.price * item.quantity)}
          </td>
        </tr>
      );
    });

  const paySaveButton =
    totalFees > 0
      ? `${t("Attendee.Button.Pay")} ${currencyFormat(totalFees)}`
      : t("Attendee.Button.Save");

  return (
    <Card>
      <CardBody>
        {titleElement}
        <Alert color="primary">
          <Heading
            style={eventAttendeeSelectOptionsStyles.subHeader}
            className="mb-2"
            text={t("Attendee.Heading.Add.On.Instructions")}
          />
          <p style={eventAttendeeSelectOptionsStyles.cardText} className="mb-0">
            {t("Attendee.Note.Select.Items")}
          </p>
        </Alert>

        <Table className="small">
          <thead style={eventAttendeeSelectOptionsStyles.thead}>
            <tr>
              <th style={eventAttendeeSelectOptionsStyles.theadText}>
                {t("Attendee.Text.Item.Options")}
              </th>
              <th style={eventAttendeeSelectOptionsStyles.theadEmpty} />
              <th style={eventAttendeeSelectOptionsStyles.quantitySubHeader}>
                {t("Attendee.Text.Item.Quantity")}
              </th>
              <th style={eventAttendeeSelectOptionsStyles.theadText}>
                {t("Attendee.Text.Item.Subtotal")}
              </th>
            </tr>
          </thead>
          <tbody className="text-muted">{tableItems}</tbody>
        </Table>
        <Button
          style={eventAttendeeSelectOptionsStyles.payButton}
          color="primary"
          disabled={sending}
          onClick={onSubmit}>
          <span style={eventAttendeeSelectOptionsStyles.payButtonText}>
            {paySaveButton}
          </span>
        </Button>
      </CardBody>
    </Card>
  );
};
