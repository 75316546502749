import React from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../hooks";

import { Group } from "../../../../types/Group";
import { InfiniteScroll } from "../../UI";
import { GroupCard, GroupCardPlaceholder } from "../";
import {
  GroupByStatusTab,
  GroupByTypeTab,
} from "../../../../containers/Group/types";

export type ListProps = {
  sectionData: Array<string | Group> | null;
  activeTab: GroupByStatusTab | GroupByTypeTab;
  allGroupItemsLength: number;
  isRefreshing: boolean;
  simpleMode?: boolean;
  hasMoreItemsToLoad: boolean;
  loadMoreGroups: (maxVisibleGroupsNumber: number) => () => void;
};

export const List: React.FC<ListProps> = ({
  sectionData,
  activeTab,
  allGroupItemsLength,
  isRefreshing,
  simpleMode,
  hasMoreItemsToLoad,
  loadMoreGroups,
}) => {
  const history = useHistory();
  const { t } = useTranslation("Group");
  const {
    divider: {
      backgroundColor: dividerBackgroundColor,
      text: { color: dividerColor },
    },
  } = useTheme();

  if (!sectionData) {
    return null;
  }

  return (
    <InfiniteScroll
      emptyText={t("No groups found")}
      isLoading={false}
      hasMore={hasMoreItemsToLoad}
      isRefreshing={isRefreshing}
      onLoadMore={loadMoreGroups(allGroupItemsLength)}
      placeholder={<GroupCardPlaceholder />}>
      {sectionData.map((item, index) => {
        if (typeof item === "string") {
          return (
            <div className="m-2">
              <h6
                id={`groupListHeader`}
                className="pt-3 pb-1"
                style={{
                  backgroundColor: dividerBackgroundColor,
                  color: dividerColor,
                }}>
                {item}
              </h6>
            </div>
          );
        }
        return (
          <GroupCard
            id={`groupListCard${item.name.replace(/\s/g, "")}`}
            key={`${index}_${activeTab}_${item.id}`}
            className={simpleMode ? "simple-mode" : ""}
            item={item}
            href={`/group-feed/${item.id}`}
            pending={item.pending}
            onClick={(): void => {
              history.push(`/group-feed/${item.id}`);
            }}
          />
        );
      })}
    </InfiniteScroll>
  );
};
