import { runFunction } from "../../http";
import { DictStrict } from "../../../types";
import { PostItem } from "../../../types/Post/PostItem";
import _ from "underscore";
import { Post } from "../../../types/Post/Post";
import { PostGroup } from "../../../types/Post/PostGroup";
import { convertObject } from "../../../lib/utils";
import { parseMentionsInPostItem } from "../ParseMentionsHelper";

type Options = {
  skipComments: boolean;
  per: number;
  page: number;
  followingEnabled?: boolean;
};

export const getMixedPosts = async (
  options: Options
): Promise<{
  data: DictStrict<PostItem>;
  postGroupIds: { id: string; groupId: string }[];
}> => {
  console.debug("[Service] getMixedPosts", options);

  const type = options.followingEnabled ? "mixedFeedV2" : "mixedFeed";
  const parseResponse = await runFunction(type, options, { requireUser: true });

  const postGroupIds: { id: string; groupId: string }[] = [];
  const data: DictStrict<PostItem> = _.object(
    _.map(parseResponse, (value: PostItem & { post: Parse.Object<Post> }) => {
      postGroupIds.push({
        id: value.post.id,
        groupId: (value.post.get("group") as PostGroup)?.id,
      });

      return [value.post.id, parseMentionsInPostItem(convertObject(value))];
    })
  );

  return { data, postGroupIds };
};
