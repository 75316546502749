import React, { useEffect, useRef, useState } from "react";

import GoogleMapReact, { Bounds, Coords } from "google-map-react";
import { useParams } from "react-router-dom";
import { Button, ModalHeader, ModalBody, Modal } from "reactstrap";
import useSupercluster from "use-supercluster";

import EventContainer from "../../../containers/Event/Detail";
import MemberContainer from "../../../containers/Member/Detail";

import { MapMarkerType, MapRegionType } from "../../../types";

import { EventDetail, MemberDetail, LoadingSpinner } from "..";

import { MapMarker, MapSearchBox } from ".";

export type MapProps = {
  data: MapMarkerType[] | null;
  loading: boolean | undefined;
  marker: MapMarkerType | undefined;
  isMemberMixedLayout?: true;
  needGeoLocation: boolean;
  region: MapRegionType | undefined;
  showsUserLocation: boolean;
  setting: {
    googleApi: string;
  };

  allowGetGeoLocation: (allowed: boolean) => void;
  regionChange: (region: MapRegionType) => void;
  viewMarker: (marker: MapMarkerType | undefined) => void;
};

export const Map: React.FC<MapProps> = ({
  region,
  data,
  setting,
  regionChange,
  allowGetGeoLocation,
}) => {
  const [isMarkerModalOpen, setMarkerModalOpen] = useState(false);

  const [markerForModal, setMarkerForModal] = useState<MapMarkerType>();

  const [isGoogleApiReady, setGoogleApiReady] = useState(false);

  const [bounds, setBounds] = useState({});

  const [zoom, setZoom] = useState(10);

  const { location } = useParams<{ location: string }>();

  /**@FIXME
   * map is not typed in the library for onGoogleApiLoaded prop
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapsRef = useRef<any>();

  useEffect(() => {
    !region && !location && allowGetGeoLocation(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMarkerModal = (marker: MapMarkerType): void => {
    setMarkerModalOpen(!isMarkerModalOpen);
    setMarkerForModal(marker);
  };

  const handleChange = ({
    zoom,
    bounds,
    center,
  }: {
    zoom: number;
    bounds: Bounds;
    center: Coords;
  }): any => {
    const region: MapRegionType = {
      latitude: center.lat,
      longitude: center.lng,
      latitudeDelta:
        bounds.nw.lat > bounds.se.lat
          ? bounds.nw.lat - bounds.se.lat
          : bounds.se.lat - bounds.nw.lat,
      longitudeDelta:
        bounds.nw.lng > bounds.se.lng
          ? bounds.nw.lng - bounds.se.lng
          : bounds.se.lng - bounds.nw.lng,
    };
    regionChange(region);
    setZoom(zoom);
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
  };

  const handleMarkerClick = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cluster: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    latitude: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    longitude: any
  ) => (): void | undefined => {
    const zoomExpand = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      20
    );
    mapRef.current.setZoom(zoomExpand);
    mapRef.current.panTo({ lat: latitude, lng: longitude });
  };

  /**@INFO
   * A feature object which contains a geometry and associated properties.
   * https://tools.ietf.org/html/rfc7946#section-3.2
   */
  const markers = data?.map(marker => ({
    type: "Feature",
    properties: {
      cluster: false,
      marker: marker,
    },
    geometry: {
      type: "Point",
      coordinates: [marker.location.longitude, marker.location.latitude],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: markers || [],
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const mapElement = region && (
    <div className={"py-2"} style={{ height: "700px" }}>
      {isGoogleApiReady && (
        <MapSearchBox
          map={mapRef.current}
          maps={mapsRef.current}
          placeholder="Search location"
        />
      )}
      <div style={{ height: "80%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: setting.googleApi, libraries: "places" }}
          defaultCenter={{ lat: region.latitude, lng: region.longitude }}
          defaultZoom={10}
          resetBoundsOnResize
          onChange={handleChange}
          /**@INFO
           * To make bounds work we have to use the following prop
           */
          yesIWantToUseGoogleMapApiInternals
          /**@INFO
           * Gives us an object so that we can extract the actual Google map instance
           */
          onGoogleApiLoaded={({ map, maps }): void => {
            mapRef.current = map;
            mapsRef.current = maps;
            setGoogleApiReady(true);
          }}>
          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
              marker,
            } = cluster.properties;
            if (isCluster) {
              return (
                <MapMarker key={cluster.id} lat={latitude} lng={longitude}>
                  <div
                    style={{
                      width: `${
                        markers !== undefined
                          ? 40 + (pointCount / markers.length) * 70
                          : undefined
                      }px`,
                      height: `${
                        markers !== undefined
                          ? 40 + (pointCount / markers.length) * 70
                          : undefined
                      }px`,
                      color: "#fff",
                      background: "#2954A3",
                      borderRadius: "50%",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 14,
                    }}
                    onClick={handleMarkerClick(cluster, latitude, longitude)}>
                    {pointCount}
                  </div>
                </MapMarker>
              );
            }
            return (
              <MapMarker key={marker.id} lat={latitude} lng={longitude}>
                <Button
                  size="sm"
                  onClick={(): void => {
                    toggleMarkerModal(marker);
                  }}>
                  {marker.for === "profile" && <i className="icon-user" />}
                  {marker.for === "event" && (
                    <i className="icon-location-pin" />
                  )}
                </Button>
              </MapMarker>
            );
          })}
        </GoogleMapReact>
      </div>
    </div>
  );

  const modalElement = data && (
    <Modal
      isOpen={isMarkerModalOpen}
      toggle={(): void => setMarkerModalOpen(false)}
      centered>
      <ModalHeader toggle={(): void => setMarkerModalOpen(false)} />
      <ModalBody>
        {markerForModal?.for === "event" && (
          <EventContainer
            match={{ params: { id: markerForModal?.targetId } }}
            Layout={EventDetail}
          />
        )}
        {markerForModal?.for === "profile" && (
          <MemberContainer
            match={{ params: { profileId: markerForModal?.targetId } }}
            Layout={MemberDetail}
            /**@TODO
             * Uncomment when MemberBBComponent is ready
             */
            LayoutBB={MemberDetail}
          />
        )}
      </ModalBody>
    </Modal>
  );
  if (!data) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {mapElement}
      {modalElement}
    </>
  );
};
