import { runFunction } from "../../http";
import { convertObject } from "../../../lib/utils";

import { NotificationLandingMessage } from "../../../types";

export const getLandingMessages = async (): Promise<NotificationLandingMessage[]> => {
  console.debug("[Service] getLandingPageMessages");

  const parseResponse = await runFunction("getLandingPageMessages", {});
  return convertObject(parseResponse);
};
