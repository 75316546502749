import React, { SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Alert, Input, Button, FormGroup } from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";

import { UserState } from "../../../containers/User/types";

import { UserLoginStateData, Override } from "../../../types";

import { useTheme } from "../../hooks";

import { UserLoginBlock, UserRegisterBlock } from "./";

export type UserForgotPasswordProps = {
  data: UserLoginStateData;
  error: string | null | undefined;
  loading: boolean;
  resetSetting?: (() => Promise<unknown>) | false;
  user: UserState | {};
  setting: {
    organizationName: string;
    logo?: string;
    backgroundImage?: string;
    signInByEmail?: boolean | undefined;
    signUpEnabled?: boolean | undefined;
    InMultiTenant?: boolean | undefined;
  };

  handleChange: <K extends keyof UserLoginStateData>(
    name: K,
    val: UserLoginStateData[K]
  ) => void;
  logIn: (callback?: () => void) => Promise<void>;
  resetPassCode: (callback?: (text: string) => void) => boolean | void;
  resetPassword: (callback?: (text: string) => void) => boolean | void;
  updatePassword: (callback?: (text: string) => void) => boolean | void;
};

export const UserForgotPassword: React.FC<UserForgotPasswordProps> = ({
  data: { email },
  loading,
  error,
  setting: { signUpEnabled },

  handleChange,
  resetPassword,
}) => {
  const userForgotPasswordStyles = {
    text: {
      color: "#fff",
      textAlign: "center",
    },
    input: {
      backgroundColor: "#fff",
      paddingRight: 15,
      paddingLeft: 15,
      height: 45,
    },
    item: {
      borderBottomWidth: 0,
      marginLeft: 0,
    },
    mainButton: {
      height: 50,
    },
  } as const;

  const history = useHistory();

  const {
    button: buttonStyle,
    login: { button: loginButtonStyle },
    text: { listTitle: listTitleStyle },
  } = useTheme();

  const { t } = useTranslation("User");

  const handleResetPassword = (e: SyntheticEvent): void => {
    e.preventDefault();

    resetPassword(text => {
      toast.success(text);
      history.push("/login");
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      Override<HTMLInputElement, { name: keyof UserLoginStateData }>
    >
  ): void => {
    handleChange(e.target.name, e.target.value);
  };

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const signUpButtonElements = signUpEnabled && <UserRegisterBlock />;

  return (
    <Form className="mb-3" onSubmit={handleResetPassword}>
      <p
        className="mb-5"
        style={{
          ...listTitleStyle,
          ...{
            ...userForgotPasswordStyles.text,
            ...textThemingWorkaround,
          },
          whiteSpace: "pre-line",
        }}>
        {/**
         * @FIXME
         * Broken translation. This translation key should be probably divided
         * into two parts. Then breaking line inside is no longer necessary.
         * `whiteSpace: "pre-line"` style currently fixes breaking line bug but
         * it still issues in mobile app.
         */}
        {t("Forgot.Password.Heading")}
      </p>
      {errorElement}
      <FormGroup>
        <Input
          disabled={loading}
          id="email"
          name="email"
          placeholder={t("Forgot.Password.Email.Address")}
          style={{
            ...userForgotPasswordStyles.item,
            ...userForgotPasswordStyles.input,
            borderRadius: buttonStyle.borderRadius,
          }}
          type="email"
          value={email}
          onChange={handleInputChange}
        />
      </FormGroup>
      <Button
        color="primary"
        block
        disabled={loading}
        style={{
          ...buttonStyle,
          ...loginButtonStyle,
          ...userForgotPasswordStyles.mainButton,
          border: 0,
          ...{
            ...buttonStyle.text,
            ...textThemingWorkaround,
            textAlign: undefined,
          },
        }}
        type="submit">
        {loading ? t("Forgot.Password.Loading") : t("Forgot.Password.Submit")}
      </Button>
      {signUpButtonElements}
      <UserLoginBlock />
    </Form>
  );
};
