import { Group } from "../../../types";
import { getGroupTypesByName } from "..";
import { runQuery } from "../../http";
import { convertObject } from "../../../lib/utils";

export const getGroupHome = async (
  groupTypeName: string
): Promise<Group | null> => {
  const groupType = await getGroupTypesByName(groupTypeName);

  if (!groupType) {
    return null;
  }
  const user: Parse.User | null = await Parse.User.currentAsync();

  const groupQuery = new Parse.Query("Group");
  groupQuery.equalTo("type", groupType);

  const parseResponse = await runQuery(
    "GroupMember",
    async (query: Parse.Query) => {
      query.equalTo("user", user);
      query.equalTo("status", "active");
      query.matchesKeyInQuery("group", "objectId", groupQuery);
      const groupMember = await query.first();
      return groupMember?.get("group");
    }
  );

  return parseResponse && convertObject(parseResponse);
};
