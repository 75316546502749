import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { RootState } from "../../types";
import { EULA } from "./types";
import { getVideo } from "../VideoLibrary/action";
import { updateUserAgreement, logOut } from "../User/action";
import { track } from "../../lib/track";
import { Video } from "../../types/VideoLibrary/Video";

type StateProps = {};

export type EULAModalLayoutProps = {
  data: EULA;
  video?: Video;
  reject: () => void;
  agree: () => void;
};

type DispatchProps = {
  getVideo: (
    id: string,
    refresh: boolean,
    callback: (data: Video) => void
  ) => Promise<void>;
  logOut: () => void;
  updateUserAgreement: () => void;
};

type OwnProps = {
  Layout: ComponentType<any>;
  data: EULA;
  video?: Video;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {};

class EULAModal extends Component<Props, State> {
  state: State = {};

  componentDidMount(): void {
    this.fetchData();
  }

  fetchData = async (): Promise<void> => {
    const { getVideo, data } = this.props;

    if (data.videoId) {
      try {
        await getVideo(data.videoId, true, ({ id, title }) => {
          track("View video in EULA", {
            Id: id,
            Title: title,
          });
        });
        this.setState({ error: null });
      } catch (error) {
        this.setState({ error });
      }
    }
  };

  onReject = (): void => {
    return this.props.logOut();
  };

  onAgree = (): void => {
    return this.props.updateUserAgreement();
  };

  render = (): JSX.Element => {
    const { Layout, data, video } = this.props;
    return (
      <Layout
        data={data}
        video={video}
        reject={this.onReject}
        agree={this.onAgree}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  const { setting } = state;
  const data = setting.setting?.user_agreement;
  const { videoId } = data as EULA;

  const { list } = state.video;
  const { items, cached } = list;
  const video =
    (videoId && ((items && items[videoId]) || (cached && cached[videoId]))) ||
    null;

  return {
    data: setting.setting?.user_agreement,
    video,
  };
};

const mapDispatchToProps = {
  getVideo,
  updateUserAgreement,
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(EULAModal);
