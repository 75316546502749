import { Group } from "../../../types";
import { getGroupTypesByName } from "..";
import { getGroups } from "./getGroups";

export const getGroupsByGroupTypeName = async (
  groupTypeName: string
): Promise<Group[]> => {
  const groupType = await getGroupTypesByName(groupTypeName);

  if (!groupType) {
    return [];
  }

  return await getGroups({
    filter: "all",
    format: "flat",
    groupTypeId: groupType.id,
    type: "listGroups",
  });
};
