import { CommentType } from "../../types/Comment";
import { Dict } from "../../types";
import { CommentTypeObject } from "../../types/Comment/CommentTypeObject";

export const commentsTypes: Dict<CommentTypeObject, CommentType> = {
  announcement: {
    type: "announcement",
    commentObject: "AnnouncementComment",
    functionGetComments: "listAnnouncementComments",
    functionAddComment: "announcementComment",
    functionLike: "announcementLike",
    functionUnLike: "announcementUnLike",
    functionIsLiked: "announcementIsLiked",
    functionCommentLike: "getAnnouncementCommentLike",
    functionGetReactions: "listAnnouncementLikes",
    functionGetSeenBy: "listAnnouncementSeenBy",
  },
  event: {
    type: "event",
    commentObject: "EventComment",
    functionGetComments: "listEventComments",
    functionAddComment: "eventComment",
    functionLike: "eventLike",
    functionUnLike: "eventUnLike",
    functionIsLiked: "eventIsLiked",
    functionCommentLike: "getEventCommentLike",
    functionGetReactions: "listEventLikes",
    functionGetSeenBy: "listEventSeenBy",
  },
  post: {
    type: "groupPost",
    commentObject: "PostComment",
    functionGetComments: "listGroupPostComments",
    functionAddComment: "groupPostComment",
    functionLike: "groupPostLike",
    functionUnLike: "groupPostUnLike",
    functionIsLiked: "groupPostIsLiked",
    functionCommentLike: "getGroupPostCommentLike",
    functionGetReactions: "listGroupPostLikes",
    functionGetSeenBy: "listGroupPostSeenBy",
  },
  eventPhoto: {
    type: "eventPhoto",
    commentObject: "EventPhotoComment",
    functionGetComments: "listEventPhotoComments",
    functionAddComment: "eventPhotoComment",
    functionLike: "eventPhotoLike",
    functionUnLike: "eventPhotoUnLike",
    functionIsLiked: "eventPhotoIsLiked",
    functionCommentLike: "getEventPhotoCommentLike",
    functionGetReactions: "listEventPhotoLikes",
    functionGetSeenBy: "listEventPhotoSeenBy",
  },
};
