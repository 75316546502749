import React from "react";

import { RouteComponentProps, Redirect } from "react-router-dom";
import queryString from "query-string";

import { RedirectAppDefault } from "..";

export type RouteOldPreviewProps = RouteComponentProps;

export const RouteOldPreview: React.FC<RouteOldPreviewProps> = ({
  location,
}) => {
  const { content, contentId, token } = queryString.parse(location.search);

  if (
    token &&
    typeof token === "string" &&
    content &&
    typeof content === "string" &&
    contentId &&
    typeof contentId === "string"
  ) {
    return <Redirect to={`/preview/${token}/${content}/${contentId}`} />;
  }

  return <RedirectAppDefault />;
};
