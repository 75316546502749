import { runFunction } from "../../http";
import _ from "underscore";

export const addEventReadBy = async (
  id: string,
  uid?: string
): Promise<void> => {
  return runFunction(
    "addEventReadBy",
    {
      eventId: id,
      targetUser: uid,
    },
    { requireUser: _.isEmpty(uid) }
  );
};
