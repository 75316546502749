import _ from "underscore";
const Parse = window.Parse;

export const addToFavoriteChatContact = async (
  userIds: string[]
): Promise<void> => {
  console.debug("[Service] addToFavoriteChatContact", userIds);
  const user = await Parse.User.currentAsync();
  if (user) {
    _.forEach(userIds, function(userId) {
      user.relation("favoriteChatContacts").add(new Parse.User({ id: userId }));
    });
    user.save();
  }
};
