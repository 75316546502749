import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";

import { SearchTypeKey, SearchTypeDict } from "../../types";

/** @TODO Missing Layout props type */
type SearchLayoutProps = any;

type Props = {
  Layout: ComponentType<SearchLayoutProps>;
  searchType: SearchTypeKey;
  query?: string;
  triggerSearch?: boolean;
} & WithTranslation;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  hasMore: boolean;
  query?: string;
  searchType: SearchTypeKey;
  searchTypes: SearchTypeDict;
  activeTab: number;
  currentTab: number;
  triggerSearch: boolean;
};

/**
 * This container is not connected to Redux store
 */
class Search extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const searchType = props.searchType || "announcement";
    const searchTypes = {
      announcement: {
        id: "announcement" as const,
        name: this.props.t("Container.Index.Announcement.News"),
        tabIndex: 0,
      },
      event: {
        id: "event" as const,
        name: this.props.t("Container.Index.Announcement.Events"),
        tabIndex: 1,
      },
      post: {
        id: "post" as const,
        name: this.props.t("Container.Index.Announcement.Posts"),
        tabIndex: 2,
      },
    };

    const activeTab =
      searchTypes[searchType] && searchTypes[searchType].tabIndex;

    this.state = {
      error: null,
      loading: false,
      refreshing: false,
      hasMore: false,
      query: props.query,
      searchType: props.searchType,
      searchTypes,
      activeTab,
      currentTab: activeTab,
      triggerSearch: !!props.triggerSearch,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>): void {
    if (this.props.query !== nextProps.query) {
      this.setState({
        query: nextProps.query,
        triggerSearch: !!nextProps.triggerSearch,
      });
    }
  }

  onChangeQuery = (query: string): void => {
    this.setState({ query });
  };

  onChangeSearchType = ({ i }: { i: number }): void => {
    const { searchTypes } = this.state;
    const searchType = Object.values(searchTypes)[i];
    if (searchType) {
      this.setState({ searchType: searchType.id, currentTab: i });
    }
  };

  render = (): JSX.Element => {
    const { Layout } = this.props;
    const {
      searchType,
      searchTypes,
      query,
      activeTab,
      currentTab,
      triggerSearch,
    } = this.state;

    return (
      <Layout
        activeTab={activeTab}
        currentTab={currentTab}
        triggerSearch={triggerSearch}
        searchType={searchType}
        searchTypes={searchTypes}
        query={query}
        changeQuery={this.onChangeQuery}
        changeSearchType={this.onChangeSearchType}
      />
    );
  };
}

export default withTranslation("Search")(Search);
