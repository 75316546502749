import { Button, Modal } from "reactstrap";
import React, { ChangeEvent, FC, useEffect, useState } from "react";

import { Avatar } from "../../UI";

import { SettingsUpdateName } from "./SettingsUpdateName";

type Props = {
  coverUrl: string;
  name?: string;
  updateChannel: (name: string, callback?: (value: string) => void) => void;
  isChatNameEditable: boolean;
};

export const SettingsHeader: FC<Props> = ({
  name,
  coverUrl,
  updateChannel,
  isChatNameEditable,
}) => {
  const [isEditChannelModalOpen, setEditChannel] = useState(false);
  const [channelName, setChannelName] = useState(name);
  const openEditChannel = (): void => setEditChannel(true);
  const closeEditChannel = (): void => setEditChannel(false);

  const changeChannelName = (event: ChangeEvent<HTMLInputElement>): void => {
    setChannelName(event.target.value);
  };

  const update = (e: any): void => {
    e.preventDefault();
    channelName &&
      updateChannel(channelName, data => {
        console.debug("updated chat", data);
      });
    closeEditChannel();
  };

  useEffect(() => setChannelName(name), [name]);

  return (
    <>
      {" "}
      <div className="sendbird-channel-profile">
        <div className="sendbird-channel-profile--inner">
          <div className="sendbird-channel-profile__avatar">
            <div className=" sendbird-avatar">
              <Avatar round={true} src={coverUrl} name={name} />
            </div>
          </div>
          <div className="sendbird-channel-profile__title sendbird-label sendbird-label--subtitle-2 sendbird-label--color-onbackground-1">
            {name}
          </div>
          {isChatNameEditable && (
            <Button
              color="link"
              className="sendbird-channel-profile__edit text-decoration-none"
              onClick={openEditChannel}>
              <div className="sendbird-label sendbird-label--button-1 text-primary">
                Edit
              </div>
            </Button>
          )}
        </div>
      </div>
      <Modal isOpen={isEditChannelModalOpen} className="p-4">
        <SettingsUpdateName
          closeEditChannel={closeEditChannel}
          channelName={channelName}
          changeChannelName={changeChannelName}
          updateChannel={update}
        />
      </Modal>
    </>
  );
};
