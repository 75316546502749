import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { isEmpty } from "underscore";

import {
  getComments,
  addComment,
  updateComment,
  deleteComment,
} from "./action";

import { track, trackComment } from "../../lib/track";

import {
  CommentRequest,
  CommentResponse,
  CommentType,
  RootState,
  UserDataState,
} from "../../types";

import { CommentProps } from "./propsTypes";
import {
  getCommentSettingState,
  getCommentsDataHasMoreState,
  getCommentsDataState,
} from "./selector";
import { getCurrentUserState } from "../User/selector";

type StateProps = {
  user: UserDataState | {};
  setting: {
    ClientHostName?: string;
    confettiEffectActions?: [];
  };
  data: CommentResponse[];
  hasMore?: boolean;
};

type DispatchProps = {
  getComments: (
    id: string,
    type: CommentType,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
  addComment: (
    id: string,
    type: CommentType,
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void,
    postAuthorId?: string
  ) => Promise<void>;
  updateComment: (
    id: string,
    type: CommentType,
    data: CommentRequest,
    groupId?: string,
    postAuthorId?: string
  ) => Promise<void>;
  deleteComment: (
    id: string,
    type: CommentType,
    data: CommentRequest,
    groupId?: string,
    itemAuthorId?: string
  ) => Promise<void>;
};

type OwnProps = {
  Layout: ComponentType<CommentProps>;

  /**
   * Web specific.
   * Sets init displayed comments amount.
   */
  commentsAmountOnOnit?: number;
  /**
   * Web specific.
   * Sets comments amount increased by show more button.
   */
  commentsAmountOnShowMore?: number;
  /** Turns off fetching data during mounting */
  disableFetchOnMount?: boolean;
  disabled?: boolean;
  match: {
    params?: { id?: string; type?: CommentType };
    isExact?: boolean;
    path?: string;
    url?: string;
  };
  newCommentInputRef?: React.RefObject<HTMLInputElement>;
  openCommentInput?: boolean;
  postAuthorId?: string;
  groupId?: string;
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  error: string | null;
  /**
   * This switch prevents state updates when component
   * is unmounted
   */
  loading: boolean;
  refreshing: boolean;
  sending?: boolean;
};

class Comments extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount(): void {
    if (!this.props.disableFetchOnMount) {
      this.fetchData(false, true);
    }

    track("View Screen", {
      Screen: "comments",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): void => {
    const { getComments, match, data } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }

    try {
      this.setState(
        { refreshing: refresh && !isEmpty(data), loading: next },
        async () => {
          if (!match?.params?.id) throw Error("Missing comment ID");
          if (!match?.params?.type) throw Error("Missing comment type");

          const { id, type } = match.params;

          await getComments(id, type, next, refresh);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });

          callback?.();
        }
      );
    } catch (error) {
      this.setState({ loading: false, refreshing: false, error: error });
    }
  };

  addComment = (
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void
  ): void => {
    const { addComment, match, postAuthorId } = this.props;
    const { sending } = this.state;
    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        if (!match?.params?.id) throw Error("Missing comment ID");
        if (!match?.params?.type) throw Error("Missing comment type");

        const { id, type } = match.params;

        await addComment(id, type, data, beforeAdding, postAuthorId);

        this.setState({ sending: false, error: null });

        trackComment(
          `Create ${type} Comment`,
          { id, type },
          {
            Comment: data.text && data.text.substring(0, 255),
          }
        );
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  updateComment = (
    data: CommentRequest,
    cancel?: boolean,
    callback?: () => void
  ): void => {
    const { updateComment, match, groupId, postAuthorId } = this.props;
    if (cancel) {
      return;
    }

    this.setState({}, async () => {
      try {
        if (!match?.params?.id) throw Error("Missing comment ID");
        if (!match?.params?.type) throw Error("Missing comment type");

        const { id, type } = match.params;

        await updateComment(id, type, data, groupId, postAuthorId);

        this.setState({ sending: false, error: null });

        trackComment(
          `Edit ${type} Comment`,
          { id, type },
          {
            Comment: data.text && data.text.substring(0, 255),
          }
        );

        callback?.();
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  deleteComment = (data: CommentRequest, callback?: () => void): void => {
    const { deleteComment, match, groupId, postAuthorId } = this.props;
    this.setState({}, async () => {
      try {
        if (!match?.params?.id) throw Error("Missing comment ID");
        if (!match?.params?.type) throw Error("Missing comment type");

        const { id, type } = match.params;

        await deleteComment(id, type, data, groupId, postAuthorId);

        this.setState({ sending: false, error: null });

        trackComment(
          `Delete ${type} Comment`,
          { id, type },
          {
            Comment: data.text && data.text.substring(0, 255),
          }
        );

        callback?.();
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const {
      Layout,

      commentsAmountOnOnit,
      commentsAmountOnShowMore,
      user,
      data,
      disabled,
      setting,
      hasMore,
      openCommentInput,
      newCommentInputRef,
    } = this.props;
    const { loading, error, refreshing, sending } = this.state;

    return (
      <Layout
        commentsAmountOnOnit={commentsAmountOnOnit}
        commentsAmountOnShowMore={commentsAmountOnShowMore}
        disabled={disabled}
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        sending={sending}
        user={user}
        setting={{ ...setting, openCommentInput: openCommentInput ?? true }}
        data={data ?? []}
        newCommentInputRef={newCommentInputRef}
        addComment={this.addComment}
        updateComment={this.updateComment}
        deleteComment={this.deleteComment}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const id = ownProps.match.params?.id || "";
  return {
    user: getCurrentUserState(state),
    setting: getCommentSettingState(state),
    data: getCommentsDataState(state, id),
    hasMore: getCommentsDataHasMoreState(state, id),
  };
};

const mapDispatchToProps = {
  getComments,
  addComment,
  updateComment,
  deleteComment,
};

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  forwardRef: true,
})(Comments);
