import _ from "underscore";
const Parse = window.Parse;

export const removeFromFavoriteChatContact = async (
  userIds: string[]
): Promise<void> => {
  const user = await Parse.User.currentAsync();
  if (user) {
    _.forEach(userIds, function(userId) {
      user
        .relation("favoriteChatContacts")
        .remove(new Parse.User({ id: userId }));
    });
    user.save();
  }
};
