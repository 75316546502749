import { saveData } from "../../http";
import { FeedbackData } from "../../../types/Support/FeedbackData";

export const sendFeedBack = async (data: FeedbackData): Promise<void> => {
  const Feedback = Parse.Object.extend("Feedback");
  const record = new Feedback();
  record.set("username", data.username);
  record.set("email", data.email);
  record.set("name", data.name);
  record.set("type", data.type);
  record.set("content", data.content);
  return saveData(record, {}, {}).then(feedback => {
    const EmailObj = Parse.Object.extend("Email");
    const email = new EmailObj();
    email.set("klass", "Feedback");
    email.set("methodName", "new_feedback");
    // eslint-disable-next-line @typescript-eslint/camelcase
    email.set("metadata", { feedback_id: feedback.id });
    email.set("sent", false);
    return saveData(email, {}, {});
  });
};
