import _ from "underscore";
import { runFunction } from "../../http";
import { CommentApiOptions } from "./index";
import { convertObject } from "../../../lib/utils";
import { UserDataState } from "../../../types/User/UserDataState";
import { SeenDict } from "../../../containers/Comment/types";
import { commentsTypes } from "../../../constants/Comment";

export const getSeens = async (
  options: CommentApiOptions
): Promise<SeenDict> => {
  console.debug("[Action] getSeens");

  const { id, page, per, type } = options;
  const option = {
    eventId: id,
    announcementId: id,
    postId: id,
    eventPhotoId: id,
    per: per,
    page: page,
  };
  const parseResponse: Parse.Object<UserDataState>[] = await runFunction(
    commentsTypes[type].functionGetSeenBy,
    option,
    {
      requireUser: true,
    }
  );
  return _.object(
    _.map(parseResponse, value => {
      return [value.id, convertObject(value)];
    })
  );
};
