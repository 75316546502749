import _ from "underscore";

import { parseToArray, parseToDate } from "../../../../lib/utils";
import { Dict, Member, UserCompanyHistoryEvent } from "../../../../types";

/**
 * @TODO Shouldn't this function be moved somewhere else? Its not an action creator.
 * Its kinda utility function used more widely than only in Members module.
 * @FIXME This function mutates its own argument `member` and returns the same mutated object.
 * Shouldn't it not mutate `member` argument and return new immutated member object instead?
 */
export const customProfile = (
  member: Member,
  groups: Dict<{
    name: string;
    objectId?: string;
  }> = {}
): Member => {
  member.isActive =
    member.status === "active" &&
    (!member.groupStatus || member.groupStatus === "active");

  member.name = member.name || member.firstName + " " + member.lastName;

  if (!member.profile || !member.profile.visibilities) {
    return member;
  }

  const visibility = member.profile.visibilities["Professional Info"];
  const section = member.profile.sections["Professional Info"];
  const personalVisibility = member.profile.visibilities["Personal Info"];
  const personalSection = member.profile.sections["Personal Info"];
  const metadata = member.profile.metadata;

  member.avatar = member.profile.thumbUrl;
  member.title = "Member";

  if (visibility.Title && !_.isEmpty(section.Title)) {
    member.title = section.Title;
  }

  if (visibility["Company Name"] && !_.isEmpty(section["Company Name"])) {
    member.company = section["Company Name"];
  }

  if (visibility.Industry && section.Industry) {
    if (_.isArray(section.Industry)) {
      member.industry = section.Industry.join(", ");
    } else {
      member.industry = section.Industry;
    }
  }

  if (visibility.Region && section.Region) {
    if (_.isArray(section.Region)) {
      member.region = section.Region.join(", ");
    } else {
      member.region = section.Region;
    }
  }

  if (metadata && !_.isEmpty(metadata.home_group)) {
    member.group = metadata.home_group;
  }

  if (metadata && !_.isEmpty(metadata.company_location)) {
    member.location = metadata.company_location;
  }

  member.email = personalSection.Email;
  member.phone = personalSection.Phone;

  if (
    personalVisibility["Interests & Hobbies"] &&
    !_.isEmpty(personalSection["Interests & Hobbies"])
  ) {
    member.interests = personalSection["Interests & Hobbies"];
  }

  if (visibility.Summary && !_.isEmpty(section.Summary)) {
    member.summary = section.Summary;
  }

  if (!_.isEmpty(personalSection.Bio)) {
    member.bio = personalSection.Bio;
  }

  // Convert Company History to array
  const companyHistory =
    member.profile.sections["Professional Info"]["Company History"];

  member.profile.sections["Professional Info"]["Company History"] =
    parseToArray(companyHistory) || [];

  // Convert Company History (fromdate, todate) to dates
  if (
    !_.isEmpty(member.profile.sections["Professional Info"]["Company History"])
  ) {
    _.forEach(
      member.profile.sections["Professional Info"][
        "Company History"
      ] as UserCompanyHistoryEvent[],
      item => {
        item["fromdate"] = parseToDate(item["fromdate"]);
        item["todate"] = parseToDate(item["todate"]);
      }
    );
  }

  //Convert Birthday to date
  member.profile.sections["Personal Info"]["Birthday"] = parseToDate(
    member.profile.sections["Personal Info"]["Birthday"]
  );

  // Convert Spouse Birthday to date
  if (member.profile.sections["Personal Info"]["Spouse"]?.Name) {
    member.profile.sections["Personal Info"]["Spouse"][
      "Birthday"
    ] = parseToDate(
      member.profile.sections["Personal Info"]["Spouse"]["Birthday"]
    );
  } else {
    member.profile.sections["Personal Info"]["Spouse"] = {};
  }

  // Convert Children Birthday to date
  if (
    _.isArray(member.profile.sections["Personal Info"]["Children"]) &&
    !_.isEmpty(member.profile.sections["Personal Info"]["Children"])
  ) {
    _.forEach(member.profile.sections["Personal Info"]["Children"], item => {
      item["Birthday"] = parseToDate(item["Birthday"]);
    });
  } else {
    member.profile.sections["Personal Info"]["Children"] = [];
  }

  //clean invalid phone numbers (Phone (Other),...)
  if (member.profile.sections["Personal Info"]["Phone"]?.length < 5) {
    member.profile.sections["Personal Info"]["Phone"] = "";
  }
  if (member.profile.sections["Personal Info"]["Phone (Other)"]?.length < 5) {
    member.profile.sections["Personal Info"]["Phone (Other)"] = "";
  }
  if (member.profile.sections["Professional Info"]["Phone"]?.length < 5) {
    member.profile.sections["Professional Info"]["Phone"] = "";
  }
  if (member.profile.sections["Executive Assistant"]["Phone"]?.length < 5) {
    member.profile.sections["Executive Assistant"]["Phone"] = "";
  }
  return member;
};
