import { CustomFileType } from "../../../../types/Chat/ChatMutatedMessage";
import React, { FC } from "react";
import { Row } from "reactstrap";
import { LoadingSpinner } from "../../UI";

export type ChatConversationFileMessageProps = {
  file?: CustomFileType;
  image?: CustomFileType;
  incoming?: boolean;

  onImageLoad?: () => void;
};

export const ChatConversationFileMessage: FC<ChatConversationFileMessageProps> = ({
  file,
  image,
  incoming,
  onImageLoad,
}) => {
  if (!image && !file) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {image && (
        <div className="sendbird-thumbnail__body">
          <div className="sendbird-thumbnail__wrap ">
            <a rel="noopener noreferrer" target="_blank" href={image.fileUrl}>
              <img
                onLoad={onImageLoad}
                className="sendbird-thumbnail__img"
                src={image?.fileUrl}
                alt={image?.name}
              />
            </a>
          </div>
        </div>
      )}
      {file && (
        <div
          className="font-weight-light chat-font-size py-1 px-3 rounded-xl mr-0 ml-1"
          style={{
            backgroundColor: incoming
              ? "rgba(41, 84, 163, 0.1)"
              : "rgba(0, 0, 0, 0.1)",
          }}>
          <Row>
            <div role="button" className="col-1 py-1 px-2">
              <i className="icon-paper-clip" />{" "}
            </div>
            <div role="button" className="my-1 ml-2">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={file.fileUrl}
                className="text-black-50">
                {file.name}
              </a>
            </div>
          </Row>
          {file.text}
        </div>
      )}
      {image?.text && (
        <div
          className="font-weight-light chat-font-size py-1 px-3 rounded-xl"
          style={{
            backgroundColor: incoming
              ? "rgba(41, 84, 163, 0.1)"
              : "rgba(0, 0, 0, 0.1)",
          }}>
          {image.text}
        </div>
      )}
    </>
  );
};
