import React from "react";

import { RouteProps, RouteComponentProps, Route } from "react-router-dom";

import { SettingDataLoaded } from "../../../types";
import { RedirectAppDefault } from "./RedirectAppDefault";

export type RouteAppComponentProps = RouteComponentProps & {
  setting: SettingDataLoaded;
  userId?: string;
};

export type RouteAppProps = RouteProps & {
  component: React.ComponentType<RouteAppComponentProps>;
  setting?: SettingDataLoaded;
  userId?: string;
};

export const RouteApp: React.FC<RouteAppProps> = ({
  setting,
  component: Component,
  userId,
  ...props
}) => {
  if (!setting) {
    return <RedirectAppDefault />;
  }

  const renderRouteApp = (
    routeComponentProps: RouteComponentProps
  ): JSX.Element => (
    <Component {...routeComponentProps} setting={setting} userId={userId} />
  );

  return <Route {...props} render={renderRouteApp} />;
};
