import React from "react";

import { RouteComponentProps } from "react-router-dom";

import UserUpdateProfileContainer from "../../../containers/User/UpdateProfile";

import { UserEditProfile } from "../../components/User";

export type RouteUserEditProfileProps = {} & RouteComponentProps;

export const RouteUserEditProfile: React.FC<RouteUserEditProfileProps> = props => (
  <UserUpdateProfileContainer {...props} Layout={UserEditProfile} />
);
