import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import { Callback, RootState, SettingState, UserDataState } from "../../types";
import { InvoiceData } from "../../types/Payment/InvoiceData";

import { getInvoices } from "./action";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";
import { getInvoiceDataState, getInvoiceDataHasMoreState } from "./selector";

type StateProps = {
  user: UserDataState | {};
  setting: SettingState | {};
  data: InvoiceData[] | null;
  hasMore: boolean;
};

export type InvoicesLayoutProps = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  hasMore: boolean;
  user: {} | UserDataState;
  setting: {} | SettingState;
  data: InvoiceData[] | null;
  reFetch: (next: boolean, refresh: boolean, callback?: Callback) => void;
};

type Props = {
  Layout: ComponentType<InvoicesLayoutProps>;
  getInvoices: (next: boolean, refresh: boolean) => Promise<void>;
} & StateProps;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class Invoices extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false, true);

    track("View Screen", {
      Screen: "payment-invoices",
    });
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): void => {
    const { getInvoices, data } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState(
      { refreshing: refresh && !!data, loading: next },
      async () => {
        try {
          await getInvoices(next, refresh);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
          callback && callback();
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  render = (): JSX.Element => {
    const { Layout, user, data, setting, hasMore } = this.props;
    const { loading, error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getInvoiceDataState(state),
    hasMore: getInvoiceDataHasMoreState(state),
  };
};

const mapDispatchToProps = {
  getInvoices: getInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
