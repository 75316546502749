import React, { FC } from "react";

import { cleanHTMLTags, displayDate } from "../../../../lib/utils";

import { MentionLink, MentionUser } from "../../../../types";
import {
  ChatMutatedMessage,
  CustomFileType,
} from "../../../../types/Chat/ChatMutatedMessage";

import { Avatar, MentionDisplay } from "../..";

import { ChatConversationReadBadges } from "./ChatConversationReadBadges";
import { ChatConversationFileMessage } from "./ChatConversationFileMessage";

export type ChatConversationOutgoingMessageProps = {
  file?: CustomFileType;
  image?: CustomFileType;
  linksMentions: MentionLink[];
  mentions: MentionUser[];
  message: ChatMutatedMessage;

  onImageLoad?: () => void;
};

export const ChatConversationOutgoingMessage: FC<ChatConversationOutgoingMessageProps> = ({
  file,
  image,
  linksMentions,
  mentions,
  message,

  onImageLoad,
}) => {
  return (
    <div
      className={
        message.messageType === "user"
          ? "sendbird-message--incoming sendbird-message"
          : file
          ? "sendbird-file-message--icoming"
          : "sendbird-thumbnail sendbird-thumbnail--incoming"
      }>
      <div
        className={
          message.messageType === "user"
            ? "sendbird-user-message--incoming"
            : file
            ? "sendbird-file-message__incoming"
            : "d-inline-block"
        }>
        {/*<div className="sendbird-user-message--incoming">*/}
        <div
          className={
            "sendbird-" +
            (message.messageType === "user"
              ? "user-message"
              : file
              ? "file-message"
              : "thumbnail") +
            "__avatar sendbird-avatar"
          }>
          <Avatar
            name={message._sender?.nickname}
            round={true}
            src={message._sender?.profileUrl}
            id={message._sender?.userId.split("_").pop()}
            size="30"
          />
        </div>
        <div>
          <span className="text-small text-black-50 font-weight-bold">
            {message._sender?.nickname}
          </span>
          <span className="text-black-50 text-small ml-2">
            {`${displayDate(message.createdAt, "hh:mm A")} ${
              message.isEdited ? "(edited)" : ""
            }`}
          </span>
        </div>
        {message.messageType === "user" ? (
          <div
            className="font-weight-light chat-font-size py-1 px-3 rounded-xl"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
            <MentionDisplay
              text={cleanHTMLTags(message.message || "")}
              allowRenderVideo={true}
              mentions={mentions}
              linkMentions={linksMentions}
            />
          </div>
        ) : (
          <ChatConversationFileMessage
            onImageLoad={onImageLoad}
            file={file}
            image={image}
          />
        )}
        {message.readReceipts && (
          <ChatConversationReadBadges
            readReceipts={message.readReceipts}
            position={{
              left: "20px",
            }}
          />
        )}
      </div>
    </div>
  );
};
