import _ from "underscore";

import { runFunction } from "../../http";

export const addAnnouncementReadBy = async (
  id: string,
  uid: string
): Promise<void> => {
  console.debug("[ServiceApi] addAnnouncementReadBy");

  return await runFunction(
    "addAnnouncementReadBy",
    {
      announcementId: id,
      targetUser: uid,
    },
    { requireUser: _.isEmpty(uid) }
  );
};
