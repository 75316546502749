import React from "react";

import { useTranslation } from "react-i18next";
import { Modal, ModalHeader } from "reactstrap";

import GroupSettingContainer from "../../../../containers/Group/Setting";
import { Callback } from "../../../../types";
import { GroupSetting } from ".";

type GroupSettingModalProps = {
  id: string;
  isOpen: boolean;
  toggle: Callback;
};

export const GroupSettingModal: React.FC<GroupSettingModalProps> = ({
  id,
  isOpen,
  toggle,
}) => {
  const { t } = useTranslation("Group");
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle} id="groupSettingHeader">
        {t("GroupDetailModal.Heading")}
      </ModalHeader>
      <GroupSettingContainer Layout={GroupSetting} match={{ params: { id } }} />
    </Modal>
  );
};
