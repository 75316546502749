import { Dict, Member } from "../../../../types";
import { customProfile } from "./customProfile";
import { getPartnerProfile } from "../../User";

export const customProfileHasPartner = async (
  member: Member,
  groups: Dict<{
    name: string;
    objectId?: string;
  }> = {}
): Promise<Member> => {
  const customUser = customProfile(member, groups);

  if (member.profile) {
    const partnerProfile = await getPartnerProfile(member.profile.objectId);
    if (partnerProfile) {
      // eslint-disable-next-line require-atomic-updates
      member.partnerProfile = partnerProfile;
    }
  }

  return customUser;
};
