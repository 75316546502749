import React from "react";

import { useHistory } from "react-router-dom";
import { Alert, Card } from "reactstrap";
import { reduce } from "underscore";

import MediaPlayerContainer from "../../../containers/Media";
import { track } from "../../../lib/track";
import { getEventLocation, toCapitalizeCase } from "../../../lib/utils";
import { EventRecap } from "../../../types/Event/EventRecap";
import {
  CommentType,
  EventAttendeeAdditionalData,
  EventInventory,
  EventItem,
  ReactionType,
  UserDataState,
  UserResponse,
} from "../../../types";
import { textThemingWorkaround } from "../../../constants/Theme";
import { DocumentTitle } from "../../../containers/routers/DocumentTitle";

import { useTheme } from "../../hooks";

import { Media } from "../Media";
import { Heading, LoadingSpinner } from "../UI";

import { EventDetailTabs } from ".";

export type EventDetailProps = {
  error: string | null;
  info?: string;
  // refreshing: boolean;
  sending?: boolean;
  user: UserDataState | { [key in keyof UserDataState]: undefined };
  setting: {
    eventChatId?: string | false;
    ClientHostName: string;
    rsvpEnabled: boolean;
    paymentEnabled: boolean;
    checkPayableTo: string;
    checkSendTo: string;
    invoiceFooterTitle: string;
    invoiceFooterBody: string;
    stripeCredentials: {
      account_id: string;
      publishable_key: string;
      account_registered: boolean;
    };
    googleApi: string;
    confettiEffectActions: [];
  };
  item?: EventItem;
  recap: EventRecap | null;
  reFetch: (refresh: boolean, callback?: () => void) => void;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  reaction?: ReactionType;
  attendee: EventAttendeeAdditionalData;
  guest: {
    guestMembers: {
      objectId: string;
    }[];
    guests: (string | UserDataState)[];
  };
  partner: {
    partnerMembers: {
      objectId: string;
    }[];
    partners: (string | UserDataState)[] | null;
  };
  currentPartner: {};
  inventories: {
    [key: string]: EventInventory;
  } | null;
  showGuestEditingModal: boolean;
  changeAttendee: <T extends keyof EventAttendeeAdditionalData>(
    name: T,
    value: EventAttendeeAdditionalData[T]
  ) => void;
  changePartner: (partnerResponse: UserResponse) => void;
  updateAttendee: (callback: () => void) => Promise<void>;
  changeInfo: (info: string) => void;
  payForEvent: (
    payByCreditCard: boolean,
    token: string,
    callback: () => void,
    fallback: (error: string) => void
  ) => Promise<void>;
  openGuestEditingModal: () => void;
  changeGuest: (guest: {
    guestMembers: {
      objectId: string;
    }[];
    guests: UserDataState[];
  }) => void;
  getPaymentFee: (total: number) => Promise<unknown>;
  hideChat: boolean;
};

export const EventDetail: React.FC<EventDetailProps> = ({
  error,
  hideChat,
  item,
  recap,
  setting,
  user,
  doReaction,
}) => {
  const history = useHistory();

  const {
    card,
    image,
    text: { header },
  } = useTheme();

  if (!item) {
    if (error) {
      return <Alert color="danger">{error}</Alert>;
    }

    return <LoadingSpinner />;
  }

  const {
    event,
    readHeadlineBy,
    isLiked,
    myAttendee,
    attendees,
    photos,
    reaction,
  } = item;

  const {
    city,
    commentCount,
    country,
    id,
    isPaid,
    items,
    likeCount,
    reactionCount,
    state,
    streetAddress1,
    thumbUrl,
    title,
    venue,
    video,
  } = event;

  const { ClientHostName: clientHostName, confettiEffectActions } = setting;

  const totalFees = !isPaid
    ? 0
    : reduce(
        items,
        (memo, value) =>
          value.required
            ? memo + (value.options[0].price || value.price || 0)
            : memo,
        0
      );

  const onAttendeeClick = (): void => {
    history.push(`/event-register/${id}`);

    /** @TODO This logic should be moved to container */
    track("Click RSVP", {
      "Event ID": id,
      "Event Title": title,
    });
  };

  const eventLocation = getEventLocation({
    city,
    country,
    state,
    streetAddress1,
    venue,
  });

  const openEventAttendeesMap = (): void => {
    if (eventLocation) {
      history.push(
        `/event-detail/${id}/attendees-map/${eventLocation?.address.replace(
          "/",
          "%2F"
        ) || eventLocation?.venue}`
      );
      /** @TODO This logic should be moved to container */
      track("Click Attendee Location", {
        "Event ID": id,
        "Event Title": title,
      });
    } else {
      history.push(`/event-detail/${id}/attendees-map`);
    }
  };

  const eventTitle = toCapitalizeCase(recap?.title || title);

  const eventDetailStyles = {
    card: { ...card, padding: 0 },
    header: {
      ...header,
      ...textThemingWorkaround,
    },
  };

  const headerElement = (
    <Heading className="pt-3 pb-0 px-3" tag="div">
      <h5
        style={eventDetailStyles.header}
        id={`eventDetailTitle${id}`}
        className="my-0">
        {eventTitle}
      </h5>
    </Heading>
  );

  return (
    <>
      <DocumentTitle pageTitle={eventTitle} />
      <Card
        id={`eventDetailCard${id}`}
        className="mb-3"
        style={eventDetailStyles.card}>
        <MediaPlayerContainer
          video={video}
          pauseWhenInvisible={true}
          style={image}
          Layout={Media}
          image={{ thumbUrl }}
          alt={eventTitle}
          resizeMode="cover"
        />
        {headerElement}
        <EventDetailTabs
          attendees={attendees}
          clientHostName={clientHostName}
          commentCount={commentCount}
          confettiEffectActions={confettiEffectActions}
          event={event}
          eventLocation={eventLocation}
          hideChat={hideChat}
          isEditor={!!(user.admin || user.editor)}
          isLiked={!!isLiked}
          likeCount={likeCount}
          myAttendee={myAttendee}
          photos={photos}
          reactionCount={reactionCount}
          reaction={reaction}
          recap={recap}
          seenCount={readHeadlineBy}
          setting={setting}
          totalFees={totalFees}
          doReaction={doReaction}
          onAttendeeClick={onAttendeeClick}
          openEventAttendeesMap={openEventAttendeesMap}
        />
      </Card>
    </>
  );
};
