import React, { useState } from "react";
import { Tooltip } from "reactstrap";

interface Props {
  disabled?: boolean;
  id: string;
  position:
    | "top"
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
  title?: string;
}
export const TooltipItem: React.FC<Props> = props => {
  const { disabled, position, id, title } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = (): void => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <span id={"tooltip-" + id.split(" ").join("")}>{props.children}</span>
      {!disabled && (
        <Tooltip
          placement={position}
          isOpen={tooltipOpen}
          target={"tooltip-" + id.split(" ").join("")}
          toggle={toggle}>
          {title}
        </Tooltip>
      )}
    </span>
  );
};
