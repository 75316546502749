import React from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../hooks";

export type EmptyProps = {
  className?: string;
  id?: string;
  text?: string;
};

export const Empty: React.FC<EmptyProps> = ({ className, id, text }) => {
  const { t } = useTranslation("UI");
  const {
    divider: {
      backgroundColor,
      text: { color },
    },
  } = useTheme();
  const style = {
    backgroundColor,
    color,
    fontSize: 13,
  };
  return (
    <div
      id={id || "emptyNoData"}
      className={classnames("text-center pt-2", className)}
      style={style}>
      {text || t("Empty.No.Data")}
    </div>
  );
};
