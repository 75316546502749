import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card, Media } from "reactstrap";

type Props = {};

export const ResourceCardPlaceholder: React.FC<Props> = () => {
  return (
    <div>
      {[1, 2, 3].map(i => (
        <Card key={i} className="border-bottom p-2 mb-2 rounded">
          <Media>
            <Media left bottom className="mr-2">
              <Skeleton width={30} height={30} />
            </Media>
            <Media body className="px-2 pt-2 text-truncate">
              <h6 className="mb-0">
                <Skeleton />
              </h6>
            </Media>
          </Media>
        </Card>
      ))}
    </div>
  );
};
