import React, { Component } from "react";

import { connect } from "react-redux";
import {
  AnnouncementItem,
  Callback,
  CommentType,
  ReactionType,
  RootState,
} from "../../types";

import { doReaction } from "../Comment/action";
import { previewAnnouncement } from "../Announcement/action";

export type PreviewContainerLayoutProps = {
  /** Disables any reaction/comment/link in CommentBar */
  disabledCommentBar?: boolean;
  error: string | null;
  item: AnnouncementItem | null;
  refreshing: boolean;
  setting: {
    ClientHostName?: string;
    confettiEffectActions: [];
  };
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  reFetch: (refresh: boolean, callback?: Callback) => void;
};

export type PreviewContainerOwnProps = {
  Layout: React.ReactType<PreviewContainerLayoutProps>;
  id: string;
  type: "announcement";
};

export type PreviewContainerStateProps = {
  item: AnnouncementItem | null;
};

export type PreviewContainerDispatchProps = {
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  getAnnouncement: (id: string) => Promise<void>;
};

export type PreviewContainerProps = PreviewContainerOwnProps &
  PreviewContainerStateProps &
  PreviewContainerDispatchProps;

type PreviewContainerState = {
  error: string | null;
  refreshing: boolean;
};

class PreviewContainer extends Component<
  PreviewContainerProps,
  PreviewContainerState
> {
  state: PreviewContainerState = {
    error: null,
    refreshing: false,
  };

  componentDidMount = (): void => {
    this.fetchData(true);
  };

  fetchData = (refresh: boolean, callback?: Callback): void => {
    const { getAnnouncement, id, type } = this.props;
    const { refreshing } = this.state;

    if (refreshing) {
      return;
    }

    this.setState({ refreshing: refresh }, async () => {
      try {
        if (type === "announcement") {
          await getAnnouncement(id);
        }

        this.setState({ refreshing: false, error: null });

        callback?.();
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, item, doReaction } = this.props;
    const { error, refreshing } = this.state;

    return (
      <Layout
        disabledCommentBar={true}
        error={error}
        item={item}
        refreshing={refreshing}
        setting={{
          confettiEffectActions: [],
        }}
        doReaction={doReaction}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: PreviewContainerOwnProps
): PreviewContainerStateProps => {
  const id = ownProps.id;
  const { cached } = state.announcement;
  const item = cached[id] || null;

  return {
    item,
  };
};

const mapDispatchToProps = {
  getAnnouncement: previewAnnouncement,
  doReaction: doReaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContainer);
