import React from "react";

import { ModalProps, Modal, ModalHeader, ModalBody } from "reactstrap";

import SupportContainer from "../../../containers/Support";

import { Override } from "../../../types";

import { Support } from "./Support";
import { useTranslation } from "react-i18next";

export type SupportModalProps = Override<
  ModalProps,
  {
    toggle?: () => void;
  }
>;

export const SupportModal: React.FC<SupportModalProps> = props => {
  const { t } = useTranslation("Support");

  return (
    <Modal {...props} id="supportModal">
      <ModalHeader toggle={props.toggle}>
        {t("SupportModal.Header.Text")}
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#fff" }}>
        <SupportContainer
          Layout={Support}
          onSendSupportSuccess={props.toggle}
        />
      </ModalBody>
    </Modal>
  );
};
