import { AnnouncementState } from "../../types";

export const announcementStateInit: AnnouncementState = {
  pins: null,
  types: null,
  history: {},
  list: {
    items: null,
    cached: {},
    page: 0,
    hasMore: true,
  },
  searchList: {
    items: null,
    /** @TODO Please review carefully */
    // cached: {},
    page: 0,
    hasMore: true,
  },
  publishedList: {
    items: null,
    page: 0,
    hasMore: true,
  },
  draftList: {
    items: null,
    page: 0,
    hasMore: true,
  },
  cached: {},
};
