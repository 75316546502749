import { createSelector } from "reselect";
import { RootState, SettingState } from "../../types";

// selector

const getSetting = (state: RootState): SettingState => state.setting;

// reselect function

export const getSettingState = createSelector([getSetting], setting => setting);
