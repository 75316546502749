import React, { useState, useEffect } from "react";

import Cards, { CallbackArgument, Focused } from "react-credit-cards";
import "react-credit-cards/lib/styles.scss";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { PaymentSetting } from "../../../../containers/Payment/types";
import { track } from "../../../../lib/track";
import {
  currencyFormat,
  displayDate,
  logException,
} from "../../../../lib/utils";
import { Callback } from "../../../../types";

import { PayExpirationDateValidator } from "./PayExpirationDateValidator";
import { PayCvcValidator } from "./PayCvcValidator";
import Stripe from "../../../../services/api/Stripe";

type PayProps = {
  creditCardOnly?: boolean;
  invoice: {
    dueDate?: Parse.Object | string | null;
    id?: string;
    objectId?: string;
    parseId: string;
    description?: string;
    total: number;
    isFeePayable: boolean | string;
  };
  setting: PaymentSetting;
  isPayModalOpen: boolean;
  togglePayModal: Callback;
  /**@FIXME
   * type of Promise
   */
  getPaymentFee?: (total: number) => Promise<any>;
  onProcess: (
    payByCreditCard: boolean,
    token: string,
    callback: () => void
  ) => void;
};

type HTMLCCInputElement = HTMLInputElement & { name: Focused };

export const Pay: React.FC<PayProps> = ({
  creditCardOnly,
  invoice,
  isPayModalOpen,
  setting,

  getPaymentFee,
  onProcess,
  togglePayModal,
}) => {
  const { t } = useTranslation("Payment");

  const { dueDate, id, objectId, parseId, description, total, isFeePayable } =
    invoice || {};

  const { checkPayableTo, checkSendTo } = setting;

  const [focused, setFocused] = useState<Focused>("number");

  const [fee, setFee] = useState(0);

  const [expiry, setExpiry] = useState("");

  const [isExpiryValid, setExpiryValid] = useState(false);

  const [cvc, setCvc] = useState("");

  const [isCvc, setCvcValid] = useState(false);

  const [isCreditCard, setCreditCard] = useState(true);

  const [sending, setSending] = useState(false);

  const [cardNumber, setCardNumber] = useState("");

  const [isCardNumberValid, setCardNumberValid] = useState(false);

  useEffect(() => {
    if (isFeePayable && getPaymentFee) {
      getPaymentFee(total).then(resp => {
        if (resp.data) {
          setFee(resp.data.fee);
        }
      });
    }
    track("Open Payment", {
      Invoice: objectId,
      ParseId: parseId,
      Description: description,
      Amount: total,
    });

    if (expiry.match(/^[2-9]$/)) {
      setExpiry(`0${expiry}`);
    }

    if (PayExpirationDateValidator(expiry)) {
      setExpiryValid(true);
    } else {
      setExpiryValid(false);
    }

    if (PayCvcValidator(cvc, [3, 4])) {
      setCvcValid(true);
    } else {
      setCvcValid(false);
    }
  }, [
    isFeePayable,
    getPaymentFee,
    objectId,
    parseId,
    description,
    total,
    expiry,
    cvc,
  ]);

  const handleInputFocus = (
    event: React.FocusEvent<HTMLCCInputElement>
  ): void => {
    setFocused(event.target.name);
  };

  const toggleIsCreditCard = (state: boolean) => (): void => {
    setCreditCard(state);
  };

  const toggleSending = (state: boolean): void => {
    setSending(state);
  };

  const handleCardNumberChange = (
    event: React.ChangeEvent<HTMLCCInputElement>
  ): void => {
    const reg = /^[0-9\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      setCardNumber(event.target.value);
    }
  };

  const handleCallback = (type: CallbackArgument, isValid: boolean): void => {
    setCardNumberValid(isValid);
  };

  const handleExpiryChange = (
    event: React.ChangeEvent<HTMLCCInputElement>
  ): void => {
    const reg = /^[0-9/\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      setExpiry(
        event.target.value
          .replace(/^(\d\d)(\d)$/g, "$1/$2")
          .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
      );
    }
  };

  const handleCvcChange = (
    event: React.ChangeEvent<HTMLCCInputElement>
  ): void => {
    const reg = /^[0-9\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      setCvc(event.target.value);
    }
  };

  const feeText = fee > 0 ? `(${t("Pay.Fees")} $${currencyFormat(fee)})` : "";

  const hasError = (error: string): string => {
    return error;
  };

  const onTrack = (event: string, error?: string): void => {
    track(event, {
      Invoice: id || objectId,
      ParseId: parseId,
      Description: description,
      Amount: total,
      Error: error,
    });
  };

  const onCreditCardPay = (): any => {
    onTrack("Clicked on payment by credit card");

    try {
      toggleSending(true);
      const publishableKey = setting?.stripeCredentials?.publishable_key;
      const stripeClient = new Stripe(publishableKey);

      stripeClient
        .createToken({
          number: cardNumber,
          cvc: cvc,
          // eslint-disable-next-line @typescript-eslint/camelcase
          exp_month: expiry.split("/")[0],
          // eslint-disable-next-line @typescript-eslint/camelcase
          exp_year: expiry.split("/")[1],
        })
        .then(token => {
          onProcess(true, token.id, () => {
            toggleSending(false);
            togglePayModal();
            onTrack("Payment success");
          });
        })
        .catch((error: string) => {
          toggleSending(false);
          hasError(error);
          onTrack("Payment fail", error);
        });
    } catch (error) {
      logException(error);
      hasError(t("Pay.Error"));
      toggleSending(false);
    }
  };

  const onCheckPay = (): void => {
    onTrack("Clicked on payment by check");

    try {
      toggleSending(true);
      onProcess?.(false, "", () => {
        toggleSending(false);
        hasError("");
        onTrack("Payment successfully");
      });
    } catch (error) {
      logException(error);
      hasError(t("Pay.Error"));
      toggleSending(false);
      onTrack("Payment fail", error);
    }
  };

  const paymentOptionsElement = !creditCardOnly && (
    <>
      <Row className={isCreditCard ? "mb-4" : "mb-1"}>
        <Col className="pr-1">
          <Button
            color={isCreditCard ? "primary" : "secondary"}
            className="mr-n2"
            block
            onClick={toggleIsCreditCard(true)}>
            {t("Pay.Credit.Card")}
          </Button>
        </Col>
        <Col className="pl-1">
          <Button
            color={!isCreditCard ? "primary" : "secondary"}
            block
            onClick={toggleIsCreditCard(false)}>
            {t("Pay.Check")}
          </Button>
        </Col>
      </Row>
    </>
  );

  const payByCreditCardElement = isCreditCard && (
    <>
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focused}
        name={""}
        number={cardNumber}
        placeholders={{ name: "" }}
        callback={handleCallback}
      />
      <Form>
        <FormGroup className="mt-5 p-1">
          <Input
            type="text"
            name="number"
            className="form-control"
            placeholder={t("Pay.Placeholder.Text.Card")}
            required
            invalid={!isCardNumberValid}
            value={cardNumber}
            maxLength={19}
            onChange={handleCardNumberChange}
            onFocus={handleInputFocus}
          />
        </FormGroup>
        <Row className="p-1">
          <Col>
            <FormGroup>
              <Input
                type="text"
                name="expiry"
                className="form-control"
                placeholder={t("Pay.Placeholder.Text.Valid")}
                required
                invalid={!isExpiryValid}
                value={expiry}
                maxLength={5}
                onChange={handleExpiryChange}
                onFocus={handleInputFocus}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Input
                type="text"
                name="cvc"
                className="form-control"
                placeholder={t("Pay.Placeholder.Text.Cvc")}
                required
                invalid={!isCvc}
                value={cvc}
                maxLength={4}
                onChange={handleCvcChange}
                onFocus={handleInputFocus}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-3 p-3">
          <Button
            onClick={onCreditCardPay}
            color="primary"
            disabled={sending || !isCardNumberValid || !isExpiryValid || !isCvc}
            block>
            {sending
              ? t("Pay.Paying")
              : `${t("Pay.Pay")} $${currencyFormat(total + fee)} ${feeText}`}
          </Button>
        </Row>
      </Form>
    </>
  );

  const payByCheckElement = (
    <>
      {!isCreditCard && (
        <Card>
          <CardBody>
            <Row className="my-2 text-secondary">
              <h5>{t("Pay.Heading.Pay.Instruction")}</h5>
            </Row>
            <Row className="mt-4 mb-n2 font-weight-bold">
              <p>{t("Pay.Heading.Checks.To")}:</p>
            </Row>
            <Row>{checkPayableTo}</Row>
            <Row className="mt-4 mb-n2 font-weight-bold">
              <p>{t("Pay.Heading.Checks.Send.To")}:</p>
            </Row>
            <Row>{checkSendTo}</Row>
            <Row className="mt-4 mb-n2 font-weight-bold">
              <p>{t("Pay.Heading.Checks.Send.By")}:</p>
            </Row>
            <Row>{displayDate(dueDate, "MM-DD-YY")}</Row>
            <Row>
              <Button
                className="mt-3"
                onClick={onCheckPay}
                color="primary"
                block>
                {sending
                  ? `${t("Pay.Checks.Confirming")}`
                  : `${t("Pay.Checks.Confirm")}`}
              </Button>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );

  return (
    /**@TODO
     * Modal with fixed height for both credit card and check payment
     */
    <Modal
      isOpen={isPayModalOpen}
      toggle={togglePayModal}
      centered
      className="mt-4">
      <ModalHeader toggle={togglePayModal}>
        {creditCardOnly ? t("Pay.Credit.Card.Details") : t("Pay.Card.Heading")}
      </ModalHeader>
      <ModalBody>
        {paymentOptionsElement}
        {payByCreditCardElement}
        {payByCheckElement}
      </ModalBody>
    </Modal>
  );
};
