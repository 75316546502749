import { runFunction } from "../../http";
import { convertObject } from "../../../lib/utils";
import { Video } from "../../../types/VideoLibrary/Video";

export const addVideoToLibrary = async (
  video: Video,
  checkURL: boolean
): Promise<Video> => {
  console.debug("[Service] addVideoToLibrary", video);
  const resp = await runFunction(
    "addVideoToLibrary",
    { data: video, checkURL },
    { requireUser: true }
  );
  return { ...convertObject(resp), fromLibrary: true };
};

export const addVideoLink = async (video: Video): Promise<{ data: Video }> => {
  console.debug("[Service] addVideoLink", video);
  const data = await addVideoToLibrary(video, true);
  return { data };
};
