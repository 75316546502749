import React, { useContext } from "react";

import { useTypedSelector } from "../../web/hooks";
import { store } from "../../store/configureStore";
import { ConfigContext } from "../../web/context";

export type notificationsCounterProps = {
  pageTitle?: string;
};

export const DocumentTitle: React.FC<notificationsCounterProps> = ({
  pageTitle,
}) => {
  const { navigationFeatures, navigationFeaturesMore } = useContext(
    ConfigContext
  );

  const pathname = window.location.pathname;

  const navigation =
    navigationFeatures.find(item => item.to === pathname) ||
    navigationFeaturesMore.find(item => pathname.includes(item.to as string));

  const { unread } = useTypedSelector(state => state.notification);
  const organizationName = store.getState().setting.config?.organizationName;
  const isLoggedIn = store.getState().user.data;

  const getAllNotificationsCount = (): number => {
    if (!unread) {
      return 0;
    }
    const notificationsNumber = unread["notifications"]?.length || 0;
    const chatNotificationsNumber = unread["chat-room"]?.length || 0;
    return notificationsNumber + chatNotificationsNumber;
  };

  let documentTitle =
    navigation?.title || pageTitle || organizationName || "Group Fire";
  if (isLoggedIn) {
    const countAll = getAllNotificationsCount();

    countAll > 0 && (documentTitle = `(${countAll}) ${documentTitle}`);
  }
  document.title = "Group Fire";
  documentTitle === organizationName
    ? (document.title = documentTitle)
    : (document.title = `${documentTitle} | ${organizationName}`);

  return <></>;
};
