import { MutableRefObject, useEffect, useRef } from "react";

export const useClickOutsideRef = (
  callback: () => void
): MutableRefObject<any> => {
  const elementRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [elementRef, callback]);

  return elementRef;
};
