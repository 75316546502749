import _ from "underscore";
import { runFunction } from "../../http";
import { convertObject, displayDate } from "../../../lib/utils";
import { EventItem } from "../../../types/Event/EventItem";
import { Dict, Member } from "../../../types";
import { EventInventory } from "../../../types/Event/EventInventory";
import { getEventInventory } from "../Event";
import { EventAttendee } from "../../../types/Event/EventAttendee";
import { customProfile } from "../Member/utils";

export const getEvent = async (
  id?: string,
  options?: { [key: string]: boolean } | { userId?: string }
): Promise<EventItem> => {
  const option = Object.assign(options, { id });
  const parseResponse = await runFunction("getEvent", option, {
    requireUser: true,
  });
  const resp = convertObject(parseResponse);

  let data = resp;

  //get event inventory status
  const inventoryData: Dict<EventInventory>[] = await getEventInventory(id);

  if (!_.isEmpty(resp)) {
    const item: EventItem = {
      ...resp,
      id: resp.event.id as string,
      month: displayDate(resp.event.startAt, "MMMM YYYY", resp.event.timezone),
      inventories: inventoryData,
      guestMembers: [],
    };

    if (item.attendees && item.attendees.all) {
      item.attendees.all = item.attendees.all.map((attendee: EventAttendee) => {
        if (attendee.user) {
          /**
           * @TODO Fix types during customProfile refactor (not mutating args etc.)
           */
          attendee.user = customProfile(attendee.user) as Member;
        }
        return attendee;
      });
    }
    data = Object.assign({}, item, { id });
  }
  return data;
};
