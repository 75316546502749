module.exports = {
  p: {
    paddingVertical: 10,
  },
  h1: {
    fontSize: 32,
    lineHeight: 37,
    fontWeight: "700",
  },
  h2: {
    fontSize: 30,
    lineHeight: 35,
    fontWeight: "600",
  },
  h3: {
    fontSize: 28,
    lineHeight: 33,
    fontWeight: "600",
  },
  h4: {
    fontSize: 22,
    lineHeight: 25,
    fontWeight: "600",
  },
  pre: {
    marginVertical: 10,
  },
  blockquote: {
    marginVertical: 10,
    color: "#5e35b1",
    borderLeftWidth: 5,
    borderLeftColor: "#5e35b1",
    paddingLeft: 10,
    fontSize: 16,
  },
  ol: {
    marginVertical: 5,
    marginLeft: 10,
  },
  ul: {
    marginVertical: 5,
    marginLeft: 10,
  },
  li: {
    paddingVertical: 10,
  },
  correctHTMLData: (body = "") => {
    return body.split(/<\/ol><ol start="[0-9]*">/).join("<br/>");
  },
};
