import React, { useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import { Tag } from "../../../../types";

import { TagList } from "..";
import { TagInputSuggestion } from ".";

export type TagInputProps = {
  id?: string;
  className?: string;
  tags?: string[];
  tagsSuggestion: Tag[] | null;

  onChangeTags: (tags: string[]) => void;
  fetchTagsSuggestion: (query: string) => void;
};

/**
 * @TODO
 * Implement search by tag when Search component will be ready
 */
export const TagInput: React.FC<TagInputProps> = ({
  id,
  className,
  tags = [],
  tagsSuggestion,

  onChangeTags,
  fetchTagsSuggestion,
}) => {
  const { t } = useTranslation("Tag");

  const [tagInputValue, setTagInputValue] = useState("");

  const handleTagInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.target.value;

    if (value.length > 50) return;

    if (value.length >= 3) {
      fetchTagsSuggestion(value);
    }

    setTagInputValue(value);
  };

  const handleTagInputKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    [",", "."].includes(event.key) && handleAddTag(tagInputValue);
  };

  const handleAddTag = (tagName: string): void => {
    const newTag = tagName.replace(/[,.#]/g, "");

    if (newTag === "") {
      return;
    }

    /**
     * @FIXME
     * Duplicates validation should be moved to the container
     */
    if (tags.includes(newTag)) {
      setTagInputValue("");

      return;
    }

    /**
     * @FIXME
     * Tag combining logic should be moved to the container.
     */
    onChangeTags([...tags, newTag]);

    setTagInputValue("");
  };

  const handleRemoveTag = (index: number): void => {
    /**
     * @FIXME
     * Tag combining logic should be moved to the container. There should
     * be another handler for removing tag.
     */
    const newTags = tags;
    newTags.splice(index, 1);

    onChangeTags(newTags);
  };

  const handleClickTag = (index: number): void => {
    setTagInputValue(tags[index]);
    handleRemoveTag(index);
  };

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    handleAddTag(tagInputValue);
  };

  return (
    <form className={classnames(className)} onSubmit={handleSubmit}>
      <TagList
        data={tags}
        onRemove={handleRemoveTag}
        onClick={handleClickTag}
      />
      <Input
        id={id}
        type="text"
        className="border mb-2"
        rows={1}
        value={tagInputValue}
        onChange={handleTagInputChange}
        placeholder={t("Tags.Placeholder.Input.Text")}
        onKeyUp={handleTagInputKeyUp}
      />
      {tagInputValue.length >= 3 && (
        <TagInputSuggestion
          onTagClick={handleAddTag}
          tagsSuggestion={tagsSuggestion}
        />
      )}
    </form>
  );
};
