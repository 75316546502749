import {
  Button,
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import React, { ChangeEvent, FC } from "react";

type Props = {
  closeEditChannel: () => void;
  channelName?: string;
  changeChannelName: (event: ChangeEvent<HTMLInputElement>) => void;
  updateChannel: (e: any) => void;
};
export const SettingsUpdateName: FC<Props> = ({
  closeEditChannel,
  channelName,
  changeChannelName,
  updateChannel,
}) => {
  return (
    <>
      <ModalHeader toggle={closeEditChannel}>Edit Channel Name</ModalHeader>
      <ModalBody>
        <Form onSubmit={updateChannel}>
          <FormGroup>
            <Input
              type="text"
              name="name"
              value={channelName}
              onChange={changeChannelName}
            />
          </FormGroup>
          <Button color="primary" className="float-right m-2">
            Submit
          </Button>
        </Form>
      </ModalBody>{" "}
    </>
  );
};
