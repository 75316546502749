import React from "react";

import { reactionImages } from "../../../constants";
import { UserDataState, ReactionType } from "../../../types";

import { useAnchorProps, useTheme } from "../../hooks";

import { Avatar } from "..";

export type CommentUserListCardProps = {
  style?: React.CSSProperties;
  type?: ReactionType;
  user: UserDataState;
};

export const CommentUserListCard: React.FC<CommentUserListCardProps> = ({
  style,
  type,
  user,
}) => {
  const {
    list: { backgroundColor },
  } = useTheme();
  const styles = {
    listItem: { backgroundColor },
  };

  const name = `${user.firstName} ${user.lastName}`;

  const imageUrl = user.profile?.thumbUrl ?? undefined;

  const memberAnchorProps = useAnchorProps(
    `/member/${user.id || user.objectId}`
  );

  const iconStyles = {
    height: "20px",
    width: "20px",
  } as const;

  const rightIcon = type && (
    <div className="ml-auto d-flex">
      <img
        className="m-2 d-block align-self-center"
        alt={type}
        src={reactionImages.image[type]}
        id={user.id || user.objectId + type}
        style={iconStyles}
      />
    </div>
  );

  return (
    <div
      id={`commentSeenByUser${user.id || user.objectId}`}
      className="d-flex position-relative mb-1"
      style={styles.listItem}>
      <div>
        <a
          {...memberAnchorProps}
          className="d-flex align-self-center h-100 stretched-link">
          <Avatar
            src={imageUrl}
            name={name}
            round={true}
            size="35"
            className="d-block m-2 align-self-center"
          />
        </a>
      </div>
      <div className="ml-3 mt-1 mb-2 d-flex">
        <p className="align-self-center my-0">{name}</p>
      </div>
      {rightIcon}
    </div>
  );
};
