import { SubscriptionState } from "./types";

export const postStateInit: SubscriptionState = {
  products: null,
  subscriptions: null,
  denySubscription: false,
  shownSubscriptionDialog: false,
};

export default postStateInit;
