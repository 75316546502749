import _ from "underscore";

import { logException } from "../../lib/utils";
import {
  addFollows as _addFollows,
  exportMembers as _exportMembers,
  ExportMembersResult,
  getGroupMembers as _getGroupMembers,
  getMember as _getMember,
  getMemberByProfile as _getMemberByProfile,
  getMembers as _getMembers,
  getMembersByGroups as _getMembersByGroups,
  removeFollows as _removeFollows,
} from "../../services/api";
import {
  AppThunkAction,
  AppThunkDispatch,
  Dict,
  GetState,
  Member,
  UserDataState,
} from "../../types";

import {
  MEMBER_ALL_PRE_LOADING_SET,
  MEMBER_ALL_SET,
  MEMBER_GROUP_SET,
  MEMBER_SET,
} from "./constants";
import i18n from "../../middlewares/i18next";
import { isWebPlatform } from "../../web/services/isWebPlatform";
import { getCurrentUser } from "../User/action";

export type GetMembersActionReturn = { data: Dict<Member> | null };

/**
 * Get all members for directory
 * @param options
 * @param refresh
 * @param preLoading
 * @param shouldStartRefreshing
 */
export function getMembers(
  options?: {},
  refresh?: boolean,
  preLoading?: boolean
): AppThunkAction<GetMembersActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetMembersActionReturn> => {
    console.debug("[Action] getMembers", { options, refresh, preLoading });

    try {
      const { member, setting } = getState();
      const { items, preLoadingStatus } = member;

      if (!refresh && !_.isEmpty(items)) {
        return { data: items };
      }

      //check if preLoadingListMembers is enabled, load members by paging instead of all members
      const allowPreloading =
        preLoading &&
        setting.config?.preLoadingListMembers &&
        preLoadingStatus === "none" &&
        !isWebPlatform();
      if (allowPreloading) {
        options = {
          page: 0,
          per: setting.config?.preLoadingListMembersSizing || 100,
        };
      }

      const data = await _getMembers(options);

      dispatch({
        type: MEMBER_ALL_PRE_LOADING_SET,
        data: allowPreloading ? "loaded" : "done",
      });

      dispatch({
        type: MEMBER_ALL_SET,
        data: data,
      });

      return {
        data: data,
      };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type GetMemberActionReturn = {
  data: { id: string; data: Member };
};

/**
 * Get member detail
 * @param userId
 * @param refresh
 */
export function getMember(
  userId: string,
  refresh: boolean
): AppThunkAction<GetMemberActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetMemberActionReturn> => {
    console.debug("[Action] getMember");

    try {
      const { member, user } = getState();

      const cachedData =
        member.cached[userId] || (member.items && member.items[userId]);

      if (!refresh && cachedData) {
        const resp = { id: userId, data: cachedData };
        dispatch({
          type: MEMBER_SET,
          data: resp,
        });

        return {
          data: resp,
        };
      }

      const data = await _getMember(userId, user?.data);

      if (data) {
        const resp = { id: userId, data: data };
        dispatch({
          type: MEMBER_SET,
          data: resp,
        });

        return {
          data: resp,
        };
      } else {
        throw Error(i18n.t("Member:Container.Action.Error"));
      }
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type GetMemberByProfileActionReturn = {
  data: { id: string; data: Member };
};

/**
 * Get member detail by profile, somewhere in old app are using profileId instead of userId
 * @param profileId
 * @param refresh
 */
export function getMemberByProfile(
  profileId: string,
  refresh: boolean
): AppThunkAction<GetMemberByProfileActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetMemberByProfileActionReturn> => {
    console.debug("[Action] getMemberByProfile", profileId);

    try {
      const { user } = getState();

      const data = await _getMemberByProfile(profileId, user?.data);

      if (data) {
        const resp = { id: profileId, data: data };
        dispatch({
          type: MEMBER_SET,
          data: resp,
        });

        return {
          data: resp,
        };
      } else {
        throw Error(i18n.t("Member:Container.Action.Error"));
      }
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export const getMemberLogin = (id: string) => async (): Promise<
  string | undefined
> => {
  console.debug("[Action] getMemberLogin");

  try {
    const member = await _getMember(id);

    if (member) {
      return member.username;
    }
  } catch (err) {
    logException(err);
  }
};

/**
 * Get all members of particular group
 * @param groupId
 * @param refresh
 */
export function getGroupMembers(
  groupId: string,
  refresh?: boolean
): AppThunkAction {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<void> => {
    console.debug("[Action] getGroupMembers");

    try {
      const { member } = getState();
      const cachedData = member.groups[groupId];

      if (cachedData && !refresh) {
        return;
      }

      const data = await _getGroupMembers(groupId);
      dispatch({
        type: MEMBER_GROUP_SET,
        data: { groupId, data },
      });
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

/**
 * Allow user can follow someone
 * @param userId
 */
export function addFollows(userId: string): AppThunkAction<void> {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    console.debug("[Action] addFollows");

    try {
      await _addFollows([userId]);

      await dispatch(getCurrentUser());
      await dispatch(getMember(userId, true));
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

/**
 * Remove following someone
 * @param userId
 */
export function removeFollows(userId: string): AppThunkAction<void> {
  return async (dispatch: AppThunkDispatch): Promise<void> => {
    console.debug("[Action] removeFollows");

    try {
      await _removeFollows([userId]);

      await dispatch(getCurrentUser());
      await dispatch(getMember(userId, true));
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type GetMembersByGroupsActionReturn = { data: Dict<Member> };

/**
 * Quick way to get all members of multiple groups
 * It's being used for sending notification to multiple groups
 * @param groupIds
 */
export function getMembersByGroups(
  groupIds: string[]
): () => Promise<GetMembersByGroupsActionReturn> {
  return async (): Promise<GetMembersByGroupsActionReturn> => {
    console.debug("[Action] getMembersByGroups");

    try {
      const data = await _getMembersByGroups(groupIds);

      return { data: data };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}
export function exportMembers(
  exportId: string | undefined
): AppThunkAction<ExportMembersResult> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<ExportMembersResult> => {
    console.debug("[Action] exportMembers");

    try {
      const { setting, user } = getState();
      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: setting?.config?.adminURL as string,
      };
      return await _exportMembers(exportId, config);
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}
