import { runFunction } from "../../http";

export const getUnreadPost = async (
  groupIds: string[] = []
  //@FIXME add typings while typing post container
): Promise<{ id: string }[]> => {
  console.debug("[Service] getPostsUnread");

  return runFunction("getPostsUnread", { groupIds }, { requireUser: true });
};
