import { sortBy } from "lodash";
import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { Dict, Member } from "../../../types";

import { runFunction } from "../../http";

import { customProfile } from ".";

export const getMembers = async (options?: {
  page?: number;
  per?: number;
}): Promise<Dict<Member>> => {
  console.debug("[Service] getMembers", options);

  const response: Member[] = await runFunction("getAllUsers", options);

  const lastNameSorter = (user: Member): string => user.lastName?.toLowerCase();
  const firstNameSorter = (user: Member): string =>
    user.firstName?.toLowerCase();

  const sortResult = sortBy(response, [lastNameSorter, firstNameSorter]);

  return _.object(
    _.map(sortResult, value => {
      /**
       * @TODO Here customProfile doesn't have second argument applied, which is necessary for filtering inside
       * So I declared groups argument's default value as `{}` (empty Dict)
       */
      return [value.id, customProfile(convertObject(value))];
    })
  );
};
