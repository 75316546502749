import { AnnouncementItem, ReactionType } from "../../../types";
import {
  getNextReactionCount,
  getNextLikeCount,
  getNextReactedItem,
} from "../Reaction";

export function getNextReactedAnnouncementItem({
  item,
  reaction,
}: {
  item: undefined;
  reaction: ReactionType;
}): undefined;
export function getNextReactedAnnouncementItem({
  item,
  reaction,
}: {
  item: AnnouncementItem;
  reaction: ReactionType;
}): AnnouncementItem;
export function getNextReactedAnnouncementItem<
  T extends undefined | AnnouncementItem
>({ item, reaction }: { item: T; reaction: ReactionType }): T;
export function getNextReactedAnnouncementItem({
  item,
  reaction,
}: {
  item: any;
  reaction: any;
}): any {
  if (item === undefined) return undefined;

  return item.announcement.event
    ? {
        ...item,
        reaction: reaction,
        isLiked: reaction !== "unlike",
        announcement: {
          ...item.announcement,
          event: {
            ...item.announcement.event,
            reactionCount: getNextReactionCount({
              newReaction: reaction,
              prevReaction: item.reaction,
              prevReactionCount: item.announcement.event.reactionCount,
            }),
            likeCount: getNextLikeCount({
              newReaction: reaction,
              prevIsLiked: item.isLiked,
              prevLikeCount: item.announcement.event.likeCount,
            }),
          },
        },
      }
    : getNextReactedItem({
        item: item,
        newReaction: reaction,
        dataKeyName: "announcement",
      });
}
