import { runFunction } from "../../http";
import _ from "underscore";
import { PostItem } from "../../../types/Post/PostItem";
import { convertObject } from "../../../lib/utils";
import { DictStrict } from "../../../types";
import { parseMentionsInPostItem } from "../ParseMentionsHelper";

type Options = {
  skipComments: boolean;
  per: number;
  page: number;
  groupId: string;
};

export const getGroupPosts = async (
  options: Options
): Promise<{
  data: DictStrict<PostItem>;
  postGroupIds: { id: string; groupId: string }[];
}> => {
  const parseResponse = await runFunction("groupFeed", options, {
    requireUser: true,
  });
  const postGroupIds: { id: string; groupId: string }[] = [];

  const data = _.object(
    _.map(parseResponse, (value: PostItem) => {
      postGroupIds.push({
        id: value.post.id,
        groupId: options.groupId,
      });
      return [value.post.id, parseMentionsInPostItem(convertObject(value))];
    })
  );

  return { data, postGroupIds };
};
