import {
  MEMBER_ALL_PRE_LOADING_SET,
  MEMBER_ALL_SET,
  MEMBER_FOLLOW_UPDATE,
  MEMBER_GROUP_SET,
  MEMBER_RESET,
  MEMBER_SET,
} from "./constants";
import { memberStateInit } from "./store";
import { MemberState, MemberAction } from "./types";

export const memberReducer = (
  state: MemberState = memberStateInit,
  action: MemberAction
): MemberState => {
  switch (action.type) {
    case MEMBER_RESET:
      return memberStateInit;

    case MEMBER_ALL_SET:
      if (action.data) {
        return {
          ...state,
          items: action.data,
        };
      }

      return state;

    case MEMBER_ALL_PRE_LOADING_SET:
      return {
        ...state,
        preLoadingStatus: action.data,
      };

    case MEMBER_SET:
      if (action.data) {
        const { id, data } = action.data;

        if (!data?.profile) {
          return {
            ...state,
          };
        }

        const cached = {
          ...state.cached,
          [id]: data,
          [data.profile?.objectId as string]: data,
        };

        //Only update data in "items" when it exists, to prevent duplicate with data (by Profile Id)
        if (state.items?.[id]) {
          return {
            ...state,
            cached,
            items: state.items
              ? {
                  ...state.items,
                  [id]: data,
                }
              : null,
          };
        }
        return {
          ...state,
          cached,
        };
      }

      return state;

    case MEMBER_GROUP_SET:
      if (action.data) {
        const { groupId, data } = action.data;

        if (groupId) {
          return {
            ...state,
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            groups: {
              ...state.groups,
              [groupId]: data,
            },
          };
        }
      }

      return state;

    case MEMBER_FOLLOW_UPDATE:
      if (action.data) {
        const { userId, followed } = action.data;

        return {
          ...state,
          items:
            state.items && state.items[userId]
              ? {
                  ...state.items,
                  [userId]: {
                    ...state.items[userId],
                    followed: followed,
                  },
                }
              : state.items,
          cached: state.cached[userId]
            ? {
                ...state.cached,
                [userId]: {
                  ...state.cached[userId],
                  followed: followed,
                },
              }
            : state.cached,
        };
      }

      return state;

    default:
      return state;
  }
};

export default memberReducer;
