import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GroupChannel, OpenChannel } from "sendbird";
import { isEmpty, isUndefined } from "underscore";

import { track } from "../../lib/track";

import {
  RootState,
  EventSingle,
  Group,
  MatchProps,
  EmptyObject,
} from "../../types";
import { ChatSender } from "../../types/Chat/ChatSender";
import { ChatSetting as ChatSettingType } from "../../types/Chat/ChatSetting";
import { UserDataState } from "../../types/User/UserDataState";

import { ChatMutatedChannel } from "./chatTypes";

import {
  addMembersToChannel,
  deleteChannel,
  fromSendBirdUserId,
  getChatNotificationSetting,
  getEventOfChat,
  getGroupOfChat,
  leaveChannel,
  removeMembersFromChannel,
  saveChatNotificationSettings,
  updateChannel,
} from "./action";
import { ChatSettingSetAction } from "./types";
import { getCurrentUserState } from "../User/selector";

type StateProps = {
  user: UserDataState | {};
  notificationSettings: ChatSettingType | {};
};

type DispatchProps = {
  addMembersToChannel: (
    channelUrl: string,
    users: { id: string }[]
  ) => Promise<boolean | { data: string }>;
  deleteChannel: (
    channelUrl: string
  ) => Promise<OpenChannel | Partial<GroupChannel>>;
  fromSendBirdUserId: (sendBirdUserId: string) => string;
  getChatEventItem: (eventChatId: string) => Promise<{ data: EventSingle }>;
  getChatGroup: (
    groupChatId: string,
    channel: ChatMutatedChannel
  ) => Promise<{ data: Group }>;
  getNotificationSettings: (
    channelUrl: string,
    refresh?: boolean
  ) => Promise<ChatSettingSetAction | { data: ChatSettingType }>;
  leaveChannel: (
    channelUrl: string
  ) => Promise<OpenChannel | Partial<GroupChannel>>;
  removeMembersFromChannel: (
    channelUrl: string,
    users: { id: string }[]
  ) => Promise<boolean>;
  saveNotificationSettings: (
    channelUrl: string,
    setting: ChatSettingType
  ) => Promise<void>;
  updateChannel: (channelUrl: string, name: string) => Promise<OpenChannel>;
};

export type ChatSettingLayout = {
  channel: ChatMutatedChannel;
  error?: string | null;
  event?: {
    data: EventSingle;
  };
  group?: {
    data: Group;
  };
  memberIDs?: string[];
  notificationSettings: {
    mobileNotification?: "all" | "specific" | undefined;
    desktopNotification?: "all" | "specific" | undefined;
    mute?: boolean | undefined;
    ignoreMuteHereChannel?: boolean | undefined;
  };
  notificationSettingsDefault: {
    mobileNotification: "all" | "specific";
    desktopNotification: "all" | "specific";
    mute: boolean;
    ignoreMuteHereChannel: boolean;
  };
  notificationSettingsOptions: {
    value: "all" | "mention" | "nothing";
    key: string;
  }[];
  refreshing?: boolean;
  sending?: boolean;
  user: UserDataState | EmptyObject;

  addMembers: (
    members: UserDataState[],
    callback?: (
      value:
        | boolean
        | {
            data: string;
          }
        | void
    ) => void
  ) => void;
  close?: () => void;
  deleteChannel: (callback?: () => void) => void;
  leaveChannel: (callback?: () => void) => void;
  onChangeNotificationSettings: (
    name:
      | "mobileNotification"
      | "desktopNotification"
      | "mute"
      | "ignoreMuteHereChannel",
    value: any
  ) => void;
  removeMembers: (members: UserDataState[], callback?: () => void) => void;
  updateChannel: (name: string, callback?: (value: string) => void) => void;
};

type Props = {
  Layout: ComponentType<ChatSettingLayout>;
  channel: ChatMutatedChannel;
  close?: () => void;
} & StateProps &
  DispatchProps &
  MatchProps<"channel"> &
  WithTranslation;

type State = {
  event?: { data: EventSingle };
  error: string | null;
  group?: { data: Group };
  refreshing: boolean;
  notificationSettings: {
    mobileNotification?: "all" | "specific";
    desktopNotification?: "all" | "specific";
    mute?: boolean;
    ignoreMuteHereChannel?: boolean;
  };
  notificationSettingsDefault: {
    mobileNotification: "all" | "specific";
    desktopNotification: "all" | "specific";
    mute: boolean;
    ignoreMuteHereChannel: boolean;
  };
  notificationSettingsOptions: {
    value: "all" | "mention" | "nothing";
    key: string;
  }[];
  sending?: boolean;
};

class ChatSetting extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      refreshing: false,
      notificationSettings: {},
      notificationSettingsDefault: {
        mobileNotification: "all",
        desktopNotification: "all",
        mute: false,
        ignoreMuteHereChannel: false,
      },
      notificationSettingsOptions: [
        {
          value: "all",
          key: this.props.t("Container.Settings.Option.All.Messages"),
        },
        {
          value: "mention",
          key: this.props.t("Container.Settings.Option.Just.Mentions"),
        },
        {
          value: "nothing",
          key: this.props.t("Container.Settings.Option.Nothing"),
        },
      ],
    };
  }

  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): null | { notificationSettings: ChatSetting | {} } {
    if (
      nextProps.notificationSettings &&
      isEmpty(prevState.notificationSettings)
    ) {
      return {
        notificationSettings: nextProps.notificationSettings,
      };
    } else return null;
  }

  componentDidMount(): void {
    this.getNotificationSettings();
    this.getEvent();
    this.getGroup();
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.channel.url !== this.props.channel.url) {
      if (this.props.channel.isEventChat) {
        this.getEvent();
      }
      if (this.props.channel.isGroupChat) {
        this.getGroup();
      }
    }
  }

  onChangeNotificationSettings = (
    name:
      | "mobileNotification"
      | "desktopNotification"
      | "mute"
      | "ignoreMuteHereChannel",
    value: undefined
  ): void => {
    const { notificationSettings } = this.state;
    notificationSettings[name] = value;
    this.setState({ notificationSettings }, () => {
      this.saveNotificationSettings();
    });
  };

  getNotificationSettings = (
    refresh?: boolean,
    callback?: () => void
  ): void => {
    const { getNotificationSettings, match } = this.props;
    const { refreshing } = this.state;
    if (refreshing) {
      return;
    }
    this.setState({ refreshing: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";
        await getNotificationSettings(channelUrl, refresh);
        this.setState({ refreshing: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  saveNotificationSettings = (
    refresh?: boolean,
    callback?: () => void
  ): void => {
    const { saveNotificationSettings, match } = this.props;
    const {
      sending,
      notificationSettings,
      notificationSettingsDefault,
    } = this.state;
    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";

        const newData: Partial<ChatSettingType> = {
          mobileNotification: !isEmpty(notificationSettings.mobileNotification)
            ? notificationSettings.mobileNotification
            : notificationSettingsDefault.mobileNotification,
          desktopNotification: !isEmpty(
            notificationSettings.desktopNotification
          )
            ? notificationSettings.desktopNotification
            : notificationSettingsDefault.desktopNotification,
          mute: !isUndefined(notificationSettings.mute)
            ? (notificationSettings.mute as boolean)
            : (notificationSettingsDefault.mute as boolean),
          ignoreMuteHereChannel: !isUndefined(
            notificationSettings.ignoreMuteHereChannel
          )
            ? notificationSettings.ignoreMuteHereChannel
            : notificationSettingsDefault.ignoreMuteHereChannel,
        };

        await saveNotificationSettings(channelUrl, newData as ChatSettingType);
        this.setState({ sending: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  onDeleteChannel = (callback?: () => void): void => {
    const { deleteChannel, match } = this.props;

    this.setState({ sending: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";
        await deleteChannel(channelUrl);
        this.setState({ sending: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  onLeaveChannel = (callback?: () => void): void => {
    const { leaveChannel, match } = this.props;

    this.setState({ sending: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";
        await leaveChannel(channelUrl);
        this.setState({ sending: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  onAddMembers = (
    members: UserDataState[],
    callback?: (value: boolean | { data: string } | void) => void
  ): void => {
    const { addMembersToChannel, match } = this.props;

    this.setState({ sending: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";
        const resp = await addMembersToChannel(channelUrl, members);
        this.setState({ sending: false, error: null });
        callback?.(resp);
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  onRemoveMembers = (members: UserDataState[], callback?: () => void): void => {
    const { removeMembersFromChannel, match } = this.props;

    this.setState({ sending: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";
        await removeMembersFromChannel(channelUrl, members);
        this.setState({ sending: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  onUpdateChannel = (
    name: string,
    callback?: (value: string) => void
  ): void => {
    const { match, updateChannel } = this.props;
    this.setState({ sending: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";
        await updateChannel(channelUrl, name);
        this.setState({ sending: false, error: null });
        callback?.(this.props.t("Container.Setting.Callback.Update.Chat.Name"));
        track("Update Chat Name", { channel: channelUrl });
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  getEvent = async (): Promise<void> => {
    const { match, getChatEventItem } = this.props;
    const channelUrl = match && match.params ? match.params.channel : "";
    const event = await getChatEventItem(channelUrl);
    this.setState({ event });
  };

  getGroup = async (): Promise<void> => {
    const { match, getChatGroup, channel } = this.props;
    const channelUrl = match && match.params ? match.params.channel : "";
    const group = await getChatGroup(channelUrl, channel as ChatMutatedChannel);
    this.setState({ group });
  };

  render = (): JSX.Element => {
    const { Layout, user, close, fromSendBirdUserId } = this.props;
    const {
      event,
      error,
      group,
      notificationSettings,
      notificationSettingsDefault,
      notificationSettingsOptions,
      refreshing,
      sending,
    } = this.state;

    const channel = this.props.channel as ChatMutatedChannel;

    return (
      <Layout
        notificationSettings={notificationSettings}
        addMembers={this.onAddMembers}
        channel={channel}
        close={close}
        deleteChannel={this.onDeleteChannel}
        event={event}
        error={error}
        group={group}
        leaveChannel={this.onLeaveChannel}
        memberIDs={channel?.members?.map((member: ChatSender) =>
          fromSendBirdUserId(member.userId)
        )}
        notificationSettingsDefault={notificationSettingsDefault}
        notificationSettingsOptions={notificationSettingsOptions}
        onChangeNotificationSettings={this.onChangeNotificationSettings}
        refreshing={refreshing}
        removeMembers={this.onRemoveMembers}
        sending={sending}
        updateChannel={this.onUpdateChannel}
        user={user}
      />
    );
  };
}
const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): StateProps => {
  const channelUrl =
    ownProps.match && ownProps.match.params
      ? ownProps.match.params.channel
      : "";

  return {
    user: getCurrentUserState(state),
    notificationSettings: state.chat.setting[channelUrl] || {},
  };
};

const mapDispatchToProps = {
  addMembersToChannel,
  deleteChannel,
  fromSendBirdUserId,
  getChatEventItem: getEventOfChat,
  getChatGroup: getGroupOfChat,
  getNotificationSettings: getChatNotificationSetting,
  leaveChannel,
  removeMembersFromChannel,
  saveNotificationSettings: saveChatNotificationSettings,
  updateChannel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Chat")(ChatSetting));
