import React, { useState } from "react";

import { Media } from "reactstrap";

import { PostNewEditModal, Avatar, StickyNavbar } from "..";
import { UserDataState, EmptyObject } from "../../../types";
import { useTheme } from "../../hooks";

type Props = {
  user: UserDataState | EmptyObject;
  groupId?: string;
};

const styles = {
  postNewButton: {
    height: 50,
    backgroundColor: "rgb(240, 242, 245)",
    color: "#505050",
    paddingTop: 12,
    paddingLeft: 15,
    cursor: "pointer",
  },
};

export const NewPostButton: React.FC<Props> = ({ user, groupId }) => {
  const {
    pins: { backgroundColor },
  } = useTheme();

  const [isPostNewEditModalOpen, setPostNewEditModalOpen] = useState(false);
  const togglePostNewEditModal = (): void => {
    setPostNewEditModalOpen(!isPostNewEditModalOpen);
  };
  const { firstName, lastName, objectId, profile } = user;
  return (
    <StickyNavbar>
      <div
        className="w-100 py-4 px-3 mb-2"
        style={{ height: 100, borderRadius: 5, backgroundColor }}>
        <Media>
          <Media left>
            <Avatar
              size="50"
              name={`${firstName} ${lastName}`}
              src={profile?.thumbUrl}
              id={objectId}
              round={true}
            />
          </Media>
          <Media body className="px-2">
            <div
              id="postNewButton"
              onClick={togglePostNewEditModal}
              style={styles.postNewButton}
              className="rounded-pill border postNewButton">
              {"I'd like to share something."}
            </div>
          </Media>
        </Media>
        <PostNewEditModal
          isOpen={isPostNewEditModalOpen}
          toggle={togglePostNewEditModal}
          groupId={groupId}
        />
      </div>
    </StickyNavbar>
  );
};
