import React from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import {
  AnnouncementItem,
  AnnouncementType,
  CommentRequest,
  CommentType,
  EmptyObject,
  ReactionType,
  UserDataState,
} from "../../../../types";

import { InfiniteScroll } from "../..";

import {
  AnnouncementCardAnchor,
  AnnouncementCardPlaceholder,
  AnnouncementPinAnchor,
} from "..";

import { AnnouncementListStickyNavbar } from ".";

export type AnnouncementsProps = {
  announcementType: string | null;
  announcementTypes: AnnouncementType[];
  data: AnnouncementItem[] | null;
  error: string | null;
  hasMore?: boolean;
  hideFilter?: boolean;
  loading?: boolean;
  pins?: AnnouncementItem[] | null;
  refreshing: boolean;
  setting: {
    announcementLabel?: string;
    ClientHostName: string;
    confettiEffectActions: [];
  };
  user: UserDataState | EmptyObject;

  addComment: (
    id: string,
    type: CommentType,
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void
  ) => Promise<void>;
  changeAnnouncementType: (
    announcementType: AnnouncementType,
    options?: { forceListReset?: boolean }
  ) => void;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  reFetch: (next: boolean, refresh: boolean, silent?: boolean) => void;
};

export const AnnouncementList: React.FC<AnnouncementsProps> = ({
  announcementType,
  announcementTypes,
  data,
  error,
  hasMore,
  hideFilter,
  loading,
  pins,
  refreshing: isRefreshing,
  setting,
  user,

  addComment,
  changeAnnouncementType,
  doReaction,
  reFetch,
}) => {
  const { t } = useTranslation("Announcement");

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const stickyNavbarElement = !hideFilter && (
    <AnnouncementListStickyNavbar
      announcementType={announcementType}
      announcementTypes={announcementTypes}
      changeAnnouncementType={changeAnnouncementType}
    />
  );

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const pinElements = pins?.map(item => (
    <AnnouncementPinAnchor
      customLabel={setting.announcementLabel}
      item={item}
      key={item.announcement.id}
    />
  ));

  const cardElements =
    data?.map(item => (
      <AnnouncementCardAnchor
        item={item}
        key={item.announcement.id}
        setting={setting}
        user={user}
        addComment={addComment}
        doReaction={doReaction}
      />
    )) || null;

  return (
    <div>
      {stickyNavbarElement}
      {errorElement}
      <InfiniteScroll
        emptyText={t("No Announcement found")}
        hasMore={!!hasMore}
        headerElement={pinElements}
        isLoading={!!loading}
        isRefreshing={!!isRefreshing}
        placeholder={<AnnouncementCardPlaceholder />}
        onLoadMore={handleLoadMore}>
        {cardElements}
      </InfiniteScroll>
    </div>
  );
};
