import {
  SUBCRIPTION_ALL_SET,
  SUBCRIPTION_RESET,
  SUBCRIPTION_PRODUCT_ALL_SET,
  SUBCRIPTION_DENY_SET,
} from "./constants";
import Store from "./store";
import { GlobalAction } from "../../types";
import { SubscriptionState } from "./types";

export const initialState = Store;

export default function reducer(
  state: SubscriptionState = initialState,
  action: GlobalAction
): SubscriptionState {
  switch (action.type) {
    case SUBCRIPTION_RESET: {
      return initialState;
    }

    case SUBCRIPTION_ALL_SET: {
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        subscriptions: action.data,
        shownSubscriptionDialog: true,
      };
    }

    case SUBCRIPTION_PRODUCT_ALL_SET: {
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        products: action.data,
        shownSubscriptionDialog: true,
      };
    }

    case SUBCRIPTION_DENY_SET: {
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        denySubscription: action.data,
        shownSubscriptionDialog: true,
      };
    }

    default:
      return state;
  }
}
