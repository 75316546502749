export const searchSections = [
  "name",
  "title",
  "company",
  "industry",
  "location",
  "bio",
  "interests",
  "summary",
] as const;
