import { AppItem, AppItemList } from "../../../types";

import { getFilteredDict } from ".";

export const getFilteredItemList = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  dataKeyName,
  removeId,
  itemList,
}: {
  removeId: string;
  groupedByValue?: string;
  /**
   * Name of property storing nested entity data (and optional
   * reactionCount property)
   *
   * @FIXME
   * We have to specify this key name because of inconsistency in
   * all available Items shapes (nested data is stored under entity
   * name property). Unifing that property name and setting it
   * for example to "data" would simplify app data structures.
   */
  dataKeyName: DataKey;
  itemList: AppItemList<DataKey, ItemType>;
}): AppItemList<DataKey, ItemType> => {
  const dict = itemList.items;

  const removedItem = dict?.[removeId];

  if (!dict || !removedItem) return itemList;

  return {
    ...itemList,
    items: getFilteredDict({
      itemsDict: dict,
      dataKeyName: dataKeyName,
      removeId: removeId,
    }),
  };
};
