import React from "react";

import classnames from "classnames";
import UserAvatar from "react-avatar";
import { Link } from "react-router-dom";
import { toInitialName } from "../../../lib/utils";

export type AvatarProps = {
  className?: string;
  color?: string;
  containerStyle?: any;
  id?: string;
  name?: string;
  onPress?: () => void;
  pending?: boolean;
  round?: boolean;
  size?: string;
  src?: string;
  style?: any;
  type?: "member" | "group";
  disablePressDetail?: boolean;
};

/**
 * @TODO Probably ids for e2e test needs fix
 */
export const Avatar: React.FC<AvatarProps> = props => {
  const {
    className,
    color,
    containerStyle,
    id,
    name,
    onPress,
    pending,
    round,
    size,
    src,
    type,
    disablePressDetail,
  } = props;
  let newSrc = src || "";

  /**  
  @FIXME filtering urls should be done in another layer, ideally in backend
  */
  //only use GroupFire avatar not Sendbird
  if (
    newSrc.indexOf("mobilize") === -1 &&
    newSrc.indexOf("groupfire.com") === -1 &&
    !newSrc.startsWith("data:image")
  ) {
    newSrc = "";
  }

  const avatar = (
    <UserAvatar
      size={size ? size : "50"}
      name={toInitialName(name)}
      src={newSrc}
      color={color}
      round={round !== undefined ? round : true}
      style={containerStyle}
      className={
        (round ? "" : "rounded-lg ") + "mx-auto d-block text-decoration-none"
      }
    />
  );

  if (onPress && !disablePressDetail) {
    return (
      <span
        id={`memberAvatar${id}`}
        className={classnames(className)}
        onClick={onPress}>
        {avatar}
      </span>
    );
  } else if (!disablePressDetail && id && id.length > 0 && !pending) {
    const link = type === "group" ? `/group-feed/${id}` : `/member/${id}`;
    return (
      <Link
        id={`memberAvatar${id}`}
        className={classnames(className, "text-decoration-none")}
        to={link}>
        {avatar}
      </Link>
    );
  }
  return (
    <span id={`memberAvatar${id}`} className={classnames(className)}>
      {avatar}
    </span>
  );
};
