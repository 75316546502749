import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import {
  EmptyObject,
  CommentType,
  ReactionType,
  PostItem,
  UserDataState,
  RootState,
} from "../../types";

import { doReaction } from "../Comment/action";

import { getUserPosts } from "./action";
import { track } from "../../lib/track";
import { getCurrentUserState } from "../User/selector";
import {
  getPostsSettingState,
  getUserPostsState,
  getUserPostsHasMoreState,
} from "./selector";

type StateProps = {
  data: PostItem[] | null;
  hasMore: boolean;
  setting: {
    ClientHostName?: string;
    confettiEffectActions?: [];
  };
  user: UserDataState | EmptyObject;
};

export type UserPostsLayoutProps = {
  data: PostItem[] | null;
  error: string | null;
  hasMore: boolean;
  isMixed?: boolean;
  loading: boolean;
  refreshing: boolean;
  setting: {
    ClientHostName?: string;
    announcementLabel?: string;
    confettiEffectActions?: [];
  };
  user: UserDataState | EmptyObject;
  disableReaction?: boolean;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>;
  reFetch: (next: boolean, refresh: boolean) => void;
};

type DispatchProps = {
  getPosts: (userId: string, next: boolean, refresh: boolean) => Promise<void>;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>; //@TODO this is assumption, compare with comment container typing
};

type OwnProps = {
  Layout: ComponentType<UserPostsLayoutProps>;
  userId: string;
  isMixed?: boolean;
  disableReaction?: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class UserPosts extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false, true);

    /**
     * match.params was undefined in every use case.
     * Should it be deleted?
     */
    track("View Screen", {
      Screen: "user-posts",
      Params: undefined,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevProps.userId !== this.props.userId) {
      this.fetchData(false, true);
    }
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): void => {
    const { getPosts, userId, data } = this.props;

    this.setState(
      { refreshing: refresh && !!data, loading: next },
      async () => {
        try {
          await getPosts(userId, next, refresh);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
          callback?.();
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  render = (): JSX.Element => {
    const {
      Layout,
      data,
      hasMore,
      isMixed,
      setting,
      user,
      doReaction,
      disableReaction,
    } = this.props;
    const { loading, refreshing, error } = this.state;

    return (
      <Layout
        data={data}
        error={error}
        loading={loading}
        isMixed={isMixed}
        refreshing={refreshing}
        setting={setting}
        user={user}
        hasMore={hasMore}
        doReaction={doReaction}
        disableReaction={disableReaction}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const userId = ownProps.userId;

  return {
    user: getCurrentUserState(state),
    setting: getPostsSettingState(state),
    data: getUserPostsState(state, userId),
    hasMore: getUserPostsHasMoreState(state, userId),
  };
};

const mapDispatchToProps = {
  getPosts: getUserPosts,
  doReaction: doReaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPosts);
