import React, { Component, ErrorInfo, ReactNode } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import { logOut } from "../../../../containers/User/action";

import errorImage from "./errorboundary.png";

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryDispatchProps = {
  logOut: (fromWeb?: boolean) => Promise<unknown>;
};

type Props = ErrorBoundaryProps &
  RouteComponentProps &
  ErrorBoundaryDispatchProps;

type StateProps = {
  hasError: boolean;
};

class ErrorBoundaryComponent extends Component<Props, StateProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = (_: Error): StateProps => ({
    hasError: true,
  });

  componentDidCatch = (error: Error, errorInfo: ErrorInfo): void =>
    console.error("Uncaught error:", error, errorInfo);

  redirectToHomePage = (): void => {
    this.setState({ hasError: false });
    const { history } = this.props;
    history.replace("/");
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  redirectToLoginPage = async (fromWeb: boolean): Promise<void> => {
    this.setState({ hasError: false });
    const { logOut } = this.props;
    await logOut(fromWeb);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  onReLoginButtonClick = (): Promise<void> => this.redirectToLoginPage(true);

  render(): JSX.Element | ReactNode {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="bg-white w-100 h-100">
          <div
            className="d-flex justify-content-between flex-column w-100 h-100"
            style={{
              background: `url(${errorImage}) no-repeat center`,
            }}>
            <div></div>
            <div className="text-center pb-5">
              <Button
                color="primary"
                className="mx-2"
                onClick={this.redirectToHomePage}>
                Homepage
              </Button>
              <Button
                color="primary"
                className="mx-2"
                onClick={this.onReLoginButtonClick}>
                Reset Page
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = {
  logOut,
};

export const ErrorBoundary = withRouter(
  connect(null, mapDispatchToProps)(ErrorBoundaryComponent)
);
