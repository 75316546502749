import React, { useEffect } from "react";

import { Alert } from "reactstrap";

import { Reaction } from "../../../types";

import { InfiniteScroll, CommentUserListCard } from "..";

import { CommentReactByPlaceholder } from ".";

export type CommentReactByProps = {
  data?: Reaction[];
  error: string | null;
  hasMore?: boolean;
  loading: boolean;
  refreshing: boolean;

  reFetch: (next: boolean, refresh: boolean, callback?: () => void) => void;
};

export const CommentReactBy: React.FC<CommentReactByProps> = ({
  data,
  error,
  hasMore,
  loading: isLoading,
  refreshing: isRefreshing,

  reFetch,
}) => {
  /** Refreshing when component mounting */
  useEffect(() => {
    reFetch(false, true);
  }, [reFetch]);

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const errorElement = error && <Alert color="warning">{error}</Alert>;

  const cardElements =
    data?.map(({ type, user }) => (
      <CommentUserListCard key={user.objectId} type={type} user={user} />
    )) || null;

  return (
    <>
      {errorElement}
      <InfiniteScroll
        hasMore={!!hasMore}
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        onLoadMore={handleLoadMore}
        placeholder={<CommentReactByPlaceholder />}>
        {cardElements}
      </InfiniteScroll>
    </>
  );
};
