import React from "react";

import { RouteComponentProps } from "react-router-dom";

import SubscriptionContainer from "../../../containers/Subscription";

import { SubscriptionList } from "../../components";

type Props = RouteComponentProps;

export const RouteSubscription: React.FC<Props> = props => (
  <SubscriptionContainer {...props} Layout={SubscriptionList} />
);
