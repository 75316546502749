import { RESOURCE_ALL_SET, RESOURCE_RESET, RESOURCE_SET } from "./constants";
import Store from "./store";
import { ResourceState, ResourceAction } from "./types";

export const initialState = Store;

export const resourceReducer = (
  state: ResourceState = initialState,
  action: ResourceAction
): ResourceState => {
  switch (action.type) {
    case RESOURCE_RESET:
      return initialState;

    case RESOURCE_ALL_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        list: {
          ...state.list,
          ...action.data,
        },
      };

    case RESOURCE_SET:
      if (action.data) {
        return {
          ...state,
          list: {
            ...state.list,

            //only set data to cached (not list) to display detail properly
            cached: {
              ...state.list.cached,
              [action.data.id]: {
                ...state.list.items?.[action.data.id],
                ...action.data,
              },
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
};

export default resourceReducer;
