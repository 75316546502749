import { runFunction } from "../../http";

export const getUnreadEvent = async (
  groups: { id: string }[]
  //@FIXME add typings after merging event types
): Promise<{ id: string }[]> => {
  console.debug("[Service] listUnreadEvents");

  return runFunction("listUnreadEvents", { groups }, { requireUser: true });
};
