import React, { FC } from "react";
import { Sender } from "sendbird";

import { Avatar } from "../../UI";
import { take } from "underscore";

type ChatConversationReadBadgesProps = {
  position?: {
    left?: string;
    right?: string;
  };
  readReceipts: Sender[];
};

export const ChatConversationReadBadges: FC<ChatConversationReadBadgesProps> = ({
  position,
  readReceipts,
}) => {
  return (
    <>
      {readReceipts.length > 0 && (
        <div className="chat-read-receipts">
          {take(readReceipts, 10)?.map((recipient, index) => {
            return (
              <Avatar
                key={index}
                name={recipient.nickname}
                src={recipient.profileUrl}
                round
                size="15"
              />
            );
          })}
          {readReceipts.length > 10 && (
            <Avatar name={`${readReceipts.length - 10} more`} round size="15" />
          )}
        </div>
      )}
    </>
  );
};
