import { PostState } from "./types";

export const postStateInit: PostState = {
  item: null,
  history: {},
  searchList: {
    items: null,
    cached: {},
    page: 0,
    hasMore: true,
  },
  mixedList: {
    items: null,
    cached: {},
    page: 0,
    hasMore: true,
  },
  usersList: {},
  groupsList: {},
  filesList: {},
  imagesList: {},
};

export default postStateInit;
