import { getData } from "../http";

export type ExtractedLink = {
  description?: string;
  title?: string;
  url?: string;
  images?: { url: string }[];
  original_url?: string;
};

export const extractLink = async (
  url: string,
  key: string
): Promise<ExtractedLink> => {
  const target = `http://api.embed.ly/1/extract?key=${key}&url=${url}&format=json`;
  return await getData(target, "GET", {
    "Content-Type": "application/json",
  });
};
