import React, { Component } from "react";

import AwesomeDebouncePromise from "awesome-debounce-promise";
import { connect } from "react-redux";

import {
  MentionLink,
  MentionSearchLinkInfo,
  MentionSearchUserInfo,
  MentionUser,
} from "../../types";

import { searchUsers, searchLinks } from "./action";

type DispatchProps = {
  searchLinks: (
    query: RegExp
  ) => Promise<{
    data: { title: string; data: MentionSearchLinkInfo[] }[];
  }>;
  searchUsers: (
    query: string,
    options: { groupId?: string; includeHereAndChannel: boolean }
  ) => Promise<{ data: MentionSearchUserInfo[] }>;
};

export type MentionInputLayoutProps = {
  disabled?: boolean;
  error: string | null;
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  links: { title: string; data: MentionSearchLinkInfo[] }[] | {};
  loading: boolean;
  maxHeight?: number;
  maxVisibleRowCount?: number;
  mentionLinkChar: string;
  mentionUserChar: string;
  minHeight?: number;
  placeholder?: string;
  // @FIXME this is possibly a mistake, this value is not passed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any;
  singleLine?: boolean;
  /**
   * @TODO
   * This style should be fixed for web and native usage
   */
  style?: {};
  suggestionBottom?: boolean;
  users: MentionSearchUserInfo[];
  value: string;

  fetchLinksData: (
    query: string
  ) => Promise<
    | void
    | {}
    | {
        data: {
          title: string;
          data: MentionSearchLinkInfo[];
        }[];
      }
  >;
  fetchUsersData: (
    query: string
  ) => Promise<void | {
    data: MentionSearchUserInfo[];
  }>;
  mentionLink: (
    link: MentionSearchLinkInfo,
    options?: {
      dontOverwriteValue?: boolean;
    }
  ) => void;
  mentionUser: (
    user: MentionSearchUserInfo,
    options?: {
      dontOverwriteValue?: boolean;
    }
  ) => void;
  onBlur?: (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => void;
  onChangeText: (text: string) => void;
  onFocus?: () => void;
  onKeyDown?: (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  openingSuggestionsPanel?: (height: number) => void | boolean;
};

type Props = {
  Layout: React.FC<MentionInputLayoutProps>;
  disabled?: boolean;
  id?: string;
  includeHereAndChannel?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  linksMentioned?: MentionLink[];
  maxHeight?: number;
  maxVisibleRowCount?: number;
  minHeight?: number;
  placeholder?: string;
  ref?: any;
  singleLine?: boolean;
  style?: {};
  suggestionBottom?: boolean;
  usersMentioned?: MentionUser[];
  value: string;

  mentionLink: (linksMentions: MentionLink[]) => void;
  mentionUser: (mentions: MentionUser[]) => void;
  onBlur?: (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => void;
  onChangeText: (text: string) => void;
  onFocus?: () => void;
  onKeyDown?: (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  openingSuggestionsPanel?: (height: number) => void | boolean;
} & DispatchProps;

type State = {
  error: string | null;
  links: { title: string; data: MentionSearchLinkInfo[] }[] | {};
  loading: boolean;
  mentionLinkChar: string;
  mentionUserChar: string;
  queryLink: string | null;
  queryUser: string | null;
  refreshing?: boolean;
  users: MentionSearchUserInfo[];
};

class MentionInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      users: [],

      mentionUserChar: "@",
      queryUser: null,

      links: [],
      mentionLinkChar: "^",
      queryLink: null,
    };
  }

  fetchUsersData = async (
    query: string,
    callback?: () => void
  ): Promise<void | { data: MentionSearchUserInfo[] }> => {
    if (query.length < 2) {
      return;
    }

    const { searchUsers } = this.props;
    const { loading, mentionUserChar } = this.state;
    this.setState({ queryUser: query });

    if (loading) {
      return;
    }

    this.setState({ loading: true });

    const options = {
      includeHereAndChannel: !!this.props.includeHereAndChannel,
    };

    try {
      const resp = await searchUsers(
        query.replace(mentionUserChar, ""),
        options
      );
      if (resp.data) {
        this.setState({ loading: false, users: resp.data, error: null });
      }
      callback && callback();
      return resp;
    } catch (error) {
      this.setState({ loading: false, refreshing: false, error: error });
    }
  };

  fetchUsersDataDebounce = AwesomeDebouncePromise(
    (query: string) => this.fetchUsersData(query),
    1000
  );

  mentionUser = (
    user: MentionSearchUserInfo,
    options?: {
      dontOverwriteValue?: boolean;
    }
  ): void => {
    const { queryUser, mentionUserChar } = this.state;
    const { onChangeText, value, mentionUser, usersMentioned } = this.props;

    const { id, name } = user;
    const newUserMentioned = {
      id,
      stub: mentionUserChar + name,
    };

    const newUsersMentioned = usersMentioned
      ? [...usersMentioned, newUserMentioned]
      : [newUserMentioned];

    const text = queryUser ? value.slice(0, -queryUser.length) : "";
    const changedText = text + mentionUserChar + name;

    const uniqueMentions = newUsersMentioned.filter(
      (mention, index, array) =>
        index ===
          array.findIndex(
            m => m.id === mention.id && m.stub === mention.stub
          ) && changedText.includes(mention.stub)
    );

    mentionUser(uniqueMentions);

    /**
     * <Mention /> from "react-mentions" package allows to pass onAdd event handler which
     * is called directrly after item select and it has built in value chanching mechanism
     * so there is no need to update text value here (it breaks web app behaviour)
     *
     * The same issue happens with links mentions.
     */
    if (!options?.dontOverwriteValue) {
      onChangeText(changedText);
    }
  };

  fetchLinksData = async (
    query: string,
    callback?: () => void
  ): Promise<
    | void
    | {
        data: {
          title: string;
          data: MentionSearchLinkInfo[];
        }[];
      }
    | {}
  > => {
    if (query.length < 2) {
      return false;
    }

    const { searchLinks } = this.props;
    const { loading, mentionLinkChar } = this.state;
    this.setState({ queryLink: query });

    if (loading) {
      return false;
    }

    this.setState({ loading: true });

    try {
      const resp = await searchLinks(
        new RegExp(query.replace(mentionLinkChar, ""))
      );
      if (resp.data) {
        this.setState({ loading: false, links: resp.data, error: null });
      }
      callback && callback();

      return resp;
    } catch (error) {
      this.setState({ loading: false, refreshing: false, error: error });
    }
  };

  fetchLinksDataDebounce = AwesomeDebouncePromise(
    (query: string) => this.fetchLinksData(query),
    1000
  );

  mentionLink = (
    link: MentionSearchLinkInfo,
    options?: {
      dontOverwriteValue?: boolean;
    }
  ): void => {
    const { queryLink, mentionLinkChar } = this.state;
    const { onChangeText, value, mentionLink, linksMentioned } = this.props;

    const { id, name, url } = link;
    const newLinkMentioned = {
      id,
      url,
      title: mentionLinkChar + name,
    };

    const newLinksMentioned = linksMentioned
      ? [...linksMentioned, newLinkMentioned]
      : [newLinkMentioned];

    const text = queryLink !== null ? value.slice(0, -queryLink.length) : "";
    const changedText = text + mentionLinkChar + name;

    const uniqueMentions = newLinksMentioned.filter(
      (mention, index, array) =>
        index ===
          array.findIndex(
            m =>
              m.id === mention.id &&
              m.url === mention.url &&
              m.title === mention.title
          ) && changedText.includes(mention.title)
    );

    mentionLink(uniqueMentions);

    if (!options?.dontOverwriteValue) {
      onChangeText(changedText);
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      disabled,
      id,
      inputRef,
      maxHeight,
      maxVisibleRowCount,
      minHeight,
      placeholder,
      ref,
      singleLine,
      style,
      suggestionBottom,
      value,

      onBlur,
      onChangeText,
      onFocus,
      onKeyDown,
      openingSuggestionsPanel,
    } = this.props;
    const {
      error,
      links,
      loading,
      mentionLinkChar,
      mentionUserChar,
      users,
    } = this.state;

    return (
      <Layout
        disabled={disabled}
        error={error}
        fetchLinksData={this.fetchLinksDataDebounce}
        fetchUsersData={this.fetchUsersDataDebounce}
        id={id}
        inputRef={inputRef}
        links={links}
        loading={loading}
        maxHeight={maxHeight}
        maxVisibleRowCount={maxVisibleRowCount}
        mentionLink={this.mentionLink}
        mentionLinkChar={mentionLinkChar}
        mentionUser={this.mentionUser}
        mentionUserChar={mentionUserChar}
        minHeight={minHeight}
        onBlur={onBlur}
        onChangeText={onChangeText}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        openingSuggestionsPanel={openingSuggestionsPanel}
        placeholder={placeholder}
        ref={ref}
        singleLine={singleLine}
        style={style}
        suggestionBottom={suggestionBottom}
        users={users}
        value={value}
      />
    );
  };
}

const mapDispatchToProps = {
  searchUsers: searchUsers,
  searchLinks: searchLinks,
};

export default connect(undefined, mapDispatchToProps, undefined, {
  forwardRef: true,
})(MentionInput);
