import React, { Component, ComponentType } from "react";

import { connect } from "react-redux";
import { flatten } from "underscore";

import { RootState, UserDataState, MixedFeedTab } from "../../types";

export type MixedFeedStateProps = {
  user: UserDataState | {};
  unread: {
    feed?: string[];
    post?: string[];
    resource?: undefined;
  };
  setting: {};
};

export type MixedFeedLayoutProps = {
  tabs: MixedFeedTab[];
  unread: {
    feed?: string[];
    post?: string[];
  };
  options: {
    postingEngagementPrompts: string[];
  };
  user: {} | UserDataState;
  setting: {};
  renderActions?: boolean;
  renderActionsDone?: boolean;
};

export type MixedFeedOwnProps = {
  Layout: ComponentType<MixedFeedLayoutProps>;
  options: { postingEngagementPrompts: string[] };
  tabs: MixedFeedTab[];
  renderActions?: boolean;
  renderActionsDone?: boolean;
};

export type Props = MixedFeedStateProps & MixedFeedOwnProps;

class MixedFeed extends Component<Props, {}> {
  render(): JSX.Element {
    const {
      Layout,
      tabs,
      unread,
      options,
      user,
      setting,
      renderActions,
      renderActionsDone,
    } = this.props;

    return (
      <Layout
        tabs={tabs}
        unread={unread}
        options={options}
        user={user}
        setting={setting}
        renderActions={renderActions}
        renderActionsDone={renderActionsDone}
      />
    );
  }
}

const mapStateToProps = (state: RootState): MixedFeedStateProps => {
  const { notification } = state;
  const { unread } = notification;
  if (unread && unread["groups"]) {
    unread.post = flatten(Object.values(unread["groups"]));
  }

  return {
    user: state.user.data || {},
    unread: {
      feed: unread.feed,
      post: unread.post,
    },
    setting: {},
  };
};

export default connect(mapStateToProps)(MixedFeed);
