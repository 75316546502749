import React from "react";

import classnames from "classnames";
import { Button, Badge } from "reactstrap";

import { Tag } from "../../../../types";

export type TagInputSuggestionProps = {
  className?: string;
  tagsSuggestion: Tag[] | null;

  onTagClick: (name: string) => void;
};

export const TagInputSuggestion: React.FC<TagInputSuggestionProps> = ({
  className,
  tagsSuggestion,

  onTagClick,
}) => {
  const tagsSuggestionsElements = tagsSuggestion?.map((tag, index) => {
    const {
      name,
      postsCount = 0,
      announcementsCount = 0,
      eventsCount = 0,
    } = tag;

    const counter =
      (postsCount || 0) + (announcementsCount || 0) + (eventsCount || 0);

    const handleClick = (): void => {
      onTagClick(name);
    };

    return (
      <Button
        className="mr-1 mb-1"
        color="primary"
        size="sm"
        key={index}
        onClick={handleClick}>
        {tag.name} <Badge color="secondary">{counter}</Badge>
      </Button>
    );
  });

  return <div className={classnames(className)}>{tagsSuggestionsElements}</div>;
};
