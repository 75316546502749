import React from "react";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { Card, Media } from "reactstrap";

import { Member } from "../../../types";

import { Avatar } from "../UI";
import { useTheme } from "../../hooks";

export type MemberCardProps = {
  className?: string;
  item: Member;
  picker?: boolean;
  onSelect?: (item: Member) => void;
  // if this is true we should have text in white color for better contrast
  // this is added due to className prop above
  enableDarkMode?: boolean;
};

export const MemberCard: React.FC<MemberCardProps> = ({
  className,
  item,
  picker,
  onSelect,
  enableDarkMode,
}) => {
  const { profile, firstName, note, id, objectId, location, company } = item;

  const name =
    profile === undefined ? firstName : `${item.firstName} ${item.lastName}`;
  const title =
    profile === undefined
      ? note
      : (profile.sections &&
          profile.sections["Professional Info"] &&
          profile.sections["Professional Info"].Title) ||
        "Member";

  const applyTextStyles = (
    basicStyle: string,
    basicTextStyle: string
  ): string => {
    return classnames(
      basicStyle,
      enableDarkMode ? "text-light" : basicTextStyle
    );
  };

  const {
    list: { backgroundColor },
  } = useTheme();

  return (
    <Card
      id={`memberCard${id || objectId}`}
      style={{ backgroundColor }}
      className={classnames(
        "p-2 mb-1 member-card rounded border-bottom",
        className
      )}>
      <Media onClick={picker ? (): void => onSelect?.(item) : undefined}>
        <Media left bottom className="mr-2" id={`memberCardAvatar${id}`}>
          <Avatar
            size={"80"}
            name={name}
            src={profile?.thumbUrl}
            id={id || objectId}
            round={true}
          />
        </Media>
        <Media body className="px-2 pt-2">
          {!picker ? (
            <Link
              id={`memberCardNameLink${id}`}
              to={`/member/${id || objectId}`}>
              {" "}
              <h6
                id={`memberCardNameValue${id}`}
                className={applyTextStyles("mb-0", "text-dark")}>
                {name}
              </h6>
            </Link>
          ) : (
            <h6
              id={`memberCardName${id}`}
              className={applyTextStyles("mb-0", "text-dark")}>
              {name}
            </h6>
          )}
          <div
            id={`memberCardTitle${id}`}
            className={applyTextStyles("text-small", "text-muted ")}>
            {title}
          </div>
          {company && (
            <div
              id={`memberCardCompany${id}`}
              className={applyTextStyles(
                "font-weight-bolder text-small",
                "text-primary "
              )}>
              {"@" + company}
            </div>
          )}
          <div
            id={`memberCardLocation${id}`}
            className={applyTextStyles("text-small", "text-muted ")}>
            {location}
          </div>
        </Media>
      </Media>
    </Card>
  );
};

export default MemberCard;
