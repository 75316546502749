import React from "react";

import ChatRooms from "../../../../containers/Chat";

import "../../../styles/css/sendbird-uikit.css";

import { ChatChannels } from "..";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ChatMutatedChannel } from "../../../../containers/Chat/chatTypes";

export type ChannelsSidebarProps = {
  simpleMode?: boolean;
  onClick?: (channel: ChatMutatedChannel) => void;
} & RouteComponentProps<{
  channel: string;
}>;

const ChannelsLeftSidebar: React.FC<ChannelsSidebarProps> = ({
  match,
  simpleMode,
  onClick,
}) => (
  <div className="sendbird-theme">
    <ChatRooms
      Layout={ChatChannels}
      match={match}
      simpleMode={simpleMode}
      onClick={onClick}
    />
  </div>
);

export const ChannelsSidebar = withRouter(ChannelsLeftSidebar);
