import { defaults, isUndefined, isEmpty, isString } from "underscore";
import { logException } from "../lib/utils";
import { logApiTimeMetric } from "../lib/logApiTimeMetric";

const Parse = window.Parse;

//const XSecretToken = window.applicationConfiguration.XSecretToken || "3708a8566888419ab07ab1775b4c7fbfdba1af8f4ce14ce2a7582e21f2d738fe8d6356d3579d4b68b74f0b6a2260849c";
const XSecretToken =
  "3708a8566888419ab07ab1775b4c7fbfdba1af8f4ce14ce2a7582e21f2d738fe8d6356d3579d4b68b74f0b6a2260849c";

export async function runQuery(name, callback, options) {
  var context = { operation: "query", table: name };
  var message = "[Parse] Query on " + name;
  console.debug(message, "started...");
  options = defaults(options || {}, { name: name });
  const timeStart = Date.now();
  const value = await promiseWrapper(
    message,
    options,
    () => {
      var model = name === "User" ? Parse.User : Parse.Object.extend(name);
      var query = new Parse.Query(model);
      return callback(query);
    },
    {},
    new Error(),
    context
  );
  const executeTime = Date.now() - timeStart;

  logApiTimeMetric(name, {
    "Execution time": executeTime,
    Parameters: options,
  });

  return value;
}

export async function runFunction(name, args, options) {
  var context = { operation: "function", name: name, args: args };
  var message = "[Parse] Function " + name;
  console.debug(message, "started with:", args);

  const timeStart = Date.now();
  const value = await promiseWrapper(
    message,
    options,
    () => {
      return Parse.Cloud.run(name, args);
    },
    {},
    new Error(),
    context
  );

  const executeTime = Date.now() - timeStart;

  logApiTimeMetric(name, {
    "Execution time": executeTime,
    Parameters: args,
  });

  return value;
}

export function fetchData(object, field, options) {
  var context = { operation: "fetch", field: field };
  var message = "[Parse] Fetch of " + field;
  console.debug(message, "started for:", object);
  return promiseWrapper(
    message,
    options,
    () => {
      var field_object = null;
      if (isUndefined(field)) {
        field_object = object;
      } else {
        field_object = object.get(field);
      }
      return field_object && field_object.fetch();
    },
    {},
    new Error(),
    context
  );
}
export function saveData(object, args, options) {
  var context = {
    operation: "save",
    table: object.className,
    id: object.id,
    args: args,
  };
  var message = "[Parse] Save";
  console.debug(message, "started for:", object);
  return promiseWrapper(
    message,
    options,
    () => {
      return object.save(args);
    },
    {},
    new Error(),
    context
  );
}
export function destroyData(object, args, options) {
  var context = {
    operation: "destroy",
    table: object.className,
    id: object.id,
    args: args,
  };
  var message = "[Parse] Destroy";
  console.debug(message, "started for:", object);
  return promiseWrapper(
    message,
    options,
    () => {
      return object.destroy();
    },
    {},
    new Error(),
    context
  );
}
export function promiseWrapper(
  message,
  options,
  buildPromise,
  Utils,
  caller,
  context
) {
  return new Promise((resolve, reject) => {
    if (!buildPromise) {
      return reject("No promise function");
    }
    options = defaults(options || {}, {
      spinner: false,
      error: true,
      notify: true,
      name: "object",
    });
    buildPromise()
      .then(results => {
        return resolve(results);
      })
      .catch(error => {
        if (options.error) {
          var user_message = null;
          switch (error.code) {
            case Parse.Error.OBJECT_NOT_FOUND:
              user_message = "Unable to find " + options.name;
              break;
            case Parse.Error.CONNECTION_FAILED:
              user_message =
                "Unable to connect to the server. Check your connection and try again.";
              break;
            case Parse.Error.TIMEOUT:
              user_message = "Server connection timed out.  Please try again.";
              break;
            case Parse.Error.SCRIPT_FAILED:
              user_message =
                "There was a problem processing your request. Please try again.";
              break;
            case Parse.Error.INVALID_QUERY:
              user_message =
                "There was a problem processing your request. Please try again.";
              break;
            case Parse.Error.INVALID_SESSION_TOKEN:
              user_message = "Session has expired";
              break;
            default:
              if (error.code) {
                user_message = "Problem contacting server (" + error.code + ")";
              }
              break;
          }
          try {
            var server_message = isString(error.message)
              ? JSON.parse(error.message)
              : error.message;
            if (!isEmpty(server_message) && !isEmpty(server_message.user)) {
              user_message = server_message.user;
            }
          } catch (ex) {}
          if (isEmpty(server_message) && !isEmpty(error.message)) {
            user_message = error.message;
          }

          if (user_message.message) {
            user_message = user_message.message;
          }

          if (options.notify) {
            caller.message = user_message;
            logException(caller);
          }

          return reject({ message: user_message });
        }
        return reject(error);
      });
  });
}
export function promiseWrapperAdmin(
  message,
  options,
  buildPromise,
  Utils,
  caller,
  context
) {
  return new Promise((resolve, reject) => {
    if (!buildPromise) {
      reject("No promise function");
    }
    options = defaults(options || {}, {
      spinner: false,
      error: true,
      notify: true,
      name: "object",
    });
    buildPromise()
      .then(async response => {
        if (options.xml) {
          return resolve(response.text());
        } else {
          const resp = await response.json();
          if (resp.error) {
            return reject({ message: resp.error });
          }
          return resolve(resp);
        }
      })
      .catch(error => {
        if (options.error) {
          var user_message = error;

          if (user_message.message) {
            user_message = user_message.message;
          }
          if (options.notify) {
            caller.message = user_message;
            logException(caller);
          }

          return reject({ message: user_message });
        }
        return reject({ message: error.statusText });
      });
  });
}
export function adminPost(name, args, options) {
  const { config } = options;
  var context = { operation: "api", name: name, args: args };
  var message = "[Admin API] " + name;
  console.debug(message, "started with:", args);
  return promiseWrapperAdmin(
    message,
    options,
    () => {
      window.resetFetchConfig && window.resetFetchConfig();
      return fetch(config.adminURL + "api/v1/" + name + "/", {
        method: "POST",
        body: JSON.stringify(args),
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Session-Token": config.sessionToken,
        },
      });
    },
    {},
    new Error(),
    context
  );
}
export function adminGet(name, args, options) {
  const { config } = options;
  var context = { operation: "api", name: name, args: args };
  var message = "[Admin API] " + name;
  console.debug(message, "started with:", args);
  return promiseWrapperAdmin(
    message,
    options,
    () => {
      let query = Object.keys(args)
        .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(args[k]))
        .join("&");
      let url = config.adminURL + "api/v1/" + name + "?" + query;
      window.resetFetchConfig && window.resetFetchConfig();
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": config.api_key,
          "X-Parse-Session-Token": config.sessionToken,
        },
      });
    },
    {},
    new Error(),
    context
  );
}
export function adminPatch(name, args, options) {
  const { config } = options;
  var context = { operation: "api", name: name, args: args };
  var message = "[Admin API] " + name;
  console.debug(message, "started with:", args);
  return promiseWrapperAdmin(
    message,
    options,
    () => {
      window.resetFetchConfig && window.resetFetchConfig();
      return fetch(config.adminURL + "api/v1/" + name + "/", {
        method: "PATCH",
        body: JSON.stringify(args),
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Session-Token": config.sessionToken,
        },
      });
    },
    {},
    new Error(),
    context
  );
}
export function adminDelete(name, args, options) {
  const { config } = options;
  var context = { operation: "api", name: name, args: args };
  var message = "[Admin API] " + name;
  console.debug(message, "started with:", args);
  return promiseWrapperAdmin(
    message,
    options,
    () => {
      window.resetFetchConfig && window.resetFetchConfig();
      return fetch(config.adminURL + "api/v1/" + name + "/", {
        method: "DELETE",
        params: args,
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Session-Token": config.sessionToken,
        },
      });
    },
    {},
    new Error(),
    context
  );
}
export function portalPost(name, args, options) {
  var context = { operation: "api", name: name, args: args };
  var message = "[Portal API] " + name;
  console.debug(message, "started with:", args);
  return promiseWrapperAdmin(
    message,
    options,
    () => {
      window.resetFetchConfig && window.resetFetchConfig();
      return fetch(`https://accounts.groupfire.com/api/v1/${name}/`, {
        method: "POST",
        body: JSON.stringify(args),
        headers: {
          "Content-Type": "application/json",
          "X-Secret-Token": XSecretToken,
        },
      });
    },
    {},
    new Error(),
    context
  );
}
export function portalGet(name, args, options) {
  var context = { operation: "api", name: name, args: args };
  var message = "[Portal API] " + name;
  console.debug(message, "started with:", args);
  return promiseWrapperAdmin(
    message,
    options,
    () => {
      window.resetFetchConfig && window.resetFetchConfig();
      return fetch(`https://accounts.groupfire.com/api/v1/${name}/`, {
        method: "GET",
        params: args,
        headers: {
          "X-Secret-Token": XSecretToken,
        },
      });
    },
    {},
    new Error(),
    context
  );
}
export function getData(api, method, header = {}) {
  var context = { operation: "api", name: api };
  var message = "[Admin API] " + api;
  return promiseWrapperAdmin(
    message,
    {},
    () => {
      window.resetFetchConfig && window.resetFetchConfig();
      return fetch(api, {
        method,
        header,
      });
    },
    {},
    new Error(),
    context
  );
}

export function fetchWithTimeout(url, options, delay = 10000) {
  const timer = new Promise(resolve => {
    setTimeout(resolve, delay, {
      timeout: true,
    });
  });
  return Promise.race([fetch(url, options), timer]).then(response => {
    return response;
  });
}
