import { adminGet } from "../../http";
import { SettingConfigData } from "../../../types/Setting/SettingConfigData";

export const searchUsedImage = async (
  query: string,
  config: SettingConfigData | null
): Promise<{ link_url: string; thumbnail_url: string }[]> => {
  return adminGet(
    "announcements/used_images",
    { query: query },
    { requireUser: true, config }
  );
};
