import React from "react";

import { RouteComponentProps, Redirect } from "react-router-dom";

import { SettingDataLoaded } from "../../../types";

import { useAppDefaultPath, useUserLogin, useUserDataState } from "../../hooks";

import { RedirectAppDefault } from "..";

import { RouteUserLogin } from ".";

export type RouteUserPasscodeProps = RouteComponentProps & {
  setting: SettingDataLoaded;
};

export const RouteUserPasscode: React.FC<RouteUserPasscodeProps> = props => {
  const user = useUserDataState();

  const defaultAppPath = useAppDefaultPath();

  const userLogin = useUserLogin();

  if (user) {
    return (
      <Redirect
        to={{
          pathname: props.location.state?.from.pathname || defaultAppPath,
        }}
      />
    );
  }

  if (userLogin) {
    return <RouteUserLogin {...props} />;
  }

  return <RedirectAppDefault {...props} />;
};
