import React, { FC } from "react";

import { textThemingWorkaround } from "../../../../constants";
import {
  Dict,
  UserSocialMedia,
  UserSocialMediaType,
  UserCompanyHistoryEvent,
} from "../../../../types";

import { useTheme } from "../../../hooks/useTheme";

import { MentionDisplay, Heading } from "../..";

import { MemberDetailSocialMedia } from "../Detail";
import { displayDate } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";

export type MemberDetailInfoSectionsBBProps = {
  contactAvailable?: string | boolean;
  currentCompany?: string | false;
  currentCompanyAvailable: boolean;
  currentCompanyLocation?: string | false;
  currentCompanyPhone?: string | false;
  currentCompanyWebsite?: string | false;
  memberEmail?: string | false;
  memberPhone?: string | false;
  noUserInfoHeading: string;
  socialMedia: UserSocialMediaType;
  socialMediaVisibilities: Dict<boolean, UserSocialMedia>;
  summary?: string | false;
  companyHistory?: UserCompanyHistoryEvent[];
};

export const MemberDetailInfoSectionsBB: FC<MemberDetailInfoSectionsBBProps> = ({
  contactAvailable,
  currentCompany,
  currentCompanyAvailable,
  currentCompanyLocation,
  currentCompanyPhone,
  currentCompanyWebsite,
  memberEmail,
  memberPhone,
  noUserInfoHeading,
  socialMedia,
  socialMediaVisibilities,
  summary,
  companyHistory,
}) => {
  const {
    divider,
    text: { cardText },
  } = useTheme();
  const { t } = useTranslation("Member");

  const memberDetailBBInfoSectionsStyles = {
    card: {
      backgroundColor: "#fff",
      paddingLeft: 20,
      paddingRight: 20,
    },
    cardBody: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    cardText: {
      paddingTop: 5,
      paddingLeft: 5,
      marginBottom: 10,
      borderBottomWidth: 0,
    },
    noUserInfoHeading: { ...cardText, ...textThemingWorkaround },
    valueHeading: {
      ...cardText,
      ...textThemingWorkaround,
      paddingBottom: 5,
      whiteSpace: "pre-wrap" as "pre-wrap",
    },
    title: {
      backgroundColor: divider.backgroundColor,
      color: divider.text.color,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: -10,
      marginRight: -10,
    },
  } as const;

  const summaryElement = !!summary && (
    <div style={memberDetailBBInfoSectionsStyles.cardBody}>
      <div style={memberDetailBBInfoSectionsStyles.cardText}>
        {!!summary && (
          <Heading
            text={summary}
            style={memberDetailBBInfoSectionsStyles.valueHeading}
          />
        )}
      </div>
    </div>
  );

  const letsConnectElement = (
    <div style={memberDetailBBInfoSectionsStyles.cardBody}>
      <h6 style={memberDetailBBInfoSectionsStyles.title}>{"Let's Connect"}</h6>
      {!contactAvailable && (
        <div style={memberDetailBBInfoSectionsStyles.cardText}>
          <Heading
            text={noUserInfoHeading}
            style={memberDetailBBInfoSectionsStyles.noUserInfoHeading}
          />
        </div>
      )}

      {!!contactAvailable && (
        <div style={memberDetailBBInfoSectionsStyles.cardText}>
          {memberEmail && (
            <div style={memberDetailBBInfoSectionsStyles.valueHeading}>
              <MentionDisplay text={memberEmail || ""} />
            </div>
          )}
          {memberPhone && (
            <div style={memberDetailBBInfoSectionsStyles.valueHeading}>
              <MentionDisplay text={memberPhone || ""} />
            </div>
          )}
          <MemberDetailSocialMedia
            data={socialMedia}
            visibilities={socialMediaVisibilities}
          />
        </div>
      )}
    </div>
  );

  const currentCompanyElement = (
    <div style={memberDetailBBInfoSectionsStyles.cardBody}>
      <h6 style={memberDetailBBInfoSectionsStyles.title}>Current Company</h6>
      {!currentCompanyAvailable && (
        <div style={memberDetailBBInfoSectionsStyles.cardText}>
          <Heading
            text={noUserInfoHeading}
            style={memberDetailBBInfoSectionsStyles.noUserInfoHeading}
          />
        </div>
      )}
      {!!currentCompanyAvailable && (
        <div style={memberDetailBBInfoSectionsStyles.cardText}>
          {currentCompany && (
            <Heading
              text={currentCompany}
              style={memberDetailBBInfoSectionsStyles.valueHeading}
            />
          )}

          {currentCompanyWebsite && (
            <div style={memberDetailBBInfoSectionsStyles.valueHeading}>
              <MentionDisplay text={currentCompanyWebsite || ""} />
            </div>
          )}
          {currentCompanyLocation && (
            <Heading
              text={currentCompanyLocation}
              style={memberDetailBBInfoSectionsStyles.valueHeading}
            />
          )}
          {currentCompanyPhone && (
            <div style={memberDetailBBInfoSectionsStyles.valueHeading}>
              <MentionDisplay text={currentCompanyPhone || ""} />
            </div>
          )}
        </div>
      )}
    </div>
  );

  const memberCompanyHistoryElement = companyHistory &&
    companyHistory.length > 0 && (
      <div style={memberDetailBBInfoSectionsStyles.cardBody} className="pb-1">
        <h6 style={memberDetailBBInfoSectionsStyles.title}>Company History</h6>
        {companyHistory.map(
          (value): JSX.Element => {
            if (value.name) {
              const fromDate = displayDate(value.fromdate, "MMM YYYY");
              const toDate = displayDate(value.todate, "MMM YYYY");
              return (
                <div
                  key={value.name}
                  style={memberDetailBBInfoSectionsStyles.cardText}>
                  <span
                    id={
                      "memberProfessionalInfoValueCompanyHistoryName" +
                      value.name.split(" ").join("")
                    }
                    style={memberDetailBBInfoSectionsStyles.valueHeading}>
                    {value.name}
                  </span>

                  {fromDate && (
                    <>
                      <span
                        id={
                          "memberProfessionalInfoValueCompanyHistoryFromDate" +
                          value.name.split(" ").join("")
                        }
                        className="pr-1"
                        style={memberDetailBBInfoSectionsStyles.valueHeading}>
                        : {fromDate}
                      </span>

                      {value.iscurrent ? (
                        <span
                          id={
                            "memberProfessionalInfoValueCompanyHistoryCurrent" +
                            value.name.split(" ").join("")
                          }
                          style={memberDetailBBInfoSectionsStyles.valueHeading}>
                          - {t("Detail.Text.Current")}
                        </span>
                      ) : (
                        <span
                          id={
                            "memberProfessionalInfoValueCompanyHistoryToDate" +
                            value.name.split(" ").join("")
                          }
                          style={memberDetailBBInfoSectionsStyles.valueHeading}>
                          - {toDate}
                        </span>
                      )}
                    </>
                  )}
                </div>
              );
            } else {
              return <div />;
            }
          }
        )}
      </div>
    );

  return (
    <div style={memberDetailBBInfoSectionsStyles.card}>
      {summaryElement}
      {letsConnectElement}
      {currentCompanyElement}
      {memberCompanyHistoryElement}
    </div>
  );
};
