//https://gist.github.com/ralphcrisostomo/36feb116cb217bb2cc0b
const tzData = [
  [
    "International Date Line West",
    "GMT-11:00",
    "(GMT-11:00) International Date Line West",
  ],
  ["Midway Island", "GMT-11:00", "(GMT-11:00) Midway Island"],
  ["Samoa", "GMT-11:00", "(GMT-11:00) Samoa"],
  ["Hawaii", "GMT-10:00", "(GMT-10:00) Hawaii"],
  ["Alaska", "GMT-09:00", "(GMT-09:00) Alaska"],
  [
    "Pacific Time (US & Canada)",
    "GMT-08:00) Pacific Time (US & Canada",
    "(GMT-08:00) Pacific Time (US & Canada)",
  ],
  ["Tijuana", "GMT-08:00", "(GMT-08:00) Tijuana"],
  ["Arizona", "GMT-07:00", "(GMT-07:00) Arizona"],
  ["Chihuahua", "GMT-07:00", "(GMT-07:00) Chihuahua"],
  ["Mazatlan", "GMT-07:00", "(GMT-07:00) Mazatlan"],
  [
    "Mountain Time (US & Canada)",
    "GMT-07:00) Mountain Time (US & Canada",
    "(GMT-07:00) Mountain Time (US & Canada)",
  ],
  ["Central America", "GMT-06:00", "(GMT-06:00) Central America"],
  [
    "Central Time (US & Canada)",
    "GMT-06:00) Central Time (US & Canada",
    "(GMT-06:00) Central Time (US & Canada)",
  ],
  ["Guadalajara", "GMT-06:00", "(GMT-06:00) Guadalajara"],
  ["Mexico City", "GMT-06:00", "(GMT-06:00) Mexico City"],
  ["Monterrey", "GMT-06:00", "(GMT-06:00) Monterrey"],
  ["Saskatchewan", "GMT-06:00", "(GMT-06:00) Saskatchewan"],
  ["Bogota", "GMT-05:00", "(GMT-05:00) Bogota"],
  [
    "Eastern Time (US & Canada)",
    "GMT-05:00) Eastern Time (US & Canada",
    "(GMT-05:00) Eastern Time (US & Canada)",
  ],
  ["Indiana (East)", "GMT-05:00) Indiana (East", "(GMT-05:00) Indiana (East)"],
  ["Lima", "GMT-05:00", "(GMT-05:00) Lima"],
  ["Quito", "GMT-05:00", "(GMT-05:00) Quito"],
  ["Caracas", "GMT-04:30", "(GMT-04:30) Caracas"],
  [
    "Atlantic Time (Canada)",
    "GMT-04:00) Atlantic Time (Canada",
    "(GMT-04:00) Atlantic Time (Canada)",
  ],
  ["Georgetown", "GMT-04:00", "(GMT-04:00) Georgetown"],
  ["La Paz", "GMT-04:00", "(GMT-04:00) La Paz"],
  ["Santiago", "GMT-04:00", "(GMT-04:00) Santiago"],
  ["Newfoundland", "GMT-03:30", "(GMT-03:30) Newfoundland"],
  ["Brasilia", "GMT-03:00", "(GMT-03:00) Brasilia"],
  ["Buenos Aires", "GMT-03:00", "(GMT-03:00) Buenos Aires"],
  ["Greenland", "GMT-03:00", "(GMT-03:00) Greenland"],
  ["Mid-Atlantic", "GMT-02:00", "(GMT-02:00) Mid-Atlantic"],
  ["Azores", "GMT-01:00", "(GMT-01:00) Azores"],
  ["Cape Verde Is.", "GMT-01:00", "(GMT-01:00) Cape Verde Is."],
  ["Casablanca", "GMT+00:00", "(GMT+00:00) Casablanca"],
  ["Dublin", "GMT+00:00", "(GMT+00:00) Dublin"],
  ["Edinburgh", "GMT+00:00", "(GMT+00:00) Edinburgh"],
  ["Lisbon", "GMT+00:00", "(GMT+00:00) Lisbon"],
  ["London", "GMT+00:00", "(GMT+00:00) London"],
  ["Monrovia", "GMT+00:00", "(GMT+00:00) Monrovia"],
  ["UTC", "GMT+00:00", "(GMT+00:00) UTC"],
  ["Amsterdam", "GMT+01:00", "(GMT+01:00) Amsterdam"],
  ["Belgrade", "GMT+01:00", "(GMT+01:00) Belgrade"],
  ["Berlin", "GMT+01:00", "(GMT+01:00) Berlin"],
  ["Bern", "GMT+01:00", "(GMT+01:00) Bern"],
  ["Bratislava", "GMT+01:00", "(GMT+01:00) Bratislava"],
  ["Brussels", "GMT+01:00", "(GMT+01:00) Brussels"],
  ["Budapest", "GMT+01:00", "(GMT+01:00) Budapest"],
  ["Copenhagen", "GMT+01:00", "(GMT+01:00) Copenhagen"],
  ["Ljubljana", "GMT+01:00", "(GMT+01:00) Ljubljana"],
  ["Madrid", "GMT+01:00", "(GMT+01:00) Madrid"],
  ["Paris", "GMT+01:00", "(GMT+01:00) Paris"],
  ["Prague", "GMT+01:00", "(GMT+01:00) Prague"],
  ["Rome", "GMT+01:00", "(GMT+01:00) Rome"],
  ["Sarajevo", "GMT+01:00", "(GMT+01:00) Sarajevo"],
  ["Skopje", "GMT+01:00", "(GMT+01:00) Skopje"],
  ["Stockholm", "GMT+01:00", "(GMT+01:00) Stockholm"],
  ["Vienna", "GMT+01:00", "(GMT+01:00) Vienna"],
  ["Warsaw", "GMT+01:00", "(GMT+01:00) Warsaw"],
  ["West Central Africa", "GMT+01:00", "(GMT+01:00) West Central Africa"],
  ["Zagreb", "GMT+01:00", "(GMT+01:00) Zagreb"],
  ["Athens", "GMT+02:00", "(GMT+02:00) Athens"],
  ["Bucharest", "GMT+02:00", "(GMT+02:00) Bucharest"],
  ["Cairo", "GMT+02:00", "(GMT+02:00) Cairo"],
  ["Harare", "GMT+02:00", "(GMT+02:00) Harare"],
  ["Helsinki", "GMT+02:00", "(GMT+02:00) Helsinki"],
  ["Istanbul", "GMT+02:00", "(GMT+02:00) Istanbul"],
  ["Jerusalem", "GMT+02:00", "(GMT+02:00) Jerusalem"],
  ["Kyiv", "GMT+02:00", "(GMT+02:00) Kyiv"],
  ["Minsk", "GMT+02:00", "(GMT+02:00) Minsk"],
  ["Pretoria", "GMT+02:00", "(GMT+02:00) Pretoria"],
  ["Riga", "GMT+02:00", "(GMT+02:00) Riga"],
  ["Sofia", "GMT+02:00", "(GMT+02:00) Sofia"],
  ["Tallinn", "GMT+02:00", "(GMT+02:00) Tallinn"],
  ["Vilnius", "GMT+02:00", "(GMT+02:00) Vilnius"],
  ["Baghdad", "GMT+03:00", "(GMT+03:00) Baghdad"],
  ["Kuwait", "GMT+03:00", "(GMT+03:00) Kuwait"],
  ["Moscow", "GMT+03:00", "(GMT+03:00) Moscow"],
  ["Nairobi", "GMT+03:00", "(GMT+03:00) Nairobi"],
  ["Riyadh", "GMT+03:00", "(GMT+03:00) Riyadh"],
  ["St. Petersburg", "GMT+03:00", "(GMT+03:00) St. Petersburg"],
  ["Volgograd", "GMT+03:00", "(GMT+03:00) Volgograd"],
  ["Tehran", "GMT+03:30", "(GMT+03:30) Tehran"],
  ["Abu Dhabi", "GMT+04:00", "(GMT+04:00) Abu Dhabi"],
  ["Baku", "GMT+04:00", "(GMT+04:00) Baku"],
  ["Muscat", "GMT+04:00", "(GMT+04:00) Muscat"],
  ["Tbilisi", "GMT+04:00", "(GMT+04:00) Tbilisi"],
  ["Yerevan", "GMT+04:00", "(GMT+04:00) Yerevan"],
  ["Kabul", "GMT+04:30", "(GMT+04:30) Kabul"],
  ["Ekaterinburg", "GMT+05:00", "(GMT+05:00) Ekaterinburg"],
  ["Islamabad", "GMT+05:00", "(GMT+05:00) Islamabad"],
  ["Karachi", "GMT+05:00", "(GMT+05:00) Karachi"],
  ["Tashkent", "GMT+05:00", "(GMT+05:00) Tashkent"],
  ["Chennai", "GMT+05:30", "(GMT+05:30) Chennai"],
  ["Kolkata", "GMT+05:30", "(GMT+05:30) Kolkata"],
  ["Mumbai", "GMT+05:30", "(GMT+05:30) Mumbai"],
  ["New Delhi", "GMT+05:30", "(GMT+05:30) New Delhi"],
  ["Sri Jayawardenepura", "GMT+05:30", "(GMT+05:30) Sri Jayawardenepura"],
  ["Kathmandu", "GMT+05:45", "(GMT+05:45) Kathmandu"],
  ["Almaty", "GMT+06:00", "(GMT+06:00) Almaty"],
  ["Astana", "GMT+06:00", "(GMT+06:00) Astana"],
  ["Dhaka", "GMT+06:00", "(GMT+06:00) Dhaka"],
  ["Novosibirsk", "GMT+06:00", "(GMT+06:00) Novosibirsk"],
  ["Rangoon", "GMT+06:30", "(GMT+06:30) Rangoon"],
  ["Bangkok", "GMT+07:00", "(GMT+07:00) Bangkok"],
  ["Hanoi", "GMT+07:00", "(GMT+07:00) Hanoi"],
  ["Jakarta", "GMT+07:00", "(GMT+07:00) Jakarta"],
  ["Krasnoyarsk", "GMT+07:00", "(GMT+07:00) Krasnoyarsk"],
  ["Beijing", "GMT+08:00", "(GMT+08:00) Beijing"],
  ["Chongqing", "GMT+08:00", "(GMT+08:00) Chongqing"],
  ["Hong Kong", "GMT+08:00", "(GMT+08:00) Hong Kong"],
  ["Irkutsk", "GMT+08:00", "(GMT+08:00) Irkutsk"],
  ["Kuala Lumpur", "GMT+08:00", "(GMT+08:00) Kuala Lumpur"],
  ["Perth", "GMT+08:00", "(GMT+08:00) Perth"],
  ["Singapore", "GMT+08:00", "(GMT+08:00) Singapore"],
  ["Taipei", "GMT+08:00", "(GMT+08:00) Taipei"],
  ["Ulaan Bataar", "GMT+08:00", "(GMT+08:00) Ulaan Bataar"],
  ["Urumqi", "GMT+08:00", "(GMT+08:00) Urumqi"],
  ["Osaka", "GMT+09:00", "(GMT+09:00) Osaka"],
  ["Sapporo", "GMT+09:00", "(GMT+09:00) Sapporo"],
  ["Seoul", "GMT+09:00", "(GMT+09:00) Seoul"],
  ["Tokyo", "GMT+09:00", "(GMT+09:00) Tokyo"],
  ["Yakutsk", "GMT+09:00", "(GMT+09:00) Yakutsk"],
  ["Adelaide", "GMT+09:30", "(GMT+09:30) Adelaide"],
  ["Darwin", "GMT+09:30", "(GMT+09:30) Darwin"],
  ["Brisbane", "GMT+10:00", "(GMT+10:00) Brisbane"],
  ["Canberra", "GMT+10:00", "(GMT+10:00) Canberra"],
  ["Guam", "GMT+10:00", "(GMT+10:00) Guam"],
  ["Hobart", "GMT+10:00", "(GMT+10:00) Hobart"],
  ["Melbourne", "GMT+10:00", "(GMT+10:00) Melbourne"],
  ["Port Moresby", "GMT+10:00", "(GMT+10:00) Port Moresby"],
  ["Sydney", "GMT+10:00", "(GMT+10:00) Sydney"],
  ["Vladivostok", "GMT+10:00", "(GMT+10:00) Vladivostok"],
  ["Kamchatka", "GMT+11:00", "(GMT+11:00) Kamchatka"],
  ["Magadan", "GMT+11:00", "(GMT+11:00) Magadan"],
  ["New Caledonia", "GMT+11:00", "(GMT+11:00) New Caledonia"],
  ["Solomon Is.", "GMT+11:00", "(GMT+11:00) Solomon Is."],
  ["Auckland", "GMT+12:00", "(GMT+12:00) Auckland"],
  ["Fiji", "GMT+12:00", "(GMT+12:00) Fiji"],
  ["Marshall Is.", "GMT+12:00", "(GMT+12:00) Marshall Is."],
  ["Wellington", "GMT+12:00", "(GMT+12:00) Wellington"],
  ["Nuku'alofa", "GMT+13:00", "(GMT+13:00) Nuku'alofa"],
];
// ref: http://api.rubyonrails.org/classes/ActiveSupport/TimeZone.html
const tzMap = {
  "International Date Line West": "Pacific/Midway",
  "Midway Island": "Pacific/Midway",
  "American Samoa": "Pacific/Pago_Pago",
  Hawaii: "Pacific/Honolulu",
  Alaska: "America/Juneau",
  "Pacific Time (US & Canada)": "America/Los_Angeles",
  Tijuana: "America/Tijuana",
  "Mountain Time (US & Canada)": "America/Denver",
  Arizona: "America/Phoenix",
  Chihuahua: "America/Chihuahua",
  Mazatlan: "America/Mazatlan",
  "Central Time (US & Canada)": "America/Chicago",
  Saskatchewan: "America/Regina",
  Guadalajara: "America/Mexico_City",
  "Mexico City": "America/Mexico_City",
  Monterrey: "America/Monterrey",
  "Central America": "America/Guatemala",
  "Eastern Time (US & Canada)": "America/New_York",
  "Indiana (East)": "America/Indiana/Indianapolis",
  Bogota: "America/Bogota",
  Lima: "America/Lima",
  Quito: "America/Lima",
  "Atlantic Time (Canada)": "America/Halifax",
  Caracas: "America/Caracas",
  "La Paz": "America/La_Paz",
  Santiago: "America/Santiago",
  Newfoundland: "America/St_Johns",
  Brasilia: "America/Sao_Paulo",
  "Buenos Aires": "America/Argentina/Buenos_Aires",
  Montevideo: "America/Montevideo",
  Georgetown: "America/Guyana",
  Greenland: "America/Godthab",
  "Mid-Atlantic": "Atlantic/South_Georgia",
  Azores: "Atlantic/Azores",
  "Cape Verde Is.": "Atlantic/Cape_Verde",
  Dublin: "Europe/Dublin",
  Edinburgh: "Europe/London",
  Lisbon: "Europe/Lisbon",
  London: "Europe/London",
  Casablanca: "Africa/Casablanca",
  Monrovia: "Africa/Monrovia",
  UTC: "Etc/UTC",
  Belgrade: "Europe/Belgrade",
  Bratislava: "Europe/Bratislava",
  Budapest: "Europe/Budapest",
  Ljubljana: "Europe/Ljubljana",
  Prague: "Europe/Prague",
  Sarajevo: "Europe/Sarajevo",
  Skopje: "Europe/Skopje",
  Warsaw: "Europe/Warsaw",
  Zagreb: "Europe/Zagreb",
  Brussels: "Europe/Brussels",
  Copenhagen: "Europe/Copenhagen",
  Madrid: "Europe/Madrid",
  Paris: "Europe/Paris",
  Amsterdam: "Europe/Amsterdam",
  Berlin: "Europe/Berlin",
  Bern: "Europe/Berlin",
  Rome: "Europe/Rome",
  Stockholm: "Europe/Stockholm",
  Vienna: "Europe/Vienna",
  "West Central Africa": "Africa/Algiers",
  Bucharest: "Europe/Bucharest",
  Cairo: "Africa/Cairo",
  Helsinki: "Europe/Helsinki",
  Kyiv: "Europe/Kiev",
  Riga: "Europe/Riga",
  Sofia: "Europe/Sofia",
  Tallinn: "Europe/Tallinn",
  Vilnius: "Europe/Vilnius",
  Athens: "Europe/Athens",
  Istanbul: "Europe/Istanbul",
  Minsk: "Europe/Minsk",
  Jerusalem: "Asia/Jerusalem",
  Harare: "Africa/Harare",
  Pretoria: "Africa/Johannesburg",
  Kaliningrad: "Europe/Kaliningrad",
  Moscow: "Europe/Moscow",
  "St. Petersburg": "Europe/Moscow",
  Volgograd: "Europe/Volgograd",
  Samara: "Europe/Samara",
  Kuwait: "Asia/Kuwait",
  Riyadh: "Asia/Riyadh",
  Nairobi: "Africa/Nairobi",
  Baghdad: "Asia/Baghdad",
  Tehran: "Asia/Tehran",
  "Abu Dhabi": "Asia/Muscat",
  Muscat: "Asia/Muscat",
  Baku: "Asia/Baku",
  Tbilisi: "Asia/Tbilisi",
  Yerevan: "Asia/Yerevan",
  Kabul: "Asia/Kabul",
  Ekaterinburg: "Asia/Yekaterinburg",
  Islamabad: "Asia/Karachi",
  Karachi: "Asia/Karachi",
  Tashkent: "Asia/Tashkent",
  Chennai: "Asia/Kolkata",
  Kolkata: "Asia/Kolkata",
  Mumbai: "Asia/Kolkata",
  "New Delhi": "Asia/Kolkata",
  Kathmandu: "Asia/Kathmandu",
  Astana: "Asia/Dhaka",
  Dhaka: "Asia/Dhaka",
  "Sri Jayawardenepura": "Asia/Colombo",
  Almaty: "Asia/Almaty",
  Novosibirsk: "Asia/Novosibirsk",
  Rangoon: "Asia/Rangoon",
  Bangkok: "Asia/Bangkok",
  Hanoi: "Asia/Bangkok",
  Jakarta: "Asia/Jakarta",
  Krasnoyarsk: "Asia/Krasnoyarsk",
  Beijing: "Asia/Shanghai",
  Chongqing: "Asia/Chongqing",
  "Hong Kong": "Asia/Hong_Kong",
  Urumqi: "Asia/Urumqi",
  "Kuala Lumpur": "Asia/Kuala_Lumpur",
  Singapore: "Asia/Singapore",
  Taipei: "Asia/Taipei",
  Perth: "Australia/Perth",
  Irkutsk: "Asia/Irkutsk",
  Ulaanbaatar: "Asia/Ulaanbaatar",
  Seoul: "Asia/Seoul",
  Osaka: "Asia/Tokyo",
  Sapporo: "Asia/Tokyo",
  Tokyo: "Asia/Tokyo",
  Yakutsk: "Asia/Yakutsk",
  Darwin: "Australia/Darwin",
  Adelaide: "Australia/Adelaide",
  Canberra: "Australia/Melbourne",
  Melbourne: "Australia/Melbourne",
  Sydney: "Australia/Sydney",
  Brisbane: "Australia/Brisbane",
  Hobart: "Australia/Hobart",
  Vladivostok: "Asia/Vladivostok",
  Guam: "Pacific/Guam",
  "Port Moresby": "Pacific/Port_Moresby",
  Magadan: "Asia/Magadan",
  Srednekolymsk: "Asia/Srednekolymsk",
  "Solomon Is.": "Pacific/Guadalcanal",
  "New Caledonia": "Pacific/Noumea",
  Fiji: "Pacific/Fiji",
  Kamchatka: "Asia/Kamchatka",
  "Marshall Is.": "Pacific/Majuro",
  Auckland: "Pacific/Auckland",
  Wellington: "Pacific/Auckland",
  "Nuku'alofa": "Pacific/Tongatapu",
  "Tokelau Is.": "Pacific/Fakaofo",
  "Chatham Is.": "Pacific/Chatham",
  Samoa: "Pacific/Apia",
};

const timeData = {
  "12:00 AM": "0",
  "12:30 AM": "30",
  "1:00 AM": "60",
  "1:30 AM": "90",
  "2:00 AM": "120",
  "2:30 AM": "150",
  "3:00 AM": "180",
  "3:30 AM": "210",
  "4:00 AM": "240",
  "4:30 AM": "270",
  "5:00 AM": "300",
  "5:30 AM": "330",
  "6:00 AM": "360",
  "6:30 AM": "390",
  "7:00 AM": "420",
  "7:30 AM": "450",
  "8:00 AM": "480",
  "8:30 AM": "510",
  "9:00 AM": "540",
  "9:30 AM": "570",
  "10:00 AM": "600",
  "10:30 AM": "630",
  "11:00 AM": "660",
  "11:30 AM": "690",
  "12:00 PM": "720",
  "12:30 PM": "750",
  "1:00 PM": "780",
  "1:30 PM": "810",
  "2:00 PM": "840",
  "2:30 PM": "870",
  "3:00 PM": "900",
  "3:30 PM": "930",
  "4:00 PM": "960",
  "4:30 PM": "990",
  "5:00 PM": "1020",
  "5:30 PM": "1050",
  "6:00 PM": "1080",
  "6:30 PM": "1110",
  "7:00 PM": "1140",
  "7:30 PM": "1170",
  "8:00 PM": "1200",
  "8:30 PM": "1230",
  "9:00 PM": "1260",
  "9:30 PM": "1290",
  "10:00 PM": "1320",
  "10:30 PM": "1350",
  "11:00 PM": "1380",
  "11:30 PM": "1410",
};

const DefaultTimezone = "Pacific Time (US & Canada)";
const DefaultDatetimeFormat = "MMM D, YYYY hh:mm A";

module.exports = {
  tzData,
  tzMap,
  DefaultTimezone,
  DefaultDatetimeFormat,
  timeData,
};
