import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { containBound } from "../../../lib/map";

import { MapBoundType, MapMarkerType } from "../../../types";

import { runQuery } from "../../http";

export const getMapMarkersInBounds = async (
  bounds: MapBoundType,
  excludeId: string[],
  targets: string[],
  types: string[] = ["event", "profile"]
): Promise<{ data: MapMarkerType[]; foundMarkers: MapMarkerType[] }> => {
  console.debug("[Service] getMapMarkersInBounds", {
    bounds,
    excludeId,
    targets,
    types,
  });

  const southwest = new Parse.GeoPoint(
    bounds.southWest.latitude,
    bounds.southWest.longitude
  );
  const northeast = new Parse.GeoPoint(
    bounds.northEast.latitude,
    bounds.northEast.longitude
  );

  const parseResponse = await runQuery("MapMarker", (query: Parse.Query) => {
    query.withinGeoBox("position", southwest, northeast);
    query.notContainedIn("objectID", excludeId);
    if (!_.isEmpty(targets)) {
      query.containedIn("targetId", targets);
    }
    query.containedIn("for", types);
    query.limit(100);
    return query.find();
  });

  const data = _.map(convertObject(parseResponse), (marker: MapMarkerType) =>
    Object.assign(marker, { location: marker.position })
  );
  const foundMarkers = _.filter(data, marker => {
    return containBound(bounds, marker.position);
  });

  return { data, foundMarkers };
};
