import { runFunction } from "../../http";
import _ from "underscore";
import { PostFile } from "../../../types/Post/PostFile";
import { Image } from "../../../types/Post/Image";
import { convertObject } from "../../../lib/utils";
import { DictStrict } from "../../../types";

interface Options {
  per: number;
  page: number;
  groupId: string;
  fileType: "photo" | "file";
}

export interface PhotoOptions extends Options {
  fileType: "photo";
}

export interface FileOptions extends Options {
  fileType: "file";
}

export const getGroupFiles = async <T extends Options>(
  options: T
): Promise<DictStrict<T extends FileOptions ? PostFile : Image>> => {
  console.debug("[Service] getGroupFiles", options);

  const parseResponse: T extends FileOptions
    ? Parse.Object<PostFile>[]
    : Parse.Object<Image>[] = await runFunction("listGroupFiles", options, {
    requireUser: true,
  });

  return _.object(
    _.map(parseResponse, value => {
      return [value.id, convertObject(value)];
    })
  );
};
