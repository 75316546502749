import React from "react";

import { Media } from "reactstrap";

export type MediaLinkProps = {
  imageUrl?: string | null;
  metadata: {
    image?: string | null;
    title?: string;
  };
  url?: string;

  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const MediaLink: React.FC<MediaLinkProps> = ({
  url,
  imageUrl,
  metadata,

  onClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.debug(`AnnouncementListCardLink handleClick url: ${url}`);

    onClick?.(e);
  };

  return (
    <Media
      onClick={handleClick}
      className="d-flex align-items-center bg-primary text-white p-2 mt-3"
      href={url}
      title={metadata?.title}
      tag="a"
      target="_blank"
      rel="noopener noreferrer">
      <Media
        left
        style={{ width: "80px", maxHeight: "80px" }}
        className="d-flex align-items-center mr-3 ">
        <Media
          /**
           * @FIXME Temporary typing workaround
           * Needs props type improvement (union type) to work properly.
           */
          src={imageUrl || metadata?.image || undefined}
          alt={metadata?.title}
          title={metadata?.title}
          style={{ maxWidth: "80px", maxHeight: "80px" }}
        />
      </Media>
      <Media body className="text-truncate">
        <h6 className="text-decoration-none">{metadata?.title}</h6>
        <p className="text-small font-weight-light">{url}</p>
      </Media>
    </Media>
  );
};
