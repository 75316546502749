import { runFunction } from "../../http";

export const markAllUnreadNotificationsAsRead = async (): Promise<void> => {
  console.debug("[Service] markAllUnreadNotificationsAsRead");

  return runFunction(
    "markAllUnreadNotificationsAsRead",
    {},
    { requireUser: true }
  );
};
