import React, { useState } from "react";

import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import { textThemingWorkaround } from "../../../../constants";
import { AnnouncementType } from "../../../../types";

import { useTheme } from "../../../hooks";

import { IconFontAwesome } from "../..";

import { AnnouncementListDropdownItem } from ".";

export type AnnouncementListDropdownProps = {
  announcementType: string | null;
  announcementTypes: AnnouncementType[];
  className?: string;

  onValueChange?: (
    type: AnnouncementType,
    options?: { forceListReset?: boolean }
  ) => void;
};

/** @TODO Consider to use UI/Dropdown component instead */
export const AnnouncementListDropdown: React.FC<AnnouncementListDropdownProps> = ({
  announcementType,
  announcementTypes,
  className,

  onValueChange,
}) => {
  /**
   * @TODO
   * This state property is introduced for keeping initial dropdown value.
   * That helps to avoid unnecessary API calls and rerenders when user choose
   * previously selected value. This logic should be moved to container for
   * keeping same logic in web and native and for keeping AnnouncementList logic
   * in one place.
   */
  const [currentValue, setCurrentValue] = useState(announcementType);

  const handleValueChange = (type: AnnouncementType): void => {
    if (type.id === currentValue) return;

    console.debug(`AnnouncementListDropdown valueChange: ${type.id}`);

    /**
     * This value is set a moment before container's state change and new props
     * passing. Prevents feeling UI is delayed.
     * */
    setCurrentValue(type.id);

    onValueChange?.(type, { forceListReset: true });
  };

  const {
    button,
    card,
    text: { listTitle },
  } = useTheme();

  const styles = {
    dropdownToggle: {
      ...button,
      borderRadius: 50,
      borderColor: "transparent",
      alignSelf: "flex-end",
      height: 35,
      backgroundColor: card.backgroundColor,
      width: 130,
    },
    dropdownToggleParagraph: {
      ...listTitle,
      ...textThemingWorkaround,
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    dropdownToggleIcon: {
      color: button.backgroundColor,
    },
  } as const;

  /**
   * @TODO
   * Following logic is originally implemented in native/UI/Dropdown
   * Reconsider container improvement by passing whole AnnouncementType
   * as announcementType prop instead of passing announcement type id string.
   * That is obvious performance issue.
   */
  let i = 0,
    label = "";

  while (i < announcementTypes.length) {
    if (announcementTypes[i].id === announcementType) {
      label = announcementTypes[i].name;
      break;
    }
    i++;
  }

  const dropdownItemElements = announcementTypes.map(type => (
    <AnnouncementListDropdownItem
      type={type}
      onClick={handleValueChange}
      key={type.id || 0}
    />
  ));

  return (
    <UncontrolledDropdown className={className}>
      <DropdownToggle
        className="d-flex justify-content-between"
        id={"announcementTypesDropdownMenuBtn"}
        style={styles.dropdownToggle}>
        <p
          className="align-self-center mb-0"
          style={styles.dropdownToggleParagraph}>
          {label}
        </p>
        <IconFontAwesome
          className="align-self-center mr-1"
          name="angle-down"
          style={styles.dropdownToggleIcon}
        />
      </DropdownToggle>
      <DropdownMenu className="announcement-list-dropdown">
        {dropdownItemElements}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
