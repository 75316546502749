import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { MentionSearchLinkInfo } from "../../../types";

import { runQuery } from "../../http";

export const searchResource = async (
  text: RegExp
): Promise<MentionSearchLinkInfo[] | undefined> => {
  console.debug("[Service] searchResource");

  const parseResponse = await runQuery("ContentPage", (query: Parse.Query) => {
    query.matches("name", text, "i");
    query.limit(10);
    return query.find();
  });
  if (!_.isEmpty(parseResponse)) {
    return convertObject(parseResponse).map(
      (item: { id: string; name: string }) => {
        return {
          id: item.id,
          name: item.name,
          url: "/content-page-text/" + item.id,
        };
      }
    );
  } else {
    return undefined;
  }
};
