/* eslint-disable @typescript-eslint/camelcase */
import {
  getWorkSpaces as _getWorkSpaces,
  getWorkSpace as _getWorkSpace,
} from "../../services/api";
import { startUp } from "../Setting/action";
import { logException } from "../../lib/utils";
import _ from "underscore";
import { WORK_SPACE_GET_ALL } from "./constants";
import { UserSet } from "./types";
import { AppThunkDispatch, GetState, AppThunkAction } from "../../types";
import { SettingLocalConfigData } from "../../types/Setting/SettingLocalConfigData";
import { USER_LOGIN_SET } from "../User/constants";
import { SETTING_RESET, LOCAL_CONFIG_SET } from "../Setting/constants";
import i18n from "../../middlewares/i18next";

export const getWorkSpace = (
  domain?: string,
  user?: { [key in UserSet]: string } | null,
  signUpProcess?: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getWorkSpace", domain);

  try {
    if (domain === undefined) {
      throw new Error(i18n.t("WorkSpace:Container.Action.Message"));
    }

    const validDomain: string = domain && domain.trim().toLowerCase();
    const resp = await _getWorkSpace(validDomain);

    if ("errors" in resp && !_.isEmpty(resp.errors)) {
      throw new Error(i18n.t("WorkSpace:Container.Action.Message"));
    } else {
      const localConfig = getState().setting.localConfig;
      const { build, version } = localConfig as SettingLocalConfigData;
      (resp as SettingLocalConfigData).multi_tenant = false;
      (resp as SettingLocalConfigData).InMultiTenant = true;
      (resp as SettingLocalConfigData).signUpProcess = signUpProcess;
      (resp as SettingLocalConfigData).version = version;
      (resp as SettingLocalConfigData).build = build;

      user &&
        dispatch({
          type: USER_LOGIN_SET,
          data: user,
        });

      dispatch({
        type: SETTING_RESET,
      });

      dispatch({
        type: LOCAL_CONFIG_SET,
        data: resp as SettingLocalConfigData,
      });

      await dispatch(startUp());
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getWorkSpaces = (username?: string): AppThunkAction => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] getWorkSpaces", username);

  const validUsername = (username || "").toLowerCase().replace(/[-() ]/gi, "");

  try {
    if (!username) {
      throw new Error(
        i18n.t("WorkSpace:Container.Action.Message.Not.Provided")
      );
    }

    const resp = await _getWorkSpaces(validUsername);

    if (resp && !_.isEmpty(resp.apps)) {
      if (resp.apps.length === 1) {
        const { subdomain, user } = resp.apps[0];
        await dispatch(getWorkSpace(subdomain, user));
      } else {
        dispatch({
          type: WORK_SPACE_GET_ALL,
          data: _.sortBy(resp.apps, "name"),
        });
      }
    } else {
      throw new Error(
        `${i18n.t("WorkSpace:Container.Action.Message.Not.Found")} ${username}.`
      );
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
