import React, { FC, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Alert,
  Card,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { isDate, isEmpty, isString, map } from "underscore";

import UserPostsContainer from "../../../../containers/Post/UserPosts";
import UserGroupsContainer from "../../../../containers/Group/UserGroups";
import { MemberDetailLayoutProps } from "../../../../containers/Member/types";
import {
  displayDate,
  parseToArray,
  removeWhiteSpaces,
  shadeColor,
} from "../../../../lib/utils";
import {
  Dict,
  EmptyObject,
  UserCompanyHistoryEvent,
  UserDataState,
  UserExecutiveAssistant,
  UserExecutiveAssistantType,
  UserPersonalInfo,
  UserPersonalInfoType,
  UserProfessionalInfo,
  UserProfessionalInfoType,
  UserSocialMedia,
  UserSocialMediaType,
} from "../../../../types";

import { useTheme } from "../../../hooks";

import { GroupListByStatus, LoadingSpinner, StickyNavbar } from "../..";
import { PostList } from "../../Post/List";

import { MemberDetailHeader, MemberDetailInfoSections } from ".";

const initialSections: {
  "My Group": Partial<Dict<any>>;
  "Personal Info": Partial<
    Dict<string, UserPersonalInfo> & {
      "Spouse/Partner/Significant Other": any;
    }
  >;
  "Professional Info": Partial<Dict<string, UserProfessionalInfo>>;
  "Executive Assistant": Partial<Dict<string, UserExecutiveAssistant>>;
  "Social Media": UserSocialMediaType | EmptyObject;
} = {
  "My Group": {},
  "Personal Info": {},
  "Professional Info": {},
  "Executive Assistant": {},
  "Social Media": {},
};

const initialVisibilities: {
  "My Group": Dict<boolean> | EmptyObject;
  "Personal Info":
    | (Dict<boolean, UserPersonalInfo> & {
        "Spouse/Partner/Significant Other": boolean;
      })
    | EmptyObject;
  "Professional Info": Dict<boolean, UserProfessionalInfo> | EmptyObject;
  "Executive Assistant": Dict<boolean, UserExecutiveAssistant> | EmptyObject;
  "Social Media": Dict<boolean, UserSocialMedia> | EmptyObject;
} = {
  "My Group": {},
  "Personal Info": {},
  "Professional Info": {},
  "Executive Assistant": {},
  "Social Media": {},
};

export const MemberDetail: FC<MemberDetailLayoutProps> = ({
  data,
  error,
  isAdmin,
  isMyProfile,
  setting,

  addFollows,
  createChannel,
  loginAsThisUser,
  removeFollows,
}) => {
  const {
    navigation: {
      backgroundColor,
      icon: { color },
    },
  } = useTheme();

  const [sending, setSending] = useState(false);

  const mainTabNames = ["Info", "Posts", "Groups"] as const;

  type MainTabName = typeof mainTabNames[number];

  const [activeMainTab, setActiveMainTab] = useState<MainTabName>("Info");

  const isActiveMainTab = (tab: MainTabName): boolean => activeMainTab === tab;

  const toggleMainTab = (tab: MainTabName): void => {
    if (!isActiveMainTab(tab)) setActiveMainTab(tab);
  };

  const { id: memberId } = useParams<{ id: string }>();

  useEffect(() => {
    setActiveMainTab("Info");
  }, [memberId]);

  const history = useHistory();

  const { t } = useTranslation("Member");

  const backgroundColorDarker =
    shadeColor(backgroundColor, -10) || backgroundColor;

  const memberDetailStyles = {
    card: {
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor,
    },
    nav: {
      width: "100%",
    },
    navbar: {
      flexDirection: "column",
      backgroundColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
    navItem: {
      flex: 1,
      textAlign: "center",
    },
    tabLink: {
      backgroundColor: backgroundColorDarker,
      color,
    },
    tabLinkActive: {
      backgroundColor,
      borderColor: color,
    },
    tabPane: {
      backgroundColor: "transparent",
    },
  } as const;

  if (!data) {
    if (error) {
      return (
        <Alert classnName="mt-2 mb-0 text-center" color="danger">
          {error}
        </Alert>
      );
    } else return <LoadingSpinner />;
  }

  const { sections, visibilities } = data?.profile ?? {
    sections: initialSections,
    visibilities: initialVisibilities,
  };

  const { id, followed, partnerProfile } = data;

  const { joinAt } = data as UserDataState;

  const professional = sections["Professional Info"];
  const professionalVisibilities = visibilities["Professional Info"];
  const assistant = sections["Executive Assistant"];
  const assistantVisibilities = visibilities["Executive Assistant"];
  const personal = sections["Personal Info"];
  const personalVisibilities = visibilities["Personal Info"];
  const socialMedia = sections["Social Media"];
  visibilities["Social Media"]["Google Plus"] = false;
  const socialMediaVisibilities = visibilities["Social Media"];

  function notEmpty<TValue>(item: TValue | null): item is TValue {
    return item !== null;
  }

  const professionalFields = map(
    professional as Dict<string, UserProfessionalInfo>,
    (value, label) => {
      if (
        label === "Company History" ||
        label === "Summary" ||
        isEmpty(value) ||
        value === "+1"
      )
        return null;
      if (label === "Phone") {
        value = removeWhiteSpaces(value);
      }
      if ((professionalVisibilities as any)[label] === false) return null;
      if (label === "Location") {
        label = "Business Address";
      }
      return { label, value };
    }
  )
    .filter(notEmpty)
    .filter(item => item && isString(item.value));

  const hasObjectEmptyValues = (
    obj: Partial<
      | UserProfessionalInfoType
      | UserPersonalInfoType
      | UserSocialMediaType
      | UserExecutiveAssistantType
      | UserCompanyHistoryEvent[]
    >
  ): boolean =>
    !Object.values(obj).some(x => x !== "" && x !== null && x.length !== 0);

  const summary = professionalVisibilities.Summary && professional.Summary;

  const companyHistory =
    !hasObjectEmptyValues(
      (parseToArray(
        professional["Company History"]
      ) as UserCompanyHistoryEvent[]).filter(value => value?.name !== "")
    ) &&
    professionalVisibilities["Company History"] &&
    parseToArray(professional["Company History"]);

  const professionalInfoAvailable =
    (!isEmpty(professionalFields) || summary || companyHistory) &&
    Object.values(professionalVisibilities).find(item => item);

  let assistantAvailable: Partial<UserExecutiveAssistantType> = {};

  Object.keys(assistant).forEach(value => {
    if (assistantVisibilities[value as UserExecutiveAssistant]) {
      assistantAvailable = {
        ...assistantAvailable,
        [value]:
          value !== "Phone"
            ? assistant[value as UserExecutiveAssistant]
            : removeWhiteSpaces(assistant[value]),
      };
    }
  });

  const personalFields = map(personal, (value, label) => {
    if (
      label === "Bio" ||
      label === "Spouse" ||
      label === "Children" ||
      label === "Email" ||
      label === "Phone" ||
      (isEmpty(value) && !isDate(value)) ||
      value === "+1"
    )
      return null;
    if ((personalVisibilities as any)[label] === false) return null;
    if (label === "Phone" || label === "Phone (Other)") {
      value = removeWhiteSpaces(value);
    }
    if (label === "Birthday") {
      const date = value && displayDate(value, "MMMM DD");
      value = date !== "Invalid date" ? date : null;
    }
    if (label === "Location") {
      label = "Home Address";
    }
    return { label, value };
  })
    .filter(notEmpty)
    .filter(item => item && isString(item.value));

  const memberBio = personalVisibilities.Bio && personal.Bio;

  const memberSpouse =
    personalVisibilities.Spouse &&
    (personal as UserPersonalInfoType).Spouse?.Name &&
    parseToArray(personal.Spouse);

  const memberChildren =
    personalVisibilities.Children && parseToArray(personal.Children);

  const personalInfoAvailable =
    (!isEmpty(personalFields) || memberSpouse || memberChildren || memberBio) &&
    Object.values(personalVisibilities).find(item => item);

  const memberEmail =
    !isEmpty(personal.Email) && personalVisibilities.Email && personal.Email;
  const memberPhone =
    !isEmpty(personal.Phone) && personalVisibilities.Phone && personal.Phone;

  const contactAvailable =
    memberEmail ||
    memberPhone ||
    Object.values(socialMediaVisibilities).find(item => item);

  const name = `${data?.firstName} ${data?.lastName}`;

  const onOpenChat = async (): Promise<void> => {
    setSending(true);
    try {
      const channel = await createChannel([{ id }]);
      setSending(false);
      history.push("/chat-room-detail/" + channel.data);
    } catch (e) {
      setSending(false);
    }
  };

  const onFollow = async (): Promise<void> => {
    setSending(true);
    try {
      if (!followed) {
        await addFollows(id, toast.success);
        setSending(false);
      } else {
        await removeFollows(id, toast.error);
        setSending(false);
      }
    } catch (e) {
      setSending(false);
    }
  };

  const joinAtDateHeading = t("Detail.Title.Joined");
  const noUserInfoHeading = t("Detail.Title.User.Has.Not.Shared.Information");

  const userPostsTabName =
    window.screen.width > 600
      ? t("Detail.Heading.Someone.Post", {
          data,
        })
      : t("Detail.Heading.Posts");

  const navItemElements = mainTabNames.map(mainTabName => (
    <NavItem key={mainTabName} style={memberDetailStyles.navItem}>
      <NavLink
        id={`memberDetail${mainTabName}Tab`}
        style={{
          ...memberDetailStyles.tabLink,
          ...(isActiveMainTab(mainTabName) && memberDetailStyles.tabLinkActive),
        }}
        onClick={(): void => {
          toggleMainTab(mainTabName);
        }}>
        {mainTabName === "Posts" && userPostsTabName}
        {mainTabName !== "Posts" && t(`Detail.Heading.${mainTabName}`)}
      </NavLink>
    </NavItem>
  ));

  return (
    <div>
      {!!error && (
        <Alert classnName="mt-2 mb-0 text-center" color="danger">
          {error}
        </Alert>
      )}
      <Card style={memberDetailStyles.card}>
        <MemberDetailHeader
          avatar={data?.avatar}
          followed={followed}
          isMyProfile={isMyProfile}
          joinAt={joinAt}
          joinAtDateHeading={joinAtDateHeading}
          name={name}
          onFollow={onFollow}
          onOpenChat={onOpenChat}
          sending={sending}
          setting={setting}
          title={data?.title}
          loginAsThisUser={loginAsThisUser}
          isAdmin={isAdmin}
        />

        <StickyNavbar className="member-detail-sticky-navbar">
          <Navbar style={memberDetailStyles.navbar}>
            <Nav tabs style={memberDetailStyles.nav}>
              {navItemElements}
            </Nav>
          </Navbar>
        </StickyNavbar>
      </Card>
      <TabContent activeTab={activeMainTab}>
        <TabPane tabId="Info" style={memberDetailStyles.tabPane}>
          <MemberDetailInfoSections
            assistantAvailable={assistantAvailable}
            companyHistory={companyHistory}
            contactAvailable={contactAvailable}
            memberBio={memberBio}
            memberChildren={memberChildren}
            memberEmail={memberEmail}
            memberPhone={memberPhone}
            memberSpouse={memberSpouse}
            noUserInfoHeading={noUserInfoHeading}
            personalFields={personalFields}
            personalInfoAvailable={personalInfoAvailable}
            professionalFields={professionalFields}
            professionalInfoAvailable={professionalInfoAvailable}
            socialMedia={socialMedia}
            socialMediaVisibilities={socialMediaVisibilities}
            summary={summary}
            partnerProfile={partnerProfile}
          />
        </TabPane>
        <TabPane tabId="Posts" style={memberDetailStyles.tabPane}>
          {activeMainTab === "Posts" && (
            <UserPostsContainer Layout={PostList} isMixed={true} userId={id} />
          )}
        </TabPane>
        <TabPane tabId="Groups" style={memberDetailStyles.tabPane}>
          <UserGroupsContainer Layout={GroupListByStatus} userId={id} />
        </TabPane>
      </TabContent>
    </div>
  );
};
