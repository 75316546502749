import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from "underscore";

import { track } from "../../lib/track";

import {
  Dict,
  Group,
  MatchProps,
  MediaInputFileProcessed,
  RootState,
  SettingConfigData,
  SettingConfigGroupType,
  SettingState,
  UserCompanyHistoryEvent,
  UserDataState,
  UserProfileData,
} from "../../types";

import { getGroupsByGroupTypeName } from "../Group/action";

import { updateProfile, getCurrentUser, logOut, getIndustry } from "./action";
import { UserIndustrySetAction, UserSetAction } from "./types";

export type UserUpdateProfileStateProps = {
  user: UserDataState | {};
  setting: SettingState;
  template: {
    "Personal Info": string[];
    "Professional Info": string[];
    "Social Media": string[];
    "Executive Assistant"?: string[];
  };
  multipleSelectFields: string[];
};

export type UserUpdateProfileDispatchProps = {
  updateProfile: (
    data: UserProfileData,
    image: MediaInputFileProcessed | null
  ) => Promise<boolean>;
  getCurrentUser: (noStore?: boolean) => Promise<UserSetAction>;
  logOut: () => Promise<void>;
  getIndustry: () => Promise<UserIndustrySetAction>;
  getGroupsByGroupTypeName: (
    groupType: string,
    refresh?: boolean
  ) => Promise<{ data?: Group[] }>;
};

export type UserUpdateProfileLayoutProps = {
  active: boolean;
  avatar: MediaInputFileProcessed | null;
  data: UserProfileData | null;
  error: string | null;
  info: string;
  loading: boolean;
  multipleSelectFields: string[];
  options: Record<
    string,
    {
      id: string;
      name: string;
    }[]
  >;
  sending: boolean;
  setting: SettingState;
  template: {
    "Personal Info": string[];
    "Professional Info": string[];
    "Social Media": string[];
    "Executive Assistant"?: string[] | undefined;
  };
  user: {} | UserDataState;

  logOut: () => Promise<void>;
  onAddChildren: () => void;
  onAddCompanyHistory: () => void;
  onChangeAvatar: (avatar: MediaInputFileProcessed | null) => void;
  onHandleChange: (data: UserProfileData) => void;
  onRemoveChildren: () => void;
  onRemoveCompanyHistory: () => void;
  updateProfile: (callback?: (() => void) | undefined) => void;
};

export type UserUpdateProfileOwnProps = {
  Layout: ComponentType<UserUpdateProfileLayoutProps>;
} & MatchProps;

export type UserUpdateProfileProps = UserUpdateProfileOwnProps &
  UserUpdateProfileDispatchProps &
  UserUpdateProfileStateProps &
  WithTranslation;

export type Options = Dict<{ id: string; name: string }[]>;

type State = {
  error: string | null;
  sending: boolean;
  loading: boolean;
  refreshing: boolean;
  data: UserProfileData | null;
  avatar: MediaInputFileProcessed | null;
  options: Options;
  info: string;
};

class UpdateProfile extends Component<UserUpdateProfileProps, State> {
  constructor(props: UserUpdateProfileProps) {
    super(props);
    this.state = {
      error: null,
      sending: false,
      loading: false,
      refreshing: false,
      data: null,
      avatar: null,
      options: {},
      info: this.props.t("Container.UpdateProfile.State.Info"),
    };
  }

  componentDidMount(): void {
    this.fetchData(true);

    track("View Screen", {
      Screen: "edit-profile",
      Params: this.props.match?.params,
    });
  }

  fetchData = (refresh: boolean, callback?: () => void): void => {
    const { getCurrentUser } = this.props;
    const { refreshing } = this.state;
    if (refreshing) {
      return;
    }

    this.setState({ refreshing: true, loading: true }, async () => {
      try {
        const resp = await getCurrentUser(true);
        if (resp.data) {
          const data = resp.data.profile;
          this.setState({ data });
        }
        this.fetchRawData();
        this.setState({ refreshing: false, error: null });
        callback?.();

        track("View Edit Profile");
      } catch (error) {
        this.setState({ refreshing: false, loading: true, error: error });
      }
    });
  };

  fetchRawData = async (): Promise<void> => {
    const { setting, getIndustry, getGroupsByGroupTypeName } = this.props;
    const { options } = this.state;
    const config = (setting as SettingState).config as SettingConfigData;
    if (!config.blenderboxImprovements) {
      const resp = await getIndustry();
      options["Industry"] = resp.data || [];
      this.setState({ options, loading: false });
    } else {
      /**
       * @TODO Is it intended to clear options?
       * If yes we should not redefine const options but instead have variable resultOptions, set it accordingly and pass in setState()
       */
      const options: Options = {};
      await Promise.all(
        (config.blenderboxProfileGroups || []).map(
          async (group: SettingConfigGroupType) => {
            if (group && group.groupType && group.name) {
              const resp = await getGroupsByGroupTypeName(group.groupType);
              options[group.name] = resp.data || [];
            }
          }
        )
      );
      this.setState({ options, loading: false });
    }
  };

  onHandleChange = (data: UserProfileData): void => {
    track("Edit Profile Data");

    return this.setState({ data });
  };

  onAddChildren = (): void => {
    const { data } = this.state;
    if (data) {
      data.sections["Personal Info"]["Children"] = (
        data.sections["Personal Info"]["Children"] || []
      ).concat([{}]);
      this.onHandleChange(data);
    }
  };

  onRemoveChildren = (): void => {
    const { data } = this.state;
    if (data && !_.isEmpty(data.sections["Personal Info"]["Children"])) {
      data.sections["Personal Info"]["Children"].pop();
      this.onHandleChange(data);
    }
  };

  onAddCompanyHistory = (): void => {
    const { data } = this.state;
    if (data) {
      data.sections["Professional Info"]["Company History"] = (
        data.sections["Professional Info"]["Company History"] || []
      )
        // temporary solution
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        .concat([{}]);
      this.onHandleChange(data);
    }
  };

  onRemoveCompanyHistory = (): void => {
    const { data } = this.state;
    if (
      data &&
      !_.isEmpty(data.sections["Professional Info"]["Company History"])
    ) {
      (data.sections["Professional Info"][
        "Company History"
      ] as UserCompanyHistoryEvent[]).pop();
      this.onHandleChange(data);
    }
  };

  onChangeAvatar = (avatar: MediaInputFileProcessed | null): void => {
    this.setState({ avatar });
    track("Upload Profile Photo");
  };

  updateProfile = (callback?: () => void): void => {
    const { updateProfile } = this.props;
    const { sending, data, avatar } = this.state;
    if (sending || !data) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        await updateProfile(data, avatar);
        this.setState({ sending: false, error: null });
        callback?.();

        track("Edit Profile", {
          Sections: data.sections,
          Visibilities: data.visibilities,
        });
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const {
      Layout,
      multipleSelectFields,
      setting,
      template,
      user,

      logOut,
    } = this.props;
    const { error, sending, data, avatar, options, info, loading } = this.state;

    return (
      <Layout
        active={(user as UserDataState).status === "active"}
        avatar={avatar}
        data={data}
        error={error}
        info={info}
        loading={loading}
        logOut={logOut}
        multipleSelectFields={multipleSelectFields}
        onAddChildren={this.onAddChildren}
        onAddCompanyHistory={this.onAddCompanyHistory}
        onChangeAvatar={this.onChangeAvatar}
        onHandleChange={this.onHandleChange}
        onRemoveChildren={this.onRemoveChildren}
        onRemoveCompanyHistory={this.onRemoveCompanyHistory}
        options={options}
        sending={sending}
        setting={setting}
        template={template}
        updateProfile={this.updateProfile}
        user={user}
      />
    );
  };
}

const mapStateToProps = (state: RootState): UserUpdateProfileStateProps => {
  const { config } = state.setting;
  let multipleSelectFields: string[] = [];
  let template: {
    "Personal Info": string[];
    "Professional Info": string[];
    "Social Media": string[];
    "Executive Assistant"?: string[];
    /*
    @TODO I18N Change keys for values(strings) to not be equal
     */
  } = {
    "Personal Info": [
      "Alma Mater",
      "Birthday",
      "Email",
      "Location",
      "Phone",
      "Phone (Other)",
      "Website",
      "Bio",
      "Interests & Hobbies",
      "Spouse",
      "Children",
    ],
    "Professional Info": [
      "Title",
      "Summary",
      "Company Name",
      "Location",
      "Phone",
      "Website",
      "Company History",
      "Industry",
    ],
    "Executive Assistant": ["Name", "Phone", "Email"],
    "Social Media": ["Facebook", "Linkedin", "Instagram", "Twitter"],
  };
  if (config && config.profileTemplate) {
    template = config.profileTemplate;
  } else if (config && config.blenderboxImprovements) {
    template = {
      "Personal Info": ["Email", "Phone", "Website"],
      "Professional Info": [
        "Title",
        "Summary",
        "Company Name",
        "Location",
        "Phone",
        "Website",
        "Industry",
        "Region",
        "Company History",
      ],
      "Social Media": ["Facebook", "Linkedin", "Instagram", "Twitter"],
    };
    multipleSelectFields = ["Industry", "Region"];
  }
  return {
    user: state.user.data || {},
    setting: state.setting,
    template,
    multipleSelectFields,
  };
};

const mapDispatchToProps = {
  updateProfile,
  getCurrentUser,
  logOut,
  getIndustry,
  getGroupsByGroupTypeName,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("User")(UpdateProfile));
