import { adminPost } from "../../http";
import { InvoiceOption, Config } from "../../../types";

export const createInvoice = async (
  // eslint-disable-next-line @typescript-eslint/camelcase
  attendee_id: string,
  items: number[],
  options: InvoiceOption[] | number[],
  config: Config
): Promise<{ invoice_id: string }> => {
  console.debug("[Service] createInvoice");

  return await adminPost(
    "invoices/create",
    // eslint-disable-next-line @typescript-eslint/camelcase
    { attendee_id, items, options },
    {
      requireUser: true,
      config,
    }
  );
};
