import { UserDataState, SettingLocalConfigData } from "../../../types";
import MixPanel from "mixpanel-browser";

import { logException } from "../../../lib/utils";
import { Dict } from "../../../types";

export async function registerMixPanel(
  user: UserDataState,
  localConfig: SettingLocalConfigData | null
): Promise<void> {
  const { mixpanel } = localConfig || {};
  const { token } = mixpanel || {};
  try {
    if (token) {
      await MixPanel.init(token);
      //eslint-disable-next-line
      // @ts-ignore
      window.track = (event: string, properties?: Dict): void => {
        MixPanel.track(event, properties);
      };
    }

    //eslint-disable-next-line
    // @ts-ignore
    if (user && window.track) {
      MixPanel.identify(user.id);

      const userAttributes = {
        $name: `${user.firstName} ${user.lastName}`,
        $email: user.email,
        "User ID": user.id,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        "Phone Number": user.username,
        Admin: user.admin,
        "Last Activity": Date.now(),
      };
      MixPanel.people.set(userAttributes);
    }
  } catch (e) {
    logException(e);
  }
}
