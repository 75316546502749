import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Alert, Button, Card, CardBody, Col, Row } from "reactstrap";

import { PaymentDetailLayoutProps as PaymentDetailProps } from "../../../../containers/Payment/Detail";
import { currencyFormat, displayDate } from "../../../../lib/utils";

import { LoadingSpinner } from "../../UI";

import { Pay } from "../Pay";

export const PaymentDetail: React.FC<PaymentDetailProps> = ({
  data,
  error,
  reFetch,
  sending,
  setting,
  makePayment,
  exportInvoice,
}) => {
  const {
    checkPayableTo,
    checkSendTo,
    invoiceFooterTitle,
    invoiceFooterBody,
  } = setting;

  const check = {
    checkPayableTo,
    checkSendTo,
    title: invoiceFooterTitle,
    body: invoiceFooterBody,
  };

  const [isPayModalOpen, setPayModalOpen] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState("");

  const togglePayModal = (): void => {
    setPayModalOpen(!isPayModalOpen);
  };

  const { t } = useTranslation("Payment");

  if (!data) {
    return <LoadingSpinner />;
  } else {
    const { description, dueDate, total, status, items } = data;

    const paid = status === "paid";

    const paymentHeaderElement = (
      <CardBody id="paymentHeaderElement" className="bg-light p-3 mb-2">
        <h5>{description}</h5>
        <h6 className="text-primary font-weight-bold">
          {!paid && displayDate(dueDate, "ll")}
        </h6>
      </CardBody>
    );

    const paymentDetailsElement = !paid && (
      <CardBody className="bg-light p-3">
        <p className="mt-2 mb-1 font-weight-bold">
          {t("Detail.Heading.Checks.Payable")}:
        </p>
        <p>{check.checkPayableTo}</p>
        <p className="mt-4 mb-1 font-weight-bold">
          {t("Detail.Heading.Checks.Send")}:
        </p>
        <p>{check.checkSendTo}</p>
        <p className="mt-4 mb-1 font-weight-bold">
          {t("Detail.Heading.Checks.Send.By")}:
        </p>
        <p>{displayDate(dueDate, "MM-DD-YY")}</p>
      </CardBody>
    );

    const itemsElement = (
      <CardBody className="my-2 p-3 bg-light">
        {items.map((item, index) => {
          return (
            <Row key={index}>
              <Col>
                <p className="">
                  {item.description}
                  {` (x${item.quantity})`}
                </p>
              </Col>
              <Col className="text-right font-weight-bold">
                {`$${currencyFormat(item.price)}`}
              </Col>
            </Row>
          );
        })}
      </CardBody>
    );

    const totalElement = (
      <CardBody id="paymentTotalElement" className="mx-n2">
        <Row>
          <Col>
            <p className="font-weight-bold">{t("Detail.Heading.Total")}</p>
          </Col>
          <Col className="text-right text-primary font-weight-bold">{`$${currencyFormat(
            total || 0
          )}`}</Col>
        </Row>
      </CardBody>
    );

    const payByCreditCardElement = !paid ? (
      <Button
        block={true}
        color="danger"
        className="my-2"
        disabled={sending}
        onClick={togglePayModal}>
        {`${sending ? t("Detail.Button.Paying") : t("Detail.Button.Pay")}
           $${currencyFormat(total || 0)} ${t("Detail.Button.By.Credit.Card")}`}
      </Button>
    ) : (
      <Button disabled={true} block color="success" className="my-2">
        {t("Detail.Button.Paid")}
      </Button>
    );

    const checkElement = (
      <CardBody className="bg-light p-3 mt-3">
        <p className="font-weight-bold mb-1">{check.title}</p>
        <p>{check.body}</p>
      </CardBody>
    );

    if (!invoiceUrl) {
      exportInvoice(url => setInvoiceUrl(url));
    }

    const downloadButton = invoiceUrl && (
      <Button
        className="mt-2"
        block
        color="primary"
        id="DetailButtonDownloadInvoicePdf">
        <a
          href={invoiceUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#fff" }}>
          {t("Detail.Button.Download.Invoice.Pdf")}{" "}
        </a>
      </Button>
    );

    const _onPay = (
      payByCreditCard: boolean,
      token: string,
      callback: () => void
    ): void => {
      makePayment(token, () => {
        callback();
        reFetch(true);
        toast.success(t("Detail.Toast.Text"), {
          onClose: () => {
            reFetch(true);
          },
        });
      });
    };
    return (
      <>
        <Pay
          isPayModalOpen={isPayModalOpen}
          togglePayModal={togglePayModal}
          setting={setting}
          invoice={data}
          onProcess={_onPay}
          creditCardOnly={true}
        />
        <Card className="mb-2">
          <CardBody>
            {!!error && <Alert color="danger">{error}</Alert>}
            {paymentHeaderElement}
            {paymentDetailsElement}
            {itemsElement}
            {totalElement}
            {payByCreditCardElement}
            {downloadButton}
            {checkElement}
          </CardBody>
        </Card>
      </>
    );
  }
};
