export const correctBoundByRegion = location => {
  return {
    southWest: {
      latitude:
        location.latitude - location.latitudeDelta / 2 > -90
          ? location.latitude - location.latitudeDelta / 2
          : -90,
      longitude:
        location.longitude - location.longitudeDelta / 2 > -179.9
          ? location.longitude - location.longitudeDelta / 2
          : -179.9,
    },
    northEast: {
      latitude:
        location.latitude + location.latitudeDelta / 2 < 90
          ? location.latitude + location.latitudeDelta / 2
          : 90,
      longitude:
        location.longitude + location.longitudeDelta / 2 < 180
          ? location.longitude + location.longitudeDelta / 2
          : 179.9,
    },
  };
};

export const correctBound = bound => {
  return {
    southWest: {
      latitude: bound.southWest.latitude > -90 ? bound.southWest.latitude : -90,
      longitude:
        bound.southWest.longitude > -179.9 ? bound.southWest.longitude : -179.9,
    },
    northEast: {
      latitude: bound.northEast.latitude < 90 ? bound.northEast.latitude : 90,
      longitude:
        bound.northEast.longitude < 180 ? bound.northEast.longitude : 179.9,
    },
  };
};

export const containBound = (bound, position) => {
  return (
    bound.southWest.latitude < position.latitude &&
    position.latitude < bound.northEast.latitude &&
    bound.southWest.longitude < position.longitude &&
    position.longitude < bound.northEast.longitude
  );
};
