import React, { FC, useState } from "react";
import { cleanHTMLTags, displayDate } from "../../../../lib/utils";
import {
  ChatMutatedMessage,
  CustomFileType,
} from "../../../../types/Chat/ChatMutatedMessage";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { SendMessageParams } from "../../../../types/Chat/SendMessageParams";
import { UserMessage } from "sendbird";
import { ChatConversationFileMessage } from "./ChatConversationFileMessage";
import { MentionDisplay } from "../../Mention";
import { ChatConversationEditMessage } from "./ChatConversationEditMessage";
import "../../../styles/css/sendbird-uikit.css";
import { ChatConversationReadBadges } from "./ChatConversationReadBadges";

export type ChatConversationIncomingMessageProps = {
  id?: number;
  image?: CustomFileType;
  message: ChatMutatedMessage;

  deleteMessage: (data: UserMessage, callback?: () => void) => Promise<void>;
  onImageLoad?: () => void;
  updateMessage: (
    data: SendMessageParams & { id: number },
    cancel: boolean,
    callback?: () => void
  ) => Promise<void>;
};

export const ChatConversationIncomingMessage: FC<ChatConversationIncomingMessageProps> = ({
  id,
  image,
  message,

  deleteMessage,
  onImageLoad,
  updateMessage,
}) => {
  const [showDropdown, setDropdownVisibility] = useState(false);
  const [editing, setEditing] = useState(false);

  const deleteMsg = (): void => {
    //@FIXME remove object mutation in Chat, start from actions
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    deleteMessage(message);
  };

  const renderMessage = (): JSX.Element => {
    const messageType = message.messageType;
    return (
      <div
        id={`chatConversationIncomingMessage${id}`}
        className={
          messageType === "user"
            ? "sendbird-message--outgoing sendbird-message"
            : message.image !== null
            ? "sendbird-thumbnail"
            : "sendbird-file-message--outgoing"
        }>
        <div className="mt-3">
          <span className="text-black-50 text-small">
            {`${displayDate(message.createdAt, "hh:mm A")} ${
              message.isEdited ? "(edited)" : ""
            }`}
          </span>
        </div>
        <div
          className={
            messageType === "user"
              ? "sendbird-user-message--outgoing"
              : message.image !== null
              ? "sendbird-thumbnail__body"
              : "sendbird-file-message__outgoing"
          }>
          <Dropdown
            isOpen={showDropdown}
            toggle={(): void => setDropdownVisibility(prevState => !prevState)}>
            <DropdownToggle
              caret={false}
              className={`btn-link text-decoration-none bg-transparent border-0 text-primary ${
                messageType === "user"
                  ? "sendbird-user-message__more"
                  : message.image !== null
                  ? "sendbird-thumbnail__more"
                  : "sendbird-file-message__more"
              } ${showDropdown && "d-block"}`}>
              <i className="icon-options-vertical font-weight-bolder p-2" />
            </DropdownToggle>
            <DropdownMenu>
              {messageType === "user" && (
                <DropdownItem onClick={(): void => setEditing(true)}>
                  Edit
                </DropdownItem>
              )}
              <DropdownItem onClick={(): void => deleteMsg()}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {messageType === "user" ? (
            <div
              className="font-weight-light chat-font-size py-1 px-3 rounded-xl"
              style={{ backgroundColor: "rgba(41, 84, 163, 0.1)" }}>
              <MentionDisplay
                text={cleanHTMLTags(message.message || "")}
                allowRenderVideo={true}
                mentions={message.mentions}
                linkMentions={message.linksMentions}
              />
            </div>
          ) : (
            <ChatConversationFileMessage
              onImageLoad={onImageLoad}
              file={message.file ?? undefined}
              image={image}
              incoming={true}
            />
          )}
          {(message.readReceipts?.length ?? 0) > 0 && (
            <ChatConversationReadBadges
              readReceipts={message.readReceipts ?? []}
              position={{
                right: "100px",
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {!editing ? (
        renderMessage()
      ) : (
        <ChatConversationEditMessage
          updateMessage={updateMessage}
          setEditing={setEditing}
          message={message.message ?? ""}
          msgMentions={message.mentions ?? []}
          msgLinksMention={message.linksMentions ?? []}
          file={message.file}
          id={message.messageId}
        />
      )}
    </>
  );
};
