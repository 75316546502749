import React, { FC, useState } from "react";
import moment from "moment";
import { ChatConversationIncomingMessage } from "./ChatConversationIncomingMessage";
import { ChatConversationOutgoingMessage } from "./ChatConversationOutgoingMessage";
import { ChatConversationChatHeader } from "./ChatConversationChatHeader";
import { ChatConversationChatInput } from "./ChatConversationChatInput";
import { ChatMutatedMessage } from "../../../../types/Chat/ChatMutatedMessage";
import { ChatConversationDateSeparator } from "./ChatConversationDateSeparator";
import { InvertedInfiniteScroll } from "./ChatConversationInvertedInfiniteScroll";
import { SendMessageParams } from "../../../../types/Chat/SendMessageParams";

import "../../../styles/css/sendbird-uikit.css";
import { ChatDetailLayout } from "../../../../containers/Chat/Detail";

type Props = ChatDetailLayout;

export const ChatConversation: FC<Props> = ({
  active,
  channel,
  hasMore,
  inputField,
  loading,
  messages,
  text,

  deleteMessage,
  getMoreChat,
  onChangeText,
  setInputField,
  sendMessage,
  updateMessage,
}) => {
  const [forceScrollToBottom, setForceScrollToBottom] = useState(false);

  //this is temporary solution, useState creates too many re renders in this case
  let lastSeparator = 0;
  const renderChatMessage = (
    msg: ChatMutatedMessage,
    index: number
  ): JSX.Element => {
    const check = !moment(msg.createdAt).isSame(lastSeparator, "day");
    if (check && msg.createdAt) {
      lastSeparator = msg.createdAt;
    }
    return (
      <div key={index} className="sendbird-msg-hoc sendbird-msg--scroll-ref">
        {check && <ChatConversationDateSeparator date={msg.createdAt} />}

        {msg.isMine ? (
          <ChatConversationIncomingMessage
            id={index}
            message={msg}
            image={msg.image ?? undefined}
            deleteMessage={deleteMessage}
            updateMessage={updateMessage}
          />
        ) : (
          <ChatConversationOutgoingMessage
            message={msg}
            image={msg.image ?? undefined}
            file={msg.file ?? undefined}
            mentions={msg.mentions ?? []}
            linksMentions={msg.linksMentions ?? []}
          />
        )}
      </div>
    );
  };

  const send = async (data: SendMessageParams): Promise<void> => {
    await sendMessage(data);
    setForceScrollToBottom(true);
  };

  const channelName: string | undefined = channel?.displayName;

  const messagesElements =
    messages && messages.length > 0
      ? messages.map((msg, index) => renderChatMessage(msg, index))
      : [];

  return (
    <div id="chat-converstation" className="bg-white">
      <ChatConversationChatHeader
        user={channel?.user}
        link={
          text === "group" || text === "event"
            ? "/chat-room-detail/" + channel?.url
            : undefined
        }
        name={channelName}
        profileUrl={channel?.coverUrl}
      />

      <InvertedInfiniteScroll
        className="px-3"
        height={text === "group" ? "80vh" : text === "event" ? "50vh" : "74vh"}
        hasMore={hasMore}
        loadMore={(): Promise<void> => getMoreChat(true, false)}
        setForceScrollToBottom={
          forceScrollToBottom ? setForceScrollToBottom : undefined
        }
        isLoading={loading}>
        {messagesElements}
      </InvertedInfiniteScroll>

      <ChatConversationChatInput
        active={active}
        setInputField={setInputField}
        inputField={inputField}
        onChangeText={onChangeText}
        sendMessage={send}
        typing={"typing" in active ? active.typing : undefined}
      />
    </div>
  );
};
