import React, { useState } from "react";

import { Switch, Redirect } from "react-router-dom";
import { isEqual } from "underscore";

import { HistoryLocation, SettingState } from "../../../types";

import { ConfigContext } from "../../context";
import { useUserDataState, useGroupHome } from "../../hooks";
import { computeAppConfig, getQueryVariable } from "../../utils";

import {
  RouteUserForgotPassword,
  RouteUserPasscode,
  RouteUserRegister,
} from "..";

import {
  RedirectAppDefault,
  RouteAppProtected,
  RouteAppAuth,
  RouteAppGuest,
} from ".";

export type SwitchAppProps = {
  setting: SettingState;
};

const SwitchAppToMemoize: React.FC<SwitchAppProps> = ({
  setting: { config, feature, localConfig, setting },
}) => {
  const user = useUserDataState();
  const groupHome = useGroupHome();

  /**
   * This property stores HistoryLocation object to redirect to after sign in.
   *
   * This value is undefined by default. When protected route visited and user
   * not logged in, current HistoryLocation object is saved here and passed
   * as <Redirect /> path prop during successful signing in process. Then this
   * value is immediately overwritten as null. This allows to mark the difference
   * between signing in and signing out process when <RouteAppProtected /> is
   * matched.
   */
  // redirectLocation?: HistoryLocation | null;
  const [
    redirectLocation,
    _setRedirectLocation,
  ] = useState<HistoryLocation | null>();

  /** Sets state.redirectLocation if current value is not null */
  const setRedirectLocation = (newRedirectLocation: HistoryLocation): void => {
    if (redirectLocation !== null) {
      _setRedirectLocation(newRedirectLocation);
    }
  };

  /** Sets state.redirectLocation as null */
  const clearRedirectLocation = (): void => {
    _setRedirectLocation(null);
  };

  /** uid URL query used to prefill login form */
  const loginUserId =
    (redirectLocation && getQueryVariable("uid", redirectLocation)) ||
    undefined;

  const settingToCompute =
    config && feature && setting && localConfig
      ? {
          config,
          feature,
          localConfig,
          setting,
        }
      : undefined;

  const {
    navigationFeatures,
    paths,
    routes,
    navigationFeaturesMore,
  } = computeAppConfig(settingToCompute, user, groupHome);

  const redirectElement = (
    <RedirectAppDefault
      redirectLocation={redirectLocation}
      setting={settingToCompute}
      clearRedirectLocation={clearRedirectLocation}
    />
  );

  const routeAppGuestProps = {
    redirectElement,
    setting: settingToCompute,
    userId: loginUserId,
  };

  return (
    <ConfigContext.Provider
      value={{
        navigationFeatures,
        paths,
        routes,
        setting: settingToCompute,
        navigationFeaturesMore,
      }}>
      <Switch>
        {/** Old routes redirects - implementing modern convention */}
        <Redirect from="/forgot-passcode" to="/forgot-password" />
        <Redirect from="/signup" to="/sign-up" />

        {/** Main app part. Only for logged in users */}
        <RouteAppProtected
          path={paths}
          setting={settingToCompute}
          clearRedirectLocation={clearRedirectLocation}
          setRedirectLocation={setRedirectLocation}
        />

        {/** Gueast routes (for not logged in users) */}
        <RouteAppGuest
          path="/login"
          component={RouteAppAuth}
          {...routeAppGuestProps}
        />
        <RouteAppGuest
          path="/passcode/:tel"
          component={RouteUserPasscode}
          {...routeAppGuestProps}
        />
        <RouteAppGuest
          path="/sign-up"
          component={RouteUserRegister}
          {...routeAppGuestProps}
        />
        <RouteAppGuest
          path="/forgot-password"
          component={RouteUserForgotPassword}
          {...routeAppGuestProps}
        />
        {/** Default redirect - runs when path not available for user or not present in app */}
        {redirectElement}
      </Switch>
    </ConfigContext.Provider>
  );
};

/** Memoization implemented for trial. Probably finally won't be necessary. */
export const SwitchApp = React.memo(SwitchAppToMemoize, isEqual);
