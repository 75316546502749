import { AppItem, DictStrict } from "../../../types";
import { getDictDefinedValues } from "./getDictDefinedValues";

export const getFilteredDict = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  dataKeyName,
  removeKey: filterKey = "id",
  removeId,

  itemsDict,
}: {
  /** Dictionaty to filter */
  itemsDict: DictStrict<ItemType>;
  /**
   * Name of property storing nested entity data (and optional
   * reactionCount property)
   *
   * @FIXME
   * We have to specify this key name because of inconsistency in
   * all available Items shapes (nested data is stored under entity
   * name property). Unifing that property name and setting it
   * for example to "data" would simplify app data structures.
   */
  dataKeyName: DataKey;

  removeKey?: string;
  removeId: string;
}): DictStrict<ItemType> =>
  getDictDefinedValues(itemsDict)
    .filter(item => item[dataKeyName][filterKey] !== removeId)
    .reduce<DictStrict<ItemType>>(
      (prevVal, curVal) => ({
        ...prevVal,
        [curVal[dataKeyName].id]: curVal,
      }),
      {}
    );
