import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { getReactions } from "./action";
import { track } from "../../lib/track";
import {
  CommentType,
  Reaction,
  RootState,
  SettingSettingData,
  UserDataState,
} from "../../types";

type StateProps = {
  user: UserDataState | {};
  setting: SettingSettingData | {};
  data?: Reaction[];
  hasMore?: boolean;
};

type DispatchProps = {
  getReactions: (
    id: string,
    type: CommentType,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
};

export type CommentReactByLayoutProps = {
  data?: Reaction[];
  error: string | null;
  hasMore?: boolean;
  loading: boolean;
  refreshing: boolean;

  reFetch: (next: boolean, refresh: boolean, callback?: () => void) => void;
};

type OwnProps = {
  Layout: ComponentType<CommentReactByLayoutProps>;
  match: {
    params?: { id?: string; type?: CommentType };
    isExact?: boolean;
    path?: string;
    url?: string;
  };
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class ReactBy extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false, true);

    track("View Screen", {
      Screen: "react-by",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): void => {
    const { getReactions, match } = this.props;
    const { refreshing, loading } = this.state;

    if (refreshing || loading) {
      return;
    }

    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        if (!match?.params?.id) throw Error("Missing comment ID");
        if (!match?.params?.type) throw Error("Missing comment type");

        const { id, type } = match.params;
        await getReactions(id, type as CommentType, next, refresh);
        this.setState({ loading: false, refreshing: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, data, hasMore } = this.props;
    const { loading, error, refreshing } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        data={data}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { setting, comment, user } = state;
  const id = ownProps.match.params?.id;
  const { reactionsList } = comment;
  const { items, hasMore } = (id && reactionsList[id]) || {};

  return {
    user: user.data || {},
    setting: setting || {},
    data: items && Object.values(items),
    hasMore,
  };
};

const mapDispatchToProps = {
  getReactions: getReactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactBy);
