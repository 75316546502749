import { runFunction } from "../../http";

export const removeFollows = async (userIds: string[]): Promise<void> => {
  console.debug("[Service] removeFollows", userIds);
  await runFunction(
    "removeFollows",
    { userIds: userIds },
    { requireUser: true }
  );
};
