import { runFunction } from "../../http";
import _ from "underscore";
import { convertObject } from "../../../lib/utils";
import { Video } from "../../../types/VideoLibrary/Video";
import { Dict } from "../../../types";

export const getVideos = async (options: {
  per: number;
  page: number;
}): Promise<Dict<Video>> => {
  console.debug("[Service] getVideos", { options });

  const parseResponse: Dict<Parse.Object<Video>> = await runFunction(
    "getVideoLibraryVideos",
    options
  );
  return _.object(
    _.map(parseResponse, value => {
      return [value.id, convertObject(value)];
    })
  );
};
