/**
 * @TODO Non consistent naming. Should I change it?
 */
export const GROUPS_BY_TYPE_SET = "GROUPS_BY_TYPE_SET";
export const GROUP_ALL_SET = "GROUP_ALL_SET";
export const GROUP_MANAGEMENT_SET = "GROUP_MANAGEMENT_SET";
export const GROUP_MY_SET = "GROUP_MY_SET";
export const GROUP_PENDING_SET = "GROUP_PENDING_SET";
export const GROUP_POSTING_SET = "GROUP_POSTING_SET";
export const GROUP_RESET = "GROUP_RESET";
export const GROUP_SET = "GROUP_SET";
export const GROUP_SETTING_SET = "GROUP_SETTING_SET";
export const GROUP_STATUS_SET = "GROUP_STATUS_SET";
export const GROUP_TYPE_SET = "GROUP_TYPE_SET";
export const GROUP_TYPES_SET = "GROUP_TYPES_SET";
export const GROUP_USER_SET = "GROUP_USER_SET";
export const GROUP_SUGGESTED_POSTING_SET = "GROUP_SUGGESTED_POSTING_SET";
export const GROUP_TARGET_SET = "GROUP_TARGET_SET";
export const GROUP_HOME_SET = "GROUP_HOME_SET";
