import React from "react";

import classnames from "classnames";
import { contains } from "underscore";

import { EventSingle } from "../../../types";

import { EventDate, EventLocation, EventVirtualLocation } from ".";

export type EventInfoHeaderProps = {
  className?: string;
  event: EventSingle;
};

export const EventInfoHeader: React.FC<EventInfoHeaderProps> = ({
  className,
  event,
}) => {
  const {
    startAt,
    endAt,
    timezone,
    allDayEvent: isAllDayEvent,
    locationType,
    locationDetails,
    city,
    country,
    id,
    state,
    streetAddress1,
    title,
    venue,
  } = event;

  // const openInAppMap = (): void => {
  //   // Actions.maps({
  //   //   match: {
  //   //     params: {
  //   //       location: address || venue,
  //   //       target: id,
  //   //     },
  //   //   },
  //   // });
  // };

  // const getDirection = (): void => {
  //   // const location = address || venue;
  //   // openMap({
  //   //   query: location,
  //   //   navigate_mode: "navigate",
  //   //   travelType: "drive",
  //   // });
  // };

  // const openEventMap = (): void => {
  //   // ActionSheet.show(
  //   //   {
  //   //     options: [
  //   //       t("EventInfoHeader.Option.Get.Directions"),
  //   //       t("EventInfoHeader.Option.Display.On.Map"),
  //   //       t("EventInfoHeader.Option.Cancel"),
  //   //     ],
  //   //     cancelButtonIndex: 2,
  //   //   },
  //   //   buttonIndex => {
  //   //     buttonIndex === 0 && getDirection();
  //   //     buttonIndex === 1 && openInAppMap();
  //   //   }
  //   // );

  //   /** @TODO This logic should be moved to container */
  //   track("Click Event Location", {
  //     "Event ID": id,
  //     "Event Title": title,
  //   });
  // };

  const notAddressType = contains(["video", "audio"], locationType);

  const eventLocationElement = !notAddressType ? (
    <EventLocation
      city={city}
      country={country}
      eventId={id}
      eventTitle={title}
      state={state}
      streetAddress1={streetAddress1}
      venue={venue}
    />
  ) : (
    <EventVirtualLocation type={locationType} details={locationDetails} />
  );

  return (
    <div className={classnames(className)}>
      <EventDate
        id={id}
        endAt={endAt}
        isAllDayEvent={isAllDayEvent}
        startAt={startAt}
        timezone={timezone}
      />
      {eventLocationElement}
    </div>
  );
};
