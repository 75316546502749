import { ThunkDispatch } from "redux-thunk";

import {
  createNotification as _createNotification,
  getLandingMessages as _getLandingMessages,
  getLandingTweets as _getLandingTweets,
  getNotifications as _getNotifications,
  getUnreadAnnouncement as _getUnreadAnnouncement,
  getUnreadEvent as _getUnreadEvent,
  getUnreadNotifications as _getUnreadNotifications,
  getUnreadPost as _getUnreadPost,
  markAllUnreadNotificationsAsRead as _markAllUnreadNotificationsAsRead,
  updateReadNotification,
} from "../../services/api";

import { getMember } from "../Member/action";

import { logException } from "../../lib/utils";
import _ from "underscore";
import { page as _page } from "../../constants";
import {
  LANDING_MESSAGE_SET,
  LANDING_TWEET_SET,
  NOTIFICATION_ALL_SET,
  NOTIFICATION_UNREAD_SET,
  NOTIFICATION_UPLOAD_SET,
} from "./constants";
import {
  Announcement,
  AppThunkAction,
  AppThunkDispatch,
  Dict,
  GetState,
  GlobalAction,
  Group,
  NotificationCreateData,
  NotificationFileProgress,
  NotificationItem,
  NotificationLandingMessage,
  NotificationLandingMessageWithAuthor,
  RootState,
  UserDataState,
} from "../../types";
import {
  LandingMessageSetAction,
  LandingTweetSetAction,
  NotificationUnreadSetAction,
  NotificationUploadSetAction,
} from "./types";

const per = _page.notification;

export const removeUnreadNotification = (
  ids: string[] | "all",
  update?: boolean
): AppThunkAction<void> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] removeUnreadNotification", { ids, update });

    const { user, notification } = getState();
    //Prevent doing comment in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't clear notifications in Guest Mode.");
    }

    const { unread } = notification;
    let notifications: string[] = [];
    if (!_.isEmpty(unread.notifications)) {
      if (ids === "all") {
        notifications = [];
      } else {
        notifications = _.difference(unread.notifications as string[], ids);
      }
      dispatch({
        type: NOTIFICATION_UNREAD_SET,
        data: { notifications },
      });
    }
    if (!_.isEmpty(ids) && update) {
      updateReadNotification(ids[0]);
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getNotifications = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getNotifications", { next, refresh });
  try {
    const { notification, user } = getState();
    if (user.viewingAsGuest) {
      throw new Error("Can't see notifications in Guest Mode.");
    }
    const { list } = notification;
    let { page } = list;
    const { items } = list;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    } else if (next) {
      page++;
    }

    const options = {
      per,
      page,
    };

    let data = await _getNotifications(options);

    //marking notifications as read once they're loaded
    dispatch(
      removeUnreadNotification(
        _.map(data, (notification: NotificationItem) => notification.id)
      )
    );

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: NOTIFICATION_ALL_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export function getLandingMessages(refresh: boolean) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<void | LandingMessageSetAction> =>
    new Promise<void | LandingMessageSetAction>((resolve, reject) => {
      console.debug("[Action] getLandingMessages");

      const { notification } = getState();
      const cachedData = notification.landingMessages;
      if (!_.isEmpty(cachedData) && !refresh) {
        return resolve();
      } else {
        _getLandingMessages()
          .then(resp => {
            const notificationWithAuthor: NotificationLandingMessageWithAuthor[] = [];

            const getAuthor = async (): Promise<void> => {
              await Promise.all(
                resp.map(async (item: NotificationLandingMessage) => {
                  const author = await dispatch(getMember(item.author, false));

                  notificationWithAuthor.push({
                    ...item,
                    author: author.data.data,
                  });
                })
              );
            };

            getAuthor().then(r => console.debug("Dispatch getAuthor(): " + r));

            return resolve(
              dispatch({
                type: LANDING_MESSAGE_SET,
                data: notificationWithAuthor,
              })
            );
          })
          .catch(error => reject(error));
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getLandingTweets(refresh: boolean) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<void | LandingTweetSetAction> =>
    new Promise<void | LandingTweetSetAction>((resolve, reject) => {
      console.debug("[Action] getLandingTweets");

      const { notification } = getState();
      const cachedData = notification.landingTweets;
      if (!_.isEmpty(cachedData) && !refresh) {
        return resolve();
      } else {
        _getLandingTweets()
          .then(resp => {
            return resolve(
              dispatch({
                type: LANDING_TWEET_SET,
                data: resp,
              })
            );
          })
          .catch(error => reject(error));
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export const readAll = (): AppThunkAction<boolean> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<boolean> => {
  try {
    console.debug("[Action] readAll", {});

    const { user } = getState();
    //Prevent clearing notification in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't clear notifications in Guest Mode.");
    }

    await _markAllUnreadNotificationsAsRead();
    dispatch(removeUnreadNotification("all"));
    return true;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export function getUnreadAnnouncement() {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<NotificationUnreadSetAction> =>
    new Promise<NotificationUnreadSetAction>((resolve, reject) => {
      console.debug("[Action] getUnreadAnnouncement");

      const { group } = getState();
      const { myGroups } = group;
      //@FIXME add typings while typing group container
      const groups = _.map(myGroups as Dict<Group>, (group: { id: string }) => {
        return { id: group.id };
      });
      _getUnreadAnnouncement(groups)
        .then(resp => {
          const feed = resp.map(
            (announcement: Announcement) => announcement.id
          );
          return resolve(
            dispatch({
              type: NOTIFICATION_UNREAD_SET,
              data: { feed },
            })
          );
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function removeUnreadAnnouncement(ids: string[]) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<NotificationUnreadSetAction> =>
    new Promise<NotificationUnreadSetAction>(resolve => {
      console.debug("[Action] removeUnreadAnnouncement");

      const { notification } = getState();
      const { unread } = notification;
      if (!_.isEmpty(unread.feed)) {
        const feed = _.difference(unread.feed as string[], ids);
        dispatch({
          type: NOTIFICATION_UNREAD_SET,
          data: { feed },
        });
      }
      return resolve();
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getUnreadEvent() {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<NotificationUnreadSetAction> =>
    new Promise<NotificationUnreadSetAction>((resolve, reject) => {
      console.debug("[Action] getUnreadEvent");

      const { group } = getState();
      const { myGroups } = group;
      //@FIXME add typings while typing group container
      const groups = _.map(myGroups as Dict<Group>, (group: { id: string }) => {
        return { id: group.id };
      });
      _getUnreadEvent(groups)
        .then(resp => {
          //@FIXME add typings after merging event types
          const events = resp.map((event: { id: string }) => event.id);
          return resolve(
            dispatch({
              type: NOTIFICATION_UNREAD_SET,
              data: { events },
            })
          );
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function removeUnreadEvent(ids: string[]) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<void> =>
    new Promise<void>(resolve => {
      console.debug("[Action] removeUnreadEvent");

      const { notification } = getState();
      const { unread } = notification;

      if (!_.isEmpty(unread.events)) {
        const events = _.difference(unread.events as string[], ids);
        dispatch({
          type: NOTIFICATION_UNREAD_SET,
          data: { events },
        });
      }
      return resolve();
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getUnreadPost() {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<NotificationUnreadSetAction> =>
    new Promise<NotificationUnreadSetAction>((resolve, reject) => {
      console.debug("[Action] getUnreadPost");

      const { group } = getState();
      const { myGroups } = group;
      //@FIXME add typings while typing group container
      const groupIds = _.map(
        myGroups as Dict<Group>,
        (group: { id: string }) => group.id
      );
      _getUnreadPost(groupIds)
        .then(resp => {
          const groups = _.object(
            //@FIXME add typings while typing post container
            _.map(resp, (post: { id: string }[], group: string) => {
              return [group, post.map(p => p.id)];
            })
          );
          return resolve(
            dispatch({
              type: NOTIFICATION_UNREAD_SET,
              data: { groups },
            })
          );
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function removeUnreadPost(
  postGroupIds: { id: string; groupId: string }[]
) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<NotificationUnreadSetAction> =>
    new Promise<NotificationUnreadSetAction>(resolve => {
      console.debug("[Action] removeUnreadPost");

      const { notification } = getState();
      const { unread } = notification;
      const groups = unread.groups || {};

      if (!_.isEmpty(groups)) {
        postGroupIds.forEach(({ id, groupId }) => {
          if (!_.isEmpty(groups[groupId])) {
            groups[groupId] = _.without(groups[groupId] || [], id);
          }
        });
        dispatch({
          type: NOTIFICATION_UNREAD_SET,
          data: { groups },
        });
      }
      return resolve();
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getUnreadNotifications() {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<NotificationItem> =>
    new Promise<NotificationItem>((resolve, reject) => {
      console.debug("[Action] getUnreadNotifications");

      const { user } = getState();
      if (!user.data) {
        return;
      }
      _getUnreadNotifications()
        .then(resp => {
          const { notificationIds, newNotification } = resp;
          dispatch({
            type: NOTIFICATION_UNREAD_SET,
            data: {
              notifications: notificationIds,
            },
          });
          return resolve(newNotification);
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function createNotification(
  data: NotificationCreateData,
  photo: string | null
) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<NotificationUploadSetAction | { data: boolean }> =>
    new Promise<NotificationUploadSetAction | { data: boolean }>(
      (resolve, reject) => {
        console.debug("[Action] createNotification");

        const { setting, user } = getState();
        const { id } = user.data as UserDataState;

        const progressCallback = (
          name: string,
          fileProgress: { [key: string]: NotificationFileProgress },
          transferred: number,
          total: number,
          cancel: boolean
        ): void => {
          dispatch({
            type: NOTIFICATION_UPLOAD_SET,
            data: { name, transferred, total, fileProgress, cancel },
          });
        };
        const customData: NotificationCreateData = {
          ...data,
          sendBy: id,
        };
        _createNotification(customData, photo, setting.config, progressCallback)
          .then(() => {
            resolve({ data: true });
          })
          .catch(error => reject(error));
      }
    ).catch(err => {
      throw err && err.message;
    });
}
