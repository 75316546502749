import React from "react";

import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import PostNewEditContainer from "../../../../containers/Post/NewEdit";

import { PostNewEdit } from "./PostNewEdit";

export type PostNewEditModalProps = {
  groupId?: string;
  id?: string;
  isOpen: boolean;

  toggle?: () => void;
};

export const PostNewEditModal: React.FC<PostNewEditModalProps> = ({
  groupId,
  id,
  isOpen,

  toggle,
}) => {
  const { t } = useTranslation("Post");

  const modalHeaderText = id
    ? t("NewEdit.Button.Update.Post")
    : t("NewEdit.Button.New.Post");

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} scrollable={true}>
      <ModalHeader id="newPostModalHeader" toggle={toggle}>
        {modalHeaderText}
      </ModalHeader>
      <ModalBody className="pt-0">
        <PostNewEditContainer
          toggle={toggle}
          Layout={PostNewEdit}
          match={
            id
              ? { params: { id: id } }
              : groupId
              ? { params: { groupId: groupId } }
              : {}
          }
        />
      </ModalBody>
    </Modal>
  );
};
