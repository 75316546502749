/**@FIXME
 * Copied from card-validator node module.
 * Temporary solution, until TS version will be upgraded to 3.8 or above.
 * Reason is "import type" feature in >= 3.8
 * https://github.com/braintree/card-validator */
const DEFAULT_LENGTH = 3;

export const PayCvcValidator = (
  value: string,
  maxLength: number | number[] = DEFAULT_LENGTH
): boolean => {
  maxLength = maxLength instanceof Array ? maxLength : [maxLength];

  return value.length >= Math.min.apply(null, maxLength);
};
