/* global document */
import * as Services from "./services";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";

import * as serviceWorker from "./register-service-worker";
import { App } from "./components";

// Components
import { ErrorBoundary, LoadingSpinner } from "./components/UI";

// Load css
import "./styles/style.scss";

import { startUp } from "../containers/Setting/action";
import { getCurrentUser, logOut } from "../containers/User/action";
import { persistor, store } from "../store/configureStore";

import "../middlewares/i18next";
import { logException, printExceptionMessage } from "../lib/utils";

// persistor.purge(); // Debug to clear persist

const onBeforeLift = async (): Promise<void> => {
  const dispatch = store.dispatch;
  try {
    await dispatch(startUp());
    await dispatch(getCurrentUser());
  } catch (error) {
    logException(error);
    dispatch(logOut());
    window.alert(`${printExceptionMessage(error)}`);
  }
};

const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Root: React.FC = () => (
  <Provider store={store}>
    <PersistGate
      loading={<LoadingSpinner size="lg" style={{ marginTop: "25%" }} />}
      persistor={persistor}
      onBeforeLift={onBeforeLift}>
      <BrowserRouter>
        <ScrollToTop />
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

Services.init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
