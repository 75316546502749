import { runFunction } from "../../http";
import { convertObject } from "../../../lib/utils";
import { NotifiacationLandingTweet } from "../../../types";

export const getLandingTweets = async (): Promise<NotifiacationLandingTweet[]> => {
  console.debug("[Service] getLandingTweets");

  const parseResponse = await runFunction("getLandingPageTweets", {});
  return convertObject(parseResponse);
};
