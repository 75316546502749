import { portalGet } from "../../http";
import { SettingLocalConfigData } from "../../../types";

export const getWorkSpace = async (
  domain: string
): Promise<SettingLocalConfigData | { errors: {} }> => {
  try {
    return await portalGet(`config/${domain}.json`, {}, {});
  } catch (errors) {
    return { errors };
  }
};
