import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { isEmpty } from "underscore";

import { track } from "../../lib/track";
import { getStateWhenGoback } from "../../lib/utils/checkGoBack";
import {
  AnnouncementItem,
  CommentType,
  DictStrict,
  EmptyObject,
  MatchProps,
  PostGroup,
  PostItem,
  ReactionType,
  RootState,
  UserDataState,
} from "../../types";

import { doReaction } from "../Comment/action";

import { getMixedPosts, removePost } from "./action";
import {
  getPostsSettingState,
  getPostsState,
  getPostsHasMoreState,
} from "./selector";
import { getCurrentUserState } from "../User/selector";
import { getPinAnnouncementsState } from "../Announcement/selector";

export type PostListStateProps = {
  data: PostItem[] | null;
  hasMore: boolean;
  pins: null | AnnouncementItem[];
  setting: {
    ClientHostName?: string;
    announcementLabel?: string;
    confettiEffectActions: [];
  };
  user: UserDataState | EmptyObject;
};

export type PostListDispatchProps = {
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>;
  getPosts: (
    next: boolean,
    refresh: boolean,
    trackCallback?: (data: DictStrict<PostItem>) => void,
    refreshWhenReactive?: boolean
  ) => Promise<void>;
  removePost: (post: {
    id: string;
    group: PostGroup & { id?: string };
  }) => Promise<{ data: boolean }>;
};

export type PostListLayoutProps = {
  data: PostItem[] | null;
  error: string | null;
  hasMore: boolean;
  isMixed?: boolean;
  loading: boolean;
  pins: AnnouncementItem[] | null;
  refreshing: boolean;
  setting: {
    ClientHostName?: string;
    announcementLabel?: string;
    confettiEffectActions?: [];
  };
  user: UserDataState | EmptyObject;

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>;
  onScroll?: () => void;
  reFetch: (
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean
  ) => void;
  removePost?: (
    post: {
      id: string;
      group: PostGroup & { id?: string };
    },
    callback?: (text: string) => void
  ) => Promise<void>;
};

export type PostListOwnProps = {
  Layout: ComponentType<PostListLayoutProps>;
  displayPins?: boolean;
  /**
   * Native specific event handler passed by MixedFeed
   *
   * @FIXME
   * Investigate arguments when typing native components
   */
  onScroll?: () => void;
};

export type PostListProps = PostListStateProps &
  PostListDispatchProps &
  PostListOwnProps &
  WithTranslation &
  MatchProps;

export type PostListState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class MixedPosts extends Component<PostListProps, PostListState> {
  state: PostListState = {
    error: null,
    loading: false,
    refreshing: false,
  };

  async componentDidMount(): Promise<void> {
    const lastState = await getStateWhenGoback();
    if (!["groups", "home"].includes(lastState)) {
      this.fetchData(false, true);
    }

    track("View Screen", {
      Screen: "groups",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean
  ): void => {
    const { getPosts, data } = this.props;
    const { refreshing, loading } = this.state;

    if (refreshing || loading) {
      return;
    }

    this.setState(
      { refreshing: refresh && !isEmpty(data), loading: next },
      async () => {
        try {
          await getPosts(next, refresh, this.onTrack, refreshWhenReactive);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  onRemovePost = async (
    post: {
      id: string;
      group: PostGroup & { id?: string };
    },
    callback?: (text: string) => void
  ): Promise<void> => {
    try {
      const { refreshing, loading } = this.state;

      if (refreshing || loading) {
        return;
      }

      await this.props.removePost(post);

      this.setState({ error: null });

      callback?.(this.props.t("Container.Index.Delete"));

      track("Delete Post", {
        "Post ID": post.id,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };

  onTrack = (posts: DictStrict<PostItem> | null): void => {
    if (posts !== null) {
      Object.keys(posts).forEach(id => {
        track("View Post", {
          "Post ID": id,
        });
      });
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      data,
      hasMore,
      pins,
      setting,
      user,

      doReaction,
      onScroll,
    } = this.props;
    const { loading, error, refreshing } = this.state;
    return (
      <Layout
        data={data}
        error={error}
        hasMore={hasMore}
        isMixed={true}
        loading={loading}
        pins={pins}
        refreshing={refreshing}
        setting={setting}
        user={user}
        doReaction={doReaction}
        onScroll={onScroll}
        reFetch={this.fetchData}
        removePost={this.onRemovePost}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: PostListOwnProps
): PostListStateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getPostsSettingState(state),
    data: getPostsState(state),
    hasMore: getPostsHasMoreState(state),
    pins: getPinAnnouncementsState(state),
  };
};

const mapDispatchToProps = {
  getPosts: getMixedPosts,
  doReaction,
  removePost,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Post")(MixedPosts));
