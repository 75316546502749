/* eslint-disable @typescript-eslint/no-var-requires */
import { Dict } from "../types";
import { appListLengthConfigKeys } from "./appListLengthConfigKeys";

export * from "./Event";
export * from "./Media";
export * from "./Search";
export * from "./Theme";
export * from "./User";

export * from "./appListLengthConfigKeys";
export * from "./appSettingsFeatureNavbarAvailable";
export * from "./reactionColorsAvailable";
export * from "./sortTypes";

export const imageLoading = require("../images/image-loading.png");
export const imageNotFound = require("../images/image-not-found.png");
export const videoNotFound = require("../images/video-not-found.png");
export const imageProcessing = require("../images/image-processing.gif");
export const defaultCalendar = require("../images/default-calendar.png");
export const imageUpload = require("../images/image-upload.png");

export const FileTypeIcons: Dict<string> = {
  "3gp": "3gp",
  aac: "aac1",
  ai: "ai1",
  apk: "apk2",
  asf: "asf2",
  avi: "avi2",
  bmp: "bmp1",
  css: "css6",
  csv: "csv",
  doc: "doc",
  docx: "docx1",
  eps: "eps3",
  exe: "exe2",
  fla: "fla",
  flv: "flv3",
  gif: "gif5",
  gzip: "gzip1",
  html: "html8",
  jpg: "jpg2",
  m4a: "m4a2",
  m4v: "m4v",
  mov: "mov",
  mp3: "mp34",
  mp4: "mp42",
  mpg: "mpg2",
  pdf: "pdf17",
  psd: "photoshop",
  png: "png4",
  ppt: "ppt2",
  pptx: "pptx",
  rar: "rar",
  rtf: "rtf1",
  svg: "svg2",
  swf: "swf2",
  tiff: "tiff",
  txt: "txt",
  wav: "wav1",
  wma: "wma2",
  wmv: "wmv",
  xls: "xls2",
  xlsx: "xlsx1",
  xml: "xml6",
  zip: "zip5",
};

export const eventColors: string[] = [
  "#B13254",
  "#FF5449",
  "#FF9249",
  "#FFBB6C",
  "#85937A",
  "#FF4D92",
  "#598432",
  "#94AE16",
  "#E6D929",
  "#0E5AA4",
  "#1A9FF9",
  "#2B8B77",
];

export const allDialCodeUSCountries: string[] = [
  "+1201",
  "+1209",
  "+1681",
  "+1689",
  "+1730",
  "+1769",
  "+1779",
  "+1202",
  "+1203",
  "+1205",
  "+1206",
  "+1207",
  "+1208",
  "+1709",
  "+1210",
  "+1212",
  "+1213",
  "+1214",
  "+1215",
  "+1216",
  "+1217",
  "+1218",
  "+1219",
  "+1224",
  "+1225",
  "+1228",
  "+1229",
  "+1231",
  "+1234",
  "+1239",
  "+1240",
  "+1248",
  "+1251",
  "+1252",
  "+1253",
  "+1254",
  "+1256",
  "+1260",
  "+1262",
  "+1267",
  "+1269",
  "+1270",
  "+1276",
  "+1281",
  "+1301",
  "+1302",
  "+1303",
  "+1304",
  "+1305",
  "+1307",
  "+1308",
  "+1309",
  "+1310",
  "+1312",
  "+1313",
  "+1314",
  "+1315",
  "+1316",
  "+1317",
  "+1318",
  "+1319",
  "+1320",
  "+1321",
  "+1323",
  "+1325",
  "+1330",
  "+1334",
  "+1336",
  "+1337",
  "+1339",
  "+1347",
  "+1351",
  "+1352",
  "+1360",
  "+1361",
  "+1386",
  "+1401",
  "+1402",
  "+1404",
  "+1405",
  "+1406",
  "+1407",
  "+1408",
  "+1409",
  "+1410",
  "+1412",
  "+1413",
  "+1414",
  "+1415",
  "+1417",
  "+1419",
  "+1423",
  "+1424",
  "+1425",
  "+1430",
  "+1432",
  "+1434",
  "+1435",
  "+1440",
  "+1443",
  "+1447",
  "+1469",
  "+1478",
  "+1479",
  "+1480",
  "+1484",
  "+1430",
  "+1432",
  "+1430",
  "+1432",
  "+1501",
  "+1502",
  "+1503",
  "+1504",
  "+1505",
  "+1507",
  "+1508",
  "+1509",
  "+1510",
  "+1512",
  "+1513",
  "+1515",
  "+1516",
  "+1517",
  "+1518",
  "+1520",
  "+1530",
  "+1540",
  "+1541",
  "+1551",
  "+1559",
  "+1561",
  "+1562",
  "+1563",
  "+1567",
  "+1570",
  "+1571",
  "+1573",
  "+1574",
  "+1575",
  "+1580",
  "+1585",
  "+1586",
  "+1601",
  "+1602",
  "+1603",
  "+1605",
  "+1606",
  "+1607",
  "+1608",
  "+1609",
  "+1610",
  "+1612",
  "+1614",
  "+1615",
  "+1616",
  "+1617",
  "+1618",
  "+1619",
  "+1620",
  "+1623",
  "+1626",
  "+1630",
  "+1631",
  "+1636",
  "+1641",
  "+1646",
  "+1650",
  "+1651",
  "+1660",
  "+1661",
  "+1662",
  "+1678",
  "+1682",
  "+1701",
  "+1702",
  "+1703",
  "+1704",
  "+1706",
  "+1707",
  "+1708",
  "+1712",
  "+1713",
  "+1714",
  "+1715",
  "+1716",
  "+1717",
  "+1718",
  "+1719",
  "+1720",
  "+1724",
  "+1727",
  "+1731",
  "+1732",
  "+1734",
  "+1740",
  "+1754",
  "+1757",
  "+1760",
  "+1763",
  "+1765",
  "+1770",
  "+1772",
  "+1773",
  "+1774",
  "+1775",
  "+1781",
  "+1785",
  "+1786",
  "+1801",
  "+1802",
  "+1803",
  "+1804",
  "+1805",
  "+1806",
  "+1808",
  "+1810",
  "+1812",
  "+1813",
  "+1814",
  "+1815",
  "+1816",
  "+1817",
  "+1818",
  "+1828",
  "+1830",
  "+1831",
  "+1832",
  "+1843",
  "+1845",
  "+1847",
  "+1848",
  "+1850",
  "+1856",
  "+1857",
  "+1858",
  "+1859",
  "+1860",
  "+1862",
  "+1863",
  "+1864",
  "+1865",
  "+1870",
  "+1878",
  "+1901",
  "+1903",
  "+1904",
  "+1906",
  "+1907",
  "+1908",
  "+1909",
  "+1910",
  "+1912",
  "+1913",
  "+1914",
  "+1915",
  "+1916",
  "+1917",
  "+1918",
  "+1919",
  "+1920",
  "+1925",
  "+1928",
  "+1931",
  "+1936",
  "+1937",
  "+1940",
  "+1941",
  "+1947",
  "+1949",
  "+1951",
  "+1952",
  "+1954",
  "+1956",
  "+1970",
  "+1971",
  "+1972",
  "+1973",
  "+1978",
  "+1979",
  "+1980",
  "+1985",
  "+1989",
];

/**
 * @TODO Reconsider better name e.g. defaultListLengthConfig
 * or defaultListLengthDict, moving following type to types
 * directory and extract this constant to external file
 */
type AppListLengthConfigKey = typeof appListLengthConfigKeys[number];

export const page: Dict<number, AppListLengthConfigKey> = {
  comment: 10,
  reaction: 20,
  seen: 20,
  announcement: 10,
  event: 30,
  post: 10,
  payment: 10,
  notification: 10,
  common: 10,
  mention: 30,
  chatRoom: 50,
  chatDetail: 20,
  resource: 1000,
} as const;

export const imageUploadConfig: Dict<{
  quality: number;
  maxHeight: number;
  maxWidth: number;
}> = {
  main: {
    quality: 0.8,
    maxHeight: 1920,
    maxWidth: 2560,
  },
  thumb: {
    quality: 100,
    maxHeight: 720,
    maxWidth: 920,
  },
  profile: {
    quality: 100,
    maxHeight: 256,
    maxWidth: 256,
  },
  crop: {
    quality: 100,
    maxHeight: 400,
    maxWidth: 800,
  },
};

export const onboarding: {
  title: string;
  text: string;
  image: typeof import("*.png");
  local: boolean;
}[] = [
  {
    title: "Connect",
    text: "Create instant connections with members in your organization",
    image: require("../images/intro/intro-1.png"),
    local: true,
  },
  {
    title: "Attend",
    text: "Follow & RSVP for upcoming events",
    image: require("../images/intro/intro-2.png"),
    local: true,
  },
  {
    title: "Belong",
    text: "Join groups and share ideas filled with content and media",
    image: require("../images/intro/intro-3.png"),
    local: true,
  },
  {
    title: "Know",
    text: "Stay up to date with everything your organization is doing",
    image: require("../images/intro/intro-4.png"),
    local: true,
  },
];

export const reactionImages = {
  image: {
    like: require("../images/reaction/like.png"),
    unlike: require("../images/reaction/unlike.png"),
    love: require("../images/reaction/love.png"),
    haha: require("../images/reaction/haha.png"),
    wow: require("../images/reaction/wow.png"),
    sad: require("../images/reaction/sad.png"),
    angry: require("../images/reaction/angry.png"),
  },
  gif: {
    like: require("../images/reaction/like2.gif"),
    love: require("../images/reaction/love2.gif"),
    haha: require("../images/reaction/haha2.gif"),
    wow: require("../images/reaction/wow2.gif"),
    sad: require("../images/reaction/sad2.gif"),
    angry: require("../images/reaction/angry2.gif"),
    unlike: require("../images/reaction/unlike.png"),
  },
  like: require("../images/reaction/like_alt.png"),
};

export const socialScheme: Dict<{
  ios: string;
  android: string;
  url: string;
  appURL: string;
  webUrl: string;
}> = {
  Facebook: {
    ios: "fbapi://",
    android: "com.facebook.katana",
    url: "http://mobile.facebook.com/",
    webUrl: "https://facebook.com/",
    appURL: "fb://profile/",
  },
  Instagram: {
    ios: "instagram://",
    android: "com.instagram.android",
    url: "http://instagram.com/",
    webUrl: "https://instagram.com/",
    appURL: "instagram://user?username=",
  },
  Twitter: {
    ios: "twitter://",
    android: "com.twitter.android",
    url: "https://twitter.com/",
    webUrl: "https://twitter.com/",
    appURL: "twitter:///user?screen_name=",
  },
  Linkedin: {
    ios: "linkedin://",
    android: "com.linkedin.android",
    url: "https://www.linkedin.com/in/",
    webUrl: "https://www.linkedin.com/in/",
    appURL: "linkedin://#profile/",
  },
};
