import React from "react";

import { useTranslation } from "react-i18next";

import UserLoginContainer from "../../../containers/User/Login";

import { TemplateSimple } from "../../components/Template";
import { UserLogin } from "../../components/User";

import { RouteAppComponentProps } from "..";

export type RouteUserLoginProps = RouteAppComponentProps;

export const RouteUserLogin: React.FC<RouteUserLoginProps> = ({
  setting,
  ...props
}) => {
  const { t } = useTranslation("User");
  return (
    <TemplateSimple pageTitle={t("Login.Page.Title")} setting={setting}>
      <UserLoginContainer {...props} Layout={UserLogin} />
    </TemplateSimple>
  );
};
