import React from "react";

import ReactPlayer from "react-player";
import { isEmpty } from "underscore";

export type MediaVideoProps = {
  type?: "Cloudinary" | "YouTube" | "Vimeo" | "Wistia";
  url: string;
  style?: React.CSSProperties;
};

export const MediaVideo: React.FC<MediaVideoProps> = ({ url, type, style }) => {
  if (
    type === "Cloudinary" ||
    type === "YouTube" ||
    type === "Vimeo" ||
    type === "Wistia" ||
    isEmpty(type)
  ) {
    return (
      <ReactPlayer
        id={`video${type}`}
        className="video-external"
        controls={true}
        url={
          type === "Wistia"
            ? `https://wistia.com/medias/${url.split("/").pop()}`
            : url
        }
        width="100%"
        style={{ ...style, minWidth: 300, marginLeft: 0, marginRight: 0 }}
      />
    );
  }

  return null;
};
