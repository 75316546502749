import { runFunction } from "../../http";
import { convertObject } from "../../../lib/utils";
import { NotificationItem } from "../../../types/Notification/NotificationItem";

export const getUnreadNotifications = async (
  // @FIXME we do not pass options to this func
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
): Promise<{
  notificationIds: string[];
  newNotification: NotificationItem;
}> => {
  console.debug("[Service] getUnreadNotificationsForUser");

  const parseResponse = await runFunction(
    "getUnreadNotificationsForUser",
    options,
    {
      requireUser: true,
    }
  );
  const { notificationIds, notification } = parseResponse;
  const newNotification = notification && convertObject(notification);
  return { notificationIds, newNotification };
};
