import React, { useState } from "react";

import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import { Heading } from "../UI/Heading";
import { HTML } from "..";
import { SubscriptionAlertLayoutProps } from "../../../containers/Subscription/SubscriptionAlert";
import { useHistory } from "react-router-dom";

type Props = SubscriptionAlertLayoutProps;

export const SubscriptionAlert: React.FC<Props> = ({
  data,
  denySubscription,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const history = useHistory();

  const modalEulaStyles = {
    button: {
      minWidth: 120,
    },
    buttonsContainer: {
      marginTop: 20,
    },
    link: {
      justifyContent: "center",
    },
  };
  const { title = "", body = "", buttons = {} } = data || {};
  const { reject, agree } = buttons;

  const onAgree = (): void => {
    setIsOpen(false);
    history.push("/subscription");
  };

  const onReject = (): void => {
    setIsOpen(false);
    denySubscription();
  };

  return (
    <Modal
      id="subscriptionAlertModal"
      isOpen={isOpen}
      centered={true}
      scrollable={true}>
      <ModalHeader>
        <Heading large bold text={title} className="text-center" />
      </ModalHeader>
      <ModalBody>
        <HTML body={body} className="" />
        <div>
          {!!agree && (
            <Button
              color="success"
              block
              onClick={onAgree}
              style={modalEulaStyles.button}>
              {agree}
            </Button>
          )}
          {!!reject && (
            <Button
              color="primary"
              block
              onClick={onReject}
              style={modalEulaStyles.button}>
              {reject}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
