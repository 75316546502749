import { runFunction } from "../../http";
import { ChatSetting } from "../../../types/Chat/ChatSetting";

export const saveChatNotificationSettings = async (
  channel: string,
  settings: ChatSetting
): Promise<any> => {
  return runFunction(
    "saveChatNotificationSettings",
    {
      channel: channel,
      settings: settings,
    },
    { requireUser: true }
  );
};
