import { createSelector } from "reselect";
import { RootState, Dict } from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";
import { Video } from "../../types/VideoLibrary/Video";

const getVideoLibraryData = (state: RootState): Dict<Video> | null =>
  state.video.list.items;

const getgetVideoLibraryDataHasMore = (state: RootState): boolean =>
  state.video.list.hasMore;

export const getVideoLibraryDataState = createSelector(
  [getVideoLibraryData],
  items => getDictDefinedValues(items) || null
);

export const getVideoLibraryDataHasMoreState = createSelector(
  [getgetVideoLibraryDataHasMore],
  hasMore => hasMore
);
