import { createSelector } from "reselect";
import {
  RootState,
  AnnouncementItem,
  DictStrict,
  AnnouncementType,
} from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";
import { getSettingState } from "../Setting/selector";

// selector

const getAnnouncementTypes = (state: RootState): AnnouncementType[] | null =>
  state.announcement.types;

const getAnnouncements = (
  state: RootState
): DictStrict<AnnouncementItem> | null => state.announcement.list.items;

const getPinAnnouncements = (
  state: RootState
): DictStrict<AnnouncementItem> | null => state.announcement.pins;

const getHasMoreAnnouncements = (state: RootState): boolean =>
  state.announcement.list.hasMore;

// reselect function

export const getAnnouncementTypesState = createSelector(
  [getAnnouncementTypes],
  types => {
    const announcementTypes = (types || []).concat([
      { name: "Show All", id: null },
    ]);
    return announcementTypes;
  }
);

export const getAnnouncementsState = createSelector([getAnnouncements], items =>
  getDictDefinedValues(items)
);

export const getPinAnnouncementsState = createSelector(
  [getPinAnnouncements],
  items => getDictDefinedValues(items)
);

export const getHasMoreAnnouncementsState = createSelector(
  [getHasMoreAnnouncements],
  hasMore => hasMore
);

export const getAnnouncementsSettingState = createSelector(
  [getSettingState],
  setting => ({
    announcementLabel: setting?.config?.announcementLabel || "",
    confettiEffectActions: setting?.setting?.confetti_effect_actions || [],
    ClientHostName: setting?.localConfig?.client_url || "",
  })
);
