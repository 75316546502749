import React from "react";

import { Bell } from "react-feather";

import { IconDict } from "../../../../types";

export type IconFeatherProps = {
  name: IconDict["Feather"];
};

export const IconFeather: React.FC<IconFeatherProps> = ({ name }) => {
  switch (name) {
    case "bell":
      return <Bell />;
  }
};
