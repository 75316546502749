import { getSettingState } from "../Setting/selector";
import { createSelector } from "reselect";
import { RootState, CommentResponse, Dict } from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";

const getCommentsData = (
  state: RootState,
  id: string
): Dict<CommentResponse> | null =>
  state.comment.commentsList?.[id]?.items || null;

const getCommentsDataHasMore = (state: RootState, id: string): boolean =>
  state.comment.commentsList?.[id]?.hasMore || false;

export const getCommentsDataState = createSelector(
  [getCommentsData],
  items => getDictDefinedValues(items) || []
);

export const getCommentsDataHasMoreState = createSelector(
  [getCommentsDataHasMore],
  hasMore => hasMore
);

export const getCommentSettingState = createSelector(
  [getSettingState],
  setting => ({
    confettiEffectActions: setting?.setting?.confetti_effect_actions || [],
    ClientHostName: setting?.localConfig?.client_url || "",
  })
);
