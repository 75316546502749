import React, { useState } from "react";

import classnames from "classnames";
import { Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";

import { toCapitalizeCase } from "../../../../lib/utils";

import { MediaSearchImageOption, MediaPhoto } from "../../../../types";

import { MediaSearchImageService } from ".";

export type MediaSearchImageProps = {
  options: MediaSearchImageOption[];

  onSelect: (image: MediaPhoto) => void;
};

export const MediaSearchImage: React.FC<MediaSearchImageProps> = ({
  options,

  onSelect,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab: number): void => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [navItemElements, tabPaneElements] = options.reduce<
    [React.ReactElement[], React.ReactElement[]]
  >(
    (prevVal, currentVal, currentIndex) => [
      [
        ...prevVal[0],
        <NavItem key={currentIndex}>
          <NavLink
            id={`searchPhotoTab${currentVal}`}
            className={classnames({ active: activeTab === currentIndex })}
            onClick={(): void => {
              toggleTab(currentIndex);
            }}>
            {toCapitalizeCase(currentVal)}
          </NavLink>
        </NavItem>,
      ],
      [
        ...prevVal[1],
        <TabPane key={currentIndex} tabId={currentIndex}>
          <MediaSearchImageService service={currentVal} onSelect={onSelect} />
        </TabPane>,
      ],
    ],
    [[], []]
  );

  return (
    <>
      <Nav tabs={true}>{navItemElements}</Nav>
      <TabContent activeTab={activeTab}>{tabPaneElements}</TabContent>
    </>
  );
};
