import React from "react";

import Skeleton from "react-loading-skeleton";
import { Row, Card, Col, CardBody } from "reactstrap";

import { useTheme } from "../../hooks";

export type VideoCardPlaceholderProps = {};

export const VideoCardPlaceholder: React.FC<VideoCardPlaceholderProps> = () => {
  const {
    card: { backgroundColor },
  } = useTheme();
  return (
    <Card
      className="mb-2"
      style={{
        backgroundColor,
      }}>
      <CardBody className="px-0 py-0">
        <Skeleton height={270} style={{ borderRadius: 0 }} />
      </CardBody>
      <CardBody className="px-3 pb-0">
        <Row>
          <Col>
            <h6>
              <Skeleton width={300} />
            </h6>
            <p className="text-left small">
              <Skeleton width={250} />
            </p>
          </Col>
          <Col>
            <p className="text-right">
              <Skeleton width={70} />
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
