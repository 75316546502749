import { logException } from "../../../lib/utils";

export const convertCanvasToBlob = (
  canvas: HTMLCanvasElement,
  options?: { type?: string; quality?: number }
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    try {
      const type = options?.type || "image/png";
      const quality = options?.quality || 1;

      canvas.toBlob(
        blob => {
          if (blob) {
            resolve(blob);
          }

          reject(new Error(`Couldn't create Blob`));
        },
        type,
        quality
      );
    } catch (e) {
      logException(e);
      reject(e);
    }
  });
