import React from "react";

import { useTranslation } from "react-i18next";

import { textThemingWorkaround } from "../../../../constants";
import { AnnouncementType } from "../../../../types";

import { useTheme } from "../../../hooks";

import { StickyNavbar, Heading } from "../..";

import { AnnouncementListDropdown } from ".";

export type AnnouncementListStickyNavbarProps = {
  announcementType: string | null;
  announcementTypes: AnnouncementType[];

  changeAnnouncementType: (
    announcementType: AnnouncementType,
    options?: { forceListReset?: boolean }
  ) => void;
};

export const AnnouncementListStickyNavbar: React.FC<AnnouncementListStickyNavbarProps> = ({
  announcementType,
  announcementTypes,

  changeAnnouncementType,
}) => {
  const {
    text: { header },
    general,
    navigation,
    rightIconStyle,
  } = useTheme();

  const { t } = useTranslation("Announcement");

  const announcementListStickyNavbarStyles = {
    heading: {
      ...header,
      ...textThemingWorkaround,
      color: general.color,
    },
    mainWrapper: {
      borderBottomWidth: 0,
      justifyContent: "space-between",
      paddingTop: 10,
      paddingBottom: 10,
    },
    searchButtonBlock: {
      borderRadius: 50,
      border: 0,
      height: 35,
      backgroundColor: navigation.backgroundColor,
      paddingLeft: 5,
    },
    searchButton: {
      borderRadius: 50,
      border: 0,
      height: 35,
      backgroundColor: navigation.backgroundColor,
      paddingLeft: 10,
    },
    searchIcon: {
      ...rightIconStyle,
      fontSize: 20,
      marginTop: 0,
      color: navigation.color,
    },
  };

  return (
    <StickyNavbar>
      <div
        className="d-flex"
        style={announcementListStickyNavbarStyles.mainWrapper}>
        <div className="align-self-center">
          <Heading
            text={t("Index.Heading.Updates") || ""}
            style={announcementListStickyNavbarStyles.heading}
          />
        </div>
        <div
          className="d-flex justify-content-end align-self-center"
          style={{}}>
          <AnnouncementListDropdown
            announcementType={announcementType}
            announcementTypes={announcementTypes}
            className="d-flex"
            onValueChange={changeAnnouncementType}
          />
        </div>
      </div>
    </StickyNavbar>
  );
};
