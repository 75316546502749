import React from "react";

import classnames from "classnames";

import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

import { Avatar, Heading } from "../UI";

export type MemberBarProps = {
  avatar?: {
    round?: boolean;
    size?: string;
  };
  className?: string;
  name: string;
  src?: string;
};

export const MemberBar: React.FC<MemberBarProps> = ({
  avatar,
  className,
  name,
  src,
}) => {
  const {
    text: { cardText },
  } = useTheme();

  const headingStyle = { ...cardText, ...textThemingWorkaround };

  return (
    <div className={classnames(className, "d-flex")}>
      <Avatar
        src={src}
        name={name}
        round={avatar?.round ?? true}
        size={avatar?.size ?? "30"}
      />
      <Heading
        className="align-self-center ml-2"
        style={headingStyle}
        text={name}
      />
    </div>
  );
};
