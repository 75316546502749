import _ from "underscore";
import { convertObject } from "../../../lib/utils";
import { Dict, Member } from "../../../types";
import { customProfile } from "..";

const Parse = window.Parse;

export const getFavoriteChatContacts = async (): Promise<Dict<Member>> => {
  console.debug("[Service] getFavoriteChatContacts");
  const user = await Parse.User.currentAsync();
  if (user) {
    const query = user.relation("favoriteChatContacts").query();
    query.include("profile");
    query.equalTo("status", "active");
    const resp = await query.find();

    return _.object(
      _.map(resp, value => {
        const user = customProfile(convertObject(value));
        return [user.id, user];
      })
    );
  } else {
    return {};
  }
};
