import React from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import {
  Callback,
  EmptyObject,
  Group,
  MatchProps,
  MessageCallback,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";

import { getGroup, canAccessGroup, joinGroup, leaveGroup } from "./action";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";
import { getGroupDetailState } from "./selector";

export type GroupDetailLayoutProps = MatchProps<"id"> & {
  canAccessGroup?: boolean;
  data?: Group | null;
  error: Error | null;
  info?: string;
  isJoined?: boolean;
  loading: boolean;
  refreshing: boolean;
  renderActions?: boolean;
  renderActionsDone?: boolean;
  setting: SettingState | EmptyObject;
  success: unknown;
  user: UserDataState | EmptyObject;
  joinGroup: (callback: MessageCallback) => Promise<void>;
  leaveGroup: (callback: MessageCallback) => Promise<void>;
  reFetch: (refresh?: boolean, callback?: Callback) => false | void;
};

export type GroupDetailOwnProps = MatchProps<"id"> & {
  Layout: React.ComponentType<any>;
  renderActions?: boolean;
  renderActionsDone?: boolean;
};

export type GroupDetailStateProps = {
  setting: SettingState | EmptyObject;
  data?: Group | null;
  user: UserDataState | EmptyObject;
};

export type GroupDetailDispatchProps = {
  canAccessGroup: (
    id: string
  ) => Promise<{
    canAccessGroup: boolean;
    isJoined: boolean;
  }>;
  getGroup: (id: string, refresh?: boolean) => unknown;
  joinGroup: (group: Group) => Promise<{ isJoined: boolean }>;
  leaveGroup: (group: Group) => Promise<unknown>;
};

export type GroupDetailProps = GroupDetailOwnProps &
  GroupDetailStateProps &
  GroupDetailDispatchProps;

export type GroupDetailState = {
  canAccessGroup?: boolean;
  error: null;
  info?: string;
  isJoined?: boolean;
  loading: boolean;
  refreshing: boolean;
  success?: unknown;
};

class GroupDetail extends React.Component<
  GroupDetailProps & WithTranslation,
  GroupDetailState
> {
  state: GroupDetailState = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount = (): void => {
    this.fetchData(true);

    track("View Screen", {
      Screen: "group-feed",
      Params: this.props.match.params,
    });
  };

  componentDidUpdate = (prevProps: GroupDetailProps): void => {
    if (prevProps.data?.id !== this.props.data?.id) {
      this.fetchData();
    }
  };

  fetchData = (refresh?: boolean, callback?: Callback): void => {
    const { getGroup, match, canAccessGroup } = this.props;
    const { refreshing, loading } = this.state;
    const id = match.params?.id;

    if (refreshing || loading || !id) {
      return;
    }

    this.setState({ refreshing: refresh as boolean }, async () => {
      try {
        await getGroup(id, refresh);

        const resp = await canAccessGroup(id);

        if (!resp.canAccessGroup) {
          this.setState({
            info: this.props.t("Container.Detail.Info"),
          });
        }

        this.setState({
          ...resp,
          loading: false,
          refreshing: false,
          error: null,
        });

        callback && callback();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  joinGroup = async (callback: MessageCallback): Promise<void> => {
    try {
      const { data, joinGroup } = this.props;

      if (!data) {
        /**
         * Probably joinGroup was throwing an error when id was undefined before.
         * Now TS doesn't allow us to do that so I'm doing it manually.
         */
        throw Error("Missing group data");
      }

      const resp = await joinGroup(data);

      const { isJoined } = resp;
      const { name, objectId } = data;

      this.setState({ isJoined, error: null });

      callback?.(
        isJoined
          ? this.props.t("Container.Detail.Callback", { name })
          : this.props.t("Container.Detail.Callback.2")
      );

      track("Join Group", {
        "Group ID": objectId,
        "Group Name": name,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };

  leaveGroup = async (callback: MessageCallback): Promise<void> => {
    try {
      const { data, leaveGroup } = this.props;

      if (!data) {
        /**
         * Probably leaveGroup was throwing an error when id was undefined before.
         * Now TS doesn't allow us to do that so I'm doing it manually.
         */
        throw Error("Missing group data");
      }

      await leaveGroup(data);

      const { name, objectId } = data;

      this.setState({ isJoined: false, error: null });

      // callback && callback(`You have left the group ${name}`);
      callback?.(this.props.t("Container.Detail.Callback.3", { name }));

      track("Leave", {
        "Group ID": objectId,
        "Group Name": name,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      data,
      setting,
      match,
      renderActions,
      renderActionsDone,
    } = this.props;
    const {
      loading,
      error,
      refreshing,
      info,
      success,
      canAccessGroup,
      isJoined,
    } = this.state;
    return (
      <Layout
        error={error}
        info={info}
        success={success}
        loading={loading}
        refreshing={refreshing}
        canAccessGroup={canAccessGroup}
        isJoined={isJoined}
        user={user}
        setting={setting}
        data={data}
        reFetch={this.fetchData}
        match={match}
        joinGroup={this.joinGroup}
        leaveGroup={this.leaveGroup}
        renderActions={!!renderActions}
        renderActionsDone={!!renderActionsDone}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: GroupDetailOwnProps
): GroupDetailStateProps => {
  const id = ownProps.match.params?.id;

  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getGroupDetailState(state, id || ""),
  };
};

const mapDispatchToProps = {
  getGroup: getGroup,
  canAccessGroup: canAccessGroup,
  joinGroup: joinGroup,
  leaveGroup: leaveGroup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Group")(GroupDetail));
