import React, { useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";

import { SingleEventItem } from "../../../types";
import { useTheme } from "../../hooks";
import { textThemingWorkaround } from "../../../constants/Theme";
import { Heading } from "../UI";

export type EventItemsCollapseProps = {
  className?: string;
  items: SingleEventItem[];
};

export const EventItemsCollapse: React.FC<EventItemsCollapseProps> = ({
  className,
  items,
}) => {
  const [isItemsCollapseOpen, setItemsCollapseOpen] = useState(false);

  const {
    button,
    text: { listText },
  } = useTheme();

  const eventItemCollapseStyles = {
    listText: {
      ...listText,
      ...textThemingWorkaround,
      paddingLeft: 0,
    },
    heading: {
      ...listText,
      ...textThemingWorkaround,
      paddingLeft: 0,
      color: button.backgroundColor,
    },
  };

  const toggleItemsCollapse = (): void =>
    setItemsCollapseOpen(!isItemsCollapseOpen);

  const { t } = useTranslation("Event");

  const groupItemElement = items.map((item, index) => (
    <ListGroupItem
      style={eventItemCollapseStyles.listText}
      key={index}
      className="py-2">
      {item.description}
    </ListGroupItem>
  ));

  return (
    <div className={classnames(className)}>
      <Button
        color="link"
        className="d-inline p-0 align-baseline text-decoration-none"
        onClick={toggleItemsCollapse}>
        <div className="d-flex">
          <i
            style={eventItemCollapseStyles.heading}
            className={`icon-${
              isItemsCollapseOpen ? "minus" : "plus"
            } d-flex my-2 mr-2`}
          />
          <Heading
            className="align-self-center"
            style={eventItemCollapseStyles.heading}
            text={t("Detail.Button.Options.Upgrade")}
          />
        </div>
      </Button>
      <Collapse isOpen={isItemsCollapseOpen}>
        <ListGroup className="small text-muted mt-2" flush={true}>
          {groupItemElement}
        </ListGroup>
      </Collapse>
    </div>
  );
};
