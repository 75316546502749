import React, { Component } from "react";
import classNames from "classnames";
import { LoadingSpinner } from "../../UI";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import handleViewport from "react-in-viewport";
import { debounce } from "underscore";

const LoadMoreButton = (props: {
  forwardedRef: any;
  hasMore: boolean;
  isLoading: boolean;
  loadMore: any;
}): JSX.Element => {
  const { forwardedRef, hasMore } = props;
  return (
    <div className="viewport-block" ref={forwardedRef}>
      {hasMore && (
        <div className="simple-infinite-scroll-loading-component" key={-2}>
          <LoadingSpinner size="sm" />
        </div>
      )}
    </div>
  );
};

export type InvertedInfiniteScrollProps = {
  children: JSX.Element[];
  className: string;
  hasMore: boolean;
  height: string;
  isLoading: boolean;

  loadMore: () => Promise<void>;
  setForceScrollToBottom?: (value: boolean) => void;
};

export type InvertedInfiniteScrollState = {};

export class InvertedInfiniteScroll extends Component<
  InvertedInfiniteScrollProps,
  InvertedInfiniteScrollState
> {
  private readonly node: React.RefObject<HTMLDivElement>;

  constructor(props: InvertedInfiniteScrollProps) {
    super(props);
    this.node = React.createRef();
  }

  componentDidMount(): void {
    this.scrollToBottom();
  }

  getSnapshotBeforeUpdate(
    prevProps: InvertedInfiniteScrollProps
  ): number | null {
    if (prevProps.children.length <= this.props.children.length) {
      const list = this.node.current as HTMLDivElement;
      const scrollDelta = list.scrollHeight - list.scrollTop;
      return scrollDelta;
    }
    return null;
  }

  componentDidUpdate(
    prevProps: InvertedInfiniteScrollProps,
    prevState: InvertedInfiniteScrollState,
    snapshot: any
  ): void {
    if (!snapshot) {
      this.scrollToBottom();
    } else {
      this.scrollToLastPosition(snapshot);
      if (this.props.setForceScrollToBottom) {
        this.scrollToBottom();
        this.props.setForceScrollToBottom(false);
      }
    }
  }

  scrollToBottom(): void {
    const scrollableContentNode = this.node.current;
    if (scrollableContentNode) {
      scrollableContentNode.scrollTop = scrollableContentNode.scrollHeight;
    }
  }

  scrollToLastPosition(snapshot: any): void {
    const scrollableContentNode = this.node.current;
    if (scrollableContentNode) {
      scrollableContentNode.scrollTop =
        scrollableContentNode.scrollHeight - snapshot;
    }
  }
  loadMoreDebounce = debounce(() => {
    const { loadMore, hasMore, isLoading } = this.props;
    if (hasMore && !isLoading) {
      loadMore();
    }
  }, 1000);

  render(): JSX.Element {
    const {
      children,
      className,
      hasMore,
      height,
      isLoading,
      loadMore,
    } = this.props;

    const LoadMoreViewport = handleViewport(
      LoadMoreButton,
      {},
      { disconnectOnLeave: true }
    );

    return (
      <div
        id="chat-converstation-invert-scroll"
        className={classNames("simple-infinite-scroll", className)}
        style={{ height: height }}
        ref={this.node}>
        <LoadMoreViewport
          onEnterViewport={this.loadMoreDebounce}
          hasMore={hasMore}
          loadMore={loadMore}
          isLoading={isLoading}
        />

        {children}
      </div>
    );
  }
}
