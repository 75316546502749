import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { Dict } from "../../../types";

import { runFunction } from "../../http";
import { ResourceItem } from "../../../types/Resource";

export type ServiceApiGetResourcesOptions = {
  per: number;
  page: number;
  notIncludeBody: boolean;
};

export const getResources = async (
  options: ServiceApiGetResourcesOptions
): Promise<Dict<ResourceItem>> => {
  console.debug("[ServiceApi] getResources");

  const response: ResourceItem[] = await runFunction(
    "getContentPages",
    options,
    {
      requireUser: true,
    }
  );

  return _.object(_.map(response, value => [value.id, convertObject(value)]));
};
