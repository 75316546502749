import {
  getVideos as _getVideos,
  getVideo as _getVideo,
} from "../../services/api";
import { logException } from "../../lib/utils";
import _ from "underscore";
import { page as _page } from "../../constants";
import { VIDEO_ALL_SET, VIDEO_SET } from "./constants";
import { AppThunkDispatch, GetState, AppThunkAction } from "../../types";
import { Video } from "../../types/VideoLibrary/Video";

const per = _page.common;

export const getVideos = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getVideos", { next, refresh });

  try {
    const { video } = getState();
    const { list } = video;
    let { page } = list;
    const { items } = list;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    } else if (next) {
      page++;
    }

    const options = {
      per,
      page,
    };

    let data = await _getVideos(options);

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: VIDEO_ALL_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getVideo = (
  id: string,
  refresh: boolean,
  callback?: (data: Video) => void
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getVideo", { id });

  try {
    const { video } = getState();
    const { list } = video;

    const { items, cached } = list;
    const cachedData = cached[id] || (items && items[id]);
    if (!refresh && cachedData) {
      if (callback) {
        callback(cachedData);
      }
      return;
    }
    const data = await _getVideo(id);

    if (callback && data) {
      callback(data);
    }
    dispatch({
      type: VIDEO_SET,
      data: data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
