/* eslint-disable @typescript-eslint/camelcase */
import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { CountryCode } from "react-native-country-picker-modal";
import { connect } from "react-redux";
import { isEmpty } from "underscore";

import { track } from "../../lib/track";
import { isValidPhone, isValidEmail } from "../../lib/utils";

import {
  SettingConfigData,
  RootState,
  SettingSettingData,
  UserDataState,
} from "../../types";
import { FeedbackData } from "../../types/Support/FeedbackData";

import { sendFeedBack } from "./action";

export type SupportStateProps = {
  user: UserDataState | {};
  setting: {
    logo: string;
    organizationName: string;
    signInByEmail?: boolean;
  };
};

export type SupportDispatchProps = {
  sendFeedBack: (data: FeedbackData) => Promise<{ data: boolean }>;
};

export type SupportLayoutProps = {
  data: SupportData;
  error: string | null;
  loading: boolean;
  options: {
    issueType: {
      id: string;
      name: string;
    }[];
    supportIssueType: {
      id: string;
      name: string;
    }[];
  };
  setting: {
    logo: string;
    organizationName: string;
    signInByEmail?: boolean | undefined;
  };
  user: {} | UserDataState;

  handleChange: <K extends keyof SupportData>(
    key: K,
    value: SupportData[K]
  ) => void;
  sendFeedBack: (callback?: (text: string) => void) => void;
  sendSupport: (callback?: (text: string) => void) => void;
};

export type SupportProps = {
  Layout: ComponentType<SupportLayoutProps>;
  onSendFeedbackSuccess?: () => void;
  onSendSupportSuccess?: () => void;
} & SupportStateProps &
  SupportDispatchProps &
  WithTranslation;

export type SupportData = {
  countryCode: CountryCode;
  phoneNumber: string;
  description?: string;
  issueType?: string;
  email?: string;
  name?: string;
};

export type SupportState = {
  error: string | null;
  loading: boolean;
  data: SupportData;
  options: {
    issueType: { id: string; name: string }[];
    supportIssueType: { id: string; name: string }[];
  };
};

class Support extends Component<SupportProps, SupportState> {
  constructor(props: SupportProps) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      data: {
        countryCode: "US",
        phoneNumber: "+1",
      },
      options: {
        issueType: [
          { id: "feedback", name: this.props.t("Container.Index.Feedback") },
          { id: "bug", name: this.props.t("Container.Index.Bug") },
          {
            id: "suggestion",
            name: this.props.t("Container.Index.Suggestion"),
          },
        ],
        supportIssueType: props.setting.signInByEmail
          ? [
              { id: "1", name: this.props.t("Container.Index.Email") },
              { id: "2", name: this.props.t("Container.Index.Password") },
              { id: "3", name: this.props.t("Container.Index.Email.New") },
              { id: "4", name: this.props.t("Container.Index.Feedback.Share") },
              { id: "5", name: this.props.t("Container.Index.Other") },
            ]
          : [
              { id: "1", name: this.props.t("Container.Index.Mobile") },
              { id: "2", name: this.props.t("Container.Index.Pin") },
              { id: "3", name: this.props.t("Container.Index.Mobile.Number") },
              { id: "4", name: this.props.t("Container.Index.Feedback.Share") },
              { id: "5", name: this.props.t("Container.Index.Other") },
            ],
      },
    };
  }

  componentDidMount(): void {
    track("View Screen", {
      Screen: "feedback",
    });
  }

  onHandleChange = <K extends keyof SupportData>(
    key: K,
    value: SupportData[K]
  ): void => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ ...this.state, data });
  };

  onSendFeedBack = (callback?: (text: string) => void): void => {
    const { sendFeedBack, user } = this.props;
    const { data } = this.state;
    const { description, issueType } = data;
    const { email, username, firstName, lastName } = user as UserDataState;
    if (isEmpty(issueType)) {
      this.setState({
        error: this.props.t("Container.Index.Error.Issue.Type"),
      });
      return;
    }
    if (isEmpty(description)) {
      this.setState({
        error: this.props.t("Container.Index.Error.Issue.Description"),
      });
      return;
    }

    const customData: FeedbackData = {
      name: `${firstName} ${lastName}`,
      email,
      type: issueType,
      content: description,
      username,
    };
    this.setState({ loading: true }, async () => {
      try {
        const resp = await sendFeedBack(customData);
        if (resp) {
          this.setState({ loading: false, error: null });
          this.props.onSendFeedbackSuccess?.();
          callback?.(this.props.t("Container.Index.Feedback.Thanks"));
        }
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  onSendSupport = (callback?: (text: string) => void): void => {
    const { sendFeedBack, setting } = this.props;
    const { data } = this.state;
    const { description, issueType, email, phoneNumber, name } = data;
    if (isEmpty(email) || !isValidEmail(email)) {
      this.setState({ error: this.props.t("Container.Index.Email.Input") });
      return;
    }
    if (isEmpty(phoneNumber) || !isValidPhone(phoneNumber)) {
      this.setState({ error: this.props.t("Container.Index.Phone.Input") });
      return;
    }
    if (isEmpty(name)) {
      this.setState({ error: this.props.t("Container.Index.Name.Input") });
      return;
    }
    if (isEmpty(issueType)) {
      this.setState({
        error: this.props.t("Container.Index.Error.Issue.Type"),
      });
      return;
    }
    const customData: FeedbackData = {
      name,
      email,
      type: issueType,
      content: description,
      username: setting.signInByEmail
        ? (email as string)
        : (phoneNumber as string),
    };
    this.setState({ loading: true }, async () => {
      try {
        const resp = await sendFeedBack(customData);
        if (resp) {
          this.setState({ loading: false, error: null });
          this.props.onSendSupportSuccess?.();
          callback?.(this.props.t("Container.Index.Support.Thanks"));
          track("Send Feedback", {
            Data: customData,
          });
        }
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { user, Layout, setting } = this.props;
    const { error, loading, data, options } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        user={user}
        data={data}
        options={options}
        setting={setting}
        handleChange={this.onHandleChange}
        sendFeedBack={this.onSendFeedBack}
        sendSupport={this.onSendSupport}
      />
    );
  };
}

const mapStateToProps = (state: RootState): SupportStateProps => {
  const { setting, config } = state.setting;
  const { enable_signin_by_email, logo_large } = setting as SettingSettingData;
  const { organizationName } = config as SettingConfigData;
  return {
    user: state.user.data || {},
    setting: {
      logo: logo_large,
      organizationName,
      signInByEmail: enable_signin_by_email,
    },
  };
};

const mapDispatchToProps = {
  sendFeedBack,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Support")(Support));
