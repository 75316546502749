import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { isEmpty } from "underscore";

import { track } from "../../lib/track";

import { ExportMembersResult } from "../../services/api";

import {
  Dict,
  RootState,
  SettingState,
  UserDataState,
  UserFeature,
} from "../../types";

import { getMediaSetting, setMediaSetting } from "../Media/action";
import { MediaSettingSetAction } from "../Media/types";
import { exportMembers } from "../Member/action";

import {
  logOut,
  getNotificationSettings,
  saveNotificationSettings,
} from "./action";
import { NotificationSettings } from "./types";

export type UserSettingStateProps = {
  notificationSettings: NotificationSettings;
  mediaSetting: Partial<Dict<boolean, MediaSet>>;
  user: UserDataState | {};
  setting: SettingState;
};

export type UserSettingDispatchProps = {
  exportMembers: (exportId: string | undefined) => Promise<ExportMembersResult>;
  logOut: (fromWeb?: boolean) => Promise<unknown>;

  /** Notification settings */
  getNotificationSettings: (refresh: boolean) => Promise<void>;
  saveNotificationSettings: (
    setting: { [key in NotificationsSet]: number }
  ) => Promise<unknown>;

  /** Media settings */
  getMediaSetting: () => Promise<MediaSettingSetAction>;
  setMediaSetting: (
    data: Partial<Dict<boolean, MediaSet>>
  ) => Promise<MediaSettingSetAction>;
};

export type UserSettingLayoutProps = {
  appVersion?: string;
  error: string | null;
  exportMembersResult: ExportMembersResult;
  feature?: UserFeature;
  loggingOut?: boolean;
  mediaSetting: Partial<Dict<boolean, MediaSet>>;
  notificationSettings: Partial<Dict<number, NotificationsSet>>;
  notificationSettingsDefault: Dict<number, NotificationsSet>;
  /** @TODO Probably that could be typed more precisely. */
  notificationSettingsOptions: Dict<
    /** @TODO In long term Dict could be better than array */
    { value: number; key: string }[],
    NotificationsSet
  >;
  refreshing: boolean;
  sending?: boolean;
  setting: SettingState;
  user: {} | UserDataState;

  /** @FIXME Standarize events' handlers names */
  changeMediaSetting: (name: MediaSet, value: boolean) => void;
  exportMembers: (callback: (url: string) => void) => Promise<void>;
  logOut: (fromWeb: boolean) => void;
  onChangeNotificationSettings: (name: NotificationsSet, value: number) => void;
};

export type UserSettingProps = {
  Layout: ComponentType<UserSettingLayoutProps>;
  /** @TODO Investigate this prop type. Probably this is never set or used */
  item?: any;
  feature?: UserFeature;
} & UserSettingDispatchProps &
  UserSettingStateProps &
  WithTranslation;

const notifications = [
  "groupPushNotifications",
  "groupMailNotifications",
  "mailMessageNotifications",
  "enabledTextMessage",
] as const;

export type NotificationsSet = typeof notifications[number];

const media = ["autoPlayVideo"] as const;
export type MediaSet = typeof media[number];

interface State {
  error: string | null;
  exportMembersResult: ExportMembersResult;
  loggingOut?: boolean;
  mediaSetting: Partial<Dict<boolean, MediaSet>>;
  notificationSettings: Partial<Dict<number, NotificationsSet>>;
  notificationSettingsDefault: Dict<number, NotificationsSet>;
  /** @TODO Probably that could be typed more precisely. */
  notificationSettingsOptions: Dict<
    /** @TODO In long term Dict could be better than array */
    { value: number; key: string }[],
    NotificationsSet
  >;
  refreshing: boolean;
  sending?: boolean;
}

class UserSetting extends Component<UserSettingProps, State> {
  constructor(props: UserSettingProps) {
    super(props);

    const { t } = this.props;

    this.state = {
      error: null,
      refreshing: false,
      notificationSettings: {},
      notificationSettingsDefault: {
        groupPushNotifications: 0,
        groupMailNotifications: 1,
        mailMessageNotifications: 0,
        enabledTextMessage: 0,
      },
      notificationSettingsOptions: {
        groupPushNotifications: [
          { value: 0, key: t("Container.Index.Option.1") },
          { value: 1, key: t("Container.Index.Option.2") },
          { value: 2, key: t("Container.Index.Option.3") },
          { value: 3, key: t("Container.Index.Option.Never") },
        ],
        groupMailNotifications: [
          { value: 0, key: t("Container.Index.Option.4") },
          { value: 1, key: t("Container.Index.Option.5") },
          { value: 2, key: t("Container.Index.Option.Daily") },
          { value: 4, key: t("Container.Index.Option.Weekly") },
          { value: 3, key: t("Container.Index.Option.Never") },
        ],
        mailMessageNotifications: [
          { value: 0, key: t("Container.Index.Option.Always") },
          { value: 1, key: t("Container.Index.Option.Never") },
        ],
        enabledTextMessage: [
          { value: 0, key: t("Container.Index.Option.Always") },
          { value: 1, key: t("Container.Index.Option.Never") },
        ],
      },
      mediaSetting: {},
      sending: false,
      loggingOut: false,
      exportMembersResult: {},
    };
  }
  exportMembersTimeout = 0;

  static getDerivedStateFromProps(
    nextProps: UserSettingProps,
    prevState: State
  ):
    | null
    | { notificationSettings: { [key in NotificationsSet]?: number } }
    | { mediaSetting: { [key in MediaSet]?: boolean } } {
    if (
      nextProps.notificationSettings &&
      isEmpty(prevState.notificationSettings)
    ) {
      return {
        notificationSettings: nextProps.notificationSettings,
      };
    } else if (nextProps.mediaSetting && isEmpty(prevState.mediaSetting)) {
      return {
        mediaSetting: nextProps.mediaSetting,
      };
    } else return null;
  }

  componentDidMount(): void {
    const { item, getMediaSetting } = this.props;
    if (!item) {
      this.getNotificationSettings(true);
      getMediaSetting();
    }
  }

  componentWillUnmount(): void {
    this.exportMembersTimeout && clearTimeout(this.exportMembersTimeout);
  }

  onChangeNotificationSettings = (
    name: NotificationsSet,
    value: number
  ): void => {
    const { notificationSettings } = this.state;
    notificationSettings[name] = value;
    this.setState({ notificationSettings }, () => {
      this.saveNotificationSettings();
    });
  };

  getNotificationSettings = (refresh: boolean, callback?: () => void): void => {
    const { getNotificationSettings } = this.props;
    const { refreshing } = this.state;
    if (refreshing) {
      return;
    }
    this.setState({ refreshing: true }, async () => {
      try {
        await getNotificationSettings(refresh);
        this.setState({ refreshing: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  saveNotificationSettings = (
    refresh?: boolean,
    callback?: () => void
  ): void => {
    const { saveNotificationSettings } = this.props;
    const {
      sending,
      notificationSettings,
      notificationSettingsDefault,
    } = this.state;
    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        const newData = {
          groupPushNotifications:
            notificationSettings.groupPushNotifications ||
            notificationSettingsDefault.groupPushNotifications,
          groupMailNotifications:
            notificationSettings.groupMailNotifications ||
            notificationSettingsDefault.groupMailNotifications,
          mailMessageNotifications:
            notificationSettings.mailMessageNotifications ||
            notificationSettingsDefault.mailMessageNotifications,
          enabledTextMessage:
            notificationSettings.enabledTextMessage ||
            notificationSettingsDefault.enabledTextMessage,
        };

        await saveNotificationSettings(newData);
        this.setState({ sending: false, error: null });
        callback?.();

        track("Edit Group Push Notifications Settings", {
          Name: newData.groupPushNotifications,
        });
        track("Edit Group Email Notifications Settings", {
          Name: newData.groupMailNotifications,
        });

        track("Edit Email Message Notifications Settings", {
          Name: newData.mailMessageNotifications,
        });
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  onChangeMediaSetting = (name: MediaSet, value: boolean): void => {
    const { mediaSetting } = this.state;
    mediaSetting[name] = value;
    this.setState({ mediaSetting }, () => {
      this.onSetMediaSetting();
    });
  };

  onSetMediaSetting = (): void => {
    const { setMediaSetting } = this.props;
    const { sending, mediaSetting } = this.state;
    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        await setMediaSetting(mediaSetting);
        this.setState({ sending: false, error: null });
        track("Change Media Setting");
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  onLogOut = (fromWeb: boolean): void => {
    this.setState({ loggingOut: true }, async () => {
      try {
        await this.props.logOut(fromWeb);
        this.setState({ loggingOut: false, error: null });
        track("Log Out");
      } catch (error) {
        this.setState({ loggingOut: false, error: error });
      }
    });
  };

  onExportMembers = async (callback: (url: string) => void): Promise<void> => {
    const { exportMembersResult } = this.state;
    const { exportMembers } = this.props;
    try {
      let data = await exportMembers(exportMembersResult.export_id);
      data = {
        ...data,
        processing:
          ["created", "processing"].indexOf(data.status || "") > -1
            ? true
            : false,
        completed: data.status === "completed",
        progress: Math.round(data.progress || 0),
      };

      this.setState({
        exportMembersResult: data,
      });
      if (!data.completed) {
        this.exportMembersTimeout = setTimeout(() => {
          this.onExportMembers(callback);
        }, 3 * 1000);
      } else if (data.url) {
        callback(data.url);
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  render = (): JSX.Element => {
    const { Layout, user, setting, feature } = this.props;
    const {
      refreshing,
      error,
      notificationSettings,
      notificationSettingsOptions,
      sending,
      notificationSettingsDefault,
      mediaSetting,
      loggingOut,
      exportMembersResult,
    } = this.state;

    return (
      <Layout
        error={error}
        refreshing={refreshing}
        sending={sending}
        loggingOut={loggingOut}
        user={user}
        mediaSetting={mediaSetting}
        setting={setting}
        feature={feature}
        exportMembersResult={exportMembersResult}
        changeMediaSetting={this.onChangeMediaSetting}
        notificationSettings={notificationSettings}
        notificationSettingsDefault={notificationSettingsDefault}
        notificationSettingsOptions={notificationSettingsOptions}
        onChangeNotificationSettings={this.onChangeNotificationSettings}
        exportMembers={this.onExportMembers}
        logOut={this.onLogOut}
      />
    );
  };
}

const mapStateToProps = (state: RootState): UserSettingStateProps => {
  return {
    user: state.user.data || {},
    notificationSettings: state.user
      .notificationSettings as NotificationSettings,
    mediaSetting: state.media.setting,
    setting: state.setting,
  };
};

const mapDispatchToProps = {
  logOut,
  getNotificationSettings,
  saveNotificationSettings,
  getMediaSetting,
  setMediaSetting,
  exportMembers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("User")(UserSetting));
