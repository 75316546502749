import { runQuery } from "../../http";
import { Video } from "../../../types/VideoLibrary/Video";
import { convertObject } from "../../../lib/utils";

export const getVideo = async (id: string): Promise<Video> => {
  console.debug("[Service] getVideo", { id });

  const parseResponse = await runQuery(
    "Video",
    (query: Parse.Query) => {
      query.equalTo("objectId", id);
      query.include(["author"]);
      return query.first();
    },
    { requireUser: true }
  );
  return Object.assign(convertObject(parseResponse), { id });
};
