import React from "react";

import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";

import { HistoryLocation, SettingDataLoaded } from "../../../types";

import { useUserDataState } from "../../hooks";

import { RouteAppProtectedComponent } from ".";

export type RouteAppProtectedProps = RouteProps & {
  setting?: SettingDataLoaded;

  clearRedirectLocation: () => void;
  setRedirectLocation: (redirectLocation: HistoryLocation) => void;
};

export const RouteAppProtected: React.FC<RouteAppProtectedProps> = ({
  setting,

  clearRedirectLocation,
  setRedirectLocation,
  ...props
}) => {
  const user = useUserDataState();

  const renderRouteAppProtected = (
    routeComponentProps: RouteComponentProps
  ): React.ReactNode => {
    if (setting && user) {
      return (
        <RouteAppProtectedComponent {...routeComponentProps} user={user} />
      );
    }

    /** Set redirectLocation and redirect to /login when user not logged in */
    setRedirectLocation(routeComponentProps.location);

    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  };

  return <Route {...props} render={renderRouteAppProtected} />;
};
