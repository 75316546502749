export const getArrayWithDeletedValue = <T extends { id: string }>(
  id: string,
  data: T[]
): T[] => {
  const newCommentsArray = [...data];
  const itemIndex = newCommentsArray.findIndex(x => x.id === id);

  newCommentsArray.splice(itemIndex, 1);

  return newCommentsArray;
};
