import { ReactionType } from "../../../types";

export const getNextLikeCount = <DataKey extends string>({
  newReaction,
  prevLikeCount,
  prevIsLiked,
}: {
  newReaction: ReactionType;
  prevLikeCount?: number;
  prevIsLiked?: boolean;
}): number =>
  (prevLikeCount || 0) + (newReaction === "unlike" ? -1 : prevIsLiked ? 0 : 1);
