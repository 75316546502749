import { GroupState } from "./types";

const groupStateInit: GroupState = {
  cached: null,
  error: null,
  groupsByType: {},
  groupsForPosting: null,
  groupsForTarget: null,
  groupsSuggestedForPosting: null,
  items: null,
  management: null,
  myGroups: null,
  setting: {},
  status: null,
  type: null,
  types: null,
  users: {},
  groupHome: null,
};

export default groupStateInit;
