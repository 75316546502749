import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Card,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap";
import { isEmpty } from "underscore";

import UserGroupsContainer from "../../../../containers/Group/UserGroups";
import { MemberDetailLayoutProps } from "../../../../containers/Member/types";
import UserPostsContainer from "../../../../containers/Post/UserPosts";
import {
  removeWhiteSpaces,
  shadeColor,
  parseToArray,
} from "../../../../lib/utils";

import {
  Dict,
  EmptyObject,
  UserExecutiveAssistant,
  UserPersonalInfo,
  UserProfessionalInfo,
  UserSocialMedia,
  UserSocialMediaType,
} from "../../../../types";

import { useTheme } from "../../../hooks/useTheme";

import { GroupListByStatus } from "../..";
import { LoadingSpinner } from "../..";
import { StickyNavbar } from "../..";
import { PostList } from "../../Post/List";

import { MemberDetailHeader } from "../Detail";

import { MemberDetailInfoSectionsBB } from ".";

const initialSections: {
  "My Group": Partial<Dict<any>>;
  "Personal Info": Partial<
    Dict<string, UserPersonalInfo> & {
      "Spouse/Partner/Significant Other": any;
    }
  >;
  "Professional Info": Partial<Dict<string, UserProfessionalInfo>>;
  "Executive Assistant": Partial<Dict<string, UserExecutiveAssistant>>;
  "Social Media": UserSocialMediaType | EmptyObject;
} = {
  "My Group": {},
  "Personal Info": {},
  "Professional Info": {},
  "Executive Assistant": {},
  "Social Media": {},
};

const initialVisibilities: {
  "My Group": Dict<boolean> | EmptyObject;
  "Personal Info":
    | (Dict<boolean, UserPersonalInfo> & {
        "Spouse/Partner/Significant Other": boolean;
      })
    | EmptyObject;
  "Professional Info": Dict<boolean, UserProfessionalInfo> | EmptyObject;
  "Executive Assistant": Dict<boolean, UserExecutiveAssistant> | EmptyObject;
  "Social Media": Dict<boolean, UserSocialMedia> | EmptyObject;
} = {
  "My Group": {},
  "Personal Info": {},
  "Professional Info": {},
  "Executive Assistant": {},
  "Social Media": {},
};

export const MemberDetailBB: FC<MemberDetailLayoutProps> = ({
  data,
  error,
  isMyProfile,
  options,
  setting,
  isAdmin,

  addFollows,
  createChannel,
  removeFollows,
  loginAsThisUser,
}) => {
  const {
    navigation: {
      backgroundColor,
      icon: { color },
    },
  } = useTheme();

  const [sending, setSending] = useState(false);

  const [newOptions, setNewOptions] = useState({ Industry: {}, Region: {} });

  const history = useHistory();

  const { t } = useTranslation("Member");

  const { id: memberId } = useParams<{ id: string }>();

  const mainTabNames = ["Info", "Posts", "Groups"] as const;

  type MainTabName = typeof mainTabNames[number];

  const [activeMainTab, setActiveMainTab] = useState<MainTabName>("Info");

  const isActiveMainTab = (tab: MainTabName): boolean => activeMainTab === tab;

  const toggleMainTab = (tab: MainTabName): void => {
    if (!isActiveMainTab(tab)) setActiveMainTab(tab);
  };

  useEffect(() => {
    setActiveMainTab("Info");
    setNewOptions({ Industry: {}, Region: {} });
  }, [memberId]);

  useMemo(() => setNewOptions(options), [options]);

  const backgroundColorDarker =
    shadeColor(backgroundColor, -10) || backgroundColor;
  const memberDetailBBStyles = {
    card: {
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 3,
      backgroundColor,
    },
    nav: {
      width: "100%",
    },
    navbar: {
      flexDirection: "column",
      backgroundColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
    navItem: {
      flex: 1,
      textAlign: "center",
    },
    tabLink: {
      backgroundColor: backgroundColorDarker,
      color,
    },
    tabLinkActive: {
      backgroundColor,
      borderColor: color,
    },
    tabPane: {
      backgroundColor: "transparent",
    },
  } as const;

  if (!data) {
    if (error) {
      return (
        <Alert classnName="mt-2 mb-0 text-center" color="danger">
          {error}
        </Alert>
      );
    } else return <LoadingSpinner />;
  }

  const { sections, visibilities } = data.profile ?? {
    sections: initialSections,
    visibilities: initialVisibilities,
  };

  const { firstName, lastName, avatar, id, followed } = data;

  const personal = sections["Personal Info"];
  const personalVisibilities = visibilities["Personal Info"];
  const professional = sections["Professional Info"];
  const professionalVisibilities = visibilities["Professional Info"];
  const socialMedia = sections["Social Media"];
  visibilities["Social Media"]["Google Plus"] = false;
  const socialMediaVisibilities = visibilities["Social Media"];

  const currentCompanyAvailable =
    (professionalVisibilities.Website && !!professional.Website) ||
    (professionalVisibilities.Location && !!professional.Location) ||
    (professionalVisibilities.Phone &&
      !!professional.Phone &&
      removeWhiteSpaces(professional.Phone) !== "+1");

  const contactAvailable =
    personalVisibilities.Email ||
    personalVisibilities.Phone ||
    Object.values(socialMediaVisibilities).find(item => item);

  const { Industry, Region } = newOptions;

  const summary = professionalVisibilities.Summary && professional.Summary;

  const name = `${firstName} ${lastName}`;

  const onOpenChat = async (): Promise<void> => {
    setSending(true);
    try {
      const channel = await createChannel([{ id }]);
      setSending(false);
      history.push("/chat-room-detail/" + channel.data);
    } catch {
      setSending(false);
    }
  };

  const onFollow = async (): Promise<void> => {
    setSending(true);
    try {
      if (!followed) {
        await addFollows(id, toast.success);
        setSending(false);
      } else {
        await removeFollows(id, toast.error);
        setSending(false);
      }
    } catch (e) {
      setSending(false);
    }
  };

  const memberEmail =
    !isEmpty(personal.Email) && personalVisibilities.Email && personal.Email;

  const memberPhone =
    !isEmpty(personal.Phone) &&
    personalVisibilities.Phone &&
    removeWhiteSpaces(personal.Phone) !== "+1" &&
    removeWhiteSpaces(personal.Phone);

  const currentCompany = !isEmpty(data.company) && data.company;

  const currentCompanyWebsite =
    !isEmpty(professional.Website) &&
    professionalVisibilities.Website &&
    professional.Website;

  const currentCompanyLocation =
    !isEmpty(professional.Location) &&
    professionalVisibilities.Location &&
    professional.Location;

  const currentCompanyPhone =
    !isEmpty(professional.Phone) &&
    professionalVisibilities.Phone &&
    removeWhiteSpaces(professional.Phone) !== "+1" &&
    removeWhiteSpaces(professional.Phone);

  const companyHistory =
    professionalVisibilities["Company History"] &&
    parseToArray(professional["Company History"]);

  const noUserInfoHeading = t("DetailBB.Title.User.Has.Not.Shared.Information");

  const userPostsTabName =
    window.screen.width > 600
      ? t("DetailBB.Heading.Someone.Post", {
          data,
        })
      : t("Detail.Heading.Posts");

  const navItemElements = mainTabNames.map(mainTabName => (
    <NavItem key={mainTabName} style={memberDetailBBStyles.navItem}>
      <NavLink
        style={{
          ...memberDetailBBStyles.tabLink,
          ...(isActiveMainTab(mainTabName) &&
            memberDetailBBStyles.tabLinkActive),
        }}
        onClick={(): void => {
          toggleMainTab(mainTabName);
        }}>
        {mainTabName === "Posts" && userPostsTabName}
        {mainTabName !== "Posts" && t(`Detail.Heading.${mainTabName}`)}
      </NavLink>
    </NavItem>
  ));

  return (
    <div>
      {!!error && (
        <Alert classnName="mt-2 mb-0 text-center" color="danger">
          {error}
        </Alert>
      )}
      <Card style={memberDetailBBStyles.card}>
        <MemberDetailHeader
          avatar={avatar}
          followed={followed}
          industry={Industry}
          isMyProfile={isMyProfile}
          name={name}
          onFollow={onFollow}
          onOpenChat={onOpenChat}
          region={Region}
          sending={sending}
          setting={setting}
          title={data?.title}
          isAdmin={isAdmin}
          loginAsThisUser={loginAsThisUser}
        />
        <StickyNavbar>
          <Navbar style={memberDetailBBStyles.navbar}>
            <Nav tabs style={memberDetailBBStyles.nav}>
              {navItemElements}
            </Nav>
          </Navbar>
        </StickyNavbar>

        <TabContent activeTab={activeMainTab}>
          <TabPane tabId="Info" style={memberDetailBBStyles.tabPane}>
            <MemberDetailInfoSectionsBB
              contactAvailable={contactAvailable}
              currentCompany={currentCompany}
              currentCompanyAvailable={currentCompanyAvailable}
              currentCompanyLocation={currentCompanyLocation}
              currentCompanyPhone={currentCompanyPhone}
              currentCompanyWebsite={currentCompanyWebsite}
              memberEmail={memberEmail}
              memberPhone={memberPhone}
              noUserInfoHeading={noUserInfoHeading}
              socialMedia={socialMedia}
              socialMediaVisibilities={socialMediaVisibilities}
              summary={summary}
              companyHistory={companyHistory}
            />
          </TabPane>
          <TabPane tabId="Posts" style={memberDetailBBStyles.tabPane}>
            {activeMainTab === "Posts" && (
              <UserPostsContainer
                Layout={PostList}
                isMixed={true}
                userId={id}
              />
            )}
          </TabPane>
          <TabPane tabId="Groups" style={memberDetailBBStyles.tabPane}>
            <UserGroupsContainer Layout={GroupListByStatus} userId={id} />
          </TabPane>
        </TabContent>
      </Card>
    </div>
  );
};
