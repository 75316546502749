import React from "react";

import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";
import { useTheme } from "../../hooks";

export type SearchRecentSearchesProps = {
  data: string[];
  onSearch: (item: string) => void;
};

/** @FIXME  If the query exists in history array, it's not displayed at the beginning */
export const SearchRecentSearches: React.FC<SearchRecentSearchesProps> = ({
  data,
  onSearch,
}) => {
  const { t } = useTranslation("Search");
  const {
    divider: {
      backgroundColor,
      text: { color },
    },
  } = useTheme();
  return (
    <div className={"py-4"}>
      <h6 id={"recentSearchesTitle"} style={{ backgroundColor, color }}>
        {t("Recent.Search.Heading")}
      </h6>
      {data?.map((item, index) => (
        <Badge
          id={`recentSearchesButton${index}`}
          className={"pointer"}
          key={index}
          onClick={(): void => onSearch(item)}
          color="secondary my-2 mr-2 p-2">
          {item}
        </Badge>
      ))}
    </div>
  );
};
