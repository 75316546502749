import { TAG_RESET, TAG_SEARCH_SET } from "./constants";
import Store from "./store";
import { TagState, TagAction } from "./types";

export const initialState = Store;

export const tagReducer = (
  state: TagState = initialState,
  action: TagAction
): TagState => {
  switch (action.type) {
    case TAG_RESET:
      return initialState;

    case TAG_SEARCH_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        search: {
          ...state.search,
          ...action.data,
        },
      };

    default:
      return state;
  }
};

export default tagReducer;
