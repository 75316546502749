import _ from "underscore";
import { uploadFilesForPost } from "./uploadFilesForPost";
import {
  Dict,
  NotificationFileProgress,
  NotificationPostingStatus,
  Post,
  PostData,
  PostUploadFile,
  SettingConfigData,
} from "../../../types";
import {
  prepareVideoFile,
  prepareVideo,
  addVideoToLibrary,
} from "../VideoLibrary";
import { Video } from "../../../types/VideoLibrary/Video";

export const updatePost = async (
  data: Partial<PostData> & { userId?: string; groupId: string },
  config?: SettingConfigData,
  progressCallback?: (
    name: string,
    fileProgress: Dict<NotificationFileProgress>,
    transferred: number,
    total: number,
    cancel: boolean
  ) => void,
  progressPostingCallback?: (status?: NotificationPostingStatus) => void
): Promise<{ id: string }> => {
  console.debug("[ServiceApi] updatePost", data);
  const {
    groupId,
    files,
    body,
    mentions,
    embedLink,
    linksMentions,
    tags,
    id,
    video,
  } = data;

  //prepare data for post
  const newData = {
    id,
    body,
    mentions: _.map(mentions || [], item => JSON.stringify(item)),
    linksMentions: _.map(linksMentions || [], item => JSON.stringify(item)),
    embedLink: embedLink || {},
    tags: (tags || []).map(tag => tag.toLowerCase()),
    edited: true,
  };
  let editedPost: Parse.Object<Post> = new (Parse.Object.extend("Post"))(
    newData
  );

  if (_.isEmpty(video?.objectId)) {
    progressPostingCallback?.({
      text: "Uploading video ...",
      shortText: "Uploading",
    });

    //uploading video
    const file = await prepareVideoFile({
      video,
      config,
      progressCallback,
    });

    const preparedVideo = await prepareVideo(file.data as Video, false);

    if (preparedVideo) {
      const savedVideo = await addVideoToLibrary(preparedVideo as Video, true);
      savedVideo &&
        editedPost.set(
          "video",
          new (Parse.Object.extend("Video"))({
            id: savedVideo?.id,
          })
        );
    } else {
      editedPost.unset("video");
    }
  }

  //updating a posting status to UI
  progressPostingCallback?.(
    !_.isEmpty(files)
      ? { text: "Uploading photos/files ...", shortText: "Uploading" }
      : { text: "Posting to group ...", shortText: "Posting" }
  );

  //prepare data for uploading which base on a post id
  const uploadData = {
    files: files as PostUploadFile[],
    postId: id,
    groupId: groupId,
    editing: false,
  };

  //uploading images/files to S3 and Parse
  const groupFileIds = await uploadFilesForPost(
    uploadData,
    config,
    progressCallback
  );

  //updating a posting status to UI
  progressPostingCallback?.({
    text: "Posting to group ...",
    shortText: "Posting",
  });

  //updating images to post
  editedPost.set("images", groupFileIds);
  editedPost = await editedPost.save();

  //deleting files in Parse
  const queryFileNeedBeDeleted = new Parse.Query("GroupFile");
  queryFileNeedBeDeleted.equalTo("post", editedPost);
  queryFileNeedBeDeleted.notContainedIn("objectId", editedPost.get("images"));
  const groupsFilesNeedBeDeleted = await queryFileNeedBeDeleted.find();
  await Parse.Object.destroyAll(groupsFilesNeedBeDeleted);

  //clearing a posting status to UI
  progressPostingCallback?.();

  return { id: editedPost.id };
};
