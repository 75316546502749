import _ from "underscore";

import { runFunction } from "../../http";
import { PostItemDict } from "../../../containers/Post/types";
import { PostItem } from "../../../types/Post/PostItem";
import { convertObject } from "../../../lib/utils";
import { parseMentionsInPostItem } from "../ParseMentionsHelper";

/**
 * @TODO Add types while containers/Search typing
 */
export const searchFullTextPosts = async (options: {}): Promise<PostItemDict> => {
  const resp: PostItem[] = await runFunction("searchFeed", options, {
    requireUser: true,
  });

  /**
   * @FIXME Converting logic temporarily moved here.
   * Reconsider move to another layer.
   */
  return _.object(
    _.map(resp, (value: PostItem) => {
      return [value.post.id, parseMentionsInPostItem(convertObject(value))];
    })
  );
};
