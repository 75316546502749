import { page as _page } from "../../constants";

import { logException } from "../../lib/utils";

import i18n from "../../middlewares/i18next";

import {
  searchUsers as _searchUsers,
  searchAnnouncement as _searchAnnouncement,
  searchEvent as _searchEvent,
  searchGroup as _searchGroup,
  searchResource as _searchResource,
} from "../../services/api";

import { MentionSearchUserInfo, MentionSearchLinkInfo } from "../../types";

const per = _page.mention;
export function searchUsers(
  query: string,
  options: { groupId?: string; includeHereAndChannel: boolean }
) {
  return (): Promise<{ data: MentionSearchUserInfo[] }> =>
    new Promise<{ data: MentionSearchUserInfo[] }>((resolve, reject) => {
      const { groupId, includeHereAndChannel } = options || {};
      const option = {
        page: 0,
        per,
        query,
        groupId,
      };
      const extraData: MentionSearchUserInfo[] = [
        { name: "here", id: "here" },
        { name: "channel", id: "channel" },
      ].filter(
        item =>
          includeHereAndChannel && item.name.indexOf(query.toLowerCase()) > -1
      );

      _searchUsers(option)
        .then(function(resp) {
          return resolve({ data: resp.concat(extraData) });
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function searchLinks(query: RegExp) {
  return async (): Promise<{
    data: { title: string; data: MentionSearchLinkInfo[] }[];
  }> => {
    try {
      const data: { title: string; data: MentionSearchLinkInfo[] }[] = [];

      const resourcesData = await _searchResource(query);
      if (resourcesData) {
        data.push({
          title: i18n.t("Mention:Container.Action.Resources"),
          data: resourcesData,
        });
      }

      const groupsData = await _searchGroup(query);
      if (groupsData) {
        data.push({
          title: i18n.t("Mention:Container.Action.Groups"),
          data: groupsData,
        });
      }

      const eventsData = await _searchEvent(query);
      if (eventsData) {
        data.push({
          title: i18n.t("Mention:Container.Action.Events"),
          data: eventsData,
        });
      }

      const announcementsData = await _searchAnnouncement(query);
      if (announcementsData) {
        data.push({
          title: i18n.t("Mention:Container.Action.Announcements"),
          data: announcementsData,
        });
      }

      return { data };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}
