import { SyntheticEvent } from "react";

import { useHistory } from "react-router-dom";

/**
 * Returns history go back event handler
 */
export const useHistoryGoBackHandler = (): ((e: SyntheticEvent) => void) => {
  const history = useHistory();

  const handleHistoryGoBack = (e: SyntheticEvent): void => {
    e.preventDefault();

    history.goBack();
  };

  return handleHistoryGoBack;
};
