import React, { useState } from "react";

import { Alert, Button } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink as RouterNavLink } from "react-router-dom";
import { NavItem, NavLink, DropdownItem } from "reactstrap";

import { exitLoggingInAsGuest } from "../../../containers/User/action";
import { UserDataState, NavigationFeature } from "../../../types";

import {
  useClickOutsideRef,
  useTheme,
  useTypedDispatch,
  useTypedSelector,
  useUserDispatchLogout,
} from "../../hooks";

import { Avatar, Icon } from "..";

export type NavbarDropdownProps = {
  user: UserDataState;
  navigationFeaturesMore: NavigationFeature[];
  toggleNavigation?: () => void;
};

export const NavbarDropdown: React.FC<NavbarDropdownProps> = ({
  user,
  toggleNavigation,
  navigationFeaturesMore,
}) => {
  const logout = useUserDispatchLogout();
  const {
    navigation: { backgroundColor },
  } = useTheme();

  const viewingAsGuest = useTypedSelector(state => state.user.viewingAsGuest);
  const subscriptionsEnabled = useTypedSelector(
    state => state.setting.setting?.subscriptions_enabled
  );
  const dispatch = useTypedDispatch();

  const { t } = useTranslation("Navbar");

  const handleLogout = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();

    logout();
  };

  const [isAccountDropdownOpen, setAccountDropdownOpen] = useState(false);

  const toggleAccountDropdown = (): void =>
    setAccountDropdownOpen(!isAccountDropdownOpen);

  const handleClickOutside = (): void => {
    if (isAccountDropdownOpen) {
      toggleAccountDropdown();
    }
  };

  const dropdownRef = useClickOutsideRef(handleClickOutside);

  const isUserActive = user.status === "active";

  const subscriptionElements = isUserActive && subscriptionsEnabled && (
    <>
      <NavItem className="d-block">
        <NavLink
          title={t("Navbar.Subscription")}
          id="subscriptions"
          tag={RouterNavLink}
          to="/subscription">
          <i className="icon-diamond" />
          <span className="pl-3 pt-2">{t("Navbar.Subscription")}</span>
        </NavLink>
      </NavItem>
    </>
  );

  const activeUserDropdownElements = isUserActive && (
    <>
      <NavItem className="d-block">
        <NavLink
          /**@TODO fix borderLeftColor*/
          // style={{ borderLeftColor: backgroundColor }}
          title={t("Navbar.My.Profile")}
          id="myProfile"
          tag={RouterNavLink}
          to={`/member/${user.id}`}>
          <i className="icon-user" />
          <span className="pl-3 pt-2">{t("Navbar.My.Profile")}</span>
        </NavLink>
      </NavItem>

      <NavItem className="d-block">
        <NavLink
          title={t("Navbar.Settings")}
          id="settings"
          tag={RouterNavLink}
          to="/settings">
          <i className="icon-wrench" />
          <span className="pl-3 pt-2">{t("Navbar.Settings")}</span>
        </NavLink>
      </NavItem>

      {subscriptionElements}
    </>
  );

  const dropdownFeatureElements = navigationFeaturesMore
    .filter(({ isVisible }) => isVisible)
    .map(({ icon, title, ...navLinkProps }, index) => (
      <NavItem key={index} className="d-block">
        <NavLink {...navLinkProps} onClick={toggleNavigation}>
          <div className={"text-center"} style={{ width: 16 }}>
            <Icon
              type={icon.type}
              name={icon.on}
              style={{ height: 16, fontSize: 16, width: 16 }}
            />
          </div>
          <span className="pl-3 pb-3">{title}</span>
        </NavLink>
      </NavItem>
    ));

  const handleExitLoggingInAsGuest = (): void => {
    dispatch(exitLoggingInAsGuest());
  };

  const LoginAsGuestElement = viewingAsGuest && (
    <NavItem className="d-block">
      <Alert
        className="text-center viewing-as-guest-dropdown-alert"
        color="danger">
        <span>{t("Navbar.ViewAsGuest.Text")}</span>
        <Button
          color="danger"
          className="text-center w-100 mt-2 "
          onClick={handleExitLoggingInAsGuest}>
          {t("Navbar.ViewAsGuest.Exit")}
        </Button>
      </Alert>
    </NavItem>
  );

  const dropdownElements = (
    <>
      {LoginAsGuestElement}
      <DropdownItem divider id="userDivider" />
      <div onClick={toggleNavigation}>{activeUserDropdownElements}</div>
      <NavItem className="d-block">
        <NavLink
          title="Logout"
          id="logout"
          onClick={handleLogout}
          className="cursor-pointer">
          <i className="icon-power" />
          <span className="pl-3 pt-2">{t("Navbar.Logout")}</span>
        </NavLink>
      </NavItem>
      <DropdownItem divider />
      <div onClick={toggleNavigation}>{dropdownFeatureElements}</div>
    </>
  );

  return (
    <div ref={dropdownRef} className="navbar-dropdown">
      <NavItem
        className="d-none d-md-block"
        style={{ background: viewingAsGuest ? "#dc3545" : undefined }}>
        <NavLink
          style={{ borderBottomColor: backgroundColor }}
          id="usernameDropdown"
          className="dropdown-toggle navbar-dropdown-toggle cursor-pointer"
          onClick={toggleAccountDropdown}>
          <Avatar
            className="mr-2"
            src={user.avatar}
            name={`${user.firstName} ${user.lastName}`}
            round={true}
            size="30"
          />
          <span>{user.firstName}</span>
        </NavLink>
      </NavItem>
      <div
        onClick={toggleAccountDropdown}
        className={classnames("navbar-dropdown-menu", {
          show: isAccountDropdownOpen,
        })}>
        {dropdownElements}
      </div>
    </div>
  );
};
