import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { SupportData } from "../../../../containers/Support";

import { Error, LoadingSpinner } from "../../UI";

import FeedbackDropdown from "./SupportFeedbackDropdown";
import { UserDataState } from "../../../../types";
import { useTheme } from "../../../hooks";

export type SupportFeedbackProps = {
  data: SupportData;
  error: string | null;
  loading: boolean;
  options: {
    issueType: {
      id: string;
      name: string;
    }[];
    supportIssueType: {
      id: string;
      name: string;
    }[];
  };
  setting: {
    logo: string;
    organizationName: string;
    signInByEmail?: boolean | undefined;
  };
  user: {} | UserDataState;

  handleChange: <K extends keyof SupportData>(
    key: K,
    value: SupportData[K]
  ) => void;
  sendFeedBack: (callback?: (text: string) => void) => void;
  sendSupport: (callback?: (text: string) => void) => void;
};

export const SupportFeedback: React.FC<SupportFeedbackProps> = ({
  data,
  error,
  loading,
  options,

  handleChange,
  sendFeedBack,
}) => {
  const { t } = useTranslation("Support");
  const {
    button: {
      backgroundColor,
      text: { color },
    },
  } = useTheme();
  const { description } = data;

  const onSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
    sendFeedBack(text => {
      toast.success(text);
    });
  };

  if (!data) {
    if (error) {
      return <Error content={error} />;
    }
    return <LoadingSpinner />;
  }

  return (
    <Card>
      <CardBody>
        {!!error && <Alert color="danger">{error}</Alert>}
        <Form>
          <h5
            id="feedbackFormTitle"
            className="mb-5"
            style={{ textAlign: "center" }}>
            {t("Feedback.Heading.Bugs.Feedback")}
          </h5>
          <FormGroup>
            <FeedbackDropdown
              issueTypes={options.issueType}
              onValueChange={(option): void =>
                handleChange("issueType", option.id)
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">
              {t("Feedback.Placeholder.Description")}
            </Label>
            <Input
              type="textarea"
              name="description"
              id="feedbackIssueDescriptionInput"
              value={description}
              onChange={(e): void =>
                handleChange("description", e.target.value)
              }
            />
          </FormGroup>
        </Form>
        <Button
          id="feedbackSubmitButton"
          block
          className="border-0"
          style={{ backgroundColor, color }}
          disabled={loading}
          onClick={onSubmit}>
          {loading ? t("Feedback.Button.Loading") : t("Feedback.Button.Submit")}
        </Button>
      </CardBody>
    </Card>
  );
};
