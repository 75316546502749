import React from "react";

import MediaSearchGiphyContainer from "../../../../containers/Media/SearchGiphy";
import MediaSearchImageStockContainer from "../../../../containers/Media/SearchImageStock";
import MediaSearchImageUsedContainer from "../../../../containers/Media/SearchImageUsed";
import MediaSearchImageWebContainer from "../../../../containers/Media/SearchImageWeb";

import { MediaSearchImageOption, MediaPhoto } from "../../../../types";

import { MediaSearchProvider } from ".";

export type MediaSearchImageServiceProps = {
  service: MediaSearchImageOption;

  onSelect: (image: MediaPhoto) => void;
};

export const MediaSearchImageService: React.FC<MediaSearchImageServiceProps> = ({
  service,

  onSelect,
}) => {
  switch (service) {
    case "giphy":
      return (
        <MediaSearchGiphyContainer
          Layout={MediaSearchProvider}
          onSelect={onSelect}
        />
      );

    case "used":
      return (
        <MediaSearchImageUsedContainer
          Layout={MediaSearchProvider}
          onSelect={onSelect}
        />
      );

    case "stock":
      return (
        <MediaSearchImageStockContainer
          Layout={MediaSearchProvider}
          onSelect={onSelect}
        />
      );

    case "web":
      return (
        <MediaSearchImageWebContainer
          Layout={MediaSearchProvider}
          onSelect={onSelect}
        />
      );
  }
};
