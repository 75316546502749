import React from "react";

import { RouteComponentProps } from "react-router-dom";

import MemberDetailContainer from "../../../containers/Member/Detail";

import { MemberDetail } from "../../components/Member/Detail";
import { MemberDetailBB } from "../../components/Member/DetailBB";

export type RouteMemberDetailProps = RouteComponentProps<{
  id?: string;
}>;

export const RouteMemberDetails: React.FC<RouteMemberDetailProps> = props => (
  <MemberDetailContainer
    {...props}
    Layout={MemberDetail}
    LayoutBB={MemberDetailBB}
  />
);
