import React, { useRef, useState } from "react";

import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { UserUpdateProfileLayoutProps } from "../../../containers/User/UpdateProfile";
import { pattern } from "../../../lib/utils";
import {
  UserDataState,
  UserExecutiveAssistantType,
  UserPersonalInfoType,
  UserProfessionalInfoType,
  UserProfileData,
  UserProfileSections,
  UserProfileVisibilities,
  UserSocialMediaType,
} from "../../../types";

import { MapSearchBox, Avatar, LoadingSpinner, StickyNavbar } from "..";

import {
  UserProfileExecutiveAssistantInfo,
  UserProfilePersonalInfo,
  UserProfileProfessionalInfo,
  UserProfileSocialMediaInfo,
} from ".";

import { useTheme } from "../../hooks";
import { MediaCropImage } from "../Media";

const editProfileTabsNames = [
  "Personal",
  "Professional",
  "Executive Assistant",
  "Social",
] as const;

type EditProfileTabsNames = typeof editProfileTabsNames[number];

export type handleProfessionalInfoChangeType = <
  K extends keyof UserProfessionalInfoType
>(
  name: K,
  value: UserProfessionalInfoType[K]
) => void;

export const UserEditProfile: React.FC<UserUpdateProfileLayoutProps> = ({
  active,
  avatar,
  data,
  error,
  info,
  multipleSelectFields,
  options,
  sending,
  setting,
  template,
  user,

  onAddChildren,
  onAddCompanyHistory,
  onChangeAvatar,
  onHandleChange,
  onRemoveChildren,
  onRemoveCompanyHistory,
  updateProfile,
}) => {
  const { t } = useTranslation("User");

  const history = useHistory();

  const [activeTab, setActiveTab] = useState<string>("Personal");

  const isActiveTab = (tab: string): boolean => activeTab === tab;

  const toggleTab = (tab: string): void => {
    if (!isActiveTab(tab)) setActiveTab(tab);
  };

  const imageInputRef = useRef<HTMLInputElement>(null);

  const [isImageLoading, setImageLoading] = useState(false);

  const [isEmailValid, setEmailValid] = useState(true);

  const [isExecutiveEmailValid, setExecutiveEmailValid] = useState(true);

  const [isExecutivePhoneValid, setExecutivePhoneValid] = useState(true);

  const [isPhoneValid, setPhoneValid] = useState(true);

  const [isPhoneOtherValid, setPhoneOtherValid] = useState(true);

  const [isPhoneProfessionalValid, setPhoneProfessionalValid] = useState(true);

  const [isWebsiteValid, setWebsiteValid] = useState(true);

  const [isUserWebsiteValid, setUserWebsiteValid] = useState(true);

  const [isGoogleApiReady, setGoogleApiReady] = useState(false);

  const userData = user as UserDataState;

  const [isCropAvatarImageModalOpen, setCropAvatarImageModalOpen] = useState(
    false
  );
  const toggleCropAvatarImageModal = (): void => {
    setCropAvatarImageModalOpen(!isCropAvatarImageModalOpen);
  };

  const [avatarInputFile, setAvatarInputFile] = useState<File>();

  const {
    tabTop: { inactive, inactiveText, underlineColor },
    navigation: { backgroundColor },
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const styles = {
    tabLink: {
      backgroundColor: inactive.backgroundColor,
      color: inactiveText.color,
      opacity: inactiveText.opacity,
    },
    tabLinkActive: {
      opacity: 1,
      borderColor: underlineColor,
    },
    button: {
      backgroundColor: buttonBackgroundColor,
      color: buttonColor,
      borderWidth: 0,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapsRef = useRef<any>();

  if (!data) {
    return <LoadingSpinner />;
  }

  const emailValidate = (email: string): boolean => {
    const regexExp = new RegExp(pattern.email);
    return regexExp.test(email);
  };

  const emailOtherValidate = (email: string): boolean => {
    const regexExp = new RegExp(pattern.email);
    return regexExp.test(email) || email === "";
  };

  const phoneValidate = (phone: string): boolean => {
    const regexExp = new RegExp(pattern.phoneForRegister);
    return regexExp.test(phone);
  };

  const phoneOtherValidate = (phone: string): boolean => {
    const regexExp = new RegExp(pattern.phone);
    return regexExp.test(phone) || phone === "";
  };

  const websiteValidate = (url: string): boolean => {
    const regexExp = new RegExp(pattern.url);
    return regexExp.test(url) || url === "";
  };

  const handlePersonalInfoChange = (name: string, value: string): void => {
    const updatePersonalInfo: UserProfileData = {
      ...(data as UserProfileData),
      sections: {
        ...(data?.sections as UserProfileSections),
        "Personal Info": {
          ...(data?.sections["Personal Info"] as UserPersonalInfoType),
          [name]: value,
        },
      },
    };
    onHandleChange(updatePersonalInfo);
  };

  const handlePersonalInfoVisibilityChange = (
    name: string,
    value: string
  ): void => {
    const updatePersonalInfoVisibility: UserProfileData = {
      ...(data as UserProfileData),
      visibilities: {
        ...(data?.visibilities as UserProfileVisibilities),
        "Personal Info": {
          ...data?.visibilities["Personal Info"],
          [name]: value,
        },
      },
    };
    onHandleChange(updatePersonalInfoVisibility);
  };

  const handleProfessionalInfoChange: handleProfessionalInfoChangeType = (
    name,
    value
  ) => {
    const updateProfessionalInfo: UserProfileData = {
      ...(data as UserProfileData),
      sections: {
        ...(data?.sections as UserProfileSections),
        "Professional Info": {
          ...(data?.sections["Professional Info"] as UserProfessionalInfoType),
          [name]: value,
        },
      },
    };
    onHandleChange(updateProfessionalInfo);
  };

  const handleProfessionalInfoVisibilityChange = (
    name: string,
    value: string
  ): void => {
    const updateProfessionalInfoVisibility: UserProfileData = {
      ...(data as UserProfileData),
      visibilities: {
        ...(data?.visibilities as UserProfileVisibilities),
        "Professional Info": {
          ...data?.visibilities["Professional Info"],
          [name]: value,
        },
      },
    };
    onHandleChange(updateProfessionalInfoVisibility);
  };

  const handleExecutiveAssistantInfoChange = (
    name: string,
    value: string
  ): void => {
    const updateExecutiveInfo: UserProfileData = {
      ...(data as UserProfileData),
      sections: {
        ...(data?.sections as UserProfileSections),
        "Executive Assistant": {
          ...(data?.sections[
            "Executive Assistant"
          ] as UserExecutiveAssistantType),
          [name]: value,
        },
      },
    };
    onHandleChange(updateExecutiveInfo);
  };

  const handleExecutiveAssistantVisibilityChange = (
    name: string,
    value: string
  ): void => {
    const updateExecutiveAssistantVisibility: UserProfileData = {
      ...(data as UserProfileData),
      visibilities: {
        ...(data?.visibilities as UserProfileVisibilities),
        "Executive Assistant": {
          ...data?.visibilities["Executive Assistant"],
          [name]: value,
        },
      },
    };
    onHandleChange(updateExecutiveAssistantVisibility);
  };

  const handleSocialMediaInfoChange = (name: string, value: string): void => {
    const updateSocialMediaInfo: UserProfileData = {
      ...(data as UserProfileData),
      sections: {
        ...(data?.sections as UserProfileSections),
        "Social Media": {
          ...(data?.sections["Social Media"] as UserSocialMediaType),
          [name]: value,
        },
      },
    };
    onHandleChange(updateSocialMediaInfo);
  };

  const handleSocialMediaVisibilityChange = (
    name: string,
    value: string
  ): void => {
    const updateSocialMediaInfoVisibility: UserProfileData = {
      ...(data as UserProfileData),
      visibilities: {
        ...(data?.visibilities as UserProfileVisibilities),
        "Social Media": {
          ...data?.visibilities["Social Media"],
          [name]: value,
        },
      },
    };
    onHandleChange(updateSocialMediaInfoVisibility);
  };

  const handleUpdateProfile = (): void => {
    updateProfile(() => {
      history.push(`/member/${(user as UserDataState).id}`);
    });
  };

  const userProfileLocationPicker = (
    value: string,
    id: string,
    type: string,
    onChange?: (name: string, value: string) => void
  ): JSX.Element => (
    <>
      {isGoogleApiReady && (
        <MapSearchBox
          autocomplete={true}
          id={id}
          map={mapRef.current}
          maps={mapsRef.current}
          name="Location"
          value={value}
          placeholder={t("Update.Profile.Professional.Info.Search.Location")}
          onChange={onChange}
          type={type}
        />
      )}

      <GoogleMapReact
        bootstrapURLKeys={{
          key: setting.config?.googleMapKey as string,
          libraries: "places",
        }}
        defaultCenter={{ lat: 33.6888268, lng: -117.8850742 }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }): void => {
          mapRef.current = map;
          mapsRef.current = maps;
          setGoogleApiReady(true);
        }}
      />
    </>
  );

  const avatarCropper = avatarInputFile && (
    <Modal
      isOpen={isCropAvatarImageModalOpen}
      toggle={toggleCropAvatarImageModal}
      centered={true}
      scrollable={true}>
      <ModalHeader
        id={"CropAvatarImageModalHeader"}
        toggle={toggleCropAvatarImageModal}>
        {t("Crop.Image.Avatar.Modal.Header")}
      </ModalHeader>
      <ModalBody>
        <MediaCropImage
          file={avatarInputFile}
          onChangeAvatar={onChangeAvatar}
          setCropAvatarImageModalOpen={setCropAvatarImageModalOpen}
          setImageLoading={setImageLoading}
        />
      </ModalBody>
    </Modal>
  );

  const name = `${userData.firstName} ${userData.lastName}`;

  const handleImageBrowserOpen = (): void => {
    imageInputRef.current?.click();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      setAvatarInputFile(e.target.files[0]);
      setCropAvatarImageModalOpen(true);
    }
  };

  const avatarUri = (avatar && avatar.fileUrl) || (user && userData.avatar);

  const industryOptions = options["Industry"];

  const regionOptions = options["Region"];

  const editUserAvatarButtonElement = (
    <>
      <Button
        id={`userEditAvatarChangeBtn`}
        className="ml-1 float-right mt-n4"
        onClick={handleImageBrowserOpen}
        disabled={sending}
        color="secondary"
        size="sm">
        <i className="icon-user" />
      </Button>
      <input
        id={`userEditAvatarFileInput`}
        ref={imageInputRef}
        className="d-none"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        onChange={handleImageChange}
      />
    </>
  );

  const userProfilePictureElement = (
    <Card>
      <CardBody>
        <CardTitle tag="h3" id="userEditProfileName">
          {name}
        </CardTitle>
        <CardSubtitle tag="small" id="userEditProfileTitle">
          {userData.title}
        </CardSubtitle>
      </CardBody>
      <CardBody
        className="pt-4"
        style={{
          backgroundColor,
        }}>
        {isImageLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "200px" }}>
            <LoadingSpinner color="white" />
          </div>
        ) : (
          <Avatar name={name} src={avatarUri} size={"200"} round={true} />
        )}
        {editUserAvatarButtonElement}
      </CardBody>

      {avatarCropper}
    </Card>
  );

  const navElement = (
    <StickyNavbar>
      <Nav tabs>
        {editProfileTabsNames.map(
          (item: EditProfileTabsNames, index: number) => {
            if (
              item === "Executive Assistant" &&
              !template["Executive Assistant"]
            ) {
              return null;
            } else {
              return (
                <NavItem key={index}>
                  <NavLink
                    className="text-truncate"
                    id={`userEditProfile${item.replace(/\s/g, "")}Tab`}
                    style={{
                      ...styles.tabLink,
                      ...(isActiveTab(item) && styles.tabLinkActive),
                    }}
                    onClick={(): void => toggleTab(item)}>
                    {item}
                  </NavLink>
                </NavItem>
              );
            }
          }
        )}
      </Nav>
    </StickyNavbar>
  );

  const infoError = (
    <div>
      {!!error && <Alert color="danger">{error}</Alert>}
      {!active && <Alert color="warning">{info}</Alert>}
    </div>
  );

  const templatePersonal = template["Personal Info"];
  const templateProfessional = template["Professional Info"];
  const templateAssistant = template["Executive Assistant"];
  const templateSocial = template["Social Media"];

  return (
    <div className="p-2 bg-white">
      {infoError}

      {userProfilePictureElement}

      {navElement}

      <TabContent activeTab={activeTab}>
        <TabPane tabId="Personal">
          {data && (
            <UserProfilePersonalInfo
              data={data.sections["Personal Info"]}
              emailValidate={emailValidate}
              isEmailValid={isEmailValid}
              isPhoneOtherValid={isPhoneOtherValid}
              isPhoneValid={isPhoneValid}
              isUserWebsiteValid={isUserWebsiteValid}
              locationPicker={userProfileLocationPicker}
              onAddChildren={onAddChildren}
              onPersonalInfoChange={handlePersonalInfoChange}
              onRemoveChildren={onRemoveChildren}
              onVisibilityChange={handlePersonalInfoVisibilityChange}
              phoneOtherValidate={phoneOtherValidate}
              phoneValidate={phoneValidate}
              setEmailValid={setEmailValid}
              setPhoneOtherValid={setPhoneOtherValid}
              setPhoneValid={setPhoneValid}
              setUserWebsiteValid={setUserWebsiteValid}
              templatePersonal={templatePersonal}
              user={user}
              visibilities={data.visibilities["Personal Info"]}
              websiteValidate={websiteValidate}
            />
          )}
        </TabPane>

        <TabPane tabId="Professional">
          {data && (
            <UserProfileProfessionalInfo
              data={data.sections["Professional Info"]}
              industryOptions={industryOptions}
              isPhoneProfessionalValid={isPhoneProfessionalValid}
              isWebsiteValid={isWebsiteValid}
              locationPicker={userProfileLocationPicker}
              multipleSelectFields={multipleSelectFields}
              onAddCompanyHistory={onAddCompanyHistory}
              onProfessionalInfoChange={handleProfessionalInfoChange}
              onRemoveCompanyHistory={onRemoveCompanyHistory}
              onVisibilityChange={handleProfessionalInfoVisibilityChange}
              phoneOtherValidate={phoneOtherValidate}
              regionOptions={regionOptions}
              setPhoneProfessionalValid={setPhoneProfessionalValid}
              setWebsiteValid={setWebsiteValid}
              templateProfessional={templateProfessional}
              visibilities={data.visibilities["Professional Info"]}
              websiteValidate={websiteValidate}
            />
          )}
        </TabPane>

        <TabPane tabId="Executive Assistant">
          {data && (
            <UserProfileExecutiveAssistantInfo
              data={data.sections["Executive Assistant"]}
              emailOtherValidate={emailOtherValidate}
              isExecutiveEmailValid={isExecutiveEmailValid}
              isExecutivePhoneValid={isExecutivePhoneValid}
              onExecutiveInfoChange={handleExecutiveAssistantInfoChange}
              onVisibilityChange={handleExecutiveAssistantVisibilityChange}
              phoneOtherValidate={phoneOtherValidate}
              setExecutiveEmailValid={setExecutiveEmailValid}
              setExecutivePhoneValid={setExecutivePhoneValid}
              templateAssistant={templateAssistant}
              visibilities={data.visibilities["Executive Assistant"]}
            />
          )}
        </TabPane>

        <TabPane tabId="Social">
          {data && (
            <UserProfileSocialMediaInfo
              data={data.sections["Social Media"]}
              onSocialMediaChange={handleSocialMediaInfoChange}
              onVisibilityChange={handleSocialMediaVisibilityChange}
              templateSocial={templateSocial}
              visibilities={data.visibilities["Social Media"]}
            />
          )}
        </TabPane>
      </TabContent>

      {infoError}

      <Button
        block
        id="editProfileSaveButton"
        style={styles.button}
        className="mt-4"
        disabled={
          !isPhoneValid ||
          !isEmailValid ||
          !isExecutiveEmailValid ||
          !isWebsiteValid ||
          !isUserWebsiteValid ||
          !isPhoneOtherValid ||
          !isPhoneProfessionalValid ||
          !isExecutivePhoneValid
        }
        onClick={handleUpdateProfile}>
        {sending
          ? t("Update.Profile.Button.Saving")
          : t("Update.Profile.Button.Save")}
      </Button>
    </div>
  );
};
