import React from "react";

import classnames from "classnames";
import { CardHeader } from "reactstrap";

import { useAnchorProps, useTheme } from "../../../hooks";

export type PostCardHeaderProps = {
  className?: string;
  groupId: string;
  id?: string;
  name: string;
};

export const PostCardHeader: React.FC<PostCardHeaderProps> = ({
  className,
  groupId,
  id,
  name,
}) => {
  const {
    text: {
      subHeader: { color },
    },
  } = useTheme();

  const styles = {
    header: {
      color,
    },
  };
  const groupFeedAnchorProps = useAnchorProps(`/group-feed/${groupId}`);

  return (
    <CardHeader id={id} className={classnames(className)} style={styles.header}>
      <a {...groupFeedAnchorProps} className={"anchor-color"}>
        {name}
      </a>
    </CardHeader>
  );
};
