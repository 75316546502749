import React, { ChangeEvent } from "react";
import { Button, Card, Col, Row, CardText, CardTitle } from "reactstrap";
import { InputProps } from "reactstrap/lib/Input";

import { fromNow } from "../../../lib/utils";
import { NotifiacationLandingTweet } from "../../../types/Notification";
import { MentionDisplay } from "../Mention";

type NotificationLandingTwitterProps = {
  item: NotifiacationLandingTweet;
};

export const NotificationLandingTwitterSideBarCard: React.FC<NotificationLandingTwitterProps> = ({
  item,
}) => {
  let link: string | undefined;
  const openTweet = (e: ChangeEvent<InputProps>): any => {
    const { id, url } = item.target;

    switch (e.target.name) {
      case "reply":
        link = `https://twitter.com/intent/tweet?in_reply_to=${id}`;
        break;
      case "retweet":
        link = `https://twitter.com/intent/retweet?tweet_id=${id}`;
        break;
      case "like":
        link = `https://twitter.com/intent/like?tweet_id=${id}`;
        break;
      default:
        link = url;
    }
    if (link) {
      window.open(link, "_blank");
    }
  };

  const styles = {
    button: {},
    icon: {
      color: "#50ABF1",
    },
    buttonLogo: {
      backgroundColor: "#50ABF1",
    },
    logo: {
      color: "#FFFFFF",
    },
  };

  const dates = fromNow(item.date);
  const { name } = item.author || {};

  return (
    <Card className="mb-3 bg-transparent px-2 pb-2 border-bottom">
      <CardTitle className="font-italic mb-1">
        <span>
          <small>
            <MentionDisplay text={item.body}></MentionDisplay>
          </small>
        </span>
      </CardTitle>
      <CardText>
        <small className="text-muted text-small">{`${name}- ${dates}`}</small>
      </CardText>
      <Row noGutters={true}>
        <Col>
          <Button
            title={"Reply"}
            name={"reply"}
            color="light"
            block
            size="sm"
            style={styles.buttonLogo}
            onClick={openTweet}>
            <i className={"icon-social-twitter"} style={styles.logo} />
          </Button>
        </Col>
        <Col>
          <Button
            title={"Reply"}
            name={"reply"}
            color="light"
            block
            size="sm"
            style={styles.button}
            onClick={openTweet}>
            <i className={"icon-action-undo"} style={styles.icon} />
          </Button>
        </Col>
        <Col>
          <Button
            title={"Retweet"}
            name={"retweet"}
            color="light"
            block
            size="sm"
            style={styles.button}
            onClick={openTweet}>
            <i className={"icon-loop"} style={styles.icon} />
          </Button>
        </Col>
        <Col>
          <Button
            title={"Like"}
            name={"like"}
            color="light"
            block
            size="sm"
            style={styles.button}
            onClick={openTweet}>
            <i className={"icon-heart"} style={styles.icon} />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
