import { AnnouncementItem, CommentResponse } from "../../../types";
import { getItemWithCommentsSet } from "../Comment";

export type GetAnnouncementItemWithCommentsSet = {
  ({
    comments,
    item,
  }: {
    comments: CommentResponse[];
    item: AnnouncementItem;
  }): AnnouncementItem;
  ({
    comments,
    item,
  }: {
    comments: CommentResponse[];
    item: undefined;
  }): undefined;
  ({
    comments,
    item,
  }: {
    comments: CommentResponse[];
    item?: AnnouncementItem;
  }): AnnouncementItem | undefined;
};

export const getAnnouncementItemWithCommentsSet = ({
  comments,
  item,
}: {
  comments: CommentResponse[];
  item: any;
}): any => {
  if (!item) return undefined;

  return item.announcement.event
    ? {
        ...item,
        comments: [...item.comments, ...comments],
        announcement: {
          ...item.announcement,
          event: {
            ...item.announcement.event,
            commentCount: item.announcement.event.commentCount + 1,
          },
        },
      }
    : getItemWithCommentsSet({
        comments: comments,
        dataKeyName: "announcement",
        item: item,
      });
};
