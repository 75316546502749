import React from "react";

import Skeleton from "react-loading-skeleton";
import { Media } from "reactstrap";

export type PostFilePlaceholderProps = {};

export const PostFilePlaceholder: React.FC<PostFilePlaceholderProps> = () => (
  <Media className="m-2 py-1 border-bottom">
    <Media left className="mr-2">
      <Skeleton height={18} width={16} />
    </Media>
    <Media body>
      <Skeleton width={300} />
    </Media>
  </Media>
);
