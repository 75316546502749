import React, { FC } from "react";

import { Row } from "reactstrap";

export type ChatChannelNavProps = {
  activeTab: number;
  unreadCount: {
    chat: number;
    rooms: number;
    events: number;
  };

  changeTab: (activeTab: number) => void;
};

export const ChatChannelNav: FC<ChatChannelNavProps> = ({
  activeTab,
  unreadCount,

  changeTab,
}) => {
  const changeTabInner = (tab: number) => (): void => {
    changeTab(tab);
  };

  const navElements = (
    <Row className="text-small m-1">
      <ul
        className="nav nav-pills nav-fill w-100"
        style={{ cursor: "pointer" }}>
        <li className="nav-item">
          <span
            className={" nav-link" + (activeTab === 0 ? " active" : "")}
            onClick={changeTabInner(0)}>
            Chat
            {unreadCount.chat > 0 && (
              <div className="badge badge-pill badge-danger ml-1">
                {unreadCount.chat}
              </div>
            )}
          </span>
        </li>
        <li className="nav-item">
          <span
            className={" nav-link" + (activeTab === 1 ? " active" : "")}
            onClick={changeTabInner(1)}>
            Rooms
            {unreadCount.rooms > 0 && (
              <div className="badge badge-pill badge-danger ml-1">
                {unreadCount.rooms}
              </div>
            )}
          </span>
        </li>
        <li className="nav-item">
          <span
            className={"nav-link" + (activeTab === 2 ? " active" : "")}
            onClick={changeTabInner(2)}>
            Events
            {unreadCount.events > 0 && (
              <div className="badge badge-pill badge-danger ml-1">
                {unreadCount.events}
              </div>
            )}
          </span>
        </li>
      </ul>
    </Row>
  );

  return <>{navElements}</>;
};
