import { AppThunkAction, AppThunkDispatch } from "../../types";
import { searchTags as _searchTags } from "../../services/api/Tag";
import { logException } from "../../lib/utils";
import { TAG_SEARCH_SET } from "./constants";

export const searchTags = (option: {
  search: string;
  type?: string;
}): AppThunkAction => async (dispatch: AppThunkDispatch): Promise<void> => {
  console.debug("[Action] searchTags", option);

  try {
    const data = await _searchTags(option);

    dispatch({
      type: TAG_SEARCH_SET,
      data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
