import _ from "underscore";

/**
 * Parses JSON string an return an object if parsable.
 *
 * Returns object if object is passed as argument.
 * In every other case returns empty object.
 */
export const parseJSONToObject: {
  <T extends {} = {}>(object: string): T;
  <T extends {}>(object: T): T;
  <T extends {}>(object: T | string): T;
} = (object: {} | string): {} => {
  if (_.isObject(object)) {
    return object;
  }

  if (_.isString(object)) {
    if (_.isEmpty(object)) {
      return {};
    }
    try {
      return parseJSONToObject(JSON.parse(object));
    } catch (err) {
      return {};
    }
  }

  return {};
};
