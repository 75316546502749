import { useHistory } from "react-router-dom";
import { SyntheticEvent } from "react";

export type AnchorProps = {
  href: string;
  onClick: (e: SyntheticEvent) => void;
};

export type UseAnchorProps = (href: string) => AnchorProps;

export const useAnchorProps: UseAnchorProps = href => {
  const history = useHistory();

  const onClick = (e: SyntheticEvent): void => {
    e.preventDefault();

    history.push(href);
  };

  return {
    href,
    onClick,
  };
};
