export const checkUrlExists = async (
  url: string | undefined
): Promise<boolean> => {
  if (!url) {
    return false;
  }

  try {
    const resp = await fetch(url, { method: "HEAD" });
    if (resp.ok) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
