import React from "react";

import { RouteComponentProps } from "react-router-dom";

import ChatDetailContainer from "../../../containers/Chat/Detail";

import { ChatConversation } from "../../components/Chat";

export type RouteChatDetailProps = RouteComponentProps<{
  channel: string;
  text: string;
}>;

export const RouteChatDetail: React.FC<RouteChatDetailProps> = props => (
  <ChatDetailContainer {...props} Layout={ChatConversation} />
);
