import { Avatar } from "../../UI";
import React, { FC, useState } from "react";
import { ChatMutatedChannel } from "../../../../containers/Chat/chatTypes";
import { useHistory } from "react-router-dom";
import { isEmpty } from "underscore";
import classnames from "classnames";
import { displayDate, isToday } from "../../../../lib/utils";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

type Props = {
  channel: ChatMutatedChannel;
  isActive: boolean;
  leaveChannel?: () => void;
  className?: string;
  onClick?: (channel: ChatMutatedChannel) => void;
};

export const ChannelCard: FC<Props> = ({
  channel,
  isActive,
  leaveChannel,
  className,
  onClick,
}) => {
  const [showDropdown, setDropdownVisibility] = useState(false);
  const history = useHistory();

  const channelName: string | undefined = channel?.displayName;

  const coverUrl: string | undefined = channel?.coverUrl;

  const onHandleClick = (): void => {
    if (onClick) {
      onClick(channel);
    } else {
      history.push("/chat-room-detail/" + channel.url);
    }
  };
  return (
    <div
      className={classnames(
        "sendbird-channel-preview w-100 " + (isActive ? "channel-active" : ""),
        "border-bottom",
        className
      )}
      onClick={onHandleClick}>
      <div className="sendbird-channel-preview__avatar">
        <Avatar round={true} src={coverUrl} name={channelName} />
      </div>
      <div className="sendbird-channel-preview__content">
        <div className="sendbird-channel-preview__content__upper h-50">
          <div className="sendbird-channel-preview__content__upper__header">
            <div className="sendbird-label sendbird-label--subtitle-2 sendbird-label--color-onbackground-1 text-truncate">
              {channelName}
            </div>
          </div>
          <div className="mr-2 sendbird-channel-preview__content__upper__last-message-at sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-2">
            {displayDate(
              channel.myLastRead,
              isToday(channel.myLastRead) ? "hh:mm A" : "MMMM DD"
            )}
          </div>
        </div>
        <div className="sendbird-channel-preview__content__lower">
          <div className="sendbird-channel-preview__content__lower__last-message sendbird-label sendbird-label--body-2 sendbird-label--color-onbackground-3 text-truncate">
            {!isEmpty(channel.lastMessage)
              ? channel.lastMessage.newMessage ?? channel.lastMessage.message
              : ""}
          </div>
          {channel.unreadMessageCount && channel.unreadMessageCount > 0 ? (
            <div className="sendbird-channel-preview__content__lower__unread-message-count">
              <div className="badge badge-pill badge-primary">
                {channel.unreadMessageCount}
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <ButtonDropdown
        style={{ zIndex: 9999 }}
        className="sendbird-channel-preview__action"
        isOpen={showDropdown}
        toggle={(): void => setDropdownVisibility(prevState => !prevState)}>
        <DropdownToggle
          caret={false}
          className="bg-transparent btn-link border-0 sendbird-iconbutton">
          {" "}
          <span className="sendbird-iconbutton__inner">
            <i className="icon-options-vertical sendbird-icon text-primary" />
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(): void => leaveChannel && leaveChannel()}>
            Leave Channel
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};
