import React from "react";

import classNames from "classnames";

import Variables from "../../../theme/variables/materialWeb";

export type HeadingProps =
  | {
      bold?: boolean;
      center?: boolean;
      children?: undefined;
      className?: string;
      color?: string;
      divider?: boolean;
      id?: string;
      large?: boolean;
      paddingH?: number;
      paddingV?: number;
      size?: number;
      style?: React.CSSProperties;
      tag?: "div" | "p" | "h4";
      text?: string;
      textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
    }
  | {
      bold?: boolean;
      center?: boolean;
      children: React.ReactElement;
      className?: string;
      color?: string;
      divider?: boolean;
      id?: string;
      large?: boolean;
      paddingH?: number;
      paddingV?: number;
      size?: number;
      style?: React.CSSProperties;
      tag?: "div" | "p";
      text?: undefined;
      textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
    };

export const Heading: React.FC<HeadingProps> = ({
  bold,
  center,
  children,
  className,
  color,
  divider,
  id,
  large,
  paddingH,
  paddingV,
  size,
  style,
  tag: Tag = "p",
  text,
  textTransform,
}) => {
  const styles: React.CSSProperties = {
    backgroundColor: divider ? Variables.brandInfo : undefined,
    color: color || "#000",
    fontSize: size || (large && Variables.fontSizeH3) || Variables.fontSizeH5,
    fontWeight: bold ? 600 : "normal",
    lineHeight: size || (large && Variables.fontSizeH1) || Variables.fontSizeH3,
    marginBottom: 0,
    paddingBottom: paddingV || 0,
    paddingLeft: paddingH || 0,
    paddingRight: paddingH || 0,
    paddingTop: paddingV || 0,
    textAlign: center ? "center" : "left",
    textTransform: textTransform || "none",
    ...style,
  } as const;

  return (
    <Tag className={classNames(className)} id={id} style={styles}>
      {text || children}
    </Tag>
  );
};
