import React, { useEffect } from "react";
import { CustomInput, Nav } from "reactstrap";
import { MembersGroupByLayout } from "../../../../containers/Member/GroupBy";
import { MembersList } from "./MembersList";
import { ButtonMembersMap } from ".";
import { usePrevious } from "../../../hooks";
import { translateSectionDictionaryToList } from "../../../utils/dataMapper";

const SELECT_DEFAULT_VALUE = "Select All";

export const MembersGroupedByComponent: React.FC<MembersGroupByLayout> = ({
  segment,
  isMemberMixedLayout,
  loading,
  countOfLoadedNumbers,
  loadMoreMembers,
  filter,
  data,
  filteredData,
  simpleMode = false,
  changeFilterType,
}) => {
  const previousMembersSegment = usePrevious(segment) ?? segment;

  useEffect(() => {
    if (previousMembersSegment === segment) {
      return;
    }
    changeFilterType(null);
  }, [changeFilterType, previousMembersSegment, segment]);

  const filteringOptions = data.map(item => (
    <option value={item.title} key={item.title} label={item.title} />
  ));

  const handleChange = (event: any): void => {
    const { value } = event.target;
    changeFilterType(value === SELECT_DEFAULT_VALUE ? null : value);
  };

  const listData =
    translateSectionDictionaryToList(filteredData) ||
    translateSectionDictionaryToList(data);

  const allMembersLength = listData ? listData.length : 0;

  return (
    <div>
      <div className="search-filter-member-bar">
        <div className="mb-2 filter-select">
          <CustomInput
            id="membersFilterSelect"
            type="select"
            onChange={handleChange}
            defaultValue={SELECT_DEFAULT_VALUE}
            value={filter ?? SELECT_DEFAULT_VALUE}>
            {filteringOptions}
            <option label={SELECT_DEFAULT_VALUE} value={SELECT_DEFAULT_VALUE} />
          </CustomInput>
        </div>

        <div className="filter-wrapper">
          <Nav className="filter-map-nav">
            {!isMemberMixedLayout && <ButtonMembersMap />}
          </Nav>
        </div>
      </div>

      <MembersList
        loading={loading ?? false}
        simpleMode={simpleMode}
        membersData={listData}
        allMembersLength={allMembersLength}
        countOfLoadedNumbers={countOfLoadedNumbers ?? 0}
        loadMoreMembers={loadMoreMembers}
      />
    </div>
  );
};
