import React from "react";

import { useIsUserLoggedIn } from "../../hooks";

import { RouteAppProps, RouteApp } from ".";

export type RouteAppGuestProps = RouteAppProps & {
  /**
   * React element to render when user logged in
   * By design, it should be a <Redirect />
   */
  redirectElement: React.ReactElement;
};

export const RouteAppGuest: React.FC<RouteAppGuestProps> = ({
  redirectElement,
  ...props
}) => {
  const isUserLoggedIn = useIsUserLoggedIn();

  if (isUserLoggedIn) return redirectElement;

  return <RouteApp {...props} />;
};
