import React from "react";

import { RouteComponentProps } from "react-router-dom";

import UserRegisterContainer from "../../../containers/User/Register";

import { SettingDataLoaded } from "../../../types";

import { TemplateSimple } from "../../components/Template";
import { UserRegister } from "../../components/User";

export type RouteUserRegisterProps = RouteComponentProps & {
  setting: SettingDataLoaded;
};

export const RouteUserRegister: React.FC<RouteUserRegisterProps> = ({
  setting,
  ...props
}) => {
  return (
    <TemplateSimple pageTitle="Sign Up" setting={setting}>
      <UserRegisterContainer {...props} Layout={UserRegister} />
    </TemplateSimple>
  );
};
