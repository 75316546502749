import _ from "underscore";

const eventKeys = [
  "streetAddress1",
  "city",
  "state",
  "country",
  "venue",
] as const;

type EventKey = typeof eventKeys[number];

/**
 * @FIXME We should simplify return type if its possible
 */
export const getEventLocation = (
  event?: { [key in EventKey]?: string } | null
):
  | ""
  | {
      address: string;
      venue?: string;
    }
  | false => {
  if (!event) {
    return "";
  }

  const keys: EventKey[] =
    event.country && event.country.trim() === "United States"
      ? ["streetAddress1", "city", "state"]
      : ["streetAddress1", "city", "state", "country"];

  const locations = keys.reduce<string[]>((previousVal, key) => {
    const value = event[key];

    return value && !_.isEmpty(value)
      ? [...previousVal, value.trim()]
      : previousVal;
  }, []);

  const location = !_.isEmpty(locations) && {
    address: locations.join(", "),
    venue: event.venue,
  };
  return location;
};
