import React, {
  ChangeEvent,
  createRef,
  FC,
  useRef,
  useState,
  SyntheticEvent,
  useEffect,
} from "react";

import { BaseEmoji, Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

import {
  Button,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { isEmpty } from "underscore";

import { MentionLink } from "../../../../types/Mention/MentionLink";
import { MediaInputFileProcessed, MediaPhoto } from "../../../../types/Media";
import { SendMessageParams } from "../../../../types/Chat/SendMessageParams";
import { isPhoto } from "../../../../lib/utils";
import { MentionUser } from "../../../../types/Mention/MentionUser";
import MentionInput from "../../../../containers/Mention";
import { ChatDetailsData, ChatInputField } from "../../../../types/Chat";

import { downloadImage } from "../../../services";
import { processInputFiles } from "../../../services/Media";
import { useClickOutsideRef } from "../../../hooks";

import { MediaSearchImage } from "../../Media/Search";
import { MediaImage } from "../../Media/Image";
import { MentionInput as MentionInputComponent } from "../../Mention/MentionInput";

export type ChatConversationChatInputProps = {
  active: ChatDetailsData;
  inputField: ChatInputField;
  typing?: string;

  onChangeText: (text: string) => boolean | void;
  setInputField: (data: ChatInputField) => void;
  sendMessage: (data: SendMessageParams) => Promise<void>;
};

export const ChatConversationChatInput: FC<ChatConversationChatInputProps> = ({
  active,
  inputField,
  typing,

  onChangeText,
  setInputField,
  sendMessage,
}) => {
  const [fileMsg, setFileMsg] = useState("");
  const [files, setFiles] = useState<MediaInputFileProcessed[]>([]);
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [isSearchImageModalOpen, setSearchImageModalOpen] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [linkMentions, setLinkMentions] = useState<MentionLink[]>([]);
  const [mentions, setMentions] = useState<MentionUser[]>([]);
  const [textMsg, setTextMsg] = useState("");

  /*Get input field content*/
  useEffect(() => {
    if (isEmpty(inputField)) {
      setTextMsg("");
      setMentions([]);
      setLinkMentions([]);
    } else {
      setTextMsg(inputField.text as string);
      setMentions(inputField.mentions as MentionUser[]);
      setLinkMentions(inputField.linksMentions as MentionLink[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active.id]);

  const { t } = useTranslation("Post");

  const toggleSearchImageModal = (): void => {
    setSearchImageModalOpen(!isSearchImageModalOpen);
  };

  const toggleUpload = (): void => {
    setUploadOpen(!isUploadOpen);
  };

  const toggleEmojiPicker = (): void => {
    setEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const handleClickOutside = (): void => {
    if (isEmojiPickerOpen) {
      setEmojiPickerOpen(false);
    }
  };

  const emojiClickOutsideRef = useClickOutsideRef(handleClickOutside);

  /** Input refs */
  const imageInputRef = useRef<HTMLInputElement>(null);
  const fileRef = createRef<HTMLInputElement>();

  const handleSelectSearchedImage = async (
    image: MediaPhoto
  ): Promise<void> => {
    toggleSearchImageModal();

    const file = await downloadImage(image);

    await processInputFiles([file], (newFiles: MediaInputFileProcessed[]) =>
      setFiles(prevState => prevState.concat(newFiles))
    );
    toggleUpload();
  };

  const onBlur = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ): void => {
    setInputField({
      text: !isEmpty(textMsg) ? textMsg : undefined,
      mentions: mentions,
      linksMentions: linkMentions,
    });
  };

  const handleImageBrowserOpen = (): void => {
    imageInputRef.current?.click();
  };

  const fileTilesElement =
    files && !isEmpty(files) ? (
      <Container>
        <Row>
          {files.map((file, index) => {
            const isFileImage = isPhoto(file?.file.type);
            const fileUri = file.thumbUrl || file.fileUrl;

            const tileContent = (
              <>
                {isFileImage && fileUri ? (
                  <MediaImage src={fileUri} height={120} resizeMode="cover" />
                ) : (
                  <>
                    <i className="icon-doc" />
                    {file?.file?.name}
                  </>
                )}
              </>
            );

            return (
              <Col sm="6" md="4" lg="3" key={index} className="py-1 px-1">
                <div className="position-relative">{tileContent}</div>
              </Col>
            );
          })}
        </Row>
      </Container>
    ) : null;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null) {
      processInputFiles(
        e.target.files as any,
        (newFiles: MediaInputFileProcessed[]) =>
          setFiles(prevState => prevState.concat(newFiles))
      );
      toggleUpload();
    }
  };

  const onMentionUser = (mentions: MentionUser[]): void => {
    setMentions(mentions);
  };

  const onMentionLink = (linksMentions: MentionLink[]): void => {
    setLinkMentions(linksMentions);
  };

  const addEmoji = (e: BaseEmoji): void => {
    console.debug("Emoji", e);
    setTextMsg(prevState => prevState + e.native);
    toggleEmojiPicker();
  };

  const send = (e?: SyntheticEvent): void => {
    e?.preventDefault();

    sendMessage({
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      text: !isEmpty(textMsg) ? textMsg : undefined,
      mentions: mentions,
      linksMentions: linkMentions as any,
      files: files as any,
      fileText: !isEmpty(fileMsg) ? fileMsg : undefined,
    }).then(() => {
      setTextMsg("");
      setFileMsg("");
      setMentions([]);
      setLinkMentions([]);
      setInputField({
        text: "",
        mentions: [],
        linksMentions: [],
      });
    });
  };

  const allowSendingOnEnter = (e: React.KeyboardEvent): boolean =>
    ((textMsg && textMsg.trim() !== "") || files.length !== 0) &&
    e.which === 13 &&
    !e.shiftKey;

  const handleEnterPress = (e: React.KeyboardEvent): void => {
    if (allowSendingOnEnter(e)) {
      send(e);
    }
  };

  const handleEnterPressFileMessage = (e: React.KeyboardEvent): void => {
    if (allowSendingOnEnter(e)) {
      send(e);
      setFiles([]);
      setUploadOpen(false);
    }
  };

  const handleSubmit = (): void => {
    send();
    setFiles([]);
    setUploadOpen(false);
  };

  const mentionInput = (isFileMessage: boolean): JSX.Element => (
    <Row>
      <Col style={{ maxWidth: 540 }} className="pr-0">
        <MentionInput
          onBlur={onBlur}
          Layout={MentionInputComponent}
          includeHereAndChannel={true}
          linksMentioned={linkMentions}
          maxHeight={50}
          mentionLink={onMentionLink}
          mentionUser={onMentionUser}
          minHeight={45}
          onChangeText={(text: string): void => {
            if (isFileMessage) {
              setFileMsg(text);
            } else {
              onChangeText(text);
              setTextMsg(text);
            }
          }}
          onKeyDown={(e: React.KeyboardEvent): void => {
            if (isFileMessage) {
              handleEnterPressFileMessage(e);
            } else {
              handleEnterPress(e);
            }
          }}
          placeholder="Enter message"
          singleLine={false}
          usersMentioned={mentions}
          value={isFileMessage ? fileMsg : textMsg}
        />
      </Col>
      <Button
        id="btn-send-chat"
        style={{ minWidth: 60 }}
        color="primary mr-3 rounded-0"
        disabled={
          isFileMessage
            ? files.length === 0
            : !textMsg || textMsg?.trim() === ""
        }
        onClick={handleSubmit}>
        <i
          className="icon-paper-plane p4-3 align-middle"
          style={{ fontSize: "1.1rem" }}
        />
      </Button>
    </Row>
  );

  return (
    <div id="chatConversationChatInput" className="px-3 py-2">
      <div className="sendbird-message-input" ref={emojiClickOutsideRef}>
        {mentionInput(false)}
        <ButtonGroup className="float-left mr-2 mt-n2">
          <Button
            color="link"
            size="sm"
            className="text-decoration-none"
            onClick={toggleEmojiPicker}>
            <i className="icon-emotsmile text-primary font-weight-bolder p4-3" />
          </Button>
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              className="text-decoration-none text-primary"
              size="sm">
              <i className="icon-picture" />
            </DropdownToggle>
            <DropdownMenu>
              {/** @TODO Implement image file picker (onOpenPhotoPicker) */}
              <DropdownItem onClick={handleImageBrowserOpen}>
                {t("NewEdit.PhotoOptions.Photo")}
              </DropdownItem>
              <DropdownItem onClick={toggleSearchImageModal}>
                {t("NewEdit.PhotoOptions.Search")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Button
            color="link"
            size="sm"
            className="text-decoration-none"
            onClick={(): void | null =>
              fileRef.current && fileRef.current.click()
            }>
            <i className="icon-paper-clip text-primary font-weight-bolder p4-3" />
          </Button>
        </ButtonGroup>
        {typing && <div className="text-small text-muted">{typing}</div>}

        <Modal isOpen={isUploadOpen} toggle={toggleUpload} centered>
          <ModalHeader
            toggle={(): void => {
              toggleUpload();
              setFiles([]);
            }}>
            {t("Chat:Mixed.Heading.File")}
          </ModalHeader>
          <ModalBody>
            {files && fileTilesElement}
            <br />
            <div className="border">{mentionInput(true)}</div>
          </ModalBody>
        </Modal>
        {isEmojiPickerOpen && (
          <span>
            <Picker
              set={"google"}
              onSelect={addEmoji}
              style={{ position: "absolute", bottom: "80px", right: "40px" }}
              color="#2954a3"
            />
          </span>
        )}
        <input
          ref={fileRef}
          className="sendbird-message-input--attach-input"
          type="file"
          onChange={handleFileChange}
        />
        <input
          ref={imageInputRef}
          className="d-none"
          type="file"
          /** @TODO What should be acceptable file formats for images? */
          accept="image/png, image/jpeg, image/gif"
          /** @FIXME Should we support multiple file selecting? */
          onChange={handleFileChange}
        />
      </div>
      <Modal
        isOpen={isSearchImageModalOpen}
        toggle={toggleSearchImageModal}
        centered>
        <ModalHeader toggle={toggleSearchImageModal}>
          {t("Media:SearchImage.Button.Search.Photo")}
        </ModalHeader>
        <ModalBody>
          <MediaSearchImage
            options={["giphy", "stock", "web"]}
            onSelect={handleSelectSearchedImage}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
