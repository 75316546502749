import setting from "../containers/Setting/reducer";
import workSpace from "../containers/WorkSpace/reducer";
import user from "../containers/User/reducer";
import member from "../containers/Member/reducer";
import post from "../containers/Post/reducer";
import group from "../containers/Group/reducer";
import announcement from "../containers/Announcement/reducer";
import event from "../containers/Event/reducer";
import comment from "../containers/Comment/reducer";
import payment from "../containers/Payment/reducer";
import notification from "../containers/Notification/reducer";
import resource from "../containers/Resource/reducer";
import video from "../containers/VideoLibrary/reducer";
import chat from "../containers/Chat/reducer";
import media from "../containers/Media/reducer";
import maps from "../containers/Map/reducer";
import tag from "../containers/Tag/reducer";
import subscription from "../containers/Subscription/reducer";
import { AnyAction } from "redux";

const rehydrated = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case "persist/REHYDRATE":
      return true;
    default:
      return state;
  }
};

const RootReducer = {
  /** New store shape */
  member,
  announcement,
  chat,
  comment,
  event,
  group,
  maps,
  media,
  notification,
  payment,
  post,
  rehydrated,
  resource,
  setting,
  user,
  video,
  workSpace,
  tag,
  subscription,
};
export default RootReducer;
