export function cancelUploading() {
  return (): Promise<void> =>
    new Promise<void>(() => {
      console.debug("cancel uploading");
      //@FIXME any idea how to type this window object?
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.canceledUploading = true;
    }).catch(err => {
      throw err && err.message;
    });
}
