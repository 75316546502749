import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardHeader, Collapse, Nav, NavItem } from "reactstrap";
import { CollapsingMembersFilter } from ".";
import { SearchBox } from "../../UI";
import { MembersList } from "./MembersList";
import { CategoryFilter } from "../../../../containers/Member/types";
import { ButtonMembersMap } from "./ButtonMembersMap";
import { useTheme } from "../../../hooks";
import { Member } from "../../../../types/Member";
import { getChunkedListItemsToRender } from "../../../utils/dataMapper";

interface Props {
  searchBoxText?: string;
  noMembersFoundText?: string;

  disableFilters: boolean;
  categoryFilters?: CategoryFilter[];
  data: Array<string | Member>;
  allMembersLength: number;
  scrollableRef?: React.RefObject<HTMLDivElement>;
  simpleMode?: boolean;
  query: string;
  isMemberMixedLayout?: boolean;
  countOfLoadedNumbers: number;
  loadMoreMembers: (maxVisibleMembersNumber: number) => () => void;
  changeQuery: (query?: string) => void | false;
  changeFilterType?: (type: string, value: string, filter: boolean) => void;
  clearFilters?: () => void;
}

export const MemberListWithTextInput: React.FC<Props> = ({
  searchBoxText = "Search The Directory",
  noMembersFoundText,
  disableFilters,
  categoryFilters,
  data,
  allMembersLength,
  scrollableRef,
  simpleMode,
  query,
  isMemberMixedLayout,
  countOfLoadedNumbers,
  loadMoreMembers,
  changeQuery,
  changeFilterType,
  clearFilters,
}) => {
  const { t } = useTranslation("Member");
  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState<string[]>([]);

  const listData = getChunkedListItemsToRender(data, countOfLoadedNumbers);

  const toggleFilter = (): void => {
    if (isOpen) {
      setCategorySelected([]);
      clearFilters && clearFilters();
    }
    setIsOpen(!isOpen);
  };

  const searchBoxPlaceholderText = isMemberMixedLayout
    ? t("Index.Placeholder.Search.By.Location")
    : searchBoxText;

  const handleSearchBoxChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    changeQuery(value);
  };

  return (
    <div>
      <div className={"mb-2 search-filter-member-bar"}>
        <SearchBox
          value={query}
          className="searchbox"
          placeholder={searchBoxPlaceholderText}
          onValueChange={handleSearchBoxChange}
        />

        {!disableFilters && (
          <div className="filter-wrapper">
            <Nav className="filter-map-nav">
              <NavItem key="filters">
                <Button
                  id="membersFiltersBtn"
                  onClick={toggleFilter}
                  className="border-0"
                  style={{
                    width: isMemberMixedLayout ? 150 : 70,
                    backgroundColor: buttonBackgroundColor,
                  }}>
                  <span style={{ color: buttonColor }}>
                    {t("MembersListFilters.Button.Filter")}
                  </span>
                </Button>
              </NavItem>

              {!isMemberMixedLayout && <ButtonMembersMap />}
            </Nav>
          </div>
        )}
      </div>

      {!disableFilters && (
        <Collapse isOpen={isOpen}>
          <Card className="mb-1 filter-card">
            <CardHeader>
              <Button
                id="membersFiltersClearBtn"
                color="danger"
                onClick={toggleFilter}>
                {t("MembersListFilters.Button.Clear.Filters")}
              </Button>
            </CardHeader>

            <CollapsingMembersFilter
              filter={changeFilterType}
              categoryFilters={categoryFilters}
              categorySelected={categorySelected}
              setCategorySelected={setCategorySelected}
            />
          </Card>
        </Collapse>
      )}

      <MembersList
        noMembersFoundText={noMembersFoundText}
        scrollableRef={scrollableRef}
        simpleMode={simpleMode}
        membersData={listData}
        allMembersLength={allMembersLength}
        countOfLoadedNumbers={countOfLoadedNumbers}
        loadMoreMembers={loadMoreMembers}
      />
    </div>
  );
};
