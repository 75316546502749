import React from "react";

import { RouteComponentProps } from "react-router-dom";

import PostImagesContainer from "../../../containers/Post/Images";

import { PostImages } from "../../components/Post";

export type RouteGroupPhotosProps = {} & RouteComponentProps;

export const RouteGroupPhotos: React.FC<RouteGroupPhotosProps> = (
  props
): JSX.Element => (
  /**
   * @FIXME
   * Why this component is named PostImagesContainer? It is used only
   * in groupPhotos case. Not sure about PostImages (probably used in
   * chat as well).
   */
  <PostImagesContainer {...props} Layout={PostImages} />
);
