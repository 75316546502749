import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Alert, Button } from "reactstrap";

import MembersContainer from "../../../containers/Member";

import { useTheme, useTypedSelector } from "../../hooks";

import { GroupMyGroups } from "../Group";
import { MembersListWithFilterTabs } from "../Member/List";

type Props = {};

export const TemplateLeftSidebar: React.FC<Props> = () => {
  const {
    sideBar: {
      left: { backgroundColor, subBackgroundColor, color, button },
    },
  } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const styles = {
    button: {
      backgroundColor: button?.backgroundColor || subBackgroundColor,
      color: button?.color || color,
    },
  };

  const { t } = useTranslation("Member");

  const viewingAsGuest = useTypedSelector(state => state.user.viewingAsGuest);

  const memberBar = (
    <div
      className="mt-2 ml-2 p-2 rounded"
      style={{ height: "45%", backgroundColor }}>
      <Link to="/members">
        <Button
          color="link"
          size="md"
          block
          className="border mb-1"
          style={styles.button}>
          Member Directory
        </Button>
      </Link>
      {viewingAsGuest ? (
        <div
          className="border rounded"
          style={{ backgroundColor: subBackgroundColor, height: "85%" }}>
          <Alert color="danger">{t("Index.Alert.Guest.Mode")}</Alert>
        </div>
      ) : (
        <div
          ref={ref}
          className="border rounded"
          id="left-side-bar-members"
          style={{ backgroundColor: subBackgroundColor, height: "85%" }}>
          <MembersContainer
            Layout={MembersListWithFilterTabs}
            disableFilters={true}
            simpleMode={true}
            scrollableRef={ref}
          />
        </div>
      )}
    </div>
  );

  const groupBar = (
    <div
      className="ml-2 p-2 mt-3 rounded"
      style={{ height: "50%", backgroundColor }}>
      <Link to="/group-list">
        <Button
          color="link"
          size="md"
          block
          className="border mb-1"
          style={styles.button}>
          My Groups
        </Button>
      </Link>
      {viewingAsGuest ? (
        <div
          className="border rounded"
          style={{ backgroundColor: subBackgroundColor, height: "85%" }}>
          <Alert color="danger">{t("Index.Alert.Guest.Mode")}</Alert>
        </div>
      ) : (
        <div
          className="border rounded"
          id="left-side-bar-groups"
          style={{ backgroundColor: subBackgroundColor, height: "85%" }}>
          <GroupMyGroups />
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="h-100">
        {memberBar}
        {groupBar}
      </div>
    </>
  );
};
