import React from "react";

import { Redirect, RouteProps } from "react-router-dom";

import { HistoryLocation, SettingDataLoaded } from "../../../types";

import { useAppDefaultPath } from "../../hooks";
import { LoadingSpinner } from "../../components";

export type RedirectAppDefaultProps = RouteProps & {
  redirectLocation?: HistoryLocation | null;
  setting?: SettingDataLoaded;

  clearRedirectLocation?: () => void;
};

export const RedirectAppDefault: React.FC<RedirectAppDefaultProps> = ({
  location,
  redirectLocation,
  setting,

  clearRedirectLocation,
}) => {
  const defaultAppPath = useAppDefaultPath();

  if (!setting) {
    return <LoadingSpinner size="lg" style={{ marginTop: "25%" }} />;
  }

  /** If hash part available on the url, strip # and redirect to what's after #
  It is for compability with old links using hash router */
  if (location?.hash) return <Redirect to={location?.hash.slice(1)} />;

  /** Redirect to redirectLocation and clear it (set to null) if exists */
  if (redirectLocation && clearRedirectLocation) {
    clearRedirectLocation();

    return <Redirect to={redirectLocation.pathname} />;
  }

  return <Redirect to={defaultAppPath} />;
};
