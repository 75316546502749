import { runFunction } from "../../http";

export const viewAsUser = async (userId: string): Promise<string> => {
  return await runFunction(
    "viewAsUser",
    { userId },
    {
      requireUser: true,
      spinner: false,
    }
  );
};
