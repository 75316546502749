import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { MentionSearchLinkInfo } from "../../../types";

import { runQuery } from "../../http";

export const searchGroup = async (
  text: RegExp
): Promise<MentionSearchLinkInfo[] | undefined> => {
  const parseResponse = await runQuery("Group", (query: Parse.Query) => {
    query.matches("name", text, "i");
    query.limit(10);
    return query.find();
  });

  if (!_.isEmpty(parseResponse)) {
    return convertObject(parseResponse).map(
      (item: { id: string; name: string; thumbUrl: string }) => {
        return {
          id: item.id,
          name: item.name,
          imageUrl: item.thumbUrl,
          url: "/group-feed/" + item.id,
        };
      }
    );
  } else {
    return undefined;
  }
};
