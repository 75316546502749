import { VIDEO_ALL_SET, VIDEO_RESET, VIDEO_SET } from "./constants";
import Store from "./store";
import { VideoLibraryAction, VideoLibraryState } from "./types";

export const initialState = Store;

export default function reducer(
  state: VideoLibraryState = initialState,
  action: VideoLibraryAction
): VideoLibraryState {
  switch (action.type) {
    case VIDEO_RESET:
      return initialState;

    case VIDEO_ALL_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        list: {
          ...state.list,
          ...action.data,
        },
      };

    case VIDEO_SET:
      if (action.data) {
        const { id } = action.data;

        return {
          ...state,
          list: {
            ...state.list,
            //items can be null due to display a placeholder loading on the feed
            //so only set data into items if items had value already
            items: state.list.items
              ? {
                  ...state.list.items,
                  [id]: action.data,
                }
              : null,
            //only set data to cached (not list) to display detail properly
            cached: {
              ...state.list.cached,
              [id]: action.data,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
