import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Group } from "../../../../types";

import { InfiniteScroll } from "../../UI";
import { GroupCard, GroupCardPlaceholder } from "..";

export type GroupListByStatusProps = {
  className?: string;
  data: Group[] | null;
  id?: string;
  testId?: string;
  simpleMode?: boolean;
  isRefreshing?: boolean;
  query?: string;
  userId?: string;
};

export const GroupListByStatus: React.FC<GroupListByStatusProps> = ({
  className,
  id,
  testId,
  data,
  query,
  isRefreshing,
  simpleMode,
}) => {
  const { t } = useTranslation("Group");

  const history = useHistory();

  return (
    <InfiniteScroll
      id={id}
      className={className}
      emptyText={t("No groups found")}
      isLoading={false}
      isRefreshing={!!isRefreshing}
      placeholder={<GroupCardPlaceholder />}>
      {data?.map(item => (
        <GroupCard
          id={
            testId
              ? `${testId}${item.name.replace(/\s/g, "")}`
              : `groupCard${item.name.replace(/\s/g, "")}`
          }
          key={item.id}
          className={simpleMode ? "simple-mode" : ""}
          item={item}
          href={`/group-feed/${item.id}`}
          onClick={(): void => {
            history.push(`/group-feed/${item.id}`);
          }}
          pending={item.pending}
          query={query}
        />
      )) || null}
    </InfiniteScroll>
  );
};
