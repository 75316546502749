import React, { ChangeEvent } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { InputProps } from "reactstrap/lib/Input";

import { displayDate } from "../../../lib/utils";
import { NotifiacationLandingTweet } from "../../../types/Notification";

import { Avatar } from "../UI";

export type NotificationLandingTwitterProps = {
  item: NotifiacationLandingTweet;
};

export const NotificationLandingTwitter: React.FC<NotificationLandingTwitterProps> = ({
  item,
}) => {
  const { t } = useTranslation("Notification");

  let link: string | undefined;
  const openTweet = (e: ChangeEvent<InputProps>): any => {
    const { id, url } = item.target;

    switch (e.target.name) {
      case "reply":
        link = `https://twitter.com/intent/tweet?in_reply_to=${id}`;
        break;
      case "retweet":
        link = `https://twitter.com/intent/retweet?tweet_id=${id}`;
        break;
      case "like":
        link = `https://twitter.com/intent/like?tweet_id=${id}`;
        break;
      default:
        link = url;
    }
    if (link) {
      window.open(link, "_blank");
    }
  };

  const dates = {
    time: displayDate(item.date, "h:mm A"),
    day: displayDate(item.date, "DD"),
    month: displayDate(item.date, "MMM"),
    year: displayDate(item.date, "YYYY"),
  };
  const { thumbUrl, name, alias } = item.author || {};

  return (
    <Card className="mb-3 pt-2">
      <CardBody className={"p-0"}>
        <Row key={name} className="px-4 py-2">
          <Avatar size="35" name={name} src={thumbUrl} />
          <Col className="my-0">
            <p className="m-0">
              {name}
              <p className="text-right small mt-n4 m-0">{`${dates.time} - ${dates.day} ${dates.month} ${dates.year}`}</p>
            </p>
            <p className="text-small anchor-color m-0">{`@${alias}`}</p>
          </Col>
        </Row>
        <p className={"p-2 m-0"}>{item.body}</p>
        <Row className="px-4 pb-2">
          <span
            className={"anchor-color"}
            style={{ cursor: "pointer" }}
            onClick={openTweet}>
            {t("NotificationLanding.Button.Open.Twitter")}
          </span>
        </Row>
        <Row noGutters={true}>
          <Col>
            <Button
              title={"Reply"}
              name={"reply"}
              color="light"
              block
              onClick={openTweet}>
              <i className={"icon-action-undo"} />
            </Button>
          </Col>
          <Col>
            <Button
              title={"Retweet"}
              name={"retweet"}
              color="light"
              block
              onClick={openTweet}>
              <i className={"icon-loop"} />
            </Button>
          </Col>
          <Col>
            <Button
              title={"Like"}
              name={"like"}
              color="light"
              block
              onClick={openTweet}>
              <i className={"icon-heart"} />
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
