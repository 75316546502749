import { getMember } from "..";
import { Member } from "../../../types";
import { object } from "underscore";

export const getCurrentUser = async (): Promise<Member | void> => {
  const user: Parse.User | null = await Parse.User.currentAsync();
  console.debug("getCurrentUser", user);

  if (!user) {
    return;
  }

  const parsedUser = await getMember(user.id);

  // get followed users
  const followsQuery = user.relation("followedUsers").query();
  followsQuery.limit(1000);
  const followedUsersResp = await followsQuery.find();
  const followedUsers = object(
    followedUsersResp.map(value => [value.id, true])
  );

  return { ...parsedUser, followedUsers } as Member;
};
