import React, { useRef, useState, useEffect } from "react";

import CommentListContainer from "../../../containers/Comment";

import {
  CommentRequest,
  CommentResponse,
  CommentType,
  Dict,
  EmptyObject,
  ReactionType,
  UserDataState,
} from "../../../types";

import { CommentActionBar, CommentList, CommentReactions } from ".";

export type CommentBarProps<CT extends CommentType> = {
  /** This prop is web specific */
  className?: string;

  attachmentCount?: number;
  commentCount: number;
  comments?: CommentResponse[];
  /** Disables any reaction/comment/link */
  disabled?: boolean;
  groupId?: string;
  id: string;
  isLiked?: boolean;
  likeCount: number;
  itemAuthorId?: string;
  reaction?: ReactionType;
  reactionCount?: Partial<Dict<number, ReactionType>>;
  seenCount?: number;
  setting?: {
    ClientHostName?: string;
    confettiEffectActions?: [];
  };
  type: CT;
  user?: UserDataState | EmptyObject;

  addComment?: (
    id: string,
    type: CT,
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void,
    authorId?: CT extends "post" ? string : undefined
  ) => Promise<void>;
  attachmentPress?: () => void;
  doReaction?: (
    id: string,
    type: CT,
    reaction: ReactionType,
    authorId?: CT extends "post" ? string : undefined
  ) => Promise<void>;
};

export const CommentBar = <CT extends CommentType>({
  className,
  commentCount,
  disabled,
  groupId,
  id,
  isLiked,
  likeCount,
  itemAuthorId,
  reaction,
  reactionCount,
  seenCount,
  type,

  doReaction,
}: CommentBarProps<CT>): JSX.Element => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (isInputFocused) {
      handleNewCommentInputFocus();
      setIsInputFocused(false);
    }
  }, [isInputFocused]);

  const newCommentInputRef = useRef<HTMLInputElement>(null);
  const [isCommentsListVisible, setIsCommentListVisible] = useState(true);

  const toggleIsCommentListVisible = (): void => {
    setIsCommentListVisible(!isCommentsListVisible);
  };

  const handleNewCommentInputFocus = (): void => {
    newCommentInputRef.current?.focus();
  };

  const onCommentsButtonClick = (): void => {
    if (!isCommentsListVisible) {
      setIsCommentListVisible(true);
    }
    setIsInputFocused(true);
  };

  const commentSection = doReaction && (
    <>
      <CommentActionBar
        id={id}
        disabled={disabled}
        react={(reaction): Promise<void> | undefined =>
          doReaction(id, type, reaction)
        }
        isLiked={isLiked ?? false}
        reaction={reaction}
        onCommentsButtonClick={onCommentsButtonClick}
      />
      {isCommentsListVisible && (
        <CommentListContainer
          Layout={CommentList}
          disableFetchOnMount={true}
          disabled={disabled}
          match={{ params: { type, id } }}
          newCommentInputRef={newCommentInputRef}
          postAuthorId={itemAuthorId}
          groupId={groupId}
        />
      )}
    </>
  );

  return (
    <div id={`commentContainer${id}`} className={className}>
      <CommentReactions
        className="mb-1"
        id={id}
        type={type}
        commentCount={commentCount}
        disabled={disabled}
        reactionCount={reactionCount ?? {}}
        seenCount={seenCount ?? 0}
        likeCount={likeCount}
        toggleIsCommentListVisible={toggleIsCommentListVisible}
      />
      {commentSection}
    </div>
  );
};
