import { VideoLibraryState } from "./types";

export const videoLibraryStateInit: VideoLibraryState = {
  error: null,
  list: {
    items: null,
    cached: {},
    page: 0,
    hasMore: true,
  },
};

export default videoLibraryStateInit;
