import React, { Component, ComponentType } from "react";

import { connect } from "react-redux";

import { track } from "../../lib/track";

import {
  MatchProps,
  PostFile,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";
import { Image } from "../../types/Post/Image";

import { getGroupImages } from "./action";
import { getSettingState } from "../Setting/selector";
import { getGroupImagesState, getGroupImagesHasMoreState } from "./selector";
import { getCurrentUserState } from "../User/selector";

export type PostImagesStateProps = {
  user: UserDataState | {};
  setting: SettingState | {};
  data: Image[] | null | undefined;
  hasMore: boolean;
};

export type PostImagesDispatchProps = {
  getGroupImages: (
    groupId: string,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
};

export type PostImagesLayoutProps = {
  data: PostFile[] | null | undefined;
  error: string | null;
  hasMore: boolean;
  loading: boolean;
  refreshing: boolean;
  reFetch: (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ) => Promise<void>;
};

export type PostImagesOwnProps = {
  Layout: ComponentType<PostImagesLayoutProps>;
};

export type PostImagesProps = PostImagesStateProps &
  PostImagesDispatchProps &
  PostImagesOwnProps &
  MatchProps;

export type PostImagesState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class PostImages extends Component<PostImagesProps, PostImagesState> {
  state: PostImagesState = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false, true);
    track("View Screen", {
      Screen: "group-photos",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = async (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getGroupImages, match } = this.props;
    const { refreshing, loading } = this.state;
    const groupId = match.params?.id;

    if (refreshing || loading || !groupId) {
      return;
    }

    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        await getGroupImages(groupId, next, refresh);

        this.setState({
          loading: false,
          refreshing: false,
          error: null,
        });

        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, data, hasMore } = this.props;
    const { loading, error, refreshing } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        data={data}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): PostImagesStateProps => {
  const groupId = ownProps.match.params?.id as string;

  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getGroupImagesState(state, groupId),
    hasMore: getGroupImagesHasMoreState(state, groupId),
  };
};

const mapDispatchToProps = {
  getGroupImages: getGroupImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostImages);
