import React from "react";

import { RouteComponentProps, Redirect } from "react-router-dom";
import queryString from "query-string";

import { RedirectAppDefault } from "..";

export type RouteOldProfileProps = RouteComponentProps;

export const RouteOldProfile: React.FC<RouteOldProfileProps> = ({
  location,
}) => {
  const urlParams = queryString.parse(location.search);

  if (urlParams.id && typeof urlParams.id === "string") {
    return <Redirect to={`/profile/${urlParams.id}`} />;
  }

  if (urlParams.userId && typeof urlParams.userId === "string") {
    return <Redirect to={`/member/${urlParams.userId}`} />;
  }

  return <RedirectAppDefault />;
};
