import React from "react";

import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useTheme } from "../../hooks";

export const Footer: React.FC = () => {
  const { t } = useTranslation("UI");
  const {
    general: { color },
  } = useTheme();
  return (
    <footer className="mt-5">
      <Row>
        <Col sm="12" className="text-right pt-3">
          <p style={{ color }}>{t("Foorer.Text")}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
