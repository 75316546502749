import React from "react";

import { IconDict } from "../../../../types";

import { Dot } from "..";
import { Icon } from ".";

/**
 * Temporarily only notifications type supported
 */
export const IconBadge = <T extends keyof IconDict>({
  name,
  title,
  type,
  unread,
}: {
  active?: boolean;
  /** @TODO Investigate this type */
  badgeStyle: {};
  name: IconDict[T];
  /** @TODO Investigate this type */
  style: {};
  title?: string;
  type: T;
  unread: number;
}): React.ReactElement => (
  <div style={{ position: "relative" }}>
    <div className="d-flex">
      <Icon name={name} type={type} style={{}} title={title} />
      {unread > 0 && (
        <>
          <Dot style={{ position: "absolute", left: "10px" }} />
          {title && title}
          <span className="ml-0 d-none d-md-inline-block">{unread}</span>
        </>
      )}
    </div>
  </div>
);
