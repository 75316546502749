import React from "react";

import classnames from "classnames";
import { Row, Col } from "reactstrap";

import { MediaImage } from ".";

type MediaImageTilesProps = {
  className?: string;
  data: {
    thumbUrl?: string;
    fileUrl: string;
  }[];
  height?: number;

  onImageClick?: (image: { thumbUrl?: string; fileUrl: string }) => void;
};

export const MediaImageTiles: React.FC<MediaImageTilesProps> = ({
  className,
  data,
  height = 170,

  onImageClick,
}) => {
  const tileElements = data.map((image, index) => {
    const handleImageClick = (): void => {
      onImageClick && onImageClick(image);
    };

    const imageSource = image.thumbUrl || image.fileUrl;

    return (
      <Col sm="6" md="4" lg="3" key={index} className="py-1 px-1">
        <MediaImage
          id={`mediaImage${index}`}
          resizeMode="cover"
          height={height}
          src={imageSource}
          onClick={handleImageClick}
        />
      </Col>
    );
  });

  return (
    <Row className={classnames("mx-n1", className)} noGutters={true}>
      {tileElements}
    </Row>
  );
};
