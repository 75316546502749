import React from "react";

import { Card, CardBody } from "reactstrap";
import Skeleton from "react-loading-skeleton";

export type PlaceholderProps = {};

export const Placeholder: React.FC<PlaceholderProps> = () => (
  <Card>
    <CardBody>
      <h5>
        <Skeleton />
      </h5>
      <Skeleton count={5} />
    </CardBody>
  </Card>
);
