import _ from "underscore";

import { convertObject } from "../../../lib/utils";

import { AnnouncementItem } from "../../../types";

import { runFunction } from "../../http";

/**
 * @TODO Arguments need better typing
 */
export const getAnnouncement = async (
  id: string,
  options: {}
): Promise<AnnouncementItem | undefined> => {
  console.debug("[ServiceApi] getAnnouncement");

  const response = await runFunction(
    "getAnnouncement",
    {
      ...options,
      id: id,
    },
    { requireUser: true }
  );

  /**
   * @FIXME Converting logic temporarily moved here.
   * Reconsider move to another layer.
   */
  return !_.isEmpty(response)
    ? {
        ...convertObject(response),
        id: id,
      }
    : undefined;
};
