import React from "react";

import { Card, CardBody, Col, Row } from "reactstrap";

import { displayDate, currencyFormat } from "../../../../lib/utils";
import { ReceiptData } from "../../../../types";
import { useHistory } from "react-router-dom";

type PaymentReceiptCardProps = {
  id: string;
  item: ReceiptData;
};

export const PaymentReceiptCard: React.FC<PaymentReceiptCardProps> = ({
  id,
  item,
}) => {
  const { total, dueDate, description } = item;

  const history = useHistory();

  const handleOnClickRecept = (): void => {
    history.push(`/payment-receipt-detail/${item.id}`);
  };

  return (
    <Card id={id} className="my-2 pt-2">
      <CardBody onClick={handleOnClickRecept}>
        <h6 id={`${id}Header`}>{description}</h6>
        <Row>
          <Col id={`${id}Date`}>{displayDate(dueDate, "ll")}</Col>
          <Col
            id={`${id}Total`}
            className="text-right text-primary font-weight-bold">{`$${currencyFormat(
            total
          )}`}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};
