import React, { useState, useRef } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { isEmpty } from "underscore";

import GroupPostingGroupsContainer from "../../../../containers/Group/PostingGroups";
import MediaContainer from "../../../../containers/Media";
import MentionInputContainer from "../../../../containers/Mention";
import UploadingContainer from "../../../../containers/Uploading";
import { isPhoto, getFileName, logException } from "../../../../lib/utils";

import {
  Callback,
  Dict,
  Group,
  MediaInputFileProcessed,
  MediaPhoto,
  NotificationPostingStatus,
  PostData,
  PostGroup,
  PostNewEditMediaTabName,
  PostUploadFile,
  Tag,
  UserDataState,
  MediaFileType,
  MentionUser,
  MentionLink,
} from "../../../../types";
import { VideoUpload } from "../../../../types/VideoLibrary/VideoUpload";

import {
  checkFileSize,
  downloadImage,
  processInputFiles,
} from "../../../services";

import { GroupPostingGroups } from "../../Group";
import { Media, MediaImage, MediaSearchImage } from "../../Media";
import { MentionInput } from "../../Mention";
import { TagInput } from "../../Tag";
import { LoadingSpinner, ErrorModal, TooltipItem } from "../../UI";
import { Uploading } from "../../Uploading";
import { useTheme } from "../../../hooks";

export type PostNewEditProps = {
  toggle?: () => void;
  error: string | null;
  sending: boolean;
  refreshing: boolean;
  fromMixedFeed: boolean;
  allowPosting: boolean;
  data: PostData;
  user: UserDataState | {};
  setting: {
    confettiEffectActions?: [];
    maxSizeForMobileVideoUploading: number;
    maxSizeForVideoUploading: number;
    maxSizeForUploading: number;
    postVideoEnabled: boolean;
  };
  postingStatus?: NotificationPostingStatus;
  tagsSuggestion: Tag[] | null;
  groupsSuggestion: Dict<Group>;

  onChangePost: <K extends keyof Partial<PostData>>(key: K, value: any) => void;
  onChangeVideo: (video: VideoUpload | null) => void;
  onChangeVideoLink: (url: string, callback?: Callback) => Promise<void>;
  onMentionLink: (newLinksMentions: any) => void;
  onMentionUser: (newMentions: any) => void;
  onPickedFiles: (
    files: (PostUploadFile | { file: File })[],
    callback?: Callback
  ) => void;
  onPost: (callback?: (text: string) => void) => void;
  onRemoveFile: (file: any, index: number) => void;
  onSearchTags: (name: string) => void;
};

/**
 * @PROPOSAL
 * Maybe PostForm would be a better name?
 *
 * @TODO Split to smaller components
 *
 */
export const PostNewEdit: React.FC<PostNewEditProps> = ({
  toggle,
  allowPosting,
  data,
  error,
  fromMixedFeed,
  groupsSuggestion,
  postingStatus,
  sending,
  setting,
  tagsSuggestion,

  onChangePost,
  onChangeVideo,
  onChangeVideoLink,
  onMentionLink,
  onMentionUser,
  onPickedFiles,
  onPost,
  onRemoveFile,
  onSearchTags,
}) => {
  const { t } = useTranslation("Post");
  const history = useHistory();
  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const postNewEditStyles = {
    minusIcon: {
      backgroundColor: "#a0a0a0",
      borderRadius: 10,
      fontSize: 24,
    },
    deleteButton: {
      border: "none",
      display: "flex",
      backgroundColor: "transparent",
      top: "5px",
      right: "5px",
    },
  };

  const { body, group, files, linksMentions, mentions, tags, video } = data;

  /** Input refs */
  const imageInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  /** PostNewEdit Modal state */
  const togglePostNewEditModal = (): void => {
    toggle && toggle();
  };

  /** BrowseMoreGroups Modal state */
  const [isBrowseMoreGroupsModalOpen, setBrowseMoreGroupsModalOpen] = useState(
    false
  );
  const toggleBrowseMoreGroupsModal = (): void => {
    setBrowseMoreGroupsModalOpen(!isBrowseMoreGroupsModalOpen);
  };

  /** SearchImage Modal state */
  const [isSearchImageModalOpen, setSearchImageModalOpen] = useState(false);
  const toggleSearchImageModal = (): void => {
    setSearchImageModalOpen(!isSearchImageModalOpen);
  };

  /** UploadVideo Modal state */
  const [isVideoUploadModalOpen, setVideoUploadModalOpen] = useState(false);
  const toggleVideoUploadModal = (): void => {
    setVideoUploadModalOpen(prevState => !prevState);
  };

  /** VideoUpload url state */
  const [videoUploadUrl, setVideoUploadUrl] = useState("");

  /** File loading state */
  const [isFileLoading, setFileLoading] = useState(false);
  const finishFileLoading = (): void => {
    setFileLoading(false);
  };

  /** Video loading state */
  const [isVideoLoading, setVideoLoading] = useState(false);

  /** Media tabs state */
  const [activeMediaTab, setActiveMediaTab] = useState<PostNewEditMediaTabName>(
    "Video"
  );

  const toggleMediaTab = (tab: PostNewEditMediaTabName): void => {
    if (activeMediaTab !== tab) setActiveMediaTab(tab);
  };

  /** Error modal state */

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  const toggleErrorModal = (): void => setErrorModalOpen(!isErrorModalOpen);

  const [errorModalMessage, setErrorModalMessage] = useState<string>("");

  const displayErrorModal = (errorMessage: string): void => {
    setErrorModalMessage(errorMessage);
    toggleErrorModal();
  };

  /** Groups handler */

  const handleChooseGroup = (group: Group): void => {
    onChangePost("group", group);

    toggleBrowseMoreGroupsModal();
  };

  /** Mentions handler */

  const handleChangeMentions = (mentions: MentionUser[]): void => {
    setTimeout(() => {
      onMentionUser(mentions);
    }, 100);
  };

  /** Link mentions handler */

  const handleChangeLinkMentions = (mentions: MentionLink[]): void => {
    setTimeout(() => {
      onMentionLink(mentions);
    }, 100);
  };

  /** Body handler */

  const handleChangeBody = (body: string): void => {
    onChangePost("body", body);
  };

  /** File loading UI state handling */

  const fileLoadingCallback = (newFiles: MediaInputFileProcessed[]): void => {
    /**
     * @FIXME Why we have to combine old and new data instead of pull objects
     * from array to existing state? It should be done in container.
     */
    onPickedFiles((files || []).concat(newFiles), finishFileLoading);
  };

  const fileLoadingFallback = (errorMessage: string): void => {
    finishFileLoading();
    displayErrorModal(errorMessage);
  };

  /** Image handlers */

  const handleImageBrowserOpen = (): void => {
    imageInputRef.current?.click();
  };

  const handleSelectSearchedImage = async (
    image: MediaPhoto
  ): Promise<void> => {
    setActiveMediaTab("Files");

    setFileLoading(true);

    toggleSearchImageModal();

    try {
      const file = await downloadImage(image);

      processInputFiles([file], fileLoadingCallback, fileLoadingFallback);
    } catch (e) {
      logException(e);
      fileLoadingFallback(e?.message || "");
    }
  };

  /** Video handlers */

  const handleVideoBrowserOpen = (): void => {
    videoInputRef.current?.click();
  };

  const videoFileLoadingCallback = (
    newFiles: MediaInputFileProcessed[]
  ): void => {
    /**
     * @FIXME Why we have to combine old and new data instead of pull objects
     * from array to existing state? It should be done in container.
     */
    const newFile = newFiles[0];
    const videoFile: MediaFileType = {
      uri: newFile.fileUrl || "",
      size: newFile.file.size,
      type: newFile.file.type,
      contentType: "video/mp4",
      name: newFile.file.name || getFileName(newFile.fileUrl || ""),
    };

    onChangeVideo(videoFile);
    setVideoLoading(false);
  };

  const handleVideoFileChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.files) {
      if (
        !checkFileSize(
          e.target.files[0],
          setting.maxSizeForMobileVideoUploading || 100
        )
      ) {
        setActiveMediaTab("Video");

        setVideoLoading(true);

        /** @TODO Should we support multiple file selecting? */
        processInputFiles(
          [e.target.files[0]],
          videoFileLoadingCallback,
          fileLoadingFallback
        );
      }
    }
  };

  const handleAddVideoLink = (): void => {
    setActiveMediaTab("Video");

    setVideoLoading(true);

    onChangeVideoLink(videoUploadUrl, (): void => {
      toggleVideoUploadModal();
      setVideoLoading(false);
    });
  };

  const handleRemoveVideo = (): void => {
    onChangeVideo(null);
  };

  /** File handler */

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      if (
        !checkFileSize(e.target.files[0], setting.maxSizeForUploading || 1024)
      ) {
        setActiveMediaTab("Files");

        setFileLoading(true);

        /** @TODO Should we support multiple file selecting? */
        processInputFiles(
          [e.target.files[0]],
          fileLoadingCallback,
          fileLoadingFallback
        );
      }
    }
  };

  /** Form submit */
  const handleSubmit = (): void => {
    const { group } = data;

    const redirectToGroup = (): void => {
      /** Is closing modal good enought? */
      togglePostNewEditModal();

      /**@FIXME temporary solution for refetching data*/
      history.replace("/");
      history.push(`/group-feed/${group?.id}`);
    };

    onPost((text: string): void => {
      redirectToGroup();
      toast.success(text);
    });
  };

  /** Form elements */

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const fileTilesElement =
    files && !isEmpty(files) ? (
      <Row noGutters className="border rounded-sm mt-2 p-1">
        {files.map((file, index) => {
          const handleRemoveFile = (): void => {
            onRemoveFile(file, index);
          };

          const fileToDisplay = file.fileThumb ?? file.file;

          const isFileImage = !!isPhoto(
            fileToDisplay.type || file.file.contentType
          );
          const fileUri =
            file.thumbUrl ||
            file.fileUrl ||
            file.file.fileUrl ||
            file.file.uri ||
            file.file.thumbUrl;

          const tileContent = (
            <>
              {isFileImage && fileUri ? (
                <MediaImage
                  id={`newPostMediaImage${index}`}
                  src={fileUri}
                  height={130}
                  resizeMode="cover"
                />
              ) : (
                <div className="d-flex">
                  <i className="icon-doc" />
                  <span>{file.file.name}</span>
                </div>
              )}
            </>
          );

          return (
            <Col
              xs="12"
              sm="4"
              key={index}
              style={{ minWidth: "min-content" }}
              className="p-2 post-file-title">
              <div className="position-relative">{tileContent}</div>
              <Button
                active
                className="p-0 position-absolute post-file-delete"
                onClick={handleRemoveFile}
                style={postNewEditStyles.deleteButton}>
                <i style={postNewEditStyles.minusIcon} className="icon-minus" />
              </Button>
            </Col>
          );
        })}
        {isFileLoading && (
          <Col xs="12" sm="4" className="p-1">
            <div className="position-relative">
              <LoadingSpinner />
            </div>
          </Col>
        )}
      </Row>
    ) : isFileLoading ? (
      <LoadingSpinner />
    ) : null;

  const videoElement =
    !isEmpty(video) && !isVideoLoading ? (
      <Col
        style={{ height: "200px" }}
        className="p-2 post-file-title d-flex mt-2">
        <MediaContainer
          style={{ display: "contents" }}
          video={video}
          Layout={Media}
        />
        <Button
          active
          className="p-0 position-absolute post-file-delete"
          onClick={(): void => {
            handleRemoveVideo();
          }}
          style={postNewEditStyles.deleteButton}>
          <i style={postNewEditStyles.minusIcon} className="icon-minus" />
        </Button>
      </Col>
    ) : (
      isVideoLoading && <LoadingSpinner />
    );

  const pickerButtons = (
    <div className="d-flex justify-content-end mt-2">
      <UncontrolledDropdown>
        <DropdownToggle
          id="newPostAddImageDropdown"
          className="ml-1"
          tag={Button}
          disabled={sending}
          color="secondary"
          size="sm">
          <i className="icon-picture" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={handleImageBrowserOpen}>
            {t("NewEdit.PhotoOptions.Photo")}
          </DropdownItem>
          <DropdownItem
            id="newPostAddImageSearchButton"
            onClick={toggleSearchImageModal}>
            {t("NewEdit.PhotoOptions.Search")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <UncontrolledDropdown>
        <TooltipItem
          disabled={isEmpty(video)}
          id="newPostAddVideoDropdown"
          title={t("NewEdit.Remove.Video.Tip")}
          position="bottom">
          <DropdownToggle
            id="newPostAddVideoDropdown"
            className="ml-1"
            tag={Button}
            disabled={sending || !isEmpty(video)}
            color="secondary"
            size="sm">
            <i className="icon-film" />
          </DropdownToggle>
        </TooltipItem>
        <DropdownMenu>
          {!!setting.postVideoEnabled && (
            <DropdownItem
              id="newPostAddVideoButton"
              onClick={handleVideoBrowserOpen}>
              {t("NewEdit.VideoOptions.Video")}
            </DropdownItem>
          )}
          <DropdownItem
            id="newPostAddVideoLinkButton"
            onClick={toggleVideoUploadModal}>
            {t("NewEdit.VideoOptions.AddLink")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <input
        ref={imageInputRef}
        className="d-none"
        type="file"
        /** @TODO What should be acceptable file formats for images? */
        accept="image/png, image/jpeg, image/gif"
        /** @FIXME Should we support multiple file selecting? */
        onChange={handleFileChange}
      />
      <input
        ref={videoInputRef}
        className="d-none"
        type="file"
        accept="video/*"
        onChange={handleVideoFileChange}
      />
      <input
        ref={fileInputRef}
        className="d-none"
        type="file"
        onChange={handleFileChange}
      />

      <Button
        className="ml-1"
        disabled={sending}
        color="secondary"
        size="sm"
        onClick={(): void | null =>
          fileInputRef.current && fileInputRef.current.click()
        }>
        <i className="icon-paper-clip" />
      </Button>
    </div>
  );

  const mediaTab = fileTilesElement && videoElement && (
    /** @TODO */
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeMediaTab === "Video" })}
            onClick={(): void => {
              toggleMediaTab("Video");
            }}>
            {t("NewEdit.Tab.Video")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            id="newPostPhotosFilesTab"
            className={classnames({ active: activeMediaTab === "Files" })}
            onClick={(): void => {
              toggleMediaTab("Files");
            }}>
            {t("NewEdit.Tab.Photos.Or.Files")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeMediaTab}>
        <TabPane tabId="Video">{videoElement}</TabPane>
        <TabPane tabId="Files">{fileTilesElement}</TabPane>
      </TabContent>
    </div>
  );

  const tagsElement = (
    <TagInput
      id="newPostTagInput"
      tags={tags}
      /** @TODO Implement when ready */
      tagsSuggestion={tagsSuggestion}
      fetchTagsSuggestion={onSearchTags}
      onChangeTags={(tags): void => onChangePost("tags", tags)}
    />
  );

  const mainButton = (
    <div className="text-right my-2 mt-4">
      <Button
        id="addNewPostButton"
        block
        style={{ backgroundColor: buttonBackgroundColor, borderWidth: 0 }}
        disabled={sending || !allowPosting}
        onClick={handleSubmit}>
        <span style={{ color: buttonColor }}>
          {postingStatus ? postingStatus.text : t("NewEdit.Button.Post")}
        </span>
      </Button>
    </div>
  );

  //if creating from fromMixedFeed we should display the suggested groups
  const groups: Dict<(Group | PostGroup) & { selected?: boolean }> = {
    ...(fromMixedFeed ? groupsSuggestion : {}),
    ...(group && !isEmpty(group)
      ? { [group.id]: { ...group, selected: true } }
      : {}),
  };

  const groupsElement = (
    <>
      <div className="mb-2">
        {Object.values(groups).map((group, index) => (
          <Button
            id={`choosenGroupButton${group.name.replace(/\s/g, "")}`}
            key={index}
            onClick={(): void => {
              onChangePost("group", group);
            }}
            className="mr-1 mb-1"
            size="sm"
            color={group.selected ? "success" : "secondary"}>
            {group.name}
          </Button>
        ))}
      </div>
      <Modal
        id="browseMoreGroupsModal"
        isOpen={isBrowseMoreGroupsModalOpen}
        toggle={toggleBrowseMoreGroupsModal}
        centered={true}>
        <ModalHeader toggle={toggleBrowseMoreGroupsModal}>
          {t("NewEdit.Heading.Choose.Group")}
        </ModalHeader>
        <ModalBody>
          <GroupPostingGroupsContainer
            Layout={GroupPostingGroups}
            onPress={handleChooseGroup}
          />
        </ModalBody>
      </Modal>
    </>
  );

  const bodyInputPlaceholder = group
    ? t("NewEdit.Placeholder.Group.Post")
    : t("NewEdit.Placeholder.Group.Choose");

  return (
    <>
      <ErrorModal
        isOpen={isErrorModalOpen}
        message={errorModalMessage}
        toggle={toggleErrorModal}
      />

      {/** Uploading */}
      <UploadingContainer Layout={Uploading} className="mb-2" />

      {errorElement}

      {/** Groups */}
      <div>
        {t("NewEdit.Heading.Text")}
        <Button
          id="browseMoreGroupsButton"
          color="link"
          className="text-decoration-none"
          style={{ paddingBottom: 10 }}
          onClick={toggleBrowseMoreGroupsModal}>
          <i className="icon-plus"></i>
        </Button>
      </div>
      {groupsElement}

      {/** Body input*/}
      <div className="mt-4 mb-2">{t("NewEdit.Heading.Message")}</div>
      <div
        className="border p-1 post-new-edit-body-input"
        style={{ borderRadius: 5 }}>
        <MentionInputContainer
          id="postNewEditInput"
          Layout={MentionInput}
          includeHereAndChannel={false}
          value={body || ""}
          onChangeText={handleChangeBody}
          // inputValue={text}
          placeholder={bodyInputPlaceholder}
          minHeight={150}
          mentionUser={handleChangeMentions}
          usersMentioned={mentions}
          mentionLink={handleChangeLinkMentions}
          linksMentioned={linksMentions}
          disabled={!group || sending}
          suggestionBottom={false}
          maxVisibleRowCount={5}
        />
      </div>

      {pickerButtons}

      {/** Media uploading modals */}
      <Modal
        isOpen={isSearchImageModalOpen}
        toggle={toggleSearchImageModal}
        centered>
        <ModalHeader toggle={toggleSearchImageModal}>
          {t("Media:SearchImage.Button.Search.Photo")}
        </ModalHeader>
        <ModalBody>
          <MediaSearchImage
            options={["giphy", "stock", "web"]}
            onSelect={handleSelectSearchedImage}
          />
        </ModalBody>
      </Modal>
      <Modal
        id="addVideoModal"
        isOpen={isVideoUploadModalOpen}
        toggle={toggleVideoUploadModal}
        centered>
        <ModalHeader toggle={toggleVideoUploadModal}>
          {t("Post:NewEdit.VideoOptions.AddLink.Title")}
        </ModalHeader>
        <ModalBody>
          <Input
            id="videoAddLinkInput"
            onChange={(e): void => setVideoUploadUrl(e.target.value)}
            placeholder={t("Post:NewEdit.VideoOptions.AddLink.Hint")}
          />
          <Button
            id="videoAddLinkButton"
            disabled={videoUploadUrl === ""}
            onClick={handleAddVideoLink}
            color="primary"
            className="float-right my-2">
            {t("Post:NewEdit.VideoOptions.AddLink")}
          </Button>
        </ModalBody>
      </Modal>

      {mediaTab}
      {!mediaTab && fileTilesElement}
      {!mediaTab && videoElement}

      <div className="mt-1 mb-2">{t("NewEdit.Heading.Tag")}</div>
      {tagsElement}

      {mainButton}
    </>
  );
};
