import React from "react";

import { Card, Col, Alert } from "reactstrap";

import { InfiniteScroll, MediaImage } from "../..";

import { PostImagesInfiniteScrollComponent, PostImagesPlaceholder } from ".";
import { PostImagesLayoutProps } from "../../../../containers/Post/Images";

export const PostImages: React.FC<PostImagesLayoutProps> = ({
  data,
  error,
  hasMore,
  loading: isLoading,
  refreshing: isRefreshing,

  reFetch,
}) => {
  /** @TODO This logic could be moved to container */
  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const content =
    data?.map((image, index) => (
      <Col sm="6" md="4" lg="3" key={image.id} className="py-1 px-1">
        <MediaImage
          id={`mediaImage${index}`}
          resizeMode="cover"
          height={170}
          src={image.thumbUrl || image.fileUrl}
        />
      </Col>
    )) || null;

  return (
    <Card className="px-2">
      {errorElement}
      <InfiniteScroll
        component={PostImagesInfiniteScrollComponent}
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        hasMore={hasMore}
        placeholder={<PostImagesPlaceholder />}
        onLoadMore={handleLoadMore}>
        {content}
      </InfiniteScroll>
    </Card>
  );
};
