import React from "react";

import { useTranslation } from "react-i18next";

import UserLoginContainer from "../../../containers/User/Login";

import { TemplateSimple } from "../../components/Template";
import { UserForgotPassword } from "../../components/User";

import { RouteAppComponentProps } from "..";

export type RouteUserForgotPasswordProps = RouteAppComponentProps;

export const RouteUserForgotPassword: React.FC<RouteUserForgotPasswordProps> = ({
  setting,
  ...props
}) => {
  const { t } = useTranslation("User");
  return (
    <TemplateSimple pageTitle={t("Login.Forgot.Password")} setting={setting}>
      <UserLoginContainer {...props} Layout={UserForgotPassword} />
    </TemplateSimple>
  );
};
