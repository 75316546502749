import { flatten } from "underscore";

export function getChunkedListItemsToRender<T>(
  listItems: Array<string | T>,
  countOfLoadedMembers: number
): Array<string | T> {
  return listItems.length > countOfLoadedMembers
    ? [...listItems].slice(0, countOfLoadedMembers)
    : listItems;
}

export function translateSectionDictionaryToList<T>(
  sectionData:
    | { title?: string; data: T[] }[]
    | { title?: string; data: T[] }
    | null
): Array<string | T> | null {
  if (!sectionData) {
    return null;
  }
  if (!Array.isArray(sectionData)) {
    return flatten([sectionData.title, ...sectionData.data]);
  }
  return flatten(
    sectionData.map(({ title, data }) => {
      return [title, ...data];
    })
  );
}
