import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { CardBody } from "reactstrap";

import { textThemingWorkaround } from "../../../../constants";
import { displayDate, removeWhiteSpaces } from "../../../../lib/utils";
import {
  Dict,
  UserCompanyHistoryEvent,
  UserExecutiveAssistant,
  UserExecutiveAssistantType,
  UserSocialMedia,
  UserSocialMediaType,
} from "../../../../types";

import { useTheme } from "../../../hooks/useTheme";

import { MentionDisplay, Heading } from "../..";

import { MemberDetailSocialMedia } from ".";
import { Link } from "react-router-dom";

export type MemberDetailInfoSectionsProps = {
  assistantAvailable: Partial<UserExecutiveAssistantType>;
  companyHistory?: UserCompanyHistoryEvent[];
  contactAvailable?: string | boolean;
  memberBio?: string | false;
  memberChildren?: {
    Name?: string;
    Birthday?: Date;
  }[];
  memberEmail?: string | false;
  memberPhone?: string | false;
  memberSpouse?: {
    Name?: string;
    Birthday?: Date;
  };
  noUserInfoHeading: string;
  personalFields: {
    label: string;
    value: any;
  }[];
  personalInfoAvailable?: boolean;
  professionalFields: {
    label: string;
    value?: string;
  }[];
  professionalInfoAvailable?: boolean;
  socialMedia: UserSocialMediaType;
  socialMediaVisibilities: Dict<boolean, UserSocialMedia>;
  summary?: string | false;
  partnerProfile?: any;
};

export const MemberDetailInfoSections: FC<MemberDetailInfoSectionsProps> = ({
  assistantAvailable,
  companyHistory,
  contactAvailable,
  memberBio,
  memberChildren,
  memberEmail,
  memberPhone,
  memberSpouse,
  noUserInfoHeading,
  personalFields,
  personalInfoAvailable,
  professionalFields,
  professionalInfoAvailable,
  socialMedia,
  socialMediaVisibilities,
  summary,
  partnerProfile,
}) => {
  const {
    text: { subHeader, listTitle },
    divider,
  } = useTheme();

  const { t } = useTranslation("Member");

  const isAssistantDataEmpty = Object.values(assistantAvailable).every(
    x => x === ""
  );

  const memberDetailInfoSectionsStyles = {
    card: {
      backgroundColor: "#fff",
      paddingLeft: 20,
      paddingRight: 20,
    },
    cardBody: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    cardText: {
      marginBottom: 10,
      borderBottomWidth: 0,
      paddingTop: 5,
    },
    noUserInfoHeading: { ...listTitle, ...textThemingWorkaround },
    propertyHeading: {
      ...subHeader,
      opacity: 0.5,
      ...textThemingWorkaround,
    },
    valueHeading: {
      ...listTitle,
      ...textThemingWorkaround,
      whiteSpace: "pre-wrap" as "pre-wrap",
    },
    title: {
      backgroundColor: divider.backgroundColor,
      color: divider.text.color,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: -10,
      marginRight: -10,
    },
  } as const;

  const memberChildrenElement = memberChildren && memberChildren.length > 0 && (
    <div style={memberDetailInfoSectionsStyles.cardText}>
      <Heading
        text={t("Detail.Title.Children")}
        id="memberPersonalInfoPropertyChildren"
        style={memberDetailInfoSectionsStyles.propertyHeading}
      />

      {memberChildren.map(
        (value): JSX.Element => {
          if (value.Name) {
            const birthday = displayDate(value.Birthday, "MMMM DD");
            return (
              <div key={value.Name}>
                <span
                  id={
                    "memberPersonalInfoChildrenValueName" +
                    (value.Name || "").split(" ").join("")
                  }
                  className="pr-1"
                  style={memberDetailInfoSectionsStyles.valueHeading}>
                  {value.Name}
                </span>

                {birthday && (
                  <span
                    id={
                      "memberPersonalInfoChildrenValueBirthday" +
                      (value.Name || "").split(" ").join("")
                    }
                    style={memberDetailInfoSectionsStyles.valueHeading}>
                    - {birthday}
                  </span>
                )}
              </div>
            );
          } else {
            return <div />;
          }
        }
      )}
    </div>
  );

  const spouseBirthday =
    !!memberSpouse && displayDate(memberSpouse.Birthday, "MMMM DD");

  const memberSpouseElement = !!memberSpouse && (
    <div
      id={"memberCardInfoSpouse"}
      style={memberDetailInfoSectionsStyles.cardText}>
      <Heading
        text={t("Detail.Label.Spouse.Partner.Significant.Other")}
        id={"memberPersonalInfoPropertySpouse"}
        style={memberDetailInfoSectionsStyles.propertyHeading}
      />

      {!partnerProfile && (
        <div>
          <span
            id={"memberPersonalInfoSpouseValueName"}
            className="pr-1"
            style={memberDetailInfoSectionsStyles.valueHeading}>
            {memberSpouse.Name}
          </span>

          {spouseBirthday && (
            <span
              id={"memberPersonalInfoSpouseValueBirthday"}
              style={memberDetailInfoSectionsStyles.valueHeading}>
              - {spouseBirthday}
            </span>
          )}
        </div>
      )}

      {!!partnerProfile && (
        <div>
          <Link
            className="text-primary"
            to={`/member/${partnerProfile.objectId}`}>{`${partnerProfile.firstName} ${partnerProfile.lastName}`}</Link>
        </div>
      )}
    </div>
  );

  const memberBioElement = !!memberBio && (
    <div style={memberDetailInfoSectionsStyles.cardText}>
      <Heading
        id={"memberPersonalInfoPropertyBio"}
        text={t("Detail.Label.About.Me")}
        style={memberDetailInfoSectionsStyles.propertyHeading}
      />

      <Heading
        id={"memberPersonalInfoValueBio"}
        text={memberBio}
        style={memberDetailInfoSectionsStyles.valueHeading}
      />
    </div>
  );

  const memberCompanyHistoryElement = companyHistory &&
    companyHistory.length > 0 && (
      <div style={memberDetailInfoSectionsStyles.cardText}>
        <Heading
          id={"memberProfessionalInfoPropertyCompanyHistory"}
          text={t("Company History")}
          style={memberDetailInfoSectionsStyles.propertyHeading}
        />

        {companyHistory.map(
          (value): JSX.Element => {
            if (value.name) {
              const fromDate = displayDate(value.fromdate, "MMM YYYY");
              const toDate = displayDate(value.todate, "MMM YYYY");
              return (
                <div key={value.name}>
                  <span
                    id={
                      "memberProfessionalInfoValueCompanyHistoryName" +
                      value.name.split(" ").join("")
                    }
                    style={memberDetailInfoSectionsStyles.valueHeading}>
                    {value.name}
                  </span>

                  {fromDate && (
                    <>
                      <span
                        id={
                          "memberProfessionalInfoValueCompanyHistoryFromDate" +
                          value.name.split(" ").join("")
                        }
                        className="pr-1"
                        style={memberDetailInfoSectionsStyles.valueHeading}>
                        : {fromDate}
                      </span>

                      {value.iscurrent === false ? (
                        <span
                          id={
                            "memberProfessionalInfoValueCompanyHistoryToDate" +
                            value.name.split(" ").join("")
                          }
                          style={memberDetailInfoSectionsStyles.valueHeading}>
                          - {toDate}
                        </span>
                      ) : (
                        <span
                          id={
                            "memberProfessionalInfoValueCompanyHistoryCurrent" +
                            value.name.split(" ").join("")
                          }
                          style={memberDetailInfoSectionsStyles.valueHeading}>
                          - {t("Detail.Text.Current")}
                        </span>
                      )}
                    </>
                  )}
                </div>
              );
            } else {
              return <div />;
            }
          }
        )}
      </div>
    );

  const summaryElement = !!summary && (
    <div style={memberDetailInfoSectionsStyles.cardText}>
      <Heading
        id={"memberProfessionalInfoPropertySummary"}
        text={t("Detail.Title.Summary")}
        style={memberDetailInfoSectionsStyles.propertyHeading}
      />
      <Heading
        id={"memberProfessionalInfoValueSummary"}
        text={summary}
        style={memberDetailInfoSectionsStyles.valueHeading}
      />
    </div>
  );

  const proffesionalInfoTab = (
    <CardBody style={memberDetailInfoSectionsStyles.cardBody}>
      <h6
        id={"proffesionalInfoTab"}
        style={memberDetailInfoSectionsStyles.title}>
        Professional Info
      </h6>
      {!professionalInfoAvailable && (
        <div style={memberDetailInfoSectionsStyles.cardText}>
          <Heading
            text={noUserInfoHeading}
            style={memberDetailInfoSectionsStyles.noUserInfoHeading}
          />
        </div>
      )}
      {!!professionalInfoAvailable && (
        <>
          {professionalFields.map(
            ({ label, value }): JSX.Element => {
              return (
                <div
                  key={label}
                  style={memberDetailInfoSectionsStyles.cardText}>
                  <Heading
                    text={t(label)}
                    style={memberDetailInfoSectionsStyles.propertyHeading}
                    id={
                      "memberProfessionalInfoProperty" +
                      label.split(" ").join("")
                    }
                  />
                  <div style={memberDetailInfoSectionsStyles.valueHeading}>
                    <MentionDisplay
                      id={
                        "memberProfessionalInfoValue" +
                        label.split(" ").join("")
                      }
                      text={value || ""}
                    />
                  </div>
                </div>
              );
            }
          )}
          {memberCompanyHistoryElement}
          {summaryElement}
        </>
      )}
    </CardBody>
  );

  const executiveAssistantTab = !isAssistantDataEmpty && (
    <CardBody style={memberDetailInfoSectionsStyles.cardBody}>
      <h6
        id={"executiveAssistantTab"}
        style={memberDetailInfoSectionsStyles.title}>
        Executive Assistant
      </h6>
      {Object.keys(assistantAvailable).map(
        (value): JSX.Element => (
          <div key={value}>
            {assistantAvailable[value as UserExecutiveAssistant] && (
              <div style={memberDetailInfoSectionsStyles.cardText}>
                <Heading
                  id={`memberExecutive${value}Property`}
                  text={t(value)}
                  style={memberDetailInfoSectionsStyles.propertyHeading}
                />

                <div style={memberDetailInfoSectionsStyles.valueHeading}>
                  <MentionDisplay
                    id={`memberExecutive${value}Value`}
                    text={
                      assistantAvailable[value as UserExecutiveAssistant] || ""
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )
      )}
    </CardBody>
  );

  const personalInfoTab = (
    <CardBody style={memberDetailInfoSectionsStyles.cardBody}>
      <h6 id={"personalInfoTitle"} style={memberDetailInfoSectionsStyles.title}>
        Personal Info
      </h6>
      {!personalInfoAvailable && (
        <div style={memberDetailInfoSectionsStyles.cardText}>
          <Heading
            text={noUserInfoHeading}
            style={memberDetailInfoSectionsStyles.noUserInfoHeading}
          />
        </div>
      )}
      {!!personalInfoAvailable && (
        <>
          {personalFields.map(
            ({ label, value }): JSX.Element => {
              return (
                <div
                  id={`memberCardInfo${label}`}
                  key={label}
                  style={memberDetailInfoSectionsStyles.cardText}>
                  <Heading
                    text={t(label)}
                    id={
                      "memberPersonalInfoProperty" + label.split(" ").join("")
                    }
                    style={memberDetailInfoSectionsStyles.propertyHeading}
                  />

                  <div style={memberDetailInfoSectionsStyles.valueHeading}>
                    <MentionDisplay
                      id={"memberPersonalInfoValue" + label.split(" ").join("")}
                      text={value || ""}
                    />
                  </div>
                </div>
              );
            }
          )}
          {memberSpouseElement}
          {memberChildrenElement}
          {memberBioElement}
        </>
      )}
    </CardBody>
  );

  const socialMediaTab = (
    <CardBody style={memberDetailInfoSectionsStyles.cardBody} className="pb-1">
      <h6 id={"socialMediaTitle"} style={memberDetailInfoSectionsStyles.title}>
        {"Let's Connect"}
      </h6>
      {!contactAvailable && (
        <div style={memberDetailInfoSectionsStyles.cardText}>
          <Heading
            text={noUserInfoHeading}
            style={memberDetailInfoSectionsStyles.noUserInfoHeading}
          />
        </div>
      )}
      {!!contactAvailable && (
        <>
          {memberEmail && (
            <div style={memberDetailInfoSectionsStyles.cardText}>
              <Heading
                id={"memberSocialMediaEmailProperty"}
                text={t("Detail.Label.Email")}
                style={memberDetailInfoSectionsStyles.propertyHeading}
              />

              <div style={memberDetailInfoSectionsStyles.valueHeading}>
                <MentionDisplay
                  id={"memberSocialMediaEmailValue"}
                  text={memberEmail || ""}
                />
              </div>
            </div>
          )}

          {memberPhone && (
            <div style={memberDetailInfoSectionsStyles.cardText}>
              <Heading
                id={"memberSocialMediaPhoneProperty"}
                text={t("Detail.Label.Phone")}
                style={memberDetailInfoSectionsStyles.propertyHeading}
              />

              <div style={memberDetailInfoSectionsStyles.valueHeading}>
                <MentionDisplay
                  id={"memberSocialMediaPhoneValue"}
                  text={
                    (memberPhone !== "+1" && removeWhiteSpaces(memberPhone)) ||
                    ""
                  }
                />
              </div>
            </div>
          )}
          <MemberDetailSocialMedia
            data={socialMedia}
            visibilities={socialMediaVisibilities}
          />
        </>
      )}
    </CardBody>
  );

  return (
    <div style={memberDetailInfoSectionsStyles.card}>
      {proffesionalInfoTab}
      {executiveAssistantTab}
      {personalInfoTab}
      {socialMediaTab}
    </div>
  );
};
