import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Row } from "reactstrap";

import { displayDate, shadeColor } from "../../../../lib/utils";
import { textThemingWorkaround } from "../../../../constants";
import { Dict, Group } from "../../../../types";
import { DocumentTitle } from "../../../../containers/routers/DocumentTitle";

import { useTheme } from "../../../hooks/useTheme";

import { Avatar, Dot, Heading } from "../..";
import { IconFontAwesome } from "../../UI/Icon";

export type MemberDetailHeaderProps = {
  avatar?: string;
  followed?: boolean;
  industry?: Dict<Group>;
  isMyProfile: boolean;
  isAdmin: boolean;
  joinAt?: string | null;
  joinAtDateHeading?: string;
  name: string;
  region?: Dict<Group>;
  sending: boolean;
  setting: {
    ClientHostName: string;
    followingEnabled: boolean | undefined;
    blenderBoxEnabled: boolean;
  };
  title?: string;

  onFollow(): void;
  onOpenChat(): void;
  loginAsThisUser: () => void;
};

export const MemberDetailHeader: FC<MemberDetailHeaderProps> = ({
  avatar,
  followed,
  industry,
  isMyProfile,
  joinAt,
  joinAtDateHeading,
  name,
  region,
  sending,
  setting,
  title,
  isAdmin,

  onFollow,
  onOpenChat,
  loginAsThisUser,
}) => {
  const {
    button,
    card,
    text: { subHeader, cardTitle, cardText },
    navigation: { backgroundColor },
    tabTop: {
      activeText: { color },
    },
  } = useTheme();

  const history = useHistory();

  const { t } = useTranslation("Member");

  const navigateToEditProfile = (): void => {
    history.push("/edit-profile");
  };

  const backgroundColorDarker =
    shadeColor(backgroundColor, -10) || backgroundColor;

  const isBlenderBox = setting.blenderBoxEnabled;

  const memberDetailHeaderStyles = {
    avatar: {
      borderColor: color,
      borderWidth: 2,
      borderStyle: "solid",
      padding: 2,
      width: "100%",
      maxHeight: "100%",
      height: "100%",
    },
    actionButtons: {
      justifyContent: "flex-end",
      marginTop: 20,
      padding: 10,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: backgroundColorDarker,
      borderColor: backgroundColor,
      borderWidth: 1,
      borderStyle: "solid",
    },
    button: {
      backgroundColor: button.backgroundColor,
      color: button.text.color,
      borderWidth: 0,
      minWidth: 70,
      marginLeft: 5,
      paddingLeft: 8,
      paddingRight: 8,
      marginTop: 5,
    },
    cardBodyAvatar: {
      backgroundColor: "transparent",
      padding: 15,
    },
    cardHeader: {
      ...card,
      padding: 0,
      marginTop: 10,
      borderRadius: 3,
      backgroundColor: "transparent",
    },
    cardItem: {
      backgroundColor: "transparent",
      color,
      padding: 0,
      justifyContent: "center",
    },
    dot: {
      backgroundColor: "#808080",
      marginLeft: "6px",
      marginRight: "6px",
      transform: "scale(0.4)",
    },
    headingJoinAt: {
      ...subHeader,
      ...textThemingWorkaround,
      color,
      paddingTop: 10,
    },
    headingName: {
      ...cardTitle,
      fontSize: 22,
      lineHeight: 30,
      ...textThemingWorkaround,
      color,
    },
    headingTitle: {
      ...cardTitle,
      ...textThemingWorkaround,
      color,
    },
    iconButton: { paddingTop: 2 },
    regionIndustry: {},
    regionIndustryText: {
      ...cardText,
      ...textThemingWorkaround,
      color,
    },
  } as const;

  const followingButton = !!setting.followingEnabled && !isMyProfile && (
    <Button
      id={"memberButtonFollow"}
      style={memberDetailHeaderStyles.button}
      disabled={sending}
      onClick={onFollow}>
      <IconFontAwesome
        name={followed ? "star" : "star-o"}
        style={memberDetailHeaderStyles.iconButton}
      />
      <span className="pl-2">{followed ? "Following" : "Follow"}</span>
    </Button>
  );

  const handleLoginAsThisUser = (): void => {
    loginAsThisUser();
  };

  const loginAsThisUserButton = !isMyProfile && isAdmin && (
    <>
      <Button
        id={"memberButtonLoginAsGuest"}
        style={memberDetailHeaderStyles.button}
        onClick={handleLoginAsThisUser}>
        <IconFontAwesome
          name="user-secret"
          style={memberDetailHeaderStyles.iconButton}
        />
        <span className="pl-2">{t("Detail.Button.LoginAsGuest")}</span>
      </Button>
    </>
  );

  const actionButtons = (
    <Row style={memberDetailHeaderStyles.actionButtons}>
      {isMyProfile ? (
        <Button
          id="editProfileButton"
          style={memberDetailHeaderStyles.button}
          onClick={navigateToEditProfile}>
          {t("Detail.Header.Button.Text")}
        </Button>
      ) : (
        <div>
          <Button
            id={"memberButtonChat"}
            style={memberDetailHeaderStyles.button}
            onClick={onOpenChat}
            disabled={sending}>
            <IconFontAwesome
              name="comment-alt"
              style={memberDetailHeaderStyles.iconButton}
            />
            <span className="pl-2">{t("Detail.Button.Chat")}</span>
          </Button>
          {followingButton}
          {loginAsThisUserButton}
        </div>
      )}
    </Row>
  );

  const goToGroup = (item: Group): void => {
    history.push(`/group-feed/${item.id}`);
  };

  const industries =
    isBlenderBox &&
    industry &&
    Object.values(industry)
      .filter(item => item)
      .map((item, index) => (
        <div key={item.name} className="d-inline">
          {index > 0 && <Dot style={memberDetailHeaderStyles.dot} />}
          <span
            className="cursor-pointer"
            key={item.id}
            onClick={(): void => goToGroup(item)}
            style={memberDetailHeaderStyles.regionIndustryText}>
            {item.name}
          </span>
        </div>
      ));

  const regions =
    isBlenderBox &&
    region &&
    Object.values(region)
      .filter(item => item)
      .map((item, index) => (
        <div key={item.name} className="d-inline">
          {index > 0 && <Dot style={memberDetailHeaderStyles.dot} />}
          <span
            className="cursor-pointer"
            key={item.id}
            onClick={(): void => goToGroup(item)}
            style={memberDetailHeaderStyles.regionIndustryText}>
            {item.name}
          </span>
        </div>
      ));

  const joinAtElement = !!joinAt && (
    <Row style={memberDetailHeaderStyles.cardItem}>
      <Heading
        text={`${joinAtDateHeading} ${displayDate(joinAt, "MMMM DD, YYYY")}`}
        style={memberDetailHeaderStyles.headingJoinAt}
      />
    </Row>
  );

  const titleElement = (
    <Row style={memberDetailHeaderStyles.cardItem}>
      <Heading
        text={title}
        id="memberTitle"
        style={memberDetailHeaderStyles.headingTitle}
      />
    </Row>
  );

  const regionsIndustriesElement = isBlenderBox && (
    <div style={memberDetailHeaderStyles.regionIndustry}>
      <Row style={memberDetailHeaderStyles.cardItem}>{industries}</Row>
      <Row style={memberDetailHeaderStyles.cardItem}>{regions}</Row>
    </div>
  );

  const headerElement = (
    <CardBody className="p-0">
      <Row style={memberDetailHeaderStyles.cardItem}>
        <Heading
          text={name}
          large
          bold
          style={memberDetailHeaderStyles.headingName}
          id="memberName"
        />
      </Row>
      {titleElement}
      {joinAtElement}
      {regionsIndustriesElement}
      {actionButtons}
    </CardBody>
  );

  return (
    <>
      <DocumentTitle pageTitle={name} />
      <Card style={memberDetailHeaderStyles.cardHeader}>
        <CardBody
          id={"memberAvatarContainer"}
          className="pb-0 rounded-top"
          style={memberDetailHeaderStyles.cardBodyAvatar}>
          {/** @TODO
           * implement view photos after clicking avatar
           */}
          <Row className="justify-content-center position-relative">
            <Avatar
              name={name}
              src={avatar}
              size={"200"}
              round={true}
              containerStyle={memberDetailHeaderStyles.avatar}
              className="pb-4"
            />
          </Row>
          {headerElement}
        </CardBody>
      </Card>
    </>
  );
};
