import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { getVideos } from "./action";
import { track } from "../../lib/track";
import { UserDataState } from "../../types/User/UserDataState";
import { MatchProps, RootState } from "../../types";
import { Video } from "../../types/VideoLibrary/Video";
import { SettingSettingData } from "../../types/Setting/SettingSettingData";
import { isEmpty } from "underscore";
import { getStateWhenGoback } from "../../lib/utils/checkGoBack";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";
import {
  getVideoLibraryDataState,
  getVideoLibraryDataHasMoreState,
} from "./selector";

type StateProps = {
  user: UserDataState | {};
  setting: SettingSettingData | {};
  data: Video[] | null;
  hasMore: boolean;
};

export type VideoListLayout = {
  data: Video[] | null;
  hasMore: boolean;
  error: null;
  loading: boolean;
  refreshing: boolean;
  user: UserDataState | {};
  setting: SettingSettingData | {};
  reFetch: (next: boolean, refresh: boolean) => void;
};

type DispatchProps = {
  getVideos: (next: boolean, refresh: boolean) => Promise<void>;
};

type Props = {
  Layout: ComponentType<VideoListLayout>;
} & StateProps &
  DispatchProps &
  MatchProps;

type State = {
  error: null;
  loading: boolean;
  refreshing: boolean;
};

class Videos extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount = async (): Promise<void> => {
    const lastState = await getStateWhenGoback();
    if (lastState !== "video-library") {
      this.fetchData(false, true);
    }

    track("View Screen", {
      Screen: "video-library",
      Params: this.props.match && this.props.match.params,
    });
  };

  fetchData = (next: boolean, refresh: boolean): void => {
    const { getVideos, data } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }
    this.setState(
      { refreshing: refresh && !isEmpty(data), loading: next },
      async () => {
        try {
          await getVideos(next, refresh);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  render = (): JSX.Element => {
    const { Layout, user, data, setting, hasMore } = this.props;
    const { loading, error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getVideoLibraryDataState(state),
    hasMore: getVideoLibraryDataHasMoreState(state),
  };
};

const mapDispatchToProps = {
  getVideos: getVideos,
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
