import React from "react";

import { Redirect, RouteComponentProps } from "react-router-dom";
import queryString from "query-string";

export type RouteOldMapsProps = RouteComponentProps;

export const RouteOldMaps: React.FC<RouteOldMapsProps> = ({ location }) => {
  const urlParams = queryString.parse(location.search);
  const url = location.pathname.replace("maps", "map");

  if (urlParams.eventId && typeof urlParams.eventId === "string") {
    return <Redirect to={`/event-detail/${urlParams.eventId}${url}`} />;
  }

  return <Redirect to={`/members/map`} />;
};
