import { persistCombineReducers } from "redux-persist";
import reducers from "../reducers";
import { CustomWindowType } from "../types";
import { isWebPlatform } from "../web/services/isWebPlatform";
const { reduxStorage }: CustomWindowType = window;

/**
 * due to leak memory for large members directory on mobile
 * so we don't store "member#items" to Storage on mobile
 */
const blacklistPaths = isWebPlatform?.()
  ? ["workSpace", "notification", "group"]
  : ["workSpace", "notification", "group", "member"];

const reduxPersistConfig = {
  key: "root",
  storage: reduxStorage as Storage,
  throttle: 1000,
  blacklist: blacklistPaths,
};

export const reducer = persistCombineReducers(reduxPersistConfig, reducers);

/**
 * @deprecated Import `RootState` from `src/types` instead.
 * @TODO Delete export below after final containers refactor.
 */
export * from "../types/RootState";
