import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { textThemingWorkaround } from "../../../../constants/Theme";
import Variables from "../../../../theme/variables/materialWeb";

import { useTheme } from "../../../hooks";

import { Heading } from "../..";

export type EventAttendeeGuestsAddNonMemberModalProps = {
  isOpen: boolean;

  onSave: (guestName: string) => void;
  toggle: () => void;
};

export const EventAttendeeGuestsAddNonMemberModal: React.FC<EventAttendeeGuestsAddNonMemberModalProps> = ({
  isOpen,

  onSave,
  toggle,
}) => {
  const { t } = useTranslation("Event");

  const {
    button,
    text: { header },
  } = useTheme();

  const eventAttendeeGuestsAddNonMemberModalStyle = {
    buttonCancel: {
      ...button,
      height: 40,
      backgroundColor: Variables.brandDanger,
      borderColor: Variables.brandDanger,
    },

    buttonOk: {
      ...button,
      height: 40,
      backgroundColor: Variables.brandSuccess,
      borderColor: Variables.brandSuccess,
    },
    modalHeading: {
      ...header,
      ...textThemingWorkaround,
    },
  };

  const [guestName, setGuestName] = useState("");

  const handleSave = (): void => {
    if (guestName !== "") {
      onSave(guestName);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={(): void => setGuestName("")}>
      <ModalHeader id="eventAttendeeAddNonMemberHeader">
        <Heading
          style={eventAttendeeGuestsAddNonMemberModalStyle.modalHeading}
          text={t("Attendee.Add.Non.Member")}
        />
      </ModalHeader>
      <ModalBody>
        <Input
          id="eventAttendeeAddNonMemberInput"
          value={guestName}
          type="text"
          placeholder={t("Attendee.Placeholder.Enter.Name")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setGuestName(e.target.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          style={eventAttendeeGuestsAddNonMemberModalStyle.buttonCancel}
          onClick={toggle}
          id="eventAttendeeAddNonMemberCancelButton">
          {t("Attendee.Button.Cancel")}
        </Button>
        <Button
          style={eventAttendeeGuestsAddNonMemberModalStyle.buttonOk}
          id="eventAttendeeAddNonMemberOkButton"
          onClick={handleSave}>
          {t("Attendee.Button.Ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
