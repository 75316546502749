import _ from "underscore";
import { runQuery } from "../../http";
import { EventAttendee } from "../../../types/Event/EventAttendee";

export const getEventAttendee = async (
  eventId: string,
  userId: string
): Promise<EventAttendee> => {
  return Parse.User.currentAsync().then(user => {
    if (!_.isEmpty(userId)) {
      user = new Parse.User({ id: userId });
    }
    const Event = Parse.Object.extend("Event");
    const event = new Event({ id: eventId });
    return runQuery("Attendee", (query: Parse.Query) => {
      query.equalTo("event", event);
      query.equalTo("user", user);
      return query.first();
    });
  });
};
