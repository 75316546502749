import React from "react";

import Skeleton from "react-loading-skeleton";

import { useTheme } from "../../hooks";

import { EventCardPlaceholder } from ".";

export type EventListSectionPlaceholderProps = {};

export const EventListSectionPlaceholder: React.FC<EventListSectionPlaceholderProps> = () => {
  const {
    divider: {
      backgroundColor: dividerBackgroundColor,
      text: { color: dividerColor },
    },
  } = useTheme();

  const styles = {
    text: {
      backgroundColor: dividerBackgroundColor,
      color: dividerColor,
    },
  };

  return (
    <>
      <div className="d-flex pt-3">
        <div style={styles.text} className="flex-fill text-left">
          <Skeleton width={100} />
        </div>
        <div style={styles.text} className="flex-fill text-right">
          <Skeleton width={70} />
        </div>
      </div>

      <EventCardPlaceholder />
    </>
  );
};
