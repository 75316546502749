import React from "react";
import { connect } from "react-redux";
import { debounce, isEmpty } from "underscore";
import i18n from "../../middlewares/i18next";
import { track } from "../../lib/track";
import { pluralize } from "../../lib/utils";

import {
  Callback,
  EmptyObject,
  Group,
  MatchProps,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";

import { getGroupsByType, getGroupsByStatus } from "./action";
import { isGroupDataByStatus, filterGroupNameByQuery } from "./helpers";

import {
  GroupByTypeTab,
  GroupByStatusTab,
  GroupStatusData,
  GroupTypeData,
  GroupByTypeData,
  GroupDataBySections,
  SearchedData,
  GroupByStatusData,
} from "./types";
import {
  getGroupListMenuTabsState,
  getGroupListState,
  getGroupListTypeState,
} from "./selector";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";

export type GroupListLayoutProps = {
  data: Group[] | GroupDataBySections | null;
  activeTab: GroupByStatusTab | GroupByTypeTab | null;
  menuTabs: string[] | null;
  error: null;
  layoutType: "type" | "status";
  loading: boolean;
  query: string;
  refreshing: boolean;
  setting: SettingState | EmptyObject;
  user: UserDataState | EmptyObject;
  /** I'm not sure is it a string */
  changeQuery: (query: string) => void;
  changeActiveTab: (tab: GroupByStatusTab | GroupByTypeTab) => void;
  reFetch: (refresh: boolean, callback?: Callback) => false | void;
};

export type GroupListOwnProps = MatchProps & {
  Layout: React.ComponentType<GroupListLayoutProps>;
};

export type GroupListStateProps = {
  data: GroupStatusData | GroupTypeData;
  layoutType: "type" | "status";
  menuTabs: string[] | null;
  setting: SettingState | EmptyObject;
  user: UserDataState | EmptyObject;
};

export type GroupListDispatchProps = {
  getGroupsByStatus: (refresh: boolean) => Promise<unknown>;
  getGroupsByType: (refresh: boolean) => Promise<unknown>;
};

export type GroupListProps = GroupListOwnProps &
  GroupListStateProps &
  GroupListDispatchProps & {};

export type GroupListState = {
  error: null;
  loading: boolean;
  /** I'm not sure is it a string */
  refreshing: boolean;
  activeTab: GroupByStatusTab | GroupByTypeTab | null;
  query: string;
  searchedData: SearchedData | null;
};

class Groups extends React.Component<GroupListProps, GroupListState> {
  state: GroupListState = {
    error: null,
    loading: false,
    refreshing: false,
    query: "",
    searchedData: null,
    activeTab:
      this.props.menuTabs &&
      (this.props.menuTabs[0] as "My groups" | "Industry"),
  };

  componentDidMount = (): void => {
    const { data } = this.props;

    if (!data) {
      this.fetchData(true);
    }

    track("View Screen", {
      Screen: "group-list",
      Params: this.props.match && this.props.match.params,
    });
  };

  componentDidUpdate = (prevProps: GroupListProps): void => {
    const menuTabs = this.props.menuTabs;

    if (menuTabs && prevProps.menuTabs !== menuTabs) {
      const defaultMenuTab = menuTabs[0] as "My groups" | "Industry";
      this.setState({ activeTab: defaultMenuTab });
    }
  };

  fetchData = (refresh: boolean, callback?: Callback): void => {
    const { getGroupsByType, getGroupsByStatus, layoutType, data } = this.props;
    const { refreshing, loading } = this.state;

    if (refreshing || loading) {
      return;
    }

    this.setState({ refreshing: refresh && !isEmpty(data) }, async () => {
      try {
        const request =
          layoutType === "type" ? getGroupsByType : getGroupsByStatus;

        await request(refresh);

        this.setState({
          loading: false,
          refreshing: false,
          error: null,
        });

        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  getSearchedData = (
    dataToFilter: GroupByStatusData | GroupByTypeData,
    query: string
  ): GroupByStatusData | GroupByTypeData => {
    if (isGroupDataByStatus(dataToFilter)) {
      return {
        ...dataToFilter,
        data: dataToFilter.data.filter(filterGroupNameByQuery(query)),
      };
    }

    return {
      ...dataToFilter,
      all: dataToFilter.all.filter(filterGroupNameByQuery(query)),
      joined: dataToFilter.joined.filter(filterGroupNameByQuery(query)),
      pending: dataToFilter.pending.filter(filterGroupNameByQuery(query)),
    };
  };

  onChangeQueryDebounce = debounce(() => {
    const { query } = this.state;
    const { menuTabs } = this.props;

    if (!menuTabs || !query) {
      return;
    }

    menuTabs.forEach(tab => {
      // eslint-disable-next-line
      // @ts-ignore
      const dataToFilter = this.props.data[tab];

      const searchedGroupData:
        | GroupByStatusData
        | GroupByTypeData = this.getSearchedData(dataToFilter, query);

      this.setState((prevState: GroupListState) => {
        if (!prevState.searchedData) {
          return {
            searchedData: {
              [tab as GroupByStatusTab | GroupByTypeTab]: searchedGroupData,
            },
          };
        }
        return {
          searchedData: {
            ...this.state.searchedData,
            [tab as GroupByStatusTab | GroupByTypeTab]: searchedGroupData,
          },
        };
      });
    });
  }, 1000);

  onChangeQuery = (query: string): void => {
    this.setState({ query: query });

    if (!query || isEmpty(query)) {
      this.setState({
        searchedData: null,
      });
      return;
    }

    this.onChangeQueryDebounce();
  };

  onChangeActiveTab = (tab: GroupByStatusTab | GroupByTypeTab): void => {
    this.setState({
      activeTab: tab,
    });
  };

  getOrganizedGroupsBySections = (
    groupData: GroupByTypeData
  ): GroupDataBySections => [
    {
      title: `${i18n.t("Group:ListGroupsByType.Title.My")} ${
        groupData?.name === i18n.t("Group:ListGroupsByType.Title.More")
          ? i18n.t("Group:ListGroupsByType.Title.Groups")
          : pluralize(groupData?.name, 2)
      }`,

      data: groupData?.joined,
    },
    {
      title: `${i18n.t("Group:ListGroupsByType.Title.All")} ${
        groupData?.name === i18n.t("Group:ListGroupsByType.Title.More")
          ? i18n.t("Group:ListGroupsByType.Title.Groups")
          : pluralize(groupData?.name, 2)
      }`,

      data: groupData?.all,
    },
  ];

  getDataByLayoutType = (): Group[] | GroupDataBySections | null => {
    const { activeTab, searchedData } = this.state;
    const { layoutType, data } = this.props;

    if (!data || !activeTab) {
      return null;
    }

    if (!Object.keys(data).includes(activeTab)) {
      return null;
    }

    const searchedDataByActiveTab = searchedData && searchedData[activeTab];
    // eslint-disable-next-line
    // @ts-ignore
    const dataByActiveTab = searchedDataByActiveTab || data[activeTab];

    return layoutType === "status"
      ? dataByActiveTab.data
      : this.getOrganizedGroupsBySections(dataByActiveTab);
  };

  render = (): JSX.Element => {
    const { Layout, user, menuTabs, setting, layoutType } = this.props;
    const { loading, error, refreshing, activeTab, query } = this.state;

    const dataByLayoutType = this.getDataByLayoutType();
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        user={user}
        setting={setting}
        data={dataByLayoutType}
        activeTab={activeTab}
        menuTabs={menuTabs}
        layoutType={layoutType}
        reFetch={this.fetchData}
        changeActiveTab={this.onChangeActiveTab}
        changeQuery={this.onChangeQuery}
        query={query}
      />
    );
  };
}

const mapStateToProps = (state: RootState): GroupListStateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getGroupListState(state),
    layoutType: getGroupListTypeState(state),
    menuTabs: getGroupListMenuTabsState(state),
  };
};

const mapDispatchToProps = {
  getGroupsByType: getGroupsByType,
  getGroupsByStatus: getGroupsByStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
