import React from "react";

import { GroupMyGroupsListContainer } from "../../../containers";

import { GroupListByStatus } from "..";

export type GroupMyGroupsProps = {};

export const GroupMyGroups: React.FC<GroupMyGroupsProps> = () => (
  <>
    <GroupMyGroupsListContainer
      Layout={GroupListByStatus}
      className="p-2"
      simpleMode={true}
    />
  </>
);
