//eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import urlParser from "js-video-url-parser";
export const VIDEO_LINKS = [
  "youtube.com",
  "youtu.be",
  "vimeo.com",
  "twitch.com",
  "dailymotion.com",
  "youku.com",
  "wistia.com",
  "wistia.net",
];
export const getEmbededVideoLink = (videoUrl = ""): string => {
  let url = videoUrl;
  try {
    url = urlParser.create({
      videoInfo: urlParser.parse(videoUrl),
      format: "embed",
      params: {
        playsinline: 1,
        autoplay: false,
      },
    });
    if (url.indexOf("//") === 0) {
      url = url.replace("//", "https://");
    }
  } catch (error) {
    console.debug(error);
  }
  return url;
};

export const isVideoLink = (url = ""): boolean => {
  //prevent rendering video for text includes video link
  if (url.split(" ").length > 1) {
    return false;
  }
  return VIDEO_LINKS.some(videoUrl => url.indexOf(videoUrl.toLowerCase()) > -1);
};
