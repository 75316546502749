/* eslint-disable @typescript-eslint/camelcase */
import {
  getAppSettings,
  getAppConfigs,
  connectServer,
  getFeatureSettings as _getFeatureSettings,
  validateVersion as _validateVersion,
  saveCustomTheme as _saveCustomTheme,
} from "../../services/api/index";
import { localConfig } from "../../constants/config";

import { logException, printExceptionMessage } from "../../lib/utils";
import {
  AppThunkAction,
  AppThunkDispatch,
  GetState,
  SettingLocalConfigData,
  ThemeData,
} from "../../types";
import {
  CONFIG_SET,
  FEATURE_SETTING_SET,
  LOCAL_CONFIG_SET,
  SETTING_RESET,
  SETTING_SET,
} from "./constants";

import { WORK_SPACE_RESET } from "../WorkSpace/constants";
import { USER_RESET } from "../User/constants";

export const getSettings = (): AppThunkAction => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] getSettings");

  try {
    const data = await getAppSettings();
    dispatch({
      type: SETTING_SET,
      data,
    });
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export const getConfigs = (): AppThunkAction => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] getConfigs");

  try {
    const data = await getAppConfigs();
    if (data) {
      dispatch({
        type: CONFIG_SET,
        data,
      });
    }
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export const setLocalConfigs = (
  values: SettingLocalConfigData
): AppThunkAction => async (dispatch: AppThunkDispatch): Promise<void> => {
  console.debug("[Action] setLocalConfigs", values);

  try {
    dispatch({
      type: LOCAL_CONFIG_SET,
      data: values,
    });
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export const saveCustomTheme = (
  value: ThemeData | ""
): AppThunkAction => async (): Promise<void> => {
  console.debug("[Action] saveCustomTheme");

  try {
    await _saveCustomTheme(value);
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export const getFeatureSettings = (): AppThunkAction => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] getFeatureSettings");

  try {
    const data = await _getFeatureSettings();

    dispatch({
      type: FEATURE_SETTING_SET,
      data,
    });
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export const connectToServer = (
  localConfig: SettingLocalConfigData
) => async (): Promise<void> => {
  console.debug("[Action] connectToServer");

  try {
    const { parse } = localConfig;
    await connectServer(parse);
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export type validateVersionResponse = {
  updateStrategy: string;
  appStoreURL: string;
};

export const validateVersion = (
  currentVersion: string,
  platform: string
) => async (): Promise<validateVersionResponse> => {
  console.debug("[Action] validateVersion");

  try {
    return _validateVersion(currentVersion, platform);
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export const startUp = () => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<SettingLocalConfigData> => {
  console.debug("[Action] startUp");

  try {
    const savedConfig = getState().setting.localConfig;
    let config: SettingLocalConfigData;

    if (
      !savedConfig ||
      (savedConfig?.name === localConfig.name &&
        savedConfig?.version !== localConfig.version)
    ) {
      config = localConfig;
    } else {
      const {
        version,
        build,
        environment,
        git,
        auto_testing,
      } = localConfig as SettingLocalConfigData;
      config = Object.assign({}, savedConfig, {
        version,
        build,
        environment,
        git,
        auto_testing,
      });
    }

    await dispatch(connectToServer(config));

    await Promise.all([
      dispatch(setLocalConfigs(config)),
      dispatch(getSettings()),
      dispatch(getConfigs()),
      dispatch(getFeatureSettings()),
    ]);

    return config;
  } catch (error) {
    logException(error);
    throw printExceptionMessage(error);
  }
};

export const resetSetting = (): AppThunkAction => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Service] resetSetting");

  try {
    dispatch({
      type: WORK_SPACE_RESET,
    });
    dispatch({
      type: SETTING_RESET,
    });
    dispatch({
      type: USER_RESET,
    });
    await dispatch(startUp());
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};
