import { adminGet } from "../../http";
import { SettingConfigData } from "../../../types/Setting/SettingConfigData";

export const searchUsedVideo = async (
  query: string,
  config: SettingConfigData | null
): Promise<{
  id: string;
  serviceId: string;
  serviceName: string;
  thumbnail_url: string;
}[]> => {
  return adminGet(
    "announcements/used_videos",
    { query: query },
    { requireUser: true, config }
  );
};
