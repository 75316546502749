import React, { FC } from "react";
import { displayDate } from "../../../../lib/utils";

type ChatConversationDateSeparatorProps = {
  date: number;
};

export const ChatConversationDateSeparator: FC<ChatConversationDateSeparatorProps> = ({
  date,
}) => {
  return (
    <div className="py-2 sendbird-separator">
      <div className="chat-separator" />
      <div className="sendbird-separator__text">
        <div className="text-black-50 font-weight-bold text-small">
          {displayDate(date, "MMMM DD, YYYY")}
        </div>
      </div>
      <div className="chat-separator" />
    </div>
  );
};
