import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { isEmpty } from "underscore";

import ChatDetailContainer from "../../../../containers/Chat/Detail";
import MemberGroupContainer from "../../../../containers/Member/Group";
import GroupPosts from "../../../../containers/Post/Group";
import { DocumentTitle } from "../../../../containers/routers/DocumentTitle";
import {
  Callback,
  Dict,
  EmptyObject,
  Group,
  MatchProps,
  MessageCallback,
  SettingState,
  UserDataState,
} from "../../../../types";

import { useTheme } from "../../../hooks";

import { PostList, StickyNavbar } from "../..";
import { ChatConversation } from "../../Chat/Conversation";
import { GroupMembersList } from "../../Member/GroupMembersList";
import { PostCardPlaceholder } from "../../Post";

import { GroupSettingModal } from "../Setting";

import { GroupDetailInfo } from ".";

export type GroupDetailProps = {
  canAccessGroup?: boolean;
  data?: Group | null;
  error: Error | null;
  info?: string;
  isJoined?: boolean;
  loading: boolean;
  refreshing: boolean;
  renderActions: boolean;
  renderActionsDone: boolean;
  setting: SettingState | EmptyObject;
  success: unknown;
  user: UserDataState | EmptyObject;

  joinGroup: (callback: MessageCallback) => Promise<void>;
  leaveGroup: (callback: MessageCallback) => Promise<void>;
  reFetch: (refresh?: boolean, callback?: Callback) => false | void;
} & MatchProps<"id">;

/**
 * @TODO Cleanup
 */
export const GroupDetail: React.FC<GroupDetailProps> = props => {
  const {
    canAccessGroup,
    data,
    error,
    info,
    isJoined,
    // loading,
    // reFetch,
    // renderActions,
    // renderActionsDone,

    joinGroup,
    leaveGroup,
  } = props;

  const groupDetailTabNames = ["Feed", "Chat", "Members", "Info"] as const;

  const isGroupOpen = data?.visibility === "all";

  type GroupDetailTabName = typeof groupDetailTabNames[number];

  const { t } = useTranslation("Group");

  const history = useHistory();

  const [isSettingModalOpen, setSettingModalOpen] = useState(false);

  const {
    tabTop: { inactive, inactiveText, underlineColor },
    card: { backgroundColor },
  } = useTheme();

  const styles = {
    tabLink: {
      backgroundColor: inactive.backgroundColor,
      color: inactiveText.color,
      opacity: inactiveText.opacity,
    },
    tabLinkActive: {
      opacity: 1,
      borderColor: underlineColor,
    },
  };
  /**
   * @FIXME
   * How Its possible to display this route and no data passed?
   * I think there should be another view or redirect in these cases
   */
  if (!data) {
    return <PostCardPlaceholder />;
  }

  const {
    description,
    groupChatId,
    hasGroupChat,
    hasGroupFeed,
    id,
    name,
  } = data;

  /**
   * @TODO It could be convered to Dict<boolean, GroupDetailTabName>
   */
  const tabs: Dict<{ heading: string; show: boolean }, GroupDetailTabName> = {
    Feed: {
      heading: t("GroupDetail.Tab.Feed"),
      show: !!hasGroupFeed,
    },
    Chat: {
      heading: t("GroupDetail.Tab.Chat"),
      show: !!(hasGroupChat && !isEmpty(groupChatId)),
    },
    Members: { heading: t("GroupDetail.Tab.Members"), show: true },
    Info: { heading: t("GroupDetail.Tab.Info"), show: true },
  };

  const toggleSettingModal = (): void => {
    setSettingModalOpen(!isSettingModalOpen);
  };

  const getInitTab = (): GroupDetailTabName => {
    let n = 0;

    while (n < groupDetailTabNames.length) {
      if (tabs[groupDetailTabNames[n]].show) {
        return groupDetailTabNames[n];
      }
      n++;
    }
    return groupDetailTabNames[0];
  };

  /**
   * @TODO move setting initial tab to useState() initializer
   * Also it should reset active tab when rendering for different group
   * For example keep activeTab per group
   */

  const setActiveTab = (tab: GroupDetailTabName): void => {
    history.replace(`/group-feed/${id}`, { activeTab: tab });
  };

  if (history.location.state?.activeTab === undefined) {
    setActiveTab(getInitTab());
  }

  const activeTab = history.location.state.activeTab;

  const isActiveTab = (tab: GroupDetailTabName): boolean => activeTab === tab;

  const toggleTab = (tab: GroupDetailTabName): void => {
    if (!isActiveTab(tab)) setActiveTab(tab);
  };

  const successToastCallback = (text: string): void => {
    toast.success(text);
  };

  const handleJoinGroup = (): void => {
    joinGroup(text => {
      successToastCallback(text);
    });
  };

  const handleLeaveGroup = (): void => {
    leaveGroup(text => {
      successToastCallback(text);
    });
  };

  /** @TODO Implement redirect to group files */
  const handleGroupFilesButtonClick = (): void => {
    history.push(`/group-files/${id}`);
  };

  /** @TODO Implement redirect to group photos */
  const handleGroupPhotosButtonClick = (): void => {
    history.push(`/group-photos/${id}`);
  };

  const joinInfoAlertElement = (
    <div className="mb-4">
      {!!info && (
        <Alert color="danger" className="mt-2">
          {info}
        </Alert>
      )}
      <Button
        id="joinRequestGroupButton"
        block
        color="primary"
        onClick={handleJoinGroup}>
        {t("Detail.Button.Join", { name })}
      </Button>
      {!!description && (
        <p className="p-3" style={{ backgroundColor }}>
          {description}
        </p>
      )}
    </div>
  );

  if (!isJoined && !canAccessGroup && info && !error) {
    return joinInfoAlertElement;
  } else if (error) {
    return (
      <Alert color="danger" className="mt-5">
        {error}
      </Alert>
    );
  }

  let suggestionPanel = null;
  if (!canAccessGroup) {
    return <PostCardPlaceholder />;
  } else if (!isJoined) {
    suggestionPanel = joinInfoAlertElement;
  }

  const navTabsElements = groupDetailTabNames.map(
    tabName =>
      tabs[tabName].show && (
        <NavItem key={tabName}>
          <NavLink
            id={`groupTab${tabName}`}
            style={{
              ...styles.tabLink,
              ...(isActiveTab(tabName) && styles.tabLinkActive),
            }}
            onClick={(): void => {
              toggleTab(tabName);
            }}>
            {/** @TODO Implement translation here when missing one added */}
            {tabs[tabName].heading}
          </NavLink>
        </NavItem>
      )
  );

  const tabContent = (
    <TabContent activeTab={activeTab}>
      {tabs.Feed.show && (
        <TabPane tabId="Feed">
          {activeTab === "Feed" && (
            <GroupPosts Layout={PostList} match={{ params: { id } }} />
          )}
        </TabPane>
      )}
      {tabs.Chat.show && (
        <TabPane tabId="Chat">
          {activeTab === "Chat" && (
            <ChatDetailContainer
              match={{ params: { channel: groupChatId ?? "", text: "group" } }}
              Layout={ChatConversation}
            />
          )}
        </TabPane>
      )}
      {tabs.Members.show && (
        <TabPane tabId="Members">
          {activeTab === "Members" && (
            <MemberGroupContainer {...props} Layout={GroupMembersList} />
          )}
        </TabPane>
      )}

      {tabs.Info.show && (
        <TabPane tabId="Info">
          {activeTab === "Info" && (
            <GroupDetailInfo
              description={description}
              isGroupOpen={isGroupOpen}
              isJoined={!!isJoined}
              /**
               * @FIXME
               * How Its possible to display this route and no data passed?
               * I think there should be another view or redirect in these cases
               */
              name={name ?? ""}
              onJoinGroupButtonClick={handleJoinGroup}
              onGroupFilesButtonClick={handleGroupFilesButtonClick}
              onGroupPhotosButtonClick={handleGroupPhotosButtonClick}
              onLeaveGroupButtonClick={handleLeaveGroup}
            />
          )}
        </TabPane>
      )}
    </TabContent>
  );

  return (
    <div>
      <DocumentTitle pageTitle={name} />
      {suggestionPanel}
      <StickyNavbar id="groupNavbar" className="pb-0">
        <Nav tabs>
          {navTabsElements}
          <Button
            id="groupDetailSettingsButton"
            color="link"
            outline
            style={{ position: "absolute", right: 0 }}
            onClick={toggleSettingModal}>
            <i
              className="icon-settings large-icons ml-auto mt-1"
              style={{ color: underlineColor }}
            />
          </Button>
        </Nav>
      </StickyNavbar>
      {tabContent}
      <GroupSettingModal
        id={id}
        isOpen={isSettingModalOpen}
        toggle={toggleSettingModal}
      />
    </div>
  );
};
