import _ from "underscore";
import { runFunction } from "../../http";
import { Post } from "../../../types/Post/Post";
import { SettingConfigData } from "../../../types/Setting/SettingConfigData";
import {
  NotificationPostingStatus,
  PostUploadFile,
  PostData,
  ProgressCallback,
} from "../../../types";
import { uploadFilesForPost } from "./uploadFilesForPost";
import { removePost } from "./removePost";
import {
  prepareVideoFile,
  prepareVideo,
  addVideoToLibrary,
} from "../VideoLibrary";
import { Video } from "../../../types/VideoLibrary/Video";

export const addPost = async (
  data: Partial<PostData> & { userId?: string; groupId: string },
  config?: SettingConfigData,
  progressCallback?: ProgressCallback,
  progressPostingCallback?: (status?: NotificationPostingStatus) => void
): Promise<{ id: string }> => {
  console.debug("[ServiceApi] addPost");
  const {
    groupId,
    files,
    body,
    mentions,
    embedLink,
    isPhoto,
    userId,
    linksMentions,
    tags,
    video,
  } = data;

  const group = new (Parse.Object.extend("Group"))({
    id: groupId,
  });
  const author: Parse.User = new Parse.User({ id: userId });
  const newPost: Parse.Object<Post> = new (Parse.Object.extend("Post"))();

  //prepare data for new posting
  const newData = {
    body,
    mentions: _.map(mentions || [], item => JSON.stringify(item)),
    linksMentions: _.map(linksMentions || [], item => JSON.stringify(item)),
    embedLink: embedLink || {},
    tags: (tags || []).map(tag => tag.toLowerCase()),
    commentCount: 0,
    likeCount: 0,
    author,
  };

  //adding new post without group
  const savedPost = await newPost.save(newData);

  //prepare data for uploading which base on a new post id
  const uploadData = {
    files: files as PostUploadFile[],
    postId: savedPost.id,
    groupId: groupId,
    editing: false,
  };

  const uploadingFallback = (): Promise<void> => {
    return removePost(savedPost.id);
  };

  if (!_.isEmpty(video)) {
    progressPostingCallback?.({
      text: "Uploading video ...",
      shortText: "Uploading",
    });

    //uploading video
    const file = await prepareVideoFile({
      video,
      config,
      progressCallback,
    });

    const preparedVideo = await prepareVideo(file.data as Video, false);

    if (preparedVideo) {
      const savedVideo = await addVideoToLibrary(preparedVideo as Video, true);
      savedVideo &&
        savedPost.set(
          "video",
          new (Parse.Object.extend("Video"))({
            id: savedVideo?.id,
          })
        );
    }
  }

  //updating a posting status to UI
  progressPostingCallback?.(
    !_.isEmpty(files)
      ? { text: "Uploading photos/files ...", shortText: "Uploading" }
      : { text: "Posting to group ...", shortText: "Posting" }
  );

  //uploading images/files to S3 and Parse
  const groupFileIds: string[] = await uploadFilesForPost(
    uploadData,
    config,
    progressCallback,
    uploadingFallback
  );

  //updating a posting status to UI
  progressPostingCallback?.({
    text: "Posting to group ...",
    shortText: "Posting",
  });

  //adding video/files and group to post

  savedPost.set("images", groupFileIds);
  savedPost.set("group", group);

  await savedPost.save();

  //sending notification to members in group
  runFunction("sendNotificationAfterCreatedNewPost", {
    postId: savedPost.id,
    isPhoto: isPhoto,
  });

  //clearing a posting status to UI
  //status can be just undefined
  progressPostingCallback?.();

  return { id: savedPost.id };
};
