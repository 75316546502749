import React from "react";

import { RouteComponentProps } from "react-router-dom";

import VideoListContainer from "../../../containers/VideoLibrary";

import { VideoList } from "../../components/VideoLibrary";

export type RouteVideoListProps = RouteComponentProps;

export const RouteVideoList: React.FC<RouteVideoListProps> = props => (
  <VideoListContainer {...props} Layout={VideoList} />
);
