import { runFunction } from "../../http";
import _ from "underscore";
import { convertObject } from "../../../lib/utils";
import { InvoiceData } from "../../../types/Payment/InvoiceData";
import { Dict, ReceiptData } from "../../../types";

type InvoiceOptions = {
  per: number;
  page: number;
  status: ("unpaid" | "overdue" | "disputed" | string)[];
};

type ReceiptOptions = {
  per: number;
  page: number;
  status: ("paid" | string)[];
};

export const getInvoices = async (
  options: InvoiceOptions
): Promise<Dict<InvoiceData>> => {
  console.debug("[Service] getInvoices", options);

  const parseResponse = await runFunction("getInvoices", options, {
    requireUser: true,
  });
  return _.object(
    _.map<Parse.Object, string[][]>(parseResponse, value => {
      return [value.id, convertObject(value)];
    })
  );
};

export const getReceipts = async (
  options: ReceiptOptions
): Promise<Dict<ReceiptData>> => {
  console.debug("[Service] getReceipts", options);

  const parseResponse = await runFunction("getInvoices", options, {
    requireUser: true,
  });
  return _.object(
    _.map<Parse.Object, string[][]>(parseResponse, value => {
      return [value.id, convertObject(value)];
    })
  );
};
