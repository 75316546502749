import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { Dict, Group } from "../../../types";

import { runFunction } from "../../http";

/**
 * @TODO Please review carefully
 * I added optional extra argument where you can pass
 * filtering function if necessary. Implemented because of
 * getGroupsForPosting action creator filtering need.
 */
export const getUserGroups = async (
  userId: string,
  dontMerge: boolean,
  includePublic: boolean,
  options?: {
    filterFn?: (group: Group) => boolean;
  }
): Promise<Dict<Group>> => {
  const response = await runFunction(
    "groupsForUser",
    {
      id: userId,
      dontMerge: dontMerge,
      includePublic: includePublic,
    },
    { requireUser: true }
  );

  const converted = convertObject(response) as Group[];

  return _.object(
    _.map(
      _.sortBy(
        options?.filterFn ? _.filter(converted, options.filterFn) : converted,
        "name"
      ),
      value => [value.id, value]
    )
  );
};
