import { NotificationState } from "./types";

const notificationStateInit: NotificationState = {
  error: null,
  list: {
    items: null,
    page: 0,
    hasMore: true,
  },
  landingMessages: null,
  landingTweets: null,
  alert: {},
  unread: {},
  upload: null,
};

export default notificationStateInit;
