import { convertObject } from "../../../lib/utils";
import { MemberProfileLink, MemberUser } from "../../../types";

import { runQuery } from "../../http";

export const getPartnerProfile = async (
  profileId: string
): Promise<MemberUser | null> => {
  console.debug("[Service] getPartnerProfile", { profileId });

  return runQuery(
    "ProfileLink",
    async (
      query: Parse.Query<
        Parse.Object<
          MemberProfileLink & {
            /**
             * @FIXME Temporary workaround for ability of access nested field in include method
             * Reconsider how to do that properly
             */
            "linkedProfile.user": MemberUser;
          }
        >
      >
    ) => {
      /**
       * @TODO There is no option for accesing nested objects by dot annotation in include method in Parse typings
       * Temporary typings implemented above.
       */
      const partnerProfile = await query
        .equalTo(
          "profile",
          new (Parse.Object.extend("Profile"))({ id: profileId })
        )
        .include(["linkedProfile.user"])
        .first();

      if (!partnerProfile) {
        return null;
      }

      const parsedPartnerProfile = convertObject(partnerProfile);

      const user = parsedPartnerProfile?.linkedProfile?.user;

      return user;
    }
  );
};
