import React from "react";

import { AlertModal } from ".";

export type ErrorModalProps = {
  message: string;
  isOpen: boolean;

  toggle: () => void;
};

export const ErrorModal: React.FC<ErrorModalProps> = props => (
  <AlertModal color="danger" {...props} />
);
