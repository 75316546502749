import React from "react";

import { Redirect } from "react-router-dom";

import {
  useIsMultiTenant,
  useIsUserLoggedIn,
  useIsUserStatusActive,
  useUserLogin,
} from "../../hooks";

import { RouteWorkspace, RouteUserLogin } from "..";

import { RouteAppComponentProps } from ".";

export type RouteAppAuthProps = RouteAppComponentProps;

export const RouteAppAuth: React.FC<RouteAppAuthProps> = props => {
  const isMultiTenant = useIsMultiTenant();

  const isUserActive = useIsUserStatusActive();

  const isUserLoggedIn = useIsUserLoggedIn();

  const userLogin = useUserLogin();

  if (isUserActive) {
    return <Redirect to={props.location.state?.from?.pathname} />;
  }

  if (isUserLoggedIn) {
    return <Redirect to="/edit-profile" />;
  }

  if (userLogin) {
    return (
      <Redirect
        to={{
          pathname: `/passcode/${userLogin.phone}`,
          /** @TODO Implement proper redirect Path  */
          state: { from: props.location.state?.from || "/events" },
        }}
      />
    );
  }

  if (isMultiTenant) {
    return <RouteWorkspace {...props} />;
  }

  return <RouteUserLogin {...props} />;
};
