import { defaultTheme } from "../../constants";
import { SettingState } from "../../types";

import {
  CONFIG_SET,
  FEATURE_SETTING_SET,
  LOCAL_CONFIG_SET,
  SETTING_RESET,
  SETTING_SET,
} from "./constants";
import Store from "./store";
import { SettingActions } from "./types";

export const initialState = Store;

export default function reducer(
  state: SettingState = initialState,
  action: SettingActions
): SettingState {
  switch (action.type) {
    case SETTING_RESET:
      return initialState;

    case SETTING_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          setting: action.data,
          theme: {
            ...defaultTheme,
            ...action.data.custom_theme,
          },
        };
      }

      return state;

    case CONFIG_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          config: action.data,
        };
      }

      return state;

    case LOCAL_CONFIG_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          localConfig: action.data,
        };
      }

      return state;

    case FEATURE_SETTING_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          feature: action.data,
        };
      }

      return state;

    default:
      return state;
  }
}
