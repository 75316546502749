import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import CloudinaryCore from "cloudinary-core";

import { RootState, MediaPhoto, MediaVideoCloudinary } from "../../types";
import { isVideoLink } from "../../lib/utils/video";
import { FastImageProps } from "react-native-fast-image";
import { ViewStyle } from "react-native";
import { Video } from "../../types/VideoLibrary/Video";
import { VideoUpload } from "../../types/VideoLibrary/VideoUpload";
import { checkUrlExists } from "../../lib/checkUrlExists";
import { getMediaSettingState } from "./selector";

type StateProps = {
  setting: {
    googleApi?: string | null;
  };
};

export type MediaLayoutProps = {
  allowViewImageFullScreen?: unknown;
  /** Alternative text for HTML */
  alt?: string;
  backgroundColor?: string;
  file?: { thumbUrl?: string; fileUrl?: string; name?: string };
  /** Hyperlink reference for HTML */
  href?: string;
  image?: { thumbUrl?: string; fileUrl: string };
  pauseWhenInvisible?: boolean;
  resizeMode?: FastImageProps["resizeMode"];
  screenInActive?: boolean;
  setting?: {
    autoPlayVideo?: boolean;
    googleApi?: string;
  };
  style?: ViewStyle;
  video?: VideoUpload;
  videoId?: string;
  videoType?: string;

  onClick?: () => void;
  onPress?: (url?: string) => void;
  onLongPress?: () => void;
  remove?: () => void;
};

type OwnProps = {
  Layout: ComponentType<any>;
  onSelect?: (image: MediaPhoto) => void;
  video?: Video | MediaVideoCloudinary;
  image?: { thumbUrl: string } | { thumbUrl?: string; fileUrl: string };
  file?: {};
  pauseWhenInvisible?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onPress?: () => void;
  onLongPress?: () => void;
  setting?: {};
  resizeMode?: "contain" | "cover" | "stretch" | "center";
  backgroundColor?: string;
  allowViewImageFullScreen?: boolean;
  screenInActive?: boolean;
  style?: {};
  /** Alternative text for HTML */
  alt?: string;
  /** Hyperlink reference for HTML */
  href?: string;
  disableFetchOnMount?: boolean;
};

type Props = OwnProps & StateProps;

type State = {
  videoId?: string;
  subVideoId?: string;
  videoType?: string;
  error?: string | null;
};

class MediaPlayer extends Component<Props, State> {
  state: State = {};

  componentDidMount(): void {
    const { disableFetchOnMount, video } = this.props;
    if (!disableFetchOnMount || video?.serviceName !== "Cloudinary") {
      this.fetchData();
    }
  }

  fetchData = async (callback?: () => void): Promise<void> => {
    try {
      const { video } = this.props;
      if (video) {
        const { serviceName, cloudName, serviceId, url, uri, formats, format } =
          video || {};

        if (serviceName === "Cloudinary") {
          //Cloudinary is not supporting TypeScript with their video players
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          const _cloudinary = CloudinaryCore.Cloudinary.new({
            // eslint-disable-next-line @typescript-eslint/camelcase
            cloud_name: cloudName || "groupfire",
          });
          const newFormat =
            formats &&
            (formats?.indexOf("dash") > -1 || formats?.indexOf("hls") > -1)
              ? "m3u8"
              : format || "m3u8";

          //get streaming video
          let videoId = _cloudinary.video_url(serviceId, {
            format: newFormat,
          });
          let linkOK = await checkUrlExists(videoId);
          //If streaming video doesn't work properly, switch to mp4 link
          if (!linkOK) {
            videoId = _cloudinary.video_url(serviceId, { format });
            linkOK = await checkUrlExists(videoId);
          }
          if (linkOK && video) {
            videoId = videoId.replace("http://", "https://");
            this.setState({ videoId, videoType: serviceName });
            callback?.();
          } else {
            this.setState({ error: "Can't play this video." });
            callback?.();
          }
        } else if (isVideoLink(url)) {
          this.setState({ videoId: url, videoType: serviceName, error: null });
          callback?.();
        } else {
          this.setState({ videoId: uri, error: null });
        }
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      video,
      image,
      file,
      pauseWhenInvisible,
      onClick,
      onPress,
      onLongPress,
      setting,
      resizeMode,
      backgroundColor,
      allowViewImageFullScreen,
      screenInActive,
      style,
      alt,
      href,
    } = this.props;
    const { videoId, videoType, error } = this.state;

    return (
      <Layout
        /** Alternative text for HTML */
        alt={alt}
        /** Hyperlink reference for HTML */
        href={href}
        videoId={videoId}
        error={error}
        videoType={videoType}
        video={video}
        image={image}
        file={file}
        pauseWhenInvisible={pauseWhenInvisible}
        onClick={onClick}
        onPress={onPress}
        onLongPress={onLongPress}
        setting={setting}
        resizeMode={resizeMode}
        backgroundColor={backgroundColor}
        screenInActive={screenInActive}
        reFetch={this.fetchData}
        allowViewImageFullScreen={allowViewImageFullScreen}
        style={style}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    setting: getMediaSettingState(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MediaPlayer);
