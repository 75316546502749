import { CustomWindowType } from "../../../types";

export const connectServer = (parseConfig: {
  api_url: string;
  app_id: string;
  client_key: string;
  gcm_sender: string;
  js_key: string;
}): void => {
  console.debug("[Service] connectServer", parseConfig);

  // eslint-disable-next-line @typescript-eslint/camelcase
  const { app_id, api_url, js_key } = parseConfig || {};

  const appWindow: CustomWindowType = window;
  if (appWindow.storage) {
    Parse.setAsyncStorage(appWindow.storage);
  }
  Parse.initialize(app_id, js_key);

  // eslint-disable-next-line @typescript-eslint/camelcase
  Parse.serverURL = api_url;
};
