import React from "react";

import { RouteComponentProps } from "react-router-dom";

import ResourceListContainer from "../../../containers/Resource";

import { ResourceList } from "../../components/Resource";

export type RouteResourceListProps = RouteComponentProps;

export const RouteResourceList: React.FC<RouteResourceListProps> = props => (
  <ResourceListContainer {...props} Layout={ResourceList} showVideoCTA={true} />
);
