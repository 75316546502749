import React from "react";

import { RouteComponentProps } from "react-router-dom";

import EventDetailContainer from "../../../containers/Event/Detail";

import { EventAttendee } from "../../components/Event";

export type RouteEventAttendeeProps = {} & RouteComponentProps;

export const RouteEventAttendee: React.FC<RouteEventAttendeeProps> = props => (
  <EventDetailContainer {...props} Layout={EventAttendee} />
);
