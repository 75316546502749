import { convertObject } from "../../../lib/utils";
import {
  GetMembersServiceApiResponseItem,
  Member,
  UserDataState,
} from "../../../types";

import { runQuery } from "../../http";

import { customProfileHasPartner } from "./utils/customProfileHasPartner";

/**
 * @TODO Probably `getUser` would be a better name and this service should be included in User module
 */
export const getMember = async (
  userId: string,
  currentUser?: UserDataState | null
): Promise<Member | void> => {
  console.debug("[Service] getMember", userId);

  const response = await runQuery(
    "User",
    (query: Parse.Query<Parse.Object<GetMembersServiceApiResponseItem>>) => {
      query.include("profile");
      return query.get(userId);
    }
  );

  if (!response) {
    return;
  }

  const parsedUser = convertObject(response);
  const parsedUserWithFollow = {
    ...parsedUser,
    followed: currentUser?.followedUsers?.[userId] || false,
  };

  return customProfileHasPartner(parsedUserWithFollow);
};
