import React, { Component, ComponentType } from "react";

import { connect } from "react-redux";

import { track } from "../../lib/track";

import { InvoiceData } from "../../types/Payment/InvoiceData";
import {
  UserDataState,
  RootState,
  MatchProps,
  SettingSettingData,
} from "../../types";

import { getInvoice, makePayment, exportInvoice } from "./action";
import { PaymentSetting } from "./types";

type StateProps = {
  setting: PaymentSetting;
  user: UserDataState | {};
};

type DispatchProps = {
  getInvoice: (
    id: string,
    userId: string,
    refresh: boolean
  ) => Promise<{ data: InvoiceData }>;
  makePayment: (
    token: string,
    parseId: string,
    amountInCents: number
  ) => Promise<void>;
  exportInvoice: (invoiceId: string) => Promise<string>;
};

export type PaymentDetailLayoutProps = {
  data: InvoiceData | null;
  error: string | null | undefined;
  refreshing: boolean;
  sending: boolean;
  setting: PaymentSetting;
  user: UserDataState | {};

  makePayment: (token: string, callback?: (() => void) | undefined) => void;
  exportInvoice: (callback: (url: string) => void) => void;
  reFetch: (refresh: boolean) => void;
};

type Props = {
  Layout: ComponentType<PaymentDetailLayoutProps>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item?: any; //@FIXME it can be deleted in my opinion
} & StateProps &
  DispatchProps &
  MatchProps;

type State = {
  data: InvoiceData | null;
  error?: string | null;
  refreshing: boolean;
  loading: boolean;
  sending: boolean;
};

class PaymentDetail extends Component<Props, State> {
  constructor(props: Props & MatchProps) {
    super(props);

    this.state = {
      error: null,
      refreshing: false,
      loading: false,
      sending: false,
      data: null,
    };
  }

  componentDidMount(): void {
    const { item } = this.props; //@FIXME this is not used anywhere
    if (!item) {
      this.fetchData(true);
    }
    track("View Screen", {
      Screen: "payment-detail",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (refresh: boolean): void => {
    const { getInvoice, match } = this.props;
    const { refreshing } = this.state;
    const id = match.params?.id;
    const userId = match.params?.userId as string;

    if (refreshing || !id) {
      return;
    }
    this.setState({ refreshing: refresh }, async () => {
      try {
        const resp = await getInvoice(id, userId, refresh);
        this.setState({
          refreshing: false,
          error: null,
          data: resp && resp.data,
        });
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  exportInvoice = async (
    callback: (url: string) => void
  ): Promise<string | undefined> => {
    const { exportInvoice, match } = this.props;
    const { loading } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true }, async () => {
      try {
        const url = await exportInvoice(match.params?.id as string);
        this.setState({ loading: false });
        callback?.(url);
      } catch (error) {
        this.setState({ error: error, loading: false });
      }
    });
  };

  makePayment = (token: string, callback?: () => void): void => {
    const { makePayment } = this.props;
    const { refreshing, sending, data } = this.state;
    if (refreshing || sending || !data) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        const { id, total } = data;
        await makePayment(token, id, total);
        this.setState({ sending: false, error: null });
        callback && callback();
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, user, setting } = this.props;
    const { error, refreshing, data, sending } = this.state;

    return (
      <Layout
        error={error}
        refreshing={refreshing}
        sending={sending}
        user={user}
        setting={setting}
        data={data}
        reFetch={this.fetchData}
        makePayment={this.makePayment}
        exportInvoice={this.exportInvoice}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  const { setting } = state.setting || {};
  return {
    setting: {
      checkPayableTo: (setting as SettingSettingData).check_payable_to,
      checkSendTo: (setting as SettingSettingData).check_send_to,
      invoiceFooterTitle: (setting as SettingSettingData).invoice_footer_title,
      invoiceFooterBody: (setting as SettingSettingData).invoice_footer_body,
      stripeCredentials: (setting as SettingSettingData).stripe_credentials,
    },
    user: state.user.data || {},
  };
};

const mapDispatchToProps = {
  getInvoice,
  makePayment,
  exportInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);
