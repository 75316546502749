import React, { useEffect } from "react";

import { Alert } from "reactstrap";

import { UserDataState } from "../../../types";

import { InfiniteScroll } from "..";

import { CommentUserListCard, CommentSeenByPlaceholder } from ".";

export type CommentSeenByProps = {
  data?: UserDataState[];
  error: string | null;
  hasMore?: boolean;
  loading: boolean;
  refreshing: boolean;

  reFetch: (next: boolean, refresh: boolean, callback?: () => void) => void;
};

export const CommentSeenBy: React.FC<CommentSeenByProps> = ({
  data,
  /**
   * @TODO Implement error handling.
   * Missing functionality both in native and web.
   */
  error,
  hasMore,
  loading: isLoading,
  refreshing: isRefreshing,

  reFetch,
}) => {
  /** Refreshing data when component mounting */
  useEffect(() => {
    reFetch(false, true);
  }, [reFetch]);

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const errorElement = error && <Alert color="warning">{error}</Alert>;

  const cardElements =
    data?.map(user => (
      <CommentUserListCard user={user} key={user.objectId} />
    )) || null;

  return (
    <>
      {errorElement}
      <InfiniteScroll
        hasMore={!!hasMore}
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        placeholder={<CommentSeenByPlaceholder />}
        onLoadMore={handleLoadMore}>
        {cardElements}
      </InfiniteScroll>
    </>
  );
};
