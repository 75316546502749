import React from "react";
import { Link } from "react-router-dom";
import { Card, CardTitle, CardText } from "reactstrap";

import { NotificationLandingMessageWithAuthor } from "../../../types/Notification";
import { fromNow } from "../../../lib/utils";

type Props = {
  item: NotificationLandingMessageWithAuthor;
};
export const NotificationLandingSideBarCard: React.FC<Props> = ({ item }) => {
  const { screen, id } = item.target;

  const dates = fromNow(item.date);

  const name = item.author && item.author.name;

  return (
    <Card
      id={`notificationLandingSideBarCard${item.target.id}`}
      className="mb-3 bg-transparent px-2 pb-2 border-bottom">
      <CardTitle className="font-italic mb-1">
        <Link to={`/${screen}/${id}`}>
          <small>{item.title}</small>
        </Link>
      </CardTitle>
      <CardText>
        <small className="text-muted text-small">{`${name} - ${dates}`}</small>
      </CardText>
    </Card>
  );
};
