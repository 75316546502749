export const ANNOUNCEMENT_ALL_SET = "ANNOUNCEMENT_ALL_SET";
export const ANNOUNCEMENT_COMMENT_SET = "ANNOUNCEMENT_COMMENT_SET";
export const ANNOUNCEMENT_DRAFT_ALL_SET = "ANNOUNCEMENT_DRAFT_ALL_SET";
export const ANNOUNCEMENT_DRAFT_SET = "ANNOUNCEMENT_DRAFT_SET";
export const ANNOUNCEMENT_PIN_SET = "ANNOUNCEMENT_PIN_SET";
export const ANNOUNCEMENT_PUBLISHED_SET = "ANNOUNCEMENT_PUBLISHED_SET";
export const ANNOUNCEMENT_REACTION_SET = "ANNOUNCEMENT_REACTION_SET";
export const ANNOUNCEMENT_RESET = "ANNOUNCEMENT_RESET";
export const ANNOUNCEMENT_SEARCH_HISTORY_SET =
  "ANNOUNCEMENT_SEARCH_HISTORY_SET";
export const ANNOUNCEMENT_SEARCH_SET = "ANNOUNCEMENT_SEARCH_SET";
export const ANNOUNCEMENT_SET = "ANNOUNCEMENT_SET";
export const ANNOUNCEMENT_TYPE_SET = "ANNOUNCEMENT_TYPE_SET";
