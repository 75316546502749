import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { onboarding } from "../../constants";
import { track } from "../../lib/track";
import { MatchProps } from "../../types/MatchProps";
import { RootState } from "../../types";
import {
  /** @TODO Why this type is not placed in separate file? */
  AppIntro,
  SettingSettingData,
} from "../../types/Setting/SettingSettingData";

type StateProps = {
  data: AppIntro[];
};

type Props = {
  /** @TODO Missing layout typing! */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Layout: ComponentType<any>;
} & StateProps &
  MatchProps;

type State = {};

class Onboarding extends Component<Props, State> {
  componentDidMount(): void {
    track("View Screen", {
      Screen: "introduce",
      Params: this.props.match && this.props.match.params,
    });
  }

  render(): JSX.Element {
    const { Layout, data } = this.props;
    return <Layout data={data} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => {
  const { setting } = state.setting;
  const appIntro = (setting as SettingSettingData).app_intro || onboarding;
  return {
    data: appIntro,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
