import React from "react";

import { RouteComponentProps, useRouteMatch } from "react-router-dom";

import PreviewContainer from "../../../containers/Preview";

import { UserDataState } from "../../../types";

import {
  AnnouncementDetail,
  AppProtected,
  TemplateAppProtected,
  TemplateAppPreview,
} from "../../components";
import { ChannelsSidebar, ChatSettingsSidebar } from "../../components/Chat";

export type RouteAppProtectedComponentProps = RouteComponentProps & {
  user: UserDataState;
};

export const RouteAppProtectedComponent: React.FC<RouteAppProtectedComponentProps> = ({
  user,
}) => {
  /**
   * Following route is an exception - it is the only one which is not rendered inside
   * TemplateAppProtected but in TemplateApp
   */
  const previewMatch = useRouteMatch<{
    content?: "announcement" | string;
    contentId?: string;
    token?: string;
  }>("/preview/:token/:content/:contentId");

  if (
    previewMatch?.params.contentId &&
    previewMatch?.params.token &&
    previewMatch?.params.content === "announcement"
  ) {
    return (
      <TemplateAppPreview>
        <PreviewContainer
          Layout={AnnouncementDetail}
          type="announcement"
          id={previewMatch.params.contentId}
        />
      </TemplateAppPreview>
    );
  }

  const customConfig = {
    leftCol: [
      {
        path: ["/chat-room-detail/:channel"],
        component: ChannelsSidebar,
      },
    ],
    rightCol: [
      {
        path: ["/chat-room-detail/:channel"],
        component: ChatSettingsSidebar,
      },
    ],
    footer: {
      disableOnPaths: ["/chat-room-detail/:channel"],
    },
  };

  return (
    <TemplateAppProtected user={user}>
      <AppProtected customConfig={customConfig} user={user} />
    </TemplateAppProtected>
  );
};
