export const getArrayWithUpdatedValue = <T extends { id: string }>(
  value: T,
  data: T[]
): T[] => {
  const newCommentsArray = [...data];
  const itemIndex = newCommentsArray.findIndex(x => x.id === value.id);

  newCommentsArray[itemIndex] = value;

  return newCommentsArray;
};
