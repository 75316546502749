import { isEmpty } from "underscore";

import {
  USER_INDUSTRY_SET,
  USER_LOGIN_SET,
  USER_NOTIFICATION_SETTINGS_SET,
  USER_RESET,
  USER_SESSION_SET,
  USER_SET,
} from "./constants";
import Store from "./store";
import { UserAction, UserState } from "./types";

export const initialState = Store;

export default function reducer(
  state: UserState = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case USER_RESET:
      return initialState;

    case USER_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          data: action.data,
        };
      }

      return state;

    case USER_NOTIFICATION_SETTINGS_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          notificationSettings: action.data,
        };
      }

      return state;

    case USER_LOGIN_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          login: action.data,
        };
      }

      return state;

    case USER_INDUSTRY_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          industry: action.data,
        };
      }

      return state;

    case USER_SESSION_SET:
      return {
        ...state,
        originalSessionToken: action.data,
        viewingAsGuest: !isEmpty(action.data),
      };

    default:
      return state;
  }
}
