import React from "react";

import Skeleton from "react-loading-skeleton";

import { useTheme } from "../../hooks";

export type CommentUserListCardPlaceholderProps = {
  showRight?: boolean;
};

export const CommentUserListCardPlaceholder: React.FC<CommentUserListCardPlaceholderProps> = ({
  showRight,
}) => {
  const {
    list: { backgroundColor },
  } = useTheme();

  const rightElement = showRight && (
    <div className="ml-auto d-flex">
      <Skeleton circle={true} width={20} height={20} className="m-2" />
    </div>
  );

  return (
    <div className="d-flex position-relative mb-1" style={{ backgroundColor }}>
      <div>
        <Skeleton circle={true} width={35} height={35} className="m-2" />
      </div>
      <div className="ml-3 mt-1 mb-2 d-flex">
        <p className="align-self-center my-0">
          <Skeleton width={150} />
        </p>
      </div>
      {rightElement}
    </div>
  );
};
