import { convertObject } from "../../../lib/utils";
import { UserDataState, MentionSearchUserInfo } from "../../../types";

import { runFunction } from "../../http";

export const searchUsers = async (option: {
  page: number;
  per: number;
  query: string;
  groupId?: string;
}): Promise<MentionSearchUserInfo[]> => {
  const parseResponse = await runFunction("apiGetUsers", option, {
    requireUser: true,
    spinner: false,
  });
  return convertObject(parseResponse).map((user: UserDataState) => {
    return {
      ...user,
      imageUrl: user.profile && user.profile.avatar,
    };
  });
};
