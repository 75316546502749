import React, { Component } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty, isEqual } from "underscore";

import {
  getAnnouncementTypes,
  getPinAnnouncements,
} from "../../../containers/Announcement/action";
import {
  clearNotificationAlert,
  connectChat,
  allowSettingHasMessages,
} from "../../../containers/Chat/action";
import ModalEulaContainer from "../../../containers/EULA";
import SubscriptionAlertContainer from "../../../containers/Subscription/SubscriptionAlert";
import { getMyGroups } from "../../../containers/Group/action";
import { getMediaSetting } from "../../../containers/Media/action";
import {
  getUnreadAnnouncement,
  getUnreadEvent,
  getUnreadPost,
  getUnreadNotifications,
  removeUnreadNotification,
} from "../../../containers/Notification/action";
import { getUnpaidInvoices } from "../../../containers/Payment/action";
import { sendActivity } from "../../../containers/User/action";
import { getGroupHome } from "../../../containers/Group/action";
import { checkCurrentSubscription } from "../../../containers/Subscription/action";

import { track } from "../../../lib/track";

import {
  EmptyObject,
  InvoiceData,
  NotificationAlertData,
  NotificationItem,
  RootState,
  SetIntervalReturn,
  UserDataState,
} from "../../../types";

import { ModalEula } from "../../components/Modal/ModalEula";
import { SubscriptionAlert } from "../../components/Subscription/SubscriptionAlert";
import { SwitchApp } from "../../routes";
import { registerAuthenticatedServices } from "../../services";

import { ConfirmModal, InAppNotificationModal } from "..";
import { getCurrentUserHasNullState } from "../../../containers/User/selector";
import { getAlertDataState } from "../../../containers/Notification/selector";
import { getSettingState } from "../../../containers/Setting/selector";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: RootState) => ({
  user: getCurrentUserHasNullState(state),
  setting: getSettingState(state),
  alert: getAlertDataState(state),
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = {
  clearNotificationAlert,
  connectChat,
  getAnnouncementTypes,
  getMediaSetting,
  getMyGroups,
  getPinAnnouncements,
  getUnpaidInvoices,
  getUnreadAnnouncement,
  getUnreadEvent,
  getUnreadNotifications,
  getUnreadPost,
  removeUnreadNotification,
  sendActivity,
  getGroupHome,
  checkCurrentSubscription,
  allowSettingHasMessages,
};

const connector = connect(mapState, mapDispatch);

type AppConnectedProps = ConnectedProps<typeof connector>;

type AppProps = AppConnectedProps & RouteComponentProps & WithTranslation;

type AppState = {
  allowModalOpen: boolean;
  registeredAuthenticatedServices: boolean;
  user?: UserDataState | null;
};

class AppToEnhance extends Component<AppProps, AppState> {
  intervalSendActivity?: SetIntervalReturn;
  intervalUnreadNotifications?: SetIntervalReturn;
  newInvoice?: InvoiceData | null;
  notification?: NotificationItem;
  showSubscriptionAlert?: boolean | void;
  showEULA?: boolean;

  state: AppState = {
    allowModalOpen: false,
    registeredAuthenticatedServices: false,
  };

  appIsActive = (active: boolean): void => {
    const { user, connectChat } = this.props;

    if (user) {
      if (active) {
        track("App Ready");
        this.sendActivity();
        this.getUnreadNotifications();
        connectChat();
      } else {
        track("App Pause");

        if (this.intervalSendActivity) {
          clearInterval(this.intervalSendActivity);
          clearInterval(this.intervalUnreadNotifications);
        }
      }
    }
  };

  componentDidMount = (): void => {
    const { user, registeredAuthenticatedServices } = this.state;

    if (user && !registeredAuthenticatedServices) {
      this.registerAuthenticatedServices(user);
    }
  };

  componentDidUpdate = (): void => {
    const { user, registeredAuthenticatedServices } = this.state;

    if (user && !registeredAuthenticatedServices) {
      this.registerAuthenticatedServices(user);
    }
  };

  static getDerivedStateFromProps(
    nextProps: AppProps,
    prevState: AppState
  ): Partial<AppState> | null {
    if (nextProps.user && !prevState.user) {
      return { user: nextProps.user };
    }

    if (!nextProps.user && prevState.user) {
      return {
        user: null,
        registeredAuthenticatedServices: false,
      };
    }

    return null;
  }

  /**
   * @FIXME
   * This part needs to be completely rewritten. It causes many
   * unnecessary rerenders. Alerts (and maybe toasts) should be
   * probably rendered in some lower layer to avoid rerendering
   * SwitchApp every time it changes. Then App could update only
   * if user or setting changed. Probably needs deeper props
   * comparison.
   */
  shouldComponentUpdate = (nextProps: AppProps): boolean => {
    if (
      !isEmpty(this.notification || this.newInvoice) ||
      this.showEULA ||
      this.showSubscriptionAlert
    ) {
      return true;
    }

    if (!isEqual(nextProps.setting, this.props.setting)) {
      return true;
    }

    if (!isEqual(nextProps.user, this.props.user)) {
      return true;
    }

    if (!isEmpty(nextProps.alert)) {
      this.showAlert(nextProps.alert);
      this.props.clearNotificationAlert();
    }

    return false;
  };

  registerAuthenticatedServices = async (
    user: UserDataState
  ): Promise<void> => {
    this.setState({ registeredAuthenticatedServices: true });

    const {
      setting,
      getAnnouncementTypes,
      getMediaSetting,
      getMyGroups,
      getPinAnnouncements,
      getUnpaidInvoices,
      getUnreadAnnouncement,
      getUnreadEvent,
      getUnreadNotifications,
      getUnreadPost,
      getGroupHome,
      checkCurrentSubscription,
    } = this.props;

    /**
     * @FIXME
     * saveInstallation is not an action. Doesn't dispatch anything.
     * Not consistent registerAuthenticatedServices API between web
     * and native.
     */
    user &&
      registerAuthenticatedServices(
        user,
        setting
        // , saveInstallation
      );

    this.appIsActive(true);

    /**
     * @FIXME
     * I think we should consider creating new action creators to reshare
     * this logic with native app.
     *
     * Phuong introduced state property `ready` and its used in condition
     * during route rendering. It is false by default...
     */
    await Promise.all([
      getGroupHome(),
      getMediaSetting(),
      getAnnouncementTypes(),
      getPinAnnouncements(true),
    ]);

    await getMyGroups(true);

    /**
     * @FIXME
     * I don't know if actions below are dependent on action above...?
     */
    await Promise.all([
      getUnreadAnnouncement(),
      getUnreadEvent(),
      getUnreadPost(),
    ]);

    this.showEULA =
      (user &&
        !user?.user_agreement &&
        !isEmpty(setting?.setting?.user_agreement)) ||
      false;

    if (!this.showEULA) {
      this.notification = await getUnreadNotifications();
      this.newInvoice = await getUnpaidInvoices();
      this.showSubscriptionAlert = await checkCurrentSubscription();
    }

    this.setState({ allowModalOpen: true });
  };

  sendActivity = (): void => {
    const { sendActivity, allowSettingHasMessages } = this.props;

    const onSendActivity = async (): Promise<void> => {
      sendActivity({
        isDevice: true,
        badge: 0,
        enabledPushNotification: true,
        allowSettingHasMessages: await allowSettingHasMessages(),
      });
    };

    onSendActivity();

    this.intervalSendActivity = setInterval(onSendActivity, 3 * 60 * 1000);
  };

  getUnreadNotifications = (): void => {
    const { getUnreadNotifications } = this.props;

    const onUnreadNotifications = (): void => {
      getUnreadNotifications();
    };

    this.intervalUnreadNotifications = setInterval(
      onUnreadNotifications,
      5 * 60 * 1000
    );
  };

  showAlert = (data: EmptyObject | NotificationAlertData): void => {
    const { text, title, id } = data;
    const { history } = this.props;

    const msg = (): JSX.Element => {
      return (
        <div>
          <h5>{title}</h5>
          <p className={"m-0"}>{text}</p>
        </div>
      );
    };
    toast.success(msg, {
      onClick: () => history.push(`/chat-room-detail/${id}`),
    });
  };

  render = (): React.ReactElement => {
    const { t, history, removeUnreadNotification, setting } = this.props;

    const { allowModalOpen } = this.state;

    const handleShowInvoiceDetails = (): void => {
      history.push(`/payment-receipt-detail/${this.newInvoice?.id}`);
    };

    const toggleModal = (): void => {
      this.setState({ allowModalOpen: !this.state.allowModalOpen });
      if (this.notification) {
        removeUnreadNotification([this.notification.id], true);
      }
    };

    let modalElement;
    if (this.showEULA) {
      //open EULA popup if have
      modalElement = setting.setting && (
        <ModalEulaContainer
          data={setting.setting.user_agreement}
          Layout={ModalEula}
        />
      );
    } else if (this.notification) {
      //open in-app notification
      modalElement = (
        <InAppNotificationModal
          id="inAppNotificationModal"
          isOpen={allowModalOpen}
          toggle={toggleModal}
          title={this.notification.title}
          message={this.notification.text || this.notification.shortText}
          options={this.notification.options}
        />
      );
    } else if (this.newInvoice) {
      //open Unpaid invoice notification
      modalElement = (
        <ConfirmModal
          color={"info"}
          title={t("Modal:Invoice.Modal.Button.Payment")}
          confirmText={t("Modal:Invoice.Modal.Details")}
          dismissText={t("App:Index.Alert.Button.Close")}
          message={this.newInvoice.description}
          isOpen={allowModalOpen}
          onConfirm={handleShowInvoiceDetails}
          toggle={toggleModal}
        />
      );
    } else if (this.showSubscriptionAlert) {
      modalElement = <SubscriptionAlertContainer Layout={SubscriptionAlert} />;
    }

    return (
      <>
        <SwitchApp setting={setting} />
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {modalElement}
      </>
    );
  };
}

export const App = withRouter(connector(withTranslation()(AppToEnhance)));
