import {
  GROUPS_BY_TYPE_SET,
  GROUP_ALL_SET,
  GROUP_MANAGEMENT_SET,
  GROUP_MY_SET,
  GROUP_PENDING_SET,
  GROUP_POSTING_SET,
  GROUP_RESET,
  GROUP_SET,
  GROUP_SETTING_SET,
  GROUP_STATUS_SET,
  GROUP_TYPES_SET,
  GROUP_TYPE_SET,
  GROUP_USER_SET,
  GROUP_SUGGESTED_POSTING_SET,
  GROUP_TARGET_SET,
  GROUP_HOME_SET,
} from "./constants";
import Store from "./store";
import { GroupState, GroupAction } from "./types";

export const initialState = Store;

/**
 * @TODO Please notice that in most of actions we are changing the state when
 * action data exists. Probably we could do the same condition in actions and
 * NOT DISPATCH AN ACTION if action.data does not exist!
 */
export const groupReducer = (
  state: GroupState = initialState,
  action: GroupAction
): GroupState => {
  switch (action.type) {
    case GROUP_RESET:
      return initialState;

    case GROUP_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          cached: {
            ...state.cached,
            [action.data.id]: action.data,
          },
        };
      }

      return state;

    case GROUP_USER_SET:
      if (action.data?.userId) {
        const { userId, data } = action.data;

        return {
          ...state,
          error: null,
          users: {
            ...state.users,
            [userId]: data,
          },
        };
      }

      return state;

    case GROUP_ALL_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          items: action.data,
        };
      }

      return state;

    case GROUP_PENDING_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          pending: action.data,
        };
      }

      return state;

    case GROUP_TYPE_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          type: action.data,
        };
      }

      return state;

    case GROUP_STATUS_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          status: action.data,
        };
      }

      return state;

    case GROUP_MY_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          myGroups: action.data,
        };
      }

      return state;

    case GROUP_POSTING_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          groupsForPosting: action.data,
        };
      }

      return state;

    case GROUP_TARGET_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          groupsForTarget: action.data,
        };
      }

      return state;

    case GROUP_SUGGESTED_POSTING_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          groupsSuggestedForPosting: action.data,
        };
      }

      return state;

    case GROUPS_BY_TYPE_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          groupsByType: action.data,
        };
      }

      return state;

    case GROUP_SETTING_SET:
      if (action.data) {
        const { id, data } = action.data;
        return {
          ...state,
          error: null,
          setting: {
            ...state.setting,
            [id]: data,
          },
        };
      }

      return state;

    case GROUP_MANAGEMENT_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          management: action.data,
        };
      }

      return state;

    case GROUP_TYPES_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          types: action.data,
        };
      }

      return state;

    case GROUP_HOME_SET:
      return {
        ...state,
        error: null,
        groupHome: action.data || null,
      };

    default:
      return state;
  }
};

export default groupReducer;
