import Parse from "parse";

import { convertObject } from "../../../lib/utils";

import { runQuery } from "../../http";
import { ResourceItem } from "../../../types/Resource";

/**
 * @TODO Arguments need better typing
 */
export const getResource = async (
  id: string
): Promise<ResourceItem | undefined> => {
  console.debug("[ServiceApi] getResource");

  const response = await runQuery(
    "ContentPage",
    (query: Parse.Query<Parse.Object<Parse.Attributes>>) => {
      query.equalTo("objectId", id);
      query.include(["video", "parent", "parent.parent"]);
      return query.first();
    },
    { requireUser: true }
  );

  const converted = {
    ...convertObject(response),
    id: id,
  };

  return converted;
};
