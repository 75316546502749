import React from "react";

import { Alert } from "reactstrap";

import {
  CommentType,
  EmptyObject,
  PostGroup,
  PostItem,
  ReactionType,
  UserDataState,
} from "../../../../types";

import { LoadingSpinner } from "../../UI";

import { PostCard } from "..";

export type PostDetailProps = {
  error: string | null;
  item: PostItem | undefined;
  loading: boolean;
  refreshing: boolean;
  setting: {
    ClientHostName?: string;
    confettiEffectActions: [];
  };
  user: UserDataState | EmptyObject;

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  reFetch: (refresh: boolean, callback?: () => void) => Promise<void>;
  removePost: (
    post: { id: string; group: PostGroup & { id?: string } },
    callback?: (text: string) => void
  ) => Promise<void>;
};

export const PostDetail: React.FC<PostDetailProps> = ({
  error,
  item,
  // loading,
  // refreshing,
  setting,
  user,

  doReaction,
  // reFetch,
  removePost,
}) => {
  if (!item) {
    if (error) {
      return <Alert color="danger">{error}</Alert>;
    }

    return <LoadingSpinner />;
  }

  return (
    <PostCard
      item={item}
      doReaction={doReaction}
      isMixed={false}
      setting={setting}
      removePost={removePost}
      user={user}
      isDetail={true}
    />
  );
};
