/**@FIXME
 * Copied from card-validator node module.
 * Temporary solution, until TS version will be upgraded to 3.8 or above.
 * Reason is "import type" feature in >= 3.8
 * https://github.com/braintree/card-validator */
export type Verification = {
  isValid: boolean;
};

export type ExpirationYearVerification = {
  isCurrentYear?: boolean;
} & Verification;

export type ExpirationMonthVerification = {
  isValidForThisYear: boolean;
} & Verification;

export type MonthAndYear = {
  month: string;
  year: string;
};

export const PayExpirationDateValidator = (value: string): boolean => {
  const parseDate = (datestring: string): MonthAndYear => {
    const date = datestring.split(/\s*\/\s*/g);

    return {
      month: date[0] || "",
      year: date.slice(1).join(),
    };
  };

  const date = parseDate(String(value));

  const expirationMonth = (value: string): ExpirationMonthVerification => {
    const currentMonth = new Date().getMonth() + 1;

    const month = parseInt(value, 10);

    const result = month > 0 && month < 13;

    return {
      isValid: result,
      isValidForThisYear: result && month >= currentMonth,
    };
  };

  const expirationYear = (value: string): ExpirationYearVerification => {
    const len = value.length;

    if (len < 2) {
      return {
        isValid: false,
      };
    }

    const currentYear = new Date().getFullYear();

    const numericValue = parseInt(value, 10);
    const twoDigitYear = Number(String(currentYear).substr(2, 2));

    const isCurrentYear = twoDigitYear === numericValue;
    const valid = numericValue >= twoDigitYear;

    return {
      isValid: valid,
      isCurrentYear: isCurrentYear,
    };
  };

  const monthValid = expirationMonth(date.month);
  const yearValid = expirationYear(date.year);

  if (monthValid.isValid) {
    if (yearValid.isCurrentYear) {
      return monthValid.isValidForThisYear;
    }

    if (yearValid.isValid) {
      return true;
    }
  }

  return false;
};
