import { contains } from "underscore";

export const getEventLocationType = (
  locationType?: string,
  locationDetails?: {
    phone_number?: string;
    audio_details?: string;
    video_url?: string;
    video_details?: string;
  }
): {
  notAddressType: boolean;
  locationContent: {
    body?: string;
    icon?: string;
    title?: string;
  };
} => {
  const notAddressType = contains(["video", "audio"], locationType);
  let locationContent = {};
  if (locationType === "audio") {
    locationContent = {
      title: locationDetails?.phone_number || "",
      body: locationDetails?.audio_details || "",
      icon: "phone",
    };
  } else if (locationType === "video") {
    locationContent = {
      title: locationDetails?.video_url || "",
      body: locationDetails?.video_details || "",
      icon: "video-camera",
    };
  }
  return {
    notAddressType,
    locationContent,
  };
};
