import React, { useState } from "react";

import { TabContent, Nav, NavItem, NavLink, TabPane } from "reactstrap";

import { StickyNavbar } from "..";

import { MembersListWithFilterTabs } from ".";
import MembersContainer from "../../../containers/Member";

import { Map } from "../Map";
import MapContainer from "../../../containers/Map";
import { useTheme } from "../../hooks";
import { Tab } from "../../../containers/Member/types";

export type MemberMixedProps = {
  tabs: Tab[];
};

export const MemberMixed: React.FC<MemberMixedProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState("members");

  const isActiveTab = (tab: string): boolean => activeTab === tab;

  const toggleTab = (tab: string): void => {
    if (!isActiveTab(tab)) setActiveTab(tab);
  };

  const {
    tabTop: { inactive, inactiveText, underlineColor },
  } = useTheme();

  const styles = {
    tabLink: {
      backgroundColor: inactive.backgroundColor,
      color: inactiveText.color,
      opacity: inactiveText.opacity,
    },
    tabLinkActive: {
      opacity: 1,
      borderColor: underlineColor,
    },
  };

  const tabElements = tabs?.map(({ id, title }) => (
    <NavItem id={`memberMixed${title.replace(/\s/g, "")}Tab`} key={id}>
      <NavLink
        active={isActiveTab(id)}
        style={{
          ...styles.tabLink,
          ...(isActiveTab(id) && styles.tabLinkActive),
        }}
        onClick={(): void => {
          toggleTab(id);
        }}>
        {title}
      </NavLink>
    </NavItem>
  ));

  const tabContentElements = tabs?.map(({ id }) => (
    <TabPane key={id} tabId={id}>
      {id === "members" && (
        <MembersContainer
          Layout={MembersListWithFilterTabs}
          tabs={tabs}
          forceRefresh={true}
          isMemberMixedLayout={true}
        />
      )}
      {id === "maps" && (
        <MapContainer
          isMemberMixedLayout={true}
          match={{ params: { types: ["profile"] } }}
          Layout={Map}
        />
      )}
    </TabPane>
  ));

  return (
    <div>
      <StickyNavbar>
        <Nav tabs>{tabElements}</Nav>
      </StickyNavbar>

      <TabContent activeTab={activeTab}>{tabContentElements}</TabContent>
    </div>
  );
};
