import React from "react";

import { useTranslation } from "react-i18next";
import { Alert, CardBody, Button } from "reactstrap";
import { sortBy } from "underscore";

import { EventAttendee } from "../../../types";

import { useTheme } from "../../hooks";

import { MemberCard, LoadingSpinner, Empty } from "..";

export type EventDetailTabsAttendeesProps = {
  id?: string;
  error?: string;
  data?: EventAttendee[];
  openEventAttendeesMap: () => void;
};

/** @FIXME Should we implement refreshing? */
export const EventDetailTabsAttendees: React.FC<EventDetailTabsAttendeesProps> = ({
  id,
  data,
  error,

  openEventAttendeesMap,
}) => {
  const { t } = useTranslation("Event");
  const { button } = useTheme();

  const eventDetailTabsAttendeesStyles = {
    button: {
      ...button,
      height: 40,
    },
  };

  const tabContent = !data ? (
    error ? (
      <Alert color="danger">{error}</Alert>
    ) : (
      <LoadingSpinner />
    )
  ) : data.length === 0 ? (
    <Empty
      id={`noAttendeesInfo${id}`}
      text={t("Attendees.Text.No.Members.Signed")}
    />
  ) : (
    <>
      {/** @TODO Attendees sorting should be moved to reducer */
      sortBy(
        data.map(att => att.user),
        "lastName"
      ).map((user, index) => (
        /**
         * @FIXME
         * MemberCard is not displaying user's address and company's name
         * Probably it should be props dependent as in native app.
         */
        <MemberCard key={index} item={user} className="mb-3" />
      ))}
      <div className="d-flex justify-content-end my-2">
        <Button
          style={eventDetailTabsAttendeesStyles.button}
          id={`eventDetailAtendeesLocationPinIcon${id}`}
          block
          onClick={openEventAttendeesMap}>
          Show location of attendees
        </Button>
      </div>
    </>
  );

  return (
    <CardBody id={`eventDetailAttendeesTabList${id}`} className="p-3">
      {tabContent}
    </CardBody>
  );
};
