import React from "react";

import { useTranslation } from "react-i18next";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Alert,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  NavLink,
} from "reactstrap";
import { isUndefined } from "underscore";

import { MediaSet, NotificationsSet } from "../../../containers/User";
import { localConfig } from "../../../constants/config";

import { UserDataState, UserFeature, SettingState } from "../../../types";
import { ExportMembersResult } from "../../../services/api";

import { useUserDispatchLogout, useTheme } from "../../hooks";

import { LoadingSpinner, Icon } from "..";

export type UserSettingProps = {
  appVersion?: string;
  error: string | null;
  feature?: UserFeature;

  loggingOut?: boolean;
  mediaSetting: {
    [key in MediaSet]?: boolean;
  };
  notificationSettings: {
    [key in NotificationsSet]?: number;
  };
  notificationSettingsDefault: {
    [key in NotificationsSet]: number;
  };
  notificationSettingsOptions: {
    [key in NotificationsSet]: { value: number; key: string }[];
  };
  exportMembersResult: ExportMembersResult;
  refreshing: boolean;
  sending?: boolean;
  setting: SettingState;
  user: {} | UserDataState;

  changeMediaSetting: (name: MediaSet, value: boolean) => void;
  exportMembers: (callback: (url: string) => void) => Promise<void>;
  onChangeNotificationSettings: (name: NotificationsSet, value: number) => void;
  logOut: (fromWeb: boolean) => void;
};

export const UserSetting: React.FC<UserSettingProps> = ({
  error,
  loggingOut,
  notificationSettings,
  notificationSettingsDefault,
  notificationSettingsOptions,
  sending,
  exportMembersResult,
  exportMembers,
  onChangeNotificationSettings,
  setting,
}) => {
  const { t } = useTranslation("User");
  const {
    button: {
      backgroundColor,
      text: { color },
    },
  } = useTheme();

  const styles = {
    header: {
      backgroundColor,
      color,
    },
  };

  const logout = useUserDispatchLogout();

  const handleLogout = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    logout();
  };

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  const userAccountSettingsElement = (
    <div className="pt-3">
      <ListGroup>
        <ListGroupItem className="py-3" style={styles.header}>
          {t("Setting.Account")}
        </ListGroupItem>
        <ListGroupItem>
          <NavLink
            className="px-0 py-0"
            title={t("Setting.Edit.Profile")}
            id="editProfile"
            tag={RouterNavLink}
            to="/edit-profile">
            <span>{t("Setting.Edit.Profile")}</span>
          </NavLink>
        </ListGroupItem>
        <ListGroupItem>
          <NavLink
            className="px-0 py-0"
            title={t("Setting.Change.Passcode")}
            id="resetPasscode"
            tag={RouterNavLink}
            to="/reset-passcode">
            <span>{t("Setting.Change.Passcode")}</span>
          </NavLink>
        </ListGroupItem>
      </ListGroup>
    </div>
  );

  const groupPushNotifications = isUndefined(
    notificationSettings.groupPushNotifications
  )
    ? notificationSettingsDefault.groupPushNotifications
    : notificationSettings.groupPushNotifications;

  const groupMailNotifications = isUndefined(
    notificationSettings.groupMailNotifications
  )
    ? notificationSettingsDefault.groupMailNotifications
    : notificationSettings.groupMailNotifications;

  const mailMessageNotifications = isUndefined(
    notificationSettings.mailMessageNotifications
  )
    ? notificationSettingsDefault.mailMessageNotifications
    : notificationSettings.mailMessageNotifications;

  const handleNotificationChange = (
    name: NotificationsSet,
    value: number
  ) => (): void => {
    onChangeNotificationSettings(name, value);
  };

  const groupPushNotificationsElement = (
    <div id="PushNotifList">
      <ListGroupItem
        id="PushNotifListTitle"
        className="py-1 font-weight-bold rounded-0 border-0">
        {t("Setting.Heading.Push.Notifications")}
      </ListGroupItem>
      {notificationSettingsOptions.groupPushNotifications.map(item => (
        <ListGroupItem
          id={`PushNotifListRadio${item.value}`}
          className="border-0"
          disabled={sending}
          key={item.value}
          onClick={handleNotificationChange(
            "groupPushNotifications",
            item.value
          )}>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  onClick={handleNotificationChange(
                    "groupPushNotifications",
                    item.value
                  )}
                  checked={item.value === groupPushNotifications}
                />
                {item.key}
              </Label>
            </FormGroup>
          </Form>
        </ListGroupItem>
      ))}
    </div>
  );

  const groupMailNotificationsElement = (
    <div id="emailNotifList" className="mt-1">
      <ListGroupItem
        id="emailNotifListTitle"
        className="py-1 font-weight-bold border-0">
        {t("Setting.Heading.Email.Notifications")}
        <p className="text-muted text-small pt-2">
          {t("Setting.Heading.Email.Notifications.Posts")}
        </p>
      </ListGroupItem>

      {notificationSettingsOptions.groupMailNotifications.map(item => (
        <ListGroupItem
          id={`emailNotifListRadio${item.value}`}
          className="border-0"
          disabled={sending}
          key={item.value}
          onClick={handleNotificationChange(
            "groupMailNotifications",
            item.value
          )}>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  onClick={handleNotificationChange(
                    "groupMailNotifications",
                    item.value
                  )}
                  checked={item.value === groupMailNotifications}
                />
                {item.key}
              </Label>
            </FormGroup>
          </Form>
        </ListGroupItem>
      ))}
    </div>
  );

  const mailMessageNotificationsElement = (
    <div id="emailMessNotifList" className="mt-1">
      <ListGroupItem
        id="emailMessNotifListTitle"
        className="py-1 font-weight-bold border-0">
        {t("Setting.Heading.Notifications.Message")}
        <p className="text-muted text-small pt-2">
          {t("Setting.Heading.Email.Notifications.Posts")}
        </p>
      </ListGroupItem>

      {notificationSettingsOptions.mailMessageNotifications.map(item => (
        <ListGroupItem
          id={`emailMessNotifListRadio${item.value}`}
          className="border-0"
          disabled={sending}
          key={item.value}
          onClick={handleNotificationChange(
            "mailMessageNotifications",
            item.value
          )}>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  onClick={handleNotificationChange(
                    "mailMessageNotifications",
                    item.value
                  )}
                  checked={item.value === mailMessageNotifications}
                />
                {item.key}
              </Label>
            </FormGroup>
          </Form>
        </ListGroupItem>
      ))}
    </div>
  );

  const notificationSettingsElement = (
    <div className="pt-3">
      <ListGroup>
        <ListGroupItem className="py-3" style={styles.header}>
          {t("Setting.Heading.Notifications")}
        </ListGroupItem>
        {groupPushNotificationsElement}
        {groupMailNotificationsElement}
        {mailMessageNotificationsElement}
      </ListGroup>
    </div>
  );

  const handleExport = (e: React.MouseEvent): void => {
    e.preventDefault();
    exportMembers(url => {
      function downloadFile(absoluteUrl: string): void {
        const link = document.createElement("a");
        link.href = absoluteUrl;
        link.download = "true";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      downloadFile(url);
    });
  };

  const exportMemberData = setting.setting?.directory_export_enabled && (
    <div className="pt-3">
      <ListGroup>
        <ListGroupItem className="py-3" style={styles.header}>
          {t("Setting.ExportData")}
        </ListGroupItem>

        <ListGroupItem style={{ cursor: "pointer" }} onClick={handleExport}>
          {t("Setting.ExportData.Members")}

          <div className="float-right">
            {exportMembersResult?.processing ? (
              <>
                <strong>{exportMembersResult.progress}% </strong>
                <LoadingSpinner size="sm" className="d-inline mx-2" />
              </>
            ) : (
              <Icon name="download" type="FontAwesome" style={{ height: 18 }} />
            )}
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
  );

  const { version, build, git } = localConfig;
  const { branch, commit } = git || {};
  const appVersion = `${version}@${build} (${branch || ""}#${commit || ""})`;

  const appVersionElement = (
    <div className="pt-3">
      <ListGroup>
        <ListGroupItem className="py-3" style={styles.header}>
          {t("Setting.Heading.App")}
        </ListGroupItem>
        <ListGroupItem className="py-3">
          {t("Setting.Heading.Version")}
          <div className="float-right">{appVersion}</div>
        </ListGroupItem>
      </ListGroup>
    </div>
  );

  const signOutElement = (
    <ListGroup className="mt-2">
      <ListGroupItem
        className="py-3"
        style={{ cursor: "pointer" }}
        onClick={handleLogout}>
        <strong>{t("Setting.Sign.Out")}</strong>
      </ListGroupItem>
    </ListGroup>
  );

  if (loggingOut) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {userAccountSettingsElement}
      {notificationSettingsElement}
      {exportMemberData}
      {appVersionElement}
      {signOutElement}
    </>
  );
};
