import { PaymentState } from "./types";

export const paymentStateInit: PaymentState = {
  invoiceList: {
    items: null,
    page: 0,
    hasMore: true,
  },
  receiptList: {
    items: null,
    page: 0,
    hasMore: true,
  },
};

export default paymentStateInit;
