import React from "react";
import { RouteComponentProps } from "react-router-dom";

import NotificationListContainer from "../../../containers/Notification";

import { NotificationList } from "../../components/Notification";

export type RouteNotificationListProps = RouteComponentProps;

export const RouteNotificationList: React.FC<RouteNotificationListProps> = props => (
  <NotificationListContainer {...props} Layout={NotificationList} />
);
