import React, { ChangeEvent } from "react";

import { useTranslation } from "react-i18next";
import {
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  Dict,
  UserExecutiveAssistant,
  UserExecutiveAssistantType,
} from "../../../../types";
import { LoadingSpinner } from "../../UI";

export type UserProfileExecutiveAssistantInfoProps = {
  data: UserExecutiveAssistantType | null;
  isExecutiveEmailValid: boolean;
  isExecutivePhoneValid: boolean;
  visibilities: Dict<boolean, UserExecutiveAssistant>;
  templateAssistant?: string[];

  emailOtherValidate: (email: string) => boolean;
  onExecutiveInfoChange: (name: string, value: string) => void;
  onVisibilityChange: (name: string, value: string) => void;
  phoneOtherValidate: (phone: string) => boolean;
  setExecutiveEmailValid: (value: boolean) => void;
  setExecutivePhoneValid: (value: boolean) => void;
};

export const UserProfileExecutiveAssistantInfo: React.FC<UserProfileExecutiveAssistantInfoProps> = ({
  data,
  isExecutiveEmailValid,
  isExecutivePhoneValid,
  templateAssistant,
  visibilities,

  emailOtherValidate,
  onExecutiveInfoChange,
  onVisibilityChange,
  phoneOtherValidate,
  setExecutiveEmailValid,
  setExecutivePhoneValid,
}) => {
  const { t } = useTranslation("User");

  if (!data) {
    return <LoadingSpinner />;
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onExecutiveInfoChange("Phone", e.target.value);
    const isPhoneValid = phoneOtherValidate(e.target.value);
    setExecutivePhoneValid(isPhoneValid);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onExecutiveInfoChange("Email", e.target.value);
    const isEmailValid = emailOtherValidate(e.target.value);
    setExecutiveEmailValid(isEmailValid);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onExecutiveInfoChange(
      e.target.name as keyof UserExecutiveAssistantType,
      e.target.value
    );
  };

  const handleVisibilityChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onVisibilityChange(
      e.target.name as keyof UserExecutiveAssistantType,
      (e.target.checked as unknown) as string
    );
  };

  const shouldDisplayElement = (
    element: keyof UserExecutiveAssistantType
  ): boolean | undefined => templateAssistant?.includes(element);

  const nameElement = shouldDisplayElement("Name") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileExecutiveAssistantInfoNameLabel">
            {t("Update.Profile.Executive.Info.Name")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileExecutiveAssistantInfoNameSwitch"
            name="Name"
            defaultChecked={visibilities["Name"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        type="text"
        name="Name"
        id="userEditProfileExecutiveAssistantInfoNameInput"
        placeholder={t("Update.Profile.Executive.Info.Name")}
        value={data.Name}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const phoneElement = shouldDisplayElement("Phone") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileExecutiveAssistantInfoPhoneLabel">
            {t("Update.Profile.Executive.Info.Phone")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileExecutiveAssistantInfoPhoneSwitch"
            name="Phone"
            defaultChecked={visibilities["Phone"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        invalid={!isExecutivePhoneValid}
        type="text"
        name="Phone"
        id="userEditProfileExecutiveAssistantInfoPhoneInput"
        placeholder={t("Update.Profile.Executive.Info.Phone")}
        value={data.Phone}
        onChange={handlePhoneChange}
      />
      {!isExecutivePhoneValid && (
        <FormFeedback>
          {t("Update.Profile.Personal.Info.Invalid.Phone")}{" "}
        </FormFeedback>
      )}
    </FormGroup>
  );

  const emailElement = shouldDisplayElement("Email") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileExecutiveAssistantInfoEmailLabel">
            {t("Update.Profile.Executive.Info.Email")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileExecutiveAssistantInfoEmailSwitch"
            name="Email"
            defaultChecked={visibilities["Email"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        invalid={!isExecutiveEmailValid}
        type="text"
        name="Email"
        id="userEditProfileExecutiveAssistantInfoEmailInput"
        placeholder={t("Update.Profile.Executive.Info.Email")}
        value={data.Email}
        onChange={handleEmailChange}
      />
      {!isExecutiveEmailValid && (
        <FormFeedback>
          {t("Update.Profile.Executive.Info.Invalid.Email")}
        </FormFeedback>
      )}
    </FormGroup>
  );

  return (
    <Form>
      {nameElement}
      {phoneElement}
      {emailElement}
    </Form>
  );
};
