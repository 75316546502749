import { Announcement } from "../../../types";

import { runFunction } from "../../http";

export const getUnreadAnnouncement = async (
  groups: { id: string }[]
): Promise<Announcement[]> => {
  console.debug("[Service] listUnreadAnnouncements");

  return runFunction(
    "listUnreadAnnouncements",
    { groups },
    { requireUser: true }
  );
};
