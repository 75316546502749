import React, { useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Popover, PopoverBody } from "reactstrap";
import { isEmpty } from "underscore";

import { track } from "../../../lib/track";
import { getEventLocation } from "../../../lib/utils";
import Variables from "../../../theme/variables/materialWeb";
import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

export type EventLocationProps = {
  city: string | undefined;
  className?: string;
  country: string | undefined;
  eventId?: string;
  eventTitle: string;
  state: string | undefined;
  streetAddress1: string | undefined;

  venue: string | undefined;

  onClick?: () => void;
};

export const EventLocation: React.FC<EventLocationProps> = ({
  city,
  className,
  country,
  eventId,
  eventTitle,
  state,
  streetAddress1,
  venue,

  onClick,
}) => {
  const { t } = useTranslation("Event");

  const history = useHistory();

  const {
    button,
    text: { listText },
  } = useTheme();

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  if (eventId === undefined) return null;

  const togglePopover = (): void => setPopoverOpen(!isPopoverOpen);

  const eventLocation = getEventLocation({
    city,
    country,
    state,
    streetAddress1,
    venue,
  });

  const eventLocationStyles = {
    locationButtons: {
      ...button,
      height: 30,
      borderColor: button.backgroundColor,
    },
    listText: {
      ...listText,
      ...textThemingWorkaround,
      paddingLeft: 0,
    },
    buttonText: {
      ...button.text,
      ...textThemingWorkaround,
      fontSize: 16,
      textAlign: undefined,
    },
    buttonCancel: {
      ...button,
      height: 30,
      backgroundColor: Variables.brandDanger,
      borderColor: Variables.brandDanger,
    },
  };

  const openEventMap = (): void => {
    if (eventLocation) {
      history.push(
        `/event-detail/${eventId}/map/${eventLocation?.address.replace(
          "/",
          "%2F"
        ) || eventLocation?.venue}`
      );

      /** @TODO This logic should be moved to container */
      track("Click Event Location", {
        "Event ID": eventId,
        "Event Title": eventTitle,
      });
    }
  };

  const handleClick = (): void => {
    openEventMap();
  };

  const isDetailView = /event-detail/.test(window.location.pathname);

  return eventLocation && !isEmpty(eventLocation) ? (
    <>
      <span
        id={`eventLocation${eventId}`}
        className={classnames(className, "cursor-pointer")}
        style={{ ...eventLocationStyles.listText, color: Variables.brandInfo }}>
        <i className="icon-location-pin my-2 mr-2" />
        {isDetailView
          ? `${eventLocation.venue} (${eventLocation.address})`
          : eventLocation.venue || eventLocation.address}
      </span>
      <Popover
        placement="bottom-start"
        isOpen={isPopoverOpen}
        target={`eventLocation${eventId}`}
        toggle={togglePopover}>
        <PopoverBody>
          <Button
            block
            onClick={handleClick}
            style={eventLocationStyles.locationButtons}>
            <span style={eventLocationStyles.buttonText}>
              {t("EventLocation.Button.Groupfire.Map")}
            </span>
          </Button>
        </PopoverBody>
        <PopoverBody>
          <Button
            className="d-flex align-items-center"
            block
            rel="noopener noreferrer"
            target="_blank"
            href={`https://maps.google.com?q=${eventLocation.address}`}
            style={eventLocationStyles.locationButtons}>
            <span style={eventLocationStyles.buttonText}>
              {t("EventLocation.Button.Google.Maps")}
            </span>
          </Button>
        </PopoverBody>
        <PopoverBody>
          <Button
            style={eventLocationStyles.buttonCancel}
            block
            onClick={togglePopover}>
            <span style={eventLocationStyles.buttonText}>
              {t("EventLocation.Button.Cancel")}
            </span>
          </Button>
        </PopoverBody>
      </Popover>
    </>
  ) : null;
};
