import React, { useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
} from "reactstrap";

import { eventDetailTabTypes } from "../../../constants";

import ChatDetailContainer from "../../../containers/Chat/Detail";

import {
  CommentResponse,
  CommentType,
  EventAttendee,
  EventDetailTabType,
  EventLocation,
  EventRecap,
  EventSingle,
  MediaImageBasic,
  ReactionCount,
  ReactionType,
} from "../../../types";

import { ChatConversation } from "../Chat/Conversation";

import {
  EventDetailTabsAttendees,
  EventDetailTabsDetails,
  EventDetailTabsPhotos,
} from ".";

export type EventDetailTabsProps = {
  attendees?: {
    total: number;
    newest: EventAttendee[];
    all: EventAttendee[];
  };
  clientHostName: string;
  commentCount: number;
  comments?: CommentResponse[];
  confettiEffectActions: [];
  event: EventSingle;
  eventLocation?: EventLocation;
  isLiked: boolean;
  isEditor: boolean;
  hideChat: boolean;
  likeCount: number;
  myAttendee?: EventAttendee;
  photos?: MediaImageBasic[];
  reaction?: ReactionType;
  reactionCount?: ReactionCount;
  recap: EventRecap | null;
  seenCount?: number;
  setting: {
    eventChatId?: string | false;
    rsvpEnabled?: boolean;
  };
  subtitle?: string;
  totalFees: number;

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  onAttendeeClick: () => void;
  openEventAttendeesMap: () => void;
};

export const EventDetailTabs: React.FC<EventDetailTabsProps> = ({
  attendees,
  clientHostName,
  commentCount,
  comments,
  confettiEffectActions,
  event,
  eventLocation,
  isLiked,
  isEditor,
  hideChat,
  likeCount,
  photos,
  reaction,
  reactionCount,
  recap,
  seenCount,
  setting,
  subtitle,
  totalFees,
  myAttendee,

  doReaction,
  onAttendeeClick,
  openEventAttendeesMap,
}) => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState<EventDetailTabType>(
    history.location.state?.activeTab ?? "Details"
  );

  const isActiveTab = (tab: EventDetailTabType): boolean => activeTab === tab;

  const toggleTab = (tab: EventDetailTabType): void => {
    if (!isActiveTab(tab)) setActiveTab(tab);
  };

  const { t } = useTranslation("Event");

  const onAttendeesClick = (): void => {
    setActiveTab("Attendees");
  };

  const tabs = {
    Details: true,
    Chat: !hideChat && setting.eventChatId,
    Attendees: true,
    Photos: photos && photos.length > 0,
  };

  const tabElements = eventDetailTabTypes.map(
    (tabType, index) =>
      !!tabs[tabType] && (
        <NavItem id={`event${tabType}Tab${event.id}`} key={index}>
          <NavLink
            className={classnames({ active: isActiveTab(tabType) })}
            onClick={(): void => {
              toggleTab(tabType);
            }}>
            {t(`Detail.Heading.${tabType}`)}
          </NavLink>
        </NavItem>
      )
  );

  const tabContentElement = (
    <TabContent activeTab={activeTab}>
      {!!tabs.Details && (
        <TabPane tabId="Details">
          <EventDetailTabsDetails
            attendees={attendees}
            clientHostName={clientHostName}
            commentCount={commentCount}
            comments={comments}
            confettiEffectActions={confettiEffectActions}
            event={event}
            eventLocation={eventLocation}
            isEditor={isEditor}
            isLiked={isLiked}
            isRsvpEnabled={setting.rsvpEnabled}
            likeCount={likeCount}
            myAttendee={myAttendee}
            reaction={reaction}
            reactionCount={reactionCount}
            recap={recap}
            seenCount={seenCount}
            showCommentBar={!setting.eventChatId}
            subtitle={subtitle}
            totalFees={totalFees}
            onAttendeeClick={onAttendeeClick}
            doReaction={doReaction}
            onAttendeesClick={onAttendeesClick}
          />
        </TabPane>
      )}
      {!!tabs.Chat && (
        <TabPane tabId="Chat">
          <CardBody>
            <ChatDetailContainer
              match={{
                params: { channel: event.eventChatId ?? "", text: "event" },
              }}
              Layout={ChatConversation}
            />
          </CardBody>
        </TabPane>
      )}
      {!!tabs.Photos && (
        /**
         * @TODO
         * I couldn't figure out how to add photos to event.
         * Looks like regular image list.
         */
        <TabPane tabId="Photos">
          <EventDetailTabsPhotos data={photos} />
        </TabPane>
      )}
      {!!tabs.Attendees && (
        <TabPane tabId="Attendees">
          <EventDetailTabsAttendees
            id={event.id}
            data={attendees?.all}
            openEventAttendeesMap={openEventAttendeesMap}
          />
        </TabPane>
      )}
    </TabContent>
  );

  return (
    <>
      <Nav tabs className="mt-3 px-3">
        {tabElements}
      </Nav>
      {tabContentElement}
    </>
  );
};
