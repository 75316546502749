import { AppItem, ReactionCount, ReactionType } from "../../../types";

export const getNextItemReactionCount = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  item,
  newReaction,
  dataKeyName,
}: {
  item: ItemType;
  newReaction: ReactionType;
  dataKeyName: DataKey;
}): ReactionCount => {
  const prevReaction = item.reaction;
  const prevReactionCount = item[dataKeyName].reactionCount || {};

  if (prevReaction === newReaction) return prevReactionCount;

  const previousReactionCounter = prevReaction
    ? prevReactionCount[prevReaction] || 1
    : 1;

  const prevReactionKey = prevReaction !== "unlike" ? prevReaction : undefined;

  if (newReaction === "unlike" && prevReactionKey) {
    return {
      ...prevReactionCount,
      [prevReactionKey]: previousReactionCounter - 1,
    };
  }

  const newReactionPreviousCount = prevReactionCount?.[newReaction] || 0;

  if (prevReactionKey) {
    return {
      ...prevReactionCount,
      [prevReactionKey]: previousReactionCounter - 1,
      [newReaction]: newReactionPreviousCount + 1,
    };
  }

  return {
    ...prevReactionCount,
    [newReaction]: newReactionPreviousCount + 1,
  };
};
