import React from "react";
import { isEmpty } from "underscore";

import {
  NotificationLandingMessageWithAuthor,
  NotifiacationLandingTweet,
} from "../../../types";

import { LoadingSpinner, Logo } from "../UI";

import { NotificationLandingCard, NotificationLandingTwitter } from "./";

export type NotificationLanding = {
  error: string | null;
  landingMessages: NotificationLandingMessageWithAuthor[] | null;
  landingTweets: NotifiacationLandingTweet[] | null;
  refreshing: boolean;
  setting: {
    logo: string;
    backgroundImage: string;
  };

  reFetch: (refresh: boolean, callback?: () => void) => void;
};

export const NotificationLanding: React.FC<NotificationLanding> = ({
  landingMessages,
  landingTweets,
  // reFetch,
  // refreshing,
  setting,
}) => {
  const { logo } = setting;

  if (!landingMessages && !landingTweets) {
    return <LoadingSpinner />;
  }

  const logoComponent = <>{!!logo && <Logo src={logo} />}</>;

  const landingMessagesElements =
    !isEmpty(landingMessages) &&
    landingMessages?.map(item => <NotificationLandingCard item={item} />);

  const landingTweetsElements =
    !isEmpty(landingTweets) &&
    landingTweets?.map(item => <NotificationLandingTwitter item={item} />);

  return (
    <div>
      {logoComponent}
      {landingMessagesElements}
      {landingTweetsElements}
    </div>
  );
};
