import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { isEmpty } from "underscore";

import {
  AnnouncementItem,
  EventItem,
  PostItem,
  SearchTypeKey,
} from "../../../types";

import {
  AnnouncementCardAnchor,
  AnnouncementCardPlaceholder,
  EventListSection,
  EventListSectionPlaceholder,
  InfiniteScroll,
  PostCard,
  PostCardPlaceholder,
} from "..";

import { SearchRecentSearches } from ".";

export type SearchTargetProps = {
  data: AnnouncementItem[] | EventItem[] | PostItem[] | null;
  error?: string;
  hasMore: boolean;
  histories: string[];
  loading?: boolean;
  query?: boolean;
  refreshing?: boolean;
  searchType?: SearchTypeKey;
  setting: {
    ClientHostName: string;
    confettiEffectActions: [];
  };

  reFetch: (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ) => void | boolean;
  searchFromHistory: (query: string) => void;
};

export const SearchTarget: React.FC<SearchTargetProps> = ({
  data,
  error,
  hasMore,
  histories,
  loading,
  query,
  reFetch,
  refreshing,
  searchFromHistory,
  searchType,
  setting,
}) => {
  const history = useHistory();
  const { t } = useTranslation("UI");

  if (history.location.state?.query !== query || isEmpty(query)) {
    return (
      <SearchRecentSearches
        data={histories}
        onSearch={(query): void => {
          searchFromHistory(query);
          history.replace("/search", {
            query: query,
            searchType: searchType,
          });
        }}
      />
    );
  }

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const handleEventClick = (id: string): void => {
    history.push(getEventHref(id));
  };

  const getEventHref = (id: string): string => `/event-detail/${id}`;

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  let content: JSX.Element[] | null = null;

  let placeholder: JSX.Element | undefined = undefined;

  switch (searchType) {
    case "announcement":
      content =
        (data as AnnouncementItem[] | null)?.map(announcementItem => (
          <AnnouncementCardAnchor
            key={announcementItem.announcement.id}
            item={announcementItem}
            setting={setting}
            showReadMoreButton={true}
            showSubTitle={true}
          />
        )) || null;

      placeholder = <AnnouncementCardPlaceholder />;

      break;

    case "event":
      /**
       * This is workaround caused by two event models: simple EventItem[] here
       * or nested one in regular EventList - we have to check double check data
       * length (here and in <InfiniteScroll /> to keep default empty behaviour).
       */
      content =
        data && data.length > 0
          ? [
              <EventListSection
                key={"theOnlyOneEventListSection"}
                data={data as EventItem[]}
                getEventHref={getEventHref}
                onEventClick={handleEventClick}
              />,
            ]
          : [];

      placeholder = <EventListSectionPlaceholder />;

      break;

    case "post":
      content =
        (data as PostItem[] | null)?.map(postItem => (
          <PostCard
            key={postItem.post.id}
            isMixed={true}
            item={postItem}
            setting={setting}
          />
        )) || null;

      placeholder = <PostCardPlaceholder />;

      break;
  }

  /** @FIXME problem with displaying appropriate amount of announcements and events search results.
   * Workaround is to logout and login again, but after some time bug come back.
   * */
  return (
    <>
      {errorElement}
      <InfiniteScroll
        emptyText={t("Empty.No.Data")}
        hasMore={hasMore}
        isLoading={!!loading}
        isRefreshing={!!refreshing}
        placeholder={placeholder}
        onLoadMore={handleLoadMore}>
        {content}
      </InfiniteScroll>
    </>
  );
};
