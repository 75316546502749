import React, { SyntheticEvent } from "react";

import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { ListGroupItem } from "reactstrap";

import { textThemingWorkaround } from "../../../constants";

import { ResourceItem } from "../../../types";

import { useTheme } from "../../hooks";

import { IconFontAwesome, Heading } from "..";

import { ResourceCardFolder } from ".";

export type ResourceCardProps = {
  className?: string;
  item: ResourceItem;
  style?: React.CSSProperties;
};

export const ResourceCard: React.FC<ResourceCardProps> = ({
  className,
  item,
  style,
}) => {
  const history = useHistory();

  const {
    list: { backgroundColor },
    text: { listTitle },
  } = useTheme();

  if (item.type === "folder") {
    return <ResourceCardFolder item={item} style={style} />;
  }

  const { name, type, id, fileUrl, url, video } = item;

  const iconName =
    type === "url"
      ? "link"
      : type === "text"
      ? "edit"
      : video
      ? "film"
      : "file";

  const testId = `resourceFile${name[0].toLowerCase()}${name
    .slice(1)
    .split(" ")
    .join("")}`;

  const href =
    type === "text" || type === "video"
      ? `/content-page-text-sub/${id}`
      : fileUrl || url;

  const resourceCardStyle: React.CSSProperties = {
    backgroundColor,
    zIndex: 0,
    ...style,
  };

  const handleListGroupItemClick =
    type === "text" || type === "video"
      ? (e: SyntheticEvent): void => {
          e.preventDefault();

          history.push(href);
        }
      : undefined;

  return (
    <ListGroupItem
      style={resourceCardStyle}
      className={classNames(
        "border-bottom-0",
        "border-radius-0",
        "border-right-0",
        "p-0",
        "mb-1",
        className
      )}>
      <a
        className="py-2 d-flex align-items-center cursor-pointer"
        href={href}
        id={testId}
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleListGroupItemClick}>
        <IconFontAwesome
          className="mx-2"
          name={iconName}
          style={{ color: listTitle.color }}
        />
        <Heading
          className="flex-grow-1"
          style={{ ...listTitle, ...textThemingWorkaround }}
          text={name}
        />
      </a>
    </ListGroupItem>
  );
};
