import React, { PropsWithChildren } from "react";
import { Container, Row, Col } from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";
import { convertHex } from "../../../lib/utils";
import { DocumentTitle } from "../../../containers/routers/DocumentTitle";
import { SettingDataLoaded } from "../../../types";

import { useTheme } from "../../hooks";

export type TemplateSimpleProps = PropsWithChildren<{
  pageTitle?: string;
  setting: SettingDataLoaded;
}>;

export const TemplateSimple: React.FC<TemplateSimpleProps> = ({
  pageTitle = "Group Fire",
  setting: {
    setting: {
      logo_large: logo,
      sign_up_page_background_image: backgroundImage,
    },
    config: { organizationName },
  },

  children,
}) => {
  /**
   * @TODO
   * Default component styles, copied from native/components/User/styles
   * Needs refactor to keep consistency between web and mobile apps
   */
  const templateNothingStyles = {
    text: {
      color: "#fff",
      textAlign: "center",
    },
  } as const;

  const {
    text: { header: headerStyle },
    login: { backgroundColor },
  } = useTheme();

  const containerStyle: React.CSSProperties = backgroundImage
    ? {
        backgroundColor,
        backgroundImage: `url("${backgroundImage}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }
    : { backgroundColor };

  const rowStyle: React.CSSProperties = {
    backgroundImage:
      backgroundColor.toLowerCase() === "#ffffff"
        ? "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))"
        : `linear-gradient(${convertHex(
            backgroundColor,
            10
          )}, ${backgroundColor} 60%, ${backgroundColor} 100%)`,
  };

  const logoElement = logo && (
    <img className="py-4 px-5 w-100" src={logo} alt={organizationName} />
  );

  const organizationNameElement = !logo && (
    <p
      className="h1 my-4 py-4"
      style={{
        ...{
          ...headerStyle,

          /** @TODO Theming workaround. Delete when issue solved. */
          ...textThemingWorkaround,

          /** @TODO Temporarily overwritten for better design */
          fontSize: undefined,
        },
        ...templateNothingStyles.text,
      }}>
      {organizationName}
    </p>
  );

  return (
    <Container className="h-100" fluid={true} style={containerStyle}>
      <DocumentTitle pageTitle={pageTitle} />

      <Row
        className="h-100 align-items-center justify-content-center"
        style={rowStyle}>
        {/**
         * @TODO Probably there should be one not fluid container put inside
         * to achieve fixed content size.
         */}
        <Col sm={10} md={8} lg={7} xl={5}>
          {logoElement}
          {organizationNameElement}
          {children}
        </Col>
      </Row>
    </Container>
  );
};
