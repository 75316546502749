import React from "react";

import FbImageGrid from "../../Plugin/react-fb-image-grid/Images";

export type MediaImageGridProps = {
  caption?: React.ReactNode;
  data: {
    thumbUrl?: string;
    fileUrl: string;
  }[];
  title?: React.ReactNode;
};

/**
 * @TODO
 * Implement displaying image thumbs in grid instead of full size image
 */
export const MediaImageGrid: React.FC<MediaImageGridProps> = ({
  caption,
  data,
  title,
}) => {
  if (data.length < 1) {
    return null;
  }

  const imagesUrls = data.map(item => item.fileUrl);

  return (
    <FbImageGrid
      hideOverlay={true}
      images={imagesUrls}
      caption={caption}
      title={title}
    />
  );
};
