//react-native-stripe-api

const REQM = " is required";
const STRIPE_URL = "https://api.stripe.com/v1/";
type StripeCard = {
  number: string;
  exp_month: string;
  exp_year: string;
  cvc: string;
};
class Stripe {
  stripePublicKey: string;

  constructor(apiKey: string) {
    this.stripePublicKey = apiKey;
  }
  /**
   * Return the default header entries : Accept and Authorization
   * @returns {Object} Default header Accept and Authorization
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  defaultHeader() {
    return {
      Accept: "application/json",
      Authorization: `Bearer ${this.stripePublicKey}`,
    };
  }

  /**
   * Generic method post to Stripe Rest API
   * @param resource : Rest API ressource ie. tokens, charges, etc.
   * @param properties : object, key by form parm
   */
  async stripePostRequest(resource: string, properties: {}): Promise<any> {
    const body = Object.entries(properties)
      .map(([key, value]) => `${key}=${value}`)
      .reduce((previous, current) => `${previous}&${current}`, "");

    const result = await fetch(`${STRIPE_URL}${resource}`, {
      method: "POST",
      headers: {
        ...this.defaultHeader(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    });
    return result.json();
  }
  /**
   * Only operation allowed from client/Using only public token
   * @param info : { number, exp_month, exp_year, address_city, address_country, address_line1,
   * ... address_line2, address_state, address_zip, currency, cvc }
   */
  createToken(info: StripeCard): Promise<any> {
    if (!info) throw new Error(`info${REQM}`);
    if (!info.number) throw new Error(`cardNumber${REQM}`);
    if (!info.exp_month) throw new Error(`expMonth${REQM}`);
    if (!info.exp_year) throw new Error(`expYear${REQM}`);
    if (!info.cvc) throw new Error(`cvc${REQM}`);

    return this.stripePostRequest("tokens", {
      "card[number]": info.number,
      "card[exp_month]": info.exp_month,
      "card[exp_year]": info.exp_year,
      "card[cvc]": info.cvc,
    });
  }

  createPaymentMethod(info: StripeCard): Promise<any> {
    if (!info) throw new Error(`info${REQM}`);
    if (!info.number) throw new Error(`cardNumber${REQM}`);
    if (!info.exp_month) throw new Error(`expMonth${REQM}`);
    if (!info.exp_year) throw new Error(`expYear${REQM}`);
    if (!info.cvc) throw new Error(`cvc${REQM}`);

    return this.stripePostRequest("payment_methods", {
      "card[number]": info.number,
      "card[exp_month]": info.exp_month,
      "card[exp_year]": info.exp_year,
      "card[cvc]": info.cvc,
      type: "card",
    });
  }
}

export default Stripe;
