import React from "react";

import { AnnouncementItem } from "../../../../types";

import { useAnnouncementAnchorProps } from "../../../hooks";

import { AnnouncementListPin } from ".";

export type AnnouncementPinAnchorProps = {
  customLabel?: string;
  item: AnnouncementItem;
};

export const AnnouncementPinAnchor: React.FC<AnnouncementPinAnchorProps> = ({
  customLabel,
  item,
}) => {
  const anchorProps = useAnnouncementAnchorProps(item);

  return (
    <AnnouncementListPin
      customLabel={customLabel}
      item={item}
      {...anchorProps}
    />
  );
};
