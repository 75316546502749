/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-find-dom-node */
import React from "react";

import ReactDOM from "react-dom";
import { Input } from "reactstrap";

export type MapSearchBoxProps = {
  autocomplete?: true;
  id?: string;
  map: google.maps.Map;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maps: any;
  name?: string;
  placeholder: string;
  value?: string;
  type?: string;

  onChange?: (name: string, value: string) => void;
};

export class MapSearchBox extends React.Component<MapSearchBoxProps> {
  searchBox?: google.maps.places.SearchBox | google.maps.places.Autocomplete;

  componentDidMount(): void {
    const opts = { types: [this.props.type] };
    const input = ReactDOM.findDOMNode(this.refs.input);
    if (input && this.props.maps) {
      this.searchBox = this.props.autocomplete
        ? new this.props.maps.places.Autocomplete(
            input as HTMLInputElement,
            opts
          )
        : new this.props.maps.places.SearchBox(input as HTMLInputElement);
      const event = this.props.autocomplete
        ? "place_changed"
        : "places_changed";

      this.searchBox?.addListener(event, this.onPlacesChanged);
    }
  }
  componentWillUnmount(): void {
    this.searchBox &&
      this.props.maps.event.clearInstanceListeners(this.searchBox);
  }

  onPlacesChanged = (): void => {
    if (this.searchBox) {
      const place = this.props.autocomplete
        ? (this.searchBox as google.maps.places.Autocomplete).getPlace()
        : (this.searchBox as google.maps.places.SearchBox).getPlaces()[0];

      if (place.geometry) {
        this.props.map.panToBounds(place.geometry.viewport);
        this.props.onChange &&
          this.props.onChange(
            this.props.name as string,
            place.formatted_address as string
          );
      } else {
        // <Alert color="danger">Place not found! Please try again</Alert>;
      }
    }
  };

  handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onChange && this.props.onChange(e.target.name, e.target.value);
  };

  render(): JSX.Element {
    return (
      <Input
        {...this.props}
        id={this.props.id}
        className="mb-3 py-1"
        name={this.props.name}
        onChange={this.handleOnChange}
        ref="input"
        type="text"
        value={this.props.value}
      />
    );
  }
}
