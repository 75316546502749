import { runQuery, runFunction, fetchData, saveData } from "../http";
import _ from "underscore";
import { logException } from "../../lib/utils";
const Parse = window.Parse;

export function findAll(query) {
  return new Promise((resolve, reject) => {
    var partialQuery = (query, skip, results) => {
      query
        .limit(1000)
        .skip(skip)
        .find()
        .then(
          function(objects) {
            if (objects.length > 0) {
              results = results.concat(objects);
            }
            if (objects.length < 1000) {
              resolve(results);
            } else {
              partialQuery(query, skip + objects.length, results);
            }
          },
          function(error) {
            reject("findAll: command failed");
          }
        );
    };
    partialQuery(query, 0, []);
  });
}

/* Server */
export * from "./Server";

/* Settings */
export * from "./Settings";

/* Work Space */
export * from "./WorkSpace";

/* Upload file */
export function uploadFile(file, path, config, progressCallback) {
  return new Promise((resolve, reject) => {
    console.debug("[Service] uploadFile", file, path);

    if (_.isEmpty(file)) {
      return resolve({});
    }
    const $scope = {
      config: config,
      pathUpload: path || "uploads/",
    };

    const { name, size, type, url } = file.file;
    if (url) {
      return resolve(file.file);
    }
    window.Upload.resolveFile(
      {
        file: file.file,
        fileThumb: file.fileThumb,
        progressCallback: progressCallback,
      },
      $scope.pathUpload,
      $scope.config
    )
      .then(data => {
        return resolve({
          fileUrl: data.fileUrl || data.thumbUrl,
          thumbUrl: data.thumbUrl || data.fileUrl,
          url: data.fileUrl || data.thumbUrl,
          name,
          size,
          type,
        });
      })
      .catch(error => {
        logException(error);
        return reject(error);
      });
  });
}

export function uploadFiles(files, path, config, progressCallback) {
  return new Promise((resolve, reject) => {
    if (_.isEmpty(files)) {
      return resolve([]);
    }

    let promises = [];
    files.forEach(file => {
      promises.push(uploadFile(file, path, config, progressCallback));
    });
    Promise.all(promises)
      .then(resp => {
        return resolve(resp);
      })
      .catch(error => {
        logException(error);
        reject(error);
      });
  });
}

export function uploadFileCloudinary(file, config, progressCallback) {
  return new Promise((resolve, reject) => {
    console.debug("[Service] uploadFileCloudinary", {
      file,
      config,
      progressCallback,
    });

    if (_.isEmpty(file)) {
      return resolve({});
    }
    //for video from search in library
    if (file.fromLibrary && file.id) {
      return resolve({ data: file });
    }

    return runFunction(
      "generateCloudinaryPolicy",
      { newContext: true },
      { requireUser: true }
    ).then(policy => {
      return window.Upload.resolveFileCloudinary({
        file: file,
        progressCallback: progressCallback,
        path: config.subDomain,
        config,
        policy,
      })
        .then(file => {
          return resolve({
            fileUrl: file.fileUrl,
            data: file.data,
          });
        })
        .catch(error => {
          logException(error);
          return reject(error);
        });
    });
  });
}

/* Authentication */

export function saveInstallation(deviceToken, data) {
  console.debug("[Service] saveInstallation");
  return Parse.Session.current().then(function(session) {
    const user = session.get("user");
    const installationId = session.get("installationId");
    const userId = user.id;
    const channels = ["Global", `User_${userId}`];
    const params = Object.assign(data, {
      channels,
      deviceToken,
      installationId,
    });
    return runFunction("saveInstallation", params, { requireUser: true });
  });
}

export function removeInstallation() {
  console.debug("[Service] removeInstallation");
  return Parse.Session.current().then(function(session) {
    const installationId = session.get("installationId");
    return runFunction(
      "removeInstallation",
      { installationId },
      { requireUser: true }
    );
  });
}

export function logOut() {
  console.debug("[Service] logOut");
  return Parse.User.logOut();
}

export function register(data, avatar, config) {
  return uploadFile(avatar, "uploads/userfiles", config).then(
    ({ thumbUrl }) => {
      if (thumbUrl) {
        data.thumbUrl = thumbUrl;
      }
      return runFunction("registerUser", data, { notify: true });
    }
  );
}

/* Profile */
export function updateProfile(profile, image, config) {
  return uploadFile(image, "uploads/userfiles", config).then(({ thumbUrl }) => {
    if (thumbUrl) {
      profile.thumbUrl = thumbUrl;
    }
    return runFunction("updateProfile", { profile }, { requireUser: true });
  });
}

export function updateUser(data) {
  return runFunction("updateUser", data, { requireUser: true });
}

export function getNotificationSettings() {
  return Parse.User.currentAsync().then(user => {
    return fetchData(user, "notificationSettings");
  });
}

export function saveNotificationSettings(setting) {
  let currentUser, newNotificationSettings;
  return Parse.User.currentAsync().then(user => {
    currentUser = user;
    if (user.get("notificationSettings")) {
      return fetchData(currentUser, "notificationSettings").then(
        notificationSettings => {
          notificationSettings.set(
            "groupPushNotifications",
            setting.groupPushNotifications
          );
          notificationSettings.set(
            "groupMailNotifications",
            setting.groupMailNotifications
          );
          notificationSettings.set(
            "mailMessageNotifications",
            setting.mailMessageNotifications
          );
          //notificationSettings.set("enabledTextMessage", setting.enabledTextMessageChecked);
          return saveData(notificationSettings);
        }
      );
    } else {
      let notificationSettings = new (Parse.Object.extend(
        "NotificationsSettings"
      ))();
      notificationSettings.set(
        "groupPushNotifications",
        setting.groupPushNotifications
      );
      notificationSettings.set(
        "groupMailNotifications",
        setting.groupMailNotifications
      );
      notificationSettings.set(
        "mailMessageNotifications",
        setting.mailMessageNotifications
      );
      notificationSettings.set("user", currentUser);
      //notificationSettings.set("enabledTextMessage", setting.enabledTextMessageChecked);
      return saveData(notificationSettings)
        .then(notificationSettings => {
          newNotificationSettings = notificationSettings;
          currentUser.set("notificationSettings", newNotificationSettings);
          return saveData(currentUser);
        })
        .then(() => {
          return newNotificationSettings;
        });
    }
  });
}

export function updateUserSendBird(userSendBirdId) {
  return Parse.User.currentAsync().then(user => {
    if (user) {
      user.set("userChatId", userSendBirdId);
      return user.save();
    }
    return new Promise.reject("Can't update user SendBird ID");
  });
}

export function sendActivity(data) {
  return runFunction("registerActivity", data, { error: false, notify: false });
}
export function setHasMessages(hasMessages) {
  return runFunction(
    "setHasMessages",
    { hasMessages },
    { error: false, notify: false }
  );
}

export function updateUserAgreement() {
  return Parse.User.currentAsync().then(user => {
    if (user) {
      user.set("user_agreement", true);
      return user.save();
    }
    return new Promise.reject("Can't update user agreement");
  });
}

export function resetPassCode(phoneNumber) {
  return runFunction("resetPin", { phone: phoneNumber });
}

export function updatePassword(password) {
  return runFunction("updatePassword", {
    password: password,
  });
}

export function forgotPassword(email) {
  return runFunction("forgotPassword", { email: email }, { error: false });
}

/* Member */
export * from "./Member";
export * from "./User";

/* Group */
export * from "./Group";

export function getGroupTypesByName(name) {
  return runQuery(
    "GroupType",
    function(query) {
      query.equalTo("name", name);
      return query.first();
    },
    { requireUser: true }
  );
}

/* Post */
export * from "./Post";

/* Announcement */
export * from "./Announcement";

/* Event */
export * from "./Event";

export function searchEventByChatChannel(channel) {
  return runQuery("Event", function(query) {
    query.contains("eventChatId", channel);
    return query.first();
  });
}

// Support and feedback
export * from "./Support";

/* Comment */
export * from "./Comment";

//Payment
export * from "./Payment";

//Notification
export * from "./Notification";

//Resource
export * from "./Resource";

//Video
export * from "./VideoLibrary";

// Chat
export * from "./Chat";

// Media
export * from "./Media";

//Map
export * from "./Map";

//extractLink
export * from "./extractLink";

//Subscription
export * from "./Subscription";
