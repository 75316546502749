import React from "react";

import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

export type NotificationCardPlaceholderProps = {};

export const NotificationCardPlaceholder: React.FC<NotificationCardPlaceholderProps> = () => {
  return (
    <div>
      {[1, 2, 3].map(i => (
        <Card key={i} className="mb-2 pt-2 pb-2">
          <CardBody className={"p-0"}>
            <Row className="px-4 py-2">
              <Skeleton width={35} height={35} />
              <Col className="my-0">
                <p>
                  <Skeleton width={100} />
                </p>
                <p className="text-left small mt-n3 m-0">
                  <Skeleton />
                </p>
              </Col>
            </Row>
            <div className="px-3 py-0">
              <p className={"text-left small m-0"}>
                <Skeleton />
              </p>
              <p className={"text-left small m-0"}>
                <Skeleton />
              </p>
            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};
