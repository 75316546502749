import React, { SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Form, Alert, FormGroup, Input, Button, Media } from "reactstrap";
import { isEmpty } from "underscore";

import { textThemingWorkaround } from "../../../constants/Theme";

import { WorkspaceState as WorkspaceContainerState } from "../../../containers/WorkSpace";
import { LoginDataSet } from "../../../containers/WorkSpace/types";

import { useTheme } from "../../hooks";
import { Override } from "../../../types";

export type WorkspaceProps = {
  setting: {
    backgroundImage: string;
    logo: string;
  };
  data: WorkspaceContainerState;
  /**
   * @FIXME
   * Empty object probably should be replaced by null
   */
  workSpaces: {} | LoginDataSet[];

  getWorkSpace: (
    fallback?: (error: string) => void,
    callback?: () => void
  ) => void;
  getWorkSpaces: (callback?: () => void) => void;
  handleChange: <K extends keyof WorkspaceContainerState>(
    key: K,
    value: WorkspaceContainerState[K]
  ) => void;
  resetSetting?: () => Promise<void>;
};

export const Workspace: React.FC<WorkspaceProps> = ({
  data: { username, domain, enteringTenant, loading, error },
  workSpaces,

  getWorkSpace,
  getWorkSpaces,
  handleChange,
}) => {
  /**
   * @TODO
   * Default component styles, copied from native/components/User/styles
   * Needs refactor to keep consistency between web and mobile apps
   */
  const workspaceStyles = {
    text: {
      color: "#fff",
      textAlign: "center",
    },
    input: {
      backgroundColor: "#fff",
      paddingRight: 15,
      paddingLeft: 15,
      height: 45,
    },
    item: {
      borderBottomWidth: 0,
      marginLeft: 0,
    },
    mainButton: {
      height: 50,
    },
    listItem: {
      height: 70,
      backgroundColor: "#fff",
      alignItems: "center",
      // paddingHorizontal: 5,
      /** @TODO Temporary workaround for horizontal padding */
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 14,
      marginLeft: 0,
    },
  } as const;

  const history = useHistory();

  const {
    button: buttonStyles,
    login: { button: loginButtonStyles },
    text: { listTitle: listTitleStyles, subHeader: subHeaderStyles },
  } = useTheme();

  const { t } = useTranslation("WorkSpace");

  const handleGetWorkSpaces = (e: SyntheticEvent): void => {
    e.preventDefault();

    getWorkSpaces();
  };

  const handleGetWorkSpace = (e: SyntheticEvent): void => {
    e.preventDefault();

    getWorkSpace(undefined, () => {
      history.push("/sign-up");
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      Override<HTMLInputElement, { name: keyof WorkspaceContainerState }>
    >
  ): void => {
    handleChange(e.target.name, e.target.value);
  };

  const handleSelectWorkSpace = (option: LoginDataSet): void => {
    /**
     * @TODO
     * Logic replicated from native app. I think its kinda antipattern here.
     * Why do we have to handle all these logic here? Better architecture could
     * help us to avoid timeout usage here...
     */
    handleChange("domain", option.subdomain);
    handleChange("user", option.user);

    setTimeout(() => {
      getWorkSpace();
    }, 1);
  };

  const instructionElement = (
    <div
      id="usePhoneOrEmailText"
      className="mb-5"
      style={{
        ...listTitleStyles,
        ...textThemingWorkaround,
        ...workspaceStyles.text,
      }}>
      {t("Index.Heading.Phone")}
    </div>
  );

  const errorAlertElement = error && (
    <Alert
      className="mb-3 text-center"
      color="danger"
      style={{
        borderRadius: buttonStyles.borderRadius,
      }}>
      {error}
    </Alert>
  );

  return (
    <>
      {isEmpty(domain) && isEmpty(workSpaces) && !enteringTenant && (
        <Form onSubmit={handleGetWorkSpaces}>
          {instructionElement}

          {errorAlertElement}

          <FormGroup>
            <Input
              type="text"
              name="username"
              id="usernamePhoneOrEmailInput"
              placeholder={t("Index.Placeholder.Phone.Email")}
              disabled={loading}
              value={username}
              style={{
                ...workspaceStyles.item,
                ...workspaceStyles.input,
                borderRadius: buttonStyles.borderRadius,
              }}
              onChange={handleInputChange}
            />
            <p
              className="mt-3"
              style={{
                ...subHeaderStyles,
                ...textThemingWorkaround,
                ...workspaceStyles.text,
              }}>
              <i className="icon-info mr-2" />
              {t("Index.Enter.Phone.Number.Info")}
            </p>
          </FormGroup>

          <Button
            id="getCommunityButton"
            block
            color="primary"
            disabled={loading}
            style={{
              ...buttonStyles,
              ...loginButtonStyles,
              ...workspaceStyles.mainButton,
              border: 0,
            }}
            type="submit">
            {loading ? t("Index.Button.Loading") : t("Index.Button.Find")}
          </Button>

          <p
            className="mt-5"
            style={{
              ...listTitleStyles,
              ...textThemingWorkaround,
              ...workspaceStyles.text,
            }}>
            {t("Index.Heading.Account")}
          </p>

          <Button
            id="signUpButton"
            block
            outline
            color="secondary"
            disabled={loading}
            style={{
              borderColor: "#fff",
              borderRadius: buttonStyles.borderRadius,
              color: "#fff",
            }}
            onClick={(): void => handleChange("enteringTenant", true)}>
            {t("Index.Sign.Up")}
          </Button>
        </Form>
      )}

      {enteringTenant && (
        <Form onSubmit={handleGetWorkSpace}>
          <p
            className="mb-5"
            style={{
              ...listTitleStyles,
              ...textThemingWorkaround,
              ...workspaceStyles.text,
            }}>
            {t("Index.Heading.Community")}
          </p>

          {errorAlertElement}

          <FormGroup>
            <Input
              type="text"
              name="domain"
              id="domain"
              disabled={loading}
              placeholder={t("Index.Placeholder.Community")}
              value={domain}
              style={{
                ...workspaceStyles.input,
                borderRadius: buttonStyles.borderRadius,
              }}
              onChange={handleInputChange}
            />
          </FormGroup>

          <Button
            block
            className="mt-3"
            color="primary"
            disabled={loading || !domain}
            type="submit"
            style={{
              ...buttonStyles,
              ...loginButtonStyles,
              ...workspaceStyles.mainButton,
              border: 0,
              ...{
                ...buttonStyles.text,
                ...textThemingWorkaround,
                textAlign: undefined,
              },
            }}>
            {loading ? t("Index.Button.Loading") : t("Index.Button.Find")}
          </Button>

          <p
            className="mt-5"
            style={{
              ...listTitleStyles,
              ...textThemingWorkaround,
              ...workspaceStyles.text,
            }}>
            {t("Index.Heading.Already.Account")}
          </p>

          <Button
            block
            outline
            color="secondary"
            disabled={loading}
            style={{
              color: "#fff",
              borderColor: "#fff",
              borderRadius: buttonStyles.borderRadius,
            }}
            onClick={(): void => handleChange("enteringTenant", false)}>
            {t("Index.Button.Sign.In")}
          </Button>
        </Form>
      )}
      {!isEmpty(workSpaces) && (
        <>
          <Form>
            <p
              className="mb-5"
              style={{
                ...listTitleStyles,
                ...textThemingWorkaround,
                ...workspaceStyles.text,
              }}>
              {t("Index.Heading.Select")}
            </p>

            {errorAlertElement}

            <Media list className="overflow-auto" style={{ height: 400 }}>
              {(workSpaces as LoginDataSet[]).map(workspace => (
                <Media
                  id={`community${workspace.name}`}
                  className="mb-3"
                  tag="li"
                  key={workspace.name}
                  style={{
                    ...workspaceStyles.listItem,
                    cursor: loading ? "wait" : "pointer",
                  }}
                  onClick={(): void => handleSelectWorkSpace(workspace)}>
                  <Media left top className="mr-3">
                    <Media
                      object
                      src={workspace.icon}
                      alt={workspace.name}
                      style={{
                        borderRadius:
                          workspaceStyles.listItem.borderRadius -
                          workspaceStyles.listItem.paddingLeft,
                        width: 60,
                        height: 60,
                      }}
                    />
                  </Media>
                  <Media
                    body
                    style={{
                      ...workspaceStyles.item,
                      ...{ ...listTitleStyles, ...textThemingWorkaround },
                    }}>
                    <div className="d-flex">
                      <div
                        className="flex-grow-1 align-self-center"
                        style={{
                          ...workspaceStyles.item,
                          ...{ ...listTitleStyles, ...textThemingWorkaround },
                        }}>
                        {workspace.name}
                      </div>
                      <div
                        className="p-2 pr-4 align-self-center"
                        style={workspaceStyles.item}>
                        <i className="icon-arrow-right" />
                      </div>
                    </div>
                  </Media>
                </Media>
              ))}
            </Media>
          </Form>
        </>
      )}
    </>
  );
};
