import React from "react";

import { RouteComponentProps } from "react-router-dom";

import ResourceDetailContainer from "../../../containers/Resource/Detail";

import { ResourceDetail } from "../../components";

export type RouteResourceDetailProps = RouteComponentProps;

export const RouteResourceDetail: React.FC<RouteResourceDetailProps> = props => (
  <ResourceDetailContainer {...props} Layout={ResourceDetail} />
);
