import { logOut as userActionLogout } from "../../containers/User/action";

import { useTypedDispatch } from ".";

export const useUserDispatchLogout = (): (() => Promise<void>) => {
  const dispatch = useTypedDispatch();

  const userDispatchLogout = (): Promise<void> => dispatch(userActionLogout());

  return userDispatchLogout;
};
