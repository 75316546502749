import React from "react";
import { Row, Card, Col, CardBody } from "reactstrap";
import { Video } from "../../../types/VideoLibrary/Video";
import { Media } from "../Media";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTheme } from "../../hooks";

type Props = {
  item: Video;
};

export const VideoCard: React.FC<Props> = ({ item }) => {
  const name = item.author ? `by ${item.author.name}` : "";
  const time = moment.utc(item.duration * 1000).format("mm:ss");
  const {
    card: { backgroundColor },
  } = useTheme();
  const styles = {
    card: {
      backgroundColor,
    },
  };
  return (
    <Card className="mb-2" style={styles.card}>
      <Link
        id={`videoLibraryCard${item.id}`}
        className={"text-decoration-none"}
        to={`/video-detail/${item.id}`}>
        <CardBody className="px-0 py-0">
          <Media
            resizeMode="cover"
            image={{ thumbUrl: item.thumbUrl, fileUrl: item.thumbUrl }}
          />
        </CardBody>
        <CardBody className="px-3 pb-0">
          <Row>
            <Col>
              <h6 id={`videoLibraryVideoTitle${item.id}`}> {item.title} </h6>
              <p
                id={`videoLibraryVideoAuthor${item.id}`}
                className="text-left small">
                {name}
              </p>
            </Col>
            <Col>
              <p id={`videoLibraryVideoTime${item.id}`} className="text-right">
                {time}
              </p>
            </Col>
          </Row>
        </CardBody>
      </Link>
    </Card>
  );
};
