import { runFunction } from "../../http";
import { commentsTypes } from "../../../constants/Comment";
import { CommentResponse } from "../../../types/Comment";
import _ from "underscore";
import { convertObject } from "../../../lib/utils";
import { CommentApiOptions } from "./index";
import { CommentResponseDict } from "../../../containers/Comment/types";
import { parseMentions } from "../ParseMentionsHelper";

export const getComments = async (
  options: CommentApiOptions & { direction: "desc" | "asc" }
): Promise<CommentResponseDict> => {
  console.debug("[Service] getComments", options);

  const { id, page, per, type, direction } = options;
  const option = {
    eventId: id,
    announcementId: id,
    postId: id,
    eventPhotoId: id,
    direction,
    per: per,
    page: page,
  };

  const parseResponse: Parse.Object<CommentResponse>[] = await runFunction(
    commentsTypes[type].functionGetComments,
    option,
    {
      requireUser: true,
    }
  );

  return _.object(
    _.map(parseResponse.reverse(), value => {
      return [value.id, parseMentions<CommentResponse>(convertObject(value))];
    })
  );
};
