import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Callback,
  EmptyObject,
  Group,
  RootState,
  UserDataState,
} from "../../types";

import { getGroupsByStatus } from "./action";
import { GroupByStatusData, GroupByStatusTab, GroupStatusData } from "./types";
import { debounce, isEmpty } from "underscore";
import { filterGroupNameByQuery } from "./helpers";
import { getCurrentUserState } from "../User/selector";
import { getGroupStatusDataState, getGroupMenuTabsState } from "./selector";

export type MixedGroupsLayoutProps = {
  activeTab: GroupByStatusTab;
  data: Group[];
  menuTabs: GroupByStatusTab[];
  error: Error | null;
  layoutType: unknown;
  loading: boolean;
  query: string;
  refreshing: boolean;
  renderActions?: boolean;
  renderActionsDone?: boolean;
  user: UserDataState | EmptyObject;
  changeQuery: (query: string) => void;
  changeActiveTab: (tab: GroupByStatusTab) => void;
  reFetch: (refresh: boolean, callback?: Callback) => Promise<void>;
};

export type MixedGroupsOwnProps = {
  Layout: React.ComponentType<any>;
  layoutType?: unknown;
  renderActions?: boolean;
  renderActionsDone?: boolean;
};

export type MixedGroupsStateProps = {
  data: GroupStatusData | null;
  menuTabs: GroupByStatusTab[] | null;
  user: UserDataState | EmptyObject;
};

export type MixedGroupsDispatchProps = {
  getGroupsByStatus: (refresh: boolean) => Promise<unknown>;
};

export type MixedGroupsProps = MixedGroupsOwnProps &
  MixedGroupsStateProps &
  MixedGroupsDispatchProps;

type MixedSearchedData = {
  [id in GroupByStatusTab]?: GroupByStatusData | null;
};

export type MixedGroupsState = {
  activeTab: GroupByStatusTab | null;
  error: Error | null;
  loading: boolean;
  refreshing: boolean;
  searchedData: MixedSearchedData | null;
  query: string;
};

class MixedGroups extends Component<MixedGroupsProps, MixedGroupsState> {
  state: MixedGroupsState = {
    error: null,
    loading: false,
    refreshing: false,
    searchedData: null,
    query: "",
    activeTab: this.props.menuTabs && this.props.menuTabs[0],
  };

  componentDidMount = async (): Promise<void> => {
    const { data } = this.props;

    if (!data) {
      await this.fetchData(true);
    }
  };

  fetchData = async (refresh: boolean, callback?: Callback): Promise<void> => {
    const { getGroupsByStatus } = this.props;
    const { refreshing, loading } = this.state;

    if (refreshing || loading) {
      return;
    }

    this.setState({ refreshing: refresh }, async () => {
      try {
        await getGroupsByStatus(refresh);

        this.setState({ loading: false, refreshing: false, error: null });

        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  getSearchedData = (
    dataToFilter: GroupByStatusData,
    query: string
  ): GroupByStatusData => {
    return {
      ...dataToFilter,
      data: dataToFilter.data.filter(filterGroupNameByQuery(query)),
    };
  };

  onChangeQueryDebounce = debounce(() => {
    const { query } = this.state;
    const { menuTabs } = this.props;

    if (!menuTabs || !query) {
      return;
    }

    menuTabs.forEach(tab => {
      // eslint-disable-next-line
      // @ts-ignore
      const dataToFilter = this.props.data[tab];

      const searchedGroupData: GroupByStatusData = this.getSearchedData(
        dataToFilter,
        query
      );

      this.setState((prevState: MixedGroupsState) => {
        if (!prevState.searchedData) {
          return {
            searchedData: {
              [tab as GroupByStatusTab]: searchedGroupData,
            },
          };
        }
        return {
          searchedData: {
            ...this.state.searchedData,
            [tab as GroupByStatusTab]: searchedGroupData,
          },
        };
      });
    });
  }, 1000);

  onChangeQuery = (query: string): void => {
    this.setState({ query: query });

    if (!query || isEmpty(query)) {
      this.setState({
        searchedData: null,
      });
      return;
    }

    this.onChangeQueryDebounce();
  };

  onChangeActiveTab = (tab: GroupByStatusTab): void => {
    this.setState({
      activeTab: tab,
    });
  };

  getDataByActiveTab = (): Group[] => {
    const { activeTab, searchedData } = this.state;
    const { data } = this.props;

    if (!data || !activeTab) {
      return [];
    }

    const searchedDataByActiveTab =
      searchedData && searchedData[activeTab]?.data;

    return searchedDataByActiveTab || data[activeTab].data;
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      menuTabs,
      layoutType,
      renderActions,
      renderActionsDone,
    } = this.props;
    const { activeTab, loading, error, query, refreshing } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        user={user}
        activeTab={activeTab}
        menuTabs={menuTabs}
        data={this.getDataByActiveTab()}
        query={query}
        layoutType={layoutType}
        reFetch={this.fetchData}
        changeActiveTab={this.onChangeActiveTab}
        changeQuery={this.onChangeQuery}
        renderActions={renderActions}
        renderActionsDone={renderActionsDone}
      />
    );
  };
}

const mapStateToProps = (state: RootState): MixedGroupsStateProps => {
  return {
    user: getCurrentUserState(state),
    data: getGroupStatusDataState(state),
    menuTabs: getGroupMenuTabsState(state),
  };
};

const mapDispatchToProps = {
  getGroupsByStatus: getGroupsByStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(MixedGroups);
