import React from "react";

import classnames from "classnames";
import { CardBody } from "reactstrap";

import { Media } from "./index";

type File =
  | {
      id?: string;
      name: string;
      url: string;
      fileUrl?: undefined | string;
    }
  | {
      id?: string;
      name: string;
      url?: undefined;
      fileUrl: string;
    };

export type MediaFileListProps = {
  className?: string;
  data: File[];
  header?: string;
  headerClassName?: string;
};

export const MediaFileList: React.FC<MediaFileListProps> = ({
  className,
  data,
  header,
  headerClassName,
}) => {
  if (data.length < 1) {
    return null;
  }

  const mediaFileElements = data.map((attachment, index) => (
    <li id={`mediaFileElement${attachment.id}`} key={index}>
      <Media
        file={{
          name: attachment.name,
          fileUrl:
            attachment.url === undefined ? attachment.fileUrl : attachment.url,
        }}
      />
    </li>
  ));

  return (
    <CardBody className={classnames(className)}>
      <p className={classnames(headerClassName)}>{header}</p>
      <ul style={{ listStyle: "none" }} className="p-0 m-0">
        {mediaFileElements}
      </ul>
    </CardBody>
  );
};
