import React, { SyntheticEvent } from "react";

import { DropdownItem } from "reactstrap";

import { AnnouncementType } from "../../../../types";

export type AnnouncementListDropdownItemProps = {
  type: AnnouncementType;

  onClick: (type: AnnouncementType) => void;
};

export const AnnouncementListDropdownItem: React.FC<AnnouncementListDropdownItemProps> = ({
  type,

  onClick,
}) => {
  const handleClick = (e: SyntheticEvent): void => {
    onClick(type);
  };

  return (
    <DropdownItem
      id={`announcementTypesDropdownMenuElement${type.name.replace(" ", "")}`}
      onClick={handleClick}>
      {type.name}
    </DropdownItem>
  );
};
