import { createSelector } from "reselect";
import { RootState, Dict, InvoiceData, ReceiptData } from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";

const getInvoiceData = (state: RootState): Dict<InvoiceData> | null =>
  state.payment.invoiceList.items;

const getInvoiceDataHasMore = (state: RootState): boolean =>
  state.payment.invoiceList.hasMore;

const getReceiptData = (state: RootState): Dict<ReceiptData> | null =>
  state.payment.receiptList.items;

const getReceiptDataHasMore = (state: RootState): boolean =>
  state.payment.receiptList.hasMore;

export const getInvoiceDataState = createSelector(
  [getInvoiceData],
  items => getDictDefinedValues(items) || null
);

export const getInvoiceDataHasMoreState = createSelector(
  [getInvoiceDataHasMore],
  hasMore => hasMore
);

export const getReceiptDataState = createSelector(
  [getReceiptData],
  items => getDictDefinedValues(items) || null
);

export const getReceiptDataHasMoreState = createSelector(
  [getReceiptDataHasMore],
  hasMore => hasMore
);
