import React from "react";

import { useTranslation } from "react-i18next";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { Dict, UserSocialMedia, UserSocialMediaType } from "../../../../types";
import { LoadingSpinner } from "../../UI";

export type UserProfileSocialMediaInfoProps = {
  data: UserSocialMediaType | null;
  templateSocial: string[];
  visibilities: Dict<boolean, UserSocialMedia>;

  onSocialMediaChange: (name: string, value: string) => void;
  onVisibilityChange: (name: string, value: string) => void;
};

export const UserProfileSocialMediaInfo: React.FC<UserProfileSocialMediaInfoProps> = ({
  data,
  templateSocial,
  visibilities,

  onSocialMediaChange,
  onVisibilityChange,
}) => {
  const { t } = useTranslation("User");

  if (!data) {
    return <LoadingSpinner />;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onSocialMediaChange(
      e.target.name as keyof UserSocialMediaType,
      e.target.value
    );
  };

  const handleVisibilityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onVisibilityChange(
      e.target.name as keyof UserSocialMediaType,
      (e.target.checked as unknown) as string
    );
  };

  const shouldDisplayElement = (element: keyof UserSocialMediaType): boolean =>
    templateSocial.includes(element);

  const facebookElement = shouldDisplayElement("Facebook") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileSocialMediaInfoFacebookLabel">
            {t("Update.Profile.Social.Media.Facebook")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileSocialMediaInfoFacebookSwitch"
            name="Facebook"
            defaultChecked={visibilities["Facebook"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        type="text"
        name="Facebook"
        id="userEditProfileSocialMediaInfoFacebookInput"
        placeholder={t("Update.Profile.Social.Media.Type.Your.Username.Only")}
        value={data.Facebook}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const linkedinElement = shouldDisplayElement("Linkedin") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileSocialMediaInfoLinkedInLabel">
            {t("Update.Profile.Social.Media.Linkedin")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileSocialMediaInfoLinkedInSwitch"
            name="Linkedin"
            defaultChecked={visibilities["Linkedin"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        type="text"
        name="Linkedin"
        id="userEditProfileSocialMediaInfoLinkedInInput"
        placeholder={t("Update.Profile.Social.Media.Type.Your.Username.Only")}
        value={data.Linkedin}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const instagramElement = shouldDisplayElement("Instagram") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileSocialMediaInfoInstagramLabel">
            {t("Update.Profile.Social.Media.Instagram")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileSocialMediaInfoInstagramSwitch"
            name="Instagram"
            defaultChecked={visibilities["Instagram"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        type="text"
        name="Instagram"
        id="userEditProfileSocialMediaInfoInstagramInput"
        placeholder={t("Update.Profile.Social.Media.Type.Your.Username.Only")}
        value={data.Instagram}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const twitterElement = shouldDisplayElement("Twitter") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileSocialMediaInfoTwitterLabel">
            {t("Update.Profile.Social.Media.Twitter")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileSocialMediaInfoTwitterSwitch"
            name="Twitter"
            defaultChecked={visibilities["Twitter"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        type="text"
        name="Twitter"
        id="userEditProfileSocialMediaInfoTwitterInput"
        placeholder={t("Update.Profile.Social.Media.Type.Your.Username.Only")}
        value={data.Twitter}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  return (
    <Form>
      {facebookElement}
      {linkedinElement}
      {instagramElement}
      {twitterElement}
    </Form>
  );
};
