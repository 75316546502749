import { MAP_MARKER_SET, MAP_RESET } from "./constants";
import Store from "./store";
import { MapAction, MapState } from "./types";

export const initialState = Store;

export default function reducer(
  state: MapState = initialState,
  action: MapAction
): MapState {
  switch (action.type) {
    case MAP_RESET:
      return initialState;

    case MAP_MARKER_SET:
      if (action.data) {
        const { data, items } = action.data;
        return {
          ...state,
          error: null,
          items,
          data,
        };
      }

      return state;

    default:
      return state;
  }
}
