import _ from "underscore";
import { ThunkDispatch } from "redux-thunk";

import { logException } from "../../lib/utils";
import { correctBoundByRegion, correctBound } from "../../lib/map";

import i18n from "../../middlewares/i18next";

import { getMapMarkersInBounds as _getMapMarkersInBounds } from "../../services/api";

import {
  GetState,
  GlobalAction,
  RootState,
  MapBoundType,
  MapOptionsType,
  MapMarkerType,
} from "../../types";

import { MAP_MARKER_SET } from "./constants";
import { MapMarkerSetAction } from "./types";

export function getMarkersInBounds(options: MapOptionsType) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<MapMarkerSetAction> =>
    new Promise<MapMarkerSetAction>((resolve, reject) => {
      console.debug("[Action] getMarkersInBounds", options);

      let bound: MapBoundType | undefined = undefined;
      if (options.bound) {
        bound = correctBound(options.bound);
      } else if (options.region) {
        bound = correctBoundByRegion(options.region);
      }
      if (!bound) {
        return reject({ message: i18n.t("Map:Container.Action.Message") });
      }

      const cachedData = getState().maps.data || [];
      const excludeIds = _.map(
        cachedData,
        (marker: MapMarkerType) => marker.objectId
      );
      _getMapMarkersInBounds(bound, excludeIds, options.targets, options.types)
        .then(resp => {
          return resolve(
            dispatch({
              type: MAP_MARKER_SET,
              data: {
                data: cachedData.concat(resp.data),
                items: resp.foundMarkers,
              },
            })
          );
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}
