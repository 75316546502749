import { createSelector } from "reselect";
import { RootState, Dict, ResourceItem } from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";

const getResourcesData = (state: RootState): Dict<ResourceItem> | null =>
  state.resource.list.items;

const getResourcesDataHasMore = (state: RootState): boolean =>
  state.resource.list.hasMore;

export const getResourcesDataState = createSelector(
  [getResourcesData],
  items =>
    getDictDefinedValues(items)?.filter(resource => !resource.parent) || null
);

export const getResourcesDataHasMoreState = createSelector(
  [getResourcesDataHasMore],
  hasMore => hasMore
);
