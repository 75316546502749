import { EventState } from "./types";

export const eventStateInit: EventState = {
  recaps: {},
  history: {},
  list: {
    items: null,
    cached: {},
    page: 0,
    hasMore: true,
  },
  searchList: {
    items: null,
    cached: {},
    page: 0,
    hasMore: true,
  },
  publishedList: {
    items: null,
    page: 0,
    hasMore: true,
  },
  draftList: {
    items: null,
    page: 0,
    hasMore: true,
  },
};

export default eventStateInit;
