import Parse from "parse";

import { convertObject } from "../../../lib/utils";
import { AnnouncementType } from "../../../types";

import { runQuery } from "../../http";

export const getAnnouncementTypes = async (): Promise<AnnouncementType[]> => {
  console.debug("[ServiceApi] getAnnouncementTypes");

  const response: AnnouncementType[] = await runQuery(
    "AnnouncementCategory",
    (query: Parse.Query<Parse.Object<Parse.Attributes>>) => {
      return query.find();
    }
  );

  /**
   * @FIXME Converting logic temporarily moved here.
   * Reconsider move to another layer.
   */
  return convertObject(response);
};
