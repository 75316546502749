import React, { useState } from "react";
import { MemberCardPlaceholder } from "../MemberCardPlaceholder";
import { Error, FilterTabs } from "../..";
import { MembersGroupedByComponent, MemberListWithTextInput } from ".";
import MembersGroupBy from "../../../../containers/Member/GroupBy";
import {
  groupByType,
  MemberListLayoutProps,
} from "../../../../containers/Member/types";
import { translateSectionDictionaryToList } from "../../../utils/dataMapper";

const MEMBERS_PER_LOAD = 30;

export const MembersListWithFilterTabs: React.FC<MemberListLayoutProps> = ({
  filterSetting,
  disableFilters,
  simpleMode,
  error = null,
  isMemberMixedLayout = false,
  data,
  filteredData,
  searchedData,
  tabs,
  query,
  changeQuery,
  changeFilterType,
  clearFilters,
  scrollableRef,
  showPlaceholder,
}) => {
  const { categoryFilters } = filterSetting;
  const [activeTab, setActiveTab] = useState<groupByType>("members");
  const [countOfLoadedNumbers, setCountOfLoadedNumbers] = useState<number>(
    MEMBERS_PER_LOAD
  );

  const isMemberListFiltersVisible = !disableFilters && tabs;

  if (showPlaceholder) {
    return <MemberCardPlaceholder />;
  } else if (error) {
    return <Error content={error} />;
  }

  const loadMoreMembers = (maxVisibleMembersNumber: number) => (): void => {
    setCountOfLoadedNumbers(prevState => {
      const currentVisibleMembersLength = prevState + MEMBERS_PER_LOAD;

      return currentVisibleMembersLength >= maxVisibleMembersNumber
        ? maxVisibleMembersNumber
        : currentVisibleMembersLength;
    });
  };

  const memberListContentProps = {
    simpleMode,
    isMemberMixedLayout,
    countOfLoadedNumbers,
    loadMoreMembers,
  };

  const listData =
    filteredData || translateSectionDictionaryToList(searchedData) || data;

  const memberListWithTextInputProps = {
    categoryFilters,
    disableFilters,
    scrollableRef,
    query: query ?? "",
    data: listData,
    allMembersLength: listData.length,
    changeQuery,
    changeFilterType,
    clearFilters,
    ...memberListContentProps,
  };

  return (
    <div>
      {isMemberListFiltersVisible && (
        <div className="filter-wrapper">
          <FilterTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      )}

      {activeTab === "members" ? (
        <MemberListWithTextInput {...memberListWithTextInputProps} />
      ) : (
        <MembersGroupBy
          Layout={MembersGroupedByComponent}
          segment={activeTab}
          {...memberListContentProps}
        />
      )}
    </div>
  );
};
