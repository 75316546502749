import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import {
  Callback,
  MatchProps,
  PostFile,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";

import { getGroupFiles } from "./action";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";
import { getGroupFilesState, getGroupFilesHasMoreState } from "./selector";

export type PostFilesStateProps = {
  user: UserDataState | {};
  setting: SettingState | {};
  data: PostFile[] | null | undefined;
  hasMore: boolean;
};

export type PostFilesDispatchProps = {
  getGroupFiles: (
    groupId: string,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
};

export type PostFilesLayoutProps = {
  data: PostFile[] | null | undefined;
  error: string | null;
  hasMore: boolean;
  loading: boolean;
  refreshing: boolean;
  style: any;

  openFile: (url?: string) => void;
  reFetch: (
    next: boolean,
    refresh: boolean,
    callback?: Callback
  ) => Promise<void>;
};

export type PostFilesOwnProps = {
  Layout: ComponentType<PostFilesLayoutProps>;
  style?: any;
};

export type PostFilesProps = PostFilesStateProps &
  PostFilesDispatchProps &
  PostFilesOwnProps &
  MatchProps;

export type PostFilesState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class PostFiles extends Component<PostFilesProps, PostFilesState> {
  state: PostFilesState = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false, true);
    track("View Screen", {
      Screen: "group-files",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = async (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getGroupFiles, match } = this.props;
    const { refreshing, loading } = this.state;
    const groupId = match.params?.id;

    if (refreshing || loading || !groupId) {
      return;
    }

    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        await getGroupFiles(groupId, next, refresh);

        this.setState({
          loading: false,
          refreshing: false,
          error: null,
        });

        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  onOpenFile = (url?: string): void => {
    const { match } = this.props;
    track("View Group File", {
      "Group ID": match && match.params && match.params.id,
      "File Url": url,
    });
  };

  render = (): JSX.Element => {
    const { Layout, data, style, hasMore } = this.props;
    const { loading, error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        style={style}
        data={data}
        reFetch={this.fetchData}
        openFile={this.onOpenFile}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): PostFilesStateProps => {
  const groupId = ownProps.match.params?.id as string;

  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getGroupFilesState(state, groupId),
    hasMore: getGroupFilesHasMoreState(state, groupId),
  };
};

const mapDispatchToProps = {
  getGroupFiles: getGroupFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostFiles);
