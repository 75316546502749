import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { CardBody, Col, CustomInput } from "reactstrap";

import {
  CategoryFilter,
  CategoryFilterId,
} from "../../../../containers/Member/types";
import { pluralize } from "../../../../lib/utils";
import { useTheme } from "../../../hooks";

interface Props {
  categoryFilters?: CategoryFilter[];
  categorySelected: string[];
  setCategorySelected: React.Dispatch<React.SetStateAction<string[]>>;
  filter?: (type: CategoryFilterId, value: string, filter: boolean) => void;
}

export const CollapsingMembersFilter: FC<Props> = ({
  categoryFilters,
  categorySelected,
  setCategorySelected,
  filter,
}) => {
  const { t } = useTranslation("Member");
  const {
    tag: { backgroundColor },
  } = useTheme();

  const defaultValue = t("CollapsingMemberFilter.Select");

  const getCategoryTitle = (name: string, count?: number): string => {
    const membersName = count
      ? ` (${count} ${pluralize(t("MembersListFilters.Button.Member"), count)})`
      : "";

    return ` ${name}${membersName}`;
  };

  const onCategoryFilterChange = (
    categoryIndex: number,
    id: CategoryFilterId,
    name: string
  ): void => {
    setCategorySelected(prevState => {
      prevState[categoryIndex] = name;
      return prevState;
    });
    filter && filter(id, name, true);
  };

  const options = (category: CategoryFilter): JSX.Element[] =>
    category.data.map((categoryItem, index) => (
      <option
        id={`memberFilter${category.name}SelectOption`}
        value={categoryItem.name}
        key={index}>
        {getCategoryTitle(categoryItem.name, categoryItem.count)}
      </option>
    ));

  return (
    <CardBody className={"pt-1"} style={{ backgroundColor, display: "flex" }}>
      {categoryFilters &&
        categoryFilters.map((category, categoryIndex) => {
          if (category.data.length < 1) {
            return <div key={category.id} />;
          }

          return (
            <Col className={"pr-2 pl-1"} key={category.id}>
              <h6 className="text-capitalize">{category.name}</h6>
              <CustomInput
                id={`membersFilter${category.name}Select`}
                type="select"
                onChange={(event): void => {
                  onCategoryFilterChange(
                    categoryIndex,
                    category.id,
                    event.target.value
                  );
                }}
                defaultValue={defaultValue}
                value={categorySelected[categoryIndex] ?? defaultValue}>
                {options(category)}
                <option hidden label={defaultValue} value={defaultValue} />
              </CustomInput>
            </Col>
          );
        })}
    </CardBody>
  );
};
