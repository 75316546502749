import { runFunction } from "../../http";

type Options = {
  groupId: string;
  canPostToPrivateGroup: boolean;
};

export const canPostToGroup = async (options: Options): Promise<boolean> => {
  return runFunction("checkGroupPermission", options, { requireUser: true });
};
