import React from "react";

import { Switch, Route, RouteProps } from "react-router-dom";
import { Col } from "reactstrap";
import { TemplateRightSidebar } from "../components/Template";

export type SwitchRightColProps = {
  customConfig?: RouteProps[];
  disabledPaths?: string[];
};

export const SwitchRightCol: React.FC<SwitchRightColProps> = ({
  customConfig,
  disabledPaths,
}) => {
  const disabledRouteElement = disabledPaths && <Route path={disabledPaths} />;

  const customRouteElements = customConfig?.map((route, index) => (
    <Route key={index} {...route} />
  ));

  return (
    <Switch>
      {disabledRouteElement}
      <Route>
        <Col lg={4} xl={3} className="invisible p-0 right-column-col">
          <div className="gf-column-right">
            <Switch>
              {customRouteElements}
              <Route component={TemplateRightSidebar} />
            </Switch>
          </div>
        </Col>
      </Route>
    </Switch>
  );
};
