import React from "react";

import AddToCalendar from "react-add-to-calendar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CardBody, Button, Col, Row } from "reactstrap";
import { isEmpty, isUndefined } from "underscore";

import {
  cleanHTMLTags,
  getCalendarDate,
  isPastEvent,
  getEventLocationType,
} from "../../../lib/utils";

import {
  CommentResponse,
  CommentType,
  Dict,
  EventAttendee,
  EventLocation,
  EventRecap,
  EventSingle,
  ReactionType,
} from "../../../types";
import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

import { CommentBar } from "../Comment";
import { MemberBar } from "../Member";
import { TagList } from "../Tag";
import { MediaFileList } from "../Media";
import { HTML, Heading } from "../UI";

import {
  EventCardAttendees,
  EventItemsCollapse,
  EventInfoHeader,
  EventFee,
} from ".";

export type EventDetailTabsDetailsProps = {
  attendees?: {
    total: number;
    newest: EventAttendee[];
    all: EventAttendee[];
  };
  clientHostName: string;
  commentCount: number;
  comments?: CommentResponse[];
  confettiEffectActions: [];
  event: EventSingle;
  eventLocation?: EventLocation;
  isEditor: boolean;
  myAttendee?: EventAttendee;
  isLiked: boolean;
  isRsvpEnabled?: boolean;
  likeCount: number;
  reactionCount?: Partial<Dict<number, ReactionType>>;
  reaction?: ReactionType;
  recap: EventRecap | null;
  seenCount?: number;
  showCommentBar: boolean;
  subtitle?: string;
  totalFees: number;
  willBeInWaitingList?: boolean;

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  onAttendeeClick: () => void;
  onAttendeesClick: () => void;
};

/**
 * @FIXME
 * Should we implement any refresh handling?
 */
export const EventDetailTabsDetails: React.FC<EventDetailTabsDetailsProps> = ({
  attendees,
  clientHostName,
  commentCount,
  confettiEffectActions,
  event,
  eventLocation,
  isEditor,
  isLiked,
  isRsvpEnabled,
  likeCount,
  myAttendee,
  reaction,
  reactionCount,
  recap,
  seenCount,
  showCommentBar,
  totalFees,
  willBeInWaitingList,
  doReaction,
  onAttendeeClick,
  onAttendeesClick,
}) => {
  const {
    attendeeDeadline,
    author,
    disableAttendees,
    endAt,
    id,
    items,
    locationDetails = {},
    locationType = "",
    partnersAllowed,
    rsvpEnabled,
    speakerBio,
    speaker,
    startAt,
    tags,
    title,
    mentions,
    linksMentions,
  } = event;
  let { attachments, description, strapLine } = event;

  const { t } = useTranslation("Event");

  const history = useHistory();

  const {
    card,
    button,
    detail,
    event: eventStyle,
    text: { subHeader, cardText, listText },
  } = useTheme();

  //If this's recap, should get title, strapLine, description, attachments from recap

  if (recap) {
    description = recap.description || "";
    attachments = recap.attachments;
    strapLine = recap.strapLine;
  }

  const eventDetailTabsDetailsStyles = {
    rsvpText: {
      ...eventStyle.rsvp.text,
      ...textThemingWorkaround,
      fontSize: 16,
    },
    listText: {
      ...listText,
      ...textThemingWorkaround,
      paddingBottom: 5,
    },
    buttonText: {
      ...button.text,
      ...textThemingWorkaround,
      fontSize: 16,
    },
    messageGuestsButton: {
      ...button,
      borderColor: button.backgroundColor,
      boxShadow: `${button.shadowOffset.width}px ${
        button.shadowOffset.height
      }px ${button.shadowRadius * 3}px   rgb(0, 0, 0, 0.3)`,
    },
    subHeader: {
      ...subHeader,
      ...textThemingWorkaround,
    },
    cardItem: {
      backgroundColor: "transparent",
    },
    cardText: {
      ...cardText,
      ...textThemingWorkaround,
    },
    dropDownStyle: {
      ...button.text,
      ...textThemingWorkaround,
      fontSize: 16,
      width: "-webkit-fill-available",
      textAlign: undefined,
      padding: 0,
    },
    attendeesHeading: {
      ...cardText,
      ...textThemingWorkaround,
      marginLeft: 10,
    },
    speakerBio: {
      ...cardText,
      ...textThemingWorkaround,
      color: button.backgroundColor,
      textDecoration: "none",
    },
    cardBody: {
      ...card,
      ...detail.card,
    },
  } as const;

  const isAttended = myAttendee && myAttendee.response === "yes";
  const isPast = isPastEvent(endAt);
  const _rsvpEnabled =
    isRsvpEnabled && (isUndefined(rsvpEnabled) || rsvpEnabled);

  const subtitleElement = strapLine && (
    <p className="mb-0 mt-3" style={eventDetailTabsDetailsStyles.listText}>
      {strapLine}
    </p>
  );

  let rsvpButtonStyle: React.CSSProperties = eventStyle.rsvp;

  let rsvpText = disableAttendees
    ? t("Detail.Button.Reply.By", { attendeeDeadline })
    : t("Card.Button.Rsvp");

  let rsvpExtraText;

  /** @TODO This logic should be propably moved to container */
  const rsvpExtraPartnerText =
    myAttendee && myAttendee.partnerResponse === "yes"
      ? t("Detail.Text.Spouse.Partner.Attending")
      : t("Detail.Text.Spouse.Partner.Not.Attending");
  if (isAttended) {
    rsvpButtonStyle = eventStyle.yes;
    if (myAttendee?.isWaiting) {
      rsvpButtonStyle = eventStyle.wait;
    }
    rsvpText = t("Detail.Button.Change.Rsvp");
    rsvpExtraText = (
      <span
        className={"d-flex"}
        style={{
          ...eventDetailTabsDetailsStyles.listText,
          color: rsvpButtonStyle.color,
        }}>
        {myAttendee?.isWaiting
          ? t("Detail.Text.Joined.Waitlist")
          : t("Detail.Text.Attending")}{" "}
      </span>
    );
  } else if (willBeInWaitingList) {
    rsvpButtonStyle = eventStyle.wait;
    rsvpText = t("Detail.Button.Join.WaitList");
  } else if (myAttendee && myAttendee.response === "no") {
    rsvpButtonStyle = eventStyle.no;
    rsvpText = t("Detail.Button.Change.Rsvp");
    rsvpExtraText = (
      <span
        className={"d-flex"}
        style={{
          ...eventDetailTabsDetailsStyles.listText,
          color: rsvpButtonStyle.color,
        }}>
        {t("Detail.Text.You.Not.Attending")}
      </span>
    );
  }

  /** Styles*/
  const rsvpButtonStyles = {
    ...eventStyle.rsvp,
    ...rsvpButtonStyle,
  };

  const rsvpTextStyle = {
    ...eventDetailTabsDetailsStyles.rsvpText,
    ...rsvpButtonStyle,
  };

  const finalRsvpButtonStyle = {
    ...rsvpButtonStyles,
    ...button.roundLeft,
    borderColor: rsvpButtonStyles.borderColor,
  };

  const finalCalendarButtonStyle = {
    ...rsvpButtonStyles,
    ...button.roundRight,
    backgroundColor: rsvpButtonStyles.borderColor,
    borderColor: rsvpButtonStyles.borderColor,
    color: "#fff",
  };

  const rsvpButtonText = <span style={rsvpTextStyle}>{rsvpText}</span>;

  const { notAddressType, locationContent } = getEventLocationType(
    locationType,
    locationDetails
  );

  let location = eventLocation
    ? eventLocation?.address || eventLocation?.venue
    : "";

  if (notAddressType) {
    location = locationContent.title;
  }

  const eventData = {
    title: title,
    description: `${
      locationContent.body ? locationContent.body + "\n\n" : ""
    }${cleanHTMLTags(description)}`,
    location: location,
    startTime: getCalendarDate(startAt),
    endTime: getCalendarDate(endAt),
  };

  const rsvpElements = !isPast && (
    <div className="my-4">
      {rsvpExtraText}
      {partnersAllowed && isAttended && (
        <span
          className={"d-flex"}
          style={eventDetailTabsDetailsStyles.listText}>
          {rsvpExtraPartnerText}
        </span>
      )}
      <Row noGutters={true}>
        <Col>
          <Button
            id={`eventDetailRsvpButton${event.id}`}
            className="mr-2"
            style={finalRsvpButtonStyle}
            block={true}
            color="primary"
            disabled={!_rsvpEnabled || disableAttendees}
            onClick={onAttendeeClick}>
            {rsvpButtonText}
          </Button>
        </Col>
        <Col>
          <Button
            id={`eventDetailAddToCalendarButton${event.id}`}
            block={true}
            color="primary"
            className={"p-0"}
            style={finalCalendarButtonStyle}>
            <div style={eventDetailTabsDetailsStyles.dropDownStyle}>
              <AddToCalendar
                event={eventData}
                displayItemIcons={false}
                buttonTemplate={{ "angle-down": "right" }}
                buttonLabel={t("Detail.Button.Add.To.Calendar")}
              />
            </div>
          </Button>
        </Col>
      </Row>
    </div>
  );

  const itemCollapseElement = totalFees > 0 && (
    <EventItemsCollapse items={items} className="my-4 pl-2" />
  );

  const authorObjectId =
    typeof author !== "string" && !!author && author.objectId;

  const authorHref = `/member/${authorObjectId}`;
  const handleAuthorClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    history.push(authorHref);
  };

  const authorElement = !!author && (
    <div
      id={`eventDetailAuthor${event.id}`}
      className="my-4 pl-2"
      style={eventDetailTabsDetailsStyles.cardItem}>
      <Heading
        className="d-flex mb-2"
        style={eventDetailTabsDetailsStyles.subHeader}
        text={t("Detail.Text.Organizer")}
      />
      <Row className={"m-0"}>
        <a
          className="text-decoration-none"
          href={authorHref}
          onClick={handleAuthorClick}>
          <MemberBar
            name={
              typeof author !== "string"
                ? `${author.firstName} ${author.lastName}`
                : author
            }
            src={
              typeof author !== "string" ? author.profile?.thumbUrl : undefined
            }
          />
        </a>
      </Row>
    </div>
  );

  const attendeesElement = attendees && attendees.total > 0 && (
    <>
      <div
        id={`eventDetailAttendeesList${event.id}`}
        className="my-4 pl-2"
        style={eventDetailTabsDetailsStyles.cardItem}>
        <div className="d-flex mb-2">
          <span
            className="d-flex"
            style={eventDetailTabsDetailsStyles.subHeader}>
            {attendees.total <= 1
              ? t("Detail.Text.Attendee")
              : t("Detail.Text.Attendee.Plural")}
          </span>
        </div>
        <EventCardAttendees
          attendees={attendees}
          onTotalAttendeesClick={onAttendeesClick}
        />
      </div>
    </>
  );

  const speakerElement = !!speaker && (
    <div className="my-4 pl-2" style={eventDetailTabsDetailsStyles.cardItem}>
      <Heading
        className="d-flex mb-2"
        style={eventDetailTabsDetailsStyles.subHeader}
        text={t("Detail.Text.Speaker")}
      />
      {speakerBio ? (
        <a
          style={eventDetailTabsDetailsStyles.speakerBio}
          target="_blank"
          rel="noopener noreferrer"
          href={speakerBio}>
          {speaker}
        </a>
      ) : (
        <span style={eventDetailTabsDetailsStyles.cardText}>{speaker}</span>
      )}
    </div>
  );

  const attachmentsListElement = attachments && attachments.length > 0 && (
    <div style={eventDetailTabsDetailsStyles.subHeader}>
      <MediaFileList
        className="pl-2 pr-0 py-0 my-4"
        data={attachments}
        header={t("Detail.Text.Attachments")}
        headerClassName="mb-2"
      />
    </div>
  );

  const hasBody =
    description && !isEmpty(description) && description !== "<p><br></p>";

  const bodyElement = !!hasBody && (
    <div className="my-4 small pl-2">
      <Heading
        className="d-flex mb-2"
        style={eventDetailTabsDetailsStyles.subHeader}
        text={t("Detail.Text.About.Event")}
      />
      {hasBody && (
        /** @TODO Implement mentions and linkMentions */
        <HTML
          body={description}
          mentions={mentions}
          linksMentions={linksMentions}
        />
      )}
      {
        /** @FIXME Why tags are displayed only when body present? */
        <TagList data={tags} type="event" className="pt-2 px-3" />
      }
    </div>
  );

  const commentBarElement = showCommentBar && (
    <CommentBar
      isLiked={isLiked}
      commentCount={commentCount}
      likeCount={likeCount}
      reactionCount={reactionCount}
      reaction={reaction}
      seenCount={seenCount}
      doReaction={doReaction}
      setting={{
        ClientHostName: clientHostName,
        confettiEffectActions,
      }}
      id={id}
      type="event"
    />
  );

  return (
    <CardBody
      style={eventDetailTabsDetailsStyles.cardBody}
      className="px-3 py-0">
      {subtitleElement}
      <EventInfoHeader
        event={event}
        // setting={{}}
        className="mt-4 mb-3 pl-2"
      />
      <EventFee
        className="mb-4 pl-2"
        fee={totalFees}
        style={eventDetailTabsDetailsStyles.listText}
      />
      {itemCollapseElement}
      {rsvpElements}
      {authorElement}
      {attendeesElement}
      {speakerElement}
      {attachmentsListElement}
      {bodyElement}
      {commentBarElement}
    </CardBody>
  );
};
