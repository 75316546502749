import React, { Component } from "react";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { isArray } from "underscore";

import { track } from "../../lib/track";

import {
  Dict,
  Group,
  Member,
  RootState,
  SettingConfigData,
  SettingLocalConfigData,
  UserDataState,
  SettingSettingData,
} from "../../types";

import { createChannel } from "../Chat/action";
import { getGroup } from "../Group/action";
import { logInAsGuest } from "../User/action";

import {
  getMember,
  getMemberByProfile,
  addFollows,
  removeFollows,
} from "./action";
import { MemberDetailLayoutProps } from "./types";

export type MemberDetailOwnProps = {
  Layout: React.ComponentType<MemberDetailLayoutProps>;
  LayoutBB: React.ComponentType<MemberDetailLayoutProps>;

  /** Routing props */
  match?: {
    params?: { id?: string; profileId?: string };
  };
};

export type MemberDetailStateProps = {
  item?: Member | UserDataState;
  isMyProfile: boolean;
  isAdmin: boolean;
  setting: {
    ClientHostName: string;
    followingEnabled: boolean | undefined;
    blenderBoxEnabled: boolean;
    adminViewAsUserEnabled: boolean;
  };
};

export type MemberDetailDispatchProps = {
  createChannel: (
    users: { id: string }[],
    name?: string,
    coverFile?: string | File
  ) => Promise<{ data: string }>;
  addFollows: (userId: string) => Promise<void>;
  getGroup: (id: string) => Promise<{ data?: Group }>;
  getMember: (
    userId: string,
    refresh: boolean
  ) => Promise<{
    data?: { id: string; data: Member };
  }>;
  getMemberByProfile: (
    profileId: string,
    shouldStartRefreshing: boolean
  ) => Promise<{
    data: { id: string; data: Member };
  }>;
  removeFollows: (userId: string) => Promise<void>;
  logInAsGuest: (userId: string) => Promise<void>;
};

export type MemberProps = MemberDetailOwnProps &
  MemberDetailStateProps &
  MemberDetailDispatchProps;

export type MemberDetailState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  options: {
    Industry: Dict<Group>;
    Region: Dict<Group>;
  };
  showMembersModal?: boolean;
};

class MemberDetail extends Component<
  MemberProps & WithTranslation,
  MemberDetailState
> {
  state: MemberDetailState = {
    error: null,
    loading: false,
    refreshing: false,
    options: {
      Industry: {},
      Region: {},
    },
  };
  _isMounted = false;

  componentDidMount = async (): Promise<void> => {
    this._isMounted = true;
    this.fetchData(true);
    track("View Screen", {
      Screen: "profile",
      Params: this.props.match && this.props.match.params,
    });
  };

  componentDidUpdate = async (prevProps: MemberProps): Promise<void> => {
    const id = this.props.match?.params?.id;
    const profileId = this.props.match?.params?.profileId;

    if (
      (id && prevProps.match?.params?.id !== id) ||
      (profileId && prevProps.match?.params?.profileId !== profileId)
    ) {
      await this.fetchData(true);
    }
  };

  componentWillUnmount = (): void => {
    this._isMounted = false;
  };

  fetchData = async (
    refresh: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getMember, match, getMemberByProfile } = this.props;
    const { refreshing, loading } = this.state;
    const id = match && match.params && match.params.id;
    const profileId = match && match.params && match.params.profileId;

    if (refreshing || loading || (!id && !profileId) || !this._isMounted) {
      return;
    }
    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        let request;
        if (id) {
          request = getMember(id, refresh);
        } else if (profileId) {
          request = getMemberByProfile(profileId, refresh);
        }

        const resp = await request;

        this._isMounted &&
          this.setState({ loading: false, refreshing: false, error: null });
        callback && callback();

        if (resp && resp.data) {
          this.fetchExtraData(resp.data);
        }
      } catch (error) {
        this._isMounted &&
          this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  fetchExtraData = (user: { id: string; data: Member }): void => {
    if (!this._isMounted) return;

    const { setting, getGroup } = this.props;
    if (setting.blenderBoxEnabled) {
      const options: {
        Industry: Dict<Group>;
        Region: Dict<Group>;
      } = {
        Industry: {},
        Region: {},
      };

      /**
       * @TODO Profile could be undefined so I added extra condition below
       */
      const { Region, Industry } =
        // user.data && user.data.profile.sections["Professional Info"];
        (user.data &&
          user.data.profile &&
          user.data.profile.sections["Professional Info"]) ||
        {};

      if (isArray(Industry)) {
        Industry.forEach(async (id: string) => {
          const group = await getGroup(id);
          if (group.data) {
            options.Industry[id] = group.data;
            this._isMounted && this.setState({ options });
          }
        });
      }
      if (isArray(Region)) {
        Region.forEach(async id => {
          const group = await getGroup(id);
          if (group.data) {
            options.Region[id] = group.data;
            this._isMounted && this.setState({ options });
          }
        });
      }
    }
  };

  onOpenMemberPicker = (): void => {
    const { showMembersModal } = this.state;
    this.setState({ showMembersModal: !showMembersModal });
  };

  onAddFollows = async (
    userId: string,
    callback: (message: string) => void
  ): Promise<void> => {
    await this.props.addFollows(userId);
    callback && callback(this.props.t("Container.Detail.Callback.1"));
  };

  onRemoveFollows = async (
    userId: string,
    callback: (message: string) => void
  ): Promise<void> => {
    await this.props.removeFollows(userId);
    callback && callback(this.props.t("Container.Detail.Callback.2"));
  };

  onAddContact = (): void => {
    const { item } = this.props;
    if (item) {
      track("Download Contact", {
        "Member ID": item.profile && item.profile.objectId,
        "Member Name": `${item.name}`,
      });
    }
  };
  onLoginAsThisUser = (): void => {
    const { item, logInAsGuest } = this.props;
    const { loading } = this.state;
    if (item && !loading) {
      this.setState({ loading: true }, async () => {
        try {
          await logInAsGuest(item.objectId as string);
          this.setState({ error: null, loading: false });
        } catch (error) {
          this.setState({ error: error, loading: false });
        }
      });
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      LayoutBB,
      item,
      setting,
      createChannel,
      isMyProfile,
      isAdmin,
    } = this.props;
    const {
      error,
      loading,
      refreshing,
      showMembersModal,
      options,
    } = this.state;
    const MainLayout = setting.blenderBoxEnabled ? LayoutBB : Layout;
    return (
      <MainLayout
        error={error}
        loading={loading}
        refreshing={refreshing}
        setting={setting}
        isMyProfile={isMyProfile}
        isAdmin={isAdmin}
        data={item}
        options={options}
        reFetch={this.fetchData}
        createChannel={createChannel}
        showMembersModal={showMembersModal}
        openMemberPicker={this.onOpenMemberPicker}
        addFollows={this.onAddFollows}
        removeFollows={this.onRemoveFollows}
        addContact={this.onAddContact}
        loginAsThisUser={this.onLoginAsThisUser}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MemberDetailOwnProps
): MemberDetailStateProps => {
  const id =
    ownProps.match && ownProps.match.params && ownProps.match.params.id;

  const profileId =
    ownProps.match && ownProps.match.params && ownProps.match.params.profileId;

  const config = state.setting.config as SettingConfigData;
  const setting = state.setting.setting as SettingSettingData;
  const localConfig = state.setting.localConfig as SettingLocalConfigData;
  const currentUser = state.user.data as UserDataState;
  let data: Member | UserDataState | undefined;

  if (id) {
    data =
      (state.member.items && state.member.items[id]) ||
      (state.member.cached && state.member.cached[id]);
  } else if (profileId) {
    data = state.member.cached && state.member.cached[profileId];
  }

  let isMyProfile = false;

  if (id === currentUser.id) {
    data = currentUser;
    isMyProfile = true;
  }

  return {
    setting: {
      ClientHostName: localConfig.client_url,
      followingEnabled:
        config.blenderboxImprovements || config.followingEnabled,
      blenderBoxEnabled: config.blenderboxImprovements,
      adminViewAsUserEnabled: setting.admin_view_as_user_enabled,
    },
    item: data,
    isMyProfile,
    isAdmin: currentUser.admin,
  };
};
const mapDispatchToProps = {
  getMember,
  getMemberByProfile,
  createChannel,
  addFollows,
  removeFollows,
  getGroup,
  logInAsGuest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Member")(MemberDetail));
