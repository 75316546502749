import _ from "underscore";
import { runFunction } from "../../http";
import { CommentType, CommentResponse } from "../../../types/Comment";
import { CommentRequest } from "../../../types/Comment/CommentRequest";
import { Group } from "../../../types/Group";
import { convertObject } from "../../../lib/utils";
import { commentsTypes } from "../../../constants/Comment";
import { parseMentions } from "../ParseMentionsHelper";

type Options = {
  id: string;
  type: CommentType;
} & CommentRequest;

export const addComment = async (
  options: Options
): Promise<{
  group: Parse.Object<Group> | undefined;
  comment: CommentResponse;
}> => {
  console.debug("[Service] addComment");

  const { id, type, text, mentions, linksMentions, userId } = options;
  const option = {
    eventId: id,
    announcementId: id,
    postId: id,
    eventPhotoId: id,
    body: text,
    mentions: _.map(mentions || [], item => JSON.stringify(item)),
    linksMentions: _.map(linksMentions || [], item => JSON.stringify(item)),
    userId,
  };
  const parseResponse: {
    comment: Parse.Object<CommentResponse>;
    group: Parse.Object<Group>;
  } = await runFunction(commentsTypes[type].functionAddComment, option, {
    requireUser: true,
  });
  return {
    group: parseResponse.group,
    comment: parseMentions<CommentResponse>(
      convertObject(parseResponse.comment)
    ),
  };
};
