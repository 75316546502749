import React from "react";

import Skeleton from "react-loading-skeleton";
import { Card, CardBody } from "reactstrap";

import { textThemingWorkaround } from "../../../../constants";

import { useTheme } from "../../../hooks";

import { Heading } from "../../UI";

export type AnnouncementCardPlaceholderProps = {};

export const AnnouncementCardPlaceholder: React.FC<AnnouncementCardPlaceholderProps> = () => {
  const {
    card,
    text: { cardText, header, subHeader },
  } = useTheme();

  const announcementCardStyles = {
    authorHeading: {
      ...cardText,
      ...textThemingWorkaround,
      display: "inline-block",
    },
    card: { backgroundColor: card.backgroundColor },
    cardBody: {
      backgroundColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
    },
    cardText: { ...cardText, ...textThemingWorkaround },
    headerCardTypeName: {
      ...subHeader,
      ...textThemingWorkaround,
      opacity: 0.8,
    },
    headerDate: { ...subHeader, ...textThemingWorkaround },
    titleHeading: { ...header, ...textThemingWorkaround },
  } as const;

  return (
    <Card className="announcement-card mb-3">
      <CardBody
        className="d-flex p-3"
        style={{
          backgroundColor: "transparent",
          paddingLeft: 0,
          paddingRight: 0,
        }}>
        <div
          className="flex-fill text-left"
          style={announcementCardStyles.headerCardTypeName}>
          <Skeleton width={150} />
        </div>
        <div
          className="flex-fill text-right"
          style={announcementCardStyles.headerDate}>
          <Skeleton width={70} />
        </div>
      </CardBody>
      <CardBody className="py-0" style={announcementCardStyles.cardBody}>
        <Skeleton height={270} style={{ borderRadius: 0 }} />
      </CardBody>
      <CardBody
        className="pt-3 pb-2 px-3"
        style={announcementCardStyles.cardBody}>
        <Heading style={announcementCardStyles.titleHeading}>
          <Skeleton />
        </Heading>
      </CardBody>
      <CardBody className="pt-0 px-3">
        <CardBody
          className="pt-1 pb-0"
          style={{
            backgroundColor: "transparent",
            paddingLeft: 0,
            paddingRight: 0,
          }}>
          <Heading
            className="pt-2 pb-0 mb-0 small"
            style={announcementCardStyles.cardText}>
            <Skeleton count={5} />
          </Heading>
        </CardBody>
      </CardBody>
    </Card>
  );
};
