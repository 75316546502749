import React from "react";

import { RouteComponentProps } from "react-router-dom";

import MemberDetailContainer from "../../../containers/Member/Detail";

import { MemberDetail } from "../../components/Member/Detail";
import { MemberDetailBB } from "../../components/Member/DetailBB";

export type RouteMemberProfileProps = RouteComponentProps<{
  id?: string;
}>;

export const RouteMemberProfile: React.FC<RouteMemberProfileProps> = ({
  match,
  ...props
}) => (
  <MemberDetailContainer
    {...props}
    match={{ ...match, params: { profileId: match.params.id } }}
    Layout={MemberDetail}
    LayoutBB={MemberDetailBB}
  />
);
