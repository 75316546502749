import React, { SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Alert, Button, Form, FormGroup, Input, CustomInput } from "reactstrap";

import { SupportData } from "../../../containers/Support";

import { UserDataState, Override } from "../../../types";
import { useTheme } from "../../hooks";
import { textThemingWorkaround } from "../../../constants/Theme";

export type SupportProps = {
  data: SupportData;
  error: string | null;
  loading: boolean;
  options: {
    issueType: {
      id: string;
      name: string;
    }[];
    supportIssueType: {
      id: string;
      name: string;
    }[];
  };
  setting: {
    logo: string;
    organizationName: string;
    signInByEmail?: boolean | undefined;
  };
  user: {} | UserDataState;

  handleChange: <K extends keyof SupportData>(
    key: K,
    value: SupportData[K]
  ) => void;
  sendFeedBack: (callback?: (text: string) => void) => void;
  sendSupport: (callback?: (text: string) => void) => void;
};

export const Support: React.FC<SupportProps> = ({
  data,
  error,
  loading,
  options,

  handleChange,
  sendSupport,
}) => {
  const {
    button: buttonStyle,
    general: { color: generalColor },
  } = useTheme();

  const { t } = useTranslation("Support");

  const { email, phoneNumber, name, description } = data;

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();

    /** @TODO Implement redirect on close */
    sendSupport(toast.success);
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      Override<HTMLInputElement, { name: keyof SupportData }>
    >
  ): void => {
    handleChange(e.target.name, e.target.value);
  };

  const errorAlertElement = error && <Alert color="danger">{error}</Alert>;

  const issueTypeOptionElements = options.supportIssueType.map(issueType => (
    <option
      /** @FIXME id value should be unique */
      id={`supportSelectIssueTypeDropdown${issueType.id}`}
      key={issueType.id}
      value={issueType.name}>
      {issueType.name}
    </option>
  ));

  return (
    <Form>
      {errorAlertElement}
      <FormGroup>
        <Input
          /**
           * @TODO
           * Design temporarily implemented via classes. Needs reconsideration
           * and refactor.
           */
          className="border-top-0 border-left-0 border-right-0 border-bottom rounded-0"
          type="email"
          name="email"
          id="supportEmailInput"
          placeholder={t("Index.Placeholder.Email")}
          value={email}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        {/**
         * @TODO
         * Implement country code prefix picker and focus on input then (ref)
         */}
        <Input
          /**
           * @TODO
           * Design temporarily implemented via classes. Needs reconsideration
           * and refactor.
           */
          className="border-top-0 border-left-0 border-right-0 border-bottom rounded-0"
          type="tel"
          name="phoneNumber"
          id="supportPhoneNumberInput"
          placeholder={t("Index.Placeholder.Phone")}
          value={phoneNumber}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Input
          /**
           * @TODO
           * Design temporarily implemented via classes. Needs reconsideration
           * and refactor.
           */
          className="border-top-0 border-left-0 border-right-0 border-bottom rounded-0"
          type="text"
          name="name"
          id="supportNameInput"
          placeholder={t("Index.Placeholder.Name")}
          value={name}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          id="SupportIssueTypeSelectorDropdown"
          name="issueType"
          style={{
            borderColor: generalColor,
            borderRadius: buttonStyle.borderRadius,
            color: generalColor,
            /** @TODO Implement fonts first */
            // fontFamily: buttonStyle.text.fontFamily,
          }}
          type="select"
          onChange={handleInputChange}>
          <option>{t("Index.Placeholder.Issue.Type")}</option>
          {issueTypeOptionElements}
        </CustomInput>
      </FormGroup>
      <FormGroup>
        <Input
          /**
           * @TODO
           * Design temporarily implemented via classes. Needs reconsideration
           * and refactor.
           */
          className="border-top-0 border-left-0 border-right-0 border-bottom rounded-0"
          id="supportIssueDescriptionInput"
          name="description"
          placeholder={t("Index.Placeholder.Description")}
          style={{
            backgroundColor: "#F5F7FA",
            // borderColor: "#F5F7FA",
          }}
          type="textarea"
          value={description}
          onChange={handleInputChange}
        />
      </FormGroup>

      <Button
        id="supportSubmitButton"
        block
        color="primary"
        disabled={loading}
        style={{
          ...buttonStyle,
          ...{
            ...buttonStyle.text,
            ...textThemingWorkaround,
            textAlign: undefined,
          },
        }}
        onClick={handleSubmit}>
        {loading ? t("Index.Button.Loading") : t("Index.Button.Submit")}
      </Button>
    </Form>
  );
};
