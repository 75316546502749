import React, { Component } from "react";

import Lightbox from "react-image-lightbox";

import "./Modal.css"; // This only needs to be imported once in your app

export type ModalProps = {
  caption?: React.ReactNode;
  images: string[];
  index: number;
  title?: React.ReactNode;

  onClose: () => void;
};

export type ModalState = {
  images: string[];
  currentImageIndex: number;
};

class ModalComponent extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      images: props.images || [],
      currentImageIndex: props.index,
    };

    this.onMovePrevRequest = this.onMovePrevRequest.bind(this);
    this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
  }

  onMovePrevRequest(): void {
    const { currentImageIndex, images } = this.state;

    this.setState({
      currentImageIndex:
        (currentImageIndex + images.length - 1) % images.length,
    });
  }

  onMoveNextRequest(): void {
    const { currentImageIndex, images } = this.state;

    this.setState({
      currentImageIndex: (currentImageIndex + 1) % images.length,
    });
  }

  render(): JSX.Element {
    const { images, currentImageIndex } = this.state;
    const { caption, title, onClose } = this.props;

    return (
      <Lightbox
        reactModalStyle={{
          overlay: { zIndex: 1050 },
        }}
        mainSrc={images[currentImageIndex]}
        nextSrc={images[(currentImageIndex + 1) % images.length]}
        prevSrc={
          images[(currentImageIndex + images.length - 1) % images.length]
        }
        onCloseRequest={onClose}
        onMovePrevRequest={this.onMovePrevRequest}
        onMoveNextRequest={this.onMoveNextRequest}
        imageCaption={caption}
        imageTitle={title}
      />
    );
  }
}

export default ModalComponent;
