/* global window */
import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { DEV, localConfig } from "../constants/config";
import { reducer } from "./reducer";
import { batchedSubscribe } from "redux-batched-subscribe";
import debounce from "lodash.debounce";

const debounceNotify = debounce(notify => notify(), 100);

const logger = createLogger({
  collapsed: true,
  predicate: () => DEV && !localConfig.ignoreReduxLogger,
});

const middleware = [thunk, logger];

const configureStore = () => {
  // Allows us to use redux devtools when it exists
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducer,
    composeEnhancer(
      applyMiddleware(...middleware),
      batchedSubscribe(debounceNotify)
    )
  );
  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return { persistor, store };
};

export default configureStore;
