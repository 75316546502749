import React from "react";

import { CommentUserListCardPlaceholder } from ".";

export type CommentSeenByPlaceholderProps = {};

export const CommentSeenByPlaceholder: React.FC<CommentSeenByPlaceholderProps> = () => (
  <>
    <CommentUserListCardPlaceholder />
    <CommentUserListCardPlaceholder />
    <CommentUserListCardPlaceholder />
  </>
);
