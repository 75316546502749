import {
  MEDIA_PHOTO_GIPHY_SET,
  MEDIA_PHOTO_STOCK_SET,
  MEDIA_PHOTO_USED_SET,
  MEDIA_PHOTO_WEB_SET,
  MEDIA_RESET,
  MEDIA_SETTING_SET,
  MEDIA_VIDEO_LIBRARY_SET,
  MEDIA_VIDEO_USED_SET,
} from "./constants";
import Store from "./store";
import { MediaAction, MediaState } from "./types";

export const initialState = Store;

export default function reducer(
  state: MediaState = initialState,
  action: MediaAction
): MediaState {
  switch (action.type) {
    case MEDIA_RESET:
      return initialState;

    case MEDIA_PHOTO_GIPHY_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          photoGiphy: action.data,
        };
      }

      return state;

    case MEDIA_PHOTO_STOCK_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          photoStock: action.data,
        };
      }

      return state;

    case MEDIA_PHOTO_WEB_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          photoWeb: action.data,
        };
      }

      return state;

    case MEDIA_PHOTO_USED_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          photoUsed: action.data,
        };
      }

      return state;

    case MEDIA_VIDEO_USED_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          videoUsed: action.data,
        };
      }

      return state;

    case MEDIA_VIDEO_LIBRARY_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          videoLibrary: action.data,
        };
      }

      return state;

    case MEDIA_SETTING_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          setting: action.data,
        };
      }

      return state;

    default:
      return state;
  }
}
