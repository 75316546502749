import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { AnnouncementItem, DictStrict } from "../../../types";

import { runFunction } from "../../http";

export type ServiceApiGetAnnouncementsOptions = {
  spinner?: boolean;
  error?: boolean;
  notify?: boolean;
  name?: string;
  per?: number;
  page?: number;
  showComments?: boolean;
  mapByEvent?: boolean;
  filterIds?: {};
};

export const getAnnouncements = async (
  options: ServiceApiGetAnnouncementsOptions
): Promise<DictStrict<AnnouncementItem>> => {
  console.debug("[ServiceApi] getAnnouncements");

  const parseResponse: AnnouncementItem[] = await runFunction(
    "listAnnouncementsV2",
    options,
    {
      requireUser: true,
    }
  );

  /**
   * @FIXME Converting logic temporarily moved here.
   * Reconsider move to another layer.
   */
  const mapByEventId = options.mapByEvent || true;
  const convertedParseResponse = convertObject(parseResponse);
  return _.object(
    _.map(convertedParseResponse, (value: AnnouncementItem) => [
      value.announcement.event &&
      value.announcement.for === "event" &&
      mapByEventId
        ? value.announcement.event.objectId
        : value.announcement.objectId,
      value,
    ])
  );
};
