import React from "react";

import { RouteComponentProps } from "react-router-dom";

import GroupDetailContainer from "../../../containers/Group/Detail";

import { GroupDetail } from "../../components/Group";

export type RouteGroupDetailProps = {} & RouteComponentProps<{ id: string }>;

export const RouteGroupDetail: React.FC<RouteGroupDetailProps> = (
  props
): JSX.Element => <GroupDetailContainer {...props} Layout={GroupDetail} />;
