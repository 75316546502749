import React, { FC, useState, SyntheticEvent } from "react";

import { Emoji, getEmojiDataFromNative, Data } from "emoji-mart";
import data from "emoji-mart/data/all.json";
import escapeRegExp from "lodash/escapeRegExp";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import TruncateMarkup from "react-truncate-markup";

import { textThemingWorkaround } from "../../../constants";

import { logException, parseJSONToObject, pattern } from "../../../lib/utils";
import { isVideoLink } from "../../../lib/utils/video";

import Variables from "../../../theme/variables/materialWeb";

import { MentionUser, MentionLink } from "../../../types";

import { useTheme, AnchorProps } from "../../hooks";

import { MediaFile, MediaVideo } from "..";

export type MentionDisplayProps = {
  allowRenderFile?: boolean;
  allowRenderVideo?: boolean;
  disabled?: boolean;
  id?: string;
  linkMentions?: MentionLink[];
  mentions?: MentionUser[];
  /**
   * @TODO
   * Investigate this type
   */
  scaleUpSingleEmoji?: boolean;
  style?: React.CSSProperties & {
    a?: React.CSSProperties;
    emailAnchor?: React.CSSProperties;
    mentionAnchor?: React.CSSProperties;
    phoneAnchor?: React.CSSProperties;
    urlAnchor?: React.CSSProperties;
  };
  text: string;
  viewMore?:
    | boolean
    | {
        numberOfLines: number;
      };
};

export const MentionDisplay: FC<MentionDisplayProps> = ({
  allowRenderFile,
  allowRenderVideo,
  disabled,
  id,
  linkMentions,
  mentions,
  style: {
    a: aStyle,
    emailAnchor: emailAnchorStyle,
    mentionAnchor: mentionAnchorStyle,
    phoneAnchor: phoneAnchorStyle,
    urlAnchor: urlAnchorStyle,
    ...style
  } = {},
  text,
  viewMore,
}) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const {
    text: { cardText: cardTextStyles },
    button: buttonStyles,
  } = useTheme();

  const { t } = useTranslation();

  const history = useHistory();

  /**
   * @TODO
   * Make sure these styles should be different
   */
  const styles = {
    url: {
      color: Variables.brandInfo,
      textDecorationLine: "underline",
      ...aStyle,
      ...urlAnchorStyle,
    },
    email: {
      color: Variables.brandInfo,
      textDecorationLine: "underline",
      ...aStyle,
      ...emailAnchorStyle,
    },
    phone: {
      color: Variables.brandInfo,
      textDecorationLine: "underline",
      ...aStyle,
      ...phoneAnchorStyle,
    },
    mention: {
      backgroundColor: "#d8dfea",
      ...aStyle,
      ...mentionAnchorStyle,
    },

    mainElement: {
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      display: viewMore ? "inline" : undefined,
      ...style,
    },

    showMoreButton: {
      background: "none",
      border: 0,
      ...cardTextStyles,
      ...textThemingWorkaround,
      paddingTop: 2,
      color: buttonStyles.backgroundColor,
      display: "inline-block",
      ...style,
    },
    hashtag: {
      fontStyle: "italic",
    },
    // bigEmoji: {
    //   fontSize: 50,
    //   lineHeight: 60,
    // },
    // video: {
    //   marginTop: 5,
    //   width: 300,
    //   height: 200,
    // },
  } as const;

  /**
   * Various mention types implementation below
   *
   * Please pay attention to the order of content transformations.
   * Converting users and links mentions should be done as soon
   * as possible to avoid display problems e.g. handling hashtags
   * before will break mentions containing "#"" character.
   */

  let content: React.ReactNodeArray;

  /**
   * We have to implement our own key counter to make elements' keys
   * really unique. This is necessary when any mentioned value is
   * duplicated in text.
   */
  let contentKeyIndex = 0;

  const AnchorComponent = disabled ? "span" : "a";

  /** Url */
  const renderUrl = (match: string, i: number): JSX.Element => {
    const displayedText =
      allowRenderVideo && isVideoLink(match) ? (
        <MediaVideo url={match} />
      ) : (
        match
      );

    return (
      <AnchorComponent
        id={`urlDisplay${match}`}
        style={styles.url}
        key={match + i + contentKeyIndex++}
        /** @FIXME Probably http anchors should be replaced with https */
        href={
          disabled
            ? undefined
            : match.startsWith("http")
            ? match
            : `http://${match}`
        }
        rel="noopener noreferrer"
        target="_blank">
        {displayedText}
      </AnchorComponent>
    );
  };

  content = reactStringReplace(text, pattern.urlWeb, renderUrl);

  /**
   * :smile: Emoji
   *
   * I didn't investigate logic below but I adapted it to types improvement.
   * Its highly possible that it could be broken and need some fixes.
   */

  const firstItem = content?.[0];

  if (firstItem && typeof firstItem === "string" && firstItem.length === 2) {
    const renderNativeEmojii = (match: string, i: number): JSX.Element => {
      const emojiData = getEmojiDataFromNative(
        firstItem,
        "google",
        (data as unknown) as Data
      );

      if (!emojiData) {
        return <span key={match + i + contentKeyIndex++}>{firstItem}</span>;
      }

      return (
        <span
          key={match + i + contentKeyIndex++}
          dangerouslySetInnerHTML={{
            __html: (Emoji({
              html: true,
              set: "google",
              emoji: emojiData,
              size: 48,
              skin: emojiData.skin || 1,
            }) as unknown) as string,
          }}
        />
      );
    };

    content = reactStringReplace(
      content,
      pattern.nativeEmoji,
      renderNativeEmojii
    );
  }

  /** :smile: Emoji */

  const renderEmojii = (match: string, i: number): JSX.Element => (
    <span
      key={match + i + contentKeyIndex++}
      dangerouslySetInnerHTML={{
        __html: (Emoji({
          html: true,
          set: "google",
          emoji: match,
          size: 20,
        }) as unknown) as string,
      }}
    />
  );

  content = reactStringReplace(content, pattern.emoji, renderEmojii);

  /** @ User mentions */

  const userMentionsArray = mentions
    ?.map(mention => parseJSONToObject<MentionUser>(mention))
    .sort((a, b) => b.stub.length - a.stub.length);

  if (userMentionsArray && userMentionsArray.length > 0) {
    const mentionRegex = new RegExp(
      `(${userMentionsArray.map(item => escapeRegExp(item.stub)).join("|")})`
    );

    try {
      const renderUserMention = (match: string, i: number): JSX.Element => {
        const user = userMentionsArray?.find(item => item.stub === match);

        const anchorProps: Partial<AnchorProps> = {};

        if (user && !disabled) {
          anchorProps.href = `/member/${user.id}`;
          anchorProps.onClick = (e: React.SyntheticEvent): void => {
            e.preventDefault();
            history.push(`/member/${user.id}`);
          };
        }

        const displayedText = match.replace("@", "");

        return (
          <AnchorComponent
            id={`mentionedUserDisplay${user ? user.id : ``}`}
            style={styles.mention}
            key={match + i + contentKeyIndex++}
            {...anchorProps}>
            {displayedText}
          </AnchorComponent>
        );
      };

      content = reactStringReplace(content, mentionRegex, renderUserMention);
    } catch (e) {
      logException(e);
    }
  }

  /** ^ Links mentions */

  const linkMentionsArray = linkMentions
    ?.map(linkMention => parseJSONToObject<MentionLink>(linkMention))
    .sort((a, b) => b.title.length - a.title.length);

  const linkMentionRegex =
    linkMentionsArray && linkMentionsArray.length > 0
      ? new RegExp(
          `(${linkMentionsArray
            .map(linkMention => escapeRegExp(linkMention.title))
            .join("|")})`
        )
      : pattern.linkInApp;

  const renderLinkMention = (match: string, i: number): JSX.Element => {
    const link = linkMentionsArray?.find(
      item => item.title.split("?").join("") === match
    );

    const anchorProps: Partial<AnchorProps> = {};

    if (link && !disabled) {
      anchorProps.href = link.url;
      anchorProps.onClick = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        history.push(`${link.url}`);
      };
    }

    const displayedText = match.replace("^", "");

    return (
      <AnchorComponent
        id={`mentionedLinkDisplay${link?.id}`}
        style={styles.url}
        key={match + i + contentKeyIndex++}
        {...anchorProps}>
        {displayedText}
      </AnchorComponent>
    );
  };

  content = reactStringReplace(content, linkMentionRegex, renderLinkMention);

  /** Emails */

  const renderEmail = (match: string, i: number): JSX.Element => (
    <AnchorComponent
      id={`emailDisplay${match}`}
      style={styles.email}
      key={match + i + contentKeyIndex++}
      href={disabled ? undefined : `mailto:${match}`}>
      {match.toLowerCase()}
    </AnchorComponent>
  );

  content = reactStringReplace(content, pattern.emailWeb, renderEmail);

  /** Phone numbers */

  const renderPhoneNumber = (match: string, i: number): JSX.Element => {
    const input = match.replace(/ /g, "");

    const newInput = input.replace(/\D/g, "");

    const cleaned = ("" + newInput).replace(/\D/g, "");

    const matched = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    const displayedText = matched
      ? [
          /** Country prefix */
          matched[1] ? "+1 " : "",
          "(",
          matched[2],
          ") ",
          matched[3],
          "-",
          matched[4],
        ].join("")
      : `(${input.substr(0, 3)})${input.substr(3, 3)}-${input.substr(6)}`;

    return (
      <AnchorComponent
        id={`phoneDisplay${match}`}
        style={styles.phone}
        key={match + i + contentKeyIndex++}
        href={disabled ? undefined : `tel:${match}`}>
        {displayedText}
      </AnchorComponent>
    );
  };

  content = reactStringReplace(content, pattern.phone, renderPhoneNumber);

  /** Hashtags */

  const renderHashtag = (match: string, i: number): JSX.Element => (
    <span
      style={styles.hashtag}
      id={`hashDisplay${match}`}
      key={match + i + contentKeyIndex++}>
      {`#${match}`}
    </span>
  );

  content = reactStringReplace(content, pattern.hashTag, renderHashtag);

  /** File */
  const renderFile = (text: string): JSX.Element | void => {
    const file = text && text.match(new RegExp(pattern.file));
    if (file) {
      return <MediaFile url={file.input} />;
    }
  };

  const file = allowRenderFile && renderFile(text);

  const mainElement = (
    <span id={id} style={styles.mainElement}>
      {content}
      {file}
    </span>
  );

  if (viewMore) {
    const toggleTruncate = (e: SyntheticEvent): void => {
      e.preventDefault();
      setIsTruncated(!isTruncated);
    };

    const buttonElement = (
      <button
        className="text-underline-hover"
        onClick={toggleTruncate}
        style={styles.showMoreButton}>
        {isTruncated ? t("UI:ReadMore.See.More") : t("UI:ReadMore.See.Less")}
      </button>
    );

    const ellipsisElement = (
      <span style={{ display: "inline" }}>
        ... <br />
        {buttonElement}
      </span>
    );

    if (isTruncated) {
      return (
        <div>
          <TruncateMarkup
            lines={
              typeof viewMore === "object" ? viewMore.numberOfLines : undefined
            }
            ellipsis={ellipsisElement}>
            {mainElement}
          </TruncateMarkup>
        </div>
      );
    }

    return (
      <>
        <div>{mainElement}</div>
        <div>{buttonElement}</div>
      </>
    );
  }

  return mainElement;
};
