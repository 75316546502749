import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { getPhotoGiphy } from "./action";
import { RootState, MediaPhoto, Callback } from "../../types";
import { GiphyPhoto } from "../../types/Media/GiphyPhoto";
import { MediaPhotoGiphySetAction } from "./types";

export type MediaSearchGiphyStateProps = {
  data: GiphyPhoto[] | null;
};

export type MediaSearchGiphyDispatchProps = {
  search: (
    refresh?: boolean,
    query?: string
  ) => Promise<MediaPhotoGiphySetAction | { data: GiphyPhoto[] | null }>;
};

export type MediaSearchGiphyLayoutProps = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  data: GiphyPhoto[] | null;

  onChangeText: (query: string) => void;
  onSearch: (callback?: Callback) => boolean | Promise<void>;
  onSelect: (image: MediaPhoto) => void;
  reFetch: (refresh?: boolean, callback?: Callback) => Promise<void> | boolean;
};

export type MediaSearchGiphyProps = {
  Layout: ComponentType<any>;
  onSelect: (image: MediaPhoto) => void;
} & MediaSearchGiphyDispatchProps &
  MediaSearchGiphyStateProps;

export type MediaSearchGiphyState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  query?: string;
};

class SearchGiphy extends Component<
  MediaSearchGiphyProps,
  MediaSearchGiphyState
> {
  constructor(props: MediaSearchGiphyProps) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      refreshing: false,
    };
  }

  componentDidMount(): void {
    this.reFetch(true);
  }

  reFetch = (refresh: boolean, callback?: () => void): void => {
    const { search } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }
    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        await search(refresh);
        this.setState({ refreshing: false, loading: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  onChangeText = (query: string): void => {
    this.setState({ query });
  };

  onSearch = (callback?: () => void): void => {
    const { search } = this.props;
    const { refreshing, loading, query } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState({ loading: true }, async () => {
      try {
        await search(true, query);
        this.setState({ loading: false, refreshing: false, error: null });
        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, data, onSelect } = this.props;
    const { loading, error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        data={data}
        onSelect={onSelect}
        reFetch={this.reFetch}
        onSearch={this.onSearch}
        onChangeText={this.onChangeText}
      />
    );
  };
}

const mapStateToProps = (state: RootState): MediaSearchGiphyStateProps => {
  return {
    data: state.media.photoGiphy,
  };
};

const mapDispatchToProps = {
  search: getPhotoGiphy,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchGiphy);
