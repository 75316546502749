import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { Dict, Tag } from "../../../types";

import { runFunction } from "../../http";

export const searchTags = async (options: {
  search: string;
  type?: string;
}): Promise<Dict<Tag>> => {
  console.debug("[Service] searchTags", options);

  const response: Tag[] = await runFunction("searchTags", options);

  return _.object(_.map(response, value => [value.id, convertObject(value)]));
};
