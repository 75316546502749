import React from "react";

import { RouteComponentProps } from "react-router-dom";

import UserSettingContainer from "../../../containers/User";

import { UserSetting } from "../../components/User";

export type RouteUserSettingProps = {} & RouteComponentProps;

export const RouteUserSetting: React.FC<RouteUserSettingProps> = props => (
  <UserSettingContainer {...props} Layout={UserSetting} />
);
