import { runFunction } from "../../http";
import _ from "underscore";

export const addPostReadBy = async (
  id: string,
  uid?: string
): Promise<void> => {
  console.debug("[Service] addPostReadBy");
  await runFunction(
    "addPostReadBy",
    {
      postId: id,
      targetUser: uid,
    },
    { requireUser: _.isEmpty(uid) }
  );
};
