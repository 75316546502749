import React from "react";

import { useTranslation } from "react-i18next";
import { Card, CardBody, CardText, Button } from "reactstrap";

import { textThemingWorkaround } from "../../../../constants";

import { AnnouncementItem } from "../../../../types";

import { AnnouncementAnchorProps, useTheme } from "../../../hooks";

import { IconFontAwesome, Heading } from "../..";

export type AnnouncementListPinProps = {
  customLabel?: string;
  item: AnnouncementItem;
} & AnnouncementAnchorProps;

export const AnnouncementListPin: React.FC<AnnouncementListPinProps> = ({
  customLabel,
  item: {
    announcement: { title, strapLine },
  },
  ...anchorProps
}) => {
  const {
    pins: { color, backgroundColor },
    text: { cardTitle, subHeader, cardText },
  } = useTheme();
  const { t } = useTranslation("Announcement");

  const styles = {
    button: {
      flex: 1,
      justifyContent: "center",
      borderColor: color,
      borderRadius: color,
      color: color,
      background: "none",
    },
    buttonHeading: { color: color, textAlign: "center" },
    card: {
      backgroundColor,
      marginBottom: 10,
      paddingBottom: 5,
    },
    cardBody: {
      backgroundColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
    },
    cardText: {
      ...subHeader,
      ...textThemingWorkaround,
      color,
    },
    icon: { color: color },
    straplineText: { ...cardText, ...textThemingWorkaround, color },
    titleSpan: { ...cardTitle, ...textThemingWorkaround, color },
  } as const;

  const infoText =
    t("Pins.Pin.Title", {
      setting: { announcementLabel: customLabel },
    }) ||
    /** In my opinion second condition will never happen */
    t("Pins.Pin.Title.Announcement");

  const titleAnchorElement = (
    <a {...anchorProps}>
      <span style={styles.titleSpan}>{title}</span>
    </a>
  );

  const strapLineElement = strapLine && (
    <CardBody className="pt-0" style={styles.cardBody}>
      <CardText style={styles.straplineText}>{strapLine}</CardText>
    </CardBody>
  );

  const buttonElement = (
    <Button block tag="a" style={styles.button} {...anchorProps}>
      <Heading
        text={t("Pins.Button.View.Announcement")}
        style={styles.buttonHeading}
      />
    </Button>
  );

  return (
    <Card className={"my-2 p-3"} style={styles.card}>
      <CardBody className="py-2" style={styles.cardBody}>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardText style={styles.cardText}>{infoText}</CardText>
          </div>
          <div>
            <IconFontAwesome name="thumb-tack" style={styles.icon} />
          </div>
        </div>
      </CardBody>
      <CardBody className="pt-0" style={styles.cardBody}>
        <Heading>{titleAnchorElement}</Heading>
      </CardBody>
      {strapLineElement}
      <CardBody className="py-0" style={styles.cardBody}>
        {buttonElement}
      </CardBody>
    </Card>
  );
};
