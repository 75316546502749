import React from "react";

import { Collapse, ListGroup } from "reactstrap";

import { ResourceItem } from "../../../types";

import { ResourceCard } from ".";

export type ResourceListGroupCollapseProps = {
  cardStyle: {};
  data: ResourceItem[];
  isOpen: boolean;
};

export const ResourceListGroupCollapse: React.FC<ResourceListGroupCollapseProps> = ({
  cardStyle,
  data,
  isOpen,
}) => {
  const resourceCardElements = data.map(item => (
    <ResourceCard key={item.id} item={item} style={cardStyle} />
  ));

  return (
    <Collapse
      isOpen={isOpen}
      tag={ListGroup}
      className="py-0 pl-3 pr-2 border-bottom-0 border-top-0 border-right-0"
      style={{ border: 0 }}>
      {resourceCardElements}
    </Collapse>
  );
};
