import React from "react";

import { RouteComponentProps } from "react-router-dom";

import VideoDetailContainer from "../../../containers/VideoLibrary/Detail";

import { VideoDetail } from "../../components/VideoLibrary";

export type RouteVideoDetailProps = RouteComponentProps;

export const RouteVideoDetail: React.FC<RouteVideoDetailProps> = props => (
  <VideoDetailContainer {...props} Layout={VideoDetail} />
);
