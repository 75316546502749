import { imageUploadConfig } from "../../../constants";
import { logException } from "../../../lib/utils";

import { convertCanvasToBlob } from ".";

export const resizeImage = (
  image: { base64URI: string; name: string },
  callback: (value: { file: File; base64URI: string }) => void,
  options?: {
    maxHeight: number;
    maxWidth: number;
  }
): void => {
  try {
    const { base64URI, name } = image;
    const { maxWidth, maxHeight } = options || imageUploadConfig.thumb;

    /** Image creation */
    const imageToResize = new Image();
    imageToResize.src = base64URI;

    /** Canvas creation */
    const canvas = document.createElement("canvas");

    const ctx = canvas.getContext("2d");

    if (ctx) {
      imageToResize.onload = function(): void {
        /** Resizing */

        const hRatio = maxWidth / imageToResize.width;
        const vRatio = maxHeight / imageToResize.height;

        const [resizedWidth, resizedHeight] =
          hRatio < vRatio
            ? [maxWidth, Math.round(imageToResize.height * hRatio)]
            : [Math.round(imageToResize.width * vRatio), maxHeight];

        canvas.width = resizedWidth;
        canvas.height = resizedHeight;

        /**
         * @TODO
         * Implement proper ratio scaling - now its hardcoded without ratio
         */
        ctx.drawImage(imageToResize, 0, 0, resizedWidth, resizedHeight);

        convertCanvasToBlob(canvas, {
          type: "image/png",
          /**
           * @FIXME
           * Value should be between 0 and 1, but is equal to 100 now... Temporarily divided by 100
           */
          quality: Math.round(imageUploadConfig.thumb.quality / 100),
        })
          .then(blob => {
            callback({
              file: new File([blob], name, { type: "image/png" }),
              base64URI: ctx.canvas.toDataURL(),
            });
          })
          .catch(e => {
            throw e;
          });
      };
    } else {
      throw Error(`Couldn't resize an image. Context doesn't exist.`);
    }
  } catch (e) {
    logException(e);
  }
};
