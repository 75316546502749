import { runQuery } from "../../http";
import _ from "underscore";
import { convertObject } from "../../../lib/utils";
import { EventInventory } from "../../../types/Event/EventInventory";

export const getEventInventory = async (
  eventId?: string
): Promise<{ [key: string]: EventInventory }[]> => {
  const parseResponse = await runQuery("Inventory", function(
    query: Parse.Query
  ) {
    query.equalTo("event", new (Parse.Object.extend("Event"))({ id: eventId }));
    return query.find();
  });

  return _.object(
    _.map<Parse.Object, Parse.Object[]>(parseResponse, value => {
      return [value.id, convertObject(value)];
    })
  );
};
