import React from "react";

import { RouteComponentProps } from "react-router-dom";

import PaymentDetailContainer from "../../../containers/Payment/Detail";

import { PaymentDetail } from "../../components/Payment";

export type RoutePaymentReceiptDetailProps = {} & RouteComponentProps<{
  id: string;
}>;

export const RoutePaymentReceiptDetail: React.FC<RoutePaymentReceiptDetailProps> = props => (
  <PaymentDetailContainer {...props} Layout={PaymentDetail} />
);
