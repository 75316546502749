import _ from "underscore";
import { saveData } from "../../http";
import { CommentResponse, CommentType } from "../../../types/Comment";
import { CommentRequest } from "../../../types/Comment/CommentRequest";
import { convertObject } from "../../../lib/utils";
import { commentsTypes } from "../../../constants/Comment";
import { parseMentions } from "../ParseMentionsHelper";

export const updateComment = async (
  options: { type: CommentType } & CommentRequest
): Promise<CommentResponse> => {
  console.debug("[Service] addComment");

  const { id, type, text, mentions, linksMentions } = options;
  const Comment = Parse.Object.extend(commentsTypes[type].commentObject);
  const comment = new Comment({
    id,
    body: text,
    mentions: _.map(mentions || [], item => JSON.stringify(item)),
    linksMentions: _.map(linksMentions || [], item => JSON.stringify(item)),
    edited: true,
  });
  const resp = await saveData(comment);
  return parseMentions<CommentResponse>(convertObject(resp));
};
