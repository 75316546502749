import React from "react";

import { IconDict } from "../../../../types";

import { iconIoniconsDict } from ".";

export type IconIoniconsProps = {
  className?: string;
  color?: string;
  fontSize?: string;
  name: IconDict["Ionicons"];
};

export const IconIonicons: React.FC<IconIoniconsProps> = ({ name, ...props }) =>
  React.createElement<Omit<IconIoniconsProps, "name">>(
    iconIoniconsDict[name],
    props
  );
