import { AppItem, AppItemList } from "../../../types";

export const getUpsertedItemList = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  dataKeyName,
  newItem,
  newItemCached,
  itemList,
  announcementId,
}: {
  /**
   * Of course this solution works on duplicated data but is
   * probably the safest one for current app architecture.
   * items and cached dictionaries updates are completely independent.
   */
  newItem: ItemType | undefined;
  newItemCached: ItemType | undefined;
  dataKeyName: DataKey;
  itemList: AppItemList<DataKey, ItemType>;
  announcementId?: string | number;
}): AppItemList<DataKey, ItemType> => {
  if (!newItem && !newItemCached) return itemList;

  return {
    ...itemList,
    items:
      newItem && itemList.items?.[newItem[dataKeyName].id]
        ? {
            ...itemList.items,
            [newItem[dataKeyName].id]: newItem,
          }
        : announcementId && itemList.items?.[announcementId]
        ? { ...itemList.items, [announcementId]: newItem }
        : itemList.items,
    cached:
      newItemCached && itemList.cached?.[newItemCached[dataKeyName].id]
        ? {
            ...itemList.cached,
            [newItemCached[dataKeyName].id]: newItemCached,
          }
        : itemList.cached,
  };
};
