import React, { FC, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { BaseEmoji, Picker } from "emoji-mart";
import { Button } from "reactstrap";

import MentionInput from "../../../../containers/Mention";

import { MentionLink, MentionUser } from "../../../../types";
import { CustomFileType } from "../../../../types/Chat/ChatMutatedMessage";
import { SendMessageParams } from "../../../../types/Chat/SendMessageParams";

import { useClickOutsideRef } from "../../../hooks";

import { MentionInput as MentionInputComponent } from "../..";
export type ChatConversationEditProps = {
  file: CustomFileType | null;
  id: number;
  isScrolling?: boolean;
  message: string;
  msgLinksMention: MentionLink[];
  msgMentions: MentionUser[];

  setEditing: (value: boolean) => void;
  updateMessage: (
    data: SendMessageParams & { id: number },
    cancel: boolean,
    callback?: () => void
  ) => Promise<void>;
};

export const ChatConversationEditMessage: FC<ChatConversationEditProps> = ({
  file,
  id,
  message,
  msgLinksMention,
  msgMentions,

  setEditing,
  updateMessage,
}) => {
  const { t } = useTranslation("Chat");
  const [editedMessage, setEditedMessage] = useState(message);
  const [mentions, setMentions] = useState<MentionUser[]>(msgMentions);
  const [linkMentions, setLinkMentions] = useState<MentionLink[]>(
    msgLinksMention
  );
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const handleClickOutside = (): void => {
    if (isEmojiPickerOpen) {
      setEmojiPickerOpen(false);
    }
  };

  const emojiClickOutsideRef = useClickOutsideRef(handleClickOutside);

  useEffect(() => {
    setEditedMessage(message);
  }, [message]);

  const cancelEdit = (): void => {
    setEmojiPickerOpen(false);
    setEditedMessage(message);
    setEditing(false);
  };

  const onMentionUser = (mentions: MentionUser[]): void => {
    setMentions(mentions);
  };

  const onMentionLink = (linksMentions: MentionLink[]): void => {
    setLinkMentions(linksMentions);
  };

  const toggleEmojiPicker = (): void => {
    setEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const addEmoji = (e: BaseEmoji): void => {
    console.debug("Emoji", e);
    setEditedMessage(prevState => prevState + e.native);
    toggleEmojiPicker();
  };

  const updateMsg = (): void => {
    editedMessage &&
      updateMessage(
        {
          text: editedMessage,
          mentions: mentions ?? [],
          linksMentions: (linkMentions as any) ?? [],
          files: file !== null ? [file] : file,
          id,
        },
        false
      ).then(() => {
        setEditing(false);
        setEditedMessage("");
      });
  };

  const imageInputRef = useRef<HTMLInputElement>(null);

  const computeBottomWith = (ref: any): string => {
    // 425px - height of a emoji picker
    if (parseInt(ref.current.getBoundingClientRect().bottom) + 100 < 570) {
      return (
        (
          window.innerHeight -
          parseInt(ref.current.getBoundingClientRect().bottom) -
          410
        ).toString() + "px"
      );
    } else {
      return (
        (
          window.innerHeight -
          parseInt(ref.current.getBoundingClientRect().bottom) +
          60
        ).toString() + "px"
      );
    }
  };

  return (
    <div ref={emojiClickOutsideRef}>
      <div ref={imageInputRef} className="pb-3 chat-edit-message">
        <MentionInput
          Layout={MentionInputComponent}
          includeHereAndChannel={true}
          value={editedMessage ?? ""}
          onChangeText={(text: string): void => {
            setEditedMessage(text);
          }}
          /** @FIXME */
          // inputValue={text}
          placeholder="Enter message"
          minHeight={45}
          maxHeight={85}
          mentionUser={onMentionUser}
          usersMentioned={mentions}
          mentionLink={onMentionLink}
          linksMentioned={linkMentions}
        />
        {isEmojiPickerOpen && (
          <span>
            <Picker
              set={"google"}
              onSelect={addEmoji}
              style={{
                position: "absolute",
                bottom: computeBottomWith(imageInputRef),
                right: "35px",
                zIndex: 1200,
              }}
              color="#2954a3"
            />
          </span>
        )}
        <div className="sendbird-message-input--edit-action">
          <Button
            color="link"
            size="sm"
            className="text-decoration-none"
            onClick={toggleEmojiPicker}>
            <i className="icon-emotsmile text-primary font-weight-bolder p4-3" />
          </Button>
          <Button color="primary" outline={true} onClick={cancelEdit}>
            {t("ConversationEditMessage.Button.Cancel")}
          </Button>
          <Button
            color="primary"
            onClick={(): void => {
              setEmojiPickerOpen(false);
              updateMsg();
            }}
            disabled={editedMessage.length === 0}>
            {t("ConversationEditMessage.Button.Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};
