import React from "react";

import Skeleton from "react-loading-skeleton";
import { Card, Media } from "reactstrap";

export type GroupCardPlaceholderProps = {};

export const GroupCardPlaceholder: React.FC<GroupCardPlaceholderProps> = () => {
  return (
    <div>
      {[1, 2, 3].map(i => (
        <Card key={i} className={"border-bottom p-2 mb-2 rounded"}>
          <Media>
            <Media left bottom className="mr-2">
              <Skeleton width={50} height={50} />
            </Media>
            <Media body className="px-2 pt-2 text-truncate">
              <h6 className="mb-0">
                <Skeleton />
              </h6>
              <p className="text-muted text-small mt-2 mb-0 text-truncate">
                <Skeleton count={1} />
              </p>
            </Media>
          </Media>
        </Card>
      ))}
    </div>
  );
};
