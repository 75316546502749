import React from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";
import { isEmpty, sortBy } from "underscore";

import {
  NotificationLandingMessageWithAuthor,
  NotifiacationLandingTweet,
} from "../../../types";

import { useTypedSelector } from "../../hooks";

import { Empty } from "..";

import {
  NotificationLandingSideBarCard,
  NotificationLandingTwitterSideBarCard,
} from ".";
import { NotificationLandingPlaceholder } from "./NotificationLandingPlaceholder";

type Props = {
  error: string | null;
  landingMessages: NotificationLandingMessageWithAuthor[] | null;
  landingTweets: NotifiacationLandingTweet[] | null;
};

export const NotificationLandingSideBar: React.FC<Props> = ({
  landingMessages,
  landingTweets,
}) => {
  const { t } = useTranslation("Notification");

  const viewingAsGuest = useTypedSelector(state => state.user.viewingAsGuest);

  if (viewingAsGuest) {
    return <Alert color="danger">{t("Index.Alert.Guest.Mode")}</Alert>;
  }

  if (!landingMessages || !landingTweets) {
    return <NotificationLandingPlaceholder />;
  }

  if (isEmpty(landingMessages) && isEmpty(landingTweets)) {
    return <Empty text="Nothing yet!" />;
  }
  const mixed = sortBy(
    [...landingTweets, ...landingMessages],
    "date"
  ).reverse();

  const landingMessagesElements = mixed?.map((item, index) => {
    if (item.target.screen === "twitter") {
      return (
        <NotificationLandingTwitterSideBarCard
          key={index}
          item={item as NotifiacationLandingTweet}
        />
      );
    }
    return (
      <NotificationLandingSideBarCard
        key={index}
        item={item as NotificationLandingMessageWithAuthor}
      />
    );
  });

  return <div>{landingMessagesElements}</div>;
};
