import React from "react";

import classnames from "classnames";

import { useTheme } from "../../hooks";

export const bgColors = [
  "bg",
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
  "white",
  "transparent",
] as const;

export type StickyNavbarProps = {
  id?: string;
  className?: string;
  children: React.ReactNode;
};

export const StickyNavbar: React.FC<StickyNavbarProps> = ({
  id,
  className,
  children,
}) => {
  const {
    general: { backgroundColor },
  } = useTheme();

  return (
    <div
      id={id}
      className={classnames("sticky-navbar", className)}
      style={{ backgroundColor }}>
      {children}
    </div>
  );
};
