import React, { ChangeEvent } from "react";

import {
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  ModalBody,
} from "reactstrap";
import { isUndefined } from "underscore";

import { GroupSettingLayoutProps } from "../../../../containers/Group/Setting";

export const GroupSetting: React.FC<GroupSettingLayoutProps> = ({
  notificationSettings,
  notificationSettingsDefault,
  notificationSettingsOptions,
  onChangeNotificationSettings,
  sending,
}) => {
  const mobileNotification = isUndefined(
    notificationSettings.mobileNotification
  )
    ? notificationSettingsDefault.mobileNotification
    : notificationSettings.mobileNotification;

  const desktopNotification = isUndefined(
    notificationSettings.desktopNotification
  )
    ? notificationSettingsDefault.desktopNotification
    : notificationSettings.desktopNotification;

  const mute = isUndefined(notificationSettings.mute)
    ? notificationSettingsDefault.mute
    : notificationSettings.mute;

  const muteNotificationsElement = (
    <Form>
      <FormGroup>
        <CustomInput
          type="switch"
          id="muteNotificationsSwitch"
          name="muteNotificationsSwitch"
          label="Mute entire group"
          defaultChecked={mute}
          onChange={(e: ChangeEvent<HTMLInputElement>): void =>
            onChangeNotificationSettings("mute", e.target.checked)
          }
        />
      </FormGroup>
    </Form>
  );
  const mobileNotificationElement = (
    <ListGroup className="my-2">
      <h6>Mobile Notifications</h6>
      {notificationSettingsOptions.map(item => (
        <ListGroupItem
          disabled={sending}
          key={item.value}
          onClick={(): void =>
            onChangeNotificationSettings("mobileNotification", item.value)
          }>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  onClick={(): void =>
                    onChangeNotificationSettings(
                      "mobileNotification",
                      item.value
                    )
                  }
                  checked={item.value === mobileNotification}
                />
                {item.key}
              </Label>
            </FormGroup>
          </Form>
        </ListGroupItem>
      ))}
    </ListGroup>
  );

  const desktopNotificationElement = (
    <ListGroup className="mt-4 mb-2">
      <h6>Desktop Notifications</h6>
      {notificationSettingsOptions.map(item => (
        <ListGroupItem
          disabled={sending}
          key={item.value}
          onClick={(): void =>
            onChangeNotificationSettings("desktopNotification", item.value)
          }>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  onClick={(): void =>
                    onChangeNotificationSettings(
                      "desktopNotification",
                      item.value
                    )
                  }
                  checked={item.value === desktopNotification}
                />
                {item.key}
              </Label>
            </FormGroup>
          </Form>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
  return (
    <ModalBody>
      {muteNotificationsElement}
      {mobileNotificationElement}
      {desktopNotificationElement}
    </ModalBody>
  );
};
