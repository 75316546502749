import React from "react";

import { Card, Alert } from "reactstrap";

import { InfiniteScroll, Media } from "../..";

import { PostFilesPlaceholder } from ".";
import { PostFilesLayoutProps } from "../../../../containers/Post/Files";

export const PostFiles: React.FC<PostFilesLayoutProps> = ({
  data,
  error,
  hasMore,
  loading: isLoading,
  refreshing: isRefreshing,

  reFetch,
}) => {
  /** @TODO This logic could be moved to container */
  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const content =
    data?.map(attachment => (
      <div
        id={`mediaFileElement${attachment.id}`}
        key={attachment.id}
        className="m-2 py-1 border-bottom">
        <Media
          file={{
            name: attachment.name,
            fileUrl: attachment.fileUrl,
          }}
        />
      </div>
    )) || null;

  return (
    <Card className="px-2">
      {errorElement}
      <InfiniteScroll
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        hasMore={hasMore}
        placeholder={<PostFilesPlaceholder />}
        onLoadMore={handleLoadMore}>
        {content}
      </InfiniteScroll>
    </Card>
  );
};
