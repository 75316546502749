import React from "react";

import { ListGroup } from "reactstrap";

import { ResourceItem } from "../../../types";

import { InfiniteScroll } from "..";

import { ResourceCard, ResourceCardPlaceholder } from ".";

export type ResourceListProps = {
  data: ResourceItem[] | null;
  refreshing: boolean;
  loading: boolean;
  hasMore?: boolean;
  showVideoCTA: boolean;

  reFetch: (next: boolean, refresh: boolean) => void;
  id?: string;
};

export const ResourceList: React.FC<ResourceListProps> = ({
  data,
  hasMore,
  loading: isLoading,
  refreshing: isRefreshing,
  showVideoCTA,

  reFetch,
}) => {
  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const content =
    data?.map(item => <ResourceCard key={item.id} item={item} />) || null;

  return (
    <ListGroup>
      <InfiniteScroll
        emptyText="No resources found"
        /**
         * I moved videoCTA above the list. I dont see any sense in putting
         * any element below <LoadingSpinner /> in infinite (theoretically) list
         */
        hasMore={!!hasMore}
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        onLoadMore={handleLoadMore}
        placeholder={<ResourceCardPlaceholder />}>
        {content}
      </InfiniteScroll>
    </ListGroup>
  );
};
