import React from "react";

import { Alert, Modal } from "reactstrap";

export type AlertModalProps = {
  backdrop?: boolean | "static";
  color:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  message: string;
  isOpen: boolean;
  title?: string;

  toggle: () => void;
};

export const AlertModal: React.FC<AlertModalProps> = ({
  backdrop,
  color,
  message,
  isOpen,
  title,

  toggle,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} centered={true} backdrop={backdrop}>
    <Alert color={color} className="m-0" toggle={toggle}>
      {title && <h4 className="alert-heading">{title}</h4>}
      {message}
    </Alert>
  </Modal>
);
