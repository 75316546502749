import React from "react";

import Skeleton from "react-loading-skeleton";
import { Card, CardBody } from "reactstrap";

type Props = {};

export const NotificationLandingPlaceholder: React.FC<Props> = () => {
  return (
    <div>
      {[1, 2, 3].map(i => (
        <Card key={i} className="m-2 pt-2 px-2">
          <CardBody className={"p-0"}>
            <p className={"text-left m-0"}>
              <Skeleton />
            </p>
            <p className={"text-left small mt-2"}>
              <Skeleton />
            </p>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};
