import _ from "underscore";

import { runFunction } from "../../http";
import { EventItemDict } from "../../../containers/Event/types";
import { EventItem } from "../../../types/Event/EventItem";
import { EventSingle } from "../../../types";
import { convertObject } from "../../../lib/utils";

/**
 * @TODO Add types while containers/Search typing
 */
export const searchFullTextEvents = async (
  options: {},
  groupIds?: string[] | null
): Promise<EventItemDict> => {
  const resp: EventItem[] = await runFunction("searchEvents", options, {
    requireUser: true,
  });

  // because multiple instances for full text search isn't supported, API returns value for all targets now
  // so solution here is making a comparison with my groups to reduce invalid targets
  const filteredData = convertObject(resp).filter(
    ({ event }: { event: EventSingle }) => {
      if (event.targetGroups) {
        return (
          event.target === "all" ||
          _.difference(event.targetGroups, groupIds || []).length === 0
        );
      } else return false;
    }
  );

  /**
   * @FIXME Converting logic temporarily moved here.
   * Reconsider move to another layer.
   */
  return _.object(
    _.map(filteredData, (value: EventItem) => {
      return [value.event.id, value];
    })
  );
};
