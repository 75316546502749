import {
  createInvoice as _createInvoice,
  getInvoice as _getInvoice,
  getInvoices as _getInvoices,
  getReceipts as _getReceipts,
  getPaymentFee as _getPaymentFee,
  makePayment as _makePayment,
  exportInvoice as _exportInvoice,
  payByCheck as _payByCheck,
} from "../../services/api/index";

import { logException } from "../../lib/utils";
import _ from "underscore";
import { page as _page } from "../../constants";
import { ThunkDispatch } from "redux-thunk";
import {
  RootState,
  AppThunkAction,
  AppThunkDispatch,
  GetState,
} from "../../types";
import { AnyAction } from "redux";
import { UserDataState, InvoiceOption, InvoiceData } from "../../types";
import { PAYMENT_INVOICE_SET, PAYMENT_RECEIPT_SET } from "./constants";
import { SettingConfigData } from "../../types/Setting/SettingConfigData";
import i18n from "../../middlewares/i18next";

const per = _page.payment;

export const makePayment = (
  token: string,
  parseId: string,
  amountInCents: number
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Active] makePayment", { parseId, amountInCents });

    const { user, setting } = getState();
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: (setting.config as SettingConfigData).adminURL,
    };
    const resp = await _makePayment(token, parseId, amountInCents, config);
    if (resp.error) {
      throw new Error(resp.error);
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const payByCheck = (
  parseId: string,
  amountInCents: number
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Active] payByCheck", { parseId, amountInCents });

    const { user, setting } = getState();
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: (setting.config as SettingConfigData).adminURL,
    };
    const resp = await _payByCheck(parseId, amountInCents, config);
    if (resp.error) {
      throw new Error(resp.error);
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export function createInvoice(
  // @TODO set one naming convention
  // eslint-disable-next-line @typescript-eslint/camelcase
  attendee_id: string,
  quantities: number[],
  options: InvoiceOption[] | number[]
) {
  return (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ): Promise<InvoiceData> =>
    new Promise<InvoiceData>((resolve, reject) => {
      const { user, setting } = getState();
      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: (setting.config as SettingConfigData).adminURL,
      };
      _createInvoice(attendee_id, quantities, options, config)
        .then(function(resp) {
          return _getInvoice(resp.invoice_id);
        })
        .then(resp => {
          return resolve(resp);
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getInvoice(id: string, userId: string, refresh: boolean) {
  return (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ): Promise<{ data: InvoiceData }> =>
    new Promise<{ data: InvoiceData }>((resolve, reject) => {
      const { payment } = getState();
      const { invoiceList, receiptList } = payment;
      let cachedData = invoiceList.items && invoiceList.items[id];
      if (!cachedData) {
        cachedData = receiptList.items && receiptList.items[id];
      }
      if (!refresh && cachedData) {
        return;
      } else {
        _getInvoice(id, userId)
          .then(function(resp) {
            if (!resp) {
              return reject({
                message: i18n.t("Payment:Container.Action.Message"),
              });
            } else {
              return resolve({ data: resp });
            }
          })
          .catch(error => reject(error));
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/** @TODO Investigate return type */
export function getUnpaidInvoices() {
  return (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ): Promise<InvoiceData | null> =>
    new Promise<InvoiceData | null>((resolve, reject) => {
      const options = {
        per: 99,
        page: 0,
        status: ["unpaid", "overdue", "disputed"],
      };
      _getInvoices(options)
        .then(resp => {
          const payments = _.map(resp, item => item.id);
          const newInvocice =
            Object.keys(resp).length > 0 ? resp[Object.keys(resp)[0]] : null;
          dispatch({
            type: "NOTIFICATION_UNREAD_SET",
            data: { payments },
          });
          return resolve(newInvocice);
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export const getInvoices = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getInvoices");

  try {
    const { payment } = getState();
    const { invoiceList } = payment;
    let { page } = invoiceList;
    const { items } = invoiceList;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    } else if (next) {
      page++;
    }

    const options = {
      per,
      page,
      status: ["unpaid", "overdue", "disputed"],
    };
    let data = await _getInvoices(options);

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: PAYMENT_INVOICE_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getReceipts = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getReceipts");

  try {
    const { payment } = getState();
    const { receiptList } = payment;
    let { page } = receiptList;
    const { items } = receiptList;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    } else if (next) {
      page++;
    }

    const options = {
      per,
      page,
      status: ["paid"],
    };
    let data = await _getReceipts(options);

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: PAYMENT_RECEIPT_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export function getPaymentFee(total: number) {
  return (
    dispatch: ThunkDispatch<RootState, null, AnyAction>,
    getState: () => RootState
  ): Promise<unknown> =>
    new Promise((resolve, reject) => {
      const { setting, user } = getState();
      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: (setting.config as SettingConfigData).adminURL,
      };
      _getPaymentFee(total, config)
        .then(resp => {
          return resolve({ data: resp });
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function exportInvoice(invoiceId: string): AppThunkAction<string> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<string> => {
    console.debug("[Action] exportInvoice");

    try {
      const { setting, user } = getState();
      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: setting?.config?.adminURL as string,
      };

      const data = await _exportInvoice(invoiceId, config);
      return data;
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}
