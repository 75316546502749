import React, { CSSProperties } from "react";

import fontawesome from "@fortawesome/fontawesome";

/** FA Free Brands */
import faFacebook from "@fortawesome/fontawesome-free-brands/faFacebook";
import faInstagram from "@fortawesome/fontawesome-free-brands/faInstagram";
import faLinkedin from "@fortawesome/fontawesome-free-brands/faLinkedin";
import faTwitter from "@fortawesome/fontawesome-free-brands/faTwitter";

/** FA Free Solid */
import faAngleDown from "@fortawesome/fontawesome-free-solid/faAngleDown";
import faAngleRight from "@fortawesome/fontawesome-free-solid/faAngleRight";
import faCalendar from "@fortawesome/fontawesome-free-solid/faCalendarAlt";
import faChartBar from "@fortawesome/fontawesome-free-solid/faChartBar";
import faCommentAlt from "@fortawesome/fontawesome-free-solid/faCommentAlt";
import faDollarSign from "@fortawesome/fontawesome-free-solid/faDollarSign";
import faEdit from "@fortawesome/fontawesome-free-solid/faEdit";
import faExclamationTriangle from "@fortawesome/fontawesome-free-solid/faExclamationTriangle";
import faFile from "@fortawesome/fontawesome-free-solid/faFile";
import faDownload from "@fortawesome/fontawesome-free-solid/faDownload";
import faFilm from "@fortawesome/fontawesome-free-solid/faFilm";
import faFilter from "@fortawesome/fontawesome-free-solid/faFilter";
import faFolderOpen from "@fortawesome/fontawesome-free-solid/faFolderOpen";
import faInfoCircle from "@fortawesome/fontawesome-free-solid/faInfoCircle";
import faLink from "@fortawesome/fontawesome-free-solid/faLink";
import faList from "@fortawesome/fontawesome-free-solid/faList";
import faMapMarkerAlt from "@fortawesome/fontawesome-free-solid/faMapMarkerAlt";
import faPhone from "@fortawesome/fontawesome-free-solid/faPhone";
import faPlay from "@fortawesome/fontawesome-free-solid/faPlay";
import faQuestionCircle from "@fortawesome/fontawesome-free-solid/faQuestionCircle";
import faSearch from "@fortawesome/fontawesome-free-solid/faSearch";
import faSitemap from "@fortawesome/fontawesome-free-solid/faSitemap";
import faStar from "@fortawesome/fontawesome-free-solid/faStar";
import faThumbtack from "@fortawesome/fontawesome-free-solid/faThumbtack";
import faTrashAlt from "@fortawesome/fontawesome-free-solid/faTrashAlt";
import faUserSecret from "@fortawesome/fontawesome-free-solid/faUserSecret";
import faVideo from "@fortawesome/fontawesome-free-solid/faVideo";

/** FA Free Regular */
/** Icon name changed intentionally to avoid name duplication */
import faStarO from "@fortawesome/fontawesome-free-regular/faStar";
import faCalendarO from "@fortawesome/fontawesome-free-regular/faCalendar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconDict } from "../../../../types";

fontawesome.library.add(
  /** FA Free Brands  */
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,

  /** FA Free Solid */
  faAngleDown,
  faAngleRight,
  faCalendar,
  faChartBar,
  faCommentAlt,
  faDollarSign,
  faEdit,
  faExclamationTriangle,
  faFile,
  faDownload,
  faFilm,
  faFilter,
  faFolderOpen,
  faInfoCircle,
  faLink,
  faList,
  faMapMarkerAlt,
  faPhone,
  faPlay,
  faQuestionCircle,
  faSearch,
  faSitemap,
  faStar,
  faThumbtack,
  faTrashAlt,
  faUserSecret,
  faVideo,

  /** FA Free Regular */
  faCalendarO,
  faStarO
);

export type IconFontAwesomeProps = {
  className?: string;
  name: IconDict["FontAwesome"];
  style?: CSSProperties;
};

/**
 * IconFontAwesome
 *
 * Wraps FontAwesome icons to one component. Icon names (prop) are
 * following the same pattern as in RN.
 *
 * __IMPORTANT__ When contributing to this component!
 *
 * Font Awesome can produce bundle chunks size issues while importing
 * directly from main library entry points e.g.:
 * - @fortawesome/fontawesome-free-brands
 * - @fortawesome/fontawesome-free-solid
 * - @fortawesome/fontawesome-free-regular
 *
 * It could be drastically improved by importing only necessary icons
 * directly from files where they have been declared, exactly like now
 * in inports statemets part. Please __DO NOT__ change it!
 */
export const IconFontAwesome: React.FC<IconFontAwesomeProps> = ({
  name,
  ...props
}) => {
  /** FA Free Regular */
  if (name === "star-o") {
    const mapping = {
      "star-o": "star",
    } as const;

    return <FontAwesomeIcon {...props} icon={["far", mapping["star-o"]]} />;
  }

  if (name === "calendar-o") {
    const mapping = {
      "calendar-o": "calendar",
    } as const;

    return <FontAwesomeIcon {...props} icon={["far", mapping["calendar-o"]]} />;
  }

  /** FA Free Brands */
  if (
    name === "facebook" ||
    name === "instagram" ||
    name === "linkedin" ||
    name === "twitter"
  ) {
    return <FontAwesomeIcon {...props} icon={["fab", name]} />;
  }

  /** FA Free Solid */
  if (name === "thumb-tack") {
    const mapping = {
      "thumb-tack": "thumbtack",
    } as const;

    return <FontAwesomeIcon {...props} icon={["fas", mapping["thumb-tack"]]} />;
  }

  if (name === "video-camera") {
    const mapping = {
      "video-camera": "video",
    } as const;

    return (
      <FontAwesomeIcon {...props} icon={["fas", mapping["video-camera"]]} />
    );
  }

  return <FontAwesomeIcon {...props} icon={["fas", name]} />;
};
