import React, { FC, useEffect, useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";
import { Alert, Button, Modal, Row } from "reactstrap";
import { isEmpty } from "underscore";

import { ChatMutatedChannel } from "../../../../containers/Chat/chatTypes";
import MembersContainer from "../../../../containers/Member";
import { ChatTypeData } from "../../../../types/Chat/ChatTypeData";
import { UserDataState } from "../../../../types/User";

import { useTypedSelector, useTheme } from "../../../hooks";

import { MemberPicker } from "../../Member/MemberPicker";

import { ChatCardPlaceholder } from "../ChatCardPlaceholder";

import { ChannelCard } from "./ChannelCard";
import { ChatChannelNav } from "./ChannelNav";
import { Empty } from "../..";
import { SettingState } from "../../../../types";

type Props = {
  activeTab: number;
  data: ChatTypeData;
  error: string | null;
  layoutType: "single" | "type";
  simpleMode?: boolean;
  setting: SettingState | {};
  hasMore?: boolean;
  loading?: boolean;
  reFetch?: (next: boolean, refresh: boolean, silent?: boolean) => void;
  changeTab: (activeTab: number) => void;
  createChannel: (
    members: UserDataState[],
    callback?: (text: string) => void
  ) => void;
  onClick?: (channel: ChatMutatedChannel) => void;
  removeChannel: (
    channelUrl: string,
    callback?: (text: string) => void
  ) => void;
} & RouteComponentProps;

const ChatChannelsList: FC<Props> = ({
  activeTab,
  data,
  layoutType,
  match,
  simpleMode,
  hasMore,
  loading,
  changeTab,
  createChannel,
  onClick,
  removeChannel,
  reFetch,
}) => {
  useEffect(() => {
    setLeftColumnVisibility(false);
  }, [match.url]);

  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const styles = {
    button: {
      borderColor: buttonBackgroundColor,
      backgroundColor: buttonBackgroundColor,
      color: buttonColor,
    },
  };

  const [isCreateChatModalOpen, setCreateChatModalVisibility] = useState(false);
  const [unreadCount, setUnreadCount] = useState({
    chat: 0,
    rooms: 0,
    events: 0,
  });
  const [isLeftColumnVisible, setLeftColumnVisibility] = useState(false);

  const history = useHistory();

  const viewingAsGuest = useTypedSelector(state => state.user.viewingAsGuest);

  const { t } = useTranslation("Chat");

  const toggleLeftColumnVisibility = (): void => {
    setLeftColumnVisibility(!isLeftColumnVisible);
  };

  useEffect(() => {
    if (isEmpty(data)) {
      return;
    }
    if (layoutType === "type") {
      setUnreadCount({
        chat: 0,
        rooms: 0,
        events: 0,
      });
      data["CONVERSATIONS FULL"].forEach((value): void => {
        if (value.unreadMessageCount) {
          setUnreadCount(prev => {
            return {
              ...prev,
              chat: prev.chat + (value.unreadMessageCount as number),
            };
          });
        }
      });
      data["GROUP ROOMS FULL"].forEach((value): void => {
        if (value.unreadMessageCount) {
          setUnreadCount(prev => {
            return {
              ...prev,
              rooms: prev.rooms + (value.unreadMessageCount as number),
            };
          });
        }
      });
      data["EVENTS FULL"].forEach((value): void => {
        if (value.unreadMessageCount) {
          setUnreadCount(prev => {
            return {
              ...prev,
              events: prev.events + (value.unreadMessageCount as number),
            };
          });
        }
      });
    }
  }, [layoutType, data]);

  if (viewingAsGuest) {
    return <Alert color="danger">{t("Index.Alert.Guest.Mode")}</Alert>;
  } else if (!data) {
    return <ChatCardPlaceholder />;
  } else if (isEmpty(data)) {
    return <Empty text="No conversation yet!" />;
  }

  const openCreateChatModal = (): void => setCreateChatModalVisibility(true);
  const closeCreateChatModal = (): void => setCreateChatModalVisibility(false);

  let channelGroup: "CONVERSATIONS FULL" | "GROUP ROOMS FULL" | "EVENTS FULL" =
    "CONVERSATIONS FULL";
  switch (activeTab) {
    case 1:
      channelGroup = "GROUP ROOMS FULL";
      break;
    case 2:
      channelGroup = "EVENTS FULL";
      break;
    case 0:
    default:
      break;
  }

  const leaveChannel = (url: string) => (): void => {
    removeChannel(url, () => history.push("/chat-room/"));
  };

  const redirectToNewChat = (url: string): void => {
    history.push("/chat-room-detail/" + url);
  };

  const containerClassName = classnames(
    "sendbird-channel-list w-100",
    !simpleMode ? "border-right bg-white" : "",
    !simpleMode ? (isLeftColumnVisible ? "visible" : "invisible") : ""
  );

  const channelsButtonElement = !simpleMode && (
    <Button
      className={classnames(
        "invisible left-column-visibility-button",
        isLeftColumnVisible ? "button-left-icon-arrow" : ""
      )}
      onClick={toggleLeftColumnVisibility}
      style={styles.button}>
      {!isLeftColumnVisible ? (
        <span>Channels</span>
      ) : (
        <span className={"icon-arrow-down"} />
      )}
    </Button>
  );

  const channelsElement = !simpleMode && (
    <Row className="border-bottom mr-0 mb-2">
      <h6 className="col m-3">Channels </h6>
      <div className="col offset-4">
        <Button
          color="link"
          className="text-decoration-none mt-1"
          onClick={openCreateChatModal}>
          <span className="text-primary font-weight-bolder icon-plus font" />{" "}
        </Button>
      </div>
    </Row>
  );

  const navElement = layoutType === "type" && (
    <ChatChannelNav
      unreadCount={unreadCount}
      changeTab={changeTab}
      activeTab={activeTab}
    />
  );

  const channelCard =
    !isEmpty(data) &&
    layoutType !== "type" &&
    ((data as unknown) as ChatMutatedChannel[]).map((value, index) => {
      return (
        <ChannelCard
          key={index}
          channel={value}
          onClick={onClick}
          className={simpleMode ? "simple-mode" : ""}
          isActive={
            "channel" in match.params && match.params["channel"] === value.url
          }
          leaveChannel={leaveChannel(value.url)}
        />
      );
    });

  const channelGroupCard =
    !isEmpty(data) &&
    layoutType === "type" &&
    data[channelGroup].map((value, index) => (
      <ChannelCard
        key={index}
        channel={value}
        onClick={onClick}
        isActive={
          "channel" in match.params && match.params["channel"] === value.url
        }
        leaveChannel={leaveChannel(value.url)}
      />
    ));

  const channelCardElement = (
    <div
      style={{
        height: layoutType === "type" ? "85vh" : "90vh",
        paddingBottom: 90,
      }}
      className={simpleMode ? "" : "border-top border-light overflow-auto"}>
      {channelGroupCard}
      {channelCard}
    </div>
  );

  const handleMembersAction = (user: { id: string }[]): void => {
    createChannel(user as UserDataState[], (url: string): void => {
      redirectToNewChat(url);
      closeCreateChatModal();
    });
  };

  const handleLoadMore = (): void | undefined =>
    loading ? undefined : reFetch?.(true, false);

  const hasMoreButton = !simpleMode && hasMore && (
    <Button
      color="link"
      className="text-decoration-none mt-1 bg-white"
      style={{
        position: "relative",
        top: -90,
      }}
      onClick={handleLoadMore}>
      {loading ? "Loading" : "Load more "}
      <span className="text-primary font-weight-bolder icon-arrow-down font" />
    </Button>
  );

  return (
    <div className={containerClassName}>
      {channelsButtonElement}
      {channelsElement}
      {navElement}
      {channelCardElement}
      {hasMoreButton}
      <Modal isOpen={isCreateChatModalOpen} className="p-4">
        <MembersContainer
          Layout={MemberPicker}
          onClose={closeCreateChatModal}
          onDone={handleMembersAction}
          // props below were necessary but I don't really use them
          renderActions
          renderActionsDone
        />
      </Modal>
    </div>
  );
};

export const ChatChannels = withRouter(ChatChannelsList);
