import { UserState } from "./types";

export const userStateInit: UserState = {
  error: null,
  data: null,
  notificationSettings: null,
  industry: null,
  originalSessionToken: null,
  viewingAsGuest: false,
};

export default userStateInit;
