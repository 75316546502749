import React from "react";

import ChatSetting from "../../../../containers/Chat/Setting";

import "../../../styles/css/sendbird-uikit.css";

import { ChatSettings } from "..";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../types";
import { ChatMutatedChannel } from "../../../../containers/Chat/chatTypes";

export type ChatSettingsSidebarProps = {} & RouteComponentProps<{
  channel: string;
}>;

const SettingsSidebar: React.FC<ChatSettingsSidebarProps> = ({ match }) => {
  const channel: ChatMutatedChannel = useSelector((state: RootState) => {
    return state.chat.active as ChatMutatedChannel;
  }, shallowEqual);
  return (
    <div className="sendbird-theme--light">
      <ChatSetting Layout={ChatSettings} match={match} channel={channel} />
    </div>
  );
};

export const ChatSettingsSidebar = withRouter(SettingsSidebar);
