import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { map } from "underscore";

import { socialScheme } from "../../../../constants";
import { Dict, UserSocialMedia, UserSocialMediaType } from "../../../../types";

import { IconFontAwesome } from "../../UI/Icon";

export type MemberDetailSocialMediaProps = {
  data: UserSocialMediaType;
  visibilities: Dict<boolean, UserSocialMedia>;
};

type SocialMediaIconType = "facebook" | "instagram" | "linkedin" | "twitter";

export const MemberDetailSocialMedia: FC<MemberDetailSocialMediaProps> = ({
  data,
  visibilities,
}) => {
  const { t } = useTranslation("Member");

  const socialMediaElements = map(
    data,
    (profile, social) =>
      !!profile &&
      visibilities &&
      visibilities[social as UserSocialMedia] && (
        <span key={social} className="pr-3">
          <a
            href={`${socialScheme[social]?.webUrl}${profile}`}
            id={`memberSocialMediaIconLink${social}`}
            rel="noopener noreferrer"
            target="_blank">
            <IconFontAwesome
              style={{ color: "#2954a3" }}
              className="fa-2x"
              name={
                social === t("SocialMedia.Label.Instagram")
                  ? t("SocialMedia.Label.Instagram.Lower.Case")
                  : (social.toLowerCase() as SocialMediaIconType)
              }
            />
          </a>
        </span>
      )
  );

  return <div className="pt-2">{socialMediaElements}</div>;
};
