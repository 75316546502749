import React from "react";

import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Media as ReactstrapMedia } from "reactstrap";

export type PostCardPlaceholderProps = {};

export const PostCardPlaceholder: React.FC<PostCardPlaceholderProps> = () => {
  return (
    <Card>
      <ReactstrapMedia className="p-3">
        <ReactstrapMedia left={true} className="mr-3">
          <Skeleton circle={true} height={45} width={45} />
        </ReactstrapMedia>
        <ReactstrapMedia body className="d-flex">
          <div className="flex-grow-1">
            <p className="mb-0">
              <Skeleton style={{ width: 300 }} />
            </p>
            <p className="mb-0 text-small text-muted">
              <Skeleton style={{ width: 100 }} />
            </p>
          </div>
        </ReactstrapMedia>
      </ReactstrapMedia>
      <CardBody
        className="py-0"
        style={{
          backgroundColor: "transparent",
          paddingLeft: 0,
          paddingRight: 0,
        }}>
        <Skeleton height={270} style={{ borderRadius: 0 }} />
      </CardBody>
      <CardBody>
        <Skeleton count={3} />
      </CardBody>
    </Card>
  );
};
