import React, { ChangeEvent, FC, useState } from "react";

import { Button, ButtonGroup } from "reactstrap";
import _ from "underscore";

import { MemberListLayoutProps } from "../../../containers/Member/types";

import { Member } from "../../../types";

import { SearchBox } from "..";

import { MemberCard } from ".";
import { useTranslation } from "react-i18next";

/**
 * @TODO
 * Refactor to use InfiniteScroll and containers builtin refetch flow.
 */
export const MemberPicker: FC<MemberListLayoutProps> = ({
  onClose,
  onDone,
  data,
  onSelect,
  selected,
  query,
  changeQuery,
  searchedData,
}) => {
  const { t } = useTranslation("Member");
  const [loadNumber, setLoadNumber] = useState<number>(10);

  const loadMore = (max: number) => (): void => {
    setLoadNumber(prevState => {
      return prevState + 10 >= max ? max : prevState + 10;
    });
  };

  const renderMemberList = (members: Member[], title?: string): JSX.Element => {
    const renderMembers: Member[] =
      members.length > loadNumber ? members.slice(0, loadNumber) : members;
    return (
      <>
        {title && <h6 className="text-black-50 border-bottom my-2">{title}</h6>}
        {renderMembers.map((item, index) => {
          const isSelected: boolean = Object.prototype.hasOwnProperty.call(
            selected,
            item.id
          );
          return (
            <div key={index}>
              <MemberCard
                item={item}
                picker={true}
                onSelect={onSelect}
                className={isSelected ? "bg-primary" : ""}
                enableDarkMode={isSelected}
              />
            </div>
          );
        })}
        {!(members.length <= loadNumber) && (
          <div className="text-center">
            <Button color="link" onClick={loadMore(members.length)}>
              {t("MembersPicker.Button.Load.More")}
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <SearchBox
        onValueChange={(event: ChangeEvent<HTMLInputElement>): void => {
          changeQuery(event.target.value);
        }}
        className={"border"}
        value={query !== null && query ? query : ""}
        placeholder="Search The Directory"
      />
      <div style={{ height: "30rem" }}>
        <div className="h-100 overflow-auto">
          {searchedData
            ? searchedData.map(
                (tab, index): JSX.Element => {
                  return (
                    <div key={index}>
                      {renderMemberList(tab.data, tab.title)}
                    </div>
                  );
                }
              )
            : data && renderMemberList(data)}
        </div>
      </div>
      <ButtonGroup className="btn-block">
        <Button onClick={onClose} color="secondary">
          {t("MembersPicker.Button.Close")}
        </Button>
        <Button onClick={onDone} disabled={_.isEmpty(selected)} color="primary">
          {t("MembersPicker.Button.Done")}
        </Button>
      </ButtonGroup>
    </div>
  );
};
