import { MediaContentType } from "../../types";

export const getFileType = (filePath: string): MediaContentType => {
  const parts = filePath.split(".");
  const extension = parts[parts.length - 1].toLowerCase();

  if (["jpg", "jpeg", "png", "gif"].indexOf(extension) > -1) {
    return ("image/" + extension) as MediaContentType;
  }

  return ("application/" + extension) as MediaContentType;
};
