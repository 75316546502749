import { runFunction } from "../../http";
import _ from "underscore";
import { NotificationItem } from "../../../types/Notification/NotificationItem";
import { convertObject } from "../../../lib/utils";

export const getNotifications = async (options: {
  page: number;
  per: number;
}): Promise<{ [key: string]: NotificationItem }> => {
  console.debug("[Service] getNotifications");

  const parseResponse = await runFunction("getNotificationsForUser", options, {
    requireUser: true,
  });

  return _.object(
    _.map(parseResponse, (value: { notification: NotificationItem }) => {
      return [value.notification.id, convertObject(value.notification)];
    })
  );
};
