import React from "react";

import { useTranslation } from "react-i18next";

import WorkspaceContainer from "../../../containers/WorkSpace";

import { TemplateSimple, Workspace } from "../../components";

import { RouteAppComponentProps } from "..";

export type RouteWorkspaceProps = RouteAppComponentProps;

export const RouteWorkspace: React.FC<RouteWorkspaceProps> = ({
  setting,
  ...props
}) => {
  const { t } = useTranslation("WorkSpace");

  return (
    <TemplateSimple pageTitle={t("Index.Page.Title")} setting={setting}>
      <WorkspaceContainer {...props} Layout={Workspace} />
    </TemplateSimple>
  );
};
