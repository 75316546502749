import { adminGet } from "../../http";
import { Config } from "../../../types";
import { Subscriptions } from "../../../types/Subscription";
import camelcaseKeys from "camelcase-keys";

export const getSubscriptions = async (
  config: Config
): Promise<Subscriptions> => {
  console.debug("[Service] getSubscriptions");

  const resp = await adminGet(
    "subscriptions/list",
    {},
    {
      requireUser: true,
      config,
    }
  );
  return camelcaseKeys(resp, { deep: true });
};
