import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media as ReactstrapMedia,
  UncontrolledDropdown,
} from "reactstrap";
import { isEmpty } from "underscore";

import MediaContainer from "../../../../containers/Media";
import { cleanHTMLTags, fromNow, isPhoto } from "../../../../lib/utils";
import {
  EmptyObject,
  Override,
  Post,
  PostFile,
  PostGroup,
  PostItem,
  ReactionType,
  UserDataState,
} from "../../../../types";

import {
  Avatar,
  CommentBar,
  CommentReactions,
  ConfirmModal,
  Media,
  MediaFileList,
  MediaImageGrid,
  MediaLink,
  MentionDisplay,
  PostCardHeader,
  PostNewEditModal,
  TagList,
} from "../..";
import { useTheme } from "../../../hooks";

export type PostCardProps = {
  isDetail?: boolean;
  isMixed?: boolean;
  item: PostItem;
  setting: {
    ClientHostName?: string;
    announcementLabel?: string;
    confettiEffectActions?: [];
  };
  user?: UserDataState | EmptyObject;

  doReaction?: (
    id: string,
    type: "post",
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>;
  removePost?: (
    post: { id: string; group: PostGroup & { id?: string } },
    callback?: (text: string) => void
  ) => Promise<void>;
};

export const PostCard: React.FC<PostCardProps> = ({
  item,
  // isDetail,
  isMixed,
  setting,
  user,

  doReaction,
  removePost,
}) => {
  const { t } = useTranslation("Post");

  const history = useHistory();

  const [isRemoveConfirmModalOpen, setRemoveConfirmModalOpen] = useState(false);

  const toggleRemoveConfirmModal = (): void => {
    setRemoveConfirmModalOpen(!isRemoveConfirmModalOpen);
  };

  /** PostNewEdit Modal state */
  const [isPostNewEditModalOpen, setPostNewEditModalOpen] = useState(false);
  const togglePostNewEditModal = (): void => {
    setPostNewEditModalOpen(!isPostNewEditModalOpen);
  };

  /**
   * @TODO
   * Should we implement redirect to detail on post body click?
   */
  // const onPressDetail = id => {
  //   const { isDetail } = this.props;
  //   !isDetail &&
  //     Actions["group-feed-detail"]({ match: { params: { postId: id } } });
  // };

  const { comments, isLiked, post, images, reaction } = item;

  const readHeadlineBy = item.readHeadlineBy || post.readHeadlineCount;

  const {
    author,
    body,
    commentCount,
    createdAt,
    embedLink,
    group,
    id,
    likeCount,
    linksMentions,
    mentions,
    reactionCount,
    tags,
    video,
    edited,
  } = post as Override<Post, { author: UserDataState }>;

  const {
    text: {
      cardText: { color, fontSize },
      cardTitle,
    },
    card: { backgroundColor },
  } = useTheme();

  const styles = {
    card: {
      backgroundColor,
    },
    body: {
      color,
      fontSize,
    },
    name: {
      color: cardTitle.color,
    },
  };
  /**
   * @FIXME Could author be falsy?
   */
  if (!author) {
    return null;
  }

  const handlePostRemove = (): void => {
    removePost?.(post, text => {
      toast.success(text);
    });
  };

  const name = `${author.firstName} ${author.lastName}`;

  const headerElement = isMixed && (
    <PostCardHeader
      className="text-small py-2 px-3"
      groupId={group.objectId}
      id={`mixedPostGroupHeader${group.name.replace(" ", "")}`}
      name={group.name}
    />
  );

  /**
   * @FIXME
   * Could images be falsy?
   *
   * @PROPOSAL
   * This logic is repeated in native component and probably
   * should be moved to the container. imageFiles and nonImageFiles
   * could be derived from container separately.
   */

  /**
   * @FIXME imageFiles is null in search results.
   */

  const { imageFiles, nonImageFiles } = !images
    ? { imageFiles: [], nonImageFiles: [] }
    : images.reduce<{
        imageFiles: PostFile[];
        nonImageFiles: PostFile[];
      }>(
        (filesData, item) =>
          isPhoto(item.contentType)
            ? {
                imageFiles: [...filesData.imageFiles, item],
                nonImageFiles: filesData.nonImageFiles,
              }
            : {
                imageFiles: filesData.imageFiles,
                nonImageFiles: [...filesData.nonImageFiles, item],
              },
        { imageFiles: [], nonImageFiles: [] }
      );

  const videoElement = video && <MediaContainer video={video} Layout={Media} />;

  const linkElement = embedLink && !isEmpty(embedLink) && (
    <MediaLink
      url={embedLink.originalUrl || embedLink.url}
      imageUrl={embedLink.imageUrl}
      /**
       * @FIXME
       * metadata standard is not consistent in announcements and posts
       */
      metadata={{ image: embedLink.imageUrl, title: embedLink.title }}
    />
  );

  const tagListElement = (
    <TagList data={tags} type="post" className="pt-2 px-3" />
  );

  /**
   * @TODO
   * Make sure that read more button is not necessary here. isMixed prop
   * is not necessary as well if so.
   */
  const bodyElement = body && (
    <div className="p-3">
      <MentionDisplay
        id={`postText${id}`}
        text={cleanHTMLTags(body || "")}
        allowRenderVideo={false}
        mentions={mentions}
        linkMentions={linksMentions}
        viewMore={{ numberOfLines: 4 }}
        style={styles.body}
      />
    </div>
  );

  const mediaImegeGridTitleElement = (
    <MentionDisplay
      text={cleanHTMLTags(body || "")}
      allowRenderVideo={false}
      mentions={mentions}
      linkMentions={linksMentions}
    />
  );

  const mediaImegeGridCaptionElement = (
    <CommentReactions
      commentCount={commentCount}
      id={id}
      likeCount={likeCount}
      reactionCount={reactionCount ?? {}}
      seenCount={readHeadlineBy ?? 0}
      style={{ backgroundColor: undefined, marginBottom: "0px !important" }}
      textStyle={{ color: "#FFF" }}
      type={"post"}
    />
  );

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    history.push(`/group-feed-detail/${id}`);
  };

  const viewDetailElement = !doReaction && (
    <CardBody className="pb-0 px-3 pt-0 small">
      <a
        onClick={handleClick}
        href={`/group-feed-detail/${id}`}
        className="text-primary">
        {t("Card.Button.Read.More")}
      </a>
    </CardBody>
  );

  const authorOptionsElement = user?.id === (author.id || author.objectId) && (
    <UncontrolledDropdown className="d-inline-block float-right">
      <DropdownToggle
        color="light"
        className="text-decoration-none py-0 px-1 ml-2">
        <i className="icon-options" style={{ fontSize: "0.8rem" }} />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={togglePostNewEditModal}>
          {t("Card.Option.Edit")}
        </DropdownItem>
        {removePost && (
          <DropdownItem
            className="text-danger"
            onClick={toggleRemoveConfirmModal}>
            {t("Card.Option.Remove")}
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const authorElement = (
    <ReactstrapMedia className="p-3">
      <ReactstrapMedia left={true} className="mr-3">
        <Avatar
          size="45"
          name={name}
          src={author.profile?.thumbUrl}
          id={author.id || author.objectId}
          round={true}
        />
      </ReactstrapMedia>
      <ReactstrapMedia body className="d-flex">
        <div className="flex-grow-1">
          <p
            id={`mixedPostGroupAuthor${id}`}
            className="mb-0"
            style={styles.name}>
            {name}
          </p>
          <p className="mb-0 text-small text-muted">{`${fromNow(createdAt)} ${
            edited ? " (edited) " : ""
          }`}</p>
        </div>
        <div>{authorOptionsElement}</div>
      </ReactstrapMedia>
    </ReactstrapMedia>
  );

  const removeConfirmModal = removePost && (
    <ConfirmModal
      color={"danger"}
      confirmText={t("Card.Alert.Button.Ok")}
      dismissText={t("Card.Alert.Button.Cancel")}
      message={t("Card.Alert.Text")}
      isOpen={isRemoveConfirmModalOpen}
      title={t("Card.Alert.Title")}
      onConfirm={handlePostRemove}
      toggle={toggleRemoveConfirmModal}
    />
  );

  const handleDoReaction =
    doReaction &&
    (async (
      id: string,
      type: "post",
      reaction: ReactionType
    ): Promise<void> => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      await doReaction(id, type, reaction, post.author.objectId);
    });

  return (
    <Card id={`mixedPostGroupCard${id}`} className="mb-3" style={styles.card}>
      {headerElement}
      {authorElement}
      {videoElement}
      {bodyElement}
      <MediaImageGrid
        caption={mediaImegeGridCaptionElement}
        data={imageFiles}
        title={mediaImegeGridTitleElement}
      />
      {tagListElement}
      {linkElement}
      <MediaFileList data={nonImageFiles} header={t("Card.Text.Attachments")} />
      {viewDetailElement}
      <CommentBar
        className="mt-2"
        commentCount={commentCount}
        comments={comments}
        groupId={post.group.objectId}
        id={post.id}
        isLiked={isLiked}
        likeCount={likeCount}
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        itemAuthorId={post.author.objectId}
        reaction={reaction}
        reactionCount={reactionCount}
        seenCount={readHeadlineBy}
        setting={setting}
        type="post"
        user={user}
        doReaction={handleDoReaction}
      />
      {removeConfirmModal}
      <PostNewEditModal
        id={id}
        isOpen={isPostNewEditModalOpen}
        toggle={togglePostNewEditModal}
      />
    </Card>
  );
};
