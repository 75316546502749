import React from "react";

import { Alert } from "reactstrap";

import { AnnouncementCard, AnnouncementCardPlaceholder } from "..";
import { AnnouncementDetailLayoutProps } from "../../../../containers/Announcement/Detail";

export const AnnouncementDetail: React.FC<AnnouncementDetailLayoutProps> = ({
  error,
  item,
  ...props
}) => {
  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  if (!item) {
    return <AnnouncementCardPlaceholder />;
  }

  return (
    <AnnouncementCard
      {...props}
      item={item}
      showAttachementsList={true}
      showAuthor={true}
      showBody={true}
      showHeader={false}
      showSubTitle={true}
    />
  );
};
