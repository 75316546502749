import React from "react";
import classnames from "classnames";

import { currencyFormat } from "../../../lib/utils";
import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

import { Icon } from "../UI/Icon";

export type EventFeeProps = {
  className?: string;
  fee: number;
  style?: React.CSSProperties;
};

export const EventFee: React.FC<EventFeeProps> = ({
  className,
  fee,
  style,
}) => {
  const {
    text: { listText },
  } = useTheme();

  const eventFeeStyles = {
    listText: {
      ...listText,
      ...textThemingWorkaround,
      padding: 0,
    },
  };

  return fee > 0 ? (
    <div className={classnames(className)} style={style}>
      <Icon
        className="mr-2"
        style={eventFeeStyles.listText}
        type="FontAwesome"
        name="dollar-sign"
      />
      {currencyFormat(fee)}
    </div>
  ) : null;
};
