import React, { FC, useState, SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { Button, Media } from "reactstrap";

import MentionInputContainer from "../../../containers/Mention";

import {
  CommentRequest,
  MentionUser,
  MentionLink,
  UserDataState,
} from "../../../types";

import { useTheme } from "../../hooks";

import { Avatar, MentionInput } from "..";

export type CommentInputProps = {
  disabled?: boolean;
  newCommentInputRef?: React.RefObject<HTMLInputElement>;
  sending?: boolean;
  user?: UserDataState;

  addComment: (
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void
  ) => void;
};

export const CommentInput: FC<CommentInputProps> = ({
  disabled,
  newCommentInputRef,
  sending,
  user,

  addComment,
}) => {
  const { t } = useTranslation("Comment");

  const [commentBody, setCommentBody] = useState("");
  const [mentions, setMentions] = useState<MentionUser[]>([]);
  const [linkMentions, setLinkMentions] = useState<MentionLink[]>([]);

  const {
    comment: { commentButton: commentButtonStyles },
  } = useTheme();

  const styles = {
    mediaBody: {
      marginLeft: 10,
      backgroundColor: commentButtonStyles.backgroundColor,
      borderRadius: 5,
      paddingLeft: 5,
      paddingRight: 5,
      position: "relative",
    },
    mediaMain: {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 0,
      paddingRight: 0,
    },
    submitButtonSpan: {
      fontSize: 15,
      fontWeight: 500,
    },
    submitButton: {
      position: "absolute",
      bottom: 0,
      right: 15,
    },
  } as const;

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();

    addComment({
      mentions: mentions,
      linksMentions: linkMentions,
      text: commentBody,
      author: user,
    });

    setMentions([]);
    setLinkMentions([]);
    setCommentBody("");
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (commentBody.trim() !== "" && e.which === 13 && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const name = `${user?.firstName} ${user?.lastName}`;
  const imageUrl = user?.profile?.thumbUrl;

  const isButtonDisabled = commentBody.trim() === "" || sending;

  return (
    <form onSubmit={handleSubmit}>
      <Media style={styles.mediaMain} className="mx-2 comment-input">
        <Media>
          <Avatar src={imageUrl} name={name} round={true} size="35" />
        </Media>
        <Media body>
          <div style={styles.mediaBody}>
            <MentionInputContainer
              id={`commentInput${new Date().getTime()}`}
              Layout={MentionInput}
              disabled={disabled}
              includeHereAndChannel={true}
              placeholder={t("Bar.Hint.Comment")}
              value={commentBody}
              mentionUser={setMentions}
              mentionLink={setLinkMentions}
              usersMentioned={mentions}
              linksMentioned={linkMentions}
              onChangeText={setCommentBody}
              maxHeight={50}
              onKeyDown={handleKeyDown}
              inputRef={newCommentInputRef}
              style={{ paddingRight: 50 }}
            />
            <Button
              id={`commentSendButton${new Date().getTime()}`}
              className="py-1"
              color="link"
              disabled={isButtonDisabled}
              style={styles.submitButton}
              type="submit">
              <span style={styles.submitButtonSpan}>
                {t("Input.Button.Edit.Send")}
              </span>
            </Button>
          </div>
        </Media>
      </Media>
    </form>
  );
};
