/* eslint-disable @typescript-eslint/camelcase */
import {
  getProducts as _getProducts,
  getSubscriptions as _getSubscriptions,
  createSubscription as _createSubscription,
  cancelSubscription as _cancelSubscription,
  resumeSubscription as _resumeSubscription,
  setPaymentMethod as _setPaymentMethod,
} from "../../services/api/Subscription";

import { logException } from "../../lib/utils";
import {
  SUBCRIPTION_ALL_SET,
  SUBCRIPTION_PRODUCT_ALL_SET,
  SUBCRIPTION_DENY_SET,
} from "./constants";

import {
  AppThunkAction,
  AppThunkDispatch,
  GetState,
  UserDataState,
  SettingConfigData,
} from "../../types";
import { NewSubscription } from "../../types/Subscription";
import _ from "underscore";

export const getProducts = (refresh: boolean): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getProducts", refresh);

  try {
    const { setting, user } = getState();
    const { subscription } = getState();
    const items = subscription.products;
    if (items && !refresh) {
      return;
    } else {
      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: (setting.config as SettingConfigData).adminURL,
      };

      const data = await _getProducts(config);

      dispatch({
        type: SUBCRIPTION_PRODUCT_ALL_SET,
        data,
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getSubscriptions = (refresh: boolean): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getSubscriptions", refresh);

  try {
    const { setting, user } = getState();
    const { subscription } = getState();
    const items = subscription.subscriptions;
    if (items && !refresh) {
      return;
    } else {
      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: (setting.config as SettingConfigData).adminURL,
      };

      const data = await _getSubscriptions(config);

      dispatch({
        type: SUBCRIPTION_ALL_SET,
        data,
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const setPaymentMethod = (
  paymentMethod: string
): AppThunkAction<void | string> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void | string> => {
  console.debug("[Action] setPaymentMethod", paymentMethod);

  try {
    const { setting, user } = getState();
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: (setting.config as SettingConfigData).adminURL,
    };

    const resp = await _setPaymentMethod(paymentMethod, config);
    if (resp.error) {
      throw new Error(resp.error);
    }
    return resp.message;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const createSubscription = (
  data: NewSubscription
): AppThunkAction<void | string> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void | string> => {
  console.debug("[Action] createSubscription", data);

  try {
    const { setting, user } = getState();
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: (setting.config as SettingConfigData).adminURL,
    };

    const resp = await _createSubscription(data, config);
    if (resp.error) {
      throw new Error(resp.error);
    }
    return resp.message;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const cancelSubscription = (
  subscriptionId: string
): AppThunkAction<void | string> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void | string> => {
  console.debug("[Action] cancelSubscription", subscriptionId);

  try {
    const { setting, user } = getState();
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: (setting.config as SettingConfigData).adminURL,
    };

    const resp = await _cancelSubscription(subscriptionId, config);
    if (resp.error) {
      throw new Error(resp.error);
    }
    return resp.message;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const resumeSubscription = (
  subscriptionId: string
): AppThunkAction<void | string> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void | string> => {
  console.debug("[Action] cancelSubscription", subscriptionId);

  try {
    const { setting, user } = getState();
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: (setting.config as SettingConfigData).adminURL,
    };

    const resp = await _resumeSubscription(subscriptionId, config);
    if (resp.error) {
      throw new Error(resp.error);
    }
    return resp.message;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const checkCurrentSubscription = (): AppThunkAction<
  void | boolean
> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void | boolean> => {
  console.debug("[Action] checkCurrentSubscription");

  try {
    const { setting, user, subscription } = getState();
    if (!setting.setting?.subscriptions_enabled) {
      return false;
    }
    if (_.isEmpty(setting.setting?.subscriptions_alert)) {
      return false;
    }
    if (subscription.denySubscription) {
      return false;
    }
    if (subscription.shownSubscriptionDialog) {
      return false;
    }

    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: (setting.config as SettingConfigData).adminURL,
    };

    const data = await _getSubscriptions(config);

    const subscribed = data.subscriptions.find(sub => sub.status === "active");

    return !subscribed;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const denySubscription = (): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] denySubscription");

  try {
    dispatch({
      type: SUBCRIPTION_DENY_SET,
      data: true,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
