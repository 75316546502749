import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";

import { SupportModal } from ".";

export type SupportButtonWModalProps = {
  block?: boolean;
};

export const SupportButtonWModal: React.FC<SupportButtonWModalProps> = ({
  block,
}) => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const toggleSupportModal = (): void => {
    setIsSupportModalOpen(!isSupportModalOpen);
  };

  const { t } = useTranslation();

  const buttonLinkStyle = {
    ...{
      ...textThemingWorkaround,
      textAlign: undefined,
    },
    color: "#fff",
  };

  return (
    <>
      <Button
        block={block}
        color="link"
        id="contactSupportButton"
        style={buttonLinkStyle}
        onClick={toggleSupportModal}>
        {t("User:Login.Contact.Support")}
      </Button>
      <SupportModal isOpen={isSupportModalOpen} toggle={toggleSupportModal} />
    </>
  );
};
