import React, { useState } from "react";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";

import {
  SubscriptionListLayoutProps,
  NewSubscriptionProduct,
} from "../../../containers/Subscription";
import { ProductView } from "./Product";
import { SubscriptionView } from "./Subcription";
import { SubscriptionPlaceholder as PlaceHolder } from "./SubscriptionPlaceholder";

import {
  Products,
  Product,
  Price,
  Subscriptions,
} from "../../../types/Subscription";
import { isEmpty } from "underscore";
import { Empty, CreatePaymentMethod } from "..";

type Props = SubscriptionListLayoutProps;

export const SubscriptionList: React.FC<Props> = ({
  error,
  setting,
  refreshing,
  data,
  products,
  showPlaceholder,
  createSubscription,
  cancelSubscription,
  resumeSubscription,
  setPaymentMethod,
  sending,
  reFetch,
}) => {
  const [newSubscriptionData, setNewSubscriptionData] = useState(
    (null as unknown) as NewSubscriptionProduct
  );
  const [showPaymentMethodUpdating, setPaymentMethodUpdating] = useState(false);

  if (showPlaceholder) {
    return <PlaceHolder />;
  }

  if (isEmpty(products?.products)) {
    return <Empty />;
  }

  const onShowPayment = (product: Product, price: Price): void => {
    setNewSubscriptionData({
      productId: product.id,
      priceId: price.id,
      name: product.name,
    });
  };

  const onClosePayment = (): void => {
    setNewSubscriptionData((null as unknown) as NewSubscriptionProduct);
    setPaymentMethodUpdating(false);
  };

  const onCreateSubscription = (token: string): void => {
    onClosePayment();
    createSubscription(token, newSubscriptionData, text => {
      toast.success(text);
      reFetch(true);
    });
  };

  const onCancelSubscription = (id: string): void => {
    cancelSubscription(id, text => {
      toast.success(text);
      reFetch(true);
    });
  };

  const onResumeSubscription = (id: string): void => {
    resumeSubscription(id, text => {
      toast.success(text);
      reFetch(true);
    });
  };

  const onShowPaymentMethodUpdate = (): void => {
    setPaymentMethodUpdating(true);
  };

  const onHandleUpdatingPaymentMethod = (token: string): void => {
    onClosePayment();
    setPaymentMethod(token, text => {
      toast.success(text);
    });
  };

  const paymentModal = newSubscriptionData ? (
    <CreatePaymentMethod
      isPayModalOpen={true}
      togglePayModal={onClosePayment}
      setting={setting}
      onProcess={onCreateSubscription}
    />
  ) : showPaymentMethodUpdating ? (
    <CreatePaymentMethod
      isPayModalOpen={true}
      togglePayModal={onClosePayment}
      setting={setting}
      onProcess={onHandleUpdatingPaymentMethod}
    />
  ) : null;

  return (
    <div>
      {!!error && (
        <Alert classnName="mt-2 mb-0 text-center" color="danger">
          {error}
        </Alert>
      )}
      <div>
        <ProductView
          data={products as Products}
          showPayment={onShowPayment}
          sending={sending}
        />
        <SubscriptionView
          setting={setting}
          data={data as Subscriptions}
          cancelSubscription={onCancelSubscription}
          resumeSubscription={onResumeSubscription}
          showPaymentMethodUpdate={onShowPaymentMethodUpdate}
          sending={sending}
        />
        {paymentModal}
        {!!error && (
          <Alert classnName="mt-2 mb-0 text-center" color="danger">
            {error}
          </Alert>
        )}
      </div>
    </div>
  );
};
