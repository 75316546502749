import { adminGet } from "../../http";

export const exportInvoice = async (
  invoiceId: string,
  config: { sessionToken: string; adminURL: string }
): Promise<string> => {
  console.debug("[Service] exportInvoice", { invoiceId, config });
  const { url } = await adminGet(
    "invoices/pdf",
    // eslint-disable-next-line @typescript-eslint/camelcase
    { invoice_id: invoiceId },
    { requireUser: true, config }
  );
  return url;
};
