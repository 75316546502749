import { ThemeData } from "../../types";

export const defaultTheme: ThemeData = {
  general: {
    paddingHorizontal: 0,
    paddingVertical: 10,
    backgroundColor: "#E7EBF2",
    highlightColor: "#b1d3f1",
    color: "#0D0D0D",
    CTAFloatingButton: true,
  },
  navigation: {
    backgroundColor: "#2954A3",
    color: "#ffffff",
    fontFamily: "SFUIDisplay-Regular",
    icon: {
      color: "#ffffff",
    },
  },
  tabTop: {
    height: 40,
    underlineColor: "#FFFFFF",
    inactive: {
      backgroundColor: "#2954A3",
    },
    inactiveText: {
      color: "#ffffff",
      opacity: 0.9,
      fontFamily: "SFUIDisplay-Regular",
    },
    active: {
      backgroundColor: "#2954A3",
    },
    activeText: {
      color: "#ffffff",
      fontFamily: "SFUIDisplay-Regular",
    },
  },
  button: {
    height: 45,
    backgroundColor: "#2954A3",
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 8,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    text: {
      paddingRight: 5,
      paddingLeft: 5,
      textAlign: "center",
      fontSize: 15,
      lineHeight: 20,
      color: "#FFFFFF",
      fontFamily: "System",
      fontWeight: "600",
    },
    roundLeft: {
      flex: 0.5,
      height: 40,
      borderBottomLeftRadius: 8,
      borderTopLeftRadius: 8,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      borderColor: "#2954A3",
    },
    roundRight: {
      flex: 0.5,
      height: 40,
      borderBottomRightRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderColor: "#2954A3",
    },
  },
  searchBox: {
    paddingLeft: 7,
    paddingRight: 9,
    backgroundColor: "#FFFFFF",
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 8,
    text: {
      fontSize: 17,
      lineHeight: 20,
      color: "#FFFFFF",
      fontFamily: "SFUIDisplay-Regular",
    },
  },
  cardItem: {
    backgroundColor: "transparent",
    paddingRight: 0,
    paddingLeft: 0,
  },
  text: {
    header: {
      color: "#0D0D0D",
      fontSize: 22,
      lineHeight: 25,
      fontWeight: "600",
      letterSpacing: -0.39,
      fontFamily: "Manrope-semibold",
    },
    subHeader: {
      color: "#0D0D0D",
      fontSize: 13,
      fontFamily: "SFUIDisplay-Regular",
      opacity: 1,
      lineHeight: 15,
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    cardTitle: {
      color: "#0D0D0D",
      fontSize: 17,
      lineHeight: 20,
      fontWeight: "600",
      letterSpacing: -0.3,
      fontFamily: "Manrope-semibold",
      paddingLeft: 0,
      paddingRight: 0,
    },
    cardText: {
      color: "#0D0D0D",
      fontSize: 15,
      fontWeight: "normal",
      lineHeight: 19,
      opacity: 0.8,
      fontFamily: "System",
      paddingLeft: 0,
      paddingRight: 0,
    },
    listTitle: {
      color: "#0D0D0D",
      fontSize: 15,
      lineHeight: 20,
      fontWeight: "500",
      letterSpacing: -0.26,
      fontFamily: "Manrope-regular",
      paddingLeft: 0,
      paddingRight: 0,
    },
    listText: {
      color: "#0D0D0D",
      fontSize: 14,
      lineHeight: 17,
      fontFamily: "SFUIDisplay-Regular",
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  image: {
    borderRadius: 0,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    marginLeft: -15,
    marginRight: -15,
  },
  avatar: {
    size: 80,
    borderRadius: 30,
  },
  tag: {
    backgroundColor: "#F5F7FA",
    text: {
      color: "#0D0D0D",
    },
  },
  shadow: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  divider: {
    backgroundColor: "#E7EBF2",
    size: 10,
    text: {
      color: "#000000",
      fontSize: 15,
      fontWeight: "500",
      lineHeight: 18,
      fontFamily: "SFUIDisplay-Regular",
      backgroundColor: "#E7EBF2",
      marginLeft: 0,
    },
  },
  alphabet: {
    backgroundColor: "transparent",
    inactiveText: {
      color: "#cccccc",
    },
    activeText: {
      color: "#000000",
    },
  },
  badge: {
    backgroundColor: "#e02222",
    color: "#FFFFFF",
  },
  card: {
    backgroundColor: "#FFFFFF",
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 0,
    borderRadius: 0,
  },
  list: {
    backgroundColor: "#FFFFFF",
    marginLeft: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 0,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 2,
    elevation: 1,
    marginBottom: 2,
  },
  tabBottom: {
    inactive: {
      backgroundColor: "#FFFFFF",
    },
    inactiveText: {
      color: "#B7B7B7",
    },
    active: {
      backgroundColor: "#FFFFFF",
    },
    activeText: {
      color: "#2954A3",
    },
  },
  detail: {
    backgroundColor: "#FFFFFF",
    padding: 20,
    card: {
      elevation: 0,
      shadowOpacity: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  comment: {
    itemBackgroundColor: "transparent",
    stats: {
      color: "#000000",
      fontSize: 13,
      fontFamily: "SFUIDisplay-Regular",
      lineHeight: 15,
    },
    commentButton: {
      backgroundColor: "#F5F7FA",
      borderColor: "#F5F7FA",
      borderRadius: 8,
      text: {
        color: "#0D0D0D",
        fontSize: 15,
        lineHeight: 18,
        fontFamily: "SFUIDisplay-Regular",
        fontWeight: "normal",
      },
    },
    likeButton: {
      backgroundColor: "#F5F7FA",
      color: "#0D0D0D",
      borderColor: "#F5F7FA",
      borderRadius: 8,
      text: {
        color: "#0D0D0D",
        fontSize: 15,
        lineHeight: 18,
        fontFamily: "SFUIDisplay-Regular",
        fontWeight: "normal",
      },
    },
  },
  pins: {
    backgroundColor: "#FFFFFF",
    color: "#0D0D0D",
    borderRadius: 14,
  },
  onBoarding: {
    topColor: "#CEF2EE",
    bottomColor: "#021b4e",
    color: "#60C5B9",
  },
  event: {
    rsvp: {
      backgroundColor: "#FFFFFF",
      borderRadius: 6,
      paddingTop: 0,
      paddingBottom: 0,
      borderColor: "#2954A3",
      text: {
        paddingRight: 5,
        paddingLeft: 5,
        fontSize: 12,
        lineHeight: 16,
        color: "#2954A3",
        fontFamily: "Manrope-regular",
      },
    },
    yes: {
      backgroundColor: "transparent",
      color: "#60C5B9",
      borderColor: "#60C5B9",
    },
    no: {
      backgroundColor: "transparent",
      color: "#00355E",
      borderColor: "#00355E",
    },
    wait: {
      backgroundColor: "transparent",
      color: "#ff8857",
      borderColor: "#ff8857",
    },
  },
  chat: {
    backgroundColor: "#2954A3",
    left: {
      borderTopRightRadius: 14,
      borderBottomRightRadius: 14,
      borderBottomLeftRadius: 14,
      backgroundColor: "#FFFFFF",
      color: "#0D0D0D",
    },
    right: {
      borderTopRightRadius: 14,
      borderTopLeftRadius: 14,
      borderBottomLeftRadius: 14,
      backgroundColor: "#D4E4FA",
      color: "#0D0D0D",
    },
  },
  login: {
    backgroundColor: "#2954A3",
    button: {
      backgroundColor: "#FF6800",
    },
  },
  sideBar: {
    left: {
      backgroundColor: "#FFFFFF",
      subBackgroundColor: "#F0F2F5",
      color: "#0D0D0D",
      button: {
        backgroundColor: "#F0F2F5",
        color: "#0D0D0D",
      },
    },
    right: {
      backgroundColor: "#FFFFFF",
      subBackgroundColor: "#F0F2F5",
      color: "#0D0D0D",
      button: {
        backgroundColor: "#F0F2F5",
        color: "#0D0D0D",
      },
    },
  },
};
