import React from "react";

import { RouteComponentProps } from "react-router-dom";

import SearchContainer from "../../../containers/Search";

import { Search } from "../../components";

export type RouteSearchProps = RouteComponentProps;

export const RouteSearch: React.FC<RouteSearchProps> = props => (
  <SearchContainer
    {...props}
    Layout={Search}
    searchType={
      props.location.state ? props.location.state.searchType : "announcement"
    }
    query={props.location.state ? props.location.state.query : ""}
    triggerSearch={props.location.state && props.location.state.triggerSearch}
  />
);
