import { adminPost } from "../../http";
import { Config } from "../../../types";
import { NewSubscription } from "../../../types/Subscription";

export const createSubscription = async (
  newSubscription: NewSubscription,
  config: Config
): Promise<{ message?: string; error?: string }> => {
  console.debug("[Service] createSubscription", newSubscription);

  return await adminPost(
    "subscriptions/create",
    {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_id: newSubscription.productId,
      // eslint-disable-next-line @typescript-eslint/camelcase
      price_id: newSubscription.priceId,
      // eslint-disable-next-line @typescript-eslint/camelcase
      payment_method: newSubscription.paymentMethod,
    },
    {
      requireUser: true,
      config,
    }
  );
};
