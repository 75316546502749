import { convertObject } from "../../../lib/utils";
import { Group } from "../../../types";

import { runQuery } from "../../http";

export const getGroup = async (id: string): Promise<Group> => {
  console.debug("[Service] getGroup", id);

  return convertObject(
    (await runQuery(
      "Group",
      (query: Parse.Query) =>
        query
          .include("type")
          .equalTo("objectId", id)
          .first(),
      { requireUser: true }
    )) as Parse.Object<Group>
  );
};
