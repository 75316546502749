import React from "react";
import { Modal, Button, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

import { MediaImage } from "../Media/Image";
import { Heading } from "./Heading";
import { isEmpty } from "underscore";
import { HTML } from ".";

export type InAppNotificationModal = {
  buttonText?: string;
  id?: string;
  imageUrl?: string;
  isOpen: boolean;
  message: string;
  options: any;
  title?: string;
  url?: string;

  toggle: () => void;
};

export const InAppNotificationModal: React.FC<InAppNotificationModal> = ({
  id,
  isOpen,
  message,
  options,
  title,

  toggle,
}) => {
  const {
    button,
    text: { listText, header },
  } = useTheme();

  const inAppNotificationModalStyle = {
    title: {
      ...header,
      ...textThemingWorkaround,
    },
    content: {
      ...listText,
      ...textThemingWorkaround,
    },
    button: {
      ...button,
      height: 40,
      placeItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    buttonText: {
      ...button.text,
      ...textThemingWorkaround,
      textAlign: undefined,
    },
  };

  const { link, imageUrl } = options || {};
  // eslint-disable-next-line @typescript-eslint/camelcase
  const profileId = options?.profile_id;
  const wellcomeNotification = !isEmpty(profileId);
  const buttonText = link?.title || "Link";
  const url = link?.url;

  const imageElement = !!imageUrl && (
    <div style={{ backgroundColor: "black" }} className="mb-3">
      <MediaImage
        src={imageUrl}
        className="p-0"
        resizeMode="contain"
        height={270}
      />
    </div>
  );

  const titleElement = (
    <Heading
      style={inAppNotificationModalStyle.title}
      text={title}
      className="px-3"
    />
  );

  const messageElement = (
    <div style={inAppNotificationModalStyle.content} className="px-3">
      <HTML body={message} />
    </div>
  );

  const linkElement = !!url && (
    <ModalFooter className="text-right px-3 py">
      <Button
        style={inAppNotificationModalStyle.button}
        block
        href={url.indexOf("http") === -1 ? `//${url}` : url}
        target="_blank"
        onClick={toggle}>
        <span style={inAppNotificationModalStyle.buttonText}>{buttonText}</span>
      </Button>
    </ModalFooter>
  );

  const linkProfileElement = !!wellcomeNotification && (
    <ModalFooter className="text-right px-3 py">
      <Button
        style={inAppNotificationModalStyle.button}
        block
        href={`/profile/${profileId}`}
        target="_blank"
        onClick={toggle}>
        <span style={inAppNotificationModalStyle.buttonText}>
          {"View profile"}
        </span>
      </Button>
    </ModalFooter>
  );

  return (
    <Modal isOpen={isOpen} centered={true} id={id}>
      <ModalHeader toggle={toggle} className="pb-0 pt-2" />
      <ModalBody className="px-0">
        {imageElement}
        {titleElement}
        {messageElement}
      </ModalBody>
      {linkElement}
      {linkProfileElement}
    </Modal>
  );
};
