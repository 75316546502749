import {
  addEvent as _addEvent,
  addEventAttendee as _addEventAttendee,
  addEventReadBy as _addEventReadBy,
  getEvent as _getEvent,
  getEventAttendee,
  getEventRecap as _getEventRecap,
  getEvents as _getEvents,
  manageEvents as _manageEvents,
  removeEvent as _removeEvent,
  searchFullTextEvents as _searchFullTextEvents,
  updateEvent as _updateEvent,
  updateEventAttendee as _updateEventAttendee,
} from "../../services/api";
import { removeUnreadEvent } from "../Notification/action";
import { logException } from "../../lib/utils";
import _ from "underscore";
import { page as _page } from "../../constants";
import { ThunkDispatch } from "redux-thunk";
import {
  Dict,
  GlobalAction,
  RootState,
  AppThunkDispatch,
  AppThunkAction,
  GetState,
  ProgressCallback,
} from "../../types";
import { UserDataState } from "../../types/User/UserDataState";
import { EventSingle } from "../../types/";
import { EventItem } from "../../types/Event/EventItem";
import { EventAttendeeAdditionalData } from "../../types/Event/EventAttendeeAdditionalData";
import { EventOptions } from "../../types/Event/EventOptions";
import { EventAttendee } from "../../types/Event/EventAttendee";
import { ChangeAttendee } from "../../types/Event/ChangeAttendee";
import { VideoType } from "../../types/VideoType";
import { GetEventsOption } from "../../types/Event/GetEventsOption";
import { Attachment } from "../../types/Event/EventAttachment";
import {
  EVENT_ALL_SET,
  EVENT_DRAFT_SET,
  EVENT_PUBLISHED_SET,
  EVENT_RECAP_SET,
  EVENT_SEARCH_HISTORY_SET,
  EVENT_SEARCH_SET,
  EVENT_SET,
} from "./constants";
import { SettingConfigData } from "../../types/Setting/SettingConfigData";
import { NOTIFICATION_UPLOAD_SET } from "../Notification/constants";
import i18n from "../../middlewares/i18next";
import { getDictDefinedValues } from "../../lib/utils/store";

const per = _page.event;

export type CreateEventActionReturn = { data: true };

export type ManageEventActionReturn = {
  data: EventItem;
};

/**
 * Get list events or events on calendar (by month)
 * @param option
 * @param next
 * @param refresh
 * @param changeMonth
 */
export const getEvents = (
  option: GetEventsOption,
  next?: boolean,
  refresh?: boolean,
  changeMonth?: boolean,
  refreshWhenReactive?: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getEvents", {
    option,
    next,
    refresh,
    changeMonth,
    refreshWhenReactive,
  });

  try {
    const { event } = getState();
    let { page } = event.list;
    const { items } = event.list;
    if (!_.isEmpty(items) && !refresh && !next && !changeMonth) {
      return;
    }

    if (refresh || changeMonth) {
      page = 0;
      if (refreshWhenReactive) {
        dispatch({
          type: EVENT_ALL_SET,
          data: {
            items: null,
            page,
            hasMore: true,
          },
        });
      }
    }
    if (next) {
      page++;
    }
    const { group } = option;
    const options = {
      per: option.filter === "all" ? 1000 : per,
      page,
      showMyAttendee: true,
      groupId: group ? group.id : null,
      ...option,
    };

    let data = await _getEvents(options);

    //Remove unread couter badge as soon as data are loaded
    dispatch(
      removeUnreadEvent(getDictDefinedValues(data).map(event => event.event.id))
    );

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: EVENT_ALL_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Search events by text and #tag
 * @param search
 * @param next
 * @param refresh
 */
export const searchFullTextEvents = (
  search: string,
  next: boolean,
  refresh: boolean
): AppThunkAction => {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<void> => {
    console.debug("[Action] searchFullTextEvents");

    try {
      const { event, group } = getState();
      const { myGroups } = group;

      const { searchList } = event;
      let { page } = searchList;
      const { items } = searchList;

      if (_.isEmpty(searchList)) {
        page = 0;
        dispatch({
          type: EVENT_SEARCH_SET,
          data: {
            items: {},
            page,
            hasMore: true,
          },
        });
      } else {
        if (refresh) {
          page = 0;
        } else if (next) {
          page++;
        }
      }

      let data = await _searchFullTextEvents(
        {
          per,
          page,
          search,
        },
        myGroups && Object.keys(myGroups)
      );

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: EVENT_SEARCH_SET,
        data: {
          items: data,
          page,
          hasMore,
        },
      });

      /**
       * Add the search text into search history
       */
      dispatch({
        type: EVENT_SEARCH_HISTORY_SET,
        data: search,
      });
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
};

/**
 * Get event detail
 * @param id
 * @param options
 * @param refresh
 * @param callback
 */
export const getEvent = (
  id: string,
  options: EventOptions,
  refresh: boolean,
  callback?: (event: EventSingle) => void
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getEvent");

  try {
    const { event, user } = getState();
    /**
     * @FIXME Temporarily workaround
     */
    const uid = (user.data && user.data.id) || (options.uid as string);

    const { list } = event;
    const { items } = list;
    let data = items?.[id];

    if (!refresh && data) {
      !user.viewingAsGuest && _addEventReadBy(id, uid); //Prevent adding Readby in Guest mode.
    } else {
      data = await _getEvent(id, {
        ...options,
      });

      !user.viewingAsGuest && _addEventReadBy(id, uid);

      dispatch(removeUnreadEvent([id]));

      if (data && data.event && callback) {
        callback(data.event);
      }

      dispatch({
        type: EVENT_SET,
        data,
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get event recap
 * @param id
 * @param refresh
 */
export const getEventRecap = (
  id: string,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Active] getEventRecap", { id, refresh });

  try {
    const { event } = getState();
    const cachedData = event.recaps && event.recaps[id];
    if (!refresh && cachedData) {
      return;
    } else {
      const data = await _getEventRecap(id);
      dispatch({
        type: EVENT_RECAP_SET,
        data: { eventId: id, data },
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Update RSVP
 * @param data
 */
export const changeEventAttendee = (
  data: ChangeAttendee
): AppThunkAction<void | EventAttendee> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void | EventAttendee> => {
  try {
    console.debug("[Action] changeEventAttendee", data);

    const { user } = getState();
    //Prevent doing RSVP in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't RSVP in Guest Mode.");
    }

    const { eventId, userId } = data;
    const resp = await _getEvent(eventId, { showMyAttendee: true, userId });
    const { event, myAttendee } = resp;

    if (_.isEmpty(myAttendee) || !myAttendee) {
      if (event.disableAttendees) {
        throw new Error(
          i18n.t("Event:Container.Action.Message.No.More.Registration")
        );
      }
      return await _addEventAttendee(data);
    } else {
      //If already had attendee, do updating instead of adding
      if (myAttendee.isAddedAsGuest) {
        throw new Error(
          i18n.t("Event:Container.Action.Message.Added.As.Guest")
        );
      }
      return await _updateEventAttendee(myAttendee.id, data);
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Change event attendee for guest
 * @param data
 * @param eventId
 * @param userIds
 */
export const changeEventAttendeeForGuest = (
  data: EventAttendeeAdditionalData,
  eventId: string,
  userIds: string[]
): AppThunkAction<void | EventAttendee[]> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void | EventAttendee[]> => {
  try {
    console.debug("[Action] changeEventAttendeeForGuest", data);

    const { user } = getState();
    //Prevent doing RSVP in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't RSVP in Guest Mode.");
    }

    const promises: Promise<void | EventAttendee>[] = [],
      childPromises: Promise<EventAttendee>[] = [];
    _.forEach(userIds, userId => {
      promises.push(
        getEventAttendee(eventId, userId).then(attendee => {
          if (attendee) {
            childPromises.push(_updateEventAttendee(attendee.id, data));
          } else {
            childPromises.push(
              _addEventAttendee(Object.assign({}, data, { eventId, userId }))
            );
          }
        })
      );
    });

    await Promise.all(promises);
    return await Promise.all(childPromises);
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get published events
 * @param next
 * @param refresh
 */
export const getPublishedEvents = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getPublishedEvents");

  try {
    const { event } = getState();
    let { page } = event.publishedList;
    const { items } = event.publishedList;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    }

    if (next) {
      page++;
    }

    let data = await _manageEvents({
      per,
      page,
      isPublished: true,
    });

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: EVENT_PUBLISHED_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get draft events
 * @param next
 * @param refresh
 */
export const getDraftEvents = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getDraftEvents");

  try {
    const { event } = getState();
    let { page } = event.draftList;
    const { items } = event.draftList;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    }

    if (next) {
      page++;
    }

    let data = await _manageEvents({
      per,
      page,
      isPublished: false,
    });

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: EVENT_DRAFT_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Add/update event
 * @param data
 * @param photo
 * @param attachments
 * @param video
 */
export const upsertEvent = (
  data: EventSingle,
  photo: {} | null,
  attachments: {}[],
  video: {} | null
): AppThunkAction<CreateEventActionReturn> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<CreateEventActionReturn> => {
  console.debug("[Action] upsertEvent");

  try {
    const { setting, user } = getState();
    const { id } = data;
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: setting.config ? setting.config.adminURL : undefined,
    };

    /**
     * @TODO Probably that should be redeclared as separate action creator
     */
    const progressCallback: ProgressCallback = (
      name,
      fileProgress,
      transferred,
      total,
      cancel
    ) => {
      dispatch({
        type: NOTIFICATION_UPLOAD_SET,
        data: { name, transferred, total, fileProgress, cancel },
      });
    };

    const commonServiceArgs = [
      data,
      photo,
      attachments,
      video,
      {
        ...config,
        ...setting.config,
      } as SettingConfigData,
      progressCallback,
    ] as const;

    if (!id) {
      await _addEvent(...commonServiceArgs);
    } else {
      await _updateEvent(id, ...commonServiceArgs);
    }

    return { data: true };
  } catch (err) {
    throw err && err.message;
  }
};

/**
 * @FIXME please handle it like `upsertAnnouncement`
 * @param data
 * @param photo
 * @param attachments
 * @param video
 */
export function createEvent(
  data: Partial<EventSingle>,
  photo: string | null,
  attachments: { file: Attachment; name?: string; fileUrl?: string }[],
  video: VideoType | null
) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: () => RootState
  ): Promise<{ data: boolean }> =>
    new Promise<{ data: boolean }>((resolve, reject) => {
      const { setting, user } = getState();
      const { id } = data;

      const { sessionToken } = user.data as UserDataState; //user initially is null, here we expect value
      const config = {
        sessionToken,
        adminURL: (setting.config as SettingConfigData).adminURL,
      };
      const progressCallback = (
        name: string,
        fileProgress: Dict<{ total: number; transferred: number }>,
        transferred: number,
        total: number,
        cancel?: boolean
      ): void => {
        dispatch({
          type: NOTIFICATION_UPLOAD_SET,
          data: { name, transferred, total, fileProgress, cancel },
        });
      };

      if (_.isEmpty(id)) {
        _addEvent(
          data,
          photo,
          attachments,
          video,
          Object.assign({}, config, setting.config),
          progressCallback
        )
          .then(() => {
            resolve({ data: true });
          })
          .catch(error => reject(error));
      } else {
        _updateEvent(
          id as string,
          data,
          photo,
          attachments,
          video,
          Object.assign({}, config, setting.config),
          progressCallback
        )
          .then(() => {
            resolve({ data: true });
          })
          .catch(error => reject(error));
      }
    }).catch(err => {
      throw err && err.message;
    });
}

/**
 * Fetch event for editing
 * @param id
 */
export const manageEvent = (
  id: string,
  options: Dict<boolean>
): AppThunkAction<ManageEventActionReturn> => async (): Promise<
  ManageEventActionReturn
> => {
  console.debug("[Action] manageEvent");

  try {
    const data = (await _getEvent(id, {
      ...options,
      isEditing: true,
    })) as EventItem;

    return { data };
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Remove event
 * @param id
 */
export const removeEvent = (id: string): AppThunkAction => async (
  _dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] removeEvent");

  try {
    const { setting, user } = getState();
    const { sessionToken } = user.data as UserDataState;

    await _removeEvent(id, {
      sessionToken: sessionToken,
      adminURL: setting.config ? setting.config.adminURL : "",
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
