import React, { SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Alert, Button } from "reactstrap";

import { useTypedSelector } from "../../hooks";

import { InfiniteScroll } from "..";

import { NotificationCard, NotificationCardPlaceholder } from ".";
import { LayoutNotificationList } from "../../../containers/Notification";

type Props = LayoutNotificationList;

export const NotificationList: React.FC<Props> = ({
  data,
  error,
  hasMore,
  loading,
  sending,
  refreshing,
  setting,
  unread,

  reFetch,
  readAll,
}) => {
  const { t } = useTranslation("Notification");

  const viewingAsGuest = useTypedSelector(state => state.user.viewingAsGuest);

  if (viewingAsGuest) {
    return <Alert color="danger">{t("Index.Alert.Guest.Mode")}</Alert>;
  }

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const onReadAll = (e: SyntheticEvent): void => {
    e.preventDefault();

    readAll(toast.success);
  };

  const errorElement = !!error && <Alert color="danger">{error}</Alert>;

  const notificationItems =
    data?.map(item => (
      <NotificationCard key={item.id} item={item} setting={setting} />
    )) || null;

  return (
    <div>
      {errorElement}
      <div className="text-right">
        <Button
          className="mb-3"
          onClick={onReadAll}
          disabled={sending || unread === 0}>
          {sending
            ? t("Index.Button.Unread.Marking")
            : unread > 0
            ? `${t("Index.Button.Mark")} ${unread} ${t(
                "Index.Button.Read.Notifications"
              )}`
            : `${t("Index.Button.Unread.Notification")}`}
        </Button>
      </div>
      <InfiniteScroll
        hasMore={hasMore}
        isLoading={loading}
        isRefreshing={refreshing}
        placeholder={<NotificationCardPlaceholder />}
        onLoadMore={handleLoadMore}>
        {notificationItems}
      </InfiniteScroll>
    </div>
  );
};

export default NotificationList;
