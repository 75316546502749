import React, { FC } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Member } from "sendbird";
import { displayDate, isToday } from "../../../../lib/utils";

import { Avatar, StickyNavbar } from "../../UI";
import Uploading from "../../../../containers/Uploading";
import { Uploading as UploadingComponent } from "../../Uploading";
type ChatConversationChatHeaderProps = {
  link?: string;
  name?: string;
  profileUrl?: string;
  user?: Member;
};

export const ChatConversationChatHeader: FC<ChatConversationChatHeaderProps> = ({
  link,
  name,
  profileUrl,
  user,
}) => {
  const history = useHistory();

  const lastSeenAt = user?.lastSeenAt
    ? displayDate(
        user?.lastSeenAt,
        isToday(user?.lastSeenAt) ? "hh:mm A" : "MMMM DD"
      )
    : "";

  return (
    <StickyNavbar className="bg-white chat-header-sticky-navbar">
      <div
        id="chatConversationChatHeader"
        className="sendbird-chat-header border border-bottom border-light">
        <div className="sendbird-chat-header__left">
          <div className="sendbird-chat-header__avatar sendbird-avatar">
            <Avatar round={true} src={profileUrl} name={name} />
          </div>
          <div className="h6 pt-2 pl-4 ">{name ?? "Conversation"}</div>
          {user && user.connectionStatus === "online" ? (
            <span className="circle text-success pl-3" />
          ) : (
            !!lastSeenAt && (
              <span className="text-muted text-small pl-3">
                {`Last seen ${lastSeenAt}`}
              </span>
            )
          )}
          {link && (
            <Button
              color="link"
              className="align-content-end"
              onClick={(): void => history.push(link)}>
              <i className="icon-size-fullscreen" />
            </Button>
          )}
        </div>
      </div>
      <Uploading
        Layout={UploadingComponent}
        className="chat-uploading-indicator"
      />
    </StickyNavbar>
  );
};
