import React from "react";

import { Row } from "reactstrap";

import { InfiniteScrollComponentType } from "../..";

export const PostImagesInfiniteScrollComponent: InfiniteScrollComponentType = ({
  children,
  sentinel,
}) => (
  <Row className="mx-n1" noGutters={true}>
    {children}
    {sentinel}
  </Row>
);
