import {
  CHAT_DETAIL_ACTIVE_SET,
  CHAT_DETAIL_SET,
  CHAT_FAVORITE_ALL_SET,
  CHAT_INPUT_FIELD_SAVE,
  CHAT_RESET,
  CHAT_ROOM_ALL_SET,
  CHAT_ROOM_SET,
  CHAT_SETTING_SET,
} from "./constants";
import Store from "./store";
import { ChatAction, ChatState } from "./types";

export const initialState = Store;

export default function reducer(
  state: ChatState = initialState,
  action: ChatAction
): ChatState {
  switch (action.type) {
    case CHAT_RESET:
      return initialState;

    case CHAT_ROOM_ALL_SET:
      if (action.data) {
        const { items, type } = action.data;

        return {
          ...state,
          error: null,
          items,
          type,
        };
      }

      return state;

    case CHAT_ROOM_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          items: {
            ...state.items,
            [action.data.id]: action.data,
          },
        };
      }

      return state;

    case CHAT_DETAIL_SET:
      if (action.data) {
        const { id, data, hasMore } = action.data;

        return {
          ...state,
          error: null,
          details: {
            ...state.details,
            [id]: {
              data,
              hasMore,
            },
          },
        };
      }

      return state;

    case CHAT_DETAIL_ACTIVE_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          active: action.data,
        };
      }

      return state;

    case CHAT_SETTING_SET:
      if (action.data) {
        const setting = action.data;

        return {
          ...state,
          error: null,
          setting,
        };
      }

      return state;

    case CHAT_FAVORITE_ALL_SET:
      if (action.data) {
        const favorites = action.data;
        return {
          ...state,
          error: null,
          favorites,
        };
      }

      return state;

    case CHAT_INPUT_FIELD_SAVE:
      if (action.data) {
        const { id, text, linksMentions, mentions } = action.data;

        return {
          ...state,
          error: null,
          inputField: {
            ...state.inputField,
            [id]: {
              text,
              linksMentions,
              mentions,
            },
          },
        };
      }

      return state;

    default:
      return state;
  }
}
