export const EVENT_RESET = "EVENT_RESET" as const;
export const EVENT_ALL_SET = "EVENT_ALL_SET" as const;
export const EVENT_SEARCH_SET = "EVENT_SEARCH_SET" as const;
export const EVENT_SEARCH_HISTORY_SET = "EVENT_SEARCH_HISTORY_SET" as const;
export const EVENT_SET = "EVENT_SET" as const;
export const EVENT_RECAP_SET = "EVENT_RECAP_SET" as const;
export const EVENT_COMMENT_SET = "EVENT_COMMENT_SET" as const;
export const EVENT_REACTION_SET = "EVENT_REACTION_SET" as const;
export const EVENT_PUBLISHED_SET = "EVENT_PUBLISHED_SET" as const;
export const EVENT_DRAFT_SET = "EVENT_DRAFT_SET" as const;
