import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";

import { ChatMutatedChannel } from "../../../../containers/Chat/chatTypes";
import { EventSingle } from "../../../../types";

import { Avatar } from "../../UI";

import { EventBar } from "../../Event/EventBar";

type Props = {
  channel: ChatMutatedChannel;
  event?: { data: EventSingle };
  groupId?: string;
  isAdminOrEditor: boolean;
  memberIDs?: string[];

  leave: () => void;
  openMembersModal: (value: boolean) => () => void;
  openNotificationsModal: () => void;
};

export const SettingsList: FC<Props> = ({
  channel,
  event,
  groupId,
  isAdminOrEditor,
  memberIDs,

  leave,
  openMembersModal,
  openNotificationsModal,
}) => {
  const [isMembersListOpen, setMembersList] = useState(false);
  const toggleMembersList = (): void => {
    setMembersList(prevState => !prevState);
  };
  const history = useHistory();

  const getUserId = (sendBirdUserId: string): string | undefined => {
    return sendBirdUserId.split("_").pop();
  };

  const eventHref = "/event-detail/" + event?.data.id;

  const handleGroupButtonClick = (): void =>
    history.push("/group-feed/" + groupId);

  const handleGroupPhotosButtonClick = (): void =>
    history.push("/group-photos/" + groupId);

  const handleGroupFilesButtonClick = (): void =>
    history.push("/group-files/" + groupId);

  const isOpenChannel = channel.channelType === "open";

  const groupButtons = channel.isGroupChat && (
    <>
      <div
        onClick={handleGroupButtonClick}
        role="button"
        className="sendbird-channel-settings__panel-item">
        <div className="sendbird-channel-settings__panel-icon-left sendbird-channel-settings__panel-icon__leave sendbird-icon ">
          <i className="icon-home text-primary" />
        </div>
        <div className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
          Group
        </div>
      </div>
      <div
        onClick={handleGroupPhotosButtonClick}
        role="button"
        className="sendbird-channel-settings__panel-item">
        <div className="sendbird-channel-settings__panel-icon-left sendbird-channel-settings__panel-icon__leave sendbird-icon ">
          <i className="icon-picture text-primary" />
        </div>
        <div className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
          Photos
        </div>
      </div>
      <div
        onClick={handleGroupFilesButtonClick}
        role="button"
        className="sendbird-channel-settings__panel-item">
        <div className="sendbird-channel-settings__panel-icon-left sendbird-channel-settings__panel-icon__leave sendbird-icon ">
          <i className="icon-paper-clip text-primary" />
        </div>
        <div className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
          Files
        </div>
      </div>
    </>
  );

  const eventInfoElement = channel.isEventChat && (
    <div className="chat-event-details-info pl-2">
      {event && <EventBar className="mt-2 ml-2 mb-2" event={event.data} />}
      <a className="text-primary text-small ml-2 " href={eventHref}>
        See more
      </a>
    </div>
  );

  const memberListElement = isMembersListOpen && (
    <div className="sendbird-members-accordion">
      <div className="sendbird-members-accordion__list">
        {channel.members?.map(value => (
          <div key={value.userId} className="row my-2">
            <div className="col-2">
              <Avatar
                round={true}
                src={value.profileUrl}
                id={getUserId(value.userId)}
              />
            </div>
            <div className="col ml-4 mt-2 sendbird-label sendbird-label--subtitle-2">
              {value.nickname}
            </div>
          </div>
        ))}
      </div>
      {(!channel.isSystemChat || (channel.isSystemChat && isAdminOrEditor)) && (
        <div className="sendbird-members-accordion__footer row">
          <button
            onClick={openMembersModal(true)}
            type="button"
            className="mx-1 col btn btn-outline-primary">
            Invite users
          </button>
          <button
            onClick={openMembersModal(false)}
            type="button"
            className="mx-1 col btn btn-outline-danger">
            Remove users
          </button>
        </div>
      )}
    </div>
  );

  const membersElement = !isOpenChannel && (
    <>
      <div
        role="switch"
        aria-checked="false"
        onClick={toggleMembersList}
        className="sendbird-channel-settings__panel-item">
        <div
          role="button"
          className="sendbird-channel-settings__panel-icon-left sendbird-icon sendbird-color--primary">
          <i className="icon-people text-primary" />
        </div>
        <div className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
          Members ({memberIDs && memberIDs.length})
        </div>
        <div
          role="button"
          className="sendbird-channel-settings__panel-icon-right sendbird-channel-settings__panel-icon--chevron  sendbird-icon ">
          <i
            className={
              isMembersListOpen ? "icon-arrow-down" : "icon-arrow-right"
            }
          />
        </div>
      </div>

      {memberListElement}
    </>
  );

  return (
    <>
      {eventInfoElement}
      {groupButtons}
      {membersElement}
      <div
        onClick={openNotificationsModal}
        role="button"
        className="sendbird-channel-settings__panel-item">
        <div className="sendbird-channel-settings__panel-icon-left sendbird-channel-settings__panel-icon__leave sendbird-icon ">
          <i className="icon-bell text-secondary" />
        </div>
        <div className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
          Notifications
        </div>
      </div>

      <div
        onClick={leave}
        role="button"
        className="sendbird-channel-settings__panel-item">
        <div className="sendbird-channel-settings__panel-icon-left sendbird-channel-settings__panel-icon__leave sendbird-icon ">
          <i className="icon-logout text-danger" />
        </div>
        <div className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
          Leave channel
        </div>
      </div>
    </>
  );
};
