import { listenGoBackEvent } from "../../lib/utils/checkGoBack";

window.Parse = require("parse");
window.storage = require("localforage");
window.reduxStorage = require("localforage");
window.track = () => {};
window.webPlatform = true;

export * from "./isWebPlatform";

export * from "./Media";
export * from "./mixpanel";

listenGoBackEvent();

window.Upload = require("./Upload/index.ts");

export function addContact({
  id,
  company,
  email,
  lastName,
  firstName,
  title,
  note,
  phone,
  photo,
  birthday,
  homeLocation,
  workLocation,
  urls,
}) {}

export function init() {
  if (process.env.NODE_ENV === "production") {
    console.debug = function() {};
  }
  console.debug("Initialized services");
}
