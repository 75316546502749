import React, { SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Media, Row } from "reactstrap";
import { reduce } from "underscore";

import { toCapitalizeCase, isPastEvent } from "../../../lib/utils";
import { EventItem } from "../../../types";
import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

import { EventBar, EventFee, EventCardAttendees } from ".";

export type EventCardProps = {
  href?: string;
  item: EventItem;

  onClick: () => void;
};

export const EventCard: React.FC<EventCardProps> = ({
  href,
  item: { attendees, event, myAttendee },

  onClick,
}) => {
  const { title, thumbUrl, isPaid, items, endAt, rsvpEnabled, id } = event;

  const { t } = useTranslation("Event");

  const history = useHistory();

  const {
    card,
    image,
    event: eventStyle,
    text: { cardTitle, listText },
  } = useTheme();

  const handleClick = (e: SyntheticEvent): void => {
    e.preventDefault();

    onClick();
  };

  const handleTotalAttendeesClick = (): void => {
    href && history.push(href, { activeTab: "Attendees" });
  };

  const eventCardStyles = {
    card: { ...card, padding: 0 },
    cardBody: { backgroundColor: "transparent" },
    rsvpText: {
      ...eventStyle.rsvp.text,
      ...textThemingWorkaround,
      fontSize: 16,
    },
    titleHeading: { ...cardTitle, ...textThemingWorkaround },
    image: {
      ...image,
      width: "135px",
      height: "135px",
      objectFit: "cover",
      marginLeft: 0,
      marginRight: 0,
      boxShadow: `${image.shadowOffset.width}px ${
        image.shadowOffset.height
      }px ${image.shadowRadius * 3}px   rgb(0, 0, 0, 0.5)`,
    },
    listText: {
      ...listText,
      ...textThemingWorkaround,
      paddingLeft: 0,
    },
  } as const;

  let rsvpButtonStyle: React.CSSProperties = {};
  let rsvpText = t("Card.Button.Rsvp");

  if (myAttendee?.response === "yes") {
    rsvpButtonStyle = eventStyle.yes;
    rsvpText = t("Card.Button.Attending");
  } else if (myAttendee?.response === "no") {
    rsvpButtonStyle = eventStyle.no;
    rsvpText = t("Card.Button.Not.Attending");
  } else if (myAttendee?.isWaiting) {
    rsvpButtonStyle = eventStyle.wait;
    rsvpText = t("Card.Button.Joined.WaitList");
  }

  const rsvpButtonStyles = {
    ...eventStyle.rsvp,
    ...rsvpButtonStyle,
  };
  const rsvpTextStyle = { ...eventCardStyles.rsvpText, ...rsvpButtonStyle };

  const rsvpButtonText = (
    <span style={rsvpTextStyle} className="rsvp-button-text">
      {rsvpText}
    </span>
  );

  /** @FIXME This logic should come from container */
  const isPast = isPastEvent(endAt);

  /** @FIXME This logic should come from container */
  const totalFees = !isPaid
    ? 0
    : reduce(
        items,
        (memo, value) =>
          value.required
            ? memo +
              ((value.options && value.options[0].price) || value.price || 0)
            : memo,
        0
      );

  const rsvpButton = !isPast && !!rsvpEnabled && (
    <Button
      id={`eventListRsvpButton${id}`}
      color="primary"
      className="mt-3 rsvp-button"
      style={rsvpButtonStyles}
      tag="a"
      href={href}
      onClick={handleClick}>
      {rsvpButtonText}
    </Button>
  );

  const titleElement = (
    <div style={eventCardStyles.titleHeading}>
      <h5 id={`eventListTitle${id}`}>
        <a href={href} onClick={handleClick}>
          {toCapitalizeCase(title)}
        </a>
      </h5>
    </div>
  );

  return (
    <Card
      id={`eventListCard${id}`}
      className="my-2 event-card"
      style={eventCardStyles.card}>
      <CardBody style={eventCardStyles.cardBody}>
        <Media>
          <Media
            left
            className="d-flex align-items-center mr-3"
            style={{
              width: "135px",
              maxHeight: "135px",
            }}
            tag="a"
            href={href}
            onClick={handleClick}>
            <Media
              id={`eventListImage${id}`}
              style={eventCardStyles.image}
              src={thumbUrl}
              alt="Generic placeholder image"
            />
          </Media>
          <Media body>
            {titleElement}
            <EventBar className="mt-2 ml-2" event={event} />
            <EventFee
              className="mt-3 ml-2 event-fee"
              fee={totalFees}
              style={eventCardStyles.listText}
            />
            <Row>
              <Col className="ml-2 pr-0 mt-3 event-card-attendees">
                <EventCardAttendees
                  attendees={attendees}
                  onTotalAttendeesClick={handleTotalAttendeesClick}
                />
              </Col>
              <Col className="d-flex justify-content-end ml-0 rsvp-button-col">
                {rsvpButton}
              </Col>
            </Row>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};
