import { ProgressCallback, SettingConfigData } from "../../../types";

import { addVideoLink } from ".";
import { uploadFileCloudinary } from "..";
import { Video } from "../../../types/VideoLibrary/Video";
import { VideoUpload } from "../../../types/VideoLibrary/VideoUpload";

/**
 * @TODO This function probably needs wider refactor to be more reusable
 * It's origin is addAnnouncement/updateAnnouncement service.
 * Logic not really related with announcements.
 */

export type ServiceApiPrepareVideoFileOptions = {
  video?: VideoUpload | null;
  config?: SettingConfigData;
  progressCallback?: ProgressCallback;
};

/**
 * @TODO Strongly type return value
 * Function returned value is not typed by itself until there is lack
 * of services/api addVideoLink and uploadFileCloudinary typings
 *
 * @TODO Arguments need better typing
 */
export const prepareVideoFile = async (
  options: ServiceApiPrepareVideoFileOptions
): Promise<{ data?: {} }> => {
  console.debug("[Service] prepareVideoFile", options.video);

  if (!options.video) {
    return {};
  }

  if (options.video.isLink) {
    //for video from youtube, add it to Video library
    return await addVideoLink(options.video as Video);
  }

  if (options.video.uploaded) {
    //for video already uploaded
    return { data: options.video };
  }

  return await uploadFileCloudinary(
    options.video,
    options.config,
    options.progressCallback
  );
};
