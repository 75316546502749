import { runQuery } from "../../http";
const Parse = window.Parse;

export const getChatNotificationSetting = async (
  channels: string[],
  userId: string
): Promise<any> => {
  return await runQuery("ChatNotificationSetting", (query: any) => {
    query.containedIn("channel", channels);
    query.equalTo("user", new Parse.User({ id: userId }));
    return query.find();
  });
};
