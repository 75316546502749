import React from "react";

import EventListContainer from "../../../containers/Event";

import { EventList } from "../../components/Event";

export type RouteEventListProps = {};

export const RouteEventList: React.FC<RouteEventListProps> = props => (
  <EventListContainer {...props} Layout={EventList} />
);
