import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { Group, Dict, GroupTree } from "../../../types";

import { runFunction } from "../../http";

/* options */
// filter: "all", "pending", "joined", "joinable"
// groupTypeId: "all", ...
// format: "tree", "flat"
export type GetGroupsServiceOptions = {
  filter: "all" | "pending" | "joined" | "joinable";
  format: "flat" | "tree";
  groupTypeId: "all" | string;
  type?: "listGroups";
};

/**
 * @TODO Please review carefully
 * Check argument types.
 */
export const getGroups: {
  (
    options: GetGroupsServiceOptions,
    convertion: {
      convertToDict: true;
    }
  ): Promise<Dict<Group>>;
  (
    options: GetGroupsServiceOptions,
    convertion?: {
      convertToDict?: false;
    }
  ): Promise<Group[]>;
} = async (
  options: GetGroupsServiceOptions,
  convertion?: {
    convertToDict?: boolean;
  }
) => {
  const response: (Group | GroupTree)[] = await runFunction(
    "listGroups",
    options,
    {
      requireUser: true,
    }
  );

  const converted = convertObject(response);

  /**
   * A lot of strange type workarounds done here.
   * Finally I recommend to split flat and tree requests
   * to another services.
   */
  return convertion?.convertToDict
    ? _.object(
        _.map(
          converted,
          // response,
          (value: Group | GroupTree) => [
            (value as GroupTree).group
              ? (value as GroupTree).group.id
              : (value as Group).id,
            // convertObject(value),
            value,
          ]
        )
      )
    : converted;
};
