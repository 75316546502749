import { runFunction } from "../../http";
import _ from "underscore";
import { convertObject } from "../../../lib/utils";
import { PostItem } from "../../../types/Post/PostItem";
import { parseMentionsInPostItem } from "../ParseMentionsHelper";

export const getPost = async (
  postId: string,
  options: { uid?: string; showComments?: boolean }
): Promise<PostItem | undefined> => {
  const option = Object.assign(options, { postId });
  const parseResponse = await runFunction("groupPostDetail", option, {
    requireUser: true,
  });

  if (!_.isEmpty(parseResponse)) {
    return Object.assign(
      {},
      parseMentionsInPostItem(convertObject(parseResponse)),
      {
        id: postId,
      }
    );
  }
};
