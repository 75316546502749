/* eslint-disable @typescript-eslint/camelcase */
import {
  getCurrentUser as _getCurrentUser,
  logIn as _logIn,
  logInAsGuest as _logInAsGuest,
  logOut as _logOut,
  updateProfile as _updateProfile,
  updateUser as _updateUser,
  getNotificationSettings as _getNotificationSettings,
  saveNotificationSettings as _saveNotificationSettings,
  saveInstallation as _saveInstallation,
  sendActivity as _sendActivity,
  updateUserAgreement as _updateUserAgreement,
  removeInstallation,
  register as _register,
  resetPassCode as _resetPassCode,
  updatePassword as _updatePassword,
  forgotPassword as _forgotPassword,
  getCurrentSession as _getCurrentSession,
  viewAsUser,
} from "../../services/api/index";

import { convertObject, logException, displayDate } from "../../lib/utils";
import { resetSetting } from "../Setting/action";
import _ from "underscore";
import { disConnectChat } from "../Chat/action";
import {
  AppThunkAction,
  GetState,
  MediaInputFileProcessed,
  UserCompanyHistoryEvent,
} from "../../types";
import {
  UserActivityData,
  UserDeviceInfo,
  UserProfileData,
  UserRegisterData,
} from "../../types";
import {
  USER_INDUSTRY_SET,
  USER_NOTIFICATION_SETTINGS_SET,
  USER_SET,
  USER_SESSION_SET,
} from "./constants";
import { NotificationsSet } from "./index";
import { AppThunkDispatch } from "../../types";
import { SettingSettingData } from "../../types/Setting/SettingSettingData";
import { CHAT_RESET } from "../Chat/constants";
import { ANNOUNCEMENT_RESET } from "../Announcement/constants";
import { EVENT_RESET } from "../Event/constants";
import { MEMBER_RESET } from "../Member/constants";
import { NOTIFICATION_RESET } from "../Notification/constants";
import { PAYMENT_RESET } from "../Payment/constants";
import { RESOURCE_RESET } from "../Resource/constants";
import { POST_RESET } from "../Post/constants";
import { UserIndustrySetAction, UserSetAction } from "./types";
import { UserDataState } from "../../types/User/UserDataState";
import { UserNotificationSettingsSetAction } from "./types";
import { DEV } from "../../constants/config";
import { GROUP_RESET } from "../Group/constants";
import { VIDEO_RESET } from "../VideoLibrary/constants";
import { MEDIA_RESET } from "../Media/constants";
import { TAG_RESET } from "../Tag/constants";
import { COMMENT_RESET } from "../Comment/constants";
import { MAP_RESET } from "../Map/constants";
import { isWebPlatform } from "../../web/services/isWebPlatform";
import { MediaFileType } from "../../types";
import { SUBCRIPTION_RESET } from "../Subscription/constants";

const correctSavingUserProfile = (
  profile: UserProfileData
): UserProfileData => {
  // Fix Company History
  if (!_.isEmpty(profile.sections["Professional Info"]["Company History"])) {
    // Convert date to string
    _.forEach(
      profile.sections["Professional Info"][
        "Company History"
      ] as UserCompanyHistoryEvent[],
      item => {
        item["fromdate"] = displayDate(item["fromdate"], "MM/DD/YYYY");
        item["todate"] = displayDate(item["todate"], "MM/DD/YYYY");
      }
    );

    // Convert Company History to JSON String
    profile.sections["Professional Info"]["Company History"] = JSON.stringify(
      profile.sections["Professional Info"]["Company History"]
    );
  }

  //Convert Birthday to string
  profile.sections["Personal Info"]["Birthday"] = displayDate(
    profile.sections["Personal Info"]["Birthday"],
    "MM/DD/YYYY"
  );

  //Convert Spouse Birthday to string
  if (profile.sections["Personal Info"]["Spouse"]) {
    profile.sections["Personal Info"]["Spouse"]["Birthday"] = displayDate(
      profile.sections["Personal Info"]["Spouse"]["Birthday"],
      "MM/DD/YYYY"
    );
  }

  //Convert Children Birthday to string
  if (!_.isEmpty(profile.sections["Personal Info"]["Children"])) {
    _.forEach(profile.sections["Personal Info"]["Children"], item => {
      item["Birthday"] = displayDate(item["Birthday"], "MM/DD/YYYY");
    });
  }

  return profile;
};

export function getCurrentUser(noStore?: boolean) {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<UserSetAction> =>
    new Promise<UserSetAction>((resolve, reject) => {
      const { user } = getState();
      _getCurrentUser()
        .then(async values => {
          if (values) {
            const result: UserSetAction = {
              type: USER_SET,
              //@FIXME unify UserDataState and Member types,
              //temporary solution
              //eslint-disable-next-line
              //@ts-ignore
              data: values as UserDataState,
            };
            if (!noStore) {
              dispatch(result);
            }
            return resolve(result);
          } else if (user.data) {
            dispatch(resetSetting());
          }
          return resolve();
        })
        .catch((error: string) => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export const clearCachedData = () => (dispatch: AppThunkDispatch): void => {
  dispatch({ type: MEMBER_RESET });
  dispatch({ type: POST_RESET });
  dispatch({ type: GROUP_RESET });
  dispatch({ type: ANNOUNCEMENT_RESET });
  dispatch({ type: EVENT_RESET });
  dispatch({ type: COMMENT_RESET });
  dispatch({ type: PAYMENT_RESET });
  dispatch({ type: NOTIFICATION_RESET });
  dispatch({ type: RESOURCE_RESET });
  dispatch({ type: VIDEO_RESET });
  dispatch({ type: CHAT_RESET });
  dispatch({ type: MEDIA_RESET });
  dispatch({ type: TAG_RESET });
  dispatch({ type: MAP_RESET });
  dispatch({ type: SUBCRIPTION_RESET });
};

export const logIn = (
  username: string,
  password: string
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { setting } = getState();
    let regrex = /[-() ]/gi;
    if (setting.setting?.enable_signin_by_email) {
      regrex = /[() ]/gi;
    }
    const validUsername = (username || "").toLowerCase().replace(regrex, "");
    await _logIn(validUsername, password);
    dispatch(clearCachedData());
    dispatch(getCurrentUser());
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const logInAsGuest = (userId: string): AppThunkAction<void> => async (
  dispatch: AppThunkDispatch,
  getState
): Promise<void> => {
  try {
    const currentSessionToken = await _getCurrentSession();
    const sessionToken = await viewAsUser(userId);
    await _logInAsGuest(sessionToken);
    dispatch(clearCachedData());
    dispatch(getCurrentUser());
    dispatch({ type: USER_SESSION_SET, data: currentSessionToken });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const exitLoggingInAsGuest = (): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { user } = getState();
    const sessionToken = user.originalSessionToken;
    await dispatch(logOut());
    if (sessionToken) {
      await _logInAsGuest(sessionToken);
      dispatch(clearCachedData());
      dispatch(getCurrentUser());
      dispatch({ type: USER_SESSION_SET, data: null });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const register = (
  data: UserRegisterData,
  avatar?: string | { file: MediaFileType }
): AppThunkAction<{ data: string }> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<{ data: string }> => {
  try {
    const { setting } = getState();
    const resp = await _register(data, avatar, setting.config);
    return { data: resp };
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const logOut = (): AppThunkAction => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] logOut");

  try {
    dispatch(disConnectChat());

    dispatch(clearCachedData());

    //because web or simulator doesn't support installation so don't need to remove it
    if (!isWebPlatform() && !DEV) {
      try {
        await removeInstallation();
      } catch (err) {
        console.debug(err);
      }
    }

    try {
      await _logOut();
    } catch (err) {
      console.debug(err);
    }

    dispatch(resetSetting());
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export function saveNotificationSettings(
  setting: { [key in NotificationsSet]: number }
) {
  return (
    dispatch: AppThunkDispatch
  ): Promise<UserNotificationSettingsSetAction> =>
    new Promise<UserNotificationSettingsSetAction>((resolve, reject) => {
      _saveNotificationSettings(setting)
        .then((resp: Parse.Object) => {
          const data = convertObject(resp);
          return resolve(
            dispatch({
              type: USER_NOTIFICATION_SETTINGS_SET,
              data: data,
            })
          );
        })
        .catch((error: string) => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export const getNotificationSettings = (
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { user } = getState();
    const cachedData = user.notificationSettings;

    if (!_.isEmpty(cachedData) && !refresh) {
      return;
    }
    const resp = await _getNotificationSettings();
    const data = convertObject(resp);
    dispatch({
      type: USER_NOTIFICATION_SETTINGS_SET,
      data: data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

const changedPhoneOrEmail = (
  oldValue: string | null,
  newValue: string | null
): boolean => {
  if (_.isEmpty(oldValue)) {
    return !_.isEmpty(newValue);
  }
  if (_.isEmpty(newValue)) {
    return !_.isEmpty(oldValue);
  }
  return (
    (oldValue as string)
      .trim()
      .split(" ")
      .join("") !==
    (newValue as string)
      .trim()
      .split(" ")
      .join("")
  );
};

function updateUser(data: UserProfileData) {
  return (dispatch: AppThunkDispatch, getState: GetState): Promise<boolean> =>
    new Promise<boolean>(resolve => {
      console.debug("[Action] updateUser", data);
      const { setting, user } = getState();
      const { enable_signin_by_email } = setting.setting as SettingSettingData;
      const { sections } = data;
      const newEmail = sections["Personal Info"].Email;
      const newPhone = sections["Personal Info"].Phone;
      const changedEmail = changedPhoneOrEmail(
        user.data && user.data.email,
        newEmail
      );
      const changedPhone = changedPhoneOrEmail(
        user.data && user.data.phoneNumber,
        newPhone
      );
      if (changedEmail || changedPhone) {
        const newUsername = enable_signin_by_email ? newEmail : newPhone;
        const changeUsername =
          (enable_signin_by_email && changedEmail) ||
          (!enable_signin_by_email && changedPhone);
        return resolve(
          _updateUser({
            username: newUsername,
            changeUsername: changeUsername,
            phoneNumber: newPhone,
            email: newEmail,
          })
        );
      }
      return resolve(true);
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export const updateProfile = (
  data: UserProfileData,
  image: MediaInputFileProcessed | null
): AppThunkAction<boolean> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<boolean> => {
  try {
    console.debug("[Action] updateProfile", data);

    const { user, setting } = getState();
    //Prevent updating profile in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't update profile in Guest Mode.");
    }

    data = correctSavingUserProfile(data);

    await _updateProfile(data, image, setting.config);
    await dispatch(updateUser(data));
    dispatch({ type: MEMBER_RESET });
    dispatch(getCurrentUser());
    return true;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const sendActivity = (data?: UserActivityData): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] sendActivity");

  try {
    const { user } = getState();
    if (!user.data || user.viewingAsGuest) {
      return;
    }

    const resp = await _sendActivity(data);
    if (resp === false) {
      dispatch(logOut());
    }
  } catch (error) {
    dispatch(logOut());
    logException(error);
    throw error && error.message;
  }
};

export function saveInstallation(
  token: string,
  data: UserDeviceInfo
): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    _saveInstallation(token, data)
      .then(() => {
        return resolve(true);
      })
      .catch((error: string) => reject(error));
  }).catch(err => {
    logException(err);
    throw err && err.message;
  });
}

export function resetPassCode(phoneNumber: string) {
  return (): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      _resetPassCode(phoneNumber)
        .then(resp => {
          return resolve(resp);
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export const updatePassword = (
  password: string
): AppThunkAction<boolean> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<boolean> => {
  console.debug("[Action] updatePassword");

  try {
    const { user } = getState();
    if (user.viewingAsGuest) {
      throw new Error("Can't update password in Guest Mode.");
    }

    await _updatePassword(password);
    return true;
  } catch (error) {
    logException(error);
    throw error && error.message;
  }
};

export function forgotPassword(email: string) {
  return (): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      _forgotPassword(email)
        .then(() => {
          return resolve(true);
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getIndustry() {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<UserIndustrySetAction> =>
    new Promise<UserIndustrySetAction>(resolve => {
      const { setting } = getState();

      let data;
      if (!_.isEmpty(setting.setting?.industries)) {
        const rowData = setting.setting?.industries as string[];
        data = _.map(rowData, (item: string) => ({
          id: item,
          name: item,
        }));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const rowData = require("./industry.json");
        data = _.map(rowData, (item: { Industry: string }) => ({
          id: item.Industry,
          name: item.Industry,
        }));
      }

      return resolve(
        dispatch({
          type: USER_INDUSTRY_SET,
          data: data,
        })
      );
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function updateUserAgreement() {
  return (): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      _updateUserAgreement()
        .then(() => {
          return resolve();
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}
