import {
  ChatSetting,
  ChatTypeData,
  ChatInputField,
  ChatDetailsData,
  ChatListDetailItem,
} from "../../types/Chat";
import {
  RootState,
  Member,
  Dict,
  SettingState,
  UserDataState,
} from "../../types";
import { createSelector } from "reselect";
import { take, isUndefined, sortBy, isEmpty, forEach } from "underscore";
import { ChatMutatedChannel } from "./chatTypes";
import { getDictDefinedValues } from "../../lib/utils/store";

const sortChat = (channels: ChatMutatedChannel[]): ChatMutatedChannel[] => {
  const unReadChannels = channels.filter(
    ({ unreadMessageCount }) => (unreadMessageCount as number) > 0
  );
  let readChannels = channels.filter(
    ({ unreadMessageCount }) => !((unreadMessageCount as number) > 0)
  );
  readChannels = sortBy(readChannels, ({ userLastRead, myLastRead }) =>
    !isUndefined(userLastRead)
      ? -(userLastRead as number)
      : -(myLastRead as number)
  );
  return unReadChannels.concat(readChannels);
};

const getChatNotificationSetting = (
  state: RootState
): Record<string, ChatSetting> => state.chat.setting;

const getChatListType = (
  state: RootState,
  simpleMode?: boolean
): "single" | "type" => {
  return state.setting.config?.blenderboxImprovements ||
    simpleMode ||
    state.setting.setting?.chat_room_type === "single"
    ? "single"
    : "type";
};

const getChatFavorites = (state: RootState): Dict<Member> =>
  state.chat.favorites;

const getChatTypeData = (state: RootState): Partial<ChatTypeData> | {} =>
  state.chat.type;

const getChatListData = (state: RootState): Dict<ChatMutatedChannel> | null =>
  state.chat.items;

const getChatInputField = (
  state: RootState,
  channelUrl: string
): Dict<ChatInputField> | {} => state.chat.inputField?.[channelUrl] || {};

const getChatDetailSetting = (state: RootState): SettingState => state.setting;

const getChatDetailActive = (
  state: RootState,
  channelUrl: string
): ChatDetailsData | {} =>
  (state.chat.active as ChatDetailsData).id === channelUrl
    ? state.chat.active
    : {};

const getChatDetailData = (
  state: RootState,
  channelUrl: string
): ChatListDetailItem => state.chat.details?.[channelUrl] || {};

// reselect function

export const getChatNotificationSettingState = createSelector(
  [getChatNotificationSetting],
  items => items
);

export const getChatListDataState = createSelector(
  [getChatListType, getChatListData, getChatTypeData],
  (type, chatItemsData, chatTypeData) => {
    let data: any = [];
    if (type === "type") {
      data = chatTypeData;
      if (!isEmpty(data)) {
        forEach(
          data as ChatTypeData,
          (chatType: ChatMutatedChannel[], key: string) => {
            data[key] = sortChat(chatType);
          }
        );
      }
    } else {
      data = chatItemsData && Object.values(chatItemsData); //this is completely different object
      if (data) {
        data = sortChat(data);
      }
    }
    return data;
  }
);

export const getChatListTypeState = createSelector(
  [getChatListType],
  type => type
);

export const getChatFavoritesState = createSelector(
  [getChatFavorites],
  items => {
    let favorites = Object.values(items || {});
    if (favorites.length > 6) {
      favorites = take(favorites, 6);
    } else if (favorites.length < 6) {
      favorites = favorites.concat([...Array(6 - favorites.length)]);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    return favorites as UserDataState[];
  }
);

export const getChatInputFieldState = createSelector(
  [getChatInputField],
  data => data
);

export const getChatDetailSettingState = createSelector(
  [getChatDetailSetting],
  setting => ({ ClientHostName: setting.localConfig?.client_url || "" })
);

export const getChatDetailActiveState = createSelector(
  [getChatDetailActive],
  data => data
);

export const getChatDetailDataState = createSelector(
  [getChatDetailData],
  item => getDictDefinedValues(item?.data) || []
);

export const getChatDetailDataHasMoreState = createSelector(
  [getChatDetailData],
  item => item.hasMore
);
