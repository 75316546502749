import { runFunction } from "../../http";
import { CommentType } from "../../../types/Comment";
import { Group } from "../../../types/Group";
import { commentsTypes } from "../../../constants/Comment";
import { ReactionType } from "../../../types";

export const doReaction = async (options: {
  id: string;
  type: CommentType;
  reaction: ReactionType;
  userId?: string; //@FIXME this might be a mistake, we are not sending this filed in action creator
}): Promise<{ group: Parse.Object<Group> } | undefined> => {
  console.debug("[Service] doReaction", options);

  const { id, type, reaction, userId } = options;
  const option = {
    eventId: id,
    announcementId: id,
    postId: id,
    eventPhotoId: id,
    type: reaction,
    userId,
  };
  let parseResponse;
  if (reaction === "unlike") {
    parseResponse = await runFunction(
      commentsTypes[type].functionUnLike,
      option,
      {
        requireUser: true,
      }
    );
  } else {
    parseResponse = await runFunction(
      commentsTypes[type].functionLike,
      option,
      {
        requireUser: true,
      }
    );
  }
  return parseResponse;
};
