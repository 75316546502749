import React from "react";

import { RouteComponentProps } from "react-router-dom";

import AnnouncementDetailContainer from "../../../containers/Announcement/Detail";

import { AnnouncementDetail } from "../../components/Announcement";

export type RouteAnnouncementDetailProps = RouteComponentProps<{
  id: string;
  userId: string;
}>;

export const RouteAnnouncementDetail: React.FC<RouteAnnouncementDetailProps> = props => (
  <AnnouncementDetailContainer
    {...props}
    Layout={AnnouncementDetail}
    forceRefreshOnMount={true}
  />
);
