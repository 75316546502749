import React from "react";
import { RouteComponentProps } from "react-router-dom";

import NotificationLandingContainer from "../../../containers/Notification/Landing";

import { NotificationLanding } from "../../components/Notification";

export type RouteNotificationLandingProps = RouteComponentProps;

export const RouteNotificationLanding: React.FC<RouteNotificationLandingProps> = props => (
  <NotificationLandingContainer {...props} Layout={NotificationLanding} />
);
