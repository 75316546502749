import { AnnouncementItem } from "../../../types";

import { AnchorProps, useAnchorProps } from "..";

export type AnnouncementAnchorProps = AnchorProps;

export type UseAnnouncementAnchorProps = (
  item: AnnouncementItem
) => AnnouncementAnchorProps;

export const useAnnouncementAnchorProps: UseAnnouncementAnchorProps = ({
  announcement: { event, id },
}) => {
  const redirectPath = event ? "event-detail" : "announcement";

  const redirectId = event?.objectId ?? id;

  const href = `/${redirectPath}/${redirectId}`;

  const anchorProps = useAnchorProps(href);

  return anchorProps;
};
