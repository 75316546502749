import { SettingConfigData, ProgressCallback } from "../../../types";
import { uploadWithProgress } from "./uploadWithProgress";

type FileCloudinary = {
  config: SettingConfigData;
  file: {
    contentType: string;
    name: string;
    size: number;
    type: string;
    uri: string;
  };
  progressCallback?: ProgressCallback;
  path: string;
  policy: {
    apiSecret?: string;
    context: string;
    signature: string;
    timestamp: string;
    eager_async: string;
    eager: string;
  };
  // @FIXME userId, title and contentType is not being provided
  userId?: string;
  title?: string;
  contentType?: string;
};

type OptionsCloudinary = {
  URI: string;
  apiKey: string;
  uploadPreset: string;
  folder: string;
  resourceType: "video";
  context: string;
  signature: string;
  timestamp: string;
  // eslint-disable-next-line @typescript-eslint/camelcase
  eager_async: string;
  eager: string;
};

export const resolveFileCloudinary = async (
  fileData: FileCloudinary
): Promise<{
  fileUrl: string;
  name?: string;
  contentType?: string;
  data: {};
}> => {
  console.debug("[Service] resolveFileCloudinary", fileData);

  const { file, path, config, policy, progressCallback } = fileData;

  const cloudinaryOption: OptionsCloudinary = {
    URI: "https://api.cloudinary.com/v1_1/groupfire/upload",
    apiKey: config.cloudinaryApiKey,
    uploadPreset: config.cloudinaryUploadPreset,
    folder: path,
    resourceType: "video",
    context: policy.context,
    signature: policy.signature,
    timestamp: policy.timestamp,
    // eslint-disable-next-line @typescript-eslint/camelcase
    eager_async: policy.eager_async,
    eager: policy.eager,
  };

  const uploadId = new Date().getTime().toString();

  const formData = new FormData();
  formData.append("api_key", cloudinaryOption.apiKey);
  formData.append("upload_preset", cloudinaryOption.uploadPreset);
  formData.append("context", cloudinaryOption.context);
  formData.append("resource_type", cloudinaryOption.resourceType);
  formData.append("folder", cloudinaryOption.folder);
  formData.append("signature", cloudinaryOption.signature);
  formData.append("timestamp", cloudinaryOption.timestamp.toString());
  formData.append("eager", cloudinaryOption.eager);
  formData.append("eager_async", cloudinaryOption.eager_async.toString());

  formData.append("file", file.uri);

  const response = await uploadWithProgress(
    cloudinaryOption.URI,
    formData,
    {
      "X-Unique-Upload-Id": uploadId,
      "Content-Range": ["bytes 0-", file.size - 1, "/", file.size].join(""),
    },
    progress =>
      progressCallback?.(
        file.name,
        {
          [file.name]: { transferred: progress.loaded, total: progress.total },
        },
        progress.loaded,
        progress.total,
        false
      ),
    () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.canceledUploading = false;
    },
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.canceledUploading
  );
  const uploadResult = JSON.parse(response);

  return {
    fileUrl: uploadResult.secure_url,
    name: fileData.title,
    contentType: fileData.contentType,
    // eslint-disable-next-line @typescript-eslint/camelcase
    data: { ...uploadResult, original_filename: file.name },
  };
};
