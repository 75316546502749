import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import {
  getPost,
  saveDraftPost,
  postToGroup,
  clearDraftPost,
  canPostToGroup,
  GetPostActionReturn,
} from "./action";
import { getGroup, getSuggestedPostingGroups } from "../Group/action";
import { searchTags } from "../Tag/action";
import { track } from "../../lib/track";
import { UserDataState } from "../../types/User/UserDataState";
import {
  Callback,
  Dict,
  Group,
  MatchProps,
  NotificationPostingStatus,
  Post,
  PostData,
  PostGroup,
  PostRenderData,
  PostUploadFile,
  RootState,
  Tag,
} from "../../types";
import { withTranslation, WithTranslation } from "react-i18next";
import { debounce } from "underscore";
import { getVideoMetaData } from "../Media/action";
import { ExternalVideo } from "../../types/Media/ExternalVideo";
import { VideoUpload } from "../../types/VideoLibrary/VideoUpload";
import _ from "underscore";

export type PostNewEditStateProps = {
  user: UserDataState | {};
  setting: {
    confettiEffectActions?: [];
    maxSizeForMobileVideoUploading: number;
    maxSizeForVideoUploading: number;
    maxSizeForUploading: number;
    postVideoEnabled: boolean;
  };
  data: PostData;
  postingStatus?: NotificationPostingStatus;
  tags: Tag[];
  groupsSuggestion: Dict<Group>;
};

export type PostNewEditDispatchProps = {
  toggle?: () => void;
  getPost: (
    id: string,
    options: { uid?: string; showComments?: boolean },
    refresh: boolean
  ) => Promise<GetPostActionReturn>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getGroup: any; //@FIXME add type when group typing is merged
  canPostToGroup: (groupId: string) => Promise<{ data: boolean }>;
  saveDraftPost: (data: Post) => Promise<boolean>;
  clearDraftPost: () => Promise<boolean>;
  searchTags: (option: { search: string; type?: string }) => Promise<void>;
  getSuggestedPostingGroups: () => Promise<void>;
  getVideoMetaData: (url: string) => Promise<ExternalVideo>;

  postToGroup: (
    data: Partial<PostData> & { userId?: string; groupId: string }
  ) => Promise<{ data: { id: string } } | void>;
};

export type PostNewEditLayout = {
  toggle?: () => void;
  allowPosting: boolean;
  data: PostData;
  error: string | null;
  fromMixedFeed: boolean;
  groupsSuggestion: Dict<Group>;
  postingStatus?: NotificationPostingStatus;
  refreshing: boolean;
  renderData: PostRenderData;
  sending: boolean;
  setting: PostNewEditStateProps["setting"];
  tagsSuggestion: Tag[] | null;
  user: UserDataState | {};
  onChangePost: <K extends keyof Partial<PostData>>(key: K, value: any) => void;
  onChangeRenderData: (key: string, value: any) => void;
  onChangeVideo: (video: VideoUpload | null) => void;
  onChangeVideoLink: (url: string, callback?: Callback) => Promise<void>;
  onMentionLink: (newLinksMentions: any) => void;
  onMentionUser: (newMentions: any) => void;
  onPickedFiles: (
    files: (PostUploadFile | { file: File })[],
    callback?: Callback
  ) => void;
  onPost: (callback?: (text: string) => void) => void;
  onRemoveFile: (file: any, index: number) => void;
  onSearchTags: (name: string) => void;
  reFetch: (refresh: boolean, callback?: () => void) => Promise<void>;
};

export type PostNewEditOwnProps = {
  Layout: ComponentType<PostNewEditLayout>;
};

export type PostNewEditProps = PostNewEditStateProps &
  PostNewEditDispatchProps &
  MatchProps &
  PostNewEditOwnProps &
  WithTranslation;

export type PostNewEditState = {
  error: string | null;
  sending: boolean;
  refreshing: boolean;
  fromMixedFeed: boolean;
  allowPosting: boolean;
  data: PostData;
  search: string | null;
  renderData: PostRenderData;
};

class NewEditPost extends Component<PostNewEditProps, PostNewEditState> {
  private saveDraftPostInterval: any;

  state: PostNewEditState = {
    error: null,
    sending: false,
    refreshing: false,
    fromMixedFeed: true,
    allowPosting: true,
    data: {
      linksMentions: [],
      mentions: [],
    },
    search: null,
    renderData: {
      groupModal: null,
      searchImageModal: null,
      searchVideoModal: null,
      videoLinkModal: null,
      fileLoading: false,
      videoLoading: false,
    },
  };

  componentDidMount(): void {
    const { data } = this.props;
    this.setState({ data });
    /**
     * @FIXME
     *
     * this.fetchData(true) causes following error:
     *
     * Warning: Can't perform a React state update on an unmounted component.
     * This is a no-op, but it indicates a memory leak in your application.
     * To fix, cancel all subscriptions and asynchronous tasks in the
     * componentWillUnmount method.
     */
    this.fetchData(true);
    this.fetchExtraData();

    this.saveDraftPostInterval = setInterval(this.saveDraftPost, 30 * 1000);

    track("View Screen", {
      Screen: "group-feed-new",
      Params: this.props.match && this.props.match.params,
    });
  }

  componentWillUnmount(): void {
    if (this.saveDraftPostInterval) {
      clearInterval(this.saveDraftPostInterval);
    }
  }

  saveDraftPost = (): void => {
    if (this.state.data !== null && this.state.data.group) {
      const {
        body = "",
        mentions = [],
        linksMentions = [],
        group = {},
      } = this.state.data;
      //eslint-disable-next-line
      //@ts-ignore
      this.props.saveDraftPost({ body, mentions, linksMentions, group });
    }
  };

  fetchData = async (
    refresh: boolean,
    callback?: () => void
  ): Promise<void> => {
    try {
      const { getPost, match } = this.props;
      const { refreshing, sending } = this.state;
      const id = match && match.params && match.params.id;

      if (refreshing || sending || !id) {
        return;
      }

      if (refresh) {
        this.setState({ refreshing: true });
      }
      const { data } = await getPost(id, {}, refresh);
      const { images, post } = data || {};

      const { mentions, linksMentions, group } = post as Post;
      this.setState({
        data: {
          ...post,
          group: Object.assign(group, {
            id: (group as PostGroup).objectId,
          }),
          mentions: mentions,
          linksMentions: linksMentions,
          files: images && images.map(item => ({ file: item })),
        },
      });
      callback?.();
    } catch (error) {
      this.setState({ sending: false, refreshing: false, error: error });
    }
  };

  fetchExtraData = async (): Promise<void> => {
    await this.fetchGroup(true);
    this.props.getSuggestedPostingGroups();
  };

  fetchGroup = async (
    refresh: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getGroup, match, canPostToGroup } = this.props;
    const { refreshing, sending } = this.state;
    const groupId = match && match.params && match.params.groupId;

    if (refreshing || sending || !groupId) {
      return;
    }
    try {
      const groupResp = await getGroup(groupId, refresh);
      if (groupResp?.data) {
        this.setState({ fromMixedFeed: false });
        this.onChangePost("group", groupResp.data);
      }

      const canPostToGroupResp = await canPostToGroup(groupId);
      if (canPostToGroupResp?.data) {
        callback?.();
      } else {
        this.setState({
          error: this.props.t("Container.NewEdit.Error"),
          allowPosting: false,
        });
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };

  onSearchTagsDebounce = debounce(
    (search: string) => this.props.searchTags({ search }),
    1000
  );

  onSearchTags = (search: string): void => {
    this.setState({ search });
    this.onSearchTagsDebounce(search);
  };

  onChangePost = <K extends keyof Partial<PostData>>(
    key: K,
    value: Partial<PostData>[K]
  ): void => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value }, error: null });
  };

  onPost = async (callback?: (text: string) => void): Promise<void> => {
    const { data } = this.state;
    const { files, body, group, video } = data;
    const { canPostToGroup } = this.props;

    if (_.isEmpty(group)) {
      this.setState({
        error: this.props.t("Container.Action.Messege.EmptyGroup"),
      });
      return;
    } else if (_.isEmpty(body) && _.isEmpty(files) && _.isEmpty(video)) {
      this.setState({
        error: this.props.t("Container.Action.Messege.EmptyText"),
      });
      return;
    } else {
      const canPost = await canPostToGroup((data.group as PostGroup).id);
      if (!canPost?.data) {
        this.setState({
          error: this.props.t("Container.NewEdit.Error"),
        });
        return;
      }
    }

    try {
      this.setState({ sending: true, error: null });

      const { user, postToGroup } = this.props;

      const customData = {
        ...data,
        groupId: (data.group as PostGroup).id,
        userId: (user as UserDataState).id,
      };
      const resp = await postToGroup(customData);
      if (resp) {
        await this.onClearDraft();

        this.setState({ sending: false, error: null });
        callback && callback(this.props.t("Container.NewEdit.Submit.Post"));

        track(`${customData.id ? "Edit Post" : "Create Post"}`, {
          "Post ID": resp?.data?.id,
          "Group ID": customData.groupId,
        });
      }
    } catch (error) {
      this.setState({ sending: false, error: error });
    }
  };

  onClearDraft = (): Promise<boolean> => {
    if (this.saveDraftPostInterval) {
      clearInterval(this.saveDraftPostInterval);
    }
    return this.props.clearDraftPost();
  };

  onChangeRenderData = (name: string, value: any): void => {
    const { renderData } = this.state;
    this.setState({ renderData: { ...renderData, [name]: value } });
  };

  onPickedFiles = (files: any, callback?: Callback): void => {
    this.onChangeRenderData("fileLoading", false);
    this.onChangePost("files", files);
    callback?.();
  };

  onRemoveFile = (file: any, index: number): void => {
    const { data } = this.state;
    data?.files?.splice(index, 1);
    this.onChangePost("files", data?.files);
  };

  onMentionUser = (newMentions: any): void => {
    setTimeout(() => this.onChangePost("mentions", newMentions), 100);
  };

  onMentionLink = (newLinksMentions: any): void => {
    setTimeout(() => this.onChangePost("linksMentions", newLinksMentions), 100);
  };

  onChangeVideoLink = async (
    url: string,
    callback?: Callback
  ): Promise<void> => {
    const { getVideoMetaData } = this.props;
    const video = await getVideoMetaData(url);
    this.onChangePost("video", video);

    setTimeout(() => {
      this.onChangeRenderData("videoLoading", false);
      callback?.();
    }, 1);
  };

  onChangeVideo = (video: VideoUpload | null): void => {
    this.onChangePost("video", video);
    setTimeout(() => {
      this.onChangeRenderData("videoLoading", false);
    }, 1);
  };

  render = (): JSX.Element => {
    const {
      toggle,
      Layout,
      user,
      setting,
      postingStatus,
      tags,
      groupsSuggestion,
    } = this.props;
    const {
      sending,
      error,
      refreshing,
      data,
      fromMixedFeed,
      allowPosting,
      search,
      renderData,
    } = this.state;

    const tagsSuggestion = search
      ? (tags || []).filter(
          ({ name }) => name.search(new RegExp(search, "i")) > -1
        )
      : [];

    return (
      <Layout
        toggle={toggle}
        error={error}
        sending={sending}
        refreshing={refreshing}
        user={user}
        setting={setting}
        fromMixedFeed={fromMixedFeed}
        allowPosting={allowPosting}
        data={data}
        reFetch={this.fetchData}
        onChangePost={this.onChangePost}
        onPost={this.onPost}
        postingStatus={postingStatus}
        tagsSuggestion={tagsSuggestion}
        onSearchTags={this.onSearchTags}
        groupsSuggestion={groupsSuggestion}
        onChangeRenderData={this.onChangeRenderData}
        renderData={renderData}
        onPickedFiles={this.onPickedFiles}
        onRemoveFile={this.onRemoveFile}
        onMentionUser={this.onMentionUser}
        onMentionLink={this.onMentionLink}
        onChangeVideoLink={this.onChangeVideoLink}
        onChangeVideo={this.onChangeVideo}
      />
    );
  };
}

const mapStateToProps = (state: RootState): PostNewEditStateProps => {
  const { setting, config } = state.setting;

  return {
    user: state.user.data || {},
    setting: {
      confettiEffectActions: setting ? setting.confetti_effect_actions : [],
      maxSizeForMobileVideoUploading:
        config?.maxSizeForMobileVideoUploading || 100,
      maxSizeForVideoUploading: config?.maxSizeForVideoUploading || 100,
      maxSizeForUploading: config?.maxSizeForUploading || 100,
      postVideoEnabled: config?.postVideoEnabled || false,
    },
    data: state.post.item || {
      mentions: [],
      linksMentions: [],
      group: {} as PostGroup,
    },
    postingStatus: state.notification.postingStatus,
    tags: state.tag.search && Object.values(state.tag.search),
    groupsSuggestion: state.group.groupsSuggestedForPosting || {},
  };
};

const mapDispatchToProps = {
  getPost,
  getGroup,
  canPostToGroup,
  saveDraftPost,
  clearDraftPost,
  postToGroup,
  searchTags,
  getSuggestedPostingGroups,
  getVideoMetaData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Post")(NewEditPost));
