import { filter, keys } from "underscore";

import {
  LANDING_MESSAGE_SET,
  LANDING_TWEET_SET,
  NOTIFICATION_ALERT_SET,
  NOTIFICATION_ALL_SET,
  NOTIFICATION_RESET,
  NOTIFICATION_UNREAD_SET,
  NOTIFICATION_UPLOAD_SET,
  POSTING_STATUS_SET,
} from "./constants";
import Store from "./store";
import { NotificationState, NotificationAction } from "./types";

export const initialState: NotificationState = Store;

export default function reducer(
  state: NotificationState = initialState,
  action: NotificationAction
): NotificationState {
  switch (action.type) {
    case NOTIFICATION_RESET:
      return initialState;

    case NOTIFICATION_ALL_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        list: {
          ...state.list,
          ...action.data,
        },
      };

    case LANDING_MESSAGE_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          landingMessages: action.data,
        };
      }

      return state;

    case LANDING_TWEET_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          landingTweets: action.data,
        };
      }

      return state;

    case NOTIFICATION_ALERT_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          alert: action.data.data,
        };
      }

      return state;

    case NOTIFICATION_UNREAD_SET:
      if (action.data) {
        return {
          ...state,
          error: null,
          unread: { ...state.unread, ...action.data },
        };
      }

      return state;

    case NOTIFICATION_UPLOAD_SET:
      if (action.data) {
        const { fileProgress, transferred, total, name, cancel } = action.data;

        if (cancel) {
          return {
            ...state,
            error: null,
            upload: null,
          };
        }

        const percent = Math.round((transferred / total) * 100) / 100;

        /**
         * Why are we using _ utilities instead of ES built in methods eg.
         * Object.keys() and Array.filter()? I'm not sure if filter is
         * returning new array or mutating the original one...
         */
        const done = filter(
          fileProgress,
          progress => progress.transferred === progress.total
        ).length;

        const count = keys(fileProgress).length;

        if (done === count) {
          return {
            ...state,
            error: null,
            upload: null,
          };
        }

        return {
          ...state,
          error: null,
          upload: { percent, done, count, name },
        };
      }

      return state;

    case POSTING_STATUS_SET:
      return {
        ...state,
        postingStatus: action.data,
      };

    default:
      return state;
  }
}
