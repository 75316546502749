import React from "react";

import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  Dict,
  UserDataState,
  UserPersonalInfo,
  UserPersonalInfoType,
} from "../../../../types";

import { LoadingSpinner } from "../../UI";

export type UserProfilePersonalInfoProps = {
  data: UserPersonalInfoType | null;
  isEmailValid: boolean;
  isPhoneOtherValid: boolean;
  isPhoneValid: boolean;
  isUserWebsiteValid: boolean;
  user: {} | UserDataState;
  templatePersonal: string[];
  visibilities: Dict<boolean, UserPersonalInfo> & {
    "Spouse/Partner/Significant Other": boolean;
  };

  emailValidate: (email: string) => boolean;
  locationPicker: (
    value: string,
    id: string,
    type: string,
    onChange?: (name: string, value: string) => void
  ) => JSX.Element;
  onAddChildren: () => void;
  onPersonalInfoChange: (name: string, value: string) => void;
  onRemoveChildren: () => void;
  onVisibilityChange: (name: string, value: string) => void;
  phoneOtherValidate: (phone: string) => boolean;
  phoneValidate: (phone: string) => boolean;
  setEmailValid: (value: boolean) => void;
  setPhoneOtherValid: (value: boolean) => void;
  setPhoneValid: (value: boolean) => void;
  setUserWebsiteValid: (value: boolean) => void;
  websiteValidate: (url: string) => boolean;
};

export const UserProfilePersonalInfo: React.FC<UserProfilePersonalInfoProps> = ({
  data,
  isEmailValid,
  isPhoneOtherValid,
  isPhoneValid,
  isUserWebsiteValid,
  templatePersonal,
  visibilities,

  emailValidate,
  locationPicker,
  onAddChildren,
  onPersonalInfoChange,
  onRemoveChildren,
  onVisibilityChange,
  phoneOtherValidate,
  phoneValidate,
  setEmailValid,
  setPhoneOtherValid,
  setPhoneValid,
  setUserWebsiteValid,
  websiteValidate,
}) => {
  const { t } = useTranslation("User");

  if (!data) {
    return <LoadingSpinner />;
  }

  const handleUserWebsiteChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onPersonalInfoChange("Website", e.target.value);
    const isWebsitValid = websiteValidate(e.target.value);
    setUserWebsiteValid(isWebsitValid);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onPersonalInfoChange("Email", e.target.value);
    const isEmailValid = emailValidate(e.target.value);
    setEmailValid(isEmailValid);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onPersonalInfoChange("Phone", e.target.value);
    const isPhoneValid = phoneValidate(e.target.value);
    setPhoneValid(isPhoneValid);
  };

  const handlePhoneOtherChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onPersonalInfoChange("Phone (Other)", e.target.value);
    const isPhoneValid = phoneOtherValidate(e.target.value);
    setPhoneOtherValid(isPhoneValid);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onPersonalInfoChange(
      e.target.name as keyof UserPersonalInfoType,
      e.target.value
    );
  };

  const handleVisibilityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onVisibilityChange(
      e.target.name as keyof UserPersonalInfoType,
      (e.target.checked as unknown) as string
    );
  };

  const shouldDisplayElement = (element: keyof UserPersonalInfoType): boolean =>
    templatePersonal.includes(element);

  const today = new Date();

  /**@FIXME
   * Not sure what is the type of Children
   */
  const children: any[] = Array.isArray(data.Children) ? data.Children : [];

  const childrenListElement = children.map((child, i) => (
    <CSSTransition key={i} timeout={200} classNames="css-transition">
      <Row className="mb-1">
        <Col className="mr-n4">
          <Input
            type="text"
            name="Name"
            id={`userEditProfilePersonalInfoChildNameInput${i}`}
            placeholder={t("Update.Profile.Personal.Info.Child.Name")}
            value={child?.Name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              children[i] = {
                Name: e.target.value,
                Birthday: children[i].Birthday,
              };
              onPersonalInfoChange("Children", (children as unknown) as string);
            }}
          />
        </Col>
        <Col>
          <DatePicker
            className="form-control"
            maxDate={today}
            name="Birthday"
            id={`userProfilePersonalInfoChildBirthday${i}`}
            selected={child.Birthday ? new Date(child.Birthday) : null}
            onChange={(date: Date): void => {
              children[i] = {
                Name: children[i].Name,
                Birthday: date,
              };
              onPersonalInfoChange("Children", (children as unknown) as string);
            }}
            placeholderText="MM/DD/YYYY"
          />
        </Col>
      </Row>
    </CSSTransition>
  ));

  const spouse = (
    <Row>
      <Col className="mr-n4">
        <Input
          type="text"
          name="Name"
          id="userProfilePersonalInfoSpouseName"
          placeholder="Name"
          value={data.Spouse ? data.Spouse.Name : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            const spouse: {
              Name?: string;
              Birthday?: Date;
            } = {
              ...(data.Spouse = {
                Name: e.target.value,
                Birthday: data.Spouse && data.Spouse.Birthday,
              }),
            };
            onPersonalInfoChange("Spouse", spouse as string);
          }}
        />
      </Col>
      <Col>
        <DatePicker
          className="form-control"
          maxDate={today}
          name="Birthday"
          id="userProfilePersonalInfoSpouseBirthday"
          placeholderText="MM/DD/YYYY"
          selected={data.Spouse?.Birthday && new Date(data.Spouse.Birthday)}
          onChange={(date: Date): void => {
            const spouseBirthday: {
              Name?: string;
              Birthday?: Date;
            } = {
              ...(data.Spouse = {
                Name: data.Spouse && data.Spouse.Name,
                Birthday: date,
              }),
            };
            onPersonalInfoChange("Spouse", spouseBirthday as string);
          }}
        />
      </Col>
    </Row>
  );

  const almaMaterElement = shouldDisplayElement("Alma Mater") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoAlmaMaterLabel">
            {t("Update.Profile.Personal.Info.Alma.Mater")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoAlmaMaterSwitch"
            name="Alma Mater"
            defaultChecked={visibilities["Alma Mater"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        type="text"
        name="Alma Mater"
        id="userEditProfilePersonalInfoAlmaMaterInput"
        placeholder={t("Update.Profile.Personal.Info.Alma.Mater")}
        value={data["Alma Mater"]}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const birthdayElement = shouldDisplayElement("Birthday") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoBirthdayLabel">
            {t("Update.Profile.Personal.Info.Birthday")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoBirthdaySwitch"
            name="Birthday"
            defaultChecked={visibilities["Birthday"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <DatePicker
        className="form-control"
        maxDate={today}
        name="Birthday"
        id="userProfilePersonalInfoBirthday"
        selected={data.Birthday ? new Date(data.Birthday) : null}
        onChange={(date: any): void => {
          onPersonalInfoChange("Birthday", date);
        }}
        placeholderText="MM/DD/YYYY"
      />
    </FormGroup>
  );

  const emailElement = shouldDisplayElement("Email") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoEmailLabel">
            {t("Update.Profile.Personal.Info.Email")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoEmailSwitch"
            name="Email"
            defaultChecked={visibilities["Email"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        invalid={!isEmailValid}
        type="text"
        name="Email"
        id="userEditProfilePersonalInfoEmailInput"
        placeholder={t("Update.Profile.Personal.Info.Email")}
        value={data.Email}
        onChange={handleEmailChange}
      />
      {!isEmailValid && (
        <FormFeedback id="userEditProfilePersonalInfoInvalidEmailMessage">
          {t("Update.Profile.Personal.Info.Invalid.Email")}
        </FormFeedback>
      )}
    </FormGroup>
  );

  const locationElement = shouldDisplayElement("Location") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoLocationLabel">
            {t("Update.Profile.Personal.Info.Location")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoLocationSwitch"
            name="Location"
            defaultChecked={visibilities["Location"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      {locationPicker(
        data.Location,
        "personalLocationSearchInput",
        "address",
        onPersonalInfoChange
      )}
    </FormGroup>
  );

  const phoneElement = shouldDisplayElement("Phone") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoPhoneLabel">
            {t("Update.Profile.Personal.Info.Phone")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoPhoneSwitch"
            name="Phone"
            defaultChecked={visibilities["Phone"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        invalid={!isPhoneValid}
        type="text"
        name="Phone"
        id="userEditProfilePersonalInfoPhoneInput"
        placeholder={t("Update.Profile.Personal.Info.Phone")}
        value={data.Phone}
        onChange={handlePhoneChange}
      />
      {!isPhoneValid && (
        <FormFeedback id="userEditProfilePersonalInfoInvalidPhoneMessage">
          {t("Update.Profile.Personal.Info.Invalid.Phone")}{" "}
        </FormFeedback>
      )}
    </FormGroup>
  );

  const phoneOtherElement = shouldDisplayElement("Phone (Other)") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoPhoneOtherLabel">
            {t("Update.Profile.Personal.Info.Phone.Other")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoPhoneOtherSwitch"
            name="Phone (Other)"
            defaultChecked={visibilities["Phone (Other)"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        invalid={!isPhoneOtherValid}
        type="text"
        name="Phone (Other)"
        id="userEditProfilePersonalInfoPhoneOhterInput"
        placeholder={t("Update.Profile.Personal.Info.Phone.Other")}
        value={data["Phone (Other)"]}
        onChange={handlePhoneOtherChange}
      />
      {!isPhoneOtherValid && (
        <FormFeedback id="userEditProfilePersonalInfoInvalidPhoneOtherMessage">
          {t("Update.Profile.Personal.Info.Invalid.Phone")}{" "}
        </FormFeedback>
      )}
    </FormGroup>
  );

  const bioElement = shouldDisplayElement("Bio") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoBioLabel">
            {t("Update.Profile.Personal.Info.Bio")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoBioSwitch"
            name="Bio"
            defaultChecked={visibilities["Bio"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        type="textarea"
        name="Bio"
        id="userEditProfilePersonalInfoBioInput"
        placeholder={t("Update.Profile.Personal.Info.Bio")}
        value={data.Bio}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const interestsElement = shouldDisplayElement("Interests & Hobbies") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoInterestsAndHobbiesLabel">
            {t("Update.Profile.Personal.Info.Interests.And.Hobbies")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoInterestsAndHobbiesSwitch"
            name="Interests & Hobbies"
            defaultChecked={visibilities["Interests & Hobbies"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        type="text"
        name="Interests & Hobbies"
        id="userEditProfilePersonalInfoInterestsAndHobbiesInput"
        placeholder={t("Update.Profile.Personal.Info.Interests.And.Hobbies")}
        value={data["Interests & Hobbies"]}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const spouseElement = shouldDisplayElement("Spouse") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoSpouseLabel">
            {t("Update.Profile.Personal.Info.Spouse")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoSpouseSwitch"
            name="Spouse"
            defaultChecked={visibilities["Spouse"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      {spouse}
    </FormGroup>
  );

  const childrenElement = shouldDisplayElement("Children") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoChildrenLabel">
            {t("Update.Profile.Personal.Info.Children")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoChildrenSwitch"
            name="Children"
            defaultChecked={visibilities["Children"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <TransitionGroup>{childrenListElement}</TransitionGroup>

      <Row className="ml-1 mb-5 mt-3">
        <i
          id="userEditProfilePersonalInfoChildrenAddButton"
          className="icon-plus pr-3"
          onClick={onAddChildren}
        />
        {childrenListElement.length !== 0 && (
          <i
            id="userEditProfilePersonalInfoChildrenRemoveButton"
            className="icon-minus"
            onClick={onRemoveChildren}
          />
        )}
      </Row>
    </FormGroup>
  );

  const websiteElement = shouldDisplayElement("Website") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfilePersonalInfoWebsiteLabel">
            {t("Update.Profile.Personal.Info.Website")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfilePersonalInfoWebsiteSwitch"
            name="Website"
            defaultChecked={visibilities["Website"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        invalid={!isUserWebsiteValid}
        type="text"
        name="Website"
        id="userEditProfilePersonalInfoWebsiteInput"
        placeholder={t("Update.Profile.Personal.Info.Website")}
        value={data.Website}
        onChange={handleUserWebsiteChange}
      />
      {!isUserWebsiteValid && (
        <FormFeedback id="userEditProfileProfessionalInfoInvalidWebsiteMessage">
          {t("Update.Profile.Professional.Info.Invalid.Website")}
        </FormFeedback>
      )}
    </FormGroup>
  );

  return (
    <Form>
      {almaMaterElement}
      {birthdayElement}
      {emailElement}
      {locationElement}
      {phoneElement}
      {phoneOtherElement}
      {websiteElement}
      {bioElement}
      {interestsElement}
      {spouseElement}
      {childrenElement}
    </Form>
  );
};
