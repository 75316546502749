import React from "react";
import { Subscriptions, Subscription } from "../../../types/Subscription";
import { SettingSettingData } from "../../../types";
import { isEmpty } from "underscore";
import { useTheme } from "../../hooks";
import { Badge, Button } from "reactstrap";
import { textThemingWorkaround } from "../../../constants";
import { useTranslation } from "react-i18next";

type Props = {
  setting: SettingSettingData;
  data: Subscriptions;
  sending: boolean;
  cancelSubscription: (id: string) => void;
  resumeSubscription: (id: string) => void;
  showPaymentMethodUpdate: () => void;
};

export const SubscriptionView: React.FC<Props> = ({
  data,
  sending,
  cancelSubscription,
  resumeSubscription,
  showPaymentMethodUpdate,
}) => {
  const {
    general: { highlightColor: divider, color: dividerColor },
    text: { listText, listTitle },
  } = useTheme();
  const { t } = useTranslation("Subscription");

  const dataArray = data.subscriptions;

  const styles = {
    container: {
      marginTop: 20,
      backgroundColor: "#fff",
    },
    content: {
      borderWidth: 1,
      borderColor: "#ccc",
      marginBottom: 5,
      padding: 15,
    },
    contentHeader: {
      ...listTitle,
      ...textThemingWorkaround,
      marginTop: 15,
    },
    contentBody: {
      ...listText,
      ...textThemingWorkaround,
      whiteSpace: "pre-wrap" as "pre-wrap",
    },
    status: {
      right: 10,
      top: 10,
    },
    statusText: {},
    button: {
      marginTop: 15,
      marginLeft: 5,
    },
    header: {
      backgroundColor: divider,
      color: dividerColor,
      padding: 10,
      fontWeight: 600,
    },
  };

  const onPress = (item: Subscription): void => {
    if (item.status === "canceled") {
      return;
    }
    const resumeEnabled = item.ending && item.status === "active";
    if (resumeEnabled) {
      resumeSubscription(item.id);
    } else {
      cancelSubscription(item.id);
    }
  };

  const renderItem = (item: Subscription, index: number): JSX.Element => {
    const active = item.status === "active";
    const canceling = item.ending && active;

    return (
      <div style={styles.content} className="position-relative" key={index}>
        <Badge
          className="position-absolute text-capitalize"
          color={
            item.status === "active"
              ? "success"
              : item.status === "canceled"
              ? "danger"
              : item.status === "incomplete_expired"
              ? "warning"
              : ""
          }
          style={styles.status}>
          {item.status}
        </Badge>
        {canceling && (
          <Badge color="danger">
            <span style={styles.statusText}>{t("Subscription.Canceling")}</span>
          </Badge>
        )}
        <h5 style={styles.contentHeader}> {t("Subscription.Plan")}</h5>
        <div style={styles.contentBody}>{item.product.name}</div>

        <h5 style={styles.contentHeader}> {t("Subscription.Description")}</h5>
        <div style={styles.contentBody}>{item.product.description}</div>

        <h5 style={styles.contentHeader}>{t("Subscription.Price")}</h5>
        <div style={styles.contentBody}>{item.price.name}</div>
        {active && (
          <div className="d-flex flex-row-reverse">
            <Button
              color="danger"
              disabled={sending}
              style={styles.button}
              onClick={(): void => onPress(item)}>
              {canceling ? t("Subscription.Resume") : t("Subscription.End")}
            </Button>
            <Button
              color="primary"
              disabled={sending}
              style={styles.button}
              onClick={(): void => showPaymentMethodUpdate()}>
              {t("Subscription.PaymentMethod.Update")}
            </Button>
          </div>
        )}
      </div>
    );
  };
  return (
    <div style={styles.container}>
      {!isEmpty(dataArray) && (
        <div style={styles.header}>{t("Subscription.MyPlans")}</div>
      )}
      {dataArray.map((item, index) => renderItem(item, index))}
    </div>
  );
};
