import React, { Component } from "react";

import { connect } from "react-redux";

import { track } from "../../lib/track";
import {
  Announcement,
  AnnouncementItem,
  Callback,
  CommentType,
  MatchProps,
  ReactionType,
  RootState,
} from "../../types";

import { doReaction } from "../Comment/action";

import { getAnnouncement } from "./action";

export type AnnouncementDetailLayoutProps = {
  /** Disables any reaction/comment/link in CommentBar */
  disabledCommentBar?: boolean;
  error: string | null;
  item: AnnouncementItem | null;
  refreshing: boolean;
  setting: {
    /** @FIXME Is it used anywhere? */
    ClientHostName?: string;
    confettiEffectActions: [];
  };

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  reFetch: (refresh: boolean, callback?: Callback) => void;
};

export type AnnouncementDetailOwnProps = {
  Layout: React.ReactType<AnnouncementDetailLayoutProps>;
  /** Disables any reaction/comment/link in CommentBar */
  disabledCommentBar?: boolean;
  forceRefreshOnMount?: boolean;
} & MatchProps<"id" | "userId">;

export type AnnouncementDetailStateProps = {
  item: AnnouncementItem | null;
  setting: {
    ClientHostName?: string;
    confettiEffectActions: [];
  };
};

export type AnnouncementDetailDispatchProps = {
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  getAnnouncement: (
    id: string,
    options: { showComments: boolean; userId?: string; uid?: string },
    refresh: boolean,
    callback: (announcement: Announcement) => void
  ) => Promise<void>;
};

export type AnnouncementDetailProps = AnnouncementDetailOwnProps &
  AnnouncementDetailStateProps &
  AnnouncementDetailDispatchProps;

type AnnouncementDetailState = {
  error: string | null;
  refreshing: boolean;
};

class AnnouncementDetail extends Component<
  AnnouncementDetailProps,
  AnnouncementDetailState
> {
  state: AnnouncementDetailState = {
    error: null,
    refreshing: false,
  };

  componentDidMount = (): void => {
    this.fetchData(!!this.props.forceRefreshOnMount);

    track("View Screen", {
      Screen: "announcement",
      Params: this.props.match && this.props.match.params,
    });
  };

  fetchData = (refresh: boolean, callback?: Callback): void => {
    const { getAnnouncement, match } = this.props;
    const { refreshing } = this.state;

    if (refreshing) {
      return;
    }

    this.setState({ refreshing: refresh }, async () => {
      try {
        const id = match.params?.id;

        if (!id) {
          throw Error("Missing announcement ID");
        }

        await getAnnouncement(
          id,
          {
            showComments: true,
            userId: match.params?.userId,
          },
          refresh,
          announcement => {
            track("View Announcement", {
              "Announcement ID": announcement.objectId,
              "Announcement Title": announcement.title,
            });
          }
        );

        this.setState({ refreshing: false, error: null });

        callback?.();
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const {
      Layout,
      disabledCommentBar,
      item,
      setting,
      doReaction,
    } = this.props;
    const { error, refreshing } = this.state;

    return (
      <Layout
        disabledCommentBar={disabledCommentBar}
        error={error}
        refreshing={refreshing}
        setting={setting}
        item={item}
        reFetch={this.fetchData}
        doReaction={doReaction}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: AnnouncementDetailOwnProps
): AnnouncementDetailStateProps => {
  const id = ownProps.match.params?.id;
  const { localConfig, setting } = state.setting;
  const { list } = state.announcement;
  const { items, cached } = list;
  const item = (id && ((items && items[id]) || (cached && cached[id]))) || null;

  return {
    setting: {
      ClientHostName: localConfig ? localConfig.client_url : "",
      confettiEffectActions: setting ? setting.confetti_effect_actions : [],
    },
    item,
  };
};

const mapDispatchToProps = {
  getAnnouncement: getAnnouncement,
  doReaction: doReaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementDetail);
