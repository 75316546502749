/** @TODO Theming workaround. Delete when issues solved. */
export const textThemingWorkaround = {
  /** fontFamily is overwritten until we solve proper fonts loading */
  fontFamily: undefined,
  /** Typing inconsistency beetween RN and web */
  fontWeight: undefined,
  /**
   * lineHeight dimention in RN is px but in web its multiplier of
   * fontSize
   */
  lineHeight: undefined,
  /** textAlign is problem only for buttons. Overwritten manualy */
  // textAlign: undefined,
};
