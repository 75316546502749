import { runFunction } from "../../http";

export const updateReadNotification = async (
  notificationId: string
): Promise<void> => {
  console.debug("[Service] readNotificationForUser");

  return await runFunction(
    "readNotificationForUser",
    { notificationId },
    { requireUser: true }
  );
};
