import { logException, shouldResizeFile } from "../../../lib/utils";

import {
  Fallback,
  MediaFileProcessedCallback,
  MediaInputFileProcessed,
} from "../../../types";

import { convertFileToBase64, resizeImage } from ".";

export const processInputFiles = async (
  files: File[],
  callBack: MediaFileProcessedCallback,
  fallBack?: Fallback
): Promise<void> => {
  console.debug("[Service] processInputFile");

  try {
    /** @TODO Should we support multiple file processing? */
    const file = files[0];

    const { name, type } = file;

    /** Get Blobs URI as base64 */
    const base64URI = await convertFileToBase64(file);

    const result: MediaInputFileProcessed = {
      file: file,
      fileUrl: base64URI,
      fileThumb: undefined,
      thumbUrl: undefined,
    };

    /** Resizing PNGs and JPEGs */
    if (shouldResizeFile(name, type)) {
      resizeImage(
        {
          base64URI,
          name,
        },
        resizedImageResponse => {
          Object.assign(result, {
            fileThumb: resizedImageResponse.file,
            thumbUrl: resizedImageResponse.base64URI,
          });

          callBack([result]);
        }
      );
    } else {
      callBack([result]);
    }
  } catch (e) {
    logException(e);
    if (e?.message && typeof e.message === "string") {
      fallBack?.(e.message);
    }
  }
};
