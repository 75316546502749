import { adminPost } from "../../http";
import { Config } from "../../../types";

export const setPaymentMethod = async (
  paymentMethod: string,
  config: Config
): Promise<{ message?: string; error?: string }> => {
  console.debug("[Service] setPaymentMethod", paymentMethod);

  return await adminPost(
    "customer/payment_method",
    {
      // eslint-disable-next-line @typescript-eslint/camelcase
      payment_method: paymentMethod,
    },
    {
      requireUser: true,
      config,
    }
  );
};
