import { HistoryLocation } from "../../types";

export const getQueryVariable = (
  variable: string,
  location: HistoryLocation
): string | undefined => {
  const pairs = location.search.substring(1).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};
