import { runFunction } from "../../http";

export const removeMembersFromGroupChat = async (
  userIds: string[],
  channel: string
): Promise<void> => {
  await runFunction(
    "removeMembersFromGroupChat",
    { userIds: userIds, channel: channel },
    {
      requireUser: true,
    }
  );
};
