import React from "react";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import { Member, RootState, UserDataState, SettingState } from "../../types";

import { getGroupMembers } from "./action";
import { getSettingState } from "../Setting/selector";
import { getCurrentUserState } from "../User/selector";
import {
  getGroupMembersDataState,
  showGroupMembersPlaceholderState,
} from "./selector";

export type GroupMembersLayoutProps = {
  //member object after mutation, name is a combined string from first and last name
  //please refactor this
  data: Member[];
  error: null;
  refreshing: boolean;
  showPlaceholder: boolean;
  query: string;
  setting: SettingState;
  searchedData: Member[];
  /**
   * @TODO Needs better typing!
   */
  style?: {};
  user: UserDataState | {};
  changeQuery: (query: string) => void;
  reFetch: (refresh?: boolean, callback?: () => {}) => void;
};

export type GroupMembersOwnProps = {
  Layout: React.ComponentType<GroupMembersLayoutProps>;
  /**
   * @TODO Needs better typing!
   */
  style?: {};
  /** Routing props */
  match?: {
    params?: { id?: string; profileId?: string };
  };
};

export type GroupMembersStateProps = {
  data: Member[];
  setting: SettingState;
  user: UserDataState | {};
  showPlaceholder: boolean;
};

export type GroupMembersDispatchProps = {
  getMembers: (id: string, refresh?: boolean) => Promise<void>;
};

export type GroupMembersProps = GroupMembersOwnProps &
  GroupMembersStateProps &
  GroupMembersDispatchProps;

export type GroupMembersState = {
  error: null;
  refreshing: boolean;
  searchedData: Member[];
  query: string;
};

class GroupMembers extends React.Component<
  GroupMembersProps,
  GroupMembersState
> {
  state: GroupMembersState = {
    error: null,
    refreshing: false,
    searchedData: [],
    query: "",
  };

  componentDidMount = (): void => {
    this.fetchData();

    track("View Screen", {
      Screen: "group-members",
      Params: this.props.match?.params,
    });
  };

  fetchData = (refresh?: boolean, callback?: () => {}): void => {
    const { getMembers, match } = this.props;
    const { refreshing } = this.state;
    const id = match?.params?.id;

    if (refreshing || !id) {
      return;
    }

    this.setState({ refreshing: true }, async () => {
      try {
        await getMembers(id, refresh);

        this.setState({ refreshing: false, error: null });

        callback?.();

        track("View Group Members", {
          "Group ID": id,
        });
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  onChangeQuery = (query: string): void => {
    const { data } = this.props;
    const searchedData = data.filter(({ name }) => {
      if (!name) {
        return false;
      }
      return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });

    this.setState({ searchedData, query });
    track("Search Group Members", {
      "Search term": query,
    });
  };

  render = (): JSX.Element => {
    const { Layout, user, data, setting, style, showPlaceholder } = this.props;
    const { error, refreshing, query, searchedData } = this.state;

    return (
      <Layout
        error={error}
        refreshing={refreshing}
        showPlaceholder={showPlaceholder}
        user={user}
        setting={setting}
        data={data}
        searchedData={searchedData}
        query={query}
        style={style}
        reFetch={this.fetchData}
        changeQuery={this.onChangeQuery}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: GroupMembersOwnProps
): GroupMembersStateProps => {
  const id = ownProps.match?.params?.id || "";

  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getGroupMembersDataState(state, id),
    showPlaceholder: showGroupMembersPlaceholderState(state, id),
  };
};

const mapDispatchToProps = {
  getMembers: getGroupMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembers);
