import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import {
  deleteMessage,
  getChannel,
  getChat,
  markAsRead,
  setInputField,
  sendMessage,
  updateMessage,
  checkHasMessages,
} from "./action";
import { isEmpty } from "underscore";
import { track } from "../../lib/track";
import { UserDataState } from "../../types/User/UserDataState";
import { Group, MatchProps, RootState, EmptyObject } from "../../types";
import { ChatDetailsData } from "../../types/Chat/ChatDetailsData";
import { FileMessage, GroupChannel, OpenChannel, UserMessage } from "sendbird";
import { ChatMutatedMessage } from "../../types/Chat/ChatMutatedMessage";
import { ChatAdminMessage, ChatMutatedChannel } from "./chatTypes";
import { TempMessageType } from "../../types/Chat/TempMessageType";
import { SendMessageParams } from "../../types/Chat/SendMessageParams";
import { ChatInputField } from "../../types/Chat/ChatInputField";
import {
  getChatInputFieldState,
  getChatDetailSettingState,
  getChatDetailActiveState,
  getChatDetailDataState,
  getChatDetailDataHasMoreState,
} from "./selector";
import { getCurrentUserState } from "../User/selector";

type StateProps = {
  active: ChatDetailsData | {};
  hasMore: boolean;
  inputField: ChatInputField;
  messages: (ChatMutatedMessage | ChatAdminMessage | TempMessageType | any)[];
  setting: {
    ClientHostName: string;
  };
  user: UserDataState | {};
};

type DispatchProps = {
  getChannel: (url: string, noStore?: boolean) => Promise<ChatMutatedChannel>;
  markAsRead: (channel: OpenChannel | GroupChannel) => Promise<boolean>;
  getChat: (
    channel: ChatMutatedChannel,
    next: boolean,
    refresh: boolean
  ) => Promise<void>;
  deleteMessage: (
    channel: ChatMutatedChannel,
    message: FileMessage | UserMessage
  ) => Promise<boolean>;
  sendMessage: (
    channel: ChatMutatedChannel,
    params: SendMessageParams,
    group: Group
  ) => Promise<void>;
  updateMessage: (
    channel: ChatMutatedChannel,
    params: SendMessageParams & { id: number }
  ) => Promise<void>;
  setInputField: (
    channel: ChatMutatedChannel,
    params: ChatInputField
  ) => Promise<void>;
  checkHasMessages: () => Promise<void>;
};

export type ChatDetailLayout = {
  active: ChatDetailsData | EmptyObject;
  channel?: ChatMutatedChannel;
  error: string | null;
  hasMore: boolean;
  inputField: ChatInputField;
  loading: boolean;
  messages?: (ChatMutatedMessage | ChatAdminMessage | TempMessageType | any)[];
  refreshing: boolean;
  renderInput: boolean;
  sending?: boolean;
  setting: {
    ClientHostName: string;
  };
  text?: string;
  uploadStatus?: string;
  user: UserDataState | EmptyObject;

  deleteMessage: (data: UserMessage, callback?: () => void) => Promise<void>;
  getMoreChat: (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ) => Promise<void>;
  onChangeText: (text: string) => boolean | void;
  reFetch: (
    refresh: boolean,
    reConnect: boolean,
    callback?: () => void
  ) => Promise<void>;
  sendMessage: (
    data: SendMessageParams,
    cancel?: boolean,
    callback?: () => void
  ) => Promise<void>;
  setInputField: (data: ChatInputField) => Promise<void>;
  updateMessage: (
    data: SendMessageParams & { id: number },
    cancel?: boolean,
    callback?: () => void
  ) => Promise<void>;
};

type Props = {
  Layout: ComponentType<ChatDetailLayout>;
  group?: Group;
  uploadStatus?: string;
  channel?: any;
} & MatchProps<"channel" | "text"> &
  StateProps &
  DispatchProps;

type State = {
  channel?: ChatMutatedChannel;
  error: string | null;
  hasMore?: boolean;
  loading: boolean;
  refreshing: boolean;
  renderInput: boolean;
  sending?: boolean;
  text?: string;
};

class ChatDetail extends Component<Props, State> {
  state: State = {
    error: null,
    hasMore: false,
    loading: false,
    refreshing: false,
    renderInput: false,
  };

  componentDidMount(): void {
    this.fetchData(true, false);
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.match?.params?.channel !== this.props.match?.params?.channel
    ) {
      this.fetchData(true, false);
    }
  }

  componentWillUnmount(): void {
    const { channel } = this.state;
    const { markAsRead } = this.props;
    if (channel) {
      markAsRead(channel);
    }
  }

  fetchData = async (
    refresh: boolean,
    reConnect: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getChannel, match, checkHasMessages } = this.props;
    const { refreshing } = this.state;
    if (refreshing) {
      return;
    }

    const getChat = (): void => {
      setTimeout(() => {
        this.getMoreChat(false, refresh, () => {
          this.setState({ renderInput: true });
        });
      }, 1);
    };

    this.setState({ refreshing: true }, async () => {
      try {
        const channelUrl = match && match.params ? match.params.channel : "";
        const text = match && match.params && match.params.text;
        let channel = this.props.channel;

        if (!channel) {
          channel = await getChannel(channelUrl);
        }

        if (
          channel.isOpenChannel() ||
          (channel.unreadMessageCount as number) > 0
        ) {
          this.setState({ error: null, channel, text });
          if (reConnect) {
            getChat();
          }
        } else {
          this.setState({ error: null, channel, text, refreshing: false });
        }

        if (!reConnect) {
          getChat();
        }

        setTimeout(() => checkHasMessages(), 1000);

        callback && callback();

        track("Enter Room Chat", {
          "Channel ": channelUrl,
        });
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  getMoreChat = async (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getChat, messages } = this.props;
    const { loading, channel } = this.state;
    if (loading || !channel) {
      return;
    }

    try {
      this.setState(
        { refreshing: true, loading: true, renderInput: !isEmpty(messages) },
        async () => {
          await getChat(channel, next, refresh);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
          callback && callback();
        }
      );
    } catch (error) {
      this.setState({ loading: false, refreshing: false, error: error });
      callback && callback();
    }
  };

  setInputField = async (data: ChatInputField): Promise<void> => {
    const { channel } = this.state;
    const { setInputField } = this.props;

    if (!channel) {
      return;
    }
    try {
      await setInputField(channel, data);
    } catch (error) {
      this.setState({ error: error });
    }
  };

  sendMessage = async (
    data: SendMessageParams,
    cancel?: boolean,
    callback?: () => void
  ): Promise<void> => {
    setTimeout(async () => {
      const { sendMessage, group } = this.props;
      const { channel } = this.state;

      if (!channel) {
        return;
      }

      try {
        await sendMessage(channel, data as SendMessageParams, group as Group);
        this.setState({ error: null });
        callback?.();
        this.onTrack(data as SendMessageParams);
      } catch (error) {
        this.setState({ error: error });
      }
    }, 1);
  };

  updateMessage = async (
    data: SendMessageParams & { id: number },
    cancel?: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { updateMessage } = this.props;
    const { sending, channel } = this.state;
    if (sending || !channel || cancel) {
      return;
    }
    try {
      await updateMessage(channel, data);
      this.setState({ sending: false, error: null });
      callback && callback();
      this.onTrack("Update Text Chat");
    } catch (error) {
      this.setState({ sending: false, error: error });
    }
  };

  deleteMessage = async (
    data: UserMessage,
    callback?: () => void
  ): Promise<void> => {
    const { deleteMessage } = this.props;
    const { sending, channel } = this.state;
    if (sending || !channel) {
      return;
    }
    try {
      await deleteMessage(channel, data);
      this.setState({ sending: false, error: null });
      callback && callback();
      this.onTrack("Delete Chat");
    } catch (error) {
      this.setState({ sending: false, error: error });
    }
  };

  onTrack = (data: SendMessageParams | string, event?: string): void => {
    const { match } = this.props;
    const channelUrl = match && match.params && match.params.channel;
    const { text, files } = data as SendMessageParams; //@FIXME this might be a mistake, data can be string!
    if (!event) {
      if (isEmpty(files)) {
        event = "Send File Chat";
      } else {
        event = "Send Text Chat";
      }
    }
    track(event, {
      "Channel ": channelUrl,
      Text: text,
    });
  };

  onChangeText = (text: string): boolean | void => {
    const { channel } = this.state;
    if (!channel || channel.isOpenChannel()) {
      return false;
    }
    if (text && text.length > 0) {
      channel.startTyping();
    } else {
      channel.endTyping();
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      setting,
      messages,
      active,
      uploadStatus,
      hasMore,
      inputField,
    } = this.props;

    const {
      loading,
      error,
      refreshing,
      channel,
      sending,
      text,
      renderInput,
    } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        sending={sending}
        user={user}
        setting={setting}
        channel={channel}
        messages={messages}
        hasMore={hasMore}
        renderInput={renderInput}
        reFetch={this.fetchData}
        getMoreChat={this.getMoreChat}
        inputField={inputField}
        sendMessage={this.sendMessage}
        onChangeText={this.onChangeText}
        setInputField={this.setInputField}
        deleteMessage={this.deleteMessage}
        updateMessage={this.updateMessage}
        active={active}
        uploadStatus={uploadStatus}
        text={text}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): StateProps => {
  const channelUrl = ownProps.match?.params?.channel || "";

  return {
    inputField: getChatInputFieldState(state, channelUrl),
    user: getCurrentUserState(state),
    setting: getChatDetailSettingState(state),
    messages: getChatDetailDataState(state, channelUrl),
    hasMore: getChatDetailDataHasMoreState(state, channelUrl),
    active: getChatDetailActiveState(state, channelUrl),
  };
};

const mapDispatchToProps = {
  getChannel,
  getChat,
  sendMessage,
  markAsRead,
  deleteMessage,
  updateMessage,
  setInputField,
  checkHasMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatDetail);
