import React from "react";

import {
  Alert,
  Button,
  Card,
  CardBody,
  ListGroup,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { textThemingWorkaround } from "../../../constants";

import MediaContainer from "../../../containers/Media";

import { isPhoto } from "../../../lib/utils";

import { ResourceItem, ResourceUrlItem } from "../../../types";

import { useTheme } from "../../hooks";

import { HTML } from "../UI";
import { Media, LoadingSpinner } from "..";

import { ResourceCard } from "./";
import { isEmpty } from "underscore";
import { Link } from "react-router-dom";

export type ResourceDetailProps = {
  error: null;
  item: ResourceItem | ResourceUrlItem | null | undefined;
};
export const ResourceDetail: React.FC<ResourceDetailProps> = ({
  error,
  item,
}) => {
  const { button: buttonStyle } = useTheme();

  if (!item) {
    if (error) {
      return <Alert color="danger">{error}</Alert>;
    }

    return <LoadingSpinner />;
  }
  const {
    body,
    name,
    contentType,
    fileUrl,
    url,
    video,
    children,
    parent,
  } = item;

  const mediaElement =
    item.contentType && isPhoto(contentType) ? (
      <MediaContainer
        Layout={Media}
        image={{ thumbUrl: item.fileUrl }}
        allowViewImageFullScreen={true}
      />
    ) : video ? (
      <MediaContainer
        Layout={Media}
        video={
          video.cloudinary
            ? {
                serviceName: "Cloudinary",
                serviceId: video.cloudinary.public_id,
                cloudName: video.cloudinary.cloud_name,
              }
            : item.video
        }
      />
    ) : fileUrl || url ? (
      /**
       * @TODO @TRANSLATION
       */
      <Button
        block
        href={fileUrl || url}
        rel="noopener noreferrer"
        style={{
          ...buttonStyle,
          ...{
            ...buttonStyle.text,
            ...textThemingWorkaround,
            textAlign: undefined,
          },
          padding: undefined,
        }}
        target="_blank">
        Open file/link
      </Button>
    ) : null;

  const bodyElement = body && (
    <HTML className="resource-card-body" body={body} />
  );

  const childrenCardStyle = {
    backgroundColor: "#fff",
    shadowOpacity: 0,
    elevation: 0,
    borderTopWidth: 0.3,
    borderRadius: 0,
    borderLeft: 0,
    borderBottom: 0,
  };

  let childrenElements;
  if (!isEmpty(children)) {
    childrenElements = (
      <ListGroup>
        {children?.map(child => (
          <ResourceCard key={child.id} item={child} style={childrenCardStyle} />
        ))}
      </ListGroup>
    );
  }

  const cardBody = !!body && (
    <CardBody className="mt-0 p-2">
      <h5 className="my-0 text-capitalize">{name}</h5>
      {bodyElement}
    </CardBody>
  );

  const breadcrumb = !!parent && (
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to="/content-pages">All Resources</Link>
      </BreadcrumbItem>
      {parent?.parent?.parent?.name && (
        <BreadcrumbItem> {parent?.parent?.parent?.name}</BreadcrumbItem>
      )}
      {parent?.parent?.name && (
        <BreadcrumbItem> {parent?.parent?.name}</BreadcrumbItem>
      )}
      {parent?.name && <BreadcrumbItem> {parent?.name}</BreadcrumbItem>}
      {<BreadcrumbItem active> {name}</BreadcrumbItem>}
    </Breadcrumb>
  );

  return (
    <>
      {breadcrumb}
      <Card>
        {mediaElement}
        {cardBody}
        {childrenElements}
      </Card>
    </>
  );
};
