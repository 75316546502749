import {
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import React, { FC } from "react";

type Props = {
  closeNotificationsModal: () => void;
  notificationSettings: {
    mobileNotification?: "all" | "specific";
    desktopNotification?: "all" | "specific";
    mute?: boolean;
    ignoreMuteHereChannel?: boolean;
  };
  notificationSettingsDefault: {
    mobileNotification: "all" | "specific";
    desktopNotification: "all" | "specific";
    mute: boolean;
    ignoreMuteHereChannel: boolean;
  };
  notificationSettingsOptions: {
    value: "all" | "mention" | "nothing";
    key: string;
  }[];

  onChangeNotificationSettings: (
    name:
      | "mobileNotification"
      | "desktopNotification"
      | "mute"
      | "ignoreMuteHereChannel",
    value: string
  ) => void;
};

export const SettingsNotification: FC<Props> = props => {
  return (
    <>
      {" "}
      <ModalHeader
        toggle={props.closeNotificationsModal}
        className="text-primary">
        Notifications
      </ModalHeader>
      <ModalBody>
        <h6 className="lead">Muting options</h6>
        <FormGroup>
          <CustomInput
            type="switch"
            id="mute"
            name="mute"
            label="Mute entire channel"
            onChange={(event): void => {
              props.onChangeNotificationSettings(
                "mute",
                // @FIXME typing problem
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                event.target.checked
              );
            }}
            defaultChecked={
              props.notificationSettings.mute ??
              props.notificationSettingsDefault.mute
            }
          />
          <CustomInput
            type="switch"
            id="ignore"
            name="ignore"
            label="Ignore notifications for @channel and @here"
            onChange={(event): void => {
              props.onChangeNotificationSettings(
                "ignoreMuteHereChannel",
                // @FIXME typing problem
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                event.target.checked
              );
            }}
            defaultChecked={
              props.notificationSettings.ignoreMuteHereChannel ??
              props.notificationSettingsDefault.ignoreMuteHereChannel
            }
          />
          <hr />
          <h6 className="lead">Notifications</h6>
          <InputGroup className="my-1">
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="bg-white">Desktop</InputGroupText>
            </InputGroupAddon>
            <Input
              type="select"
              name="select"
              onChange={(event): void => {
                props.onChangeNotificationSettings(
                  "desktopNotification",
                  event.target.value
                );
              }}
              value={
                props.notificationSettings.desktopNotification ??
                props.notificationSettingsDefault.desktopNotification
              }>
              {props.notificationSettingsOptions.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Input>
          </InputGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="bg-white">Mobile</InputGroupText>
            </InputGroupAddon>
            <Input
              type="select"
              name="select"
              onChange={(event): void => {
                props.onChangeNotificationSettings(
                  "mobileNotification",
                  event.target.value
                );
              }}
              value={
                props.notificationSettings.mobileNotification ??
                props.notificationSettingsDefault.mobileNotification
              }>
              {props.notificationSettingsOptions.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.key}
                </option>
              ))}
            </Input>
          </InputGroup>
        </FormGroup>
      </ModalBody>{" "}
    </>
  );
};
