import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { textThemingWorkaround } from "../../../constants/Theme";
import { EventAttendee } from "../../../types";

import { useTheme } from "../../hooks";

import { Avatar, Heading } from "../UI";

export type EventCardAttendeesProps = {
  attendees?: {
    all: EventAttendee[];
    newest: EventAttendee[];
    total: number;
  };

  onTotalAttendeesClick: () => void;
};

export const EventCardAttendees: React.FC<EventCardAttendeesProps> = ({
  attendees,

  onTotalAttendeesClick,
}) => {
  const history = useHistory();

  const { t } = useTranslation("Event");

  const {
    text: { subHeader, cardText },
  } = useTheme();

  const eventCardAttendeesStyles = {
    attendeesHeading: {
      ...cardText,
      ...textThemingWorkaround,
      marginLeft: 10,
    },
    cardItem: {
      backgroundColor: "transparent",
    },
    subHeader: {
      ...subHeader,
      ...textThemingWorkaround,
    },
  };

  const totalAttendeesHeading = attendees && attendees.total > 0 && (
    <div
      className="align-self-center cursor-pointer"
      onClick={onTotalAttendeesClick}>
      <Heading
        style={eventCardAttendeesStyles.attendeesHeading}
        text={
          attendees && attendees.total <= 1
            ? t("Detail.Text.Member", {
                attendees,
              })
            : t("Detail.Text.Member.Plural", {
                attendees,
              })
        }
      />
    </div>
  );

  const attendeesElement =
    attendees &&
    attendees.total > 0 &&
    attendees.newest.map((attendee, index) => {
      const attendeesHref = `/member/${attendee.user.id ||
        attendee.user.objectId}`;
      const handleAttendeesClick = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        history.push(attendeesHref);
      };

      return (
        <div
          key={attendee.id}
          style={{
            marginLeft: index > 0 ? -15 : 0,
            zIndex: 10 - index,
          }}>
          <a
            className="text-decoration-none"
            href={`/member/${attendee.user.id || attendee.user.objectId}`}
            onClick={handleAttendeesClick}>
            <Avatar
              key={index}
              src={attendee.user.profile && attendee.user.profile.thumbUrl}
              name={`${attendee.user.firstName} ${
                attendee.user.lastName ? attendee.user.lastName : ""
              }`}
              round={true}
              size="30"
            />
          </a>
        </div>
      );
    });

  return (
    <div style={eventCardAttendeesStyles.cardItem}>
      <div className="d-flex">
        {attendeesElement}
        {totalAttendeesHeading}
      </div>
    </div>
  );
};
