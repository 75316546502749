import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { TabContent, Nav, NavItem, NavLink, TabPane, Button } from "reactstrap";

import { UserDataState, MixedFeedTab } from "../../../types";

import { getRandomInt } from "../../../lib/utils";

import { PostNewEditModal, PostList, StickyNavbar, Dot } from "..";
import PostContainer from "../../../containers/Post";

import { AnnouncementList } from "..";
import AnnouncementContainer from "../../../containers/Announcement";

import { ResourceList } from "..";
import ResourceContainer from "../../../containers/Resource";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../hooks";

export type MixedFeedProps = {
  tabs: MixedFeedTab[];
  unread: {
    feed?: string[];
    post?: string[];
    resource?: undefined;
  };
  options: {
    postingEngagementPrompts: string[];
  };
  user: {} | UserDataState;
  setting: {};
  renderActions?: boolean;
  renderActionsDone?: boolean;
};

/**
 * @FIXME
 * This logic should come from container
 */
export type PostingEngagementPromptsProps = {
  options: {
    postingEngagementPrompts: string[];
  };
};
const PostingEngagementPrompts: React.FC<PostingEngagementPromptsProps> = ({
  options,
}) => {
  const { t } = useTranslation("MixedFeed");
  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const [isPostNewEditModalOpen, setPostNewEditModalOpen] = useState(false);
  const togglePostNewEditModal = (): void => {
    setPostNewEditModalOpen(!isPostNewEditModalOpen);
  };

  const buttonElements = (
    <div className="d-flex">
      <Button
        id="mixedFeedNewPostButton"
        onClick={togglePostNewEditModal}
        block
        color="primary"
        className="border-0"
        style={{ backgroundColor: buttonBackgroundColor }}>
        <span className="text-small" style={{ color: buttonColor }}>
          {t("Index.Heading.Post")}
        </span>
      </Button>
    </div>
  );

  let postingEngagementPrompt = null;
  if (options.postingEngagementPrompts.length > 0) {
    const randomIndex = getRandomInt(
      0,
      options.postingEngagementPrompts.length - 1
    );
    postingEngagementPrompt = (
      <div
        id="postingEngagementPrompt"
        style={{ color: buttonBackgroundColor }}
        className="pt-2 pb-4 d-flex justify-content-center text-small">{`${options.postingEngagementPrompts[randomIndex]}`}</div>
    );
  }

  return (
    <div>
      {buttonElements}
      {postingEngagementPrompt}
      <PostNewEditModal
        isOpen={isPostNewEditModalOpen}
        toggle={togglePostNewEditModal}
      />
    </div>
  );
};

export const MixedFeed: React.FC<MixedFeedProps> = ({
  tabs,
  unread,
  options,
}) => {
  const history = useHistory();

  const setActiveTab = (tab: MixedFeedTab["id"]): void => {
    history.replace(`/home`, { activeTab: tab });
  };

  if (history.location.state?.activeTab === undefined) {
    setActiveTab("post");
  }

  const activeTab = history.location.state.activeTab;

  const isActiveTab = (tab: MixedFeedTab["id"]): boolean => activeTab === tab;

  const toggleTab = (tab: MixedFeedTab["id"]): void => {
    if (!isActiveTab(tab)) setActiveTab(tab);
  };

  const {
    tabTop: { inactive, inactiveText, underlineColor },
  } = useTheme();

  const styles = {
    tabLink: {
      backgroundColor: inactive.backgroundColor,
      color: inactiveText.color,
      opacity: inactiveText.opacity,
    },
    tabLinkActive: {
      opacity: 1,
      borderColor: underlineColor,
    },
  };
  const navElement = (
    <Nav tabs id="mixedFeedTab">
      {tabs?.map(({ id, title }) => {
        let titleBadge = false;
        if ((unread[id]?.length || 0) > 0) {
          titleBadge = true;
        }

        return (
          <NavItem id={`mixedFeed${title}Tab`} key={id}>
            <NavLink
              active={isActiveTab(id)}
              style={{
                ...styles.tabLink,
                ...(isActiveTab(id) && styles.tabLinkActive),
              }}
              onClick={(): void => {
                toggleTab(id);
              }}>
              {title}
              {titleBadge && <Dot style={{ marginLeft: 5 }} />}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );

  const tabContentElement = (
    <TabContent activeTab={activeTab}>
      {tabs?.map(({ id, displayPins }) => (
        <TabPane key={id} tabId={id}>
          {activeTab === "post" && id === "post" && (
            <PostContainer
              Layout={PostList}
              match={{}}
              displayPins={displayPins}
            />
          )}
          {activeTab === "feed" && id === "feed" && (
            <AnnouncementContainer
              Layout={AnnouncementList}
              match={{}}
              displayPins={displayPins}
              hideFilter={true}
            />
          )}
          {activeTab === "resource" && id === "resource" && (
            <ResourceContainer
              Layout={ResourceList}
              match={{}}
              showVideoCTA={true}
            />
          )}
        </TabPane>
      ))}
    </TabContent>
  );

  return (
    <>
      <PostingEngagementPrompts options={options} />
      <StickyNavbar>{navElement}</StickyNavbar>
      {tabContentElement}
    </>
  );
};
