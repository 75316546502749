import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getGroupsByStatus } from "../../../containers/Group/action";
import { RootState, Group, AppThunkDispatch } from "../../../types";

export type GroupMyGroupsListContainerStateProps = {
  data: Group[] | null;
};
export type GroupMyGroupsListContainerDispatchProps = {
  getGroups: (refresh: boolean) => void;
};

export type GroupMyGroupsListLayoutProps = {
  className?: string;
  simpleMode?: boolean;
  data: Group[] | null;
};

export type GroupMyGroupsListContainerOwnProps = {
  Layout: React.ComponentType<GroupMyGroupsListLayoutProps>;
  className?: string;
  simpleMode?: boolean;
};

export type GroupMyGroupsListContainerProps = GroupMyGroupsListContainerStateProps &
  GroupMyGroupsListContainerDispatchProps &
  GroupMyGroupsListContainerOwnProps;

const GroupMyGroupsList: React.FC<GroupMyGroupsListContainerProps> = ({
  Layout,
  className,
  data,
  simpleMode,
  getGroups,
}) => {
  useEffect(() => {
    getGroups(true);
  }, [getGroups]);

  return <Layout className={className} data={data} simpleMode={simpleMode} />;
};

const mapStateToProps = (
  state: RootState
): GroupMyGroupsListContainerStateProps => {
  const statusGroups = state.group.status;

  return {
    data: !statusGroups ? null : statusGroups?.["My groups"].data,
  };
};

const mapDispatchToProps = (
  dispatch: AppThunkDispatch
): GroupMyGroupsListContainerDispatchProps => ({
  getGroups: (refresh: boolean): Promise<void> =>
    dispatch(getGroupsByStatus(refresh)),
});

export const GroupMyGroupsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupMyGroupsList);
