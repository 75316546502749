import React from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import i18n from "../../middlewares/i18next";
import { connect } from "react-redux";

import {
  forEach,
  isEmpty,
  intersection,
  sortBy,
  map,
  groupBy,
  debounce,
  flatten,
  uniq,
} from "underscore";

import { track } from "../../lib/track";

import { searchSections } from "../../constants/Members/searchSections";
import {
  MemberListLayoutProps,
  Tab,
  MemberData,
  FilterType,
  CategoryFilter,
  groupByType,
} from "./types";

import {
  Dict,
  Group,
  Member,
  RootState,
  SettingConfigGroupType,
  SettingSettingData,
  SettingState,
  UserDataState,
} from "../../types";

import { getGroupsByGroupTypeName } from "../Group/action";

import { getMembers, GetMembersActionReturn } from "./action";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";
import {
  getMembersState,
  isLoadAllMembersEnabled,
  getMembersFilterSettingState,
  showpMembersPlaceholderState,
} from "./selector";

export type MembersOwnProps = {
  Layout: React.ComponentType<MemberListLayoutProps>;
  forceRefresh?: boolean;
  match?: {
    params: {};
  };
  multiple?: boolean;
  onClose?: () => void;
  onDone?: (member: { id: string }[]) => void;
  renderActions?: boolean;
  renderActionsDone?: boolean;
  filteredMemberIDs?: string[];
  disableFilters?: boolean;
  scrollableRef?: React.RefObject<HTMLDivElement>;
  simpleMode?: boolean;
  isMemberMixedLayout?: true;
  tabs?: Tab[];
};

type FilterSetting = {
  mainGroupType: SettingConfigGroupType | null;
  mainGroupData: Group[] | [];
  homeGroupEnable: boolean;
  homeGroupType: string | null;
  homeGroupData: Group[] | null;
  followingEnabled?: boolean;
  blenderBoxEnabled: boolean;
};

export type MembersStateProps = {
  data: Member[];
  showPlaceholder: boolean;
  loadAllMembersEnabled: boolean;
  filterSetting: FilterSetting;
  user: UserDataState | {};
  setting: SettingState;
};

export type MembersDispatchProps = {
  getGroupsByGroupTypeName: (
    groupType: string,
    refresh?: boolean
  ) => Promise<{ data?: Group[] }>;
  getMembers: (
    options?: {},
    refresh?: boolean,
    preLoading?: boolean
  ) => Promise<GetMembersActionReturn>;
};

export type MembersProps = MembersOwnProps &
  MembersStateProps &
  MembersDispatchProps;

export type MembersState = {
  error: null;
  loading: boolean;
  refreshing: boolean;
  tabs: Tab[];
  categoryFilters: CategoryFilter[];
  selected: Dict<{ id: string }>;
  filteredData: Member[] | null;
  searchedData: MemberData[] | null;
  filterType: FilterType;
  query?: string | null;
};

type Props = MembersProps & WithTranslation;

class Members extends React.Component<Props, MembersState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      refreshing: false,
      tabs: [],
      categoryFilters: [],
      selected: {},
      filteredData: null,
      searchedData: null,
      filterType: {},
    };
  }

  componentDidMount = async (): Promise<void> => {
    await this.fetchData({}, this.props.forceRefresh || false);
    await this.fetchOptions();
    this.updateMemberTabs();

    track("View Screen", {
      Screen: "members",
      Params: this.props.match && this.props.match.params,
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.data !== this.props.data) {
      this.updateMemberTabs();
    }
  }

  updateMemberTabs = (): void => {
    const { data, filterSetting, setting } = this.props;
    const {
      blenderBoxEnabled,
      mainGroupType,
      mainGroupData,
      homeGroupEnable,
      homeGroupType,
      followingEnabled,
    } = filterSetting;

    const {
      // eslint-disable-next-line @typescript-eslint/camelcase
      directory_location_enabled: directoryLocationEnabled,
      // eslint-disable-next-line @typescript-eslint/camelcase
      directory_industry_enabled: directoryIndustryEnabled,
    } = setting.setting as SettingSettingData;

    const isHomeGroupType = isEmpty(homeGroupType) ? null : homeGroupType;

    let tabs: Tab[] = [];
    const categoryFilters: CategoryFilter[] = [];

    //display tabs directory: Name - Industry - Location - HomeGroup;
    if (!blenderBoxEnabled && data) {
      tabs = [
        {
          title: i18n.t("Member:Container.Index.Name"),
          icon: "",
          id: "members",
        },
      ];
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (directoryLocationEnabled) {
        tabs.push({
          title: i18n.t("Member:Container.Index.Location"),
          icon: "",
          id: "location",
        });
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (directoryIndustryEnabled) {
        tabs.push({
          title: i18n.t("Member:Container.Index.Industry"),
          icon: "",
          id: "industry",
        });
      }
      if (isHomeGroupType) {
        tabs.push({ title: homeGroupType as string, icon: "", id: "group" });
      }

      tabs.forEach(({ id, title }) => {
        if (id === "maps") return;
        const dataGroupBy = sortBy(
          map(
            groupBy(
              data.filter(user => user[id] && user[id] !== "undefined"),
              id
            ),
            (data, title) => {
              return { name: title };
            }
          ),
          "name"
        );
        categoryFilters.push({ name: title, id, data: dataGroupBy });
      });
    }

    if (blenderBoxEnabled && mainGroupData) {
      const data: { name: string; count: number }[] = [];
      mainGroupData.forEach(value => {
        data.push({
          name: value.name,
          count: value.memberCount,
        });
      });

      categoryFilters.unshift({
        name: mainGroupType?.name || "",
        id: mainGroupType?.name as groupByType,
        data,
      });
    }

    if (filterSetting.blenderBoxEnabled) {
      const filterSecondaryText = [
        followingEnabled ? i18n.t("Member:Index.Text.My.Network") : null,
        homeGroupEnable ? homeGroupType : null,
      ]
        .filter(item => item)
        .join(" or ");

      const filterSecondaryOptions = [
        followingEnabled ? i18n.t("Member:Index.Text.My.Network") : null,
        homeGroupEnable ? homeGroupType : null,
      ]
        .filter(item => item)
        .map(item => ({ name: item as string }));

      categoryFilters.push({
        name: filterSecondaryText,
        id: "secondary",
        data: filterSecondaryOptions,
      });
    }

    if (blenderBoxEnabled && mainGroupData) {
      const data: { name: string; count: number }[] = [];
      mainGroupData.forEach(value => {
        data.push({
          name: value.name,
          count: value.memberCount,
        });
      });

      categoryFilters.unshift({
        name: mainGroupType?.name || "",
        id: mainGroupType?.name as groupByType,
        data,
      });
    }

    this.setState({
      tabs,
      categoryFilters: uniq(
        categoryFilters.filter(item => !isEmpty(item.data)),
        item => item.id
      ),
    });
  };

  fetchData = async (
    data?: {},
    refresh?: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getMembers } = this.props;

    this.setState(
      {
        refreshing: refresh as boolean,
        loading: !(refresh as boolean),
      },
      async () => {
        try {
          await getMembers(data, refresh, true);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });

          callback?.();
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  fetchOptions = async (): Promise<void> => {
    const { getGroupsByGroupTypeName, filterSetting } = this.props;
    const { homeGroupType, mainGroupType } = filterSetting;

    const promises = [];
    if (homeGroupType) {
      promises.push(getGroupsByGroupTypeName(homeGroupType));
    }
    if (mainGroupType) {
      promises.push(getGroupsByGroupTypeName(mainGroupType.groupType));
    }
    await Promise.all(promises);
  };

  onSelect = (member: { id: string }): void => {
    const { selected } = this.state;
    const { onDone, multiple = true } = this.props;

    if (!multiple && onDone) {
      onDone([member]);
      return;
    }

    const { id } = member;
    const newSelectedItemsList = { ...selected };

    if (selected[id]) {
      delete newSelectedItemsList[member.id];
    } else {
      newSelectedItemsList[member.id] = member;
    }
    this.setState({ selected: newSelectedItemsList });
  };

  onDone = (): void => {
    const { selected } = this.state;
    const { onDone } = this.props;
    if (onDone) {
      onDone(Object.values(selected));
    }
  };

  getSectionName = (section: typeof searchSections[number]): string => {
    return this.props.t(
      "Container.Index." + section.charAt(0).toUpperCase() + section.slice(1)
    );
  };

  filterMembersInSectionWithQuery = (section: string, query: string) => (
    member: Member
  ): boolean => {
    const sectionName = section.toLowerCase();

    if (!(sectionName in member)) {
      return false;
    }

    const sectionMemberData = member[sectionName as keyof Member] as string;

    if (!sectionMemberData) {
      return false;
    }
    return sectionMemberData.toLowerCase().indexOf(query.toLowerCase()) > -1;
  };

  getSearchData = (query: string): MemberData[] => {
    const members: MemberData[] = [];
    const { data } = this.props;

    searchSections.forEach(section => {
      const name = this.getSectionName(section);

      if (name && data) {
        const items = data.filter(
          this.filterMembersInSectionWithQuery(name, query)
        );
        if (items.length > 0) {
          members.push({
            title: [name, " (", items.length, ")"].join(""),
            data: items,
          });
        }
      }
    });
    return members;
  };

  onChangeQuery = (query?: string): void => {
    this.setState(
      {
        query,
      },
      () => {
        if (!query || isEmpty(query)) {
          this.setState({
            searchedData: null,
          });
          !isEmpty(this.state.filterType) && this.onHandleFilter();
          return;
        } else {
          this.onChangeQueryDebounce();
        }
      }
    );
  };

  onChangeQueryDebounce = debounce(() => {
    const { query } = this.state;
    if (query && query.length > 2) {
      const updatedSearchData: MemberData[] = this.getSearchData(query);

      this.setState(
        {
          searchedData: updatedSearchData,
          filteredData: null,
        },
        () => {
          //automatically fetch more data and search
          this.onFetchMoreDataAndSearch();
        }
      );

      !isEmpty(this.state.filterType) && this.onHandleFilter(updatedSearchData);

      track("Search Members", {
        "Search term": query,
      });
    }
  }, 1000);

  onFetchMoreDataAndSearch = (): void => {
    const { searchedData, query } = this.state;
    const { loadAllMembersEnabled } = this.props;
    if (
      loadAllMembersEnabled &&
      !isEmpty(query) &&
      searchedData &&
      flatten(searchedData.map(item => item.data)).length < 3
    ) {
      this.fetchData({}, true, () => {
        this.onChangeQueryDebounce();
      });
    }
  };

  onFetchMoreDataAndFilter = (): void => {
    const { filteredData } = this.state;
    const { loadAllMembersEnabled } = this.props;
    if (
      loadAllMembersEnabled &&
      filteredData &&
      flatten(filteredData).length < 3
    ) {
      this.fetchData({}, true, () => {
        this.onHandleFilter();
      });
    }
  };

  onChangeFilterType = (type: string, value: string, filter: boolean): void => {
    this.setState(
      prev => ({
        filterType: { ...prev.filterType, [type]: value },
      }),
      () => {
        if (filter) {
          const { query, searchedData } = this.state;

          if (query && searchedData) {
            this.onHandleFilter(searchedData);
            return;
          }
          this.onHandleFilter();
        }
      }
    );
  };

  filterMembersDataByCategory = (value: string, type: string) => (
    member: Member
  ): boolean | "" => {
    const filteredValue: string | undefined | boolean =
      member[type.toLowerCase() as groupByType];

    if (filteredValue === undefined) {
      return false;
    }

    return filteredValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
  };

  deepCopy = (data: MemberData[] | undefined): MemberData[] | null => {
    if (!data) {
      return null;
    }
    const cloned: MemberData[] = [];
    data.forEach(item => {
      cloned.push({ title: item.title, data: [...item.data] });
    });
    return cloned;
  };

  onHandleFilter = (dataToFilter?: MemberData[]): void => {
    const { filterType } = this.state;
    const { data, filterSetting } = this.props;
    const { mainGroupData, blenderBoxEnabled } = filterSetting;
    let filteredData = data;
    let filteredDataFromParam: MemberData[] | null = this.deepCopy(
      dataToFilter
    );

    forEach(filterType, (value: string, type: string) => {
      let newValue = value;

      if (type === "secondary") {
        if (value === i18n.t("Member:Index.Text.My.Network")) {
          filteredData = this.onChangeFilterByFollowing(filteredData);
        } else {
          this.onChangeFilterByHomeGroup();
        }
        return;
      }

      //If blenderBoxEnabled, Member#industry has value id[],
      //so we need to find id of industry "type", replace to current "value" then continue a filtering.
      if (
        blenderBoxEnabled &&
        ["industry", "region"].includes(type.toLowerCase())
      ) {
        const industryGroup = mainGroupData.find(
          group => group.name.toLowerCase() === value.toLowerCase()
        );
        if (industryGroup) {
          newValue = industryGroup.objectId;
        }
      }

      if (filteredDataFromParam) {
        filteredDataFromParam = filteredDataFromParam.map(({ data }) => {
          return {
            data: data.filter(this.filterMembersDataByCategory(newValue, type)),
          };
        });
        return;
      }

      if (filteredData) {
        filteredData = filteredData.filter(
          this.filterMembersDataByCategory(newValue, type)
        );
      }
    });

    if (filteredDataFromParam) {
      this.setState({
        filteredData: uniq(
          flatten(filteredDataFromParam.map(({ data }) => data))
        ),
      });
      return;
    }
    this.setState(
      {
        filteredData,
        searchedData: null,
      },
      () => {
        //automatically fetch more data and filter
        this.onFetchMoreDataAndFilter();
      }
    );
  };

  onChangeFilterByFollowing = (dataFromFilterHandler?: Member[]): Member[] => {
    const { data } = this.props;
    let dataFilteredByIsFollowed = dataFromFilterHandler || data;

    if (data) {
      dataFilteredByIsFollowed = dataFilteredByIsFollowed.filter(
        member => member.followed
      );

      this.setState(
        {
          filteredData: dataFilteredByIsFollowed,
          searchedData: null,
          query: null,
        },
        () => {
          //automatically fetch more data and filter
          this.onFetchMoreDataAndFilter();
        }
      );
    }
    return dataFilteredByIsFollowed;
  };

  onChangeFilterByHomeGroup = (): void => {
    const { data, filterSetting } = this.props;
    const { homeGroupData } = filterSetting;
    /**
     * @TODO Please look at this
     * I replaced map Array method to map underscore function becauese
     * homeGroupData is Dict object, not an array.
     */
    // const groupNames = homeGroupData && homeGroupData.map(group => group.name);
    const groupNames = homeGroupData && map(homeGroupData, group => group.name);

    if (data && groupNames) {
      const filteredData = data.filter(member => {
        const groups =
          member.group && member.group.split(",").map(group => group.trim());
        return intersection(groupNames, groups).length > 0;
      });

      this.setState({ filteredData, searchedData: null, query: null }, () => {
        //automatically fetch more data and filter
        this.onFetchMoreDataAndFilter();
      });
    }
  };

  onClearFilters = (): void => {
    this.setState({
      query: null,
      filteredData: null,
      searchedData: null,
      filterType: {},
    });
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      data,
      setting,
      onClose,
      filterSetting,
      renderActions,
      renderActionsDone,
      loadAllMembersEnabled,
      scrollableRef,
      simpleMode,
      disableFilters,
      isMemberMixedLayout,
      showPlaceholder,
    } = this.props;

    const {
      selected,
      searchedData,
      filteredData,
      query,
      filterType,
      categoryFilters,
      tabs,
      loading,
      refreshing,
      error,
    } = this.state;

    const screenTabs = isEmpty(tabs) ? undefined : tabs;
    return (
      <Layout
        simpleMode={simpleMode ?? false}
        disableFilters={disableFilters ?? false}
        isMemberMixedLayout={isMemberMixedLayout}
        error={error}
        loading={loading}
        refreshing={refreshing}
        user={user}
        setting={setting}
        data={data}
        reFetch={this.fetchData}
        onSelect={this.onSelect}
        onDone={this.onDone}
        selected={selected}
        onClose={onClose}
        searchedData={searchedData}
        query={query}
        filterType={filterType}
        tabs={screenTabs}
        filteredData={filteredData}
        filterSetting={{ categoryFilters, ...filterSetting }}
        changeQuery={this.onChangeQuery}
        changeFilterType={this.onChangeFilterType}
        handleFilter={this.onHandleFilter}
        changeFilterByFollowing={this.onChangeFilterByFollowing}
        changeFilterByHomeGroup={this.onChangeFilterByHomeGroup}
        clearFilters={this.onClearFilters}
        renderActions={renderActions}
        renderActionsDone={renderActionsDone}
        loadAllMembersEnabled={loadAllMembersEnabled}
        showPlaceholder={showPlaceholder}
        scrollableRef={scrollableRef}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MembersOwnProps
): MembersStateProps => {
  let data = getMembersState(state);

  if (ownProps.filteredMemberIDs && data) {
    data = data.filter(member =>
      ownProps.filteredMemberIDs?.includes(member.id)
    );
  }

  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: data,
    showPlaceholder: showpMembersPlaceholderState(state),
    loadAllMembersEnabled: isLoadAllMembersEnabled(state),
    filterSetting: getMembersFilterSettingState(state),
  };
};

const mapDispatchToProps = {
  getMembers: getMembers,
  getGroupsByGroupTypeName: getGroupsByGroupTypeName,
};

const MemberContainer: React.ComponentType<MembersOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Member")(Members));

export default MemberContainer;
