import { isGif, getExtensionOfFile } from ".";

export const shouldResizeFile = (
  fileName: string,
  /** @FIXME That could be probably literal union string type */
  contentType?: string
): boolean => {
  if (contentType && isGif(contentType)) {
    return false;
  }

  const fileExtension = getExtensionOfFile(fileName);

  return (
    fileExtension.indexOf("png") > -1 ||
    fileExtension.indexOf("jpg") > -1 ||
    fileExtension.indexOf("jpeg") > -1
  );
};
