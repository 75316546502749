import { ReactionType, ReactionCount } from "../../../types";

/**
 * Computes new reactionCount object
 */

export const getNextReactionCount = <DataKey extends string>({
  newReaction,
  prevReaction,
  prevReactionCount = {},
}: {
  newReaction: ReactionType;
  prevReaction?: ReactionType;
  prevReactionCount?: ReactionCount;
}): ReactionCount => {
  if (prevReaction === newReaction) return prevReactionCount;

  const previousReactionCounter = prevReaction
    ? prevReactionCount[prevReaction] || 1
    : 1;

  const prevReactionKey = prevReaction !== "unlike" ? prevReaction : undefined;

  if (newReaction === "unlike" && prevReactionKey) {
    return {
      ...prevReactionCount,
      [prevReactionKey]: previousReactionCounter - 1,
    };
  }

  const newReactionPreviousCount = prevReactionCount?.[newReaction] || 0;

  if (prevReactionKey) {
    return {
      ...prevReactionCount,
      [prevReactionKey]: previousReactionCounter - 1,
      [newReaction]: newReactionPreviousCount + 1,
    };
  }

  return {
    ...prevReactionCount,
    [newReaction]: newReactionPreviousCount + 1,
  };
};
