import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Alert, Card } from "reactstrap";

import {
  RSVPData,
  PaymentOptions,
} from "../../../../containers/Event/containerTypes";
import MediaPlayerContainer from "../../../../containers/Media";

import {
  Dict,
  EventAttendeeAdditionalData,
  EventAttendeeGuest,
  EventAttendeeItem,
  EventInventory,
  EventItem,
  UserDataState,
  UserResponse,
} from "../../../../types";

import { Media } from "../../Media";
import { Pay } from "../../Payment";
import { ConfirmModal, LoadingSpinner } from "../../UI";

import { EventAttendeeEventInfo, EventAttendeeSelectOptions } from ".";
import { useHistory } from "react-router-dom";
import { isEmpty } from "underscore";

export type EventAttendeeProps = {
  error: string | null;
  info?: string;
  sending?: boolean;
  invoice: {
    dueDate: Parse.Object<Date>;
    id: string;
    objectId: string;
    parseId: string;
    description: string;
    total: number;
    isFeePayable: boolean | string;
  };
  user: UserDataState | { [key in keyof UserDataState]: undefined };
  setting: {
    eventChatId?: string | false;
    ClientHostName: string;
    rsvpEnabled: boolean;
    paymentEnabled: boolean;
    checkPayableTo: string;
    checkSendTo: string;
    invoiceFooterTitle: string;
    invoiceFooterBody: string;
    stripeCredentials: {
      account_id: string;
      publishable_key: string;
      account_registered: boolean;
    };
    googleApi: string;
    confettiEffectActions: [];
  };
  item?: EventItem;
  recap: {
    id?: string;
    description?: string;
    strapLine?: string;
    title?: string;
  };
  attendee: EventAttendeeAdditionalData;
  guest: {
    guestMembers: {
      firstName?: string;
      id: string;
      objectId?: string;
    }[];
    guests: string[];
  };
  partner: {
    partnerMembers: {
      objectId: string;
    }[];
    partners: (string | UserDataState)[] | null;
  };
  currentPartner: { fisrtName?: string; lastName: string };
  inventories: {
    [key: string]: EventInventory;
  } | null;
  hideChat: boolean;
  rsvpData: RSVPData;
  changeAttendee: <T extends keyof EventAttendeeAdditionalData>(
    name: T,
    value: EventAttendeeAdditionalData[T]
  ) => void;
  changeGuest: (adding: boolean, item: any) => void;
  changeInfo: (
    info: string,
    author1?: { author: string | UserDataState | undefined },
    author2?: { author: string | UserDataState | undefined }
  ) => void;
  changeOptionsAttendee: <T extends keyof EventAttendeeItem>(
    index: number,
    name: T,
    value: EventAttendeeItem[T]
  ) => void;
  changePartner: (partnerResponse: UserResponse) => void;
  getPaymentFee: (total: number) => Promise<unknown>;
  getPaymentOptions: (
    items: EventAttendeeItem[],
    inventories: Dict<EventInventory> | null
  ) => PaymentOptions;
  payForEvent: (
    payByCreditCard: boolean,
    token: string,
    callback: () => void,
    fallback: (error: string) => void
  ) => Promise<void>;
  updateAttendee: (callback: () => void) => Promise<void>;
};

/**
 * @FIXME Should be refreshing/reFetch implemented here?
 *
 * @TODO
 * Implement redirects
 * Split to smaller components
 */
export const EventAttendee: React.FC<EventAttendeeProps> = ({
  attendee,
  currentPartner,
  error,
  guest,
  info,
  inventories,
  item,
  partner,
  rsvpData,
  sending,
  setting,

  changeAttendee,
  changeGuest,
  changeInfo,
  changeOptionsAttendee,
  changePartner,
  getPaymentFee,
  getPaymentOptions,
  payForEvent,
  updateAttendee,
}) => {
  const { t } = useTranslation("Event");
  const history = useHistory();

  /** Modals related state */

  const isPayModalOpen = true;
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const togglePayModal = (): void => {
    if (isPayModalOpen) {
      changeAttendee("step", "options");
    }
  };
  if (!item) {
    return <LoadingSpinner />;
  }

  const toggleConfirmModal = (): void => {
    setConfirmModalOpen(!isConfirmModalOpen);
  };

  const save = (): void => {
    updateAttendee(() => {
      const { event } = item as EventItem;

      myAttendee && myAttendee.isWaiting
        ? toast.warning(t("Attendee.Toast.Placed.On.Waitlist"))
        : toast.success(t("Attendee.Toast.Submitted.Rsvp"));
      history.push(`/event-detail/${event.objectId}`);
    });
  };

  const handleAddMemberGuest = (
    guests: {
      id: string;
    }[]
  ): void => {
    if (!isEmpty(guests)) {
      changeGuest(true, guests[0]);
    }
  };

  const handleAddNonMemberGuest = (name: string): void => {
    changeGuest(true, name);
  };

  const handleRemoveGuest = (guest: EventAttendeeGuest): void => {
    changeGuest(false, guest);
  };

  const handleSubmit = (): void => {
    const { event } = item as EventItem;
    const { invoice, step } = attendee;
    const { hasPayment, totalFees, isAttended } = rsvpData;

    if (!step && (!isAttended || !hasPayment || (hasPayment && invoice))) {
      save();
    } else if (!step && hasPayment && isAttended) {
      changeAttendee("step", "options");

      const { preventAttending, items } = getPaymentOptions(
        event.items as EventAttendeeItem[],
        inventories
      );

      if (preventAttending) {
        toggleConfirmModal();
      } else {
        changeAttendee("items", items);
      }
    } else if (step === "options") {
      if (totalFees > 0) {
        changeAttendee("step", "payment");
      } else {
        save();
      }
    }
  };

  const onSubmit = (): void => {
    const { event } = item as EventItem;
    const { author } = event;
    const { hasPayment, totalFees, passInvoiceDueDate } = rsvpData;

    if (hasPayment && totalFees > 0 && passInvoiceDueDate) {
      changeInfo(t("Attendee.Warning4", { author }));
    } else {
      handleSubmit();
    }
  };

  const onPayForEvent = (
    payByCreditCard: boolean,
    token: string,
    callback: () => void
  ): void => {
    payForEvent(
      payByCreditCard,
      token,
      () => {
        callback && callback();
        toast.success(t("Attendee.Toast.Submitted.Rsvp"));
        history.push(`/event-detail/${event.objectId}`);
      },
      (error: any) => {
        toast.error(error, {
          onClose: () => toast.warning(t("Attendee.Toast.Pay.To.Complete")),
        });
        history.push(`/event-detail/${event.objectId}`);
      }
    );
  };

  const { event, myAttendee, willBeInWaitingList } = item;

  const { disableAttendees, author, video, thumbUrl } = event;

  const { isAttended, passAttendeeDeadline } = rsvpData;

  const { invoice, step } = attendee;

  /** This logic is duplicated in web and native. Probably could be moved to container */
  const [disabled, warning] =
    willBeInWaitingList && !isAttended
      ? [false, t("Attendee.Warning1")]
      : disableAttendees || (isAttended && passAttendeeDeadline)
      ? [true, t("Attendee.Warning2", { author })]
      : invoice
      ? [true, t("Attendee.Warning3")]
      : [false, undefined];

  const paymentModal = step === "payment" && (
    <Pay
      setting={setting}
      invoice={{
        total: rsvpData.totalFees,
        isFeePayable: item.event.isFeePayable,
        parseId: item.event.id,
        dueDate: item.event.invoiceDueDate,
      }}
      getPaymentFee={getPaymentFee}
      onProcess={onPayForEvent}
      isPayModalOpen={isPayModalOpen}
      togglePayModal={togglePayModal}
    />
  );
  const content =
    step === "options" || step === "payment" ? (
      <EventAttendeeSelectOptions
        items={attendee.items}
        sending={sending}
        title={item.event.title}
        totalFees={rsvpData.totalFees}
        onChangeOptionsAttendee={changeOptionsAttendee}
        onSubmit={onSubmit}
      />
    ) : (
      <EventAttendeeEventInfo
        {...{
          attendee,
          currentPartner,
          event,
          guest,
          isAttended,
          myAttendee,
          partner,
          passAttendeeDeadline,
          rsvpData,
          sending,
          willBeInWaitingList,

          addMemberGuest: handleAddMemberGuest,
          addNonMemberGuest: handleAddNonMemberGuest,
          changeAttendee,
          changePartner,
          onSubmit,
          removeGuest: handleRemoveGuest,
        }}
      />
    );
  const confirmModal = (
    <ConfirmModal
      color={"warning"}
      confirmText={t("Attendee.Button.Ok")}
      title={t("Attendee.Title.Alert")}
      message={t("Attendee.Message.Items.Have.Sold.Out")}
      isOpen={isConfirmModalOpen}
      onConfirm={(): void => {
        toggleConfirmModal();
        history.goBack();
      }}
    />
  );

  return (
    <>
      <Card id="evenAttendeeCard" className="my-2">
        <MediaPlayerContainer
          video={video}
          pauseWhenInvisible={true}
          Layout={Media}
          image={{ thumbUrl }}
          alt={item.event.title}
          resizeMode="cover"
        />
        {!!error && <Alert color="danger">{error}</Alert>}
        {!!warning && <Alert type="warning">{warning}</Alert>}
        {!!info && <Alert type="info">{info}</Alert>}
        {!disabled && content}
        {paymentModal}
      </Card>
      {confirmModal}
    </>
  );
};
