import React, { ChangeEvent } from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import {
  Callback,
  EmptyObject,
  Group,
  SettingState,
  UserDataState,
} from "../../../../types";

import { SearchBox, InfiniteScroll } from "../..";

import { GroupCard, GroupCardPlaceholder } from "..";

export type GroupPostingGroupsProps = {
  data: Group[] | null;
  error: Error | null;
  query?: string;
  refreshing: boolean;
  setting: SettingState | EmptyObject;
  user: UserDataState | EmptyObject;

  changeQuery: (query: string) => void;
  onPress: (item: Group) => void;
  reFetch: (refresh: boolean, callback?: Callback) => void;
};

export const GroupPostingGroups: React.FC<GroupPostingGroupsProps> = ({
  data,
  error,
  refreshing: isRefreshing,
  query,

  changeQuery,
  onPress: onClick,
}) => {
  const { t } = useTranslation("Group");

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>): void => {
    changeQuery(event.target.value);
  };

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  /**
   * Filtering by query inside <GroupCard /> prevents displaying <Empty />
   * because data/content real length is never equal to 0. Placeholder is
   * now displayed only before first fetch. I think it should be filtered
   * in container instead.
   */
  const content =
    data?.map(item => (
      <GroupCard
        id={`groupChooseGroup${item.name.replace(/\s/g, "")}`}
        key={item.id}
        item={item}
        onClick={onClick}
        query={query}
        disablePressDetail={true}
        className="simple-mode"
      />
    )) || null;

  return (
    <>
      <SearchBox
        className="mb-3 border"
        placeholder={t("PostingGroups.Placeholder.Search")}
        onValueChange={handleChangeQuery}
        value={query}
      />
      {errorElement}
      <InfiniteScroll
        isLoading={false}
        isRefreshing={isRefreshing}
        placeholder={<GroupCardPlaceholder />}>
        {content}
      </InfiniteScroll>
    </>
  );
};
