import { adminGet } from "../../http";

export type ExportMembersResult = {
  export_id?: string;
  status?: string;
  progress?: number;
  url?: string;
  fileName?: string;
  processing?: boolean;
  completed?: boolean;
};

export const exportMembers = async (
  exportId: string | undefined,
  config: { sessionToken: string; adminURL: string }
): Promise<ExportMembersResult> => {
  console.debug("[Service] exportMembers", { exportId, config });
  // eslint-disable-next-line @typescript-eslint/camelcase
  const params = exportId ? { export_id: exportId } : {};
  return adminGet("users/export", params, { requireUser: true, config });
};
