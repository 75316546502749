import React, { SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button, ButtonGroup, Form, FormGroup, Input } from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";

import { UserLoginDataSet, UserLoginStateData } from "../../../types";

import { useTheme } from "../../hooks";

import { SupportButtonWModal } from "../Support";

import { UserRegisterBlock } from ".";

export type UserLoginProps = {
  data: UserLoginStateData;
  error: string | null | undefined;
  loading: boolean;
  setting: {
    organizationName: string;
    logo?: string;
    signInByEmail?: boolean;
    signUpEnabled?: boolean;
    InMultiTenant?: boolean;
  };

  handleChange: (name: UserLoginDataSet, val: string) => void;
  logIn: (callback?: () => void) => Promise<void>;
  resetSetting?: (() => Promise<unknown>) | false;
  resetPassCode: (callback?: (text: string) => void) => boolean | void;
  resetPassword: (callback?: (text: string) => void) => boolean | void;
  updatePassword: (callback?: (text: string) => void) => boolean | void;
};

export const UserLogin: React.FC<UserLoginProps> = ({
  data: { phone, email, passwordText },
  error,
  loading,
  setting: { signInByEmail, signUpEnabled },

  handleChange,
  logIn,
  resetPassCode,
  resetSetting,
}) => {
  /**
   * @TODO
   * Default component styles, copied from native/components/User/styles
   * Needs refactor to keep consistency between web and mobile apps
   */
  const userLoginStyles = {
    text: {
      color: "#fff",
      textAlign: "center",
    },
    input: {
      paddingRight: 15,
      paddingLeft: 15,
      height: 45,
    },
    item: {
      borderBottomWidth: 0,
      marginLeft: 0,
      backgroundColor: "#fff",
      borderRadius: 10,
    },
    mainButton: {
      height: 50,
    },
  } as const;

  const history = useHistory();

  const {
    login: { button: loginButtonStyle },
    text: { listTitle: listTitleStyle },
  } = useTheme();

  const { t } = useTranslation("User");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(e.target.name as UserLoginDataSet, e.target.value);
  };

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();

    logIn();
  };

  const handleResetPassCode = (): void => {
    resetPassCode(toast.success);
  };

  const handleResetSetting = (): void => {
    resetSetting && resetSetting();
    history.replace("/");
  };

  const redirectToForgotPassword = (): void => history.push("/forgot-password");

  /**
   * @TODO
   *
   * This translation is terrible (generally it comes from native/components/User/Login
   * but I temporarily added one extra condition following existing rules).
   * Translating parts of splitted message looks like antipattern. Then
   * condition is based on `signInByEmail` value and returns translated
   * or hardcoded value. Then we are transforming passwordText value comming
   * from containers/User/Login to lowercase - this is only once set
   * containers internal state property containing translated passphrase name
   * value while component is mounting and is conditioned on... `signInByEmail`
   *
   * There should be two translation keys for two possible signInByEmail setting
   * values. Then passwordText props will be PROBABLY not necessary anymore and
   * could be removed from containers state as well.
   */
  const instructionText = `${t("Login.Heading.Please")} ${
    !resetSetting
      ? `${signInByEmail ? "email" : t("Login.Phone.Number").toLowerCase()} ${t(
          "Login.Heading.And"
        )} `
      : ""
  }${passwordText.toLowerCase()} ${t("Login.Heading.To.Login")}`;

  const instructionElement = (
    <div
      className="mb-3"
      style={{
        ...{
          ...listTitleStyle,

          /** @TODO Theming workaround. Delete when issue solved. */
          ...textThemingWorkaround,
        },
        ...userLoginStyles.text,
      }}>
      {instructionText}
    </div>
  );

  const errorAlertElement = error && (
    <Alert className="mb-2 text-center" color="danger">
      {error}
    </Alert>
  );

  const inputStyle = {
    ...userLoginStyles.item,
    ...userLoginStyles.input,
  };

  const identityInputElement = !signInByEmail ? (
    /**
     * @TODO Implement missing CountryPicker for handling
     * countryCode and phone change.
     */

    <Input
      /**
       * @TODO
       * There will be probably  need for ref implementation
       * for focusing this input on CountryPicker change.
       */
      type="tel"
      name="phone"
      id="PhoneNumber"
      placeholder={t("Login.Phone.Number")}
      disabled={loading}
      value={phone}
      style={inputStyle}
      onChange={handleInputChange}
    />
  ) : (
    <Input
      type="email"
      name="email"
      id="Email"
      placeholder={t("Login.Placeholder.Email")}
      disabled={loading}
      value={email}
      style={inputStyle}
      onChange={handleInputChange}
    />
  );

  const signInButtonElement = (
    <Button
      block
      id="signInButton"
      color="primary"
      /**
       * @TODO
       * This button should be disabled also if form is not valid.
       * Now there is no form input values validation implemented.
       * Probably it should be done in container layer. I suggest we
       * could consider yup validation library which we know from projects
       * where forms were based on Formik.
       */
      disabled={loading}
      type="submit"
      style={{
        /**
         * @TODO
         * Following object probably contain a lot of not CSS related data
         * and needs refactor during theming standarization.
         */
        ...loginButtonStyle,
        ...userLoginStyles.mainButton,
        border: 0,
        ...{
          /** @TODO Theming workaround. Delete when issue solved. */
          textAlign: undefined,
        },

        /** @TODO Theming workaround. Delete when issue solved. */
        ...textThemingWorkaround,
      }}
      onClick={handleSubmit}>
      {loading ? t("Login.Button.Loading") : t("Login.Button.Sign.In")}
    </Button>
  );

  const registerBlockElement = signUpEnabled && <UserRegisterBlock />;

  const buttonLinkStyle = {
    ...{
      /** @TODO Theming workaround. Delete when issue solved. */
      ...textThemingWorkaround,

      /** @TODO Theming workaround. Delete when issue solved. */
      textAlign: undefined,
    },
    color: "#fff",
  };

  const forgotPasswordButtonElement = (
    <Button
      color="link"
      id={signInByEmail ? "forgotPasswordButton" : "requestNewPasscodeButton"}
      style={buttonLinkStyle}
      onClick={signInByEmail ? redirectToForgotPassword : handleResetPassCode}>
      {signInByEmail
        ? t("Login.Forgot.Password")
        : t("Login.Forgot.New.Passcode")}
    </Button>
  );

  const resetSettingButtonElements = resetSetting && (
    <>
      {/** @TODO Missing translation */}
      <p className="text-center text-white">{"or"}</p>
      <Button
        block
        className="mb-2"
        color="link"
        disabled={loading}
        id="chooseAnotherCommunityButton"
        style={buttonLinkStyle}
        onClick={handleResetSetting}>
        <i className="icon-logout mr-3 " />
        {t("Login.Choose.Another.Community")}
      </Button>
    </>
  );

  return (
    <>
      <Form className="mb-1" onSubmit={handleSubmit}>
        {instructionElement}

        {errorAlertElement}

        <FormGroup>{identityInputElement}</FormGroup>
        <FormGroup>
          <Input
            type="password"
            name="password"
            id="passwordInput"
            disabled={loading}
            placeholder={passwordText}
            style={inputStyle}
            onChange={handleInputChange}
          />
        </FormGroup>
        {signInButtonElement}
      </Form>

      {resetSettingButtonElements}

      {registerBlockElement}

      <ButtonGroup className="w-100">
        {forgotPasswordButtonElement}
        <SupportButtonWModal />
      </ButtonGroup>
    </>
  );
};
