import React from "react";

import { Modal } from "reactstrap";

import MembersContainer from "../../../../containers/Member";

import { MemberPicker } from "../../Member/MemberPicker";

export type EventAttendeeGuestsAddMemberModalProps = {
  isOpen: boolean;

  toggle: () => void;
  onSelectMember: (members: { id: string }[]) => void;
};

export const EventAttendeeGuestsAddMemberModal: React.FC<EventAttendeeGuestsAddMemberModalProps> = ({
  isOpen,

  onSelectMember,
  toggle,
}) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <MembersContainer
      Layout={MemberPicker}
      onClose={toggle}
      onDone={onSelectMember}
      multiple={false}
    />
  </Modal>
);
