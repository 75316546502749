import React from "react";

import { ViewStyle } from "react-native";
import { connect } from "react-redux";
import { isEmpty } from "underscore";

import { track } from "../../lib/track";
import { getStateWhenGoback } from "../../lib/utils/checkGoBack";

import {
  AnnouncementItem,
  AnnouncementType,
  CommentRequest,
  CommentType,
  EmptyObject,
  MatchProps,
  ReactionType,
  RootState,
  UserDataState,
} from "../../types";

import { addComment, doReaction } from "../Comment/action";

import { getAnnouncements } from "./action";
import {
  getAnnouncementsState,
  getHasMoreAnnouncementsState,
  getPinAnnouncementsState,
  getAnnouncementTypesState,
  getAnnouncementsSettingState,
} from "./selector";
import { getCurrentUserState } from "../User/selector";

export type AnnouncementsLayoutProps = {
  announcementType: string | null;
  announcementTypes: AnnouncementType[];
  data: AnnouncementItem[] | null;
  hasMore?: boolean;
  error: null | string;
  hideFilter?: boolean;
  loading?: boolean;
  mediaSetting: {} | null;
  pins: AnnouncementItem[] | null;
  refreshing: boolean;
  renderActions?: boolean;
  renderActionsDone?: boolean;
  setting: {
    announcementLabel?: string;
    ClientHostName: string;
    confettiEffectActions: [];
  };
  style?: ViewStyle;
  user: UserDataState | EmptyObject;

  changeAnnouncementType: (
    announcementType: AnnouncementType,
    options?: { forceListReset?: boolean }
  ) => void;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  reFetch: (
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean
  ) => void;
  /** Is it native specific prop? */
  onScroll?: () => void;
  addComment: (
    id: string,
    type: CommentType,
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void
  ) => Promise<void>;
};

export type AnnouncementsOwnProps = {
  Layout: React.ComponentType<AnnouncementsLayoutProps>;
  displayPins?: boolean;
  hideFilter?: boolean;
  style?: ViewStyle;
  onScroll?: () => void;
  renderActions?: boolean;
  renderActionsDone?: boolean;
} & MatchProps;

export type AnnouncementsStateProps = {
  announcementTypes: AnnouncementType[];
  data: AnnouncementItem[] | null;
  hasMore: boolean;
  displayPins: boolean;
  pins: AnnouncementItem[] | null;
  setting: {
    announcementLabel?: string;
    confettiEffectActions: [];
    ClientHostName: string;
  };
  user: UserDataState | EmptyObject;
};

export type AnnouncementsDispatchProps = {
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  getAnnouncements: (
    options: {},
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean
  ) => Promise<void>;
  addComment: (
    id: string,
    type: CommentType,
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void
  ) => Promise<void>;
};

export type AnnouncementsProps = AnnouncementsOwnProps &
  AnnouncementsStateProps &
  AnnouncementsDispatchProps;

export type AnnouncementsState = {
  announcementType: string | null;
  error: null | string;
  loading: boolean;
  mediaSetting: {} | null;
  refreshing: boolean;
};

class Announcements extends React.Component<
  AnnouncementsProps,
  AnnouncementsState
> {
  state: AnnouncementsState = {
    error: null,
    loading: false,
    refreshing: false,
    announcementType: null,
    mediaSetting: null,
  };

  async componentDidMount(): Promise<void> {
    const lastState = await getStateWhenGoback();

    if (!["feed", "home"].includes(lastState)) {
      this.fetchData(false, true);
    }

    track("View Screen", {
      Screen: "feed",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean
  ): void => {
    const { getAnnouncements, data } = this.props;
    const { refreshing, loading, announcementType } = this.state;

    if (refreshing || loading) {
      return;
    }

    this.setState(
      { refreshing: refresh && !isEmpty(data), loading: next },
      async () => {
        try {
          await getAnnouncements(
            {
              categoryId: announcementType || undefined,
            },
            next,
            refresh,
            refreshWhenReactive
          );
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  onChangeAnnouncementType = (
    { id, name }: AnnouncementType,
    options?: { forceListReset?: boolean }
  ): void => {
    this.setState({ announcementType: id }, () => {
      this.fetchData(false, true, options?.forceListReset);
    });

    track("Filter Announcements", {
      CategoryId: id,
      CategoryName: name,
    });
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      data,
      hasMore,
      setting,
      doReaction,
      pins,
      announcementTypes,
      hideFilter,
      style,
      onScroll,
      renderActions,
      renderActionsDone,
      addComment,
    } = this.props;
    const {
      loading,
      error,
      refreshing,
      mediaSetting,
      announcementType,
    } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        pins={pins}
        reFetch={this.fetchData}
        doReaction={doReaction}
        mediaSetting={mediaSetting}
        announcementTypes={announcementTypes}
        announcementType={announcementType}
        changeAnnouncementType={this.onChangeAnnouncementType}
        hideFilter={hideFilter}
        style={style}
        onScroll={onScroll}
        renderActions={renderActions}
        renderActionsDone={renderActionsDone}
        addComment={addComment}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: AnnouncementsOwnProps
): AnnouncementsStateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getAnnouncementsSettingState(state),
    data: getAnnouncementsState(state),
    hasMore: getHasMoreAnnouncementsState(state),
    announcementTypes: getAnnouncementTypesState(state),
    displayPins: ownProps.displayPins !== false,
    pins: getPinAnnouncementsState(state),
  };
};

const mapDispatchToProps = {
  getAnnouncements,
  doReaction,
  addComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
