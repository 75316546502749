import { runQuery } from "../../http";
import { convertObject } from "../../../lib/utils";
import { EventRecap } from "../../../types/Event/EventRecap";

export const getEventRecap = async (
  eventId: string
): Promise<EventRecap | null> => {
  console.debug("[Service] getEventRecap", { eventId });

  const event = new (Parse.Object.extend("Event"))({ id: eventId });
  const parseResponse = await runQuery(
    "EventRecap",
    function(query: Parse.Query) {
      query.equalTo("event", event);
      return query.first();
    },
    { requireUser: true }
  );
  return convertObject(parseResponse);
};
