import React from "react";

import { Alert } from "reactstrap";

import { VideoListLayout } from "../../../containers/VideoLibrary";

import { InfiniteScroll, VideoCard } from "..";

import { VideoCardPlaceholder } from ".";

export const VideoList: React.FC<VideoListLayout> = ({
  error,
  reFetch,
  data,
  hasMore,
  loading,
  refreshing,
}) => {
  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const errorElement = error && <Alert color="warning">{error}</Alert>;

  const cardElements =
    data?.map(item => <VideoCard key={item.id} item={item} />) || null;

  return (
    <div>
      {errorElement}
      <InfiniteScroll
        hasMore={!!hasMore}
        isLoading={loading}
        isRefreshing={refreshing}
        onLoadMore={handleLoadMore}
        placeholder={<VideoCardPlaceholder />}>
        {cardElements}
      </InfiniteScroll>
    </div>
  );
};
