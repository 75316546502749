import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Button,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";

import { EventAttendeeGuest } from "../../../../types";

import {
  EventAttendeeGuestsAddMemberModal,
  EventAttendeeGuestsAddNonMemberModal,
} from ".";
import { useTheme } from "../../../hooks";
import { textThemingWorkaround } from "../../../../constants/Theme";
import { Heading } from "../../UI";

export type EventAttendeeGuestsEditModalProps = {
  allGuest: EventAttendeeGuest[] | null;
  isOpen: boolean;
  maxGuestCount: number;

  addMemberGuest: (members: { id: string }[]) => void;
  addNonMemberGuest: (name: string) => void;
  removeGuest: (guest: EventAttendeeGuest) => void;
  toggle: () => void;
};

export const EventAttendeeGuestsEditModal: React.FC<EventAttendeeGuestsEditModalProps> = ({
  allGuest,
  isOpen,
  maxGuestCount,

  addMemberGuest,
  addNonMemberGuest,
  removeGuest,
  toggle,
}) => {
  const { t } = useTranslation("Event");

  const {
    button,
    text: { header },
  } = useTheme();

  const eventAttendeeGuestsEditModalStyles = {
    modalHeading: {
      ...header,
      ...textThemingWorkaround,
    },
    addButton: {
      ...button,
      height: 40,
    },
    doneButtonText: {
      ...button.text,
      ...textThemingWorkaround,
      textAlign: undefined,
      fontSize: 16,
    },
  };

  const [isNonMemberGuestModalOpen, setNonMemberGuestModalOpen] = useState(
    false
  );

  const toggleNonMemberGuestModal = (): void => {
    setNonMemberGuestModalOpen(!isNonMemberGuestModalOpen);
  };

  const [isMemberGuestModalOpen, setMemberGuestModalOpen] = useState(false);

  const toggleMemberGuestModal = (): void => {
    setMemberGuestModalOpen(!isMemberGuestModalOpen);
  };

  const handleAddMemberGuest = (
    selectedMembers: {
      id: string;
    }[]
  ): void => {
    addMemberGuest(selectedMembers);
    toggleMemberGuestModal();
  };

  const handleAddNonMemberGuset = (guestName: string): void => {
    addNonMemberGuest(guestName);
    toggleNonMemberGuestModal();
  };

  const guestsList = (
    <ListGroup>
      <EventAttendeeGuestsAddMemberModal
        isOpen={isMemberGuestModalOpen}
        toggle={toggleMemberGuestModal}
        onSelectMember={handleAddMemberGuest}
      />
      <EventAttendeeGuestsAddNonMemberModal
        isOpen={isNonMemberGuestModalOpen}
        toggle={toggleNonMemberGuestModal}
        onSave={handleAddNonMemberGuset}
      />
      {allGuest?.map((guest, index) => (
        <ListGroupItem
          className="d-flex"
          key={index}
          tag={"button"}
          action={true}
          onClick={(): void => {
            removeGuest(guest);
          }}>
          {`${guest.firstName} ${guest.lastName ?? ""}`}
          <i className="icon-minus ml-auto mt-1" />
        </ListGroupItem>
      ))}
      {((allGuest && allGuest.length) || 0) < maxGuestCount &&
        Array(maxGuestCount - ((allGuest && allGuest.length) || 0))
          .fill({})
          .map((item, index) => (
            <div key={index}>
              <UncontrolledDropdown>
                <ListGroupItem
                  id={`eventAttendeeAddGuestDropdown${index}`}
                  tag={DropdownToggle}
                  action={true}
                  className="d-flex">
                  {t("Attendee.Text.Add.Guest")}
                  <i className="icon-plus ml-auto mt-1" />
                </ListGroupItem>
                <DropdownMenu>
                  <DropdownItem
                    id="eventAttendeeAddGuestFromDirectory"
                    onClick={toggleMemberGuestModal}>
                    {t("Attendee.Option.Directory")}
                  </DropdownItem>
                  <DropdownItem
                    id="eventAttendeeAddGuestNonMember"
                    onClick={toggleNonMemberGuestModal}>
                    {t("Attendee.Option.Non-member")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ))}
    </ListGroup>
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Heading
          style={eventAttendeeGuestsEditModalStyles.modalHeading}
          text={t("Attendee.Additional.Guests")}
        />
      </ModalHeader>
      <CardBody>{guestsList}</CardBody>
      <ModalFooter>
        <Button
          style={eventAttendeeGuestsEditModalStyles.addButton}
          color="primary"
          onClick={toggle}>
          <span style={eventAttendeeGuestsEditModalStyles.doneButtonText}>
            {t("Attendee.Button.Done")}
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
