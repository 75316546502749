import { adminGet } from "../../http";
import { Config } from "../../../types";
import { Products } from "../../../types/Subscription";
import camelcaseKeys from "camelcase-keys";

export const getProducts = async (config: Config): Promise<Products> => {
  console.debug("[Service] getProducts");

  const resp = await adminGet(
    "products/list",
    {},
    {
      requireUser: true,
      config,
    }
  );
  return camelcaseKeys(resp, { deep: true });
};
