import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import {
  MatchProps,
  NotificationItem,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";

import { getNotifications, readAll } from "./action";
import { getStateWhenGoback } from "../../lib/utils/checkGoBack";

type StateProps = {
  user: UserDataState | {};
  setting: SettingState | {};
  unread: number;
  data: NotificationItem[] | null;
  hasMore: boolean;
};

type DispatchProps = {
  getNotifications: (next: boolean, refresh: boolean) => Promise<void>;
  readAll: () => Promise<boolean>;
};

export type LayoutNotificationList = {
  error?: string | null;
  loading: boolean;
  sending: boolean;
  refreshing: boolean;
  user: UserDataState | {};
  setting: SettingState | {};
  unread: number;
  data: NotificationItem[] | null;
  hasMore: boolean;
  reFetch: (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ) => void | boolean;
  readAll: (callback: (text: string) => void) => void;
};

type Props = {
  // @TODO add typing when typing components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Layout: ComponentType<LayoutNotificationList>;
} & StateProps &
  DispatchProps &
  MatchProps &
  WithTranslation;

type State = {
  error: string | null;
  loading: boolean;
  sending: boolean;
  refreshing: boolean;
};

class Notifications extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    sending: false,
    refreshing: false,
  };

  async componentDidMount(): Promise<void> {
    const lastState = await getStateWhenGoback();

    if (!["notifications"].includes(lastState)) {
      this.fetchData(false, true);
    }

    track("View Screen", {
      Screen: "notifications",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    callback?: () => void
  ): void => {
    const { getNotifications } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        await getNotifications(next, refresh);
        this.setState({
          loading: false,
          refreshing: false,
          error: null,
        });
        callback?.();
      } catch (error) {
        this.setState({ loading: false, refreshing: false, error: error });
      }
    });
  };

  onReadAll = (callback: (text: string) => void): boolean | void => {
    const { readAll, unread } = this.props;
    const { sending } = this.state;
    if (sending) {
      return false;
    }

    this.setState({ sending: true }, async () => {
      try {
        await readAll();
        this.setState({ sending: false, error: null });
        callback &&
          callback(
            `${this.props.t(
              "Container.Index.Cleared"
            )} ${unread} ${this.props.t("Container.Index.Notification")}`
          );
      } catch (error) {
        this.setState({ sending: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, user, data, setting, unread, hasMore } = this.props;
    const { loading, error, refreshing, sending } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        sending={sending}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        unread={unread}
        reFetch={this.fetchData}
        readAll={this.onReadAll}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  const { list } = state.notification;
  const { hasMore, items } = list;
  return {
    user: state.user.data || {},
    setting: state.setting || {},
    unread: state.notification.unread.notifications
      ? state.notification.unread.notifications.length
      : 0,
    data: items && Object.values(items),
    hasMore,
  };
};

const mapDispatchToProps = {
  getNotifications: getNotifications,
  readAll: readAll,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Notification")(Notifications));
