import { adminPost } from "../../http";

export const getPaymentFee = async (
  total: number,
  config: {
    sessionToken: string;
    adminURL: string;
  }
): Promise<unknown> => {
  return adminPost(
    "payments/fees",
    { total: total },
    { requireUser: true, config }
  );
};
