import { convertObject } from "../../../lib/utils";
import { Dict, Group } from "../../../types";

import { runFunction } from "../../http";
import _ from "underscore";

export const getSuggestedPostingGroups = async (
  quantity: number
): Promise<Dict<Group>> => {
  console.debug("[Service] getSuggestedPostingGroups");
  const response = await runFunction(
    "defaultGroupsForPosting",
    {
      quantity,
    },
    { requireUser: true }
  );

  return _.object(
    _.map(response, (value: Group) => [value.id, convertObject(value)])
  );
};
