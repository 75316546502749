import React from "react";

import { RouteComponentProps } from "react-router-dom";

import GroupMixedContainer from "../../../containers/Group/Mixed";

import { GroupMixed } from "../../components/Group";

export type RouteGroupMixedProps = {} & RouteComponentProps;

export const RouteGroupMixed: React.FC<RouteGroupMixedProps> = props => (
  <GroupMixedContainer {...props} Layout={GroupMixed} />
);
