import React from "react";

import { RouteComponentProps } from "react-router-dom";

import EventDetailContainer from "../../../containers/Event/Detail";

import { EventDetail } from "../../components/Event";

export type RouteEventDetailProps = {} & RouteComponentProps;

export const RouteEventDetail: React.FC<RouteEventDetailProps> = props => (
  <EventDetailContainer
    {...props}
    Layout={EventDetail}
    forceRefreshOnMount={true}
  />
);
