import _ from "underscore";

import { logException } from "../../lib/utils";

import {
  createGroup as _createGroup,
  getGroup as _getGroup,
  getGroupMemberSetting as _getGroupMemberSetting,
  getGroupTypes as _getGroupTypes,
  getGroups as _getGroups,
  getGroupsByGroupTypeName as _getGroupsByGroupTypeName,
  getUserGroups as _getUserGroups,
  joinGroup as _joinGroup,
  leaveGroup as _leaveGroup,
  removeGroup as _removeGroup,
  saveGroupMemberSetting as _saveGroupMemberSetting,
  updateGroup as _updateGroup,
  getSuggestedPostingGroups as _getSuggestedPostingGroups,
  getGroupHome as _getGroupHome,
} from "../../services/api";

import {
  GroupByStatusTab,
  GroupByTypeTab,
  GroupTypeData,
  GroupStatusData,
} from "./types";
import {
  AppThunkAction,
  AppThunkDispatch,
  Dict,
  GetState,
  Group,
  GroupType,
  ProgressCallback,
  SettingConfigData,
  UserDataState,
  SettingConfigGroupType,
} from "../../types";

import { NOTIFICATION_UPLOAD_SET } from "../Notification/constants";

import {
  GROUPS_BY_TYPE_SET,
  GROUP_ALL_SET,
  GROUP_MANAGEMENT_SET,
  GROUP_MY_SET,
  GROUP_PENDING_SET,
  GROUP_POSTING_SET,
  GROUP_SET,
  GROUP_SETTING_SET,
  GROUP_STATUS_SET,
  GROUP_TYPES_SET,
  GROUP_TYPE_SET,
  GROUP_USER_SET,
  GROUP_SUGGESTED_POSTING_SET,
  GROUP_TARGET_SET,
  GROUP_HOME_SET,
} from "./constants";
import i18n from "../../middlewares/i18next";

export type GetUserGroupsActionReturn = {
  data: {
    userId: string;
    data: Dict<Group>;
  };
};

/**
 * Get joined groups of particular user
 * @param userId
 * @param refresh
 */
export function getUserGroups(
  userId: string,
  refresh?: boolean
): AppThunkAction<GetUserGroupsActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetUserGroupsActionReturn> => {
    try {
      console.debug("[Action] getUserGroups");

      const { group } = getState();

      const cachedData = group.users && group.users[userId];

      if (cachedData && !refresh) {
        return {
          data: { userId, data: cachedData },
        };
      }

      const data = await _getUserGroups(userId, false, false);

      dispatch({
        type: GROUP_USER_SET,
        data: { userId, data },
      });

      return {
        data: { userId, data },
      };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

/**
 * Get my groups (joined groups)
 * @param refresh
 */
export const getMyGroups = (refresh: boolean): AppThunkAction<void> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] getMyGroups");

    const { group, user } = getState();
    const { id } = user.data as UserDataState;

    const { myGroups } = group;

    if (myGroups && !refresh) {
      return;
    }

    //dispatch getUserGroups to get groups of user from `users` if have
    //otherwise we'll fetch from DB
    const resp = await dispatch(getUserGroups(id, refresh));

    if (resp) {
      dispatch({
        type: GROUP_MY_SET,
        data: resp.data.data,
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get available groups for posting
 * @param refresh
 */
export const getGroupsForPosting = (
  refresh?: boolean
): AppThunkAction<void> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] getGroupsForPosting");

    const { group, user } = getState();
    const { id } = user.data as UserDataState;
    const { groupsForPosting } = group;

    if (groupsForPosting && !refresh) {
      return;
    }

    const data = await _getUserGroups(id, false, true, {
      filterFn: ({ hasGroupFeed, permission }) => {
        return hasGroupFeed && (user.data?.admin || permission !== "admins");
      },
    });

    dispatch({
      type: GROUP_POSTING_SET,
      data: data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getGroupsForTarget = (
  refresh?: boolean
): AppThunkAction<void> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] getGroupsForPosting");

    const { group, user } = getState();
    const { id } = user.data as UserDataState;
    const { groupsForTarget } = group;

    if (groupsForTarget && !refresh) {
      return;
    }

    const data = await _getUserGroups(id, false, true, {});

    dispatch({
      type: GROUP_TARGET_SET,
      data: data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get suggested groups for posting
 * @param refresh
 */
export const getSuggestedPostingGroups = (): AppThunkAction<void> => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  try {
    console.debug("[Action] getSuggestedPostingGroups");

    const data = await _getSuggestedPostingGroups(6);

    dispatch({
      type: GROUP_SUGGESTED_POSTING_SET,
      data: data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export type GetAllGroupsActionReturn = { data?: Dict<Group> };
/**
 * Get all groups in community
 * @param refresh
 */
export function getAllGroups(
  refresh?: boolean
): AppThunkAction<GetAllGroupsActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetAllGroupsActionReturn> => {
    try {
      console.debug("[Action] getAllGroups");

      const { group } = getState();
      const { items } = group;
      if (items && !refresh) {
        return { data: items };
      }

      const data = await _getGroups(
        {
          filter: "all",
          format: "flat",
          groupTypeId: "all",
        },
        { convertToDict: true }
      );

      dispatch({
        type: GROUP_ALL_SET,
        data: data,
      });

      return {
        data: data,
      };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type GetPendingGroupsActionReturn = { data: Dict<Group> };
/**
 * Get pendding groups (sent request but is not approved yet)
 */
export function getPendingGroups(
  refresh: boolean
): AppThunkAction<GetPendingGroupsActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetPendingGroupsActionReturn> => {
    try {
      console.debug("[Action] getPendingGroups");

      const { group } = getState();
      const { pending } = group;

      if (pending && !_.isEmpty(pending) && !refresh) {
        return { data: pending };
      }

      const data = await _getGroups(
        {
          filter: "pending",
          format: "flat",
          groupTypeId: "all",
        },
        { convertToDict: true }
      );

      dispatch({
        type: GROUP_PENDING_SET,
        data: data,
      });

      return { data: data };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

/**
 * Get groups then put them into group types which was set in config "blenderboxPostGroups"
 * @param refresh
 */
export function getGroupsByType(refresh: boolean): AppThunkAction<void> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<void> => {
    console.debug("[Action] getGroupsByType");

    try {
      const { setting, user, group } = getState();
      const { type } = group;

      if (type && !refresh) {
        return;
      }

      const { id } = user.data as UserDataState;
      const groupTypeAssigned = _.map(
        (setting.config as SettingConfigData).blenderboxPostGroups,
        type => type.name
      );

      const [
        allGroupsData,
        joinedGroupsData,
        pendingGroupsData,
      ] = await Promise.all([
        dispatch(getAllGroups(refresh)),
        dispatch(getUserGroups(id, refresh)),
        dispatch(getPendingGroups(refresh)),
      ]);
      const allGroups = Object.values(allGroupsData.data || {});
      const joinedGroups = Object.values(joinedGroupsData.data.data || {});
      const pendingGroups = Object.values(pendingGroupsData.data || {});

      const groupsDataByStatusName = (setting.config as SettingConfigData).blenderboxPostGroups.reduce<
        Dict<
          | SettingConfigGroupType
          | ({ name: GroupByTypeTab } & {
              all: {};
              joined: {};
              pending: {};
            })
        >
      >(
        (typeDict, type) => ({
          ...typeDict,
          [type.name]: {
            ...type,
            joined: _.sortBy(
              _.filter(joinedGroups, group => group.type.name === type.name),
              "name"
            ),
            pending: _.sortBy(
              _.filter(pendingGroups, group => group.type.name === type.name),
              "name"
            ),
            all: _.sortBy(
              _.map(
                _.filter(allGroups, group => group.type.name === type.name),
                group => ({
                  ...group,
                  notJoined: _.isEmpty(joinedGroupsData.data.data[group.id]),
                })
              ),
              "name"
            ),
          },
        }),
        {
          More: {
            name: i18n.t("Group:Container.Action.More"),
            joined: _.sortBy(
              _.filter(
                joinedGroups,
                group => !_.contains(groupTypeAssigned, group.type.name)
              ),
              "name"
            ),
            pending: _.sortBy(
              _.filter(
                pendingGroups,
                group => !_.contains(groupTypeAssigned, group.type.name)
              ),
              "name"
            ),
            all: _.sortBy(
              _.map(
                _.filter(
                  allGroups,
                  group => !_.contains(groupTypeAssigned, group.type.name)
                ),
                group => ({
                  ...group,
                  notJoined: _.isEmpty(joinedGroupsData.data.data[group.id]),
                })
              ),
              "name"
            ),
          },
        }
      );

      dispatch({
        type: GROUP_TYPE_SET,
        data: groupsDataByStatusName as GroupTypeData,
      });
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

/**
 * Get groups then put them into the states [Joined groups, Public groups, Private groups]
 * @param refresh
 */
export function getGroupsByStatus(refresh: boolean): AppThunkAction<void> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<void> => {
    try {
      console.debug("[Action] getGroupsByStatus");

      const { user, group } = getState();

      const { status } = group;

      if (status && !refresh) {
        return;
      }

      const { id } = user.data as UserDataState;

      const [
        allGroupsData,
        joinedGroupsData,
        pendingGroupsData,
      ] = await Promise.all([
        dispatch(getAllGroups(refresh)),
        dispatch(getUserGroups(id, refresh)),
        dispatch(getPendingGroups(refresh)),
      ]);
      const allGroups = Object.values(allGroupsData.data || {});
      const joinedGroups = Object.values(joinedGroupsData.data.data || {});
      const pendingGroups = Object.values(pendingGroupsData.data || {});

      const groupsDataByStatusName: GroupStatusData = {
        "My groups": {
          name: i18n.t("Group:Container.Action.My.Groups") as GroupByStatusTab,
          data: _.sortBy(
            _.union(
              joinedGroups,
              pendingGroups.map(group => ({ ...group, pending: true }))
            ),
            "name"
          ),
        },
        Open: {
          name: i18n.t("Group:Container.Action.Open") as GroupByStatusTab,
          data: _.sortBy(
            allGroups.filter(
              ({ id, visibility }) =>
                visibility === "all" &&
                !joinedGroupsData.data?.data[id] &&
                pendingGroupsData.data &&
                !pendingGroupsData.data[id]
            ),
            "name"
          ),
        },
        Request: {
          name: i18n.t("Group:Container.Action.Request") as GroupByStatusTab,
          data: _.sortBy(
            allGroups.filter(
              ({ id, visibility }) =>
                visibility !== "all" &&
                !joinedGroupsData.data?.data[id] &&
                pendingGroupsData.data &&
                !pendingGroupsData.data[id]
            ),
            "name"
          ),
        },
      };

      dispatch({
        type: GROUP_STATUS_SET,
        data: groupsDataByStatusName,
      });
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type GetGroupActionReturn = { data?: Group };
/**
 * Get particular group
 * @param id
 * @param refresh
 */
export function getGroup(
  id: string,
  refresh?: boolean
): AppThunkAction<GetGroupActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetGroupActionReturn> => {
    try {
      console.debug("[Action] getGroup", id);

      const { group } = getState();
      const { myGroups, items, cached } = group;

      let cachedData = cached && cached[id];

      if (_.isEmpty(cachedData)) {
        cachedData = myGroups && myGroups[id];
      }

      if (_.isEmpty(cachedData)) {
        cachedData = items && items[id];
      }

      if (cachedData && !_.isEmpty(cachedData) && !refresh) {
        return { data: cachedData };
      }

      const data = await _getGroup(id);

      dispatch({
        type: GROUP_SET,
        data,
      });

      return { data };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type CanAccessGroupActionReturn = {
  canAccessGroup: boolean;
  isJoined: boolean;
};
/**
 * Check if user can access to a particular group
 * @param id
 * @param refresh
 */
export function canAccessGroup(
  id: string,
  refresh?: boolean
): AppThunkAction<CanAccessGroupActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<CanAccessGroupActionReturn> => {
    try {
      console.debug("[Action] canAccessGroup");

      const { user } = getState();
      const userId = (user.data as UserDataState).id;
      const myGroups = await dispatch(getUserGroups(userId, !!refresh));
      const { data } = myGroups || {};

      if (data?.data[id]) {
        return { canAccessGroup: true, isJoined: true };
      }

      const group = await dispatch(getGroup(id, refresh));
      return {
        canAccessGroup: group?.data?.visibility === "all",
        isJoined: false,
      };
    } catch (err) {
      throw err && err.message;
    }
  };
}

export type JoinGroupActionReturn = { isJoined: boolean };
/**
 * Join a particular group
 * @param group
 */

export const joinGroup = (
  group: Group
): AppThunkAction<JoinGroupActionReturn> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<JoinGroupActionReturn> => {
  try {
    console.debug("[Action] joinGroup", { group });

    const { user } = getState();
    //Prevent joining group in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't join group in Guest Mode.");
    }
    await _joinGroup(group.id);

    const { config, setting } = getState().setting;
    if (config?.blenderboxImprovements || setting?.group_list_type === "type") {
      dispatch(getGroupsByType(true));
    } else {
      dispatch(getGroupsByStatus(true));
    }

    return { isJoined: group.joinability === "free_join" };
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export type LeaveGroupActionReturn = { data: true };
/**
 * Leave a particular group which already joined
 * @param group
 */
export const leaveGroup = (
  group: Group
): AppThunkAction<LeaveGroupActionReturn> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<LeaveGroupActionReturn> => {
  try {
    console.debug("[Action] leaveGroup", { group });

    const { user } = getState();
    //Prevent leaving group in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't leave group in Guest Mode.");
    }

    await _leaveGroup(group.id);

    //update groups on tab
    const { config, setting } = getState().setting;
    if (config?.blenderboxImprovements || setting?.group_list_type === "type") {
      dispatch(getGroupsByType(true));
    } else {
      dispatch(getGroupsByStatus(true));
    }

    return { data: true };
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export type GetGroupsByGroupTypeNameActionReturn = { data?: Group[] };
/**
 * Get all groups have same group type
 * @param groupType
 * @param refresh
 */
export function getGroupsByGroupTypeName(
  groupType: string,
  refresh?: boolean
): AppThunkAction<GetGroupsByGroupTypeNameActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetGroupsByGroupTypeNameActionReturn> => {
    try {
      console.debug("[Action] getGroupsByGroupTypeName");

      const { group } = getState();
      const { groupsByType } = group;
      const cachedData = groupsByType[groupType];

      if (cachedData && !refresh) {
        return { data: cachedData };
      }
      const data = _.sortBy(await _getGroupsByGroupTypeName(groupType), "name");

      groupsByType[groupType] = data;

      dispatch({
        type: GROUPS_BY_TYPE_SET,
        data: groupsByType,
      });

      return { data: groupsByType[groupType] };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export const getGroupHome = (): AppThunkAction<void> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] getHomeGroup");

    const { setting } = getState();
    const homeGroupType = setting?.setting?.home_group_type;
    let homeGroup;
    if (!_.isEmpty(homeGroupType)) {
      homeGroup = await _getGroupHome(homeGroupType as string);
    }

    dispatch({
      type: GROUP_HOME_SET,
      data: homeGroup,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export type GetGroupMemberSettingActionReturn = {
  data?: { id: string; data: {} };
};
/**
 * Get the user settings of particular group
 * @param groupId
 * @param refresh
 */
export function getGroupMemberSetting(
  groupId: string,
  refresh?: boolean
): AppThunkAction<GetGroupMemberSettingActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<GetGroupMemberSettingActionReturn> => {
    try {
      console.debug("[Action] getGroupMemberSetting");

      const { group, user } = getState();
      const { id } = user.data as UserDataState;
      const cachedData = group.setting[groupId];

      if (!refresh && cachedData) {
        return {
          data: { id: groupId, data: cachedData },
        };
      }

      const data = await _getGroupMemberSetting(groupId, id);

      dispatch({
        type: GROUP_SETTING_SET,
        data: { id: groupId, data },
      });

      return {
        data: { id: groupId, data },
      };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type SaveGroupMemberSettingActionReturn = {
  data: { id: string; data: {} };
};
/**
 * Save the user setting of particular group
 * @param groupId
 * @param setting
 */
export function saveGroupMemberSetting(
  groupId: string,
  setting: {}
): AppThunkAction<SaveGroupMemberSettingActionReturn> {
  return async (
    dispatch: AppThunkDispatch
  ): Promise<SaveGroupMemberSettingActionReturn> => {
    try {
      console.debug("[Action] saveGroupMemberSetting");

      await _saveGroupMemberSetting(groupId, setting);

      dispatch({
        type: GROUP_SETTING_SET,
        data: { id: groupId, data: setting },
      });

      return {
        data: { id: groupId, data: setting },
      };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

/* Admin */
export type GetGroupTypesActionReturn = { data: GroupType[] };

export function getGroupTypes(): AppThunkAction<GetGroupTypesActionReturn> {
  return async (
    dispatch: AppThunkDispatch
  ): Promise<GetGroupTypesActionReturn> => {
    try {
      console.debug("[Action] getGroupTypes");

      const data = await _getGroupTypes();

      dispatch({
        type: GROUP_TYPES_SET,
        data: data,
      });

      return {
        data: data,
      };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type GetManagementGroupsActionReturn = { data?: {} | null };

export function getManagementGroups(refresh: boolean): AppThunkAction<void> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<void> => {
    try {
      const { group } = getState();
      const cachedData = group.management;

      if (!_.isEmpty(cachedData) && !refresh) {
        return;
      }

      const data = await _getGroups(
        {
          filter: "all",
          groupTypeId: "all",
          format: "tree",
        },
        { convertToDict: true }
      );

      dispatch({
        type: GROUP_MANAGEMENT_SET,
        data: data,
      });
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type ManageGroupActionReturn = { data: Group };

export function manageGroup(
  id: string
): AppThunkAction<ManageGroupActionReturn> {
  return async (): Promise<ManageGroupActionReturn> => {
    try {
      const data = await _getGroup(id);

      return { data };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type CreateGroupActionReturn = {};

/**
 * @TODO Similar situation to Announcements
 * This action creator updates Group as well.
 * Probably we can rename to upsert or sth like this
 */
export function createGroup(
  // data: Pick<
  //   Group,
  //   /**
  //    * @TODO These are probably only mandatory fields
  //    * Test once again with all the options.
  //    * Probably it will be easier to use Exclude instead of Pick.
  //    */
  //   | "hasGroupChat"
  //   | "hasGroupFeed"
  //   | "hidden"
  //   | "id"
  //   | "imageUrl"
  //   | "joinability"
  //   | "name"
  //   | "permission"
  //   | "thumbUrl"
  //   | "visibility"
  // > & {
  //   /** GroupType id */
  //   type: string;
  // },
  data: Partial<Group> & { type?: string; parent?: string | null },
  photo: {} | null
): AppThunkAction<CreateGroupActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<CreateGroupActionReturn> => {
    try {
      const { setting, user } = getState();
      const { id } = data;

      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: (setting.config as SettingConfigData).adminURL,
      };

      const progressCallback: ProgressCallback = (
        name,
        fileProgress,
        transferred,
        total,
        cancel
      ) => {
        dispatch({
          type: NOTIFICATION_UPLOAD_SET,
          data: { name, transferred, total, fileProgress, cancel },
        });
      };

      if (_.isEmpty(id) || !id) {
        await _createGroup(
          data,
          photo,
          Object.assign({}, config, setting.config),
          progressCallback
        );

        dispatch(getManagementGroups(true));

        return { data: true };
      }

      await _updateGroup(
        id,
        data,
        photo,
        Object.assign({}, config, setting.config),
        progressCallback
      );

      dispatch(getManagementGroups(true));

      return { data: true };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}

export type RemoveGroupActionReturn = unknown;

export function removeGroup(
  id: string
): AppThunkAction<RemoveGroupActionReturn> {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<RemoveGroupActionReturn> => {
    try {
      const { setting, user } = getState();
      const { sessionToken } = user.data as UserDataState;
      const config = {
        sessionToken,
        adminURL: (setting.config as SettingConfigData).adminURL,
      };

      await _removeGroup(id, config);

      dispatch(getManagementGroups(true));

      return { data: true };
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
}
