import React from "react";

import { RouteComponentProps } from "react-router-dom";

import UserLoginContainer from "../../../containers/User/Login";

import { UserChangePassword } from "../../components/User";

export type RouteUserChangePasswordProps = {} & RouteComponentProps;

export const RouteUserChangePassword: React.FC<RouteUserChangePasswordProps> = props => (
  <UserLoginContainer {...props} Layout={UserChangePassword} />
);
