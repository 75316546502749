import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { CardText, Button } from "reactstrap";
import { map } from "underscore";

import { textThemingWorkaround } from "../../../../constants/Theme";
import { EventAttendeeGuest } from "../../../../types";

import { useTheme } from "../../../hooks";

import { Avatar, Heading } from "../..";

import { EventAttendeeGuestsEditModal } from ".";

export type EventAttendeeGuestsProps = {
  maxGuestCount: number;
  guestMembers:
    | {
        firstName?: string;
        id: string;
        lastName?: string;
        profile?: { thumbUrl?: string };
      }[]
    | null;
  guests: string[];

  addMemberGuest: (members: { id: string }[]) => void;
  addNonMemberGuest: (name: string) => void;
  removeGuest: (guest: EventAttendeeGuest) => void;
};

export const EventAttendeeGuests: React.FC<EventAttendeeGuestsProps> = ({
  maxGuestCount,
  guestMembers,
  guests,

  addMemberGuest,
  addNonMemberGuest,
  removeGuest,
}) => {
  const { t } = useTranslation("Event");

  const {
    text: { cardText, listTitle },
  } = useTheme();

  const eventAttendeeGuestsStyles = {
    guestsNotAllowed: {
      ...cardText,
      ...textThemingWorkaround,
      color: "#0D0D0D",
      alignSelf: "center",
    },
    listTitle: {
      ...listTitle,
      ...textThemingWorkaround,
    },
    addNewGuest: {
      borderRadius: 20,
    },
  };

  const [isGuestEditModalOpen, setGuestModalOpen] = useState(false);

  const toggleGuestEditModalOpen = (): void => {
    setGuestModalOpen(!isGuestEditModalOpen);
  };

  if ((maxGuestCount || 0) === 0) {
    return (
      <CardText className="mt-4 d-flex" style={{ opacity: 0.5 }}>
        <i className="icon-info my-2 ml-1 mr-2" />
        <Heading
          style={eventAttendeeGuestsStyles.guestsNotAllowed}
          text={t("Attendee.Text.Additional.Guests.Not.Allowed")}
        />
      </CardText>
    );
  }

  /**
   * @FIXME
   * This logic is duplicated in web and native. Probably should be moved
   * to the container.
   */
  const allGuest = (guestMembers || []).concat(
    map(guests || [], guest => ({ firstName: guest, id: guest }))
  );

  const addEditGuestButton =
    allGuest.length > 0 ? t("Attendee.Button.Edit") : t("Attendee.Button.Add");

  const guestAvatar = allGuest.length > 0 && (
    <div className="mt-3">
      {allGuest.map(user => (
        <Avatar
          size={"50px"}
          round
          className="d-inline-block mr-2"
          key={user.id}
          name={`${user.firstName} ${user.lastName ? user.lastName : ""}`}
          src={user.profile?.thumbUrl}
          id={user.id}
        />
      ))}
    </div>
  );

  return (
    <CardText id="eventRsvpAdditionalGuestsText" className="mt-4">
      <Heading
        style={eventAttendeeGuestsStyles.listTitle}
        text={t("Attendee.Text.Additional.Guests", { maxGuestCount })}
      />
      <Button
        color="light"
        id="eventAttendeeAddGuestsButton"
        onClick={toggleGuestEditModalOpen}
        style={eventAttendeeGuestsStyles.addNewGuest}
        className="mt-3">
        {addEditGuestButton}
      </Button>
      {guestAvatar}
      <EventAttendeeGuestsEditModal
        toggle={toggleGuestEditModalOpen}
        isOpen={isGuestEditModalOpen}
        allGuest={allGuest}
        maxGuestCount={maxGuestCount}
        addMemberGuest={addMemberGuest}
        addNonMemberGuest={addNonMemberGuest}
        removeGuest={removeGuest}
      />
    </CardText>
  );
};
