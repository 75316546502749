import { useSettingConfig, useSettingSetting } from ".";

export const useIsHomePageEnabled = (): boolean => {
  const { home_page_type: homepageType } = useSettingSetting() || {};

  const { blenderboxImprovements: isBlenderboxImprovementsEnabled } =
    useSettingConfig() || {};

  return (
    // isLandingPageEnabled, disable landing page on web due to it has been moved to Right side bar
    isBlenderboxImprovementsEnabled || homepageType === "mix"
  );
};
