import React, { useState } from "react";

import classnames from "classnames";
import { ListGroupItem } from "reactstrap";

import { textThemingWorkaround } from "../../../constants";
import { ResourceItem } from "../../../types";

import { useTheme } from "../../hooks";

import { IconFontAwesome, Heading, IconIonicons } from "..";

import { ResourceListGroupCollapse } from ".";

export type ResourceCardFolderProps = {
  item: ResourceItem;
  style?: React.CSSProperties;
};

export const ResourceCardFolder: React.FC<ResourceCardFolderProps> = ({
  item,
  style,
}) => {
  const [isChildrenCollapseOpen, setChildrenCollapseOpen] = useState(false);

  const toggleChildrenCollapse = (): void => {
    setChildrenCollapseOpen(!isChildrenCollapseOpen);
  };
  const {
    text: { listTitle },
  } = useTheme();

  const { name, children } = item;

  const childrenCardStyle = {
    backgroundColor: "#fff",
    shadowOpacity: 0,
    elevation: 0,
    borderTopWidth: 0.3,
    borderRadius: 0,
    borderLeft: 0,
    borderBottom: 0,
  };

  const childrenCollapse = children && (
    <ResourceListGroupCollapse
      data={children}
      isOpen={isChildrenCollapseOpen}
      cardStyle={childrenCardStyle}
    />
  );

  const testId = `${item.name[0].toLowerCase()}${item.name
    .slice(1)
    .split(" ")
    .join("")}`;

  const resourceCardStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    borderTopColor: "#c9c9c9",
    borderTopWidth: 0,
    marginBottom: 5,
    zIndex: 0,
    ...style,
  };

  return (
    <ListGroupItem
      style={resourceCardStyle}
      className={classnames(
        "border-bottom-0",
        "border-radius-0",
        "border-right-0",
        "p-0"
      )}>
      <div
        className="py-2 d-flex align-items-center cursor-pointer"
        id={testId}
        onClick={toggleChildrenCollapse}>
        <IconFontAwesome
          className="mx-2"
          name="folder-open"
          style={{ color: listTitle.color }}
        />
        <Heading
          className="flex-grow-1"
          style={{ ...listTitle, ...textThemingWorkaround }}
          text={name}
        />
        <IconIonicons
          className="mx-2"
          name={isChildrenCollapseOpen ? "ios-arrow-down" : "ios-arrow-forward"}
          fontSize="18px"
        />
      </div>
      <div>{childrenCollapse}</div>
    </ListGroupItem>
  );
};
