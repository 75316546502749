import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, NavItem } from "reactstrap";
import { useTheme } from "../../../hooks";

export const ButtonMembersMap = (): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation("Member");
  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const redirectToMembersMap = (): void => history.push("/members/map");

  return (
    <NavItem key="map">
      <Button
        id="membersMapBtn"
        className="ml-1 border-0"
        style={{ backgroundColor: buttonBackgroundColor }}
        onClick={redirectToMembersMap}>
        <span style={{ color: buttonColor }}>
          {t("MembersListFilters.Button.Map")}
        </span>
      </Button>
    </NavItem>
  );
};
