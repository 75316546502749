import React from "react";

import classnames from "classnames";
import { Spinner } from "reactstrap";

export type LoadingSpinnerProps = {
  id?: string;
  className?: string;
  color?: string;
  size?: "sm" | "lg";
  style?: React.CSSProperties;
};

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className,
  color = "primary",
  id,
  size = "lg",
  style,
}) => {
  const styles = {
    lg: { height: "3rem", width: "3rem" },
    sm: { height: "1rem", width: "1rem" },
  } as const;

  const classNames = classnames(
    "text-center",
    {
      "py-5": size === "lg",
      "py-2": size === "sm",
    },
    className
  );

  const spinnerStyles = {
    ...styles[size],
    ...style,
  };

  return (
    <div id={id} className={classNames}>
      <Spinner color={color} style={spinnerStyles} />
    </div>
  );
};
