import React from "react";
import { Products, Product, Price } from "../../../types/Subscription";
import { useTheme } from "../../hooks";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Alert, Button } from "reactstrap";
import { textThemingWorkaround } from "../../../constants";
import { useTranslation } from "react-i18next";
type Props = {
  data: Products;
  sending: boolean;
  showPayment: (product: Product, price: Price) => void;
};

export const ProductView: React.FC<Props> = ({
  data,
  sending,
  showPayment,
}) => {
  const {
    tabTop: {
      active: { backgroundColor },
      activeText: { color },
    },
    text: { listTitle, listText },
  } = useTheme();
  const { t } = useTranslation("Subscription");
  const dataArray = data.products;
  const subscribedIndex = data.products.findIndex(
    product => product.subscribed
  );

  const styles = {
    header: {
      ...listTitle,
      ...textThemingWorkaround,
      backgroundColor,
      color,
      paddingLeft: 10,
      paddingRight: 10,
    },
    content: {
      borderWidth: 1,
      borderColor: "#ccc",
    },
    contentHeader: {
      ...listTitle,
      ...textThemingWorkaround,
    },
    contentBody: {
      ...listText,
      ...textThemingWorkaround,
      marginBottom: 20,
      whiteSpace: "pre-wrap" as "pre-wrap",
    },
    button: {
      height: 60,
    },
  };

  const renderHeader = (item: Product): JSX.Element => {
    return (
      <AccordionItemButton style={styles.header}>
        {item.subscribed && (
          <i className="icon-check" style={{ marginRight: 20 }}></i>
        )}
        {!item.subscribed && <i style={{ marginRight: 35 }}></i>}
        {item.name}
      </AccordionItemButton>
    );
  };

  const renderContent = (item: Product): JSX.Element => {
    return (
      <div style={styles.content}>
        <h5 style={styles.contentHeader}>{t("Product.Description")}</h5>
        <div style={styles.contentBody}>{item.description}</div>

        <h5 style={styles.contentHeader}>{t("Product.Prices")}</h5>
        {item.prices.map((price, index) => {
          if (price.subscribed) {
            return (
              <Alert
                key={index}
                style={{ marginTop: 10, textAlign: "center" }}
                color={
                  price.subscribed ? "success" : "info"
                }>{`${price.name} (Current plan)`}</Alert>
            );
          }
          return (
            <Button
              key={index}
              style={styles.button}
              color="success"
              outline
              block
              disabled={sending}
              onClick={(): void => showPayment(item, price)}>
              <div> {price.name}</div>
              <div>{t("Product.Subscribe")}</div>
            </Button>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      <Accordion allowZeroExpanded preExpanded={[subscribedIndex.toString()]}>
        {dataArray.map((item, index) => (
          <AccordionItem key={index} uuid={index.toString()}>
            <AccordionItemHeading>{renderHeader(item)}</AccordionItemHeading>
            <AccordionItemPanel>{renderContent(item)}</AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
