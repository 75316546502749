import React, { ChangeEvent } from "react";

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { WithTranslation } from "react-i18next";

import SearchTargetContainer, {
  SearchTargetToEnhance,
} from "../../../containers/Search/Target";

import { Announcement, SearchTypeKey, SearchTypeDict } from "../../../types";

import { SearchBox } from "..";

import { SearchTarget } from ".";
import { RouteComponentProps, withRouter } from "react-router-dom";

export type SearchProps = {
  triggerSearch: boolean;
  searchType: SearchTypeKey;
  query: string;
  changeQuery: (text: string) => void;
  activeTab?: number;
  currentTab?: number;
  height: number;
  changeSearchType: (text: { i: number }) => void;
  searchTypes: SearchTypeDict;
  underlineColor?: string;
  event?: string;
  post?: string;
  announcement?: Announcement;
} & RouteComponentProps &
  WithTranslation;

/**
 * @TODO rewrite to FC
 */

class SearchToEnhance extends React.Component<SearchProps> {
  state = { rSelected: this.props.activeTab };
  announcement: null | SearchTargetToEnhance = null;
  event: null | SearchTargetToEnhance = null;
  post: null | SearchTargetToEnhance = null;

  componentDidMount(): void {
    if (this.props.triggerSearch) {
      setTimeout(() => {
        this.onSearch();
      }, 1000);
    }

    const scrollPosition = Number(
      window.localStorage.getItem("scrollPosition")
    );
    if (scrollPosition) {
      window.localStorage.removeItem("scrollPosition");
      setTimeout(() => window.scrollTo(0, scrollPosition), 1); // doesn't work synchronously - not sure why
    }
  }

  componentWillUnmount(): void {
    window.localStorage.setItem("scrollPosition", String(window.pageYOffset));
    window.scrollTo(0, 0); // make sure next page is scrolled to top
  }

  shouldComponentUpdate(
    nextProps: Readonly<SearchProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    if (this.props.triggerSearch) {
      setTimeout(() => {
        this.onSearch();
      }, 1);
    }
    return true;
  }

  onSearch = (): void => {
    const { searchType, query } = this.props;

    const refElement = this[searchType];

    refElement && refElement.onSearchWeb();
    if (
      this.props.history.location.state?.query !== query ||
      this.props.history.location.state?.searchType !== searchType
    )
      this.props.history.replace("/search", {
        query: query,
        searchType: searchType,
      });
  };

  onClick = (index: number): void => {
    const { query, changeSearchType } = this.props;
    changeSearchType({ i: index });
    setTimeout(() => {
      query && this.onSearch();
    }, 1000);
  };

  render(): JSX.Element {
    const {
      query,
      changeQuery,
      searchType,
      searchTypes,
      //activeTab,
      //currentTab,
    } = this.props;

    return (
      <>
        <div
          className={"py-2"}
          onKeyPress={(event): void => {
            if (event.key === "Enter") {
              this.onSearch();
            }
          }}>
          <Row form>
            <Col xs={9}>
              <SearchBox
                id={"searchBar"}
                onValueChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  changeQuery(event.target.value);
                }}
                placeholder="Search (press Enter)"
                value={query}
                autoFocus
              />
            </Col>
            <Col xs={3}>
              <UncontrolledButtonDropdown className="w-100">
                <DropdownToggle
                  className="px-3"
                  caret
                  id={"searchTypeDropdownButton"}
                  style={{ paddingBottom: 8 }}>
                  {searchTypes[searchType]?.name}
                </DropdownToggle>
                <DropdownMenu>
                  {Object.values(searchTypes).map((option, index) => (
                    <DropdownItem
                      key={index}
                      id={`${option.name.toLowerCase()}SearchTab`}
                      onClick={(): void => {
                        this.onClick(index);
                        this.setState({ rSelected: index });
                      }}>
                      {option.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Col>
          </Row>
        </div>

        <>
          {searchType === "announcement" && (
            <SearchTargetContainer
              Layout={SearchTarget}
              query={query}
              searchType={searchType}
              searchTypes={searchTypes}
              changeQuery={changeQuery}
              onRef={(ref: null | SearchTargetToEnhance): void => {
                this.announcement = ref;
              }}
            />
          )}
          {searchType === "event" && (
            <SearchTargetContainer
              Layout={SearchTarget}
              query={query}
              searchType={searchType}
              searchTypes={searchTypes}
              changeQuery={changeQuery}
              onRef={(ref: null | SearchTargetToEnhance): void => {
                this.event = ref;
              }}
            />
          )}
          {searchType === "post" && (
            <SearchTargetContainer
              Layout={SearchTarget}
              query={query}
              searchType={searchType}
              searchTypes={searchTypes}
              changeQuery={changeQuery}
              onRef={(ref: null | SearchTargetToEnhance): void => {
                this.post = ref;
              }}
            />
          )}
        </>
      </>
    );
  }
}

export const Search = withRouter(SearchToEnhance);
