import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  difference,
  flatten,
  isEmpty,
  isString,
  map,
  reduce,
} from "underscore";

import {
  changeEventAttendee,
  changeEventAttendeeForGuest,
  getEvent,
  getEventRecap,
} from "./action";
import { doReaction } from "../Comment/action";
import {
  createInvoice,
  makePayment,
  getPaymentFee,
  payByCheck,
} from "../Payment/action";

import {
  currencyFormat,
  displayDate,
  getObjectByLevel,
  isBeforeNow,
  isPastEvent,
} from "../../lib/utils";
import { track } from "../../lib/track";

import {
  ChangeAttendee,
  CommentType,
  Dict,
  EventAttendee,
  EventAttendeeAdditionalData,
  EventAttendeeItem,
  EventAttendeeItemOption,
  EventInventory,
  EventItem,
  EventOptions,
  EventSingle,
  InvoiceData,
  InvoiceOption,
  MatchProps,
  ReactionType,
  RootState,
  SettingConfigData,
  SettingLocalConfigData,
  SettingSettingData,
  UserDataState,
  UserResponse,
} from "../../types";
import { RSVPRender, PaymentOptions, RSVPData } from "./containerTypes";
import { EventRecap } from "../../types/Event";

export type EventDetailSetting = {
  eventChatId?: string | false;
  ClientHostName: string;
  rsvpEnabled: boolean;
  paymentEnabled: boolean;
  checkPayableTo: string;
  checkSendTo: string;
  invoiceFooterTitle: string;
  invoiceFooterBody: string;
  stripeCredentials: {
    account_id: string;
    publishable_key: string;
    account_registered: boolean;
  };
  googleApi: string;
  confettiEffectActions: [];
};

export type EventDetailRecap = {
  id: string;
  description: string;
  strapLine: string;
  title: string;
  attachments: any;
};

type EventDetailStateProps = {
  setting: EventDetailSetting;
  user: UserDataState | {};
  item?: EventItem;
  recap: EventRecap | null;
};

type EventDetailDispatchProps = {
  getEvent: (
    id: string,
    options: EventOptions,
    refresh: boolean,
    trackCallback: (event: EventSingle) => void
  ) => Promise<void>;
  changeEventAttendee: (data: ChangeAttendee) => Promise<void | EventAttendee>;
  createInvoice: (
    // @TODO set one naming convention
    // eslint-disable-next-line @typescript-eslint/camelcase
    attendee_id: string,
    quantities: number[],
    options: InvoiceOption[] | number[]
  ) => Promise<InvoiceData>;
  makePayment: (
    token: string,
    parseId: string,
    amountInCents: number
  ) => Promise<void>;
  payByCheck: (parseId: string, amountInCents: number) => Promise<void>;
  changeEventAttendeeForGuest: (
    data: EventAttendeeAdditionalData,
    eventId: string,
    userIds: string[]
  ) => Promise<void | EventAttendee[]>;
  getPaymentFee: (total: number) => Promise<unknown>;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  getEventRecap: (id: string, refresh: boolean) => Promise<void>;
};

export type EventDetailLayoutProps = {
  error: string | null;
  info?: string;
  refreshing: boolean;
  sending?: boolean;
  user: {} | UserDataState;
  setting: EventDetailSetting;
  item: EventItem | undefined;
  recap: EventDetailRecap | null;
  reFetch: (refresh: boolean, callback?: () => void) => void;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  attendee: EventAttendeeAdditionalData;
  guest: {
    guestMembers: {
      objectId: string;
    }[];
    guests: (string | UserDataState)[];
  };
  partner: {
    partnerMembers: {
      objectId: string;
    }[];
    partners: (string | UserDataState)[] | null;
  };
  currentPartner: UserDataState | undefined;
  inventories: {
    [key: string]: EventInventory;
  } | null;
  changeAttendee: <T extends keyof EventAttendeeAdditionalData>(
    name: T,
    value: EventAttendeeAdditionalData[T]
  ) => void;
  changePartner: (partnerResponse: UserResponse) => void;
  updateAttendee: (callback: () => void) => Promise<void>;
  changeInfo: (info: string) => void;
  payForEvent: (
    payByCreditCard: boolean,
    token: string,
    callback: () => void,
    fallback: (error: string) => void
  ) => Promise<void>;
  openGuestEditingModal: (name: string, value: any) => void;
  changeGuest: {
    (adding: true, item: { objectId: string } | string): void;
    (
      adding: false,
      item: { objectId: string; id?: string; firstName?: string }
    ): void;
    (
      adding: boolean,
      item: { objectId: string; id?: string; firstName?: string } | string
    ): void;
  };
  getPaymentFee: (total: number) => Promise<unknown>;
  getPaymentOptions: (
    items: EventAttendeeItem[],
    inventories: Dict<EventInventory> | null
  ) => PaymentOptions;
  changeOptionsAttendee: <T extends keyof EventAttendeeItem>(
    index: number,
    name: T,
    value: EventAttendeeItem[T]
  ) => void;
  hideChat: boolean;
  rsvpRender: RSVPRender;
  rsvpData: RSVPData;
};

export type EventDetailOwnProps = {
  Layout: ComponentType<any>;

  forceRefreshOnMount?: boolean;
  hideChat?: boolean;
};

export type EventDetailProps = EventDetailOwnProps &
  EventDetailStateProps &
  EventDetailDispatchProps &
  MatchProps &
  WithTranslation;

export type EventDetailState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  attendee: EventAttendeeAdditionalData;
  guest: {
    guestMembers: { objectId: string; id?: string }[];
    guests: (UserDataState | string)[];
  };
  inventories: { [key: string]: EventInventory } | null;
  sending?: boolean;
  partner: {
    partnerMembers: { objectId: string }[];
    partners: (UserDataState | string)[] | null;
  };
  currentPartner: UserDataState | undefined;
  info?: string;
  rsvpRender: RSVPRender;
};

class EventDetail extends Component<EventDetailProps, EventDetailState> {
  constructor(props: EventDetailProps) {
    super(props);

    const { profile } = props.user as UserDataState;
    const partnerName = getObjectByLevel(
      ["Personal Info", "Spouse", "Name"],
      profile?.sections
    );
    const currentPartner =
      (props.user as UserDataState).partnerProfile ||
      (partnerName && { firstName: partnerName });

    this.state = {
      error: null,
      loading: false,
      refreshing: false,
      attendee: {
        response: "yes",
      },
      guest: {
        guestMembers: props.item
          ? map(props.item.guestMembers, guest => {
              return { objectId: guest.objectId };
            })
          : [],
        guests: [],
      },
      partner: {
        partnerMembers: props.item
          ? map(props.item.partnerMembers, guest => {
              return { objectId: guest.objectId };
            })
          : [],
        partners: null,
      },
      inventories: null,
      currentPartner,
      rsvpRender: {
        nonMemberGuestModal: null,
        memberGuestModal: null,
        guestEditingModal: null,
      },
    };
  }

  static getDerivedStateFromProps(
    nextProps: EventDetailProps,
    prevState: EventDetailState
  ): null | {} {
    if (
      nextProps.item &&
      nextProps.item.myAttendee &&
      isEmpty(prevState.attendee.id)
    ) {
      const { response, note, notifyMe, guests, invoice, partnerResponse, id } =
        nextProps.item.myAttendee || {};

      const { guest } = prevState;
      guest.guests = guests;
      return {
        attendee: { response, note, notifyMe, invoice, partnerResponse, id },
        guest,
      };
    }
    if (
      nextProps.item &&
      nextProps.item.guestMembers &&
      !prevState.guest.guestMembers
    ) {
      const { guest } = prevState;
      guest.guestMembers = nextProps.item.guestMembers;
      return { guest };
    }
    if (
      nextProps.item &&
      nextProps.item.inventories &&
      !prevState.inventories
    ) {
      return { inventories: nextProps.item.inventories };
    } else return null;
  }

  componentDidMount(): void {
    const { forceRefreshOnMount, item } = this.props;
    if (forceRefreshOnMount || !item || !item.comments) {
      this.fetchData(true);
    } else {
      this.fetchData(false);
    }
    track("View Screen", {
      Screen: "event-detail",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (refresh: boolean, callback?: () => void): void => {
    const { getEvent, match } = this.props;
    const { refreshing } = this.state;
    if (refreshing) {
      return;
    }

    this.setState(
      {
        refreshing: refresh,
      },
      async () => {
        try {
          const id = match.params?.id as string;
          const userId = match.params?.userId;

          const options = {
            userId,
            showComments: true,
            showMyAttendee: true,
            showAttendees: true,
          };
          const trackCallback = (event: EventSingle): void => {
            track("View Event", {
              "Event ID": event.objectId,
              "Event Title": event.title,
            });
          };
          await getEvent(id, options, refresh, trackCallback);
          this.setState({ refreshing: false, error: null });
          this.fetchExtra(id, refresh);
          callback?.();
        } catch (error) {
          console.debug(error);
          this.setState({ refreshing: false, error: error });
        }
      }
    );
  };

  fetchExtra = (eventId: string, refresh: boolean): void => {
    const { getEventRecap } = this.props;
    getEventRecap(eventId, refresh);
  };

  onChangeAttendee = (
    name: keyof EventAttendeeAdditionalData,
    value: any
  ): void => {
    const { attendee } = this.state;
    (attendee as EventAttendeeAdditionalData)[name] = value;
    this.setState({ attendee });
  };

  onChangeOptionsAttendee = <T extends keyof EventAttendeeItem>(
    index: number,
    name: T,
    value: EventAttendeeItem[T]
  ): void => {
    const { attendee } = this.state;
    const { items } = attendee;

    if (items) {
      items[index][name] = value;

      const option = items[index].options.find(
        option => option.index === items[index].selectedOption
      );

      items[index].price = option ? option.price : 0;
      this.onChangeAttendee("items", items);
    }
  };

  onChangePartner = (partnerResponse: UserResponse): void => {
    const { attendee, partner } = this.state;
    (attendee as EventAttendeeAdditionalData).partnerResponse = partnerResponse;

    const { user } = this.props;
    if (partnerResponse === "no") {
      partner.partnerMembers = [];
    } else {
      const { partnerProfile, profile } = user as UserDataState;
      if (partnerProfile) {
        partner.partnerMembers = [partnerProfile];
      } else {
        const partnerName = getObjectByLevel(
          ["Personal Info", "Spouse", "Name"],
          profile?.sections
        );
        partner.partners = [partnerName];
      }
    }

    this.setState({ attendee, partner });
  };

  onUpdateAttendee = async (callback: () => void): Promise<void> => {
    const { match, changeEventAttendee, user, item } = this.props;
    const { refreshing, sending, attendee, guest, partner } = this.state;
    if (refreshing || sending || !item) {
      return;
    }
    try {
      this.setState({ sending: true });
      const userId = match && match.params && match.params.userId;
      const {
        response,
        partnerResponse,
        note,
        notifyMe,
      } = attendee as EventAttendeeAdditionalData;
      const data = {
        response,
        partnerResponse,
        note,
        notifyMe,
        guests: guest.guests,
        partners: partner.partners,
        eventId: item && item.id,
        userId: (user as UserDataState).id || userId,
      };

      const subAttendees: {
        guestMembers: { objectId?: string }[];
        partnerMembers: { objectId?: string }[];
      } = {
        guestMembers: [],
        partnerMembers: [],
      };

      // Update RSVP for guests
      const attendedGuestResp = await this.onUpdateAttendeeForGuest(
        guest.guestMembers,
        item.guestMembers
      );
      subAttendees.guestMembers = flatten(attendedGuestResp);

      // Update RSVP for partner
      const attendedPartnerResp = await this.onUpdateAttendeeForGuest(
        partner.partnerMembers,
        item.partnerMembers
      );
      subAttendees.partnerMembers = flatten(attendedPartnerResp);

      //Update RSVP for current user
      await changeEventAttendee({ ...data, ...subAttendees });

      this.setState({ sending: false, error: null });

      callback && callback();
      track("Register for Event", {
        "Event ID": item.id,
        "Event Title": item.title,
        "Additional Guests": guest.guests,
      });

      //refetch event with new RSVP
      setTimeout(() => {
        this.fetchData(true);
      }, 1);
    } catch (error) {
      this.setState({ sending: false, error: error });
    }
  };

  onUpdateAttendeeForGuest = (
    newGuests: { objectId: string }[],
    oldGuests: UserDataState[]
  ): Promise<(void | EventAttendee[])[]> => {
    console.debug("[Container] onUpdateAttendeeForGuest", {
      newGuests,
      oldGuests,
    });

    const { changeEventAttendeeForGuest, user, item } = this.props;
    const { attendee } = this.state;

    const newGuestMemberIds = map(newGuests, guest => guest.objectId);
    const oldGuestMemberIds = map(oldGuests, guest => guest.objectId);
    const guestMemberIdsWillBeRemoved = difference(
      oldGuestMemberIds,
      newGuestMemberIds
    );

    const promises = [];
    if (!isEmpty(guestMemberIdsWillBeRemoved) && item) {
      const dataForRemovedGuest: {
        response: "no" | "yes" | "no_answer";
        note: string;
      } = {
        response: "no",
        note: `${this.props.t("Container.Detail.Note.Rsvp.Changed")} ${
          (user as UserDataState).firstName
        } ${(user as UserDataState).lastName}`,
      };
      promises.push(
        changeEventAttendeeForGuest(
          dataForRemovedGuest,
          item.id,
          guestMemberIdsWillBeRemoved
        )
      );
    }

    if (!isEmpty(newGuestMemberIds) && item) {
      const { response, notifyMe } = attendee as EventAttendeeAdditionalData;
      const dataForGuest: EventAttendeeAdditionalData = {
        response: response,
        notifyMe: notifyMe,
        guests: [],
        note: `${this.props.t("Container.Detail.Note.Rsvp.Added")} ${
          (user as UserDataState).firstName
        } ${(user as UserDataState).lastName}`,
        isAddedAsGuest: true,
      };
      promises.push(
        changeEventAttendeeForGuest(dataForGuest, item.id, newGuestMemberIds)
      );
    }
    return Promise.all(promises);
  };

  onPayForEvent = async (
    payByCreditCard: boolean,
    token: string,
    callback: () => void,
    fallback: (error: string) => void
  ): Promise<void> => {
    const {
      user,
      match,
      changeEventAttendee,
      makePayment,
      payByCheck,
      createInvoice,
      item,
    } = this.props;
    const {
      refreshing,
      loading,
      sending,
      attendee,
      guest,
      partner,
    } = this.state;
    if (refreshing || loading || sending || !item) {
      return;
    }

    try {
      this.setState({ sending: true });
      const userId = match && match.params && match.params.userId;
      const {
        response,
        partnerResponse,
        note,
        notifyMe,
        items,
      } = attendee as EventAttendeeAdditionalData;
      const data: ChangeAttendee = {
        response,
        partnerResponse,
        note,
        notifyMe,
        guests: guest.guests,
        partners: partner.partners,
        eventId: item && item.id,
        userId: (user as UserDataState).id || userId,
      };

      // add RSVP of current user
      const attendeeResp = await changeEventAttendee(data);

      // create invoice
      const itemQuantities = (items as EventAttendeeItem[]).map(
        item => item.quantity
      );
      const itemOptions = (items as EventAttendeeItem[]).map(
        item => item.selectedOption
      );

      const invoice: InvoiceData = await createInvoice(
        (attendeeResp as EventAttendee).id,
        itemQuantities,
        itemOptions
      );

      // handle a payment if have
      if (payByCreditCard) {
        if (!isEmpty(token)) {
          await makePayment(token, invoice.id, invoice.total);
        } else {
          this.setState({
            sending: false,
            error: this.props.t("Container.Detail.Error.Card.Declined"),
          });
        }
      } else {
        await payByCheck(invoice.id, invoice.total);
      }

      const subAttendees = {
        guestMembers: {},
        partnerMembers: {},
      };

      // Update RSVP for guests
      const attendedGuestResp = await this.onUpdateAttendeeForGuest(
        guest.guestMembers,
        item.guestMembers
      );
      subAttendees.guestMembers = flatten(attendedGuestResp);

      // Update RSVP for partner
      const attendedPartnerResp = await this.onUpdateAttendeeForGuest(
        partner.partnerMembers,
        item.partnerMembers
      );
      subAttendees.partnerMembers = flatten(attendedPartnerResp);

      //Update RSVP for current user with partner/guests RSVP
      await changeEventAttendee(Object.assign({}, data, { ...subAttendees }));

      this.setState({ sending: false, error: null });
      callback && callback();
      track("Register for Event", {
        "Event ID": item.id,
        "Event Title": item.title,
        "Additional Guests": guest.guests,
      });

      //refetch event with new RSVP
      this.fetchData(true);
    } catch (error) {
      this.setState({ sending: false, error: error });
    }
  };

  onOpenGuestEditingModal = (name: string, value: any): void => {
    const { rsvpRender } = this.state;
    this.setState({ rsvpRender: { ...rsvpRender, [name]: value } });
  };

  onChangeInfo = (info: string): void => {
    this.setState({ info });
  };

  onChangeGuest = (
    adding: boolean,
    item: { objectId: string; id?: string; firstName?: string }
  ): void => {
    const { guest } = this.state;

    let guestMembers = guest.guestMembers || [];
    let guests = guest.guests || [];
    if (adding) {
      if (isString(item)) {
        guests = guests.concat([item]);
      } else {
        guestMembers = guestMembers.concat([item]);
      }
    } else if (typeof item !== "string") {
      /**
       * @TODO Please review carefully. Changed condition to
       * make TS work properly
       */
      guestMembers = guestMembers.filter(guest => guest.id !== item.id);
      guests = guests.filter(guest => guest !== item.firstName);
    }
    this.setState({ guest: { guestMembers, guests } });
  };

  getPaymentOptions = (
    items: EventAttendeeItem[],
    inventories: { [key: string]: EventInventory } | null
  ): PaymentOptions => {
    let preventAttending = false;

    const newItems = map(items, (item: EventAttendeeItem) => {
      item.selectedOption = 0;
      item.price = 0;
      item.quantity = item.required ? 1 : 0;

      let options: EventAttendeeItemOption[] = [];
      if (isEmpty(item.options)) {
        options.push({
          name: `${item.description} - $${item.price}`,
          price: item.price,
          index: 0,
        });
      } else {
        options = item.options.map((option, index) => {
          //get inventory info of options if it has inventory(available)
          const inventory =
            inventories && inventories[option.inventoryId as string];
          const available = inventory
            ? inventory?.numAvailable
            : option.available;
          const maximum = inventory
            ? inventory?.numAvailable < item.maximum
              ? inventory?.numAvailable
              : item.maximum
            : item.maximum;

          return {
            ...option,
            index,
            name: `${option.name || item.description} - $${currencyFormat(
              option.price
            )}`,
            available,
            maximum,
            inventory,
          };
        });
        //remove options have sold out
        options = options.filter(
          option => !option.inventoryId || (option.available as number) > 0
        );
        if (options.length > 0) {
          item.maximum = options[0].maximum as number;
          item.selectedOption = options[0].index;
        }
        //check if required items have sold out and show up alert
        //also set maximum for item depends on option available
        if (item.required) {
          if (options.length > 0) {
            item.quantity = 1;
            item.price = options[0].price;
          } else {
            item.quantity = 0;
            preventAttending = true;
          }
        }
      }
      return {
        ...item,
        options,
      };
    });

    return {
      items: newItems,
      preventAttending,
    };
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      item,
      setting,
      doReaction,
      getPaymentFee,
      hideChat,
      recap,
    } = this.props;
    const {
      error,
      refreshing,
      sending,
      attendee,

      guest,
      partner,
      currentPartner,
      inventories,
      info,
      rsvpRender,
    } = this.state;

    const rsvpData: RSVPData = {
      hasPayment: setting.paymentEnabled && (item?.event?.isPaid as boolean),
      isAttended: attendee.response === "yes",
      isPartnerAttended: attendee.partnerResponse === "yes",
      rsvpEnabled: setting.rsvpEnabled && item?.event?.rsvpEnabled !== false,
      isPast: isPastEvent(item?.event?.endAt),
      totalFees: reduce(
        attendee.items || [],
        (memoizer, value) => {
          return memoizer + value.price * value.quantity;
        },
        0
      ),
      passAttendeeDeadline: isBeforeNow(
        displayDate(item?.event?.attendeeDeadline, null, item?.event?.timezone)
      ),
      passInvoiceDueDate: isBeforeNow(
        displayDate(item?.event?.invoiceDueDate, null, item?.event?.timezone)
      ),
      maxGuestCount: item?.event?.maxGuestCount as number,
    };

    return (
      <Layout
        error={error}
        info={info}
        refreshing={refreshing}
        sending={sending}
        user={user}
        setting={setting}
        item={item}
        recap={recap}
        reFetch={this.fetchData}
        doReaction={doReaction}
        attendee={attendee}
        guest={guest}
        partner={partner}
        currentPartner={currentPartner}
        inventories={inventories}
        changeAttendee={this.onChangeAttendee}
        changeOptionsAttendee={this.onChangeOptionsAttendee}
        changePartner={this.onChangePartner}
        updateAttendee={this.onUpdateAttendee}
        changeInfo={this.onChangeInfo}
        payForEvent={this.onPayForEvent}
        openGuestEditingModal={this.onOpenGuestEditingModal}
        changeGuest={this.onChangeGuest}
        getPaymentOptions={this.getPaymentOptions}
        getPaymentFee={getPaymentFee}
        hideChat={hideChat}
        rsvpRender={rsvpRender}
        rsvpData={rsvpData}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): EventDetailStateProps => {
  const id = ownProps.match.params?.id;
  const { localConfig, setting, config } = state.setting || {};
  const { list, recaps } = state.event;
  const { items, cached } = list;
  const item =
    (id && ((items && items[id]) || (cached && cached[id]))) || undefined;
  return {
    setting: {
      eventChatId:
        item && item.event && item.event.hasEventChat && item.event.eventChatId,
      ClientHostName: (localConfig as SettingLocalConfigData).client_url,
      rsvpEnabled: (setting as SettingSettingData).rsvp_enabled,
      paymentEnabled: (setting as SettingSettingData).payments_enabled,
      checkPayableTo: (setting as SettingSettingData).check_payable_to,
      checkSendTo: (setting as SettingSettingData).check_send_to,
      invoiceFooterTitle: (setting as SettingSettingData).invoice_footer_title,
      invoiceFooterBody: (setting as SettingSettingData).invoice_footer_body,
      stripeCredentials: (setting as SettingSettingData).stripe_credentials,
      googleApi: (config as SettingConfigData).googleMapKey,
      confettiEffectActions: (setting as SettingSettingData)
        .confetti_effect_actions,
    },
    user: state.user.data || {},
    recap: recaps && recaps[id as string],
    item,
  };
};

const mapDispatchToProps = {
  getEvent,
  changeEventAttendee,
  createInvoice,
  makePayment,
  changeEventAttendeeForGuest,
  getPaymentFee,
  doReaction,
  getEventRecap,
  payByCheck,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Event")(EventDetail));
