import { createSelector } from "reselect";
import { RootState, DictStrict, EventItem } from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";
import { getSettingState } from "../Setting/selector";

// selector

const getEvents = (state: RootState): DictStrict<EventItem> | null =>
  state.event.list.items;

const getEventsHasMore = (state: RootState): boolean =>
  state.event.list.hasMore;

// reselect function

export const getEventsState = createSelector([getEvents], items =>
  getDictDefinedValues(items)
);

export const getEventsHasMoreState = createSelector(
  [getEventsHasMore],
  hasMore => hasMore
);

export const getEventsSettingState = createSelector(
  [getSettingState],
  setting => ({
    cover: setting?.setting?.event_calendar_background_image || "",
  })
);
