/* eslint-disable @typescript-eslint/camelcase */
import {
  getUserPosts as _getUserPosts,
  getMixedPosts as _getMixedPosts,
  getGroupPosts as _getGroupPosts,
  getPost as _getPost,
  addPost as _addPost,
  updatePost as _updatePost,
  removePost as _removePost,
  canPostToGroup as _canPostToGroup,
  getGroupFiles as _getGroupFiles,
  addPostReadBy as _addPostReadBy,
  searchFullTextPosts as _searchFullTextPosts,
  FileOptions,
} from "../../services/api/Post";
import { extractLink } from "../../services/api";
import { removeUnreadPost } from "../Notification/action";

import { getLink, logException } from "../../lib/utils";
import _ from "underscore";
import { page as _page } from "../../constants";
import {
  POST_ALL_SET,
  POST_DRAFT_CLEAR,
  POST_DRAFT_SET,
  POST_FILES_SET,
  POST_GROUP_SET,
  POST_IMAGES_SET,
  POST_REMOVE,
  POST_SEARCH_HISTORY_SET,
  POST_SEARCH_SET,
  POST_SET,
  POST_USER_SET,
} from "./constants";
import { ThunkDispatch } from "redux-thunk";
import {
  AppThunkAction,
  AppThunkDispatch,
  DictStrict,
  GetState,
  GlobalAction,
  NotificationFileProgress,
  NotificationPostingStatus,
  Post,
  PostData,
  PostGroup,
  PostItem,
  RootState,
  SettingConfigData,
} from "../../types";
import {
  NOTIFICATION_UPLOAD_SET,
  POSTING_STATUS_SET,
} from "../Notification/constants";
import { PhotoOptions } from "../../services/api/Post";
import i18n from "../../middlewares/i18next";

const per = _page.post;

/**
 * Get all posts of particular user
 * It's being used in profile page
 * @param userId
 * @param next
 * @param refresh
 */
export const getUserPosts = (
  userId: string,
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { setting } = getState();
    const { post } = getState();
    let { page = 0 } = post.usersList[userId] || {};
    const { items } = post.usersList[userId] || {};
    if (items && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
      }

      if (next) {
        page++;
      }
      const options = {
        skipComments: false,
        per,
        page,
        author: userId,
        followingEnabled:
          (setting.config as SettingConfigData).blenderboxImprovements ||
          (setting.config as SettingConfigData).followingEnabled,
      };

      let data = await _getUserPosts(options);

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: POST_USER_SET,
        data: {
          userId: userId,
          items: data,
          page,
          hasMore,
        },
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get mixed (all groups) posts
 * @param next
 * @param refresh
 * @param trackCallback
 */
export const getMixedPosts = (
  next: boolean,
  refresh: boolean,
  trackCallback?: (data: DictStrict<PostItem>) => void,
  refreshWhenReactive?: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getMixedPosts", {
    next,
    refresh,
    refreshWhenReactive,
  });

  try {
    const { post, setting } = getState();
    let { page = 0 } = post.mixedList;
    const { items } = post.mixedList;
    if (items && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
        if (refreshWhenReactive) {
          dispatch({
            type: POST_ALL_SET,
            data: {
              items: null,
              page,
              hasMore: true,
            },
          });
        }
      }
      if (next) {
        page++;
      }
      const options = {
        skipComments: false,
        per,
        page,
        followingEnabled:
          (setting.config as SettingConfigData).blenderboxImprovements ||
          (setting.config as SettingConfigData).followingEnabled,
      };
      const result = await _getMixedPosts(options);
      let { data } = result;
      const { postGroupIds } = result;

      trackCallback?.(data);

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: POST_ALL_SET,
        data: {
          items: data,
          page,
          hasMore,
        },
      });

      dispatch(removeUnreadPost(postGroupIds));
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get posts of particular group
 * @param groupId
 * @param next
 * @param refresh
 * @param trackCallback
 */
export const getGroupPosts = (
  groupId: string,
  next: boolean,
  refresh: boolean,
  trackCallback?: (data: DictStrict<PostItem>) => void,
  refreshWhenReactive?: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getGroupPosts", {
    groupId,
    next,
    refresh,
    refreshWhenReactive,
  });

  try {
    const { post } = getState();
    let { page = 0 } = post.groupsList[groupId] || {};
    const { items } = post.groupsList[groupId] || {};
    if (items && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
        if (refreshWhenReactive) {
          dispatch({
            type: POST_GROUP_SET,
            data: {
              groupId,
              items: null,
              page,
              hasMore: true,
            },
          });
        }
      }
      if (next) {
        page++;
      }
      const options = {
        skipComments: false,
        per,
        page,
        groupId,
      };
      // eslint-disable-next-line prefer-const
      let { data, postGroupIds } = await _getGroupPosts(options);
      trackCallback?.(data);

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: POST_GROUP_SET,
        data: {
          groupId,
          items: data,
          page,
          hasMore,
        },
      });

      dispatch(removeUnreadPost(postGroupIds));
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Search announcements by text and #tag
 * @param search
 * @param next
 * @param refresh
 */
export const searchFullTextPosts = (
  search: string,
  next: boolean,
  refresh: boolean
): AppThunkAction => {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<void> => {
    console.debug("[Action] searchFullTextPosts");

    try {
      const { post } = getState();

      const { searchList } = post;
      let { page = 0 } = searchList;
      const { items } = searchList;

      if (_.isEmpty(searchList)) {
        page = 0;
        dispatch({
          type: POST_SEARCH_SET,
          data: {
            items: {},
            page,
            hasMore: true,
          },
        });
      } else {
        if (refresh) {
          page = 0;
        } else if (next) {
          page++;
        }
      }

      let data = await _searchFullTextPosts({
        per,
        page,
        search,
      });

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: POST_SEARCH_SET,
        data: {
          items: data,
          page,
          hasMore,
        },
      });

      /**
       * Add the search text into search history
       */
      dispatch({
        type: POST_SEARCH_HISTORY_SET,
        data: search,
      });
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
};

/**
 * Get images of particular groups
 * @param groupId
 * @param next
 * @param refresh
 */
export const getGroupImages = (
  groupId: string,
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getGroupImages");

  try {
    const { post } = getState();
    let { page = 0 } = post.imagesList[groupId] || {};
    const { items } = post.imagesList[groupId] || {};

    if (items && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
      }
      if (next) {
        page++;
      }

      const options = {
        per,
        page,
        groupId,
        fileType: "photo" as const,
      };

      let data = await _getGroupFiles<PhotoOptions>(options);

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: POST_IMAGES_SET,
        data: {
          groupId,
          items: data,
          page,
          hasMore,
        },
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get files of particular group
 * @param groupId
 * @param next
 * @param refresh
 */
export const getGroupFiles = (
  groupId: string,
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getGroupFiles");

  try {
    const { post } = getState();

    let { page = 0 } = post.filesList[groupId] || {};
    const { items } = post.filesList[groupId] || {};
    if (items && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
      }
      if (next) {
        page++;
      }

      const options = {
        per,
        page,
        groupId,
        fileType: "file" as const,
      };

      let data = await _getGroupFiles<FileOptions>(options);

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: POST_FILES_SET,
        data: {
          groupId,
          items: data,
          page,
          hasMore,
        },
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export type GetPostActionReturn = {
  data: PostItem | undefined;
};

/**
 * Get post detail
 * @param id
 * @param options
 * @param refresh
 */
export const getPost = (
  id: string,
  options: { uid?: string; showComments?: boolean },
  refresh: boolean
): AppThunkAction<GetPostActionReturn> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<GetPostActionReturn> => {
  console.debug("[Action] getPost", id);
  try {
    const { post, user } = getState();
    const uid = (user.data && user.data.id) || options.uid;
    const { mixedList } = post;
    const cachedData = mixedList.items && mixedList.items[id];
    if (!refresh && cachedData) {
      _addPostReadBy(id, uid);
      return { data: cachedData };
    } else {
      /**
       * Shouldn't we check if data exists before dispatch? Is there any chance
       * when data will be undefined or is error thrown in this case? Is there any sense
       * to dispatch empty data?
       */
      const data = await _getPost(id, options);

      dispatch({
        type: POST_SET,
        data: data,
      });
      _addPostReadBy(id, uid);
      return { data };
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const saveDraftPost = (data: Post) => async (
  dispatch: AppThunkDispatch
): Promise<boolean> => {
  console.debug("[Action] saveDraftPost");

  dispatch({
    type: POST_DRAFT_SET,
    data: data,
  });
  return true;
};

export const clearDraftPost = () => async (
  dispatch: AppThunkDispatch
): Promise<boolean> => {
  console.debug("[Action] clearDraftPost");

  dispatch({
    type: POST_DRAFT_CLEAR,
  });
  return true;
};

/**
 * Craete new or update a post
 * @param data
 */
export const postToGroup = (
  data: Partial<PostData> & { userId?: string; groupId: string }
) => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<{ data: { id: string } } | void> => {
  console.debug("[Action] postToGroup", data);

  //using for updating a post status
  const progressPostingCallback = (
    status?: NotificationPostingStatus
  ): void => {
    dispatch({
      type: POSTING_STATUS_SET,
      data: status,
    });
  };

  //using for updating a uploading files status
  const progressUploadingCallback = (
    name: string,
    fileProgress: { [key: string]: NotificationFileProgress },
    transferred: number,
    total: number,
    cancel: boolean
  ): void => {
    dispatch({
      type: NOTIFICATION_UPLOAD_SET,
      data: { name, transferred, total, fileProgress, cancel },
    });
  };

  try {
    const { user, setting } = getState();
    //Prevent doing post in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't post in Guest Mode.");
    }

    const { groupId, userId, id } = data;
    let { embedLink } = data;

    //validate if group is missing
    if (_.isEmpty(groupId) || _.isEmpty(userId)) {
      throw Error(i18n.t("Post:Container.Action.Messege"));
    }

    //extrating an embeded link in post
    const links = getLink(data.body || "");
    if (!_.isEmpty(links)) {
      try {
        progressPostingCallback({
          text: i18n.t("Post:Container.Action.Link"),
          shortText: "Getting",
        });
        const link = await extractLink(
          links[0],
          (setting.config as SettingConfigData).embedlyApiKey
        );

        const { description, title, url, images, original_url } = link;
        embedLink = {
          description,
          url: url,
          title: title || original_url,
          imageUrl: !_.isEmpty(images)
            ? (images as { url: string }[])[0].url
            : null,
        };
      } catch (error) {
        console.debug({ error });
      }
    }

    //prepareing data for posting
    const newData: Partial<PostData> & {
      userId?: string;
      groupId: string;
    } = {
      ...data,
      embedLink,
    };

    if (_.isEmpty(id)) {
      //adding new post
      const newPost = await _addPost(
        newData,
        setting.config as SettingConfigData,
        progressUploadingCallback,
        progressPostingCallback
      );
      // dispatch(getMixedPosts(false, true));
      return { data: { id: newPost.id } };
    } else {
      //updating a post
      const savedPost = await _updatePost(
        newData,
        setting.config as SettingConfigData,
        progressUploadingCallback,
        progressPostingCallback
      );
      // dispatch(getMixedPosts(false, true));
      return { data: { id: savedPost.id } };
    }
  } catch (err) {
    progressPostingCallback();
    logException(err);
    throw err && err.message;
  }
};

export const removePost = (post: {
  id: string;
  group: PostGroup & { id?: string };
}) => async (dispatch: AppThunkDispatch): Promise<{ data: boolean }> => {
  try {
    const { id, group } = post;
    await _removePost(id);
    dispatch({
      type: POST_REMOVE,
      data: { id, groupId: group.id || group.objectId },
    });
    return { data: true };
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export function canPostToGroup(groupId: string) {
  return (
    dispatch: ThunkDispatch<RootState, null, GlobalAction>,
    getState: GetState
  ): Promise<{ data: boolean }> =>
    new Promise<{ data: boolean }>((resolve, reject) => {
      const { setting } = getState();
      const { blenderboxImprovements } = setting.config as SettingConfigData;
      _canPostToGroup({
        groupId,
        canPostToPrivateGroup: blenderboxImprovements,
      })
        .then(resp => {
          return resolve({ data: resp });
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}
