import React, { FC, useState } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Button, Col, Media, Modal, Row, ModalHeader } from "reactstrap";

import { reactionImages, textThemingWorkaround } from "../../../constants";
import { reactionTypes } from "../../../constants/reactionTypes";

import CommentReactByContainer from "../../../containers/Comment/ReactBy";
import CommentSeenByContainer from "../../../containers/Comment/SeenBy";

import { Dict, ReactionType, CommentType } from "../../../types";

import { useTheme } from "../../hooks";

import { CommentReactBy, CommentSeenBy } from ".";

export type CommentReactionsProps = {
  id: string;

  className?: string;
  commentCount: number;
  disabled?: boolean;
  likeCount: number;
  reactionCount: Partial<Dict<number, ReactionType>>;
  seenCount: number;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  type: CommentType;
  toggleIsCommentListVisible?: () => void;
};

export const CommentReactions: FC<CommentReactionsProps> = ({
  id,

  className,
  commentCount,
  disabled,
  likeCount,
  reactionCount,
  seenCount,
  style,
  textStyle,
  type,
  toggleIsCommentListVisible,
}) => {
  const { t } = useTranslation("Comment");

  const [isReactionListModalOpen, setIsReactionListModalOpen] = useState(false);
  const [isSeenListModalOpen, setIsSeenListModalOpen] = useState(false);

  const toggleReactionListModal = (): void => {
    setIsReactionListModalOpen(!isReactionListModalOpen);
  };

  const toggleSeenListModal = (): void => {
    setIsSeenListModalOpen(!isSeenListModalOpen);
  };

  const {
    navigation: navigationStyles,
    comment: { stats },
  } = useTheme();

  const styles = {
    commentCount: {
      fontWeight: 400,
      paddingRight: 0,
      paddingLeft: 5,
      textTransform: "none",
      ...{ ...stats, ...textThemingWorkaround, ...textStyle },
    },
    modalHeader: {
      backgroundColor: navigationStyles.backgroundColor,
      color: navigationStyles.color,
    },
    modalBody: {
      backgroundColor: navigationStyles.backgroundColor,
      padding: 10,
    },
    reactionThumbnailMedia: {
      cursor: disabled ? "default" : "pointer",
      marginLeft: "-5px",
    },
    row: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      ...style,
    },
    text: { ...stats, ...textThemingWorkaround, ...textStyle },
  } as const;

  const reactionThumbnailElements = reactionTypes.map(
    reactionType =>
      (reactionCount[reactionType] && (
        <small key={reactionType}>
          <Media
            object
            height="20"
            onClick={disabled ? undefined : toggleReactionListModal}
            src={reactionImages.image[reactionType]}
            size="small"
            style={styles.reactionThumbnailMedia}
          />
        </small>
      )) ||
      null
    /**
     * Null prevents here from rendering "0" in some cases
     * (number to string type casting).
     */
  );

  const reactionBlock = likeCount > 0 && (
    <>
      {reactionThumbnailElements}
      <Button
        className="align-baseline d-inline p-0 ml-2"
        color="link"
        disabled={disabled}
        onClick={toggleReactionListModal}
        id={`commentReactionsButton${id}`}
        style={styles.text}>
        {likeCount}
      </Button>
    </>
  );

  const commentCounter = (
    <span className="mr-4" style={styles.commentCount}>
      {commentCount}
      {` Comment${commentCount > 1 ? `s` : ""}`}
    </span>
  );

  const renderCommentsButton = (): React.ReactElement | null => {
    const hasComments = commentCount > 0;
    const hasShowMoreCommentsOnClick =
      typeof toggleIsCommentListVisible === "function";

    if (!hasComments) {
      return null;
    }
    if (!hasShowMoreCommentsOnClick) {
      return commentCounter;
    }
    return (
      <Button
        className="d-inline p-0 align-baseline "
        color="link"
        onClick={toggleIsCommentListVisible}
        disabled={disabled || commentCount === 0}
        style={styles.commentCount}>
        {commentCounter}
      </Button>
    );
  };
  const commentsButton = renderCommentsButton();

  const modalElements = !disabled && (
    <>
      <Modal
        isOpen={isReactionListModalOpen}
        toggle={toggleReactionListModal}
        className="py-5 border-0 modal__reactby"
        id={`commentReactionsModal${id}`}>
        <ModalHeader
          className="d-flex justify-content-center"
          toggle={toggleReactionListModal}
          style={styles.modalHeader}>
          {t("Routes:Reacted By")}
        </ModalHeader>
        <div style={styles.modalBody}>
          <CommentReactByContainer
            Layout={CommentReactBy}
            match={{ params: { type, id } }}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isSeenListModalOpen}
        toggle={toggleSeenListModal}
        className="py-5 modal__seenby"
        id={`seenByModal${id}`}>
        <ModalHeader
          className="d-flex justify-content-center"
          toggle={toggleSeenListModal}
          style={styles.modalHeader}>
          {t("Routes:Seen By")}
        </ModalHeader>
        <div style={styles.modalBody}>
          <CommentSeenByContainer
            Layout={CommentSeenBy}
            match={{ params: { type, id } }}
          />
        </div>
      </Modal>
    </>
  );

  return (
    <>
      {modalElements}
      <Row noGutters className={classnames(className)} style={styles.row}>
        <Col className="pl-3 pb-1" style={{ paddingLeft: "5px" }} xs="4">
          {reactionBlock}
        </Col>

        <Col className="text-right pr-3" xs="8">
          {commentsButton}

          <Button
            className="d-inline p-0 align-baseline "
            color="link"
            onClick={toggleSeenListModal}
            disabled={disabled || seenCount === 0}
            id={`seenByButton${id}`}
            style={styles.commentCount}>
            {t("Reaction.Seen.By")} {seenCount}
          </Button>
        </Col>
      </Row>
    </>
  );
};
