import { adminPost } from "../../http";
import { Config } from "../../../types/Config";

export const payByCheck = async (
  parseId: string,
  amountInCents: number,
  config: Config
): Promise<{ message: string; error?: string }> => {
  // eslint-disable-next-line @typescript-eslint/camelcase
  console.debug("[Active] payByCheck", {
    parseId,
    amountInCents,
  });

  return adminPost(
    "payments/check",
    // eslint-disable-next-line @typescript-eslint/camelcase
    { invoice_id: parseId, total: amountInCents },
    {
      requireUser: true,
      config,
    }
  );
};
