import React from "react";

import { useTranslation } from "react-i18next";

import { EventItem } from "../../../types";

import { EventCard } from ".";
import { pluralize } from "../../../lib/utils";
import { useTheme } from "../../hooks";

export type EventListSectionProps = {
  id?: number;
  data: EventItem[];
  eventsNumber?: boolean;
  headerText?: string;

  onEventClick: (id: string) => void;
  getEventHref: (id: string) => string;
};

export const EventListSection: React.FC<EventListSectionProps> = ({
  id,
  data,
  eventsNumber = true,
  headerText,

  getEventHref,
  onEventClick,
}) => {
  const { t } = useTranslation("Event");
  const {
    divider: {
      backgroundColor: dividerBackgroundColor,
      text: { color: dividerColor },
    },
  } = useTheme();
  const eventCards = data.map((eventItem, index) => {
    const handleEventClick = (): void => {
      /** @FIXME Probably events in store always have id property */
      eventItem.event.id && onEventClick(eventItem.event.id);
    };

    /** @FIXME Probably events in store always have id property */
    const href = eventItem.event.id && getEventHref(eventItem.event.id);

    return (
      <EventCard
        key={index}
        item={eventItem}
        onClick={handleEventClick}
        href={href}
      />
    );
  });

  const styles = {
    text: {
      backgroundColor: dividerBackgroundColor,
      color: dividerColor,
    },
  };

  return (
    <>
      <div className="d-flex pt-3">
        <div
          id={`eventListHeaderDate${id}`}
          style={styles.text}
          className="flex-fill text-left">
          {headerText}
        </div>
        {eventsNumber && (
          <div
            id={`eventListHeaderCount${id}`}
            style={styles.text}
            className="flex-fill text-right">{`${data.length} ${pluralize(
            t("Index.Heading.Event"),
            data.length
          )}`}</div>
        )}
      </div>

      {eventCards}
    </>
  );
};
