import { Member, UserDataState } from "../../../types";

import { runQuery } from "../../http";

import { getMember } from ".";

export const getMemberByProfile = async (
  profileId: string,
  currentUser?: UserDataState | null
): Promise<Member | null> => {
  console.debug("[Service] getMemberByProfile", profileId);

  const response: Parse.Object<Member> | null = await runQuery(
    "User",
    (query: Parse.Query) =>
      query
        .equalTo(
          "profile",
          new (Parse.Object.extend("Profile"))({ id: profileId })
        )
        .include("profile")
        .first()
  );

  if (!response) {
    return null;
  }

  const user = await getMember(response.id, currentUser);
  if (!user) {
    return null;
  }

  return user;
};
