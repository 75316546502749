import { adminPost } from "../../http";
import { Config } from "../../../types";

export const resumeSubscription = async (
  subscriptionId: string,
  config: Config
): Promise<{ message?: string; error?: string }> => {
  console.debug("[Service] resumeSubscription", subscriptionId);

  return await adminPost(
    "subscriptions/resume",
    {
      // eslint-disable-next-line @typescript-eslint/camelcase
      subscription_id: subscriptionId,
    },
    {
      requireUser: true,
      config,
    }
  );
};
