import React, { useEffect, useState } from "react";

import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "reactstrap";

import { ChatMutatedChannel } from "../../../../containers/Chat/chatTypes";
import MembersContainer from "../../../../containers/Member";
import {
  UserDataState,
  EventSingle,
  Group,
  EmptyObject,
} from "../../../../types";

import { useTheme } from "../../../hooks";

import { MemberPicker } from "../../Member/MemberPicker";

import { SettingsHeader } from "./SettingsHeader";
import { SettingsList } from "./SettingsList";
import { SettingsNotification } from "./SettingsNotification";

export type ChatSettingsProps = {
  channel: ChatMutatedChannel;
  event?: { data: EventSingle };
  group?: { data: Group };
  memberIDs?: string[];
  user: UserDataState | EmptyObject;
  notificationSettings: {
    mobileNotification?: "all" | "specific";
    desktopNotification?: "all" | "specific";
    mute?: boolean;
    ignoreMuteHereChannel?: boolean;
  };
  notificationSettingsDefault: {
    mobileNotification: "all" | "specific";
    desktopNotification: "all" | "specific";
    mute: boolean;
    ignoreMuteHereChannel: boolean;
  };
  notificationSettingsOptions: {
    value: "all" | "mention" | "nothing";
    key: string;
  }[];

  addMembers: (
    members: UserDataState[],
    callback?: (value: boolean | { data: string } | void) => void
  ) => void;
  leaveChannel: (callback?: () => void) => void;
  onChangeNotificationSettings: (
    name:
      | "mobileNotification"
      | "desktopNotification"
      | "mute"
      | "ignoreMuteHereChannel",
    value: string
  ) => void;
  removeMembers: (members: UserDataState[], callback?: () => void) => void;
  updateChannel: (name: string, callback?: (value: string) => void) => void;
};

export const ChatSettings: React.FC<ChatSettingsProps> = ({
  channel,
  event,
  group,
  memberIDs,
  notificationSettings,
  notificationSettingsDefault,
  notificationSettingsOptions,
  user,

  addMembers,
  leaveChannel,
  onChangeNotificationSettings,
  removeMembers,
  updateChannel,
}) => {
  const channelId = channel.id;

  useEffect(() => {
    setRightColumnVisibility(false);
  }, [channelId]);

  const {
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();

  const styles = {
    button: {
      borderColor: buttonBackgroundColor,
      backgroundColor: buttonBackgroundColor,
      color: buttonColor,
    },
  };

  const [isNotificationsModalOpen, setNotificationsModal] = useState(false);
  const [MembersModal, setMembersModal] = useState({
    open: false,
    isAddingMembers: false,
  });
  const [isRightColumnVisible, setRightColumnVisibility] = useState(false);

  const history = useHistory();

  const groupId = group?.data.id;

  const openNotificationsModal = (): void => setNotificationsModal(true);
  const closeNotificationsModal = (): void => setNotificationsModal(false);

  const openMembersModal = (addingMembers: boolean) => (): void =>
    setMembersModal({ open: true, isAddingMembers: addingMembers });
  const closeMembersModal = (): void =>
    setMembersModal({ open: false, isAddingMembers: false });

  const leave = (): void => {
    leaveChannel(() => {
      history.push("/chat-room/");
    });
  };

  const channelName: string | undefined =
    channel?.name === "Private Chat" || channel?.name === ""
      ? channel?.user?.nickname ?? channel?.displayName
      : channel?.name ?? channel?.displayName;

  const toggleRightColumnVisibility = (): void => {
    setRightColumnVisibility(!isRightColumnVisible);
  };

  const isChatNameEditable = !channel.isSystemChat && !channel.is1Vs1Chat;

  const isAdminOrEditor = user.admin || user.editor;

  const handleMembersAction = (
    user:
      | {
          id: string;
        }
      | {
          id: string;
        }[]
  ): void => {
    // @FIXME to investigate
    // there is typing error here, but function is working fine
    MembersModal.isAddingMembers
      ? addMembers(
          user as UserDataState[],
          (result: { data: string } | boolean | void) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            //@ts-ignore
            "data" in result &&
              history.push("/chat-room-detail/" + result.data);
          }
        )
      : removeMembers(user as UserDataState[]);
    closeMembersModal();
  };

  const filteredMemberIDs = MembersModal.isAddingMembers
    ? undefined
    : memberIDs;

  const infoButtonElement = (
    <Button
      className={classnames(
        "invisible right-column-visibility-button",
        isRightColumnVisible ? "button-right-icon-arrow" : ""
      )}
      onClick={toggleRightColumnVisibility}
      style={styles.button}>
      {!isRightColumnVisible ? (
        <span>Info</span>
      ) : (
        <span className={"icon-arrow-down"} />
      )}
    </Button>
  );

  const containerClassName = `sendbird-app__settingspanel-wrap ${
    isRightColumnVisible ? "visible" : "invisible"
  }`;

  return (
    <div className={containerClassName}>
      {infoButtonElement}
      <div className="sendbird-channel-settings">
        <div className="mt-5 sendbird-channel-settings__header">
          <div className="sendbird-label sendbird-label--h-2 sendbird-label--color-onbackground-1">
            Channel information
          </div>
        </div>
        <div className="sendbird-channel-settings__scroll-area">
          <SettingsHeader
            coverUrl={channel.coverUrl}
            name={channelName ?? "Untitled"}
            updateChannel={updateChannel}
            isChatNameEditable={isChatNameEditable}
          />
          <SettingsList
            channel={channel}
            event={event}
            groupId={groupId}
            isAdminOrEditor={isAdminOrEditor}
            leave={leave}
            memberIDs={memberIDs}
            openMembersModal={openMembersModal}
            openNotificationsModal={openNotificationsModal}
          />
        </div>
      </div>
      <Modal isOpen={isNotificationsModalOpen} className="p-4">
        <SettingsNotification
          closeNotificationsModal={closeNotificationsModal}
          notificationSettings={notificationSettings}
          notificationSettingsDefault={notificationSettingsDefault}
          notificationSettingsOptions={notificationSettingsOptions}
          onChangeNotificationSettings={onChangeNotificationSettings}
        />
      </Modal>
      <Modal isOpen={MembersModal.open} className="p-4">
        <MembersContainer
          Layout={MemberPicker}
          onClose={closeMembersModal}
          onDone={handleMembersAction}
          filteredMemberIDs={filteredMemberIDs}
          // props below were necessary but I don't really use them
          renderActions
          renderActionsDone
        />
      </Modal>
    </div>
  );
};
