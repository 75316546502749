export const POST_ALL_SET = "POST_ALL_SET" as const;
export const POST_COMMENT_SET = "POST_COMMENT_SET" as const;
export const POST_COMMENT_UPDATE = "POST_COMMENT_UPDATE" as const;
export const POST_DRAFT_CLEAR = "POST_DRAFT_CLEAR" as const;
export const POST_DRAFT_SET = "POST_DRAFT_SET" as const;
export const POST_FILES_SET = "POST_FILES_SET" as const;
export const POST_GROUP_SET = "POST_GROUP_SET" as const;
export const POST_IMAGES_SET = "POST_IMAGES_SET" as const;
export const POST_REACTION_SET = "POST_REACTION_SET" as const;
export const POST_REMOVE = "POST_REMOVE" as const;
export const POST_RESET = "POST_RESET" as const;
export const POST_SEARCH_HISTORY_SET = "POST_SEARCH_HISTORY_SET" as const;
export const POST_SEARCH_SET = "POST_SEARCH_SET" as const;
export const POST_SET = "POST_SET" as const;
export const POST_USER_SET = "POST_USER_SET" as const;
