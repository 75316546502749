/* eslint-disable @typescript-eslint/camelcase*/
import React, { Component, ComponentType } from "react";

import { connect } from "react-redux";

import {
  NotifiacationLandingTweet,
  NotificationLandingMessageWithAuthor,
  RootState,
} from "../../types";

import { getLandingMessages, getLandingTweets } from "./action";
import { LandingMessageSetAction, LandingTweetSetAction } from "./types";
import {
  getLandingTweetsDataState,
  getLandingMessagesDataState,
  getLandingNotificationSettingState,
} from "./selector";

export type NotificationLandingStateProps = {
  landingMessages: NotificationLandingMessageWithAuthor[] | null;
  landingTweets: NotifiacationLandingTweet[] | null;
  setting: {
    logo: string;
    backgroundImage: string;
  };
};

export type NotificationLandingDispatchProps = {
  getLandingMessages: (
    refresh: boolean
  ) => Promise<void | LandingMessageSetAction>;
  getLandingTweets: (refresh: boolean) => Promise<void | LandingTweetSetAction>;
};

export type NotificationLandingLayoutProps = {
  error: string | null;
  landingMessages: NotificationLandingMessageWithAuthor[] | null;
  landingTweets: NotifiacationLandingTweet[] | null;
  refreshing: boolean;
  setting: {
    logo: string;
    backgroundImage: string;
  };

  reFetch: (refresh: boolean, callback?: () => void) => void;
};

export type NotificationLandingOwnProps = {
  Layout: ComponentType<NotificationLandingLayoutProps>;
};

export type NotificationLandingProps = NotificationLandingOwnProps &
  NotificationLandingDispatchProps &
  NotificationLandingStateProps;

type NotificationLandingState = {
  error: string | null;
  refreshing: boolean;
};

class LandingPage extends Component<
  NotificationLandingProps,
  NotificationLandingState
> {
  constructor(props: NotificationLandingProps) {
    super(props);
    this.state = {
      error: null,
      refreshing: false,
    };
  }

  componentDidMount(): void {
    this.fetchData(true);
  }

  fetchData = (refresh: boolean, callback?: () => void): void => {
    const { getLandingMessages, getLandingTweets } = this.props;
    const { refreshing } = this.state;
    if (refreshing) {
      return;
    }

    this.setState({ refreshing: true }, async () => {
      try {
        Promise.all([getLandingMessages(refresh), getLandingTweets(refresh)]);

        this.setState({ refreshing: false, error: null });

        callback?.();
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, landingMessages, landingTweets, setting } = this.props;
    const { error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        refreshing={refreshing}
        landingMessages={landingMessages}
        landingTweets={landingTweets}
        setting={setting}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (state: RootState): NotificationLandingStateProps => {
  return {
    landingMessages: getLandingMessagesDataState(state),
    landingTweets: getLandingTweetsDataState(state),
    setting: getLandingNotificationSettingState(state),
  };
};

const mapDispatchToProps = {
  getLandingMessages: getLandingMessages,
  getLandingTweets: getLandingTweets,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
