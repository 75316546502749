import React, { useState, useContext } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Link,
  NavLink as RouterNavLink,
  useRouteMatch,
} from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar as NavbarReactstrap,
  NavbarToggler,
  NavItem,
  NavLink,
  NavbarBrand,
} from "reactstrap";

import IconBadgeContainer from "../../../containers/routers/IconBadge";
import { UserDataState } from "../../../types";

import { ConfigContext } from "../../context";
import { useTheme } from "../../hooks";

import { IconBadge, Icon, NavbarDropdown, TooltipItem } from ".";

export type NavbarProps = {
  user: UserDataState;
};

export const Navbar: React.FC<NavbarProps> = ({ user }) => {
  const { navigationFeatures, navigationFeaturesMore, setting } = useContext(
    ConfigContext
  );

  const logo = setting?.setting.logo_title;

  const appName =
    setting?.localConfig.display_name || setting?.localConfig.name;

  const [isNavigationOpen, setNavigationOpen] = useState(false);

  const toggleNavigation = (): void => setNavigationOpen(!isNavigationOpen);

  const handleToggleNavigation = (): void => {
    if (isNavigationOpen) {
      toggleNavigation();
    }
  };

  const {
    navigation: { backgroundColor, color },
  } = useTheme();

  const { t } = useTranslation("Navbar");

  const isUserActive = user.status === "active";

  const chatRoomDetailMatch = useRouteMatch("/chat-room-detail");

  const navItemElements = navigationFeatures.map(
    ({ icon, title, id, ...navLinkProps }, index) => (
      <NavItem key={index} className="d-block">
        <TooltipItem id={id || "menu"} title={title} position="bottom">
          <NavLink
            {...navLinkProps}
            id={id}
            onClick={handleToggleNavigation}
            style={{ color, borderColor: backgroundColor }}
            className={classnames("align-items-center", {
              active: chatRoomDetailMatch && navLinkProps.to === "/chat-room",
            })}>
            <Icon type={icon.type} name={icon.on} style={{ height: 24 }} />
            <span className="pl-3 d-md-none">{title}</span>
          </NavLink>
        </TooltipItem>
      </NavItem>
    )
  );

  const notificationNavlinkItemElement = isUserActive && (
    <NavItem className="d-block">
      <TooltipItem
        id={t("Navbar.Notifications")}
        title={t("Navbar.Notifications")}
        position="bottom">
        <NavLink
          style={{ borderColor: backgroundColor }}
          id="navbarNotificationsLink"
          tag={RouterNavLink}
          to="/notifications"
          className="align-items-center"
          onClick={handleToggleNavigation}>
          <IconBadgeContainer
            Layout={IconBadge}
            id="notifications"
            style={{}}
            active
            type="Feather"
            name="bell"
            badgeStyle={{}}
          />
          <span className="pl-3 d-md-none">{t("Navbar.Notifications")}</span>
        </NavLink>
      </TooltipItem>
    </NavItem>
  );

  const chatNavlinkItemElement = isUserActive && (
    <NavItem className="d-block">
      <TooltipItem
        id={t("Navbar.Chat")}
        title={t("Navbar.Chat")}
        position="bottom">
        <NavLink
          style={{ borderColor: backgroundColor }}
          id="chatNavlinkItemElement"
          tag={RouterNavLink}
          to="/chat-room"
          className="align-items-center"
          onClick={handleToggleNavigation}>
          <IconBadgeContainer
            Layout={IconBadge}
            id="chat-room"
            style={{}}
            active
            type="SVG"
            name="chatboxes"
            badgeStyle={{}}
          />
          <span className="pl-3 d-md-none">{t("Navbar.Chat")}</span>
        </NavLink>
      </TooltipItem>
    </NavItem>
  );

  const searchNavlinkItemElement = isUserActive && (
    <NavItem className="d-block">
      <TooltipItem
        id={t("Navbar.Search")}
        title={t("Navbar.Search")}
        position="bottom">
        <NavLink
          style={{ borderColor: backgroundColor }}
          id="searchNavlinkItemElement"
          tag={RouterNavLink}
          to={{ pathname: "/search", state: { searchType: "post" } }}
          className="align-items-center"
          onClick={handleToggleNavigation}>
          <Icon
            name={"search"}
            type={"FontAwesome"}
            style={{ fontSize: 20 }}
            title={t("Navbar.Search")}
          />
          <span className="pl-3 d-md-none">{t("Navbar.Search")}</span>
        </NavLink>
      </TooltipItem>
    </NavItem>
  );

  return (
    <NavbarReactstrap
      dark
      expand="md"
      fixed="top"
      className="p-0"
      style={{ backgroundColor }}>
      <div className="navbar-container justify-content-between">
        <NavbarBrand
          tag={Link}
          id="appName"
          to="/"
          className="d-flex navbar-brand align-items-center pl-2 d-md-none d-xl-flex"
          style={{ color }}>
          {!!logo && <img className="mr-2 p-1" src={logo} alt={appName} />}
          {!logo && <span>{appName}</span>}
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavigation} />
      </div>

      <Collapse isOpen={isNavigationOpen} navbar>
        <Nav navbar={true}>
          <div className="navbar-main-features">{navItemElements}</div>

          <div className="navbar-right-column">
            <NavbarDropdown
              toggleNavigation={handleToggleNavigation}
              navigationFeaturesMore={navigationFeaturesMore}
              user={user}
            />
            {chatNavlinkItemElement}
            {notificationNavlinkItemElement}

            {searchNavlinkItemElement}
          </div>
        </Nav>
      </Collapse>
    </NavbarReactstrap>
  );
};

export default Navbar;
