import { adminPost } from "../../http";
import { Config } from "../../../types";

export const cancelSubscription = async (
  subscriptionId: string,
  config: Config
): Promise<{ message?: string; error?: string }> => {
  console.debug("[Service] cancelSubscription", subscriptionId);

  return await adminPost(
    "subscriptions/cancel",
    {
      // eslint-disable-next-line @typescript-eslint/camelcase
      subscription_id: subscriptionId,
    },
    {
      requireUser: true,
      config,
    }
  );
};
