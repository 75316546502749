/* eslint-disable @typescript-eslint/camelcase */
import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { register, logIn } from "./action";
import { isEmpty } from "underscore";
import { isValidPhone, isValidEmail } from "../../lib/utils";
import { UserState } from "./types";
import {
  Dict,
  RootState,
  SettingSettingData,
  UserRegisterData,
} from "../../types";
import { SettingConfigData } from "../../types/Setting/SettingConfigData";
import { withTranslation, WithTranslation } from "react-i18next";
import { MediaFileType } from "../../types";
import { CountryCode } from "react-native-country-picker-modal";

export type UserRegisterStateProps = {
  user: UserState;
  setting: {
    organizationName: string;
    logo: string;
    backgroundImage: string;
    signInByEmail?: boolean;
    signUpEnabled?: boolean;
  };
};

export type UserRegisterDispatchProps = {
  register: (
    data: UserRegisterData,
    avatar?: string | { file: MediaFileType }
  ) => Promise<{ data: string }>;
  logIn: (username: string, password: string) => Promise<void>;
};

export type UserRegisterLayoutProps = {
  data: UserRegisterDataState;
  dataError: {
    firstName?: boolean;
    lastName?: boolean;
    phoneNumber?: boolean;
    email?: boolean;
    password?: boolean;
    confirmPassword?: boolean;
  };
  disabled?: boolean;
  error: string | null;
  /**
   * @FIXME
   * Global standard is `loading`
   */
  sending: boolean;
  setting: {
    backgroundImage: string;
    logo: string;
    organizationName: string;
    signInByEmail?: boolean;
    signUpEnabled?: boolean;
  };

  handleChange: <K extends UserRegisterDataKey>(
    name: K,
    val: UserRegisterDataState[K]
  ) => void;
  register: (
    callback: (text: string, fun: () => void) => void
  ) => Promise<void>;
};

export type UserRegisterProps = {
  Layout: ComponentType<UserRegisterLayoutProps>;
} & UserRegisterStateProps &
  UserRegisterDispatchProps &
  WithTranslation;

const userRegisterDataKeys = [
  "countryCode",
  "firstName",
  "lastName",
  "phoneNumber",
  "email",
  "password",
  "passwordText",
  "confirmPassword",
  "avatar",
] as const;
export type UserRegisterDataKey = typeof userRegisterDataKeys[number];

export type UserRegisterDataState = Omit<
  Dict<string, UserRegisterDataKey>,
  "avatar" | "countryCode"
> & {
  avatar: { file: MediaFileType } | string;
  countryCode: CountryCode;
};

export type UserRegisterState = {
  error: string | null;
  sending: boolean;
  data: UserRegisterDataState;
  dataError: {
    firstName?: boolean;
    lastName?: boolean;
    phoneNumber?: boolean;
    email?: boolean;
    password?: boolean;
    confirmPassword?: boolean;
  };
  disabled?: boolean;
};

class SignUp extends Component<UserRegisterProps, UserRegisterState> {
  constructor(props: UserRegisterProps) {
    super(props);

    const { signInByEmail } = props.setting;
    this.state = {
      error: null,
      sending: false,
      data: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "+1",
        password: "",
        confirmPassword: "",
        countryCode: "US",
        passwordText: signInByEmail
          ? this.props.t("Container.Register.Password")
          : this.props.t("Container.Register.Passcode"),
        avatar: "",
      },
      dataError: {},
    };
  }

  componentDidMount(): void {
    const { signUpEnabled } = this.props.setting;
    if (!signUpEnabled) {
      this.setState({
        error: this.props.t("Container.Register.Error"),
        disabled: true,
      });
    }
  }

  onHandleChange = <K extends UserRegisterDataKey>(
    name: K,
    val: UserRegisterDataState[K]
  ): void => {
    const { data } = this.state;
    data[name] = val;
    this.setState({ data });
  };

  onValidation = (): boolean => {
    const { data } = this.state;
    const { setting } = this.props;
    const { signInByEmail } = setting;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmPassword,
    } = data;
    const dataError = {
      firstName: isEmpty(firstName),
      lastName: isEmpty(lastName),
      phoneNumber:
        (isEmpty(phoneNumber) && !signInByEmail) ||
        !isValidPhone(phoneNumber, signInByEmail, true),
      email:
        (isEmpty(email) && signInByEmail) ||
        !isValidEmail(email, !signInByEmail),
      password: (isEmpty(password) || password.length < 8) && signInByEmail,
      confirmPassword: confirmPassword !== password && signInByEmail,
    };
    this.setState({ dataError });
    return Object.values(dataError).filter(value => value).length === 0;
  };

  onRegister = async (
    callback: (text: string, fun: () => void) => void
  ): Promise<void> => {
    try {
      const { register, setting, logIn } = this.props;
      const { sending, data } = this.state;
      if (sending) {
        return;
      }

      if (!this.onValidation()) {
        return;
      }
      this.setState({ sending: true });

      const { signInByEmail } = setting;
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        avatar,
      } = data;
      const customData = {
        firstName,
        lastName,
        phoneNumber,
        password,
        email: email && email.toLowerCase(),
        username: signInByEmail ? email && email.toLowerCase() : phoneNumber,
        signUpByEmail: signInByEmail,
      };

      const resp = await register(customData, avatar);
      if (resp.data) {
        this.setState({ sending: false, error: null });
        const { username } = customData;
        const text = this.props.t("Container.Register.Successfully", {
          setting,
        });
        callback &&
          callback(text, () => {
            logIn(username, resp.data);
          });
      }
    } catch (error) {
      this.setState({ sending: false, error: error });
    }
  };

  render = (): JSX.Element => {
    const { Layout, setting } = this.props;
    const { error, sending, disabled, data, dataError } = this.state;

    return (
      <Layout
        error={error}
        sending={sending}
        disabled={disabled}
        data={data}
        dataError={dataError}
        setting={setting}
        register={this.onRegister}
        handleChange={this.onHandleChange}
      />
    );
  };
}
const mapStateToProps = (state: RootState): UserRegisterStateProps => {
  const { setting, config } = state.setting;
  const {
    logo_large,
    sign_up_page_background_image,
    enable_signin_by_email,
    signup_enabled,
  } = setting as SettingSettingData;
  const { organizationName } = config as SettingConfigData;
  return {
    user: state.user,
    setting: {
      organizationName,
      logo: logo_large,
      backgroundImage: sign_up_page_background_image,
      signInByEmail: enable_signin_by_email,
      signUpEnabled: signup_enabled,
    },
  };
};

const mapDispatchToProps = {
  register,
  logIn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("User")(SignUp));
