import React from "react";

import { RouteComponentProps } from "react-router-dom";

import SupportContainer from "../../../containers/Support";

import { SupportFeedback } from "../../components/Support";

export type RouteSupportFeedbackProps = RouteComponentProps;

export const RouteSupportFeedback: React.FC<RouteSupportFeedbackProps> = props => (
  <SupportContainer {...props} Layout={SupportFeedback} />
);
