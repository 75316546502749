import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

export type UserRegisterBlockProps = {};

export const UserRegisterBlock: React.FC<UserRegisterBlockProps> = () => {
  /**
   * @TODO
   * Temporarily partly copied here. Needs deep refactor.
   */
  const userLoginStyles = {
    text: {
      color: "#fff",
      textAlign: "center",
    },
  } as const;

  const history = useHistory();

  const {
    button: buttonStyle,
    text: { listTitle: listTitleStyle },
  } = useTheme();

  const { t } = useTranslation("User");

  const redirectToSignUp = (): void => history.push("/sign-up");

  return (
    <div className="mb-3 mt-3">
      <p
        className="mb-3"
        style={{
          ...{
            ...listTitleStyle,

            /** @TODO Theming workaround. Delete when issue solved. */
            ...textThemingWorkaround,
          },
          ...userLoginStyles.text,
        }}>
        {t("Login.Account")}
      </p>
      <Button
        block
        id="SignUpButton"
        outline
        style={{
          color: "#fff",
          borderColor: "#fff",
          borderRadius: buttonStyle.borderRadius,
        }}
        onClick={redirectToSignUp}>
        {t("Login.Button.Sign.Up")}
      </Button>
    </div>
  );
};
