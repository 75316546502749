import React, { ChangeEvent, useState } from "react";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
import { SearchBox, StickyNavbar } from "../../UI";

import { useTheme } from "../../../hooks";
import { GroupCardPlaceholder, List } from "..";
import { isDictionary } from "../../../../containers/Group/helpers";
import { GroupListLayoutProps } from "../../../../containers/Group";
import {
  GroupByStatusTab,
  GroupByTypeTab,
} from "../../../../containers/Group/types";
import {
  getChunkedListItemsToRender,
  translateSectionDictionaryToList,
} from "../../../utils/dataMapper";

const GROUPS_PER_LOAD = 30;

export const GroupList: React.FC<GroupListLayoutProps> = ({
  data,
  activeTab,
  menuTabs,
  query,
  refreshing: isRefreshing,

  changeQuery,
  changeActiveTab,
}) => {
  const { t } = useTranslation("Group");

  const [countOfLoadedGroups, setCountOfLoadedGroups] = useState<number>(
    GROUPS_PER_LOAD
  );

  const {
    tabTop: { inactive, inactiveText, underlineColor },
  } = useTheme();

  const styles = {
    tabLink: {
      backgroundColor: inactive.backgroundColor,
      color: inactiveText.color,
      opacity: inactiveText.opacity,
    },
    tabLinkActive: {
      opacity: 1,
      borderColor: underlineColor,
    },
  };

  if (!data || !activeTab) {
    return <GroupCardPlaceholder />;
  }

  const isActiveTab = (tab: string): boolean => activeTab === tab;

  const loadMoreGroups = (maxVisibleGroupsNumber: number) => (): void => {
    setCountOfLoadedGroups(prevState => {
      const currentVisibleGroupsLength = prevState + GROUPS_PER_LOAD;

      return currentVisibleGroupsLength >= maxVisibleGroupsNumber
        ? maxVisibleGroupsNumber
        : currentVisibleGroupsLength;
    });
  };

  const sectionData = !isDictionary(data)
    ? data
    : translateSectionDictionaryToList(data);

  const listComponentProps = {
    activeTab,
    isRefreshing,
    hasMoreItemsToLoad:
      (sectionData && countOfLoadedGroups < sectionData.length) ?? false,
    allGroupItemsLength: sectionData?.length ?? 0,
    sectionData:
      sectionData &&
      getChunkedListItemsToRender(sectionData, countOfLoadedGroups),
    loadMoreGroups,
  };

  return (
    <>
      <StickyNavbar>
        <Nav tabs id="groupListNavLinks">
          {menuTabs &&
            menuTabs.map(tab => (
              <NavItem key={tab}>
                <NavLink
                  id={`groupListNavLink${tab.replace(" ", "")}`}
                  active={isActiveTab(tab)}
                  style={{
                    ...styles.tabLink,
                    ...(isActiveTab(tab) && styles.tabLinkActive),
                  }}
                  onClick={(): void => {
                    changeActiveTab(tab as GroupByStatusTab | GroupByTypeTab);
                  }}>
                  {tab}
                </NavLink>
              </NavItem>
            ))}
        </Nav>

        <div className={"pt-3 pb-2"}>
          <SearchBox
            id={"groupSearchBar"}
            placeholder={t("ListGroupsByStatus.Placeholder.Search")}
            value={query}
            onValueChange={(event: ChangeEvent<HTMLInputElement>): void => {
              changeQuery(event.target.value);
            }}
          />
        </div>
      </StickyNavbar>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={activeTab}>
          <List {...listComponentProps} />
        </TabPane>
      </TabContent>
    </>
  );
};
