import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { groupByType, Tab } from "../../../../containers/Member/types";
import { useTheme } from "../../../hooks";

export type MemberListFiltersTabProps = {
  tabs?: Tab[];
  activeTab: groupByType;
  setActiveTab: (tab: groupByType) => void;
};

export const FilterTabs: React.FC<MemberListFiltersTabProps> = ({
  tabs,
  activeTab,
  setActiveTab,
}) => {
  const {
    tabTop: { inactive, inactiveText, underlineColor },
  } = useTheme();

  const styles = {
    tabLink: {
      backgroundColor: inactive.backgroundColor,
      color: inactiveText.color,
      opacity: inactiveText.opacity,
    },
    tabLinkActive: {
      opacity: 1,
      borderColor: underlineColor,
    },
  };

  const handleTabClick = (tabId: groupByType) => (): void => {
    setActiveTab(tabId);
  };

  if (!tabs) {
    return null;
  }
  return (
    <Nav tabs className="mb-2">
      {tabs.map(
        ({ id, title }) =>
          id !== "maps" && (
            <NavItem key={id} className="category-tabs">
              <NavLink
                id={"membersNavLink" + title}
                style={{
                  ...styles.tabLink,
                  ...(activeTab === id && styles.tabLinkActive),
                }}
                onClick={handleTabClick(id)}>
                {title}
              </NavLink>
            </NavItem>
          )
      )}
    </Nav>
  );
};
