import { AppItemList, AppItem, DictStrict } from "../../../types";

export const getInsertedNestedItemListIfExists = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  dataKeyName,
  newItem,
  groupedByValue,
  nestedItemList,
}: {
  newItem?: ItemType;
  groupedByValue?: string;
  /**
   * Name of property storing nested entity data (and optional
   * reactionCount property)
   *
   * @FIXME
   * We have to specify this key name because of inconsistency in
   * all available Items shapes (nested data is stored under entity
   * name property). Unifing that property name and setting it
   * for example to "data" would simplify app data structures.
   */
  dataKeyName: DataKey;
  nestedItemList: DictStrict<AppItemList<DataKey, ItemType>>;
}): DictStrict<AppItemList<DataKey, ItemType>> => {
  if (!groupedByValue || !newItem) return nestedItemList;

  const listToInsert = nestedItemList[groupedByValue];

  return listToInsert?.items
    ? {
        ...nestedItemList,
        [groupedByValue]: {
          ...listToInsert,
          items: {
            ...listToInsert.items,
            [newItem[dataKeyName].id]: newItem,
          },
        },
      }
    : nestedItemList;
};
