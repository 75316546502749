export const appSettingsFeatureNavbarAvailable = [
  "home",
  "members",
  /** Events Calendar */
  "events",
  /** Notifications */
  "notifications",
  /** Discussion Feed */
  "groups",
  /** My Groups */
  "group-list",
  /** Announcements */
  "feed",
  /**
   * Temporary workaround
   * Map
   */
  "maps",
  /**
   * Settings
   *
   * @TODO
   * Investigate this route and make sure is it the same as /user-settings
   */
  "settings",
  /** Search */
  "search",
  /** Messages */
  "chat-room",
  /** Payments */
  "payments",
  /** Resources */
  "content-pages",
  /** Video Library */
  "video-library",
  /** Feedback */
  "feedback",
  /** Page type section and Setting#home_group_type  */
  "home-group",
] as const;
