import _ from "underscore";
import { asyncForEach } from "../../../lib/utils";
import { SettingConfigData } from "../../../types/Setting/SettingConfigData";
import { ProgressCallback, PostUploadFile } from "../../../types";

export async function uploadFilesForPost(
  data: {
    files: PostUploadFile[];
    postId?: string;
    groupId: string;
    editing: boolean;
  },
  config?: SettingConfigData,
  progressCallback?: ProgressCallback,
  uploadingFallback?: () => Promise<void>
): Promise<string[]> {
  const { files, postId, groupId } = data;
  if (_.isEmpty(files)) {
    return [];
  }

  const $scope: {
    config: SettingConfigData;
    pathUpload: string | undefined;
    groupFileIds: string[];
  } = {
    config: config as SettingConfigData,
    pathUpload: config && config.S3PostPath + groupId + "/post/" + postId + "/",
    groupFileIds: [],
  };

  const startUpload = async (): Promise<void> => {
    await asyncForEach(files, async (file: PostUploadFile) => {
      if (!file.file.objectId) {
        // @FIXME window will be typed in separate commit
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        await window.Upload.resolveFile(
          {
            file: file.file,
            fileThumb: file.fileThumb,
            progressCallback,
          },
          $scope.pathUpload,
          $scope.config
        ).then((data: PostUploadFile) => {
          file.fileUrl = data.fileUrl;
          file.thumbUrl = data.thumbUrl;
        });
      } else {
        $scope.groupFileIds.push(file.file.objectId);
      }
    });
  };

  try {
    await startUpload();

    const author = Parse.User.current();
    const group = new (Parse.Object.extend("Group"))({ id: groupId });
    const post = new (Parse.Object.extend("Post"))({ id: postId });

    const unSavedGroupFiles: Parse.Object[] = [];
    _.forEach(files, item => {
      let groupFile;
      const { fileUrl, thumbUrl, file } = item;
      const { name, contentType, type, title, objectId } = file || {};
      if (!objectId) {
        groupFile = new (Parse.Object.extend("GroupFile"))({
          contentType: contentType || type,
          fileUrl,
          thumbUrl,
          name,
          title,
          group,
          author,
          post,
        });
        unSavedGroupFiles.push(groupFile);
      } else {
        groupFile = new (Parse.Object.extend("GroupFile"))({ id: objectId });
        groupFile.set("title", title);
        unSavedGroupFiles.push(groupFile);
      }
    });

    const groupFiles = await Parse.Object.saveAll(unSavedGroupFiles);
    return await _.union(
      $scope.groupFileIds,
      _.map(groupFiles, groupFile => {
        return groupFile.id;
      })
    );
  } catch (error) {
    uploadingFallback && uploadingFallback();
    throw error;
  }
}
