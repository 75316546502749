import _ from "underscore";
import { runFunction } from "../../http";
import { Reaction } from "../../../types/Comment";
import { convertObject } from "../../../lib/utils";
import { CommentApiOptions } from ".";
import { ReactionDict } from "../../../containers/Comment/types";
import { commentsTypes } from "../../../constants/Comment";

export const getReactions = async (
  options: CommentApiOptions
): Promise<ReactionDict> => {
  console.debug("[Action] getReactions");

  const { id, page, per, type } = options;
  const option = {
    eventId: id,
    announcementId: id,
    postId: id,
    eventPhotoId: id,
    per: per,
    page: page,
  };
  const parseResponse: Parse.Object<Reaction>[] = await runFunction(
    commentsTypes[type].functionGetReactions,
    option,
    {
      requireUser: true,
    }
  );

  return _.object(
    _.map(parseResponse, value => {
      return [value.id, convertObject(value)];
    })
  );
};
