import React, { FC, useEffect, useState } from "react";

import { connect, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ChatMutatedChannel } from "../../../containers/Chat/chatTypes";
import { ChatRoomAllSetAction } from "../../../containers/Chat/types";
import { getChatRooms } from "../../../containers/Chat/action";
import { RootState } from "../../../types";

import { useTypedSelector } from "../../hooks";
import { Empty } from "..";
import { ChatCardPlaceholder } from "./ChatCardPlaceholder";

export * from "./Conversation";
export * from "./List";
export * from "./List/ChannelsSidebar";
export * from "./Settings";
export * from "./Settings/SettingsSidebar";

type Props = {
  getChatRooms: (
    next: boolean,
    refresh?: boolean
  ) => Promise<ChatRoomAllSetAction>;
};

export const ChatRooms: FC<Props> = ({ getChatRooms }): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const channel = useSelector((state: RootState) => {
    const channels: ChatMutatedChannel[] | undefined =
      "CONVERSATIONS FULL" in state.chat.type
        ? state.chat.type["CONVERSATIONS FULL"]
        : [];
    return channels ? channels[0] : undefined;
  });
  const history = useHistory();

  const viewingAsGuest = useTypedSelector(state => state.user.viewingAsGuest);

  const { t } = useTranslation("Chat");

  useEffect(() => {
    if (!channel) {
      const fetchData = async (
        next: boolean,
        refresh: boolean
      ): Promise<void> => {
        try {
          setLoading(true);
          await getChatRooms(next, refresh);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };

      fetchData(false, true);
    }
  }, [channel, getChatRooms]);

  if (viewingAsGuest)
    return <Alert color="danger">{t("Index.Alert.Guest.Mode")}</Alert>;

  channel && history.replace("/chat-room-detail/" + channel.url);

  if (loading) {
    return <ChatCardPlaceholder />;
  }
  return <Empty text="No conversation yet!" />;
};

const mapDispatchToProps = {
  getChatRooms,
};

export const ChatRoomsContainer = connect(null, mapDispatchToProps)(ChatRooms);
