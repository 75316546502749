import _ from "underscore";
import { saveData } from "../../http";
import { ChangeAttendee } from "../../../types/Event/ChangeAttendee";
import { EventAttendee } from "../../../types/Event/EventAttendee";

export const addEventAttendee = async (
  data: ChangeAttendee
): Promise<EventAttendee> => {
  const {
    eventId,
    userId,
    guests,
    notifyMe,
    note,
    response,
    partnerResponse,
    guestMembers,
    partners,
    partnerMembers,
  } = data;
  const Attendee = Parse.Object.extend("Attendee");
  const value = {
    guests: guests || [],
    partners: partners || [],
    notifyMe: notifyMe || false,
    note,
    response,
    partnerResponse,
    event: new (Parse.Object.extend("Event"))({ id: eventId }),
    user: new Parse.User({ id: userId }),
    responseAt: new Date(),
  };
  const attendee = new Attendee(value);
  guestMembers &&
    _.forEach(guestMembers, guestMember => {
      // guestMembers data is not always provided
      attendee.relation("guestMembers").add(guestMember);
    });
  _.forEach(partnerMembers as { objectId?: string }[], partnerMember => {
    attendee.relation("partnerMembers").add(partnerMember);
  });
  if (response === "no") {
    value["partnerResponse"] = "no";
  }
  return saveData(attendee, null, { spinner: false });
};
