import React, { Component } from "react";
import { connect } from "react-redux";
import { groupBy, map, sortBy } from "underscore";

import { RootState, Member } from "../../types";

export type MemberDetails = {
  title: string;
  data: Member[];
};

export type MembersData = MemberDetails[];

export type MembersGroupByLayout = {
  data: MembersData;
  filteredData: MembersData | null;
  filter: string | null;
  segment: keyof Member;
  changeFilterType: (value: string | null) => void;
  loading?: boolean;
  simpleMode?: boolean;
  isMemberMixedLayout?: boolean;
  countOfLoadedNumbers?: number;
  loadMoreMembers?: (maxVisibleMembersNumber: number) => () => void;
  createChat?: (member: Member) => Promise<string | undefined>;
};

export type MembersGroupByOwnProps = {
  Layout: React.ComponentType<MembersGroupByLayout>;
  filter?: string;
  segment: keyof Member;
  loading?: boolean;
  simpleMode?: boolean;
  isMemberMixedLayout?: boolean;
  countOfLoadedNumbers?: number;
  loadMoreMembers?: (maxVisibleMembersNumber: number) => () => void;
  createChat?: (member: Member) => Promise<string | undefined>;
};

export type MembersGroupByStateProps = {
  data: MembersData;
};

export type MembersGroupByProps = MembersGroupByOwnProps &
  MembersGroupByStateProps;

export type MembersGroupByState = {
  loading: false;
  refreshing: false;
  filter: string | null;
  filteredData: MembersData | null;
};

class MembersGroupBy extends Component<
  MembersGroupByProps,
  MembersGroupByState
> {
  state: MembersGroupByState = {
    loading: false,
    refreshing: false,
    filter: null,
    filteredData: null,
  };

  onChangeFilterType = (value: string | null): void => {
    const filteredData = !value
      ? null
      : this.props.data.filter(({ title }) => title === value);
    this.setState({ filter: value, filteredData });
  };

  render = (): JSX.Element => {
    const {
      Layout,
      data,
      segment,
      loading = false,
      simpleMode,
      isMemberMixedLayout = false,
      countOfLoadedNumbers,
      loadMoreMembers,
      createChat,
    } = this.props;
    const { filteredData, filter } = this.state;

    return (
      <Layout
        data={data}
        segment={segment}
        filteredData={filteredData}
        filter={filter}
        loading={loading}
        simpleMode={simpleMode}
        isMemberMixedLayout={isMemberMixedLayout}
        countOfLoadedNumbers={countOfLoadedNumbers}
        loadMoreMembers={loadMoreMembers}
        createChat={createChat}
        changeFilterType={this.onChangeFilterType}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MembersGroupByOwnProps
): MembersGroupByStateProps => {
  const data = (state.member.items && Object.values(state.member.items)) || [];

  const { filter, segment } = ownProps;

  /**
   * @TODO This should be probably moved somewhere else
   */
  const dataGroupBy = sortBy(
    map(
      groupBy(
        data.filter(
          user =>
            user[segment] &&
            user[segment] !== "undefined" &&
            (!filter || user[segment] === filter)
        ),
        segment
      ),
      (data, title) => ({ title, data })
    ),
    "title"
  );

  return {
    data: dataGroupBy,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MembersGroupBy);
