import React, { CSSProperties } from "react";

import classnames from "classnames";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";

import { Dict } from "../../../types";

export type ResetOption = { name?: string; value?: undefined | null | string };

export type DropdownProps<T extends string> = {
  id?: string;
  className?: string;
  options: Dict<string, T> | T[];
  value: T;
  textStyle?: CSSProperties;
  style?: CSSProperties;

  onChange: (value: T) => void;
};

export const Dropdown = <T extends string>({
  id,
  className,
  options,
  style,
  textStyle,
  value,

  onChange,
}: DropdownProps<T>): React.ReactElement => {
  const handleItemClick = (clickedValue: T) => (): void => {
    /** Avoids unnecessary rerenders caused by action calls */
    if (clickedValue === value) {
      return;
    }

    onChange(clickedValue);
  };

  const getOptionName = (option: T): string =>
    options instanceof Array ? option : options[option];

  const currentValueName = getOptionName(value);

  return (
    <UncontrolledDropdown className={classnames(className)}>
      <DropdownToggle style={style} id={id} caret>
        <span style={textStyle}>{currentValueName}</span>
      </DropdownToggle>
      <DropdownMenu>
        {(Object.keys(options) as T[]).map((option, index) => (
          <DropdownItem
            id={`dropdownItem${option}`}
            key={index}
            onClick={handleItemClick(option)}>
            {getOptionName(option)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
