import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

export type ErrorProps = {
  content?: React.ReactNode;
  isLink?: boolean;
  title?: React.ReactNode;
};

/**
 * @TODO Translate Home Button. Consider moving button to independent module before.
 */
export const Error: React.FC<ErrorProps> = ({
  content,
  isLink = true,
  title,
}) => {
  const { t } = useTranslation("UI");

  return (
    <Row>
      <Col lg="4">
        <h2>{title || t("Error.Title")}</h2>
        <p>{content || t("Error.Content")}</p>
        {isLink && (
          <p>
            <Link to="/" className="btn btn-primary">
              {t("Error.Link.Text")}
            </Link>
          </p>
        )}
      </Col>
    </Row>
  );
};

export default Error;
