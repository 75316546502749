import { CommentResponse } from "../../../types";

import { getArrayWithUpdatedValue } from "../store";

type GetItemWithCommentUpdated = {
  <ItemType extends { comments: CommentResponse[] } | undefined>(options: {
    comment: CommentResponse;
    /** Item to update */
    item: ItemType;
  }): ItemType;
  <ItemType extends { comments: CommentResponse[] }>(options: {
    comment: CommentResponse;
    /** Item to update */
    item: ItemType;
  }): ItemType;
  (options: {
    comment: CommentResponse;
    /** Item to update */
    item: undefined;
  }): undefined;
};

export const getItemWithCommentUpdated: GetItemWithCommentUpdated = <
  ItemType extends { comments: CommentResponse[]; [key: string]: any }
>({
  item,
  comment,
}: {
  /** Item to update */
  item: any;
  comment: CommentResponse;
}): ItemType | undefined => {
  if (!item) {
    return undefined;
  }

  return {
    ...item,
    comments: getArrayWithUpdatedValue(comment, item.comments),
  };
};
