import React from "react";

import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import Select, { ValueType, ActionMeta } from "react-select";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { isEmpty } from "underscore";

import {
  Dict,
  UserProfessionalInfo,
  UserProfessionalInfoType,
} from "../../../../types";

import { LoadingSpinner } from "../../UI";

import { handleProfessionalInfoChangeType } from "../UserEditProfile";

export type UserProfileProfessionalInfoProps = {
  data: UserProfessionalInfoType | null;
  industryOptions: {
    id: string;
    name: string;
  }[];
  isPhoneProfessionalValid: boolean;
  isWebsiteValid: boolean;
  multipleSelectFields: string[];
  regionOptions: {
    id: string;
    name: string;
  }[];
  templateProfessional: string[];
  visibilities: Dict<boolean, UserProfessionalInfo>;

  locationPicker: (
    value: string,
    id: string,
    type: string,
    onChange?: (name: string, value: string) => void
  ) => JSX.Element;
  onAddCompanyHistory: () => void;
  onProfessionalInfoChange: handleProfessionalInfoChangeType;
  onRemoveCompanyHistory: () => void;
  onVisibilityChange: (name: string, value: string) => void;
  phoneOtherValidate: (phone: string) => boolean;
  setPhoneProfessionalValid: (value: boolean) => void;
  setWebsiteValid: (value: boolean) => void;
  websiteValidate: (url: string) => boolean;
};

type SelectOption =
  | { label: string; value: string }
  | {
      label: string;
      value: string;
      id: string;
    }[];

type OnSelectChange = (
  option: ValueType<SelectOption>,
  action: ActionMeta<SelectOption>
) => void;

type MultipleValues =
  | {
      value: string;
      label: string;
      id: string;
    }[]
  | null;

export const UserProfileProfessionalInfo: React.FC<UserProfileProfessionalInfoProps> = ({
  data,
  industryOptions,
  isPhoneProfessionalValid,
  isWebsiteValid,
  multipleSelectFields,
  regionOptions,
  templateProfessional,
  visibilities,

  locationPicker,
  onAddCompanyHistory,
  onProfessionalInfoChange,
  onRemoveCompanyHistory,
  onVisibilityChange,
  phoneOtherValidate,
  setPhoneProfessionalValid,
  setWebsiteValid,
  websiteValidate,
}) => {
  const { t } = useTranslation("User");

  if (!data) {
    return <LoadingSpinner />;
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onProfessionalInfoChange("Phone", e.target.value);
    const isPhoneValid = phoneOtherValidate(e.target.value);
    setPhoneProfessionalValid(isPhoneValid);
  };

  const handleWebsiteChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onProfessionalInfoChange("Website", e.target.value);
    const isWebsitValid = websiteValidate(e.target.value);
    setWebsiteValid(isWebsitValid);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onProfessionalInfoChange(
      e.target.name as keyof UserProfessionalInfoType,
      e.target.value
    );
  };

  const handleSelectChange: OnSelectChange = (option, action) => {
    if (action.name && option) {
      const values = !Array.isArray(option)
        ? (option as { label: string; value: string }).value
        : option.map(obj => obj.id);

      onProfessionalInfoChange(action.name as "Industry" | "Region", values);
    }

    if (action.name && !option) {
      onProfessionalInfoChange(action.name as "Industry" | "Region", []);
    }
  };

  const handleVisibilityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onVisibilityChange(
      e.target.name as keyof UserProfessionalInfoType,
      (e.target.checked as unknown) as string
    );
  };

  const shouldDisplayElement = (
    element: keyof UserProfessionalInfoType
  ): boolean => templateProfessional.includes(element);

  const renderSelectOptions = (
    multiple: boolean,
    options: {
      id: string;
      name: string;
    }[]
  ): { value: string; label: string; id?: string }[] =>
    options &&
    (multiple
      ? options.map(option => ({
          value: option.name,
          label: option.name,
          id: option.id,
        }))
      : options.map(option => ({
          value: option.name,
          label: option.name,
        })));

  const industrySelectValue = data.Industry
    ? typeof data.Industry === "string"
      ? { label: data.Industry, value: data.Industry }
      : data.Industry.map(option => ({ value: option, label: option }))
    : null;

  const renderSelectValues = (
    data: string | string[],
    options: {
      id: string;
      name: string;
    }[]
  ): MultipleValues =>
    !isEmpty(multipleSelectFields) &&
    options &&
    Array.isArray(data) &&
    !isEmpty(data)
      ? data.map(item => {
          const option = options.find(value => value.id === item);
          if (option) {
            const selectValue = {
              value: option.name,
              label: option.name,
              id: option.id,
            };

            return selectValue;
          } else {
            const selectValue = { value: "", label: "", id: "" };
            return selectValue;
          }
        })
      : null;

  const industryPlaceholder = t("Update.Profile.Professional.Info.Industry");

  const regionPlaceholder = t("Update.Profile.Professional.Info.Region");

  const industrySelectElement = isEmpty(multipleSelectFields) && (
    <Select
      name="Industry"
      id="userEditProfileProfessionalInfoIndustrySelect"
      options={renderSelectOptions(false, industryOptions)}
      value={industrySelectValue}
      placeholder={industryPlaceholder}
      onChange={handleSelectChange}
      isClearable={true}
    />
  );

  const industryMultipleSelectElement = !isEmpty(multipleSelectFields) && (
    <Select
      name="Industry"
      id="userEditProfileProfessionalInfoIndustryMultipleSelect"
      options={renderSelectOptions(true, industryOptions)}
      value={renderSelectValues(data.Industry, industryOptions)}
      placeholder={industryPlaceholder}
      isMulti={true}
      onChange={handleSelectChange}
    />
  );

  const regionElement = shouldDisplayElement("Region") &&
    !isEmpty(multipleSelectFields) && (
      <FormGroup>
        <Row className="mt-4 font-weight-bold">
          <Col>
            <Label id="userEditProfileProfessionalInfoRegionLabel">
              {regionPlaceholder}
            </Label>
          </Col>
          <Col className="text-right">
            <CustomInput
              type="switch"
              id="userEditProfileProfessionalInfoRegionSwitch"
              name="Region"
              defaultChecked={visibilities["Region"]}
              onChange={handleVisibilityChange}
            />
          </Col>
        </Row>
        <Select
          name="Region"
          id="userEditProfileProfessionalInfoRegionMultipleSelect"
          options={renderSelectOptions(true, regionOptions)}
          value={renderSelectValues(data.Region, regionOptions)}
          placeholder={regionPlaceholder}
          isMulti={true}
          onChange={handleSelectChange}
        />
      </FormGroup>
    );

  const companyHistory = Array.isArray(data["Company History"])
    ? data["Company History"]
    : [];

  const today = new Date();

  const companyHistoryListElement = companyHistory.map((item, i) => (
    <CSSTransition key={i} timeout={200} classNames="css-transition">
      <div>
        <FormGroup>
          <Label id="userEditProfileProfessionalInfoCompanyHistoryNameLabel">
            {t("Update.Profile.Professional.Info.Company.History.Company")}
          </Label>
          <Input
            type="text"
            name="name"
            id={`userProfessionalInfoCompanyHistory${item.name}`}
            placeholder={t(
              "Update.Profile.Professional.Info.Company.History.Company"
            )}
            value={item.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              companyHistory[i] = {
                ...companyHistory[i],
                name: e.target.value,
              };
              onProfessionalInfoChange("Company History", companyHistory);
            }}
          />
        </FormGroup>

        <FormGroup>
          <Row className="mt-3">
            <Col className="mr-n4">
              <Label
                id={`userEditProfileProfessionalInfoCompanyFromDateLabel${item.name}`}>
                {t("Update.Profile.Professional.Info.Company.History.From")}
              </Label>
              <DatePicker
                className="form-control"
                maxDate={item.todate > today ? today : item.todate}
                name="fromDate"
                id={`userPersonalInfoCompanyFromDate${item.name}`}
                selected={item.fromdate ? new Date(item.fromdate) : null}
                onChange={(date: Date): void => {
                  companyHistory[i] = {
                    ...companyHistory[i],
                    fromdate: date,
                  };
                  onProfessionalInfoChange("Company History", companyHistory);
                }}
                placeholderText="MM/DD/YYYY"
              />
            </Col>
            <Col>
              <Label
                id={`userEditProfileProfessionalInfoCompanyToDateLabel${item.name}`}>
                {t("Update.Profile.Professional.Info.Company.History.To")}
              </Label>
              <DatePicker
                className="form-control"
                minDate={item.fromdate}
                name="toDate"
                id={`userPersonalInfoCompanyToDate${item.name}`}
                selected={item.todate ? new Date(item.todate) : null}
                disabled={item.iscurrent}
                onChange={(date: Date): void => {
                  companyHistory[i] = {
                    ...companyHistory[i],
                    todate: date,
                  };
                  onProfessionalInfoChange("Company History", companyHistory);
                }}
                placeholderText="MM/DD/YYYY"
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup check className="mb-3">
          <Label>
            <input
              className="ml-n3 mr-2"
              type="checkbox"
              id="userPersonalInfoCurrentCompanySwitch"
              name="iscurrent"
              checked={item.iscurrent}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                companyHistory[i] = {
                  ...companyHistory[i],
                  iscurrent: e.target.checked,
                };
                onProfessionalInfoChange("Company History", companyHistory);
              }}
            />
            {t(
              "Update.Profile.Professional.Info.Company.History.Current.Company"
            )}
          </Label>
        </FormGroup>
      </div>
    </CSSTransition>
  ));

  const companyHistoryElement = shouldDisplayElement("Company History") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label>{t("Update.Profile.Professional.Info.Company.History")}</Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userProfessionalInfoCompanyHistorySwitch"
            name="Company History"
            defaultChecked={visibilities["Company History"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <TransitionGroup>{companyHistoryListElement}</TransitionGroup>

      <Row className="ml-1 mb-5 mt-2">
        <i className="icon-plus pr-3" onClick={onAddCompanyHistory} />
        {companyHistoryListElement.length !== 0 && (
          <i className="icon-minus" onClick={onRemoveCompanyHistory} />
        )}
      </Row>
    </FormGroup>
  );

  const titleElement = shouldDisplayElement("Title") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileProfessionalInfoTitleLabel">
            {t("Update.Profile.Professional.Info.Title")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileProfessionalInfoTitleSwitch"
            name="Title"
            defaultChecked={visibilities["Title"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        type="text"
        name="Title"
        id="userEditProfileProfessionalInfoTitleInput"
        placeholder={t("Update.Profile.Professional.Info.Title")}
        value={data.Title}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const summaryElement = shouldDisplayElement("Summary") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileProfessionalInfoSummaryLabel">
            {t("Update.Profile.Professional.Info.Summary")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileProfessionalInfoSummarySwitch"
            name="Summary"
            defaultChecked={visibilities["Summary"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        type="textarea"
        name="Summary"
        id="userEditProfileProfessionalInfoSummaryInput"
        placeholder={t("Update.Profile.Professional.Info.Summary")}
        value={data.Summary}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const companyNameElement = shouldDisplayElement("Company Name") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileProfessionalInfoCompanyNameLabel">
            {t("Update.Profile.Professional.Info.Company.Name")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileProfessionalInfoCompanyNameSwitch"
            name="Company Name"
            defaultChecked={visibilities["Company Name"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      <Input
        type="text"
        name="Company Name"
        id="userEditProfileProfessionalInfoCompanyNameInput"
        placeholder={t("Update.Profile.Professional.Info.Company.Name")}
        value={data["Company Name"]}
        onChange={handleInputChange}
      />
    </FormGroup>
  );

  const locationElement = shouldDisplayElement("Location") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileProfessionalInfoLocationLabel">
            {t("Update.Profile.Professional.Info.Location")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileProfessionalInfoLocationSwitch"
            name="Location"
            defaultChecked={visibilities["Location"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>

      {locationPicker(
        data.Location,
        "professionalLocationSearchInput",
        "establishment",
        onProfessionalInfoChange as (k: string, v: string) => void
      )}
    </FormGroup>
  );

  const phoneElement = shouldDisplayElement("Phone") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileProfessionalInfoPhoneLabel">
            {t("Update.Profile.Professional.Info.Phone")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileProfessionalInfoPhoneSwitch"
            name="Phone"
            defaultChecked={visibilities["Phone"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        invalid={!isPhoneProfessionalValid}
        type="text"
        name="Phone"
        id="userEditProfileProfessionalInfoPhoneInput"
        placeholder={t("Update.Profile.Professional.Info.Phone")}
        value={data.Phone}
        onChange={handlePhoneChange}
      />
      {!isPhoneProfessionalValid && (
        <FormFeedback>
          {t("Update.Profile.Personal.Info.Invalid.Phone")}{" "}
        </FormFeedback>
      )}
    </FormGroup>
  );

  const websiteElement = shouldDisplayElement("Website") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileProfessionalInfoWebsiteLabel">
            {t("Update.Profile.Professional.Info.Website")}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileProfessionalInfoWebsiteSwitch"
            name="Website"
            defaultChecked={visibilities["Website"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      <Input
        invalid={!isWebsiteValid}
        type="text"
        name="Website"
        id="userEditProfileProfessionalInfoWebsiteInput"
        placeholder={t("Update.Profile.Professional.Info.Website")}
        value={data.Website}
        onChange={handleWebsiteChange}
      />
      {!isWebsiteValid && (
        <FormFeedback id="userEditProfileProfessionalInfoInvalidWebsiteMessage">
          {t("Update.Profile.Professional.Info.Invalid.Website")}
        </FormFeedback>
      )}
    </FormGroup>
  );

  const industryElement = shouldDisplayElement("Industry") && (
    <FormGroup>
      <Row className="mt-4 font-weight-bold">
        <Col>
          <Label id="userEditProfileProfessionalInfoIndustryLabel">
            {industryPlaceholder}
          </Label>
        </Col>
        <Col className="text-right">
          <CustomInput
            type="switch"
            id="userEditProfileProfessionalInfoIndustrySwitch"
            name="Industry"
            defaultChecked={visibilities["Industry"]}
            onChange={handleVisibilityChange}
          />
        </Col>
      </Row>
      {industrySelectElement}
      {industryMultipleSelectElement}
    </FormGroup>
  );

  return (
    <Form>
      {titleElement}
      {summaryElement}
      {companyNameElement}
      {locationElement}
      {phoneElement}
      {websiteElement}
      {industryElement}
      {regionElement}
      {companyHistoryElement}
    </Form>
  );
};
