import React from "react";

import Skeleton from "react-loading-skeleton";
import { Col } from "reactstrap";

export type PostImagePlaceholderProps = {};

export const PostImagePlaceholder: React.FC<PostImagePlaceholderProps> = () => (
  <Col sm="6" md="4" lg="3" className="py-1 px-1">
    <Skeleton height={170} width="100%" />
  </Col>
);
