import SendBird from "sendbird";
import { appId } from "../constants/sendbird";
import { isObject, isArray, forEach, isEmpty } from "underscore";

const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};

const keysToCamel = function(o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map(i => {
      return keysToCamel(i);
    });
  }

  return o;
};

export function listChannels(sendBirdConfig, isOpenChat, options, token = "") {
  return new Promise((resolve, reject) => {
    const { sendBirdToken, sendBirdKey } = sendBirdConfig;
    const {
      showMember = false,
      showReadReceipt = false,
      limit = 10,
      includeEmpty = false,
      userId = "",
      customType = "",
      name = "",
    } = options;

    let api = `https://api-${sendBirdKey}.sendbird.com/v3/users/${userId}/my_group_channels`;
    if (isOpenChat) {
      api = `https://api-${sendBirdKey}.sendbird.com/v3/open_channels`;
    }
    api += `?limit=${limit}&token=${token}&show_member=${showMember}&show_read_receipt=${showReadReceipt}&show_empty=${includeEmpty}&custom_types=${customType}&order=latest_last_message`;
    if (!isEmpty(name)) {
      api += `&name=${name}`;
    }
    window.resetFetchConfig && window.resetFetchConfig();
    fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json, charset=utf8",
        "Api-Token": sendBirdToken,
      },
    })
      .then(async response => {
        const resp = await response.json();
        const result = keysToCamel(resp);
        const error = result.error && result;
        let channels = [];
        forEach(result.channels, (channel, index) => {
          channels.push({ ...channel, url: channel.channelUrl });
        });
        return resolve({ channels, error, token: result.next });
      })
      .catch(error => {
        return resolve({ error });
      });
  });
}

export function init(newAppId = appId) {
  return new SendBird({ appId: newAppId });
}
