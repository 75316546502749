import { RootState, Group } from "../../types";
import { GroupStatusData, GroupByStatusTab, GroupTypeData } from "./types";
import { createSelector } from "reselect";
import { isEmpty } from "underscore";

const getGroupStatusData = (state: RootState): GroupStatusData | null =>
  state.group.status;

const getgroupHomeData = (state: RootState): Group | null =>
  state.group.groupHome;

const getGroupListType = (state: RootState): "type" | "status" => {
  return state.setting.config?.blenderboxImprovements ||
    state.setting.setting?.group_list_type === "type"
    ? "type"
    : "status";
};

const getGroupList = (state: RootState): GroupStatusData | GroupTypeData => {
  const type = getGroupListType(state);

  const data = state.group[type] as GroupStatusData | GroupTypeData;
  return data;
};

const getGroupDetail = (state: RootState, groupId: string): Group | null => {
  const { myGroups, items, cached } = state.group;
  let group = cached?.[groupId] || null;
  if (isEmpty(group)) {
    group = myGroups?.[groupId] || null;
  }
  if (isEmpty(group)) {
    group = items?.[groupId] || null;
  }
  return group;
};

export const getGroupStatusDataState = createSelector(
  [getGroupStatusData],
  items => items
);

export const getGroupMenuTabsState = createSelector(
  [getGroupStatusData],
  items => items && (Object.keys(items) as GroupByStatusTab[])
);

export const getGroupListState = createSelector([getGroupList], items => items);

export const getGroupListMenuTabsState = createSelector(
  [getGroupList],
  items => items && Object.keys(items)
);

export const getGroupListTypeState = createSelector(
  [getGroupListType],
  items => items
);

export const getGroupDetailState = createSelector(
  [getGroupDetail],
  items => items
);

export const getGroupHomeDataState = createSelector(
  [getgroupHomeData],
  value => value
);
