import React from "react";
import classnames from "classnames";
import Octicon from "react-component-octicons";

import { IconDict } from "../../../../types";

import { IconFeather, IconSVG, IconFontAwesome, IconIonicons } from ".";

export const Icon = <T extends keyof IconDict>({
  active,
  className,
  name,
  style,
  //   title,
  type,
}: {
  active?: boolean;
  className?: string;
  style: {
    active?: {
      color: string;
    };
    color?: string;
    height?: number;
    width?: number;
    fontSize?: number;
  };
  title?: string;
  type: T;
  name: IconDict[T];
}): JSX.Element => {
  const customStyle = Object.assign({}, style, {
    color: active ? style.active && style.active.color : style.color,
  });
  delete customStyle.active;

  const { color, fontSize, height, width } = customStyle;

  if (type === "SVG") {
    return (
      <IconSVG
        color={color}
        width={26}
        height={24}
        name={name as IconDict["SVG"]}
      />
    );
  }

  if (type === "Feather") {
    return <IconFeather name={name as IconDict["Feather"]} />;
  }

  if (type === "Ionicons") {
    return (
      <IconIonicons
        name={name as IconDict["Ionicons"]}
        color={color || "#fff"}
        fontSize={`${fontSize || 28}px`}
      />
    );
  }

  if (type === "FontAwesome") {
    return (
      <IconFontAwesome
        className={classnames(className)}
        name={name as IconDict["FontAwesome"]}
        style={{ ...style, fontSize: `${fontSize || 24}px` }}
      />
    );
  }

  if (type === "Octicons") {
    return (
      <div style={{ height: `${height || 26}px`, width: `${width || 26}px` }}>
        <Octicon
          name={name as IconDict["Octicons"]}
          zoom="100%"
          style={{ verticalAlign: "baseline" }}
        />
      </div>
    );
  }

  return <></>;
};
