import React, { FC, useState } from "react";

import { BaseEmoji, emojiIndex } from "emoji-mart";
import {
  Mention,
  MentionItem,
  MentionsInput,
  SuggestionDataItem,
} from "react-mentions";
import { Media } from "reactstrap";

import { textThemingWorkaround } from "../../../constants";
import { pattern } from "../../../lib/utils";
import {
  MentionSearchLinkInfo,
  MentionSearchUserInfo,
  Dict,
} from "../../../types";

import { useTheme } from "../../hooks";

import { Avatar } from "..";
import { LoadingSpinner } from "../UI";

export type MentionInputProps = {
  disabled?: boolean;
  error: string | null;
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  links: { title: string; data: MentionSearchLinkInfo[] }[] | {};
  loading: boolean;
  maxHeight?: number;
  maxVisibleRowCount?: number;
  mentionLinkChar: string;
  mentionUserChar: string;
  minHeight?: number;
  placeholder?: string;
  singleLine?: boolean;
  /**
   * @TODO
   * This style should be fixed for web and native usage
   */
  style?: {};
  suggestionBottom?: boolean;
  users: MentionSearchUserInfo[];
  value: string;

  /**
   * @TODO
   * Investigate if this type could be broken
   */
  fetchLinksData: (
    query: string
  ) => Promise<
    | void
    | { data?: undefined }
    | {
        data: {
          title: string;
          data: MentionSearchLinkInfo[];
        }[];
      }
  >;
  fetchUsersData: (
    query: string
  ) => Promise<void | {
    data: MentionSearchUserInfo[];
  }>;
  mentionLink: (
    link: MentionSearchLinkInfo,
    options?: {
      dontOverwriteValue?: boolean;
    }
  ) => void;
  mentionUser: (
    user: MentionSearchUserInfo,
    options?: {
      dontOverwriteValue?: boolean;
    }
  ) => void;
  onBlur?: (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => void;
  onChangeText: (text: string) => void;
  onFocus?: () => void;
  onKeyDown?: (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  openingSuggestionsPanel?: (height: number) => void | boolean;
};

/**
 * @TODO
 * Consider sugegstionPortalHost usage for better displaying long suggestion lists
 * Consider loading icon display while data fetching
 */
export const MentionInput: FC<MentionInputProps> = ({
  disabled,
  id,
  inputRef,
  loading,
  maxHeight,
  mentionLinkChar,
  mentionUserChar,
  minHeight,
  placeholder,
  singleLine,
  style,
  value,

  fetchLinksData,
  fetchUsersData,
  mentionLink,
  mentionUser,
  onBlur,
  onChangeText,
  onKeyDown,
}) => {
  /**
   * Temporarly I implemented link entities storage here because etchLinksData
   * is not saving responsed data anywhere. Simultaneously
   * Mention onAdd prop which is choosing suggestion callback has only two arguments
   * which are id and display and it is not enought to call mentionLink function which
   * takes { id: string, name: string, url: string, imageUrl?: string } as an argument.
   *
   * There is necessity for storing these data by id key in some dictionary to ensure
   * we have all necessary data tomention link.
   *
   * Typing for react-mentions are unfortunately not proper and requires improvement
   * for supporting real SuggestionDataItem which is not
   * `interface SuggestionDataItem { id: string | number; display: string; }` but
   * should be generic type, something like (i didnt test exactly this solution):
   * ```
   *   type GenericSuggestionDataItem<T extends SuggestionDataItem> =
   *     | { id: string; display: string } & T
   *     | { id: number; display: string } & T;
   * ```
   *
   * Meanwhile I also discovered some bug in API - i sent called mentionLink with
   * hardcoded url value and server accepted it without any validation. Is it safe to
   * allow users send request to API with no consistent data? Why dont to pass only
   * id and get rest of data on backend?
   */
  const [linksDataDict, setLinksDataDict] = useState<
    Dict<MentionSearchLinkInfo>
  >({});

  const updateLinksData = (linksInfoArray: MentionSearchLinkInfo[]): void => {
    const newLinksData = linksInfoArray.reduce(
      (prevVal, curVal) => ({
        ...prevVal,
        [curVal.id]: curVal,
      }),
      linksDataDict
    );

    setLinksDataDict(newLinksData);
  };

  const {
    button: {
      backgroundColor,
      text: { color },
    },
  } = useTheme();

  const styles = {
    suggestionFocused: {
      color,
      backgroundColor,
    },
    suggestionHeader: {
      textAlign: "center",
      fontWeight: "bold",
      backgroundColor: "#d8dfea",
      cursor: "default",
    },
    mentionsInput: {
      input: {
        minHeight: minHeight && minHeight > 25 ? minHeight : 26,
        maxHeight: maxHeight,
        fontSize: 15,
        ...textThemingWorkaround,
        ...style,
      },
    },
  } as const;

  /**@FIXME typing*/
  const renderSuggestionRow = (
    item: { id: string; display: string; imageUrl?: string; name: string },
    search: string,
    highlitedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ): JSX.Element =>
    item.id.includes("title") ? (
      <Media
        onClick={(event: React.MouseEvent): void => {
          event.stopPropagation();
        }}
        className={"p-2 mentions-suggestions align-items-center"}
        style={styles.suggestionHeader}>
        <Media body className="px-3">
          <span>{highlitedDisplay}</span>
        </Media>
      </Media>
    ) : (
      <Media
        className={"p-2 mentions-suggestions align-items-center"}
        id={`mentionedUserInput${item.name}`}
        style={focused ? styles.suggestionFocused : undefined}>
        <Media left>
          <Avatar size="25" name={item.name} src={item.imageUrl} />
        </Media>
        <Media body className="px-3">
          <span>{highlitedDisplay}</span>
        </Media>
      </Media>
    );

  const handleChange = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ): void => {
    onChangeText(newPlainTextValue);
  };

  const getEmojiis = (
    query: string,
    callback: (data: SuggestionDataItem[]) => void
  ): void => {
    callback(
      emojiIndex?.search(query)?.map(o => {
        return {
          display: (o as BaseEmoji).native,
          id: o.id,
        } as SuggestionDataItem;
      }) ?? []
    );
  };

  const fetchUsers = (
    query: string,
    callback: (data: SuggestionDataItem[]) => void
  ): void | SuggestionDataItem[] => {
    fetchUsersData(query).then(resp => {
      if (resp) {
        callback(
          resp.data.map<SuggestionDataItem>((searchUserInfo, index) => ({
            display: searchUserInfo.name,
            index: index,
            ...searchUserInfo,
          }))
        );
      }
    });
  };

  const fetchLinks = (
    query: string,
    callback: (data: SuggestionDataItem[]) => void
  ): void | SuggestionDataItem[] => {
    fetchLinksData(query).then(resp => {
      if (resp && resp.data) {
        const flattenedLinksInfo = resp.data.reduce<MentionSearchLinkInfo[]>(
          (all, row) => {
            const rowWithTitle = [];
            rowWithTitle.push({
              name: row.title,
              id: `title ${row.title}`,
              url: "",
            });
            row.data.forEach(item => rowWithTitle.push(item));

            return all.concat(rowWithTitle);
          },
          []
        );
        const convertedLinkData = flattenedLinksInfo.map<SuggestionDataItem>(
          searchLinkInfo => ({
            display: searchLinkInfo.name,
            ...searchLinkInfo,
          })
        );

        updateLinksData(flattenedLinksInfo);
        callback(convertedLinkData);
      }
    });
  };

  const handleAddUser = (id: string, name: string): void => {
    mentionUser({ id: id.toString(), name }, { dontOverwriteValue: true });
  };

  const handleAddLink = (id: string): void => {
    const selectedLink = linksDataDict[id];

    selectedLink && mentionLink(selectedLink, { dontOverwriteValue: true });
  };

  const transformDisplayUser = (id: string, display: string): string =>
    `${mentionUserChar}${display}`;

  const transformDisplayLink = (id: string, display: string): string =>
    `${mentionLinkChar}${display}`;

  return (
    <div
      style={{
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
      }}>
      {loading && <LoadingSpinner size="sm" className="mentions-spinner" />}
      <MentionsInput
        allowSuggestionsAboveCursor={true}
        id={id}
        className="mention-input"
        style={styles.mentionsInput}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        singleLine={singleLine ?? false}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        inputRef={inputRef}
        disabled={disabled}>
        <Mention
          trigger=":"
          markup="__id__"
          regex={pattern.emoji}
          data={getEmojiis}
        />
        <Mention
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          renderSuggestion={renderSuggestionRow}
          displayTransform={transformDisplayUser}
          trigger={mentionUserChar}
          data={fetchUsers}
          appendSpaceOnAdd={true}
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          onAdd={handleAddUser}
        />
        <Mention
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          renderSuggestion={renderSuggestionRow}
          displayTransform={transformDisplayLink}
          trigger={mentionLinkChar}
          data={fetchLinks}
          appendSpaceOnAdd
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          onAdd={handleAddLink}
        />
      </MentionsInput>
    </div>
  );
};
