import React from "react";

import classnames from "classnames";

import { EventDate, EventLocation } from ".";

export type EventBarProps = {
  className?: string;
  event: {
    allDayEvent: boolean | string;
    city?: string | undefined;
    country?: string | undefined;
    endAt?: Date;
    objectId?: string;
    startAt?: Date;
    state?: string | undefined;
    streetAddress1?: string | undefined;
    timezone?: string | undefined;
    title: string;
    venue?: string | undefined;
  };
};

export const EventBar: React.FC<EventBarProps> = ({
  className,
  event: {
    allDayEvent: isAllDayEvent,
    city,
    country,
    endAt,
    objectId,
    startAt,
    state,
    streetAddress1,
    timezone,
    title,
    venue,
  },
}) => {
  return (
    <div id="eventDateAndLocation" className={classnames(className)}>
      <EventDate
        id={objectId}
        endAt={endAt}
        isAllDayEvent={isAllDayEvent}
        startAt={startAt}
        timezone={timezone}
      />
      <EventLocation
        city={city}
        country={country}
        eventId={objectId}
        eventTitle={title}
        state={state}
        streetAddress1={streetAddress1}
        venue={venue}
      />
    </div>
  );
};
