import React from "react";
import { connect } from "react-redux";

import {
  Callback,
  EmptyObject,
  Group,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";

import { getGroupsForPosting } from "./action";

export type PostingGroupsLayoutProps = {
  data: Group[] | null;
  error: Error | null;
  query?: string;
  refreshing: boolean;
  setting: SettingState | EmptyObject;
  user: UserDataState | EmptyObject;
  changeQuery: (query: string) => void;
  onPress: (item: Group) => void;
  reFetch: (refresh: boolean, callback?: Callback) => void;
};

export type PostingGroupsOwnProps = {
  Layout: React.ComponentType<PostingGroupsLayoutProps>;
  onPress: (item: Group) => void;
};

export type PostingGroupsStateProps = {
  data: Group[] | null;
  user: UserDataState | EmptyObject;
  setting: SettingState | EmptyObject;
};

export type PostingGroupsDispatchProps = {
  getGroups: (refresh: boolean) => Promise<unknown>;
};

export type PostingGroupsProps = PostingGroupsOwnProps &
  PostingGroupsStateProps &
  PostingGroupsDispatchProps;

export type PostingGroupsState = {
  error: Error | null;
  refreshing: boolean;
  query?: string;
};

class PostingGroups extends React.Component<
  PostingGroupsProps,
  PostingGroupsState
> {
  state: PostingGroupsState = {
    error: null,
    refreshing: false,
  };

  componentDidMount = (): void => {
    this.fetchData(true);
  };

  fetchData = (refresh: boolean, callback?: Callback): void => {
    const { getGroups } = this.props;

    const { refreshing } = this.state;

    if (refreshing) {
      return;
    }

    this.setState({ refreshing: refresh }, async () => {
      try {
        await getGroups(refresh);
        this.setState({
          refreshing: false,
          error: null,
        });
        callback?.();
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  onChangeQuery = (query: string): void => {
    this.setState({ query });
  };

  render = (): JSX.Element => {
    const { Layout, user, data, setting, onPress } = this.props;
    const { refreshing, error, query } = this.state;

    return (
      <Layout
        error={error}
        refreshing={refreshing}
        user={user}
        setting={setting}
        data={data}
        query={query}
        reFetch={this.fetchData}
        onPress={onPress}
        changeQuery={this.onChangeQuery}
      />
    );
  };
}

const mapStateToProps = (state: RootState): PostingGroupsStateProps => ({
  user: state.user.data || {},
  setting: state.setting || {},
  data:
    state.group.groupsForPosting && Object.values(state.group.groupsForPosting),
});

const mapDispatchToProps = {
  getGroups: getGroupsForPosting,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostingGroups);
