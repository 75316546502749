import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  EventFilterType,
  EventFilters,
  EventFiltersDisplayed,
  EventItem,
  Group,
  SortTypes,
} from "../../../types";

import { InfiniteScroll } from "..";

import {
  EventCalendar,
  EventListActionBar,
  EventListSection,
  EventListSectionPlaceholder,
} from ".";

export type ChangeEventFilterValue = {
  sort: SortTypes;
  filter: EventFilters;
  group?: Group | null;
};

export type OnEventFilterChange = <T extends EventFilterType>(
  filter: T,
  value: ChangeEventFilterValue[T],
  options?: { forceListReset?: boolean }
) => void;

export type EventListProps = {
  data: EventItem[] | null;
  dataByMonth?: {
    title: string;
    data: EventItem[];
  }[];
  // dateSelected: Date;
  // error: string | null;
  filter: EventFilters;
  group?: Group;
  hasMore: boolean;
  loading: boolean;
  refreshing: boolean;
  // renderActions: boolean;
  // renderActionsDone: boolean;
  // setting: { cover: string; };
  sort: SortTypes;
  // user: UserDataState | {};
  // changeDate: (date: Date) => void;

  changeFilter: OnEventFilterChange;
  changeMonth: (date: Date) => void;
  changeTab: (tab: { i: number }) => void;
  reFetch: (
    next: boolean,
    refresh: boolean,
    changeMonth?: boolean | undefined,
    silent?: boolean | undefined
  ) => boolean | void;
};

export const EventList: React.FC<EventListProps> = ({
  data,
  dataByMonth,
  changeTab,
  changeMonth,
  filter: filterBy,
  group,
  hasMore,
  loading,
  reFetch,
  refreshing: isRefreshing,
  sort: sortBy,

  changeFilter: onFilterChange,
}) => {
  const history = useHistory();

  const { t } = useTranslation("Event");

  const handleLoadMore = (): void => {
    reFetch(true, false);
  };

  const handleEventClick = (id: string): void => {
    history.push(getEventHref(id));
  };

  const getEventHref = (id: string): string => `/event-detail/${id}`;

  const eventListSectionElements =
    (filterBy !== "all" &&
      dataByMonth?.map(({ data, title }, index) => (
        <EventListSection
          id={index}
          key={index}
          headerText={title}
          data={data}
          getEventHref={getEventHref}
          onEventClick={handleEventClick}
        />
      ))) ||
    null;

  const activeViewElement =
    filterBy === "all" ? (
      /** Should we implement any placeholder for calendar? */
      <EventCalendar data={data || []} changeMonth={changeMonth} />
    ) : (
      <InfiniteScroll
        emptyText={t("No events found")}
        hasMore={hasMore}
        isLoading={loading}
        isRefreshing={isRefreshing}
        placeholder={<EventListSectionPlaceholder />}
        onLoadMore={handleLoadMore}>
        {eventListSectionElements}
      </InfiniteScroll>
    );

  return (
    <div>
      <EventListActionBar
        activeView={filterBy === "all" ? "Calendar" : "List"}
        filterBy={filterBy as EventFiltersDisplayed}
        group={group}
        sortBy={sortBy}
        onCalendarViewButtonClick={(): void => {
          changeTab({ i: 1 });
        }}
        onFilterChange={onFilterChange}
        onListViewButtonClick={(): void => {
          changeTab({ i: 0 });
        }}
      />
      {activeViewElement}
    </div>
  );
};
