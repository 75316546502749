import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Collapse,
  Card,
  CardBody,
  Row,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
  Col,
} from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";
import GroupPostingGroupsContainer from "../../../containers/Group/PostingGroups";
import {
  EventFiltersDisplayed,
  SortTypes,
  EventFilterType,
  Group,
} from "../../../types";

import { useTheme } from "../../hooks";

import { Dropdown, GroupPostingGroups, Heading } from "..";

import { OnEventFilterChange, ChangeEventFilterValue } from ".";

export type EventListFilterCollapseProps = {
  isOpen: boolean;

  filterBy: EventFiltersDisplayed;
  group?: Group;
  sortBy: SortTypes;

  onFilterChange: OnEventFilterChange;
};

export const EventListFilterCollapse: React.FC<EventListFilterCollapseProps> = ({
  filterBy,
  isOpen,
  sortBy,
  group,

  onFilterChange: onChange,
}) => {
  const { t } = useTranslation("Event");

  const {
    button,
    text: { listText },
  } = useTheme();

  const eventLisFilterCollapseStyles = {
    button: {
      ...button,
      height: 40,
      border: 0,
    },
    heading: {
      ...listText,
      ...textThemingWorkaround,
      textAlign: undefined,
      paddingBottom: 5,
    },
    buttonText: {
      ...button.text,
      ...textThemingWorkaround,
      fontSize: 16,
      textAlign: undefined,
    },
    resetButton: {
      alignSelf: "center",
      width: "fit-content",
      height: "fit-content",
      marginLeft: 20,
      color: button.backgroundColor,
      cursor: "pointer",
    },
  };

  const sortByOptions = {
    ascending: t("Index.Button.Closest.Date"),
    descending: t("Index.Button.Furthest.Date"),
  };

  const filterByOptions = {
    upcoming: t("Index.Button.In.Progress.Upcoming"),
    past: t("Index.Button.Past"),
  };

  const [isBrowseGroupsModalOpen, setBrowseGroupsModalOpen] = useState(false);

  const toggleBrowseMoreGroupsModal = (): void => {
    setBrowseGroupsModalOpen(!isBrowseGroupsModalOpen);
  };

  const handleChange = <T extends EventFilterType>(filterType: T) => (
    filterValue: ChangeEventFilterValue[T]
  ): void => {
    onChange(filterType, filterValue, { forceListReset: true });

    setBrowseGroupsModalOpen(false);
  };

  const handleSortChange = handleChange("sort");
  const handleFilterChange = handleChange("filter");
  const handleFilterByGroupChange = handleChange("group");
  const handleResetToAllEvents = (): void =>
    onChange("group", undefined, { forceListReset: true });

  const groupModal = (
    <Modal
      id="eventListFilterByGroupModal"
      isOpen={isBrowseGroupsModalOpen}
      toggle={toggleBrowseMoreGroupsModal}
      centered={true}>
      <ModalHeader toggle={toggleBrowseMoreGroupsModal}>
        {t("Index.Button.Choose.Group")}
      </ModalHeader>
      <ModalBody>
        <GroupPostingGroupsContainer
          Layout={GroupPostingGroups}
          onPress={handleFilterByGroupChange}
        />
      </ModalBody>
    </Modal>
  );

  const filterByGroupButton = (
    <div className="d-flex">
      <Button
        id="eventListFilterByGroupButton"
        style={eventLisFilterCollapseStyles.button}
        onClick={toggleBrowseMoreGroupsModal}>
        <span style={eventLisFilterCollapseStyles.buttonText}>
          {group ? group.name : t("Index.Button.All.Events")}
        </span>
      </Button>
      {!!group && (
        <span
          style={eventLisFilterCollapseStyles.resetButton}
          onClick={handleResetToAllEvents}>
          {t("Index.Button.Reset.To.All.Events")}
        </span>
      )}
      {groupModal}
    </div>
  );

  return (
    <Collapse isOpen={isOpen}>
      <Card>
        <CardBody className="px-2">
          <Row className="event-list-filters-row">
            <Col>
              <Heading
                style={eventLisFilterCollapseStyles.heading}
                id="eventsSortByText"
                text={t("Index.Heading.Sort.By")}
              />
              <Dropdown
                textStyle={eventLisFilterCollapseStyles.buttonText}
                style={eventLisFilterCollapseStyles.button}
                id="eventListSortByDateDropdown"
                className="mb-3"
                value={sortBy}
                options={sortByOptions}
                onChange={handleSortChange}
              />
            </Col>
            <Col>
              <Heading
                style={eventLisFilterCollapseStyles.heading}
                id="eventsViewText"
                text={t("Index.Heading.Event.View")}
              />
              <Dropdown
                textStyle={eventLisFilterCollapseStyles.buttonText}
                style={eventLisFilterCollapseStyles.button}
                id="eventListEventViewDropdown"
                className="mb-3"
                value={filterBy}
                options={filterByOptions}
                onChange={handleFilterChange}
              />
            </Col>
            <Col className="mr-2">
              <Heading
                style={eventLisFilterCollapseStyles.heading}
                id="eventsFilterByGroupText"
                text={t("Index.Heading.Filter.By.Group")}
              />
              {filterByGroupButton}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
};
