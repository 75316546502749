import React from "react";

import { RouteComponentProps } from "react-router-dom";

import GroupListContainer from "../../../containers/Group";

import { GroupList } from "../../components/Group";

export type RouteGroupListProps = {} & RouteComponentProps<{ id: string }>;

export const RouteGroupList: React.FC<RouteGroupListProps> = (
  props
): JSX.Element => <GroupListContainer {...props} Layout={GroupList} />;
