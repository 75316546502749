import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { Announcement, AnnouncementItem, DictStrict } from "../../../types";

import { runFunction } from "../../http";

/**
 * @TODO Add types while containers/Search typing
 */
export const searchFullTextAnnouncements = async (
  options: {},
  groupIds?: string[] | null
): Promise<DictStrict<AnnouncementItem>> => {
  const resp: AnnouncementItem[] = await runFunction(
    "searchAnnouncements",
    options,
    { requireUser: true }
  );

  // because multiple instances for full text search isn't supported, API returns value for all targets now
  // so solution here is making a comparison with my groups to reduce invalid targets
  const filteredData = convertObject(resp).filter(
    ({ announcement }: { announcement: Announcement }) => {
      return (
        announcement.target === "all" ||
        _.difference(announcement.targetGroups, groupIds || []).length === 0
      );
    }
  );

  /**
   * @FIXME Converting logic temporarily moved here.
   * Reconsider move to another layer.
   */
  return _.object(
    _.map(filteredData, (value: AnnouncementItem) => {
      return [
        value.announcement.event
          ? value.announcement.event.objectId
          : value.announcement.id,
        value,
      ];
    })
  );
};
