import React from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { MediaImageBasic } from "../../../types";

import { MediaFile, MediaImage, MediaVideo } from ".";

export type MediaProps = {
  allowViewImageFullScreen?: unknown;
  alt?: string;
  backgroundColor?: string;
  file?: { thumbUrl?: string; fileUrl: string; name?: string };
  href?: string;
  image?: MediaImageBasic;
  pauseWhenInvisible?: boolean;
  resizeMode?: "contain" | "cover";
  style?: React.CSSProperties;
  //  | "stretch" | "center";
  screenInActive?: boolean;
  setting?: {
    autoPlayVideo?: boolean;
    googleApi?: string;
  };
  video?: { thumbUrl?: string; fileUrl: string };
  videoId?: string;
  /**
   * @TODO Do we support any other video type?
   */
  videoType?: "Cloudinary" | "YouTube" | "Vimeo" | "Wistia";

  remove?: () => void;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const Media: React.FC<MediaProps> = ({
  alt,
  video,
  image,
  file,
  href,
  videoType,
  videoId,
  remove: onRemove,
  resizeMode = "cover",
  style,
  onClick,
}) => {
  const { t } = useTranslation("Media");

  if (!video && !image && !file && !videoId) {
    return <Alert color="warning">{t("Index.Message.Not.Found.Media")}</Alert>;
  }

  const mediaElement = file ? (
    <MediaFile name={file.name} url={file.fileUrl} onRemove={onRemove} />
  ) : videoId ? (
    <MediaVideo style={style} url={videoId} type={videoType} />
  ) : image ? (
    <MediaImage
      alt={alt}
      height={270}
      href={href}
      resizeMode={resizeMode}
      src={image.thumbUrl ?? image.fileUrl}
      onClick={onClick}
    />
  ) : null;

  return mediaElement;
};
