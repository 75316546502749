import { destroyData } from "../../http";
import { CommentType, CommentRequest } from "../../../types/Comment";
import { commentsTypes } from "../../../constants/Comment";

export const deleteComment = async (
  options: CommentRequest & { type: CommentType }
): Promise<void> => {
  console.debug("[Action] deleteComment");

  const { id, type } = options;
  const Comment = Parse.Object.extend(commentsTypes[type].commentObject);
  const comment = new Comment({ id });
  return await destroyData(comment, null, {});
};
