import React from "react";

import { RouteComponentProps } from "react-router-dom";

import PostFilesContainer from "../../../containers/Post/Files";

import { PostFiles } from "../../components/Post";

export type RouteGroupFilesProps = {} & RouteComponentProps;

export const RouteGroupFiles: React.FC<RouteGroupFilesProps> = (
  props
): JSX.Element => (
  /**
   * @FIXME
   * Why this component is named PostFilesContainer? It is used only
   * in groupFiles case. Not sure about PostFiles.
   */
  <PostFilesContainer {...props} Layout={PostFiles} />
);
