import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { flatten } from "underscore";
import { RootState, IconSet, IconSingleSet } from "../../types";
import { NotificationUnread } from "../../types/Notification/NotificationUnread";
import { IconDict } from "../../types/IconDict";

export type IconBadgeLayoutProps<T extends keyof IconDict> = {
  active?: boolean;
  /** @TODO Investigate this type */
  badgeStyle: {};
  /** @TODO Investigate this type */
  style: {};
  title?: string;
  unread: number;
} & IconSingleSet<T>;

export type IconBadgeOwnProps = {
  Layout: React.ComponentType<IconBadgeLayoutProps<keyof IconDict>>;
  active?: boolean;
  badgeStyle: {};
  id: keyof NotificationUnread;
  style: {};
  title?: string;
} & IconSet;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: RootState, ownProps: IconBadgeOwnProps) => {
  const {
    notification: { unread },
  } = state;

  const type = ownProps.id;

  let count: number | undefined = 0;

  const unreadSection = unread[type];

  if (type === "group-list" || type === "groups") {
    count = unread.groups ? flatten(Object.values(unread.groups)).length : 0;
  } else if (type === "more") {
    count = unread["payments"]?.length;
  } else if (unreadSection) {
    count = unreadSection.length as number;
  }
  return {
    unread: count ?? 0,
  };
};

const connector = connect(mapStateToProps);

export type IconBadgeProps = IconBadgeOwnProps &
  ConnectedProps<typeof connector>;

class IconBadge extends React.Component<IconBadgeProps> {
  render = (): JSX.Element => {
    const {
      Layout,
      unread,
      style,
      badgeStyle,
      name,
      type,
      active,
      title,
    } = this.props;

    return (
      <Layout
        unread={unread}
        style={style}
        badgeStyle={badgeStyle}
        name={name}
        type={type}
        active={active}
        title={title}
      />
    );
  };
}

export default connector(IconBadge);
