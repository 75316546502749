import React from "react";

import { useTheme } from "../../hooks";

export type BackgroundFixedProps = {};

export const BackgroundFixed: React.FC<BackgroundFixedProps> = () => {
  const {
    general: { backgroundColor },
  } = useTheme();

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor,
      }}
    />
  );
};
