import { SettingConfigData } from "../../../types";
import { runFunction } from "../../http";

interface AppConfig extends Parse.Config {
  attributes?: SettingConfigData;
}

export const getAppConfigs = async (): Promise<
  SettingConfigData | undefined
> => {
  console.debug("[Service] getAppConfigs");
  const configs: AppConfig = await runFunction("getAppConfigs");
  return configs.attributes;
};
