import React from "react";

import { Alert, Modal, Button } from "reactstrap";

export type ConfirmModalProps = {
  color:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  confirmText: string;
  dismissText?: string;
  isOpen: boolean;
  message: string;
  title?: string;

  onConfirm: () => void;
  toggle?: () => void;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  color,
  confirmText,
  dismissText,
  isOpen,
  message,
  title,

  onConfirm,
  toggle,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} centered={true}>
    <Alert color={color} className="m-0">
      {title && <h4 className="alert-heading">{title}</h4>}
      {message}
      <div className="text-right">
        <Button className="mr-2" color="link" onClick={toggle}>
          {dismissText}
        </Button>
        <Button
          color={color}
          onClick={(): void => {
            onConfirm();
            toggle && toggle();
          }}>
          {confirmText}
        </Button>
      </div>
    </Alert>
  </Modal>
);
