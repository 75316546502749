import React from "react";

import { Switch, Route, RouteProps } from "react-router-dom";
import { Col } from "reactstrap";

import { TemplateLeftSidebar } from "../components";

export type SwitchLeftColProps = {
  customConfig?: RouteProps[];
  disabledPaths?: string[];
};

export const SwitchLeftCol: React.FC<SwitchLeftColProps> = ({
  customConfig,
  disabledPaths,
}) => {
  const disabledRouteElement = disabledPaths && <Route path={disabledPaths} />;

  const customRouteElements = customConfig?.map((route, index) => (
    <Route key={index} {...route} />
  ));

  return (
    <Switch>
      {disabledRouteElement}
      <Route>
        <Col
          xl={3}
          className="invisible p-0 left-column-col"
          style={{ position: "absolute", left: "-999em" }}>
          <div className="gf-column-left">
            <Switch>
              {customRouteElements}
              <Route component={TemplateLeftSidebar} />
            </Switch>
          </div>
        </Col>
      </Route>
    </Switch>
  );
};
