import { promiseWrapper } from "../../http";
import _ from "underscore";
import { CustomFileType } from "../../../types/Chat/ChatMutatedMessage";
import { Image } from "../../../types/Post/Image";

export const addFilesToGroup = async (
  files: CustomFileType[],
  groupId: string,
  userId?: string
): Promise<Parse.Object<Image>[]> => {
  return promiseWrapper("Add files/photos to group", {}, () => {
    const group = new (Parse.Object.extend("Group"))({ id: groupId });
    const author = new Parse.User({ id: userId });

    const unSavedGroupFiles: Parse.Object<Image>[] = [];
    _.forEach(files, item => {
      const { fileUrl, thumbUrl, file } = item;
      const { name, contentType, type, title } = file || {};
      const groupFile: Parse.Object<Image> = new (Parse.Object.extend(
        "GroupFile"
      ))({
        contentType: contentType || type,
        fileUrl,
        thumbUrl,
        name,
        title,
        group,
        author,
      });
      unSavedGroupFiles.push(groupFile);
    });
    //this return is never used
    return Parse.Object.saveAll(unSavedGroupFiles);
  });
};
