import React from "react";

import PostContainer from "../../../../containers/Post";
import { Group, UserDataState, EmptyObject, Callback } from "../../../../types";

import { PostList } from "../..";
import { NewPostButton } from "../NewPostButton";

export type GroupMixedProps = {
  data: { name: string; data: Group[] }[] | null;
  error: Error | null;
  layoutType: unknown;
  loading: boolean;
  query?: string;
  refreshing: boolean;
  renderActions: boolean;
  renderActionsDone: boolean;
  setting: {};
  user: UserDataState | EmptyObject;

  changeQuery: (query: string) => void;
  reFetch: (refresh: boolean, callback?: Callback) => false | void;
};

export const GroupMixed: React.FC<GroupMixedProps> = ({
  data = [],
  refreshing: isRefreshing,
  query,

  changeQuery,
  reFetch,
  user,
}) => {
  return (
    <div>
      <NewPostButton user={user}></NewPostButton>
      <PostContainer Layout={PostList} match={{}} />
    </div>
  );
};
