import React from "react";

import classnames from "classnames";
import { DomElement } from "htmlparser2";
import parse from "html-react-parser";
import { isEmpty } from "underscore";

import { MentionLink, MentionUser } from "../../../types";

import { correctHTMLData } from "../../../constants/html";
import { parseJSONToObject } from "../../../lib/utils/parseJSONToObject";
import { MentionDisplay } from "../Mention";

//eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import escape from "escape-html";
import { reverseString } from "../../../lib/utils";

export type HTMLProps = {
  body: string;
  className?: string;
  linksMentions?: MentionLink[];
  mentions?: MentionUser[];
};

export const HTML: React.FC<HTMLProps> = ({
  body,
  className,
  mentions = [],
  linksMentions = [],
}) => {
  if (!body) {
    return null;
  }

  let html = correctHTMLData(body);

  const linksMentionsHtml = linksMentions.map(mention =>
    escape(parseJSONToObject<MentionLink>(mention).title)
  );
  /**
   * @TODO Remove parseJSONToObject once implemented mechanism for clearing cache
   */
  const mentionHtml = mentions.map(mention =>
    escape(parseJSONToObject<MentionUser>(mention).stub)
  );

  //react-native-parsed-text doesn't handle correct regrex for text has "?" char
  //Display#renderLinksInApp returned an incorrect text to match to "title"
  //So the temporary solution for now is replacing "?" by "." to display in content.
  const linksMentionsHtmlForRendering = linksMentions.map(mention => {
    const parsed = parseJSONToObject<MentionLink>(mention);
    return {
      ...parsed,
      title: (parsed.title || "").split("?").join("."),
    };
  });

  mentionHtml
    .filter(mention => !isEmpty(mention))
    .forEach(mention => {
      html = html
        .split(mention.trim())
        //reverseString to fix duplicated mentions
        .join(
          `<var id="mention" data="${reverseString(mention)}">${mention}</var>`
        );
    });

  linksMentionsHtml
    .filter(mention => !isEmpty(mention))
    .forEach(mention => {
      html = html.split(`${mention}`).join(
        //reverseString to fix duplicated mentions
        `<var id="mention" data="${reverseString(mention)
          .split("?")
          .join(".")}">${mention}</var>`
      );
    });

  const options = {
    replace: (
      domNode: DomElement
    ): JSX.Element | object | void | undefined | null | false => {
      const { data = "" } = domNode.attribs || {};
      if (!domNode.attribs) return;
      if (domNode.attribs.id === "mention") {
        return (
          <MentionDisplay
            //return original text from reverseString when rendering
            text={reverseString(data)}
            mentions={mentions}
            linkMentions={linksMentionsHtmlForRendering}
          />
        );
      }
    },
  };

  return (
    <div className={classnames("html-body", className)}>
      {parse(html, options)}
    </div>
  );
};
