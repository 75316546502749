import { NavLink, RouteComponentProps } from "react-router-dom";

import { appSettingsFeatureNavbarAvailable } from "../../constants";

import i18n from "../../middlewares/i18next";

import {
  AppRouteName,
  AppSettingsFeatureNavbarAvailable,
  AppSettingsFeatureNavbarIconSet,
  ConfigContextValue,
  Dict,
  NavigationFeature,
  SettingConfigData,
  SettingFeatureData,
  SettingSettingData,
  UserDataState,
  Group,
} from "../../types";

import { ChatRoomsContainer } from "../components/Chat";

import {
  RedirectUserSettings,
  RouteAnnouncementDetail,
  RouteAnnouncementList,
  RouteChatDetail,
  RouteEventAttendee,
  RouteEventDetail,
  RouteEventList,
  RouteEventMap,
  RouteGroupDetail,
  RouteGroupFiles,
  RouteGroupList,
  RouteGroupMixed,
  RouteGroupPhotos,
  RouteMap,
  RouteMemberDetails,
  RouteMemberList,
  RouteMemberProfile,
  RouteMixedFeed,
  RouteNotificationLanding,
  RouteNotificationList,
  RouteOldMaps,
  RouteOldPreview,
  RouteOldProfile,
  RouteOnboarding,
  RoutePaymentMixed,
  RoutePaymentReceiptDetail,
  RoutePostDetail,
  RouteResourceDetail,
  RouteResourceList,
  RouteSearch,
  RouteSupportFeedback,
  RouteUserChangePassword,
  RouteUserEditProfile,
  RouteUserSetting,
  RouteVideoDetail,
  RouteVideoList,
  RouteSubscription,
} from "../routes";

/**
 * Init and rnext computing could be splitted to smaller functions
 */
export const computeAppConfig = (
  setting?: {
    config: SettingConfigData;
    feature: SettingFeatureData;
    setting: SettingSettingData;
  },
  user?: UserDataState | null,
  groupHome?: Group | null
): ConfigContextValue => {
  console.debug("computeAppConfig");

  /** First computing for default ConfigContext value has undefined argument */
  const {
    config: { homeLabel, blenderboxImprovements, adminURL },
    feature,
    setting: {
      payments_enabled: isPaymentsEnabled,
      video_library_enabled: isVideoLibraryEnabled,
      helpDeskUrl: helpdeskUrl,
      home_page_type: homepageType,
      subscriptions_enabled: isSubscriptionEnabled,
    },
  } = setting || { config: {}, feature: [], setting: {} };

  //isLandingPageEnabled, disable landing page on web due to it has been moved to Right side bar
  const isHomepageEnabled = blenderboxImprovements || homepageType === "mix";

  const isUserStatusActive = user?.status === "active";

  const navigationDict: Dict<
    {
      name: string;
      icon: AppSettingsFeatureNavbarIconSet;
      id?: string;
      isVisible: boolean;
    },
    AppSettingsFeatureNavbarAvailable
  > = {
    home: {
      name: "Home",
      icon: { on: "home", type: "Ionicons" },
      isVisible: false,
    },
    feed: {
      name: "News",
      icon: { on: "paper", type: "SVG" },
      id: "updates",
      isVisible: false,
    },
    events: {
      name: "Events",
      icon: { on: "calendar", type: "SVG" },
      id: "events",
      isVisible: false,
    },
    groups: {
      name: "Groups",
      icon: { on: "groups", type: "SVG" },
      id: "discussion",
      isVisible: false,
    },
    members: {
      name: "Members",
      icon: { on: "people", type: "SVG" },
      id: "members",
      isVisible: false,
    },
    "chat-room": {
      name: "Chat",
      icon: { on: "chatboxes", type: "SVG" },
      isVisible: false,
    },
    "group-list": {
      name: "Group List",
      icon: { on: "sitemap", type: "FontAwesome" },
      id: "group-list",
      isVisible: isUserStatusActive,
    },
    payments: {
      name: "Payments",
      icon: { on: "dollar-sign", type: "FontAwesome" },
      id: "payments",
      isVisible: isUserStatusActive,
    },
    "content-pages": {
      name: "Resources",
      icon: { on: "file-directory", type: "Octicons" },
      id: "resources",
      isVisible: isUserStatusActive,
    },
    "video-library": {
      name: "Video Library",
      icon: { on: "play", type: "FontAwesome" },
      id: "videoLibrary",
      isVisible: isUserStatusActive,
    },
    feedback: {
      name: i18n.t("More:More.Heading.Report"),
      icon: { on: "info-circle", type: "FontAwesome" },
      id: "feedback",
      isVisible: isUserStatusActive,
    },
    settings: {
      name: "Settings",
      /** @TODO Implemented temporarily - not present in native app */
      icon: { on: "settings", type: "Octicons" },
      isVisible: false,
    },
    notifications: {
      name: "Notifications",
      icon: { on: "bell", type: "Feather" },
      isVisible: false,
    },
    search: {
      name: "Search",
      id: "search",
      icon: { on: "search", type: "FontAwesome" },
      isVisible: false,
    },
    maps: {
      name: "Maps",
      /**  @TODO Implemented temporarily - not present in native app */
      icon: { on: "globe", type: "Octicons" },
      isVisible: false,
    },
    "home-group": {
      name: "Home group",
      icon: { on: "md-cube", type: "Ionicons" },
      isVisible: false,
    },
  };

  /**
   * I don't know why but this object has to be declared in this scope. component
   * value will be lost otherwise.
   */
  const routesDict: {
    [K in AppRouteName]: {
      /**
       * Unique feature key - path without `/` at the begining.
       * This is forced by redux `state.setting.feature` shape.
       */
      pageId: K;

      /** Route component property */
      component?: React.ComponentType<RouteComponentProps<any>>;

      /** Hides feature in whole app when set to `true` */
      isActive?: boolean;
    };
  } = {
    home: {
      pageId: "home",
      component: RouteNotificationLanding,
      isActive: false,
    },
    feed: {
      pageId: "feed",
      component: RouteAnnouncementList,
      isActive: false,
    },
    events: {
      pageId: "events",
      component: RouteEventList,
      isActive: false,
    },
    groups: {
      pageId: "groups",
      component: RouteGroupMixed,
      isActive: false,
    },
    "members/map": {
      pageId: "members/map",
      component: RouteMap,
      isActive: true,
    },
    members: {
      pageId: "members",
      /** @TODO Implement tabs and MemberMixed */
      component: RouteMemberList,
      isActive: false,
    },
    "chat-room": {
      pageId: "chat-room",
      component: ChatRoomsContainer,
      isActive: true,
    },
    "group-list": {
      pageId: "group-list",
      component: RouteGroupList,
      isActive: true,
    },
    payments: {
      pageId: "payments",
      component: RoutePaymentMixed,
      isActive: false,
    },
    "content-pages": {
      pageId: "content-pages",
      component: RouteResourceList,
      isActive: true,
    },
    "video-library": {
      pageId: "video-library",
      component: RouteVideoList,
      isActive: false,
    },
    feedback: {
      pageId: "feedback",
      component: RouteSupportFeedback,
      isActive: true,
    },
    "user-setting": {
      pageId: "user-setting",
      component: RedirectUserSettings,
      isActive: true,
    },
    settings: {
      pageId: "settings",
      component: RouteUserSetting,
      isActive: true,
      /** @TODO Implemented temporarily - not present in native app */
    },
    "edit-profile": {
      pageId: "edit-profile",
      component: RouteUserEditProfile,
      isActive: true,
    },
    "member/:id": {
      pageId: "member/:id",
      component: RouteMemberDetails,
      isActive: true,
    },
    "profile/:id": {
      pageId: "profile/:id",
      component: RouteMemberProfile,
      isActive: true,
    },
    profile: {
      pageId: "profile",
      component: RouteOldProfile,
      isActive: true,
    },
    "announcement/:id": {
      pageId: "announcement/:id",
      component: RouteAnnouncementDetail,
      isActive: true,
    },
    "event-detail/:eventId/map/:location": {
      pageId: "event-detail/:eventId/map/:location",
      component: RouteEventMap,
      isActive: true,
    },
    "event-detail/:eventId/attendees-map/:location": {
      pageId: "event-detail/:eventId/attendees-map/:location",
      component: RouteEventMap,
      isActive: true,
    },
    "event-detail/:eventId/attendees-map": {
      pageId: "event-detail/:eventId/attendees-map",
      component: RouteEventMap,
      isActive: true,
    },
    "event-detail/:id": {
      pageId: "event-detail/:id",
      component: RouteEventDetail,
      isActive: true,
    },
    "event-register/:id": {
      pageId: "event-register/:id",
      component: RouteEventAttendee,
      isActive: true,
    },
    "group-feed/:id": {
      pageId: "group-feed/:id",
      component: RouteGroupDetail,
      isActive: true,
    },
    "payment-receipt-detail/:id": {
      pageId: "payment-receipt-detail/:id",
      component: RoutePaymentReceiptDetail,
      isActive: false,
    },
    "content-page-text/:id": {
      pageId: "content-page-text/:id",
      component: RouteResourceDetail,
      isActive: true,
    },
    "content-page-text-sub/:id": {
      pageId: "content-page-text-sub/:id",
      component: RouteResourceDetail,
      isActive: true,
    },
    "video-detail/:id": {
      pageId: "video-detail/:id",
      component: RouteVideoDetail,

      isActive: false,
    },
    notifications: {
      pageId: "notifications",
      component: RouteNotificationList,
      isActive: true,
    },
    "group-feed-detail/:postId": {
      pageId: "group-feed-detail/:postId",
      component: RoutePostDetail,
      isActive: true,
    },
    "search-groups": {
      pageId: "search-groups",
      /** @TODO Create layout and route */
      isActive: true,
    },
    "maps/:location?": {
      pageId: "maps/:location?",
      component: RouteOldMaps,
      isActive: true,
    },
    maps: {
      pageId: "maps",
      component: RouteOldMaps,
      isActive: true,
    },
    "group-photos/:id": {
      pageId: "group-photos/:id",
      component: RouteGroupPhotos,
      isActive: true,
    },
    "group-files/:id": {
      pageId: "group-files/:id",
      component: RouteGroupFiles,
      isActive: true,
    },
    "chat-room-detail/:channel": {
      pageId: "chat-room-detail/:channel",
      component: RouteChatDetail,
      isActive: true,
    },
    "chat-group-detail": {
      pageId: "chat-group-detail",
      /** @TODO Investigate what should be displayed on this path */
      isActive: true,
    },
    "reset-passcode": {
      pageId: "reset-passcode",
      component: RouteUserChangePassword,
      isActive: true,
    },
    onboarding: {
      pageId: "onboarding",
      component: RouteOnboarding,
      isActive: true,
    },
    search: {
      pageId: "search",
      component: RouteSearch,
      isActive: true,
    },
    "home-group": {
      pageId: "home-group",
      component: RouteGroupDetail,
      isActive: true,
    },
    preview: {
      pageId: "preview",
      component: RouteOldPreview,
      isActive: true,
    },
    subscription: {
      pageId: "subscription",
      component: RouteSubscription,
      isActive: isSubscriptionEnabled,
    },
  };

  const navigationFeaturesDict: Dict<NavigationFeature> = {};
  const navigationFeaturesMoreDict: Dict<NavigationFeature> = {};

  /** Setting home page custom config */
  if (isUserStatusActive && isHomepageEnabled) {
    routesDict.home.isActive = true;
    navigationDict.home.isVisible = true;

    if (homeLabel) {
      navigationDict.home.name = homeLabel;
    }

    if (blenderboxImprovements || homepageType === "mix") {
      routesDict.home.component = RouteMixedFeed;
    }

    // pushAppFeatureToNavigation("home");
    navigationFeaturesDict[`/${routesDict.home.pageId}`] = {
      id: navigationDict.home.id,
      icon: navigationDict.home.icon,
      title: navigationDict.home.name,
      tag: NavLink,
      to: `/${routesDict.home.pageId}`,
    };
  }

  /** Switching on payment related features if isPaymentsEnabled */
  if (isPaymentsEnabled) {
    routesDict.payments.isActive = true;
    routesDict["payment-receipt-detail/:id"].isActive = true;
  }

  /** Switching on video related features if isVideoLibraryEnabled */
  if (isVideoLibraryEnabled) {
    routesDict["video-library"].isActive = true;
    routesDict["video-detail/:id"].isActive = true;
  }

  /**
   * Overriding defaultAppProtectedConfig by redux stored settings.
   *
   * Every feature stored in redux is not hidden and present in Navigation
   * by default
   */
  if (isUserStatusActive) {
    feature.forEach(featureData => {
      switch (featureData.pageType) {
        /**
         * These features' routes (pageIds) are static AppRouteName type.
         * Their data could overwrite default routes config dictionary.
         */
        case "sections":
          //remove Chat-room if have, because we move Chat-room to the left.
          if (["chat-room"].includes(featureData.pageId)) {
            break;
          }
          //@fix home-group case
          if (!routesDict[featureData.pageId]) {
            break;
          }
          routesDict[featureData.pageId].isActive = true;

          navigationDict[featureData.pageId].name = featureData.name;
          navigationDict[featureData.pageId].isVisible = true;

          navigationFeaturesDict[`/${featureData.pageId}`] = {
            icon: navigationDict[featureData.pageId].icon,
            id: featureData.pageId,
            title: featureData.name,
            tag: NavLink,
            to: `/${featureData.pageId}`,
          };

          // "home-group" will be displayed when Setting#home_group_type has value  */

          if (featureData.pageId === "home-group") {
            if (groupHome) {
              navigationFeaturesDict[
                `/${featureData.pageId}`
              ] = Object.assign(
                {},
                navigationFeaturesDict[`/${featureData.pageId}`],
                { to: `/group-feed/${groupHome.objectId}` }
              );
            } else {
              delete navigationFeaturesDict[`/${featureData.pageId}`];
            }
          }

          break;
        /**
         * @FIXME
         * Its a mistake we are adding new static routes to dict which
         * could match to previously defined dynamic ones. It should be
         * added only to navigationFeatures array.
         */
        case "resources":
          navigationFeaturesDict[`/content-page-text/${featureData.pageId}`] = {
            icon: {
              on: "md-information-circle",
              type: "Ionicons",
            },
            title: featureData.name,
            tag: NavLink,
            to: `/content-page-text/${featureData.pageId}`,
          };

          break;

        /** The same as in Groups */
        case "groups":
          navigationFeaturesDict[`/group-feed/${featureData.pageId}`] = {
            icon: {
              on: "md-cube",
              type: "Ionicons",
            },
            title: featureData.name,
            tag: NavLink,
            to: `/group-feed/${featureData.pageId}`,
          };

          break;

        /**
         * Similar as above. We have full url instead. It should create only
         * navigationFeature
         */
        case "url":
          navigationFeaturesDict[featureData.pageId] = {
            icon: {
              on: "ios-link",
              type: "Ionicons",
            },
            title: featureData.name,
            href: featureData.pageId,
            target: "_blank",
            rel: "noopener noreferrer",
          };

          break;

        default:
      }
    });
  }

  const paths = Object.values(routesDict)
    .filter(({ isActive }) => isActive)
    .map(({ pageId }) => `/${pageId}`);

  /**
   * This path is an exception - this is the only one which is not displayed
   * in TemplateAppProtected so there is added also an exception in
   * RouteAppProtectedComponent to render it in different way. That is why
   * it is not present in routesDict as other routes.
   */
  paths.push("/preview/:token/:content/:contentId");

  appSettingsFeatureNavbarAvailable.forEach(pageId => {
    const { icon, id, name, isVisible } = navigationDict[pageId];

    if (
      !navigationFeaturesDict[`/${pageId}`] &&
      routesDict[pageId].isActive &&
      !["groups", "home-group"].includes(pageId)
    ) {
      navigationFeaturesMoreDict[`/${pageId}`] = {
        id: id,
        icon: icon,
        title: name,
        tag: NavLink,
        to: `/${pageId}`,
        isVisible: isVisible,
      };
    }
  });

  const routes = Object.values(routesDict)
    .filter(({ isActive }) => isActive)
    .map(({ pageId }) => ({
      path: `/${pageId}`,
      component: routesDict[pageId].component,
    }));

  const navigationFeatures = Object.values(navigationFeaturesDict);
  const navigationFeaturesMore = Object.values(navigationFeaturesMoreDict);

  //add help desk button
  navigationFeaturesMore.push({
    id: "help-desk",
    icon: {
      on: "question-circle",
      type: "FontAwesome",
    },
    title: i18n.t("More:More.Heading.Help.Desk"),
    href: helpdeskUrl ?? "https://help-client.groupfire.com",
    target: "_blank",
    rel: "noopener noreferrer",
    isVisible: true,
  });

  //add control panel button for admin
  const isAdmin = user?.admin || user?.editor;
  if (isAdmin) {
    navigationFeaturesMore.push({
      id: "control-panel",
      icon: {
        on: "chart-bar",
        type: "FontAwesome",
      },
      title: i18n.t("More:More.Heading.Control.Panel"),
      href: adminURL,
      target: "_blank",
      rel: "noopener noreferrer",
    });
  }

  return {
    navigationFeatures: navigationFeatures,
    navigationFeaturesMore: navigationFeaturesMore,
    paths: paths,
    routes: routes,
  };
};
