/* eslint-disable @typescript-eslint/camelcase */
import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { CountryCode } from "react-native-country-picker-modal";
import { isEmpty } from "underscore";

import { isValidPhone, isValidEmail, parsePhoneNumber } from "../../lib/utils";

import {
  RootState,
  SettingConfigData,
  SettingSettingData,
  UserLoginStateData,
} from "../../types";

import { getMemberLogin } from "../Member/action";
import { resetSetting } from "../Setting/action";

import { UserState } from "./types";
import { logIn, resetPassCode, forgotPassword, updatePassword } from "./action";

export type UserLoginStateProps = {
  user: UserState;
  setting: {
    organizationName: string;
    logo?: string;
    backgroundImage?: string;
    signInByEmail?: boolean;
    signUpEnabled?: boolean;
    InMultiTenant?: boolean;
  };
};

export type UserLoginDispatchProps = {
  getMemberLogin: (id: string) => Promise<string | undefined>;
  logIn: (username: string, password: string) => Promise<void>;
  resetSetting: false | (() => Promise<unknown>);
  updatePassword: (password: string) => Promise<boolean>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassCode: (phoneNumber: string) => Promise<boolean>;
};

export type UserLoginLayoutProps = {
  data: UserLoginStateData;
  error: string | null | undefined;
  loading: boolean;
  resetSetting?: (() => Promise<unknown>) | false;
  user: UserState | {};
  setting: {
    organizationName: string;
    logo?: string;
    backgroundImage?: string;
    signInByEmail?: boolean | undefined;
    signUpEnabled?: boolean | undefined;
    InMultiTenant?: boolean | undefined;
  };

  handleChange: <K extends keyof UserLoginStateData>(
    name: K,
    val: UserLoginStateData[K]
  ) => void;
  logIn: (callback?: () => void) => Promise<void>;
  resetPassCode: (callback?: (text: string) => void) => boolean | void;
  resetPassword: (callback?: (text: string) => void) => boolean | void;
  updatePassword: (callback?: (text: string) => void) => boolean | void;
};

export type UserLoginOwnProps = {
  Layout: ComponentType<UserLoginLayoutProps>;
  userId?: string;
} & WithTranslation;

export type UserLoginProps = UserLoginOwnProps &
  UserLoginStateProps &
  UserLoginDispatchProps &
  WithTranslation;

export interface UserLoginState {
  error: string | null;
  loading: boolean;
  success: null;
  data: UserLoginStateData;
}

class Login extends Component<UserLoginProps, UserLoginState> {
  constructor(props: UserLoginProps) {
    super(props);

    const { signInByEmail } = props.setting;
    let phone: string | boolean = "",
      email: string | boolean = "";
    if (props.user.login) {
      phone = !signInByEmail && props.user.login.phone;
      email = !!signInByEmail && props.user.login.email;
    }

    let countryCode: CountryCode = "US";
    if (phone) {
      countryCode =
        (parsePhoneNumber(phone) as { country: CountryCode }).country ||
        countryCode;
    } else {
      phone = "+1";
    }

    this.state = {
      error: null,
      loading: false,
      success: null,
      data: {
        phone,
        email: email ? email : "",
        password: "",
        countryCode,
        passwordText: signInByEmail
          ? this.props.t("Container.Login.Password")
          : this.props.t("Container.Login.Passcode"),
        currentPassword: "",
        confirmPassword: "",
      },
    };
  }

  componentDidMount = async (): Promise<void> => {
    if (this.props.userId) {
      this.setState({ loading: true });

      const key = this.props.setting.signInByEmail ? "email" : "phone";

      const memberLogin = await this.props.getMemberLogin(this.props.userId);

      this.setState({
        data: {
          ...this.state.data,
          [key]: memberLogin || "",
        },
        loading: false,
      });
    }
  };

  onHandleChange = <K extends keyof UserLoginStateData>(
    name: K,
    val: UserLoginStateData[K]
  ): void => {
    const { data } = this.state;
    data[name] = val;
    this.setState({ data });
  };

  onLogIn = async (callback?: () => void): Promise<void> => {
    const { logIn, setting } = this.props;
    const { data } = this.state;
    const { password, phone, email } = data;
    const { signInByEmail } = setting;

    if (isEmpty(password)) {
      return;
    }

    let username = "";
    if (isValidPhone(phone)) {
      username = phone;
    } else if (isValidEmail(email)) {
      username = email;
    }
    if (isEmpty(username)) {
      this.setState({
        error: signInByEmail
          ? this.props.t("Container.Login.Error.Email.Correct")
          : this.props.t("Container.Login.Error.Phone.Correct"),
      });
      return;
    }

    this.setState({ loading: true }, async () => {
      try {
        await logIn(username as string, password);
        this.setState({ loading: false, error: null });
        callback && callback();
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  onResetPassCode = (callback?: (text: string) => void): boolean | void => {
    const { resetPassCode } = this.props;
    const { data } = this.state;
    const { phone } = data;
    if (isEmpty(phone)) {
      this.setState({ error: this.props.t("Container.Login.Error.Phone") });
      return false;
    }
    if (!isValidPhone(phone)) {
      this.setState({
        error: this.props.t("Container.Login.Error.Phone.Correct"),
      });
      return false;
    }

    this.setState({ loading: true });
    resetPassCode(phone)
      .then((resp: boolean) => {
        if (resp) {
          this.setState({ loading: false, error: null });
          callback && callback(this.props.t("Container.Login.Sms"));
        }
      })
      .catch((error: string) => {
        this.setState({ loading: false, error: error });
      });
  };

  onResetPassword = (callback?: (text: string) => void): boolean | void => {
    const { forgotPassword } = this.props;

    const { data } = this.state;
    const { email } = data;
    if (isEmpty(email)) {
      this.setState({ error: this.props.t("Container.Login.Email") });
      return false;
    }
    if (!isValidEmail(email)) {
      this.setState({ error: this.props.t("Container.Login.Email.Correct") });
      return false;
    }

    this.setState({ loading: true });
    forgotPassword(email)
      .then((resp: boolean) => {
        if (resp) {
          this.setState({ loading: false, error: null });
          callback && callback(this.props.t("Container.Login.Email.Recovery"));
        }
      })
      .catch((error: string) => {
        this.setState({ loading: false, error: error });
      });
  };

  updatePassword = (callback?: (text: string) => void): boolean | void => {
    const { updatePassword, setting } = this.props;

    const { data } = this.state;
    const { currentPassword, password, confirmPassword } = data;
    if (
      isEmpty(currentPassword) ||
      isEmpty(password) ||
      isEmpty(confirmPassword)
    ) {
      this.setState({
        error: this.props.t("Container.Login.Error.All.Fields"),
      });
      return false;
    }
    if (setting.signInByEmail) {
      if (
        currentPassword.length < 8 ||
        password.length < 8 ||
        confirmPassword.length < 8
      ) {
        this.setState({
          error: this.props.t("Container.Login.Error.Correct.Password"),
        });
        return false;
      }
      if (password !== confirmPassword) {
        this.setState({
          error: this.props.t("Container.Login.Error.Confirm.Password"),
        });
        return false;
      }
    } else {
      if (
        currentPassword.length !== 4 ||
        password.length !== 4 ||
        confirmPassword.length !== 4
      ) {
        this.setState({
          error: this.props.t("Container.Login.Error.Correct.Passcode"),
        });
        return false;
      }
      if (password !== confirmPassword) {
        this.setState({
          error: this.props.t("Container.Login.Error.Confirm.Passcode"),
        });
        return false;
      }
    }

    this.setState({ loading: true });
    updatePassword(password)
      .then((resp: boolean) => {
        if (resp) {
          this.setState({ loading: false, error: null });
          callback &&
            callback(
              `${this.props.t("Container.Login.Update")} ${
                setting.signInByEmail
                  ? this.props.t("Container.Login.Password")
                  : this.props.t("Container.Login.Passcode")
              } ${this.props.t("Container.Login.Successful")}`
            );
        }
      })
      .catch((error: string) => {
        this.setState({ loading: false, error: error });
      });
  };

  render = (): JSX.Element => {
    const { user, Layout, setting, resetSetting } = this.props;
    const { error, loading, data } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        user={user}
        data={data}
        setting={setting}
        handleChange={this.onHandleChange}
        logIn={this.onLogIn}
        resetSetting={setting.InMultiTenant && resetSetting}
        resetPassCode={this.onResetPassCode}
        resetPassword={this.onResetPassword}
        updatePassword={this.updatePassword}
      />
    );
  };
}

const mapStateToProps = (state: RootState): UserLoginStateProps => {
  const { setting, config, localConfig } = state.setting;
  const {
    logo_large,
    sign_up_page_background_image,
    enable_signin_by_email,
    signup_enabled,
  } = setting as SettingSettingData;
  const { organizationName } = config as SettingConfigData;
  return {
    user: state.user,
    setting: {
      organizationName,
      logo: logo_large,
      backgroundImage: sign_up_page_background_image,
      signInByEmail: enable_signin_by_email,
      signUpEnabled: signup_enabled,
      InMultiTenant: localConfig ? localConfig.InMultiTenant : undefined,
    },
  };
};

const mapDispatchToProps = {
  getMemberLogin,
  logIn,
  resetSetting,
  resetPassCode,
  forgotPassword,
  updatePassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("User")(Login));
