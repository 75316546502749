import React from "react";
import { isEmpty } from "underscore";
import { Empty, InfiniteScroll } from "../../UI";
import { Member } from "../../../../types/Member";
import { MemberCard } from "../MemberCard";
import { useTheme } from "../../../hooks";

interface Props {
  noMembersFoundText?: string;
  loading?: boolean;
  simpleMode?: boolean;
  membersData: Array<string | Member> | null;
  allMembersLength: number;
  scrollableRef?: React.RefObject<HTMLDivElement>;
  countOfLoadedNumbers: number;
  loadMoreMembers?: (maxVisibleMembersNumber: number) => () => void;
}

export const MembersList: React.FC<Props> = ({
  noMembersFoundText = "No members found",
  scrollableRef,
  simpleMode,
  membersData,
  allMembersLength,
  countOfLoadedNumbers,
  loadMoreMembers,
}) => {
  const {
    divider: {
      backgroundColor: dividerBackgroundColor,
      text: { color: dividerColor },
    },
  } = useTheme();

  const isMemberListEmpty = isEmpty(membersData);

  if (!membersData || isMemberListEmpty) {
    return <Empty text={noMembersFoundText} />;
  }

  return (
    <div>
      <InfiniteScroll
        scrollableRef={scrollableRef}
        isLoading={false}
        hasMore={countOfLoadedNumbers < allMembersLength}
        onLoadMore={loadMoreMembers && loadMoreMembers(allMembersLength)}>
        {membersData.map((item, index) => {
          if (typeof item === "string") {
            return (
              <h6
                id="memberListTitle"
                className="mt-3 mb-2"
                style={{
                  backgroundColor: dividerBackgroundColor,
                  color: dividerColor,
                }}>
                {item}
              </h6>
            );
          }
          return (
            <MemberCard
              key={`${index}_${item.id}`}
              item={item}
              className={simpleMode ? "simple-mode" : ""}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
