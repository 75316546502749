import React from "react";

import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import MixedFeedContainer from "../../../containers/MixedFeed";

import { RootState, MixedFeedTab } from "../../../types";

import { MixedFeed } from "../../components/MixedFeed";

export type RouteMixedFeedProps = RouteComponentProps;

export const RouteMixedFeed: React.FC<RouteMixedFeedProps> = props => {
  /**
   * @TODO
   * Values dependent on redux store should be calculated in container instead of
   * passing it as container's own props (initially by LoggedInRouters).
   */

  const postingEngagementPrompts =
    useSelector(
      (state: RootState) => state.setting.setting?.posting_engagement_prompt
    ) || [];

  const blenderboxHomePageTabs = useSelector(
    (state: RootState) => state.setting.config?.blenderboxHomePageTabs
  );

  const defaultBBTabs: MixedFeedTab[] = [
    { title: "News", icon: "", id: "feed", displayPins: true },
    { title: "Feed", icon: "", id: "post", displayPins: false },
    { title: "Resource", icon: "", id: "resource" },
  ];

  const tabs = blenderboxHomePageTabs || defaultBBTabs;

  return (
    <MixedFeedContainer
      {...props}
      Layout={MixedFeed}
      options={{ postingEngagementPrompts }}
      tabs={tabs}
    />
  );
};
