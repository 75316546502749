import React, { Component, ReactNode } from "react";

import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import { imageNotFound } from "../../../../constants";

import Modal from "./Modal";

export type ImagesProps = {
  caption?: React.ReactNode;
  countFrom: number;
  hideOverlay?: boolean;
  images: string[];
  overlayBackgroundColor?: string;
  title?: React.ReactNode;

  onClickEach?: (options: { src: string; index: number }) => void;
  renderOverlay: () => ReactNode;
};

export type ImagesState = {
  conditionalRender: boolean;
  countFrom: number;
  index: number;
  modal: boolean;
  url?: string;
};

class Images extends Component<ImagesProps, ImagesState> {
  static defaultProps = {
    countFrom: 5,
    hideOverlay: false,
    images: [],
    onClickEach: null,
    overlayBackgroundColor: "#222222",
    renderOverlay: (): string => "Preview Image",
  };

  static propTypes = {};

  constructor(props: ImagesProps) {
    super(props);

    this.state = {
      conditionalRender: false,
      countFrom:
        props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
      index: 0,
      modal: false,
    };

    this.openModal = this.openModal.bind(this);
    this.onClose = this.onClose.bind(this);

    if (props.countFrom <= 0 || props.countFrom > 5) {
      console.warn("countFrom is limited to 5!");
    }
  }

  addDefaultSrc = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ): void => {
    (event.target as HTMLImageElement).src = imageNotFound;
  };

  openModal(index: number): void {
    const { onClickEach, images } = this.props;

    if (onClickEach) {
      return onClickEach({ src: images[index], index });
    }

    this.setState({ modal: true, url: images[index], index });
  }

  onClose(): void {
    this.setState({ modal: false });
  }

  render(): JSX.Element {
    const { modal, index, countFrom } = this.state;
    const { caption, images, title } = this.props;
    const imagesToShow = [...images];
    if (countFrom && images.length > countFrom) {
      imagesToShow.length = countFrom;
    }

    return (
      <div className="grid-container">
        {[1, 3, 4].includes(imagesToShow.length) && this.renderOne()}
        {imagesToShow.length >= 2 &&
          imagesToShow.length !== 4 &&
          this.renderTwo()}
        {imagesToShow.length >= 4 && this.renderThree()}

        {modal && (
          <Modal
            onClose={this.onClose}
            index={index}
            images={images}
            caption={caption}
            title={title}
          />
        )}
      </div>
    );
  }

  renderOne(): JSX.Element {
    const { images } = this.props;
    const { countFrom } = this.state;
    const overlay =
      images.length > countFrom && countFrom === 1
        ? this.renderCountOverlay(true)
        : this.renderOverlay();

    return (
      <Container className={"post-container"}>
        <Row>
          <Col
            xs={12}
            md={12}
            className={`border`}
            onClick={this.openModal.bind(this, 0)}>
            <img
              src={images[0]}
              onError={this.addDefaultSrc}
              style={{ width: "100%" }}
              alt={images[0]}
            />
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  }

  renderTwo(): JSX.Element {
    const { images } = this.props;
    const { countFrom } = this.state;
    const overlay =
      images.length > countFrom && [2, 3].includes(+countFrom)
        ? this.renderCountOverlay(true)
        : this.renderOverlay();
    const conditionalRender =
      [3, 4].includes(images.length) ||
      (images.length > +countFrom && [3, 4].includes(+countFrom));

    return (
      <Container className={"post-container"}>
        <Row>
          <Col
            xs={6}
            md={6}
            className="border height-two background"
            onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
            style={{
              background: `url(${conditionalRender ? images[1] : images[0]})`,
            }}>
            {this.renderOverlay()}
          </Col>
          <Col
            xs={6}
            md={6}
            className="border height-two background"
            onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
            style={{
              background: `url(${conditionalRender ? images[2] : images[1]})`,
            }}>
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  }

  renderThree(): JSX.Element {
    const { images } = this.props;
    const { countFrom } = this.state;
    const conditionalRender =
      images.length === 4 || (images.length > +countFrom && +countFrom === 4);
    const overlay =
      !countFrom ||
      countFrom > 5 ||
      (images.length > countFrom && [4, 5].includes(+countFrom))
        ? this.renderCountOverlay(true)
        : this.renderOverlay(conditionalRender ? 3 : 4);

    return (
      <Container className={"post-container"}>
        <Row>
          <Col
            xs={6}
            md={4}
            className="border height-three background"
            onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
            style={{
              background: `url(${conditionalRender ? images[1] : images[2]})`,
            }}>
            {this.renderOverlay(conditionalRender ? 1 : 2)}
          </Col>
          <Col
            xs={6}
            md={4}
            className="border height-three background"
            onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
            style={{
              background: `url(${conditionalRender ? images[2] : images[3]})`,
            }}>
            {this.renderOverlay(conditionalRender ? 2 : 3)}
          </Col>
          <Col
            xs={6}
            md={4}
            className="border height-three background"
            onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
            style={{
              background: `url(${conditionalRender ? images[3] : images[4]})`,
            }}>
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  }

  renderOverlay(id?: number): JSX.Element[] | boolean {
    const { hideOverlay, renderOverlay, overlayBackgroundColor } = this.props;

    if (hideOverlay) {
      return false;
    }

    return [
      <div
        key={`cover-${id}`}
        className="cover slide"
        style={{ backgroundColor: overlayBackgroundColor }}
      />,
      <div
        key={`cover-text-${id}`}
        className="cover-text slide animate-text"
        style={{ fontSize: "100%" }}>
        {renderOverlay()}
      </div>,
    ];
  }

  renderCountOverlay(more: any): JSX.Element[] {
    const { images } = this.props;
    const { countFrom } = this.state;
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);

    return [
      more && <div key="count" className="cover" />,
      more && (
        <div
          key="count-sub"
          className="cover-text"
          style={{ fontSize: "200%" }}>
          <p>+{extra}</p>
        </div>
      ),
    ];
  }
}

Images.propTypes = {
  images: PropTypes.array.isRequired,
  hideOverlay: PropTypes.bool,
  renderOverlay: PropTypes.func,
  overlayBackgroundColor: PropTypes.string,
  onClickEach: PropTypes.func,
  countFrom: PropTypes.number,
};

export default Images;
