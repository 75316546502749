import React, { Component } from "react";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import {
  MatchProps,
  ResourceItem,
  RootState,
  UserDataState,
} from "../../types";

import { getResources } from "./action";
import {
  getResourcesDataState,
  getResourcesDataHasMoreState,
} from "./selector";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";

export type ResourcesOwnProps = {
  Layout: React.ComponentType<ResourcesLayoutProps>;
  style?: {};
  showVideoCTA: boolean;
  onScroll?: () => void;
} & MatchProps;

export type ResourcesLayoutProps = {
  user: UserDataState | {};
  setting: {};
  data: ResourceItem[] | null;
  style?: {};
  error: null;
  loading: boolean;
  refreshing: boolean;
  hasMore?: boolean;
  reFetch: (next: boolean, refresh: boolean) => void;
  onScroll?: () => void;

  showVideoCTA: boolean;
};

export type ResourcesStateProps = {
  user: UserDataState | {};
  setting: {};
  data: ResourceItem[] | null;
  hasMore: boolean;
};

export type ResourceDispatchProps = {
  getResources: (next: boolean, refresh: boolean) => Promise<void>;
};

export type ResourcesProps = ResourcesOwnProps &
  ResourcesStateProps &
  ResourceDispatchProps;
export type ResourcesState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  hasMore?: boolean;
};

class Resources extends Component<ResourcesProps, ResourcesState> {
  state = {
    error: null,
    loading: false,
    refreshing: false,
    hasMore: false,
  };

  componentDidMount(): void {
    this.fetchData(false, true);
    track("View Screen", {
      Screen: "content-pages",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (next: boolean, refresh: boolean): void => {
    const { getResources, data } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState(
      { refreshing: refresh && !!data, loading: next },
      async () => {
        try {
          await getResources(next, refresh);
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      data,
      setting,
      style,
      showVideoCTA,
      onScroll,
      hasMore,
    } = this.props;
    const { loading, error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        showVideoCTA={showVideoCTA}
        style={style}
        onScroll={onScroll}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (state: RootState): ResourcesStateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getResourcesDataState(state),
    hasMore: getResourcesDataHasMoreState(state),
  };
};

const mapDispatchToProps = {
  getResources: getResources,
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
