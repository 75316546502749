import React, { Component } from "react";

import { connect } from "react-redux";

import MapContainer from "../../../containers/Map";
import { EventItem, MatchProps, RootState } from "../../../types";

import { Map } from "../../components/Map";

export type EventAttendeeMapContainerStateProps = {
  event?: EventItem;
};

export type EventMapContainerProps = EventAttendeeMapContainerStateProps &
  MatchProps;

class EventMap extends Component<EventMapContainerProps> {
  render = (): JSX.Element => {
    const eventId = this.props.match?.params?.eventId;
    const location = this.props.match?.params?.location;

    const types = this.props.match.url?.match(/attendees-map/)
      ? ["profile"]
      : ["profile", "event"];

    const eventAttendees = this.props.event?.attendees?.all?.map(attendee => {
      if (eventId === this.props.event?.event.id)
        return attendee?.user?.profile?.objectId;
    });

    return (
      <MapContainer
        match={{
          params: {
            types: types,
            targets: [eventId, eventAttendees] as string[],
            location: location,
          },
        }}
        Layout={Map}
      />
    );
  };
}

export const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): EventAttendeeMapContainerStateProps => {
  const id = ownProps.match?.params?.eventId;
  const { list } = state.event;
  const { items } = list;
  const event = (id && items && items[id]) || undefined;

  return {
    event,
  };
};

export default connect(mapStateToProps)(EventMap);
