import React from "react";

import classnames from "classnames";
import { CardImg } from "reactstrap";

export type MediaImageProps = {
  id?: string;
  alt?: string;
  className?: string;
  height?: number;
  href?: string;
  resizeMode?: "contain" | "cover";
  src: string;
  title?: string;

  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

/**
 * @TODO
 * Implement image preloading and placeholding
 */
export const MediaImage: React.FC<MediaImageProps> = ({
  id,
  className,
  height,
  href,
  resizeMode,
  title,

  onClick,

  ...props
}) =>
  href ? (
    <a
      title={title}
      href={href}
      onClick={onClick}
      className={classnames(className)}>
      <CardImg style={{ height: height, objectFit: resizeMode }} {...props} />
    </a>
  ) : (
    <CardImg
      id={id}
      style={{
        height: height,
        objectFit: resizeMode,
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
      className={classnames(className)}
      {...props}
    />
  );
