import React, { Component, ComponentType } from "react";

//@FIXME there are no types to this lib
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Geocode from "react-geocode";
import { connect } from "react-redux";
import Geolocation from "@react-native-community/geolocation";
import { debounce } from "underscore";

import { track } from "../../lib/track";

import {
  RootState,
  SettingConfigData,
  MapMarkerType,
  MapPositionType,
  MapOptionsType,
  MapRegionType,
} from "../../types";

import { getMarkersInBounds } from "./action";
import { MapMarkerSetAction } from "./types";

type StateProps = {
  data: MapMarkerType[] | null;
  setting: {
    googleApi: string;
  };
};

type DispatchProps = {
  getMarkersInBounds: (options: MapOptionsType) => Promise<MapMarkerSetAction>;
};

export type MapLayoutProps = {
  data: MapMarkerType[] | null;
  loading: boolean | undefined;
  isMemberMixedLayout?: true;
  marker: MapMarkerType | undefined;
  needGeoLocation: boolean;
  region: MapRegionType | undefined;
  showsUserLocation: boolean;
  setting: {
    googleApi: string;
  };

  allowGetGeoLocation: (allowed: boolean) => void;
  regionChange: (region: MapRegionType) => void;
  viewMarker: (marker: MapMarkerType | undefined) => void;
};

export type MapOwnProps = {
  Layout: ComponentType<MapLayoutProps>;
  isMemberMixedLayout?: true;
};

type Props = MapOwnProps &
  StateProps &
  DispatchProps & {
    /**@FIXME Match props are overridden */
    match: {
      params?: {
        location?: string;
        targets?: string[];
        types: string[];
      };
      isExact?: boolean;
      path?: string;
      url?: string;
    };
  };

type State = {
  showsUserLocation: boolean;
  needGeoLocation: boolean;
  error: string | null;
  defaultRegion: MapPositionType;
  region?: MapRegionType;
  loading?: boolean;
  marker?: MapMarkerType | undefined;
};

class Map extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const location = props?.match?.params?.location;
    this.state = {
      showsUserLocation: true,
      needGeoLocation: !location,
      error: null,
      defaultRegion: {
        latitude: 33.6888268,
        longitude: -117.8850742,
      },
    };

    if (location) {
      this.getGeoLocationFromAddress(location);
    }
  }

  componentDidMount(): void {
    track("View Screen", {
      Screen: "map",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchDataDebounce = debounce(
    (
      option: Partial<MapOptionsType>,
      refresh?: boolean,
      callback?: () => void
    ) => this.fetchData(option, refresh, callback),
    1000
  );

  fetchData = (
    option: Partial<MapOptionsType>,
    refresh?: boolean,
    callback?: () => void
  ): void => {
    const { getMarkersInBounds, match } = this.props;
    const { loading } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true }, async () => {
      try {
        option = Object.assign({}, option, {
          types: match?.params?.types,
          targets: match?.params?.targets,
        });
        await getMarkersInBounds(option as MapOptionsType);
        this.setState({ loading: false, error: null });
        callback && callback();
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  getGeoLocationFromAddress = (location: string): void => {
    const { setting } = this.props;
    Geocode.setApiKey(setting.googleApi);
    Geocode.fromAddress(location).then(
      (response: {
        results: {
          geometry: {
            location: {
              lat: number;
              lng: number;
            };
          };
        }[];
      }) => {
        const { lat, lng } = response.results[0].geometry.location;
        const region = {
          latitude: lat,
          longitude: lng,
          latitudeDelta: 0.005,
          longitudeDelta: 0.005,
        };
        //there is only one argument in this function
        this.onRegionChange(region);
      },
      (error: string) => {
        console.debug(error);
      }
    );
  };

  onRegionChange = (region: MapRegionType): void => {
    this.setState({ region });
    this.fetchDataDebounce({ region });
  };

  onSetRegion = ({ latitude, longitude }: MapPositionType): void => {
    const region = {
      latitude,
      longitude,
      latitudeDelta: 0.005,
      longitudeDelta: 0.005,
    };

    this.setState({ region }, () => {
      this.fetchDataDebounce({ region });
    });
  };

  onAllowGetGeoLocation = (allowed: boolean): void => {
    const { defaultRegion } = this.state;
    const getGeoLocation = (
      enableHighAccuracy: boolean,
      fallback?: () => void
    ): void => {
      Geolocation.getCurrentPosition(
        position => {
          this.onSetRegion({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        error => {
          console.debug(error);
          fallback && fallback();
        },
        {
          enableHighAccuracy: enableHighAccuracy,
          timeout: 5000,
          maximumAge: 10000,
        }
      );
    };
    if (allowed) {
      getGeoLocation(false, () => {
        getGeoLocation(true, () => {
          this.onSetRegion(defaultRegion);
        });
      });
    } else {
      this.onSetRegion(defaultRegion);
    }
  };

  onViewMarker = (marker: MapMarkerType | undefined): void => {
    this.setState({ marker });
  };

  render = (): JSX.Element => {
    const { Layout, data, setting, isMemberMixedLayout } = this.props;
    const {
      region,
      showsUserLocation,
      marker,
      needGeoLocation,
      loading,
    } = this.state;

    return (
      <Layout
        data={data}
        region={region}
        showsUserLocation={showsUserLocation}
        regionChange={this.onRegionChange}
        viewMarker={this.onViewMarker}
        allowGetGeoLocation={this.onAllowGetGeoLocation}
        needGeoLocation={needGeoLocation}
        marker={marker}
        setting={setting}
        loading={loading}
        isMemberMixedLayout={isMemberMixedLayout}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  const { setting } = state;
  const { config } = setting;
  return {
    data: state.maps.items,
    setting: {
      googleApi: (config as SettingConfigData).googleMapKey,
    },
  };
};

const mapDispatchToProps = {
  getMarkersInBounds,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
