/* eslint-disable @typescript-eslint/camelcase */
import {
  searchAnimatedImage,
  searchStockImage,
  searchWebImage,
  searchUsedImage,
  searchUsedVideo,
  searchLibraryVideo,
  getVideoMetaData as _getVideoMetaData,
} from "../../services/api";
import { isGif, logException } from "../../lib/utils";
import _ from "underscore";
import { AppThunkDispatch, GetState } from "../../types";
import {
  MEDIA_PHOTO_GIPHY_SET,
  MEDIA_PHOTO_STOCK_SET,
  MEDIA_PHOTO_WEB_SET,
  MEDIA_SETTING_SET,
  MEDIA_VIDEO_LIBRARY_SET,
  MEDIA_VIDEO_USED_SET,
} from "./constants";
import { SettingSettingData } from "../../types/Setting/SettingSettingData";
import { SettingConfigData } from "../../types/Setting/SettingConfigData";
import { UserDataState } from "../../types/User/UserDataState";
import { PngPhoto } from "../../types/Media/PngPhoto";
import {
  MediaPhotoGiphySetAction,
  MediaPhotoStockSetAction,
  MediaPhotoUsedSetAction,
  MediaPhotoWebSetAction,
  MediaSettingSetAction,
  MediaVideoLibrarySetAction,
  MediaVideoUsedSetAction,
} from "./types";
import { GiphyPhoto } from "../../types/Media/GiphyPhoto";
import { MediaPhoto } from "../../types";
import { MediaVideo } from "../../types/Media/MediaVideo";
import { ExternalVideo } from "../../types/Media/ExternalVideo";
import { MediaSet } from "../User";
import i18n from "../../middlewares/i18next";

export function getPhotoGiphy(refresh?: boolean, query = "") {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<MediaPhotoGiphySetAction | { data: GiphyPhoto[] | null }> =>
    new Promise<MediaPhotoGiphySetAction | { data: GiphyPhoto[] | null }>(
      (resolve, reject) => {
        const { media, setting } = getState();
        const cachedData = media.photoGiphy;
        if (!_.isEmpty(cachedData) && !refresh) {
          return resolve({ data: cachedData });
        } else {
          searchAnimatedImage(query, setting.config)
            .then(resp => {
              const data: GiphyPhoto[] = _.map(
                resp,
                ({
                  link_url,
                  thumbnail_url,
                }: {
                  link_url: string;
                  thumbnail_url: string;
                }) => {
                  return {
                    uri: link_url,
                    thumbUrl: thumbnail_url,
                    name: new Date().getTime().toString(),
                    fileSize: 1,
                    type: "image/gif",
                    contentType: "image/gif",
                  };
                }
              );

              return resolve(
                dispatch({
                  type: MEDIA_PHOTO_GIPHY_SET,
                  data,
                })
              );
            })
            .catch(error => reject(error));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getPhotoStock(refresh?: boolean, query = "") {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<MediaPhotoStockSetAction | { data: PngPhoto[] | null }> =>
    new Promise<MediaPhotoStockSetAction | { data: PngPhoto[] | null }>(
      (resolve, reject) => {
        const { media, setting } = getState();
        const cachedData = media.photoStock;
        if (!_.isEmpty(cachedData) && !refresh) {
          return resolve({ data: cachedData });
        } else {
          searchStockImage(query, setting.config)
            .then(resp => {
              const data: PngPhoto[] = _.map(
                resp,
                ({ link_url, thumbnail_url }) => {
                  return {
                    uri: link_url,
                    thumbUrl: thumbnail_url,
                    name: new Date().getTime().toString(),
                    fileSize: 1,
                    type: "image/png",
                    contentType: "image/png",
                  };
                }
              );
              return resolve(
                dispatch({
                  type: MEDIA_PHOTO_STOCK_SET,
                  data,
                })
              );
            })
            .catch(error => reject(error));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getPhotoWeb(refresh?: boolean, query = "") {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<MediaPhotoWebSetAction | { data: PngPhoto[] | null }> =>
    new Promise<MediaPhotoWebSetAction | { data: PngPhoto[] | null }>(
      (resolve, reject) => {
        const { media, setting } = getState();
        const cachedData = media.photoWeb;
        if (!_.isEmpty(cachedData) && !refresh) {
          return resolve({ data: cachedData });
        } else {
          if (_.isEmpty(query)) {
            return resolve(
              dispatch({
                type: MEDIA_PHOTO_WEB_SET,
                data: [],
              })
            );
          }
          searchWebImage(query, setting.config)
            .then(resp => {
              const data: PngPhoto[] = _.map(
                resp,
                ({ link_url, thumbnail_url }) => {
                  return {
                    uri: link_url,
                    thumbUrl: thumbnail_url,
                    name: new Date().getTime().toString(),
                    fileSize: 1,
                    type: "image/png",
                    contentType: "image/png",
                  };
                }
              );
              return resolve(
                dispatch({
                  type: MEDIA_PHOTO_WEB_SET,
                  data,
                })
              );
            })
            .catch(error => reject(error));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getPhotoUsed(refresh?: boolean, query = "") {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<MediaPhotoUsedSetAction | { data: MediaPhoto[] | null }> =>
    new Promise<MediaPhotoUsedSetAction | { data: MediaPhoto[] | null }>(
      (resolve, reject) => {
        const { media, setting } = getState();
        const cachedData = media.photoUsed;
        if (!_.isEmpty(cachedData) && !refresh) {
          return resolve({ data: cachedData });
        } else {
          searchUsedImage(query, setting.config)
            .then(resp => {
              const data: MediaPhoto[] = _.map(
                resp,
                ({ link_url, thumbnail_url }) => {
                  const contentType: "image/gif" | "image/png" = isGif(link_url)
                    ? "image/gif"
                    : "image/png";
                  return {
                    uri: link_url,
                    thumbUrl: thumbnail_url,
                    name: new Date().getTime().toString(),
                    fileSize: 1,
                    type: contentType,
                    contentType: contentType,
                  };
                }
              );
              return resolve(
                dispatch({
                  type: "MEDIA_PHOTO_USED_SET",
                  data,
                })
              );
            })
            .catch(error => reject(error));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getVideoLibrary(refresh?: boolean, query = "") {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<MediaVideoLibrarySetAction | { data: MediaVideo[] | null }> =>
    new Promise<MediaVideoLibrarySetAction | { data: MediaVideo[] | null }>(
      (resolve, reject) => {
        const { media, setting } = getState();
        const cachedData = media.videoLibrary;
        if (!_.isEmpty(cachedData) && !refresh) {
          return resolve({ data: cachedData });
        } else {
          searchLibraryVideo(query, setting.config)
            .then((resp = []) => {
              const data = resp
                .filter(({ serviceName }) => serviceName === "Cloudinary")
                .map(({ id, serviceId, serviceName, thumbnail_url }) => {
                  const contentType = "video/mp4" as const;
                  return {
                    serviceId,
                    serviceName,
                    thumbUrl: thumbnail_url,
                    name: new Date().getTime().toString(),
                    id: id,
                    fromLibrary: true,
                    type: contentType,
                    contentType: contentType,
                  };
                });
              return resolve(
                dispatch({
                  type: MEDIA_VIDEO_LIBRARY_SET,
                  data,
                })
              );
            })
            .catch(error => reject(error));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getVideoUsed(refresh?: boolean, query = "") {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<MediaVideoUsedSetAction | { data: MediaVideo[] | null }> =>
    new Promise<MediaVideoUsedSetAction | { data: MediaVideo[] | null }>(
      (resolve, reject) => {
        const { media, setting } = getState();
        const cachedData = media.videoUsed;
        if (!_.isEmpty(cachedData) && !refresh) {
          return resolve({ data: cachedData });
        } else {
          searchUsedVideo(query, setting.config)
            .then((resp = []) => {
              const data = resp
                .filter(({ serviceName }) => serviceName === "Cloudinary")
                .map(({ id, serviceId, serviceName, thumbnail_url }) => {
                  const contentType = "video/mp4" as const;
                  return {
                    serviceId,
                    serviceName,
                    thumbUrl: thumbnail_url,
                    name: new Date().getTime().toString(),
                    id: id,
                    fromLibrary: true,
                    type: contentType,
                    contentType: contentType,
                  };
                });
              return resolve(
                dispatch({
                  type: MEDIA_VIDEO_USED_SET,
                  data,
                })
              );
            })
            .catch(error => reject(error));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getVideoMetaData(url: string) {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<ExternalVideo> =>
    new Promise<ExternalVideo>((resolve, reject) => {
      console.debug("getVideoMetaData", url);

      const { setting, user } = getState();
      if (_.isEmpty(url)) {
        return reject({ message: i18n.t("Media:Container.Action.Message") });
      }
      const { api_key } = setting.setting as SettingSettingData;
      const { adminURL } = setting.config as SettingConfigData;
      const { sessionToken } = user.data as UserDataState;

      _getVideoMetaData(url, { api_key, sessionToken, adminURL }).then(resp => {
        return resolve(Object.assign({}, resp, { isLink: true }));
      });
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function getMediaSetting() {
  return (dispatch: AppThunkDispatch): Promise<MediaSettingSetAction> =>
    new Promise<MediaSettingSetAction>(resolve => {
      //@FIXME we need to type this window object somehow
      //@Phuong Property 'storage' does not exist on type 'Window & typeof globalThis'. Did you mean 'Storage'
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      window.storage.getItem("mediaSetting").then(resp => {
        if (resp) {
          return resolve(
            dispatch({
              type: MEDIA_SETTING_SET,
              data: JSON.parse(resp),
            })
          );
        }
        return resolve();
      });
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function setMediaSetting(
  data: {
    [key in MediaSet]?: boolean;
  }
) {
  return (dispatch: AppThunkDispatch): Promise<MediaSettingSetAction> =>
    new Promise<MediaSettingSetAction>(resolve => {
      // @FIXME we should use localStorage instead some custom storage, proper version shown below
      // window.localStorage.setItem("mediaSetting", JSON.stringify(data));
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.storage.setItem("mediaSetting", JSON.stringify(data));
      return resolve(
        dispatch({
          type: MEDIA_SETTING_SET,
          data,
        })
      );
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}
