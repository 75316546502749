import { createSelector } from "reselect";
import { RootState } from "../../types";
import { MediaState } from "./types";
import { getSettingState } from "../Setting/selector";

const getMediaSettingData = (state: RootState): MediaState => state.media;

export const getMediaSettingState = createSelector(
  [getSettingState, getMediaSettingData],
  (setting, mediaSetting) => ({
    googleApi: setting.config?.googleMapKey || null,
    ...mediaSetting,
  })
);
