import { runFunction } from "../../http";
import { GetEventsOption } from "../../../types/Event/GetEventsOption";
import _ from "underscore";
import { EventItem } from "../../../types/Event/EventItem";
import { convertObject, displayDate } from "../../../lib/utils";
import { DictStrict } from "../../../types";

export const getEvents = async (
  options: GetEventsOption & {
    per: number;
    page: number;
    showMyAttendee: boolean;
  }
): Promise<DictStrict<EventItem>> => {
  let parseResponse = await runFunction("listEvents", options, {
    requireUser: true,
  });
  if (parseResponse && options.group) {
    parseResponse = parseResponse.filter(
      (item: { event: Parse.Object }) =>
        item.event.get("targetGroups").indexOf(options.group.id) > -1
    );
  }

  return _.object(
    _.map<EventItem, EventItem[]>(parseResponse, value => {
      const { id } = value.event;
      const item = convertObject(value);
      item.month = displayDate(
        item.event.startAt,
        "MMMM YYYY",
        item.event.timezone
      );
      return [id, item];
    })
  );
};
