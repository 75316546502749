import { adminPost } from "../../http";
import { Config } from "../../../types/Config";

export const makePayment = async (
  token: string,
  // eslint-disable-next-line @typescript-eslint/camelcase
  parse_id: string,
  // eslint-disable-next-line @typescript-eslint/camelcase
  amount_in_cents: number,
  config: Config
): Promise<{ message: string; error?: string }> => {
  return await adminPost(
    "payments/charge",
    {
      token: token,
      // eslint-disable-next-line @typescript-eslint/camelcase
      invoice_id: parse_id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      total: amount_in_cents,
    },
    {
      requireUser: true,
      config,
    }
  );
};
