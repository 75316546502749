import React, { PropsWithChildren } from "react";

import { Container, Row } from "reactstrap";

import { UserDataState } from "../../../types";

import { BackgroundFixed, Navbar } from "..";

export type TemplateAppProtectedProps = PropsWithChildren<{
  user: UserDataState;
}>;

export const TemplateAppProtected: React.FC<TemplateAppProtectedProps> = ({
  children,
  user,
}) => (
  <>
    <BackgroundFixed />
    <Container fluid className="h-100">
      <Row className="h-100">
        <Navbar user={user} />
        {children}
      </Row>
    </Container>
  </>
);
