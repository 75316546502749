import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { withTranslation, WithTranslation } from "react-i18next";

export type FeedbackDropdownProps = {
  initialName?: string;
  issueTypes: { id: string; name: string }[];
  onValueChange?: (type: { id: string; name: string }) => void;
} & WithTranslation;

const FeedbackDropdown: React.FC<FeedbackDropdownProps> = ({
  initialName,
  issueTypes,

  onValueChange,
  t,
}) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    initialName
  );

  const handleValueChange = (type: { id: string; name: string }): void => {
    setCurrentValue(type.name);

    onValueChange?.(type);
  };

  const handleClickFactory = (type: {
    id: string;
    name: string;
  }) => (): void => {
    if (type.id === currentValue) {
      return;
    }

    handleValueChange(type);
  };

  const label =
    currentValue === undefined
      ? t("Feedback.Placeholder.Issue.Type")
      : currentValue;

  return (
    <UncontrolledDropdown>
      <DropdownToggle caret block id="feedbackIssueTypeToggleDropdown">
        {label}
      </DropdownToggle>
      <DropdownMenu id="FeedbackIssueTypeSelectorDropdown">
        {issueTypes.map((type, index) => (
          <DropdownItem
            id={`feedbackSelectIssueTypeDropdown${index}`}
            key={index}
            onClick={handleClickFactory(type)}>
            {type.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default withTranslation("Support")(FeedbackDropdown);
