import React from "react";

import classnames from "classnames";
import { CardText } from "reactstrap";
import { isEmpty } from "underscore";

import { textThemingWorkaround } from "../../../constants/Theme";
import { getEventDate } from "../../../lib/utils";

import { useTheme } from "../../hooks";

import { Icon } from "..";

export type EventDateProps = {
  id?: string;
  className?: string;
  endAt: Date | undefined;
  isAllDayEvent: boolean | string | undefined;
  startAt: Date | undefined;
  timezone: string | undefined;
};

export const EventDate: React.FC<EventDateProps> = ({
  id,
  className,
  startAt,
  endAt,
  isAllDayEvent,
  timezone,
}) => {
  const {
    text: { listText },
  } = useTheme();

  const eventDateStyles = {
    listText: {
      ...listText,
      ...textThemingWorkaround,
      padding: 0,
    },
  };

  const eventDate = getEventDate(
    startAt,
    endAt,
    isAllDayEvent,
    timezone,
    false,
    true,
    true
  );

  return !isEmpty(eventDate) ? (
    <CardText
      id={`eventDateTime${id}`}
      className={classnames(className, "event-date-time")}>
      <Icon
        className="mr-2"
        style={eventDateStyles.listText}
        type="FontAwesome"
        name="calendar-o"
      />
      <span style={eventDateStyles.listText}>{eventDate}</span>
    </CardText>
  ) : null;
};
