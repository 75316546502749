import React, { useState } from "react";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";

import Invoices from "../../../../containers/Payment/Invoices";
import Receipts from "../../../../containers/Payment/Receipts";

import { StickyNavbar } from "../../UI/StickyNavbar";
import { PaymentInvoiceList, PaymentReceiptList } from "..";
import { useTheme } from "../../../hooks";

export const PaymentMixed: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Invoices");

  const toggle = (tab: string): void => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { t } = useTranslation("Payment");
  const {
    tabTop: { inactive, inactiveText, underlineColor },
  } = useTheme();

  const styles = {
    tabLink: {
      backgroundColor: inactive.backgroundColor,
      color: inactiveText.color,
      opacity: inactiveText.opacity,
    },
    tabLinkActive: {
      opacity: 1,
      borderColor: underlineColor,
    },
  };

  return (
    <div>
      <StickyNavbar>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{
                ...styles.tabLink,
                ...(activeTab === "Invoices" && styles.tabLinkActive),
              }}
              id="invoicesTab"
              onClick={(): void => {
                toggle("Invoices");
              }}>
              {t("Index.Heading.Invoices")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{
                ...styles.tabLink,
                ...(activeTab === "Receipts" && styles.tabLinkActive),
              }}
              id="receiptsTab"
              onClick={(): void => {
                toggle("Receipts");
              }}>
              {t("Index.Heading.Receipts")}
            </NavLink>
          </NavItem>
        </Nav>
      </StickyNavbar>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="Invoices">
          <Invoices Layout={PaymentInvoiceList} />
        </TabPane>
        <TabPane tabId="Receipts">
          <Receipts Layout={PaymentReceiptList} />
        </TabPane>
      </TabContent>
    </div>
  );
};
