import { AppItem } from "../../../types";
import { getArrayWithDeletedValue } from "../store";

export const getItemWithCommentRemoved = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  item,
  commentId,
  dataKeyName,
}: {
  /** Item to update */
  item?: ItemType;
  commentId: string;
  /**
   * Name of property storing nested entity data (and optional
   * reactionCount property)
   *
   * @FIXME
   * We have to specify this key name because of inconsistency in
   * all available Items shapes (nested data is stored under entity
   * name property). Unifing that property name and setting it
   * for example to "data" would simplify app data structures.
   */
  dataKeyName: DataKey;
}): ItemType | undefined =>
  item
    ? {
        ...item,
        comments: getArrayWithDeletedValue(commentId, item.comments),
        [dataKeyName]: {
          ...item[dataKeyName],
          commentCount: item[dataKeyName].commentCount - 1,
        },
      }
    : undefined;
