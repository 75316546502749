import {
  getAnnouncementItemWithCommentRemoved,
  getAnnouncementItemWithCommentsSet,
  getNextReactedAnnouncementItem,
} from "../../lib/utils/Announcement";
import { getUpsertedItemList } from "../../lib/utils/store";
import { getItemWithCommentUpdated } from "../../lib/utils/Comment";

import { AnnouncementState, GlobalAction } from "../../types";

import { COMMENT_UPDATE, COMMENT_DELETE } from "../Comment/constants";

import {
  ANNOUNCEMENT_ALL_SET,
  ANNOUNCEMENT_COMMENT_SET,
  ANNOUNCEMENT_DRAFT_ALL_SET,
  ANNOUNCEMENT_PIN_SET,
  ANNOUNCEMENT_PUBLISHED_SET,
  ANNOUNCEMENT_REACTION_SET,
  ANNOUNCEMENT_RESET,
  ANNOUNCEMENT_SEARCH_HISTORY_SET,
  ANNOUNCEMENT_SEARCH_SET,
  ANNOUNCEMENT_SET,
  ANNOUNCEMENT_TYPE_SET,
  ANNOUNCEMENT_DRAFT_SET,
} from "./constants";
import { announcementStateInit } from "./store";

export const announcementReducer = (
  state: AnnouncementState = announcementStateInit,
  action: GlobalAction
): AnnouncementState => {
  switch (action.type) {
    case ANNOUNCEMENT_RESET:
      return announcementStateInit;

    case ANNOUNCEMENT_PIN_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        pins: action.data,
      };

    case ANNOUNCEMENT_TYPE_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        types: action.data,
      };

    case ANNOUNCEMENT_ALL_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        list: {
          ...state.list,
          ...action.data,
        },
      };

    case ANNOUNCEMENT_SEARCH_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        searchList: action.data,
      };

    case ANNOUNCEMENT_SEARCH_HISTORY_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        history: {
          ...state.history,
          [action.data]: true,
        },
      };

    case ANNOUNCEMENT_SET:
      if (action.data) {
        const { id } = action.data;
        const { items, cached } = state.list;

        return {
          ...state,
          list: {
            ...state.list,
            //items can be null due to display a placeholder loading on the feed
            //so only set data into items if items had value already
            items: items
              ? {
                  ...items,
                  [id]: action.data,
                }
              : null,
            //always set data to cached data to display detail properly
            cached: {
              ...cached,
              [id]: action.data,
            },
          },
        };
      }

      return state;

    case ANNOUNCEMENT_COMMENT_SET:
      return {
        ...state,
        list: getUpsertedItemList({
          newItem: getAnnouncementItemWithCommentsSet({
            comments: action.data.items,
            item: state.list.items?.[action.data.id],
          }),
          newItemCached: getAnnouncementItemWithCommentsSet({
            comments: action.data.items,
            item: state.list.cached?.[action.data.id],
          }),
          dataKeyName: "announcement",
          itemList: state.list,
        }),
      };

    case ANNOUNCEMENT_REACTION_SET:
      return {
        ...state,
        list: getUpsertedItemList({
          newItem: getNextReactedAnnouncementItem({
            item: state.list.items?.[action.data.id],
            reaction: action.data.reaction,
          }),
          newItemCached: getNextReactedAnnouncementItem({
            item: state.list.cached?.[action.data.id],
            reaction: action.data.reaction,
          }),
          dataKeyName: "announcement",
          itemList: state.list,
          announcementId: action.data.id,
        }),
      };

    case ANNOUNCEMENT_PUBLISHED_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        publishedList: action.data,
      };

    case ANNOUNCEMENT_DRAFT_ALL_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        draftList: {
          ...state.draftList,
          ...action.data,
        },
      };

    case ANNOUNCEMENT_DRAFT_SET:
      if (!action.data) {
        return state;
      }
      return {
        ...state,
        cached: { ...state.cached, ...action.data },
      };

    /** Comments */

    case COMMENT_UPDATE:
      if (action.data.type === "announcement" || action.data.type === "event") {
        return {
          ...state,
          list: getUpsertedItemList({
            newItem: getItemWithCommentUpdated({
              comment: action.data.data,
              item: state.list.items?.[action.data.id],
            }),
            newItemCached: getItemWithCommentUpdated({
              comment: action.data.data,
              item: state.list.cached?.[action.data.id],
            }),
            dataKeyName: "announcement",
            itemList: state.list,
          }),
        };
      }

      return state;

    case COMMENT_DELETE:
      if (
        (action.data.type === "announcement" || action.data.type === "event") &&
        (state.list.items?.[action.data.id] ||
          state.list.cached?.[action.data.id]) &&
        action.data.data.id
      ) {
        return {
          ...state,
          list: getUpsertedItemList({
            newItem: getAnnouncementItemWithCommentRemoved({
              commentId: action.data.data.id,
              item: state.list.items?.[action.data.id],
            }),
            newItemCached: getAnnouncementItemWithCommentRemoved({
              commentId: action.data.data.id,
              item: state.list.cached?.[action.data.id],
            }),
            dataKeyName: "announcement",
            itemList: state.list,
          }),
        };
      }

      return state;

    default:
      return state;
  }
};

export default announcementReducer;
