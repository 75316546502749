import { createSelector } from "reselect";
import { RootState, UserDataState } from "../../types";

// selector

const getCurrentUser = (state: RootState): UserDataState | {} =>
  state.user.data || {};

const getCurrentUserHasNull = (state: RootState): UserDataState | null =>
  state.user.data;

const getViewingAsGuestStatus = (state: RootState): boolean =>
  state.user.viewingAsGuest;

// reselect function

export const getCurrentUserState = createSelector(
  [getCurrentUser],
  user => user
);

export const getCurrentUserHasNullState = createSelector(
  [getCurrentUserHasNull],
  user => user
);

export const getViewingAsGuestStatusState = createSelector(
  [getViewingAsGuestStatus],
  value => value
);
