import React from "react";

import { RouteComponentProps } from "react-router-dom";

import MapContainer from "../../../containers/Map";

import { Map } from "../../components/Map";

export type RouteMapProps = RouteComponentProps<{
  type: string;
}>;

export const RouteMap: React.FC<RouteMapProps> = props => (
  <MapContainer
    {...{
      ...props,
      match: {
        ...props.match,
        params: { ...props.match.params, types: ["profile"] },
      },
    }}
    Layout={Map}
  />
);
