import React from "react";

import { PostImagePlaceholder } from ".";

export type PostImagesPlaceholderProps = {};

export const PostImagesPlaceholder: React.FC<PostImagesPlaceholderProps> = () => (
  <>
    <PostImagePlaceholder />
    <PostImagePlaceholder />
    <PostImagePlaceholder />
    <PostImagePlaceholder />
  </>
);
