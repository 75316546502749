import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { isEmpty } from "underscore";

import { track } from "../../lib/track";

import {
  CommentType,
  DictStrict,
  EmptyObject,
  MatchProps,
  PostGroup,
  PostItem,
  ReactionType,
  RootState,
  UserDataState,
} from "../../types";

import { doReaction } from "../Comment/action";

import { getGroupPosts, removePost } from "./action";
import { getCurrentUserState } from "../User/selector";
import {
  getPostsSettingState,
  getGroupPostsState,
  getGroupPostsHasMoreState,
} from "./selector";

type StateProps = {
  user: UserDataState | {};
  setting: {
    ClientHostName?: string;
    confettiEffectActions?: [];
  };
  data: PostItem[] | null | undefined;
  hasMore: boolean;
};

type DispatchProps = {
  getPosts: (
    groupId: string,
    next: boolean,
    refresh: boolean,
    trackCallback?: (data: DictStrict<PostItem>) => void,
    refreshWhenReactive?: boolean
  ) => Promise<void>;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>; //@TODO this is assumption, compare with comment container typing
  removePost: (post: {
    id: string;
    group: PostGroup & { id?: string };
  }) => Promise<{ data: boolean }>;
};

export type PostGroupLayoutProps = {
  data: PostItem[] | null | undefined;
  error: string | null;
  groupId?: string;
  hasMore: boolean;
  loading: boolean;
  refreshing: boolean;
  setting: {
    ClientHostName?: string;
    confettiEffectActions?: [];
  };
  user: UserDataState | EmptyObject;

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType,
    authorId?: string
  ) => Promise<void>;
  reFetch: (
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean | undefined
  ) => void;
  removePost?: (
    post: {
      id: string;
      group: PostGroup & { id?: string };
    },
    callback?: (text: string) => void
  ) => Promise<void>;
};

type OwnProps = {
  Layout: ComponentType<PostGroupLayoutProps>;
};

type Props = StateProps &
  DispatchProps &
  MatchProps &
  OwnProps &
  WithTranslation;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class GroupPosts extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false, true);

    track("View Screen", {
      Screen: "group-post",
      Params: this.props.match && this.props.match.params,
    });
  }

  componentDidUpdate(prevProps: Props): void {
    /**
     * Data refetch when match.params.id changed
     * Especially for web usage when id passed in address could be changed.
     *
     * Same logic should be probably implemented in every container injecting
     * URL params dependend data
     */
    if (prevProps.match.params?.id !== this.props.match.params?.id) {
      this.fetchData(false, true);
    }
  }

  fetchData = (
    next: boolean,
    refresh: boolean,
    refreshWhenReactive?: boolean
  ): void => {
    const { getPosts, match, data } = this.props;
    const { refreshing, loading } = this.state;
    const groupId = match.params?.id;

    if (refreshing || loading || !groupId) {
      return;
    }

    this.setState(
      { refreshing: refresh && !isEmpty(data), loading: next },
      async () => {
        try {
          await getPosts(
            groupId,
            next,
            refresh,
            this.onTrack,
            refreshWhenReactive
          );
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  onRemovePost = async (
    post: {
      id: string;
      group: PostGroup & { id?: string };
    },
    callback?: (text: string) => void
  ): Promise<void> => {
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }
    try {
      await this.props.removePost(post);

      this.setState({ error: null });
      callback && callback(this.props.t("Container.Index.Delete"));
      track("Delete Post", {
        "Post ID": post.id,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };

  onTrack = (posts: DictStrict<PostItem> | null): void => {
    if (posts !== null) {
      Object.keys(posts).forEach(id => {
        track("View Post", {
          "Post ID": id,
        });
      });
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      data,
      setting,
      doReaction,
      hasMore,
      match,
    } = this.props;
    const { loading, error, refreshing } = this.state;
    const groupId = match.params?.id;

    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        reFetch={this.fetchData}
        doReaction={doReaction}
        removePost={this.onRemovePost}
        groupId={groupId}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): StateProps => {
  const groupId = ownProps.match.params?.id;
  return {
    user: getCurrentUserState(state),
    setting: getPostsSettingState(state),
    data: getGroupPostsState(state, groupId || ""),
    hasMore: getGroupPostsHasMoreState(state, groupId || ""),
  };
};

const mapDispatchToProps = {
  getPosts: getGroupPosts,
  doReaction,
  removePost,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Post")(GroupPosts));
