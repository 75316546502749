import { WORK_SPACE_GET_ALL, WORK_SPACE_RESET } from "./constants";
import Store from "./store";
import { WorkSpaceAction, WorkSpaceState } from "./types";

export const initialState = Store;

export default function reducer(
  state: WorkSpaceState = initialState,
  action: WorkSpaceAction
): WorkSpaceState {
  switch (action.type) {
    case WORK_SPACE_RESET:
      return initialState;

    case WORK_SPACE_GET_ALL:
      if (action.data) {
        return {
          ...state,
          error: null,
          data: action.data,
        };
      }

      return state;

    default:
      return state;
  }
}
