import { Member } from "../../../types";

import { getCurrentUser } from ".";
import { convertObject } from "../../../lib/utils";

export const logIn = async (
  username: string,
  password: string
): Promise<Member | void> => {
  await Parse.User.logIn(username, password);

  const parseRespose = await getCurrentUser();
  return convertObject(parseRespose);
};
