import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  getProducts,
  getSubscriptions,
  cancelSubscription,
  createSubscription,
  resumeSubscription,
  setPaymentMethod,
} from "./action";
import {
  Subscriptions,
  Products,
  NewSubscription,
} from "../../types/Subscription";
import { MatchProps, RootState, SettingSettingData } from "../../types";

type StateProps = {
  data: Subscriptions | null;
  products: Products | null;
  showPlaceholder: boolean;
  setting: SettingSettingData;
};

type DispatchProps = {
  getProducts: (refresh: boolean) => Promise<void>;
  getSubscriptions: (refresh: boolean) => Promise<void>;
  createSubscription: (data: NewSubscription) => Promise<void | string>;
  cancelSubscription: (id: string) => Promise<void | string>;
  resumeSubscription: (id: string) => Promise<void | string>;
  setPaymentMethod: (token: string) => Promise<void | string>;
};

export type NewSubscriptionProduct = {
  productId: string;
  priceId: string;
  name: string;
};

export type SubscriptionListLayoutProps = {
  error: string | null;
  setting: SettingSettingData;
  data: Subscriptions | null;
  products: Products | null;
  refreshing: boolean;
  sending: boolean;
  showPlaceholder: boolean;
  reFetch: (refresh: boolean) => void;
  createSubscription: (
    paymentMethod: string,
    data: NewSubscriptionProduct,
    callback: (text: string) => void
  ) => void;
  cancelSubscription: (id: string, callback: (text: string) => void) => void;
  resumeSubscription: (id: string, callback: (text: string) => void) => void;
  setPaymentMethod: (
    paymentMethod: string,
    callback: (text: string) => void
  ) => void;
};

type OwnProps = {
  Layout: ComponentType<SubscriptionListLayoutProps>;
} & MatchProps;

type Props = StateProps & DispatchProps & OwnProps & WithTranslation;

type States = {
  error: string | null;
  sending: boolean;
  refreshing: boolean;
};

class SubscriptionList extends Component<Props, States> {
  state: States = {
    error: null,
    sending: false,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(true);
  }

  fetchData = (refresh: boolean): void => {
    const { getProducts, getSubscriptions } = this.props;
    const { refreshing } = this.state;

    if (refreshing) {
      return;
    }

    this.setState({ refreshing: refresh }, async () => {
      try {
        await getProducts(refresh);
        await getSubscriptions(refresh);
        this.setState({
          sending: false,
          refreshing: false,
          error: null,
        });
      } catch (error) {
        this.setState({ sending: false, refreshing: false, error: error });
      }
    });
  };

  createSubscription = (
    paymentMethod: string,
    data: NewSubscriptionProduct,
    callback: (text: string) => void
  ): void => {
    const { createSubscription } = this.props;
    const { sending } = this.state;

    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        const { productId, priceId } = data;
        const newSubscription: NewSubscription = {
          productId,
          priceId,
          paymentMethod,
        };
        const message = await createSubscription(newSubscription);
        this.setState({ sending: false, error: null });
        callback?.(message as string);
      } catch (error) {
        this.setState({ sending: false, error });
      }
    });
  };

  setPaymentMethod = (
    paymentMethod: string,
    callback: (text: string) => void
  ): void => {
    const { setPaymentMethod } = this.props;
    const { sending } = this.state;

    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        const message = await setPaymentMethod(paymentMethod);
        this.setState({ sending: false, error: null });
        callback?.(message as string);
      } catch (error) {
        this.setState({ sending: false, error });
      }
    });
  };

  cancelSubscription = (id: string, callback: (text: string) => void): void => {
    const { cancelSubscription } = this.props;
    const { sending } = this.state;

    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        const message = await cancelSubscription(id);
        this.setState({ sending: false, error: null });
        callback?.(message as string);
      } catch (error) {
        this.setState({ sending: false, error });
      }
    });
  };

  resumeSubscription = (id: string, callback: (text: string) => void): void => {
    const { resumeSubscription } = this.props;
    const { sending } = this.state;

    if (sending) {
      return;
    }
    this.setState({ sending: true }, async () => {
      try {
        const message = await resumeSubscription(id);
        this.setState({ sending: false, error: null });
        callback?.(message as string);
      } catch (error) {
        this.setState({ sending: false, error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, data, products, showPlaceholder, setting } = this.props;
    const { sending, error, refreshing } = this.state;

    return (
      <Layout
        setting={setting}
        data={data}
        products={products}
        error={error}
        refreshing={refreshing}
        sending={sending}
        showPlaceholder={showPlaceholder}
        reFetch={this.fetchData}
        createSubscription={this.createSubscription}
        cancelSubscription={this.cancelSubscription}
        resumeSubscription={this.resumeSubscription}
        setPaymentMethod={this.setPaymentMethod}
      />
    );
  };
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  const { setting } = state.setting;
  const { subscriptions, products } = state.subscription;

  return {
    setting: setting as SettingSettingData,
    data: subscriptions,
    products: products,
    showPlaceholder: !subscriptions || !products,
  };
};

const mapDispatchToProps = {
  getProducts,
  getSubscriptions,
  cancelSubscription,
  createSubscription,
  resumeSubscription,
  setPaymentMethod,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Subscription")(SubscriptionList));
