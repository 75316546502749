/* eslint-disable @typescript-eslint/camelcase */
import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";

import { RootState, SettingSettingData } from "../../types";

import { getMemberLogin } from "../Member/action";
import { resetSetting } from "../Setting/action";

import { getWorkSpaces, getWorkSpace } from "./action";
import { LoginDataSet, UserSet } from "./types";

export type WorkspaceStateProps = {
  workSpaces: LoginDataSet[] | {};
  setting: { logo: string; backgroundImage: string };
};

export type WorkspaceDispatchProps = {
  getMemberLogin: (id: string) => Promise<string | undefined>;
  getWorkSpaces: (username?: string) => Promise<void>;
  getWorkSpace: (
    domain?: string,
    user?: { [key in UserSet]: string } | null,
    signUpProcess?: boolean
  ) => Promise<void>;
  resetSetting?: () => Promise<void>;
};

export type WorkspaceLayoutProps = {
  setting: {
    backgroundImage: string;
    logo: string;
  };
  data: WorkspaceState;
  /**
   * @FIXME
   * Empty object probably should be replaced by null
   */
  workSpaces: {} | LoginDataSet[];

  /**
   * @TODO
   * Please review carefully. Component API changed.
   *
   * I think that putting callback as a second parameter
   * (while fallcack as a first) is kinda antipattern but I
   * don't want to introduce any backward compatibility breaking
   * changes
   */
  getWorkSpace: (
    fallback?: (error: string) => void,
    callback?: () => void
  ) => void;
  getWorkSpaces: (callback?: () => void) => void;
  handleChange: <K extends keyof WorkspaceState>(
    key: K,
    value: WorkspaceState[K]
  ) => void;
  resetSetting?: () => Promise<void>;
};

export type WorkspaceProps = {
  Layout: ComponentType<WorkspaceLayoutProps>;
  userId?: string;
} & WorkspaceDispatchProps &
  WorkspaceStateProps &
  WithTranslation;

export type WorkspaceState = {
  phone: string;
  email: string;
  domain: string;
  user: { [key in UserSet]: string } | null;
  enteringTenant: boolean;
  countryCode: string;
  loading: boolean;
  error: string | null;
  username: string;
};

class WorkSpace extends Component<WorkspaceProps, WorkspaceState> {
  constructor(props: WorkspaceProps) {
    super(props);

    this.state = {
      phone: "+1",
      email: "",
      domain: "",
      user: null,
      enteringTenant: false,
      countryCode: "US",
      loading: false,
      error: null,
      username: "",
    };
  }

  componentDidMount = async (): Promise<void> => {
    if (this.props.userId) {
      this.setState({ loading: true });

      const memberLogin = await this.props.getMemberLogin(this.props.userId);

      this.setState({ username: memberLogin || "", loading: false });
    }
  };

  onHandleChange = <K extends keyof WorkspaceState>(
    key: K,
    value: WorkspaceState[K]
  ): void => {
    this.setState({ [key]: value } as Pick<WorkspaceState, K>);
  };

  onGetWorkSpaces = (callback?: () => void): void => {
    const { username } = this.state;
    if (!username) {
      this.setState({
        error: this.props.t("Container.Index.Error"),
      });
      return;
    }
    this.setState({ loading: true }, async () => {
      try {
        await this.props.getWorkSpaces(username);
        this.setState({ loading: false, error: null });
        callback && callback();
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  onGetWorkSpace = (
    fallback?: (error: string) => void,
    callback?: () => void
  ): void => {
    const { domain, user, enteringTenant } = this.state;
    const signUpProcess = enteringTenant;
    this.setState({ loading: true }, async () => {
      try {
        await this.props.getWorkSpace(domain, user, signUpProcess);
        callback?.();
      } catch (error) {
        fallback && fallback(error);
        this.setState({ loading: false, error: error, domain: "" });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, workSpaces, setting, resetSetting } = this.props;
    return (
      <Layout
        setting={setting}
        data={this.state}
        workSpaces={workSpaces}
        handleChange={this.onHandleChange}
        getWorkSpaces={this.onGetWorkSpaces}
        getWorkSpace={this.onGetWorkSpace}
        resetSetting={resetSetting}
      />
    );
  };
}

const mapStateToProps = (state: RootState): WorkspaceStateProps => {
  const { setting } = state.setting;
  const {
    logo_large,
    sign_up_page_background_image,
  } = setting as SettingSettingData;
  return {
    workSpaces: state.workSpace.data || {},
    setting: {
      logo: logo_large,
      backgroundImage: sign_up_page_background_image,
    },
  };
};

const mapDispatchToProps = {
  getMemberLogin,
  getWorkSpaces,
  getWorkSpace,
  resetSetting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("WorkSpace")(WorkSpace));
