import React from "react";

import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

type PaymentInvoiceCardPlaceholderProps = {};

export const PaymentInvoiceCardPlaceholder: React.FC<PaymentInvoiceCardPlaceholderProps> = () => (
  <div>
    {[1, 2, 3].map(i => (
      <Card key={i} className="my-2">
        <CardBody>
          <h6>
            <Skeleton />
          </h6>
          <Row>
            <Col>
              <Skeleton width={50} />
            </Col>
            <Col className="text-right text-primary font-weight-bold">
              <Skeleton width={80} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    ))}
  </div>
);
