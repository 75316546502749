import { useIsHomePageEnabled, useSettingFeature, useUserDataState } from "..";

export const useAppDefaultPath = (): string => {
  const features = useSettingFeature() || [];

  const user = useUserDataState();

  const isHomepageEnabled = useIsHomePageEnabled();

  if (!user) return "/login";

  /**
   * @TODO
   * Make sure there are only two statuses available
   */
  if (user.status === "pending") return "/edit-profile";

  if (isHomepageEnabled) return "/home";

  /** @TODO Consider what should be default protected app route */
  let defaultRedirectPath = "/notifications";

  let i = 0;

  while (i < features.length) {
    if (features[i].home) {
      defaultRedirectPath = `/${features[i].pageId}`;
      break;
    }
    i++;
  }

  return defaultRedirectPath;
};
