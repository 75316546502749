import React, { ChangeEvent, FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { GroupMembersLayoutProps } from "../../../containers/Member/Group";

import { Member } from "../../../types";

import { InfiniteScroll, MemberCardPlaceholder, SearchBox } from "../";

import { MemberCard } from ".";

export const GroupMembersList: FC<GroupMembersLayoutProps> = ({
  searchedData,
  data,
  changeQuery,
  refreshing,
  query,
  showPlaceholder,
}) => {
  const { t } = useTranslation("Member");
  const [loadNumber, setLoadNumber] = useState<number>(10);

  const loadMore = (max: number) => (): void => {
    setLoadNumber(prevState => {
      return prevState + 10 >= max ? max : prevState + 10;
    });
  };

  const renderMemberSectionItems = (members: Member[]): JSX.Element[] => {
    return members.map(item => (
      <MemberCard key={item.id} item={item} picker={false} />
    ));
  };

  const renderMemberList = (members: Member[]): JSX.Element => {
    const renderMembers: Member[] =
      members.length > loadNumber ? members.slice(0, loadNumber) : members;

    return (
      <>
        {
          <div>
            <InfiniteScroll
              emptyText={t("GroupMemberList.Text.No.Members.Found")}
              isLoading={false}
              isRefreshing={refreshing && showPlaceholder}
              hasMore={members.length > loadNumber}
              onLoadMore={loadMore(members.length)}
              placeholder={<MemberCardPlaceholder />}>
              {renderMemberSectionItems(renderMembers)}
            </InfiniteScroll>
          </div>
        }
      </>
    );
  };

  return (
    <>
      <div className={"my-2"}>
        <SearchBox
          onValueChange={(event: ChangeEvent<HTMLInputElement>): void => {
            changeQuery(event.target.value);
          }}
          value={query !== null && query ? query : ""}
          placeholder={t("GroupMemberList.Placeholder.Search.Member")}
        />
      </div>
      {renderMemberList(query !== "" ? searchedData : data)}
    </>
  );
};
