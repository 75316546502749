import React from "react";

import { RouteComponentProps } from "react-router-dom";

import OnboardingContainer from "../../../containers/Onboarding";

import { Onboarding } from "../../components/Onboarding";

export type RouteOnboardingProps = {} & RouteComponentProps;

export const RouteOnboarding: React.FC<RouteOnboardingProps> = props => (
  <OnboardingContainer {...props} Layout={Onboarding} />
);
