import { MediaState } from "./types";

export const mediaStateInit: MediaState = {
  error: null,
  data: null,
  photoGiphy: null,
  photoStock: null,
  photoWeb: null,
  photoUsed: null,
  videoUsed: null,
  videoLibrary: null,
  setting: {},
};

export default mediaStateInit;
