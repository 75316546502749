import { runFunction } from "../../http";

export const saveGroupMemberSetting = async (
  groupId: string,
  settings: {}
): Promise<void> => {
  console.debug("[Service] saveGroupMemberSetting");
  await runFunction(
    "saveGroupFeedNotifications",
    {
      groupId: groupId,
      settings: settings,
    },
    { requireUser: true }
  );
};
