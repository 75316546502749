import {
  getResources as _getResources,
  getResource as _getResource,
  ServiceApiGetResourcesOptions,
} from "../../services/api";
import { logException } from "../../lib/utils";
import _ from "underscore";
import { page as _page } from "../../constants";
import { RESOURCE_ALL_SET, RESOURCE_SET } from "./constants";
import { ResourceItem } from "../../types/Resource";
import { AppThunkAction, AppThunkDispatch, GetState } from "../../types";

const per = _page.resource;

export const getResources = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getResources");

  try {
    const { resource } = getState();
    const { list } = resource;
    let { page } = list;
    const { items } = list;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    } else if (next) {
      page++;
    }

    const options: ServiceApiGetResourcesOptions = {
      per,
      page,
      notIncludeBody: true,
    };

    let data = await _getResources(options);

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: RESOURCE_ALL_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export type GetResourceActionReturn = { data?: ResourceItem };

export const getResource = (
  id: string,
  refresh: boolean,
  callback?: (data: ResourceItem) => void
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getResource");

  try {
    const { resource } = getState();
    const { list } = resource;

    //because `items` doesn't include all fields (misssing `body` due to it's probably too big for list),
    // so we need to get data from `cached`
    const { cached } = list;

    if (!refresh && cached[id]) {
      if (callback) {
        callback(cached[id]);
      }
      return;
    }

    const data = await _getResource(id);

    if (callback && data) {
      callback(data);
    }
    dispatch({
      type: RESOURCE_SET,
      data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
