import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import {
  getChatRooms,
  createChannel,
  deleteChannel,
  updateChannel,
  getFavoriteContacts,
  addToFavoriteContact,
  removeFromFavoriteContact,
  saveChatNotificationSettings,
} from "./action";
import { isEmpty } from "underscore";
import { track } from "../../lib/track";
import { UserDataState } from "../../types/User/UserDataState";
import { MatchProps } from "../../types";
import { RootState } from "../../types";
import { ChatGroupChannel } from "../../types/Chat/ChatGroupChannel";
import { ChatOpenChannel } from "../../types/Chat/ChatOpenChannel";
import { ChatSetting } from "../../types/Chat/ChatSetting";
import { SettingState } from "../../types";
import { GroupChannel, OpenChannel } from "sendbird";
import { ChatRoomAllSetAction } from "./types";
import { ChatMutatedChannel } from "./chatTypes";
import { withTranslation, WithTranslation } from "react-i18next";
import { getCurrentUserState } from "../User/selector";
import { getSettingState } from "../Setting/selector";
import {
  getChatNotificationSettingState,
  getChatFavoritesState,
  getChatListTypeState,
  getChatListDataState,
} from "./selector";

type StateProps = {
  user: UserDataState | {};
  data: (ChatGroupChannel | ChatOpenChannel)[];
  layoutType: "single" | "type";
  setting: SettingState | {};
  favorites: UserDataState[];
  notificationSettings: { [key: string]: ChatSetting };
  simpleMode?: boolean;
  onClick?: (channel: ChatMutatedChannel) => void;
};

type DispatchProps = {
  getChatRooms: (
    next: boolean,
    refresh?: boolean
  ) => Promise<ChatRoomAllSetAction>;
  getFavoriteContacts: (refresh: boolean) => Promise<void>;
  saveNotificationSettings: (
    channelUrl: string,
    setting: ChatSetting
  ) => Promise<void>;
  createChannel: (
    users: { id: string }[],
    name?: string,
    coverFile?: string | File
  ) => Promise<{ data: string }>;
  deleteChannel: (
    channelUrl: string
  ) => Promise<OpenChannel | Partial<GroupChannel>>;
  updateChannel: (channelUrl: string, name: string) => Promise<OpenChannel>;
  addToFavoriteContact: (members: UserDataState[]) => Promise<void>;
  removeFromFavoriteContact: (members: UserDataState[]) => Promise<void>;
};

export type ListChatLayout = {
  error: string | null;
  loading?: boolean;
  refreshing?: boolean;
  hasMore?: boolean;
  activeTab: number;
  query?: string;
  user?: UserDataState | {};
  data: any;
  layoutType: "single" | "type";
  setting: SettingState | {};
  favorites?: UserDataState[];
  notificationSettings?: { [key: string]: ChatSetting };
  renderActions?: boolean;
  renderActionsDone?: boolean;
  simpleMode?: boolean;
  onClick?: (channel: ChatMutatedChannel) => void;
  reFetch?: (next: boolean, refresh: boolean, silent?: boolean) => void;
  changeTab: (activeTab: number) => void;
  changeQuery?: (query: string) => void;
  createChannel: (
    members: UserDataState[],
    callback?: (text: string) => void
  ) => void;
  removeChannel: (
    channelUrl: string,
    callback?: (text: string) => void
  ) => void;
  updateChannel?: (
    channelUrl: string,
    name: string,
    callback?: (text: string) => void
  ) => void;
  addToFavoriteContact?: (
    members: UserDataState[],
    callback: (text: string) => void
  ) => void;
  removeFromFavoriteContact?: (
    members: UserDataState[],
    callback: (text: string) => void
  ) => void;
  saveNotificationSettings?: (
    channelUrl: string,
    data: ChatSetting,
    callback?: (text: string) => void
  ) => void;
};
type OwnProps = {
  Layout: ComponentType<ListChatLayout>;
  renderActions?: boolean;
  renderActionsDone?: boolean;
  simpleMode?: boolean;
  onClick?: (channel: ChatMutatedChannel) => void;
} & MatchProps;
type Props = OwnProps & StateProps & DispatchProps & WithTranslation;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  hasMore?: boolean;
  activeTab: number;
  query?: string;
};

class ChatRooms extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
    hasMore: false,
    activeTab: 0,
  };

  componentDidMount(): void {
    this.fetchData(false, true, true);
    this.fetchExtraData(true);
    track("View Screen", {
      Screen: "chat-room",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (next: boolean, refresh: boolean, silent?: boolean): void => {
    const { getChatRooms, data } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState(
      { refreshing: refresh && !isEmpty(data), loading: next },
      async () => {
        try {
          const resp = await getChatRooms(next, refresh);
          if (resp) {
            this.setState({
              loading: false,
              refreshing: false,
              error: null,
              hasMore: resp?.hasMore as boolean,
            });
          }
        } catch (error) {
          this.setState({ loading: false, refreshing: false, error: error });
        }
      }
    );
  };

  fetchExtraData = (refresh: boolean): void => {
    this.props.getFavoriteContacts(refresh);
  };

  onChangeTab = (activeTab: number): void => {
    this.setState({ activeTab });
  };

  onSaveNotificationSettings = (
    channelUrl: string,
    data: ChatSetting,
    callback?: (text: string) => void
  ): void => {
    this.setState({}, async () => {
      try {
        this.props.saveNotificationSettings(channelUrl, data);
        callback &&
          callback(this.props.t("Container.Index.Callback.Update.Settings"));
      } catch {
        // continue regardless of error
      }
    });
  };

  createChannel = (
    members: UserDataState[],
    callback?: (url: string) => void
  ): void => {
    const { createChannel } = this.props;
    this.setState({}, async () => {
      try {
        const resp = await createChannel(members);
        if (resp?.data) {
          callback?.(resp.data);
          this.setState({ error: null });
          track("Start Chat", { channel: resp.data });
        }
      } catch (error) {
        this.setState({ error: error });
      }
    });
  };

  onChangeQuery = (query: string): void => {
    this.setState({ query });
    track("Search Members", {
      "Search term": query,
    });
  };

  onRemoveChannel = (
    channelUrl: string,
    callback?: (text: string) => void
  ): void => {
    this.props.deleteChannel(channelUrl).then(() => {
      this.fetchData(false, true);
      track("Remove Chat", { channel: channelUrl });
      callback?.(this.props.t("Container.Index.Callback.Delete"));
    });
  };

  onUpdateChannel = (
    channelUrl: string,
    name: string,
    callback?: (text: string) => void
  ): void => {
    this.props.updateChannel(channelUrl, name).then(() => {
      this.fetchData(false, true);
      track("Update Chat Name", { channel: channelUrl });
      callback?.(this.props.t("Container.Index.Callback.Update.Chat.Name"));
    });
  };

  onAddToFavoriteContact = (
    members: UserDataState[],
    callback: (text: string) => void
  ): void => {
    this.setState({}, async () => {
      try {
        await this.props.addToFavoriteContact(members);
        callback?.(this.props.t("Container.Index.Callback.Added.To.Favourite"));
      } catch (error) {
        // continue regardless of error
      }
    });
  };

  onRemoveFromFavoriteContact = (
    members: UserDataState[],
    callback: (text: string) => void
  ): void => {
    this.setState({}, async () => {
      try {
        await this.props.removeFromFavoriteContact(members);
        callback?.(
          this.props.t("Container.Index.Callback.Removed.From.Favourite")
        );
      } catch (err) {
        // continue regardless of error
      }
    });
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      data,
      setting,
      layoutType,
      favorites,
      notificationSettings,
      renderActions,
      renderActionsDone,
      simpleMode,
      onClick,
    } = this.props;
    const {
      loading,
      error,
      refreshing,
      hasMore,
      query,
      activeTab,
    } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        favorites={favorites}
        layoutType={layoutType}
        query={query}
        activeTab={activeTab}
        notificationSettings={notificationSettings}
        reFetch={this.fetchData}
        changeTab={this.onChangeTab}
        createChannel={this.createChannel}
        changeQuery={this.onChangeQuery}
        removeChannel={this.onRemoveChannel}
        updateChannel={this.onUpdateChannel}
        addToFavoriteContact={this.onAddToFavoriteContact}
        removeFromFavoriteContact={this.onRemoveFromFavoriteContact}
        saveNotificationSettings={this.onSaveNotificationSettings}
        renderActions={renderActions}
        renderActionsDone={renderActionsDone}
        simpleMode={simpleMode}
        onClick={onClick}
      />
    );
  };
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): StateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getSettingState(state),
    data: getChatListDataState(state, ownProps.simpleMode),
    layoutType: getChatListTypeState(state, ownProps.simpleMode),
    favorites: getChatFavoritesState(state),
    notificationSettings: getChatNotificationSettingState(state),
  };
};

const mapDispatchToProps = {
  getChatRooms,
  createChannel,
  deleteChannel,
  updateChannel,
  getFavoriteContacts,
  addToFavoriteContact,
  removeFromFavoriteContact,
  saveNotificationSettings: saveChatNotificationSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Chat")(ChatRooms));
