import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Progress, Button } from "reactstrap";

import { NotificationUploadData } from "../../../types";

export type UploadingProps = {
  className?: string;
  status: NotificationUploadData | null;

  cancel: () => void;
};

export const Uploading: React.FC<UploadingProps> = ({
  className,
  status,
  cancel,
}) => {
  const { t } = useTranslation("Uploading");
  if (!status) {
    return null;
  }

  const { percent } = status;
  return (
    <div className={classnames(className, "p-3", "bg-light")}>
      <div className="position-relative h-100 w-100">
        <Progress animated color="success" value={percent * 100}>
          {t("Index.Uploading")}
        </Progress>
        <Button
          color="link"
          size="sm"
          onClick={cancel}
          className="p-0 m-0"
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            lineHeight: 0,
          }}>
          <i className="icon-close" style={{ fontSize: 15 }} />
        </Button>
      </div>
    </div>
  );
};
