import _ from "underscore";

import { page as _page } from "../../constants";

import { logException } from "../../lib/utils";
import { getDictDefinedValues } from "../../lib/utils/store";

import {
  getAnnouncements as _getAnnouncements,
  getAnnouncement as _getAnnouncement,
  getAnnouncementTypes as _getAnnouncementTypes,
  addAnnouncementReadBy as _addAnnouncementReadBy,
  manageAnnouncements as _manageAnnouncements,
  addAnnouncement as _addAnnouncement,
  updateAnnouncement as _updateAnnouncement,
  removeAnnouncement as _removeAnnouncement,
  searchFullTextAnnouncements as _searchFullTextAnnouncements,
} from "../../services/api";

import {
  Announcement,
  AnnouncementItem,
  AppThunkAction,
  AppThunkDispatch,
  GetState,
  ProgressCallback,
  SettingConfigData,
} from "../../types";
import { UserDataState } from "../../types/User/UserDataState";

import { removeUnreadAnnouncement } from "../Notification/action";

import {
  ANNOUNCEMENT_ALL_SET,
  ANNOUNCEMENT_DRAFT_ALL_SET,
  ANNOUNCEMENT_DRAFT_SET,
  ANNOUNCEMENT_PIN_SET,
  ANNOUNCEMENT_PUBLISHED_SET,
  ANNOUNCEMENT_SEARCH_HISTORY_SET,
  ANNOUNCEMENT_SEARCH_SET,
  ANNOUNCEMENT_SET,
  ANNOUNCEMENT_TYPE_SET,
} from "./constants";
import { NOTIFICATION_UPLOAD_SET } from "../Notification/constants";

const per = _page.announcement;

export type CreateAnnouncementActionReturn = { data: true };

export type ManageAnnouncementActionReturn = {
  data: Announcement;
};

/**
 * Get list annoucements
 * @param option
 * @param next
 * @param refresh
 */
export const getAnnouncements = (
  option: {},
  next: boolean,
  refresh: boolean,
  refreshWhenReactive?: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getAnnouncements");

  try {
    const { announcement } = getState();
    const { list } = announcement;
    let { page } = list;
    const { items } = list;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
      if (refreshWhenReactive) {
        dispatch({
          type: ANNOUNCEMENT_ALL_SET,
          data: {
            items: null,
            page,
            hasMore: true,
          },
        });
      }
    } else if (next) {
      page++;
    }

    let data = await _getAnnouncements({
      per,
      page,
      showComments: true,
      ...option,
    });

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: ANNOUNCEMENT_ALL_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });

    //Remove unread couter badge as soon as data are loaded
    dispatch(
      removeUnreadAnnouncement(
        getDictDefinedValues(data).map(
          announcementItem => announcementItem.announcement.id
        )
      )
    );
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Search announcements by text and #tag
 * @param search
 * @param next
 * @param refresh
 */
export const searchFullTextAnnouncements = (
  search: string,
  next: boolean,
  refresh: boolean
): AppThunkAction => {
  return async (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<void> => {
    console.debug("[Action] searchFullTextAnnouncements");

    try {
      const { announcement, group } = getState();
      const { myGroups } = group;

      const { searchList } = announcement;
      let { page } = searchList;
      const { items } = searchList;

      if (_.isEmpty(searchList)) {
        page = 0;
        dispatch({
          type: ANNOUNCEMENT_SEARCH_SET,
          data: {
            items: {},
            page,
            hasMore: true,
          },
        });
      } else {
        if (refresh) {
          page = 0;
        } else if (next) {
          page++;
        }
      }

      let data = await _searchFullTextAnnouncements(
        {
          per,
          page,
          search,
        },
        myGroups && Object.keys(myGroups)
      );

      const hasMore = Object.keys(data).length === per;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: ANNOUNCEMENT_SEARCH_SET,
        data: {
          items: data,
          page,
          hasMore,
        },
      });

      /**
       * Add the search text into search history
       */
      dispatch({
        type: ANNOUNCEMENT_SEARCH_HISTORY_SET,
        data: search,
      });
    } catch (err) {
      logException(err);
      throw err && err.message;
    }
  };
};

/**
 * get anncoucement detail
 * @param id
 * @param options
 * @param refresh
 * @param callback
 */
export const getAnnouncement = (
  id: string,
  options: { showComments: boolean; userId?: string; uid?: string },
  refresh: boolean,
  callback?: (announcement: Announcement) => void
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getAnnouncement");

  try {
    const { announcement, user } = getState();
    /**
     * @FIXME Temporarily workaround
     */
    const uid = (user.data && user.data.id) || (options.uid as string);

    const { list } = announcement;
    const { items } = list;
    let data = items?.[id];

    if (!refresh && data) {
      !user.viewingAsGuest && _addAnnouncementReadBy(id, uid); //Prevent adding Readby in Guest mode.
    } else {
      data = await _getAnnouncement(id, options);

      !user.viewingAsGuest && _addAnnouncementReadBy(id, uid);

      dispatch(removeUnreadAnnouncement([id]));

      if (data && data.announcement && callback) {
        callback(data.announcement);
      }

      dispatch({
        type: ANNOUNCEMENT_SET,
        data,
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get pinned annoucements which are set in AppSetting
 * @param refresh
 */
export const getPinAnnouncements = (
  refresh?: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getPinAnnouncements");

  try {
    const { announcement, setting } = getState();
    const cachedData = announcement.pins;

    if (!setting.setting) {
      return;
    }

    if (!refresh && cachedData) {
      return;
    }

    const pins = setting.setting.pinned_announcements;

    if (_.isEmpty(pins)) {
      dispatch({
        type: ANNOUNCEMENT_PIN_SET,
        data: {},
      });
      return;
    }

    const data = await _getAnnouncements({
      showComments: false,
      filterIds: setting.setting.pinned_announcements,
      mapByEvent: false,
    });

    dispatch({
      type: ANNOUNCEMENT_PIN_SET,
      data: data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get announcement types
 */
export const getAnnouncementTypes = (): AppThunkAction => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] getAnnouncementTypes");

  try {
    const data = await _getAnnouncementTypes();

    dispatch({
      type: ANNOUNCEMENT_TYPE_SET,
      data: data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get published announcements
 */
export const getPublishedAnnouncements = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getPublishedAnnouncements");

  try {
    const { announcement } = getState();
    let { page } = announcement.publishedList;
    const { items } = announcement.publishedList;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    }

    if (next) {
      page++;
    }

    let data = await _manageAnnouncements({
      per,
      page,
      isPublished: true,
    });

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: ANNOUNCEMENT_PUBLISHED_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get draft annoucements
 * @param next
 * @param refresh
 */
export const getDraftAnnouncements = (
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getDraftAnnouncements");

  try {
    const { announcement } = getState();
    let { page } = announcement.draftList;
    const { items } = announcement.draftList;

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    }

    if (refresh) {
      page = 0;
    }

    if (next) {
      page++;
    }

    let data = await _manageAnnouncements({
      per,
      page,
      isPublished: false,
    });

    const hasMore = Object.keys(data).length === per;

    data = {
      ...(refresh ? {} : items),
      ...data,
    };

    dispatch({
      type: ANNOUNCEMENT_DRAFT_ALL_SET,
      data: {
        items: data,
        page,
        hasMore,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Add/update announcement
 * @param data
 * @param photo
 * @param attachments
 * @param video
 */
export const upsertAnnouncement = (
  /**
   * @TODO Make sure what are real necessary data here
   * Argument typing based on code reading
   */
  data: {
    id?: string;
    replyTo: string | null;
    draft: string;
    scheduled: string;
    /**
     * @FIXME Temporary solution implemented.
     * Caused by not clear Date processing logic in containers/Announcement/Admin/NewEdit
     */
    publishOnDate?: Date | string | null;
    publishOnTime?: Date | string | null;
    notificationDeliveryMethods: string[];
  },
  photo: {} | null,
  attachments: {}[],
  video: {} | null
): AppThunkAction<CreateAnnouncementActionReturn> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<CreateAnnouncementActionReturn> => {
  console.debug("[Action] upsertEvent");

  try {
    const { setting, user } = getState();
    const { id } = data;
    const { sessionToken } = user.data as UserDataState;
    const config = {
      sessionToken,
      adminURL: setting.config ? setting.config.adminURL : undefined,
    };

    /**
     * @TODO Probably that should be redeclared as separate action creator
     */
    const progressCallback: ProgressCallback = (
      name,
      fileProgress,
      transferred,
      total,
      cancel
    ) => {
      dispatch({
        type: NOTIFICATION_UPLOAD_SET,
        data: { name, transferred, total, fileProgress, cancel },
      });
    };

    const commonServiceArgs = [
      data,
      photo,
      attachments,
      video,
      {
        ...config,
        ...setting.config,
      } as SettingConfigData,
      progressCallback,
    ] as const;

    if (!id) {
      await _addAnnouncement(...commonServiceArgs);
    } else {
      await _updateAnnouncement(id, ...commonServiceArgs);
    }

    return { data: true };
  } catch (err) {
    throw err && err.message;
  }
};

/**
 * Fetch announcement for editing
 * @param id
 */
export const manageAnnouncement = (
  id: string
): AppThunkAction<ManageAnnouncementActionReturn> => async (): Promise<
  ManageAnnouncementActionReturn
> => {
  console.debug("[Action] manageAnnouncements");

  try {
    const data = (await _getAnnouncement(id, {
      isEditing: true,
    })) as AnnouncementItem;

    return { data: data.announcement };
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const previewAnnouncement = (id: string): AppThunkAction => async (
  dispatch
): Promise<void> => {
  console.debug("[Action] previewAnnouncement", id);

  try {
    const item = (await _getAnnouncement(id, {
      isEditing: true,
    })) as AnnouncementItem;

    dispatch({
      type: ANNOUNCEMENT_DRAFT_SET,
      id,
      data: {
        [id]: item,
      },
    });
  } catch (err) {
    logException(err);
  }
};

/**
 * Remove announcement
 * @param id
 */
export const removeAnnouncement = (id: string): AppThunkAction => async (
  _dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] removeAnnouncement");

  try {
    const { setting, user } = getState();
    const { sessionToken } = user.data as UserDataState;

    await _removeAnnouncement(id, {
      sessionToken: sessionToken,
      adminURL: setting.config ? setting.config.adminURL : "",
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
