import React, { SyntheticEvent } from "react";

import { Media as MediaReactstrap, Button } from "reactstrap";

import { getFileInfoFromPath } from "../../../lib/utils";

export type MediaFileProps = {
  name?: string;
  url?: string;

  onRemove?: () => void;
};

export const MediaFile: React.FC<MediaFileProps> = ({
  name,
  url,
  onRemove,
}) => {
  const handleRemove = (e: SyntheticEvent): void => {
    e.preventDefault();

    onRemove?.();
  };

  const fileInfo = getFileInfoFromPath(url);

  return (
    <MediaReactstrap>
      <MediaReactstrap
        left
        className="mr-2"
        tag="a"
        rel="noopener noreferrer"
        href={fileInfo.url}
        target="_blank">
        <i className="icon-doc" />
      </MediaReactstrap>
      <MediaReactstrap
        body
        tag="a"
        rel="noopener noreferrer"
        href={fileInfo.url}
        target="_blank">
        {name || fileInfo.name}
      </MediaReactstrap>
      {!!onRemove && (
        <MediaReactstrap right>
          <Button
            color="link"
            className="text-danger p-0"
            onClick={handleRemove}>
            <i className="icon-ban" />
          </Button>
        </MediaReactstrap>
      )}
    </MediaReactstrap>
  );
};
