import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { has, isEmpty, isString } from "underscore";

import { cleanHTMLTags, fromNow } from "../../../lib/utils";

import { NotificationItem, SettingState } from "../../../types";

import { useTheme } from "../../hooks";

import { Avatar } from "..";

export type NotificationCardProps = {
  item: NotificationItem;
  setting: SettingState | {};
};

export const NotificationCard: React.FC<NotificationCardProps> = ({ item }) => {
  const { title, shortText, text, sendBy, sendAt, options } = item;

  const { profile, firstName, lastName } = sendBy || {};
  const name = lastName ? `${firstName} ${lastName}` : "System";
  const { thumbUrl } = profile || {};
  const postCreatedAt = fromNow(sendAt);

  const history = useHistory();
  const { t } = useTranslation("Notification");
  const {
    list: { backgroundColor },
  } = useTheme();
  const styles = {
    listItem: { backgroundColor },
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = (): void => {
    setModalOpen(!isModalOpen);
  };

  const authorElement = profile && (
    <Row key={name} className="px-4 py-2">
      <Avatar
        size="35"
        name={name}
        src={thumbUrl}
        id={sendBy?.id || sendBy?.objectId}
      />
      <Col className="my-0">
        <p>
          {name}
          <p className="text-right small mt-n4">{postCreatedAt}</p>
        </p>
        <p className="text-left small mt-n3 m-0">{title}</p>
      </Col>
    </Row>
  );

  const bodyElement = item && (
    <div className="px-3 py-0">
      <p className={"text-truncate m-0"}>{cleanHTMLTags(shortText || text)}</p>
    </div>
  );
  let href: string;
  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    history.push(href);
  };

  let notificationType: JSX.Element = <></>;

  const renderNotificationType = (href: string, title: string): void => {
    notificationType = (
      <a
        id={`notificationTypeButton${title.replace(/\s/g, "") +
          (sendBy?.id || sendBy?.objectId)}`}
        onClick={handleClick}
        className={"px-3 pt-3 anchor-color float-right"}
        href={href}>
        {title}
      </a>
    );
  };

  let hrefText: string;

  /**@TODO add modal for unpaid invoice and welcome new member*/
  if (isEmpty(options)) {
    notificationType = (
      <>
        <span
          className={"pointer px-3 py-0 anchor-color"}
          onClick={toggleModal}>
          {t("NotificationCard.Button.View")}
        </span>
        <Modal isOpen={isModalOpen} toggle={toggleModal} centered={true}>
          <ModalBody>
            <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
            <ModalBody>
              <p>{cleanHTMLTags(shortText || text)}</p>
            </ModalBody>
          </ModalBody>
        </Modal>
      </>
    );
  } else if (has(options, "invoice_id")) {
    href = `/payment-receipt-detail/${options?.invoice_id}`;
    hrefText = t("NotificationCard.Button.View.Invoice");
    renderNotificationType(href, hrefText);
  } else if (has(options, "profile_id")) {
    href = `/member/${sendBy?.objectId}`;
    hrefText = t("NotificationCard.Button.View.Profile");
    renderNotificationType(href, hrefText);
  } else if (has(options, "post")) {
    href = `/group-feed-detail/${options?.post}`;
    hrefText = t("NotificationCard.Button.View.Post");
    renderNotificationType(href, hrefText);
  } else if (has(options, "post_id")) {
    href = `/group-feed-detail/${options?.post_id}`;
    hrefText = t("NotificationCard.Button.View.Post");
    renderNotificationType(href, hrefText);
  } else if (has(options, "announcement")) {
    href = `/announcement/${options?.announcement}`;
    hrefText = t("NotificationCard.Button.View.Announcement");
    renderNotificationType(href, hrefText);
  } else if (has(options, "announcement_id")) {
    href = `/announcement/${options?.announcement_id}`;
    hrefText = t("NotificationCard.Button.View.Announcement");
    renderNotificationType(href, hrefText);
  } else if (has(options, "event")) {
    href = `/event-detail/${options?.event}`;
    hrefText = t("NotificationCard.Button.View.Event");
    renderNotificationType(href, hrefText);
  } else if (has(options, "event_id")) {
    href = `/event-detail/${options?.event_id}`;
    hrefText = t("NotificationCard.Button.View.Event");
    renderNotificationType(href, hrefText);
  } else if (has(options, "url") && isString(options?.url)) {
    /**@TODO How to test it? I did't find this case.*/
    href = `${options?.url}`;
    hrefText = t("NotificationCard.Button.View.Url");
    renderNotificationType(href, hrefText);
  } else if (has(options, "url") && !isEmpty(options?.link)) {
    href = `${options?.link}`;
    hrefText = t("NotificationCard.Button.View.Link");
    renderNotificationType(href, hrefText);
  }

  return (
    <Card
      id={`notificationCard${item.id}`}
      className="mb-3 pt-2 pb-2"
      style={styles.listItem}>
      <CardBody className={"p-0"}>
        {authorElement}
        {bodyElement}
        {notificationType}
      </CardBody>
    </Card>
  );
};

export default NotificationCard;
