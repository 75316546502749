import { AnnouncementItem } from "../../../types";
import { getItemWithCommentRemoved } from "../Comment";
import { getArrayWithDeletedValue } from "../store";

export function getAnnouncementItemWithCommentRemoved({
  commentId,
  item,
}: {
  commentId: string;
  item: AnnouncementItem;
}): AnnouncementItem;
export function getAnnouncementItemWithCommentRemoved({
  commentId,
  item,
}: {
  commentId: string;
  item: undefined;
}): undefined;
export function getAnnouncementItemWithCommentRemoved<
  T extends undefined | AnnouncementItem
>({ commentId, item }: { commentId: string; item?: T }): T;
export function getAnnouncementItemWithCommentRemoved({
  commentId,
  item,
}: {
  commentId: any;
  item?: any;
}): any {
  if (!item) return undefined;

  return item.announcement.event
    ? {
        ...item,
        comments: getArrayWithDeletedValue(commentId, item.comments),
        announcement: {
          ...item.announcement,
          event: {
            ...item.announcement.event,
            commentCount: item.announcement.event.commentCount - 1,
          },
        },
      }
    : getItemWithCommentRemoved({
        item: item,
        commentId: commentId,
        dataKeyName: "announcement",
      });
}
