import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Button,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Row,
} from "reactstrap";

import { AppIntro } from "../../../types";

export type OnboardingProps = {
  data: AppIntro[];
  imgList: AppIntro[];
};

export const Onboarding: React.FC<OnboardingProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const history = useHistory();

  const { t } = useTranslation("Onboarding");
  const items = data || [];
  const lastIndex = items.length - 1;

  const nextSlide = (): void => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previousSlide = (): void => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number): void => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={(): void => setAnimating(true)}
        onExited={(): void => setAnimating(false)}
        key={item.image}>
        <img style={{ height: "45rem" }} src={item.image} alt={item.text} />?
        <Row style={{ marginTop: "12rem", fontSize: "25px" }}>
          <CarouselCaption captionText={item.text} captionHeader={item.title} />
        </Row>
      </CarouselItem>
    );
  });

  return (
    <Container
      fluid={true}
      className="h-100 position-fixed bg-info"
      style={{ marginTop: "-56px" }}>
      <Row>
        <Col className="col-11 my-4 mx-5 text-right">
          <Button
            id="onboardingSkipButton"
            className="btn btn-warning btn-lg"
            onClick={(): void => history.push("/")}>
            {t("Index.Skip.Button")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Carousel
            className="text-center mx-auto"
            interval={false}
            activeIndex={activeIndex}
            next={nextSlide}
            previous={previousSlide}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previousSlide}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={nextSlide}
            />
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {activeIndex === lastIndex && (
            <Button
              id="onboardingGetStartedButton"
              className="mt-2 btn btn-success btn-lg "
              onClick={(): void => history.push("/")}>
              {t("Slide.GetStarted.Button")}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};
