import React from "react";

import { RouteComponentProps } from "react-router-dom";

import AnnouncementListContainer from "../../../containers/Announcement";

import { AnnouncementList } from "../../components/Announcement";

export type RouteAnnouncementListProps = RouteComponentProps;

export const RouteAnnouncementList: React.FC<RouteAnnouncementListProps> = props => (
  <AnnouncementListContainer {...props} Layout={AnnouncementList} />
);
