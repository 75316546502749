/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-ignore
import MdCube from "react-ionicons/lib/MdCube";
// @ts-ignore
import MdHome from "react-ionicons/lib/MdHome";
// @ts-ignore
import MdInformationCircle from "react-ionicons/lib/MdInformationCircle";
// @ts-ignore
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
// @ts-ignore
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
// @ts-ignore
import IosLink from "react-ionicons/lib/IosLink";

import { IconDict, Dict } from "../../../../types";

export const iconIoniconsDict: Dict<
  React.ComponentType,
  IconDict["Ionicons"]
> = {
  home: MdHome,
  "ios-arrow-down": IosArrowDown,
  "ios-arrow-forward": IosArrowForward,
  "ios-link": IosLink,
  "md-cube": MdCube,
  "md-information-circle": MdInformationCircle,
} as const;
