import React from "react";

import { CardBody } from "reactstrap";

import { MediaImageBasic } from "../../../types";

import { Media } from "../Media";
import { LoadingSpinner, Empty } from "../UI";

export type EventDetailTabsPhotosProps = {
  data?: MediaImageBasic[];
};

/** @FIXME Should I implement refresh handling? */
export const EventDetailTabsPhotos: React.FC<EventDetailTabsPhotosProps> = ({
  data,
}) => {
  const handleClick = (image: MediaImageBasic) => (): void => {
    /** @TODO Implement full screen gallery. ImageGrid is using LightBox */
    // Actions.viewPhotos({
    //   match: {
    //     params: { photos: [{ uri: image.thumbUrl, imageUrl: image.fileUrl }] },
    //   },
    // });
  };

  const tabContent = !data ? (
    <LoadingSpinner />
  ) : data.length === 0 ? (
    <Empty />
  ) : (
    data.map((image, index) => (
      <Media
        key={index}
        image={image}
        resizeMode="cover"
        onClick={handleClick(image)}
      />
    ))
  );

  return <CardBody className="p-3">{tabContent}</CardBody>;
};
