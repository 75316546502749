import { PostItem } from "../../../types/Post/PostItem";
import { Post } from "../../../types";
import { parseMentions } from ".";
export function parseMentionsInPostItem(convertedObject: any): PostItem {
  const post = convertedObject && convertedObject.post;

  const convertedObjectWithMentions = Object.assign({}, convertedObject, {
    post: parseMentions<Post>(post),
  });
  return convertedObjectWithMentions;
}
