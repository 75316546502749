import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { FacebookSelector } from "react-reactions";
import { Button, Col, Row } from "reactstrap";

import {
  reactionColorsAvailable,
  reactionImages,
  textThemingWorkaround,
} from "../../../constants";
import { ReactionType } from "../../../types";

import { useTheme } from "../../hooks";

export type CommentActionBarProps = {
  disabled?: boolean;
  id?: string;
  isLiked: boolean;
  reaction?: ReactionType;

  onCommentsButtonClick: () => void;
  react: (reaction: ReactionType) => Promise<void> | undefined;
};

export const CommentActionBar: FC<CommentActionBarProps> = ({
  disabled,
  id,
  isLiked,
  react,

  onCommentsButtonClick,
  reaction,
}) => {
  const { t } = useTranslation("Comment");

  const [isReactionSelectorVisible, setIsReactionSelectorVisible] = useState(
    false
  );

  const {
    comment: {
      likeButton: { text: likeButtonTextStyles, ...likeButtonStyles },
      commentButton: { text: commentButtonTextStyles, ...commentButtonStyles },
    },
  } = useTheme();

  const styles = {
    commentButton: {
      ...commentButtonStyles,
      flex: 0.5,
      marginLeft: 1,
      justifyContent: "center",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
    commentButtonSpan: {
      ...commentButtonTextStyles,
      ...textThemingWorkaround,
    },
    likeButton: {
      ...likeButtonStyles,
      flex: 0.5,
      marginRight: 1,
      justifyContent: "center",
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    likeButtonSpan: {
      ...likeButtonTextStyles,
      ...textThemingWorkaround,
      textTransform: "capitalize",
      color:
        (reaction && reactionColorsAvailable[reaction]) ||
        likeButtonTextStyles.color,
    },
  } as const;

  const addReaction = (reaction: string): void => {
    react(reaction as ReactionType)?.then(() =>
      setIsReactionSelectorVisible(false)
    );
  };

  //@TODO in order to add more of a popup with reactions just above like button
  // we need to count position and for that we need to know what is the height
  // of announcement pin or post card. There is a way to check this as shown below
  // (lines 40 - 43) but we need more unique approach for adding ids
  // announcementHeaderTextVideoannouncement is not unique so it won't work for every case
  //
  //
  // const relativeReactionsPosition =
  //   (document.getElementById("announcementHeaderTextVideoannouncement")
  //     ?.clientHeight ?? 0) * 0.8;
  const handleMouseEnter = (): void => {
    setTimeout(() => {
      setIsReactionSelectorVisible(true);
    }, 500);
  };

  const reactionSelector = isReactionSelectorVisible && (
    <span id={`reactionsBar${id}`} className={"reactions-content"}>
      <FacebookSelector onSelect={addReaction} />
    </span>
  );

  const handleLikeButtonClick = (): void => {
    setIsReactionSelectorVisible(false);
    if (isLiked) {
      react("unlike");
    } else {
      addReaction("like");
    }
  };

  const likeText =
    isLiked && reaction === "like"
      ? t("Bar.Button.Liked")
      : reaction === "unlike" || !reaction
      ? t("Bar.Button.Like")
      : reaction;

  const likeButton = (
    <Button
      id={`commentAddReactionButton${isLiked ? "Liked" : "Like"}${id}`}
      onMouseEnter={handleMouseEnter}
      color="light"
      disabled={disabled}
      block
      onClick={handleLikeButtonClick}
      style={styles.likeButton}>
      {reaction && reaction !== "unlike" ? (
        <img
          className="mr-4 px-2 rounded"
          height="20"
          src={reactionImages.image[reaction]}
          alt={reaction}
        />
      ) : (
        <i className="icon-like mr-4" />
      )}
      <span style={styles.likeButtonSpan}>{likeText}</span>
    </Button>
  );

  return (
    <Row noGutters={true} className="pb-2">
      <Col className={"reactions-button ml-2"}>
        {reactionSelector}
        {likeButton}
      </Col>
      <Col className="mr-2">
        <Button
          id={`commentListButton${id}`}
          color="light"
          block
          disabled={disabled}
          onClick={onCommentsButtonClick}
          style={styles.commentButton}>
          <i className="icon-speech mr-4" />
          <span style={styles.commentButtonSpan}>{t("Bar.Hint.Comment")}</span>
        </Button>
      </Col>
    </Row>
  );
};
