import { createSelector } from "reselect";
import {
  RootState,
  Member,
  Dict,
  SettingConfigData,
  Group,
  SettingConfigGroupType,
} from "../../types";
import { getDictDefinedValues } from "../../lib/utils/store";
import { PreLoadingMembersStatus } from "./types";
import { getSettingState } from "../Setting/selector";
import { isEmpty, sortBy } from "underscore";

// selector

const getMainGroupType = (state: RootState): SettingConfigGroupType | null => {
  return !isEmpty(state.setting.config?.blenderboxFilterGroups)
    ? state.setting.config?.blenderboxFilterGroups[0] || null
    : null;
};

const getHomeGroupType = (state: RootState): string | null => {
  return state.setting.setting?.home_group_type || null;
};

const getMainGroupData = (state: RootState): Group[] => {
  const mainGroupType = getMainGroupType(state);

  return (
    (mainGroupType?.groupType &&
      state.group.groupsByType?.[mainGroupType?.groupType]) ||
    []
  );
};

const getHomeGroupData = (state: RootState): Group[] | null => {
  const homeGroupType = getHomeGroupType(state);
  return homeGroupType ? state.group.groupsByType?.[homeGroupType] : null;
};

const getMembers = (state: RootState): Dict<Member> | null =>
  state.member.items;

const getPreLoadingStatus = (state: RootState): PreLoadingMembersStatus =>
  state.member.preLoadingStatus;

const getGroupMembersData = (
  state: RootState,
  groupId: string
): Dict<Member> | null => state.member.groups?.[groupId] || null;

const showpMembersPlaceholder = (state: RootState): boolean =>
  !state.member.items ? true : false;

const showGroupMembersPlaceholder = (
  state: RootState,
  groupId: string
): boolean => (!state.member.groups?.[groupId] ? true : false);

// reselect function

export const getMembersState = createSelector(
  [getMembers],
  items => getDictDefinedValues(items) || []
);

export const showpMembersPlaceholderState = createSelector(
  [showpMembersPlaceholder],
  show => show
);
export const showGroupMembersPlaceholderState = createSelector(
  [showGroupMembersPlaceholder],
  show => show
);

export const getGroupMembersDataState = createSelector(
  [getGroupMembersData],
  items => {
    const data = getDictDefinedValues(items || {})?.filter(
      member => member.status === "active"
    );
    return sortBy(data, "lastName");
  }
);

export const isLoadAllMembersEnabled = createSelector(
  [getPreLoadingStatus, getSettingState],
  (preLoadingStatus, setting) =>
    (preLoadingStatus === "loaded" && setting.config?.preLoadingListMembers) ||
    false
);

export const getMembersFilterSettingState = createSelector(
  [
    getSettingState,
    getMainGroupType,
    getHomeGroupType,
    getMainGroupData,
    getHomeGroupData,
  ],
  (setting, mainGroupType, homeGroupType, mainGroupData, homeGroupData) => {
    const {
      blenderboxImprovements,
      blenderboxFilterByHomeGroupEnabled,
      followingEnabled,
    } = setting.config as SettingConfigData;

    const blenderBoxEnabled = blenderboxImprovements;

    return {
      mainGroupType,
      mainGroupData,
      homeGroupEnable: blenderboxFilterByHomeGroupEnabled,
      homeGroupType,
      homeGroupData,
      followingEnabled: blenderBoxEnabled ? true : followingEnabled,
      blenderBoxEnabled,
    };
  }
);
