import React, { SyntheticEvent, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { UserState } from "../../../containers/User/types";

import { UserLoginStateData } from "../../../types";

import { useHistoryGoBackHandler } from "../../hooks";

import { LoadingSpinner } from "../UI";

export type ChangePasswordProps = {
  data: UserLoginStateData;
  error: string | null | undefined;
  loading: boolean;
  resetSetting?: (() => Promise<unknown>) | false;
  user: UserState | {};
  setting: {
    organizationName: string;
    logo?: string;
    backgroundImage?: string;
    signInByEmail?: boolean | undefined;
    signUpEnabled?: boolean | undefined;
    InMultiTenant?: boolean | undefined;
  };

  handleChange: <K extends keyof UserLoginStateData>(
    name: K,
    val: UserLoginStateData[K]
  ) => void;
  logIn: (callback?: () => void) => Promise<void>;
  resetPassCode: (callback?: (text: string) => void) => boolean | void;
  resetPassword: (callback?: (text: string) => void) => boolean | void;
  updatePassword: (callback?: (text: string) => void) => boolean | void;
};

export const UserChangePassword: React.FC<ChangePasswordProps> = ({
  data,
  error,
  loading,
  setting,

  handleChange,
  updatePassword,
}) => {
  const [successMessage, setSuccessMessage] = useState<string>();

  const { t } = useTranslation("User");

  const handleHistoryGoBack = useHistoryGoBackHandler();

  if (loading) {
    return <LoadingSpinner />;
  }

  const { currentPassword, password, confirmPassword } = data;

  const { signInByEmail } = setting;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    handleChange(e.target.name as keyof UserLoginStateData, e.target.value);

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
    updatePassword((message: string) => setSuccessMessage(message));

    //     text => {
    //   Toast.show({
    //     text: text,
    //     duration: 3000,
    //     type: "success",
    //     buttonText: this.props.t("Change.Password.Button.Ok"),
    //     onClose: () => {
    //       Actions.pop();
    //     },
    //   });
    // }
  };

  const alertElement = error ? (
    <Alert color="danger">{error}</Alert>
  ) : (
    successMessage && <Alert color="success">{successMessage}</Alert>
  );

  return (
    <Card className="mt-3 pt-2">
      <CardBody>
        {alertElement}
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>
              {signInByEmail
                ? t("Change.Password.Current")
                : t("Change.Password.Passcode.Current")}
            </Label>
            <Input
              id="currentPasswordInput"
              type="password"
              name="currentPassword"
              onChange={handleInputChange}
              value={currentPassword}
              disabled={loading}
              maxLength={signInByEmail ? 50 : 4}
            />
          </FormGroup>

          <FormGroup>
            <Label>
              {signInByEmail
                ? t("Change.Password.New")
                : t("Change.Password.Passcode.New")}
            </Label>
            <Input
              id="newPasswordInput"
              type="password"
              name="password"
              onChange={handleInputChange}
              value={password}
              disabled={loading}
              maxLength={signInByEmail ? 50 : 4}
            />
          </FormGroup>

          <FormGroup>
            <Label>
              {signInByEmail
                ? t("Change.Password.Confirm")
                : t("Change.Password.Passcode.Confirm")}
            </Label>
            <Input
              id="repeatNewPasswordInput"
              type="password"
              name="confirmPassword"
              onChange={handleInputChange}
              value={confirmPassword}
              disabled={loading}
              maxLength={signInByEmail ? 50 : 4}
            />
          </FormGroup>
          <Button
            id="changePasswordBtn"
            className="mt-2 float-right"
            color="primary"
            disabled={loading}>
            {loading
              ? t("Change.Password.Loading")
              : t("Change.Password.Submit")}
          </Button>
          <Button
            id="cancelChangePasswordBtn"
            className="mt-2 mr-3 float-right"
            color="secondary"
            onClick={handleHistoryGoBack}>
            {t("Change.Password.Cancel")}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};
