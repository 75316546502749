import {
  getItemWithCommentRemoved,
  getItemWithCommentUpdated,
  getItemWithCommentsSet,
} from "../../lib/utils/Comment";
import { getNextReactedItem } from "../../lib/utils/Reaction";
import {
  getFilteredItemList,
  getFilteredNestedItemList,
  getInsertedNestedItemListIfExists,
  getUpsertedItemList,
  getUpsertedNestedItemList,
} from "../../lib/utils/store";

import { GlobalAction } from "../../types";

import { COMMENT_DELETE, COMMENT_UPDATE } from "../Comment/constants";

import {
  POST_ALL_SET,
  POST_COMMENT_SET,
  POST_DRAFT_CLEAR,
  POST_DRAFT_SET,
  POST_FILES_SET,
  POST_GROUP_SET,
  POST_IMAGES_SET,
  POST_REACTION_SET,
  POST_REMOVE,
  POST_RESET,
  POST_SEARCH_HISTORY_SET,
  POST_SEARCH_SET,
  POST_SET,
  POST_USER_SET,
} from "./constants";
import Store from "./store";
import { PostState } from "./types";

export const initialState = Store;

export default function reducer(
  state: PostState = initialState,
  action: GlobalAction
): PostState {
  switch (action.type) {
    /** Resetting initial state */
    case POST_RESET:
      return initialState;

    /** Setting all groups posts list */
    case POST_ALL_SET:
      return {
        ...state,
        mixedList: {
          ...state.mixedList,
          ...action.data,
        },
      };

    /** Setting search posts list */
    case POST_SEARCH_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        searchList: action.data,
      };

    /** Setting search history  */
    case POST_SEARCH_HISTORY_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        history: {
          /**
           * Why is it stored as Dict? Why not array? Is following
           * boolean value used anywhere?
           */
          ...state.history,
          [action.data]: true,
        },
      };

    /**
     * Setting group's posts list.
     *
     * Nested list grouped by group id.
     */
    case POST_GROUP_SET:
      if (action.data) {
        const { groupId, ...newList } = action.data;

        if (groupId) {
          return {
            ...state,
            groupsList: {
              ...state.groupsList,
              [groupId]: newList,
            },
          };
        }
      }

      return state;

    /**
     * Setting user's posts list.
     *
     * Nested list grouped by author's id.
     */
    case POST_USER_SET:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          [action.data.userId]: {
            hasMore: action.data.hasMore,
            items: action.data.items,
            page: action.data.page,
          },
        },
      };

    /** Setting single post */
    case POST_SET:
      if (action.data) {
        const {
          id,
          group,
          author: { id: authorId },
        } = action.data.post;

        return {
          ...state,
          mixedList: {
            ...state.mixedList,
            items: state.mixedList.items
              ? {
                  ...state.mixedList.items,
                  [id]: action.data,
                }
              : null,
            cached: {
              ...state.mixedList.cached,
              [id]: action.data,
            },
          },
          groupsList: getInsertedNestedItemListIfExists({
            dataKeyName: "post",
            newItem: action.data,
            groupedByValue: group.objectId,
            nestedItemList: state.groupsList,
          }),
          usersList: getInsertedNestedItemListIfExists({
            dataKeyName: "post",
            newItem: action.data,
            groupedByValue: authorId,
            nestedItemList: state.usersList,
          }),
        };
      }

      return state;

    /**
     * Removing post
     */
    case POST_REMOVE:
      if (action.data) {
        const { id, groupId } = action.data;

        /**
         * There is no guarantee that post exists in mixedList so I have to check both
         */
        const authorId =
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          state.mixedList.items?.[id]?.post.author.objectId ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          state.groupsList[groupId].items?.[id]?.post.author.objectId;

        return {
          ...state,
          mixedList: getFilteredItemList({
            dataKeyName: "post",
            removeId: id,
            itemList: state.mixedList,
          }),
          groupsList: getFilteredNestedItemList({
            dataKeyName: "post",
            removeId: id,
            groupedByValue: groupId,
            nestedItemList: state.groupsList,
          }),
          usersList: getFilteredNestedItemList({
            dataKeyName: "post",
            removeId: id,
            groupedByValue: authorId,
            nestedItemList: state.usersList,
          }),
        };
      }

      return state;

    case POST_COMMENT_SET:
      //API should return groupId for if it's post's comments
      // const { id, groupId, items, postAuthorId } = ;

      return {
        ...state,
        mixedList: getUpsertedItemList({
          dataKeyName: "post",
          newItem: getItemWithCommentsSet({
            comments: action.data.items,
            dataKeyName: "post",
            item: state.mixedList.items?.[action.data.id],
          }),
          newItemCached: getItemWithCommentsSet({
            comments: action.data.items,
            dataKeyName: "post",
            item: state.mixedList.cached?.[action.data.id],
          }),
          itemList: state.mixedList,
        }),
        groupsList: action.data.groupId
          ? getUpsertedNestedItemList({
              newItem: getItemWithCommentsSet({
                comments: action.data.items,
                dataKeyName: "post",
                item:
                  state.groupsList[action.data.groupId]?.items?.[
                    action.data.id
                  ] || undefined,
              }),
              groupedByValue: action.data.groupId,
              dataKeyName: "post",
              nestedItemList: state.groupsList,
            })
          : state.groupsList,
        usersList: action.data.postAuthorId
          ? getUpsertedNestedItemList({
              newItem: getItemWithCommentsSet({
                comments: action.data.items,
                dataKeyName: "post",
                item:
                  state.usersList[action.data.postAuthorId]?.items?.[
                    action.data.id
                  ],
              }),
              groupedByValue: action.data.postAuthorId,
              dataKeyName: "post",
              nestedItemList: state.usersList,
            })
          : state.usersList,
      };

    case POST_REACTION_SET:
      return {
        ...state,
        mixedList: getUpsertedItemList({
          dataKeyName: "post",
          newItem: getNextReactedItem({
            item: state.mixedList.items?.[action.data.id],
            newReaction: action.data.reaction,
            dataKeyName: "post",
          }),
          newItemCached: getNextReactedItem({
            item: state.mixedList.cached?.[action.data.id],
            newReaction: action.data.reaction,
            dataKeyName: "post",
          }),
          itemList: state.mixedList,
        }),
        groupsList: action.data.groupId
          ? getUpsertedNestedItemList({
              dataKeyName: "post",
              newItem: getNextReactedItem({
                item:
                  state.groupsList[action.data.groupId]?.items?.[
                    action.data.id
                  ] || undefined,
                newReaction: action.data.reaction,
                dataKeyName: "post",
              }),
              groupedByValue: action.data.groupId,
              nestedItemList: state.groupsList,
            })
          : state.groupsList,
        usersList: action.data.authorId
          ? getUpsertedNestedItemList({
              dataKeyName: "post",
              newItem: getNextReactedItem({
                item:
                  state.usersList[action.data.authorId]?.items?.[
                    action.data.id
                  ] || undefined,
                newReaction: action.data.reaction,
                dataKeyName: "post",
              }),
              groupedByValue: action.data.authorId,
              nestedItemList: state.usersList,
            })
          : state.usersList,
      };

    case POST_DRAFT_SET:
      if (action.data) {
        return {
          ...state,
          item: action.data,
        };
      }

      return state;

    case POST_DRAFT_CLEAR:
      return {
        ...state,
        item: null,
      };

    case POST_IMAGES_SET:
      if (action.data) {
        const { groupId, ...newList } = action.data;

        if (groupId) {
          return {
            ...state,
            imagesList: {
              ...state.imagesList,
              [groupId]: newList,
            },
          };
        }
      }

      return state;

    case POST_FILES_SET:
      if (action.data) {
        const { groupId, ...newList } = action.data;

        if (groupId) {
          return {
            ...state,
            filesList: {
              ...state.filesList,
              [groupId]: newList,
            },
          };
        }
      }

      return state;

    /** Comments */

    case COMMENT_UPDATE:
      //API should return groupId for if it's post's comments
      if (action.data.type === "post") {
        return {
          ...state,
          mixedList:
            state.mixedList.items?.[action.data.id] ||
            state.mixedList.cached?.[action.data.id]
              ? getUpsertedItemList({
                  dataKeyName: "post",
                  newItem: getItemWithCommentUpdated({
                    comment: action.data.data,
                    item: state.mixedList.items?.[action.data.id],
                  }),
                  newItemCached: getItemWithCommentUpdated({
                    comment: action.data.data,
                    item: state.mixedList.cached?.[action.data.id],
                  }),
                  itemList: state.mixedList,
                })
              : state.mixedList,
          groupsList: action.data.groupId
            ? getUpsertedNestedItemList({
                newItem: getItemWithCommentUpdated({
                  comment: action.data.data,
                  item:
                    state.groupsList[action.data.groupId]?.items?.[
                      action.data.id
                    ],
                }),
                groupedByValue: action.data.groupId,
                dataKeyName: "post",
                nestedItemList: state.groupsList,
              })
            : state.groupsList,
          usersList: action.data.postAuthorId
            ? getUpsertedNestedItemList({
                newItem: getItemWithCommentUpdated({
                  item:
                    state.usersList[action.data.postAuthorId]?.items?.[
                      action.data.id
                    ],
                  comment: action.data.data,
                }),
                groupedByValue: action.data.postAuthorId,
                dataKeyName: "post",
                nestedItemList: state.usersList,
              })
            : state.usersList,
        };
      }

      return state;

    case COMMENT_DELETE:
      if (action.data.type === "post") {
        //API should return groupId for if it's post's comments

        return {
          ...state,
          mixedList: getUpsertedItemList({
            dataKeyName: "post",
            newItem: getItemWithCommentRemoved({
              item: state.mixedList.items?.[action.data.id],
              commentId: action.data.id,
              dataKeyName: "post",
            }),
            newItemCached: getItemWithCommentRemoved({
              item: state.mixedList.cached?.[action.data.id],
              commentId: action.data.id,
              dataKeyName: "post",
            }),
            itemList: state.mixedList,
          }),
          groupsList: action.data.groupId
            ? getUpsertedNestedItemList({
                newItem: getItemWithCommentRemoved({
                  item:
                    state.groupsList[action.data.groupId]?.items?.[
                      action.data.id
                    ],
                  commentId: action.data.id,
                  dataKeyName: "post",
                }),
                groupedByValue: action.data.groupId,
                dataKeyName: "post",
                nestedItemList: state.groupsList,
              })
            : state.groupsList,
          usersList: action.data.postAuthorId
            ? getUpsertedNestedItemList({
                newItem: getItemWithCommentRemoved({
                  item:
                    state.usersList[action.data.postAuthorId]?.items?.[
                      action.data.id
                    ],
                  commentId: action.data.id,
                  dataKeyName: "post",
                }),
                groupedByValue: action.data.postAuthorId,
                dataKeyName: "post",
                nestedItemList: state.usersList,
              })
            : state.usersList,
        };
      }

      return state;

    default:
      return state;
  }
}
