import React from "react";

import { useTranslation } from "react-i18next";
import { Button, Card, CardBody } from "reactstrap";

import { Callback } from "../../../../types";
import { textThemingWorkaround } from "../../../../constants";

import { useTheme } from "../../../hooks";

type GroupDetailInfoProps = {
  description?: string;
  isGroupOpen: boolean;
  isJoined: boolean;
  name: string;

  onGroupFilesButtonClick: Callback;
  onGroupPhotosButtonClick: Callback;
  onJoinGroupButtonClick: Callback;
  onLeaveGroupButtonClick: Callback;
};

export const GroupDetailInfo: React.FC<GroupDetailInfoProps> = ({
  description,
  isGroupOpen,
  isJoined,
  name,

  onGroupFilesButtonClick,
  onGroupPhotosButtonClick,
  onJoinGroupButtonClick,
  onLeaveGroupButtonClick,
}) => {
  const { t } = useTranslation("Group");
  const {
    text: { cardText },
    button: {
      backgroundColor: buttonBackgroundColor,
      text: { color: buttonColor },
    },
  } = useTheme();
  const styles = {
    description: {
      ...cardText,
      ...textThemingWorkaround,
      whiteSpace: "pre-wrap" as "pre-wrap",
    },
    buttonPhoto: {
      borderColor: buttonBackgroundColor,
      backgroundColor: buttonBackgroundColor,
      color: buttonColor,
    },
    buttonJoin: {
      backgroundColor: buttonBackgroundColor,
      color: buttonColor,
      border: 0,
    },
  };

  const readMoreElement = description && (
    <p className="my-2 small mb-4" style={styles.description}>
      {description}
    </p>
  );

  const buttonElements = (
    <>
      {/** @TODO Reconsider to pass href and component="a" */}
      {(isJoined || isGroupOpen) && (
        <>
          <Button
            id="detailButtonGroupPhotos"
            block={true}
            color="primary"
            style={styles.buttonPhoto}
            outline
            onClick={onGroupPhotosButtonClick}>
            {t("Detail.Button.Group.Photos")}
          </Button>
          <Button
            id="detailButtonGroupFiles"
            block={true}
            color="primary"
            style={styles.buttonPhoto}
            outline
            onClick={onGroupFilesButtonClick}>
            {t("Detail.Button.Group.Files")}
          </Button>
        </>
      )}
      {!isJoined && (
        <Button
          id="detailButtonGroupJoin"
          block={true}
          color="primary"
          style={styles.buttonJoin}
          onClick={onJoinGroupButtonClick}>
          {t("Detail.Button.Join", { name })}
        </Button>
      )}
      {isJoined && (
        <Button
          id="detailButtonGroupLeave"
          block={true}
          color="danger"
          onClick={onLeaveGroupButtonClick}>
          {t("Detail.Button.Leave", { name })}
        </Button>
      )}
    </>
  );

  return (
    <Card>
      <CardBody>
        <h5>{t("GroupDetail.Tab.Info")}</h5>
        {readMoreElement}
        {buttonElements}
      </CardBody>
    </Card>
  );
};
