import React from "react";

import { PostFilePlaceholder } from ".";

export type PostFilesPlaceholderProps = {};

export const PostFilesPlaceholder: React.FC<PostFilesPlaceholderProps> = () => (
  <>
    <PostFilePlaceholder />
    <PostFilePlaceholder />
    <PostFilePlaceholder />
  </>
);
