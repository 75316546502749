import React, { useState, useEffect } from "react";

import Cards, { CallbackArgument, Focused } from "react-credit-cards";
import "react-credit-cards/lib/styles.scss";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { track } from "../../../../lib/track";
import { logException } from "../../../../lib/utils";
import { Callback, SettingSettingData } from "../../../../types";

import { PayExpirationDateValidator } from "./PayExpirationDateValidator";
import { PayCvcValidator } from "./PayCvcValidator";
import Stripe from "../../../../services/api/Stripe";

type PayProps = {
  setting: SettingSettingData;
  isPayModalOpen: boolean;
  togglePayModal: Callback;
  onProcess: (id: string) => void;
};

type HTMLCCInputElement = HTMLInputElement & { name: Focused };

export const CreatePaymentMethod: React.FC<PayProps> = ({
  isPayModalOpen,
  setting,
  onProcess,
  togglePayModal,
}) => {
  const { t } = useTranslation("Payment");

  const [focused, setFocused] = useState<Focused>("number");

  const [expiry, setExpiry] = useState("");

  const [isExpiryValid, setExpiryValid] = useState(false);

  const [cvc, setCvc] = useState("");

  const [isCvc, setCvcValid] = useState(false);

  const [sending, setSending] = useState(false);

  const [cardNumber, setCardNumber] = useState("");

  const [isCardNumberValid, setCardNumberValid] = useState(false);

  useEffect(() => {
    if (expiry.match(/^[2-9]$/)) {
      setExpiry(`0${expiry}`);
    }

    if (PayExpirationDateValidator(expiry)) {
      setExpiryValid(true);
    } else {
      setExpiryValid(false);
    }

    if (PayCvcValidator(cvc, [3, 4])) {
      setCvcValid(true);
    } else {
      setCvcValid(false);
    }
  }, [expiry, cvc]);

  const handleInputFocus = (
    event: React.FocusEvent<HTMLCCInputElement>
  ): void => {
    setFocused(event.target.name);
  };

  const toggleSending = (state: boolean): void => {
    setSending(state);
  };

  const handleCardNumberChange = (
    event: React.ChangeEvent<HTMLCCInputElement>
  ): void => {
    const reg = /^[0-9\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      setCardNumber(event.target.value);
    }
  };

  const handleCallback = (type: CallbackArgument, isValid: boolean): void => {
    setCardNumberValid(isValid);
  };

  const handleExpiryChange = (
    event: React.ChangeEvent<HTMLCCInputElement>
  ): void => {
    const reg = /^[0-9/\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      setExpiry(
        event.target.value
          .replace(/^(\d\d)(\d)$/g, "$1/$2")
          .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
      );
    }
  };

  const handleCvcChange = (
    event: React.ChangeEvent<HTMLCCInputElement>
  ): void => {
    const reg = /^[0-9\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      setCvc(event.target.value);
    }
  };

  const hasError = (error: string): string => {
    return error;
  };

  const onTrack = (event: string, error?: string): void => {
    track(event, {
      Error: error,
    });
  };

  const onSubmit = (): any => {
    try {
      toggleSending(true);
      const publishableKey = setting?.stripe_credentials?.publishable_key;
      const stripeClient = new Stripe(publishableKey);

      stripeClient
        .createPaymentMethod({
          number: cardNumber,
          cvc: cvc,
          // eslint-disable-next-line @typescript-eslint/camelcase
          exp_month: expiry.split("/")[0],
          // eslint-disable-next-line @typescript-eslint/camelcase
          exp_year: expiry.split("/")[1],
        })
        .then(token => {
          onProcess(token?.id as string);
        })
        .catch((error: string) => {
          toggleSending(false);
          hasError(error);
          onTrack("Payment fail", error);
        });
    } catch (error) {
      logException(error);
      hasError(t("Pay.Error"));
      toggleSending(false);
    }
  };

  return (
    /**@TODO
     * Modal with fixed height for both credit card and check payment
     */
    <Modal
      isOpen={isPayModalOpen}
      toggle={togglePayModal}
      centered
      className="mt-4">
      <ModalHeader toggle={togglePayModal}></ModalHeader>
      <ModalBody>
        <Cards
          cvc={cvc}
          expiry={expiry}
          focused={focused}
          name={""}
          number={cardNumber}
          placeholders={{ name: "" }}
          callback={handleCallback}
        />
        <Form>
          <FormGroup className="mt-5 p-1">
            <Input
              type="text"
              name="number"
              className="form-control"
              placeholder={t("Pay.Placeholder.Text.Card")}
              required
              invalid={!isCardNumberValid}
              value={cardNumber}
              maxLength={19}
              onChange={handleCardNumberChange}
              onFocus={handleInputFocus}
            />
          </FormGroup>
          <Row className="p-1">
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  name="expiry"
                  className="form-control"
                  placeholder={t("Pay.Placeholder.Text.Valid")}
                  required
                  invalid={!isExpiryValid}
                  value={expiry}
                  maxLength={5}
                  onChange={handleExpiryChange}
                  onFocus={handleInputFocus}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  name="cvc"
                  className="form-control"
                  placeholder={t("Pay.Placeholder.Text.Cvc")}
                  required
                  invalid={!isCvc}
                  value={cvc}
                  maxLength={4}
                  onChange={handleCvcChange}
                  onFocus={handleInputFocus}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3 p-3">
            <Button
              onClick={onSubmit}
              color="primary"
              disabled={
                sending || !isCardNumberValid || !isExpiryValid || !isCvc
              }
              block>
              {sending
                ? t("Pay.Checks.Confirming")
                : t("Pay.Subscribe.Confirm")}
            </Button>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
