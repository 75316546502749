import { MemberState } from "./types";

export const memberStateInit: MemberState = {
  cached: {},
  items: null,
  groups: {},
  follows: {},
  preLoadingStatus: "none",
};

export default memberStateInit;
