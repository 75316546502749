import React, { useContext } from "react";

import { Switch, RouteProps, Route, Redirect } from "react-router-dom";
import { Col } from "reactstrap";

import { UserDataState } from "../../../types";

import {
  RouteUserEditProfile,
  SwitchLeftCol,
  SwitchRightCol,
} from "../../routes";

import { TemplateMainContent } from "..";
import { ConfigContext } from "../../context";

export type AppProtectedProps = {
  customConfig:
    | {
        leftCol?: RouteProps[];
        rightCol?: RouteProps[];
        footer?: { disableOnPaths: string[] };
      }
    | undefined;
  user: UserDataState;
};

export const AppProtected: React.FC<AppProtectedProps> = ({
  customConfig,
  user,
}) => {
  const { routes } = useContext(ConfigContext);

  if (user.status !== "active")
    return (
      <Col className="gf-main-content">
        <Switch>
          <Route path="/edit-profile" component={RouteUserEditProfile} />
          <Redirect to="/edit-profile" />
        </Switch>
      </Col>
    );

  /** @TODO Implement custom paths components in navigation and in routes */

  const routeElements = routes.map((routeProps, i) => (
    <Route key={i} {...routeProps} />
  ));

  return (
    <>
      <SwitchLeftCol customConfig={customConfig?.leftCol} />
      <TemplateMainContent
        disabledFooterPaths={customConfig?.footer?.disableOnPaths}>
        <Switch>{routeElements}</Switch>
      </TemplateMainContent>
      <SwitchRightCol customConfig={customConfig?.rightCol} />
    </>
  );
};
