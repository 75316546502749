import { sendFeedBack as _sendFeedBack } from "../../services/api";
import { logException } from "../../lib/utils";
import { FeedbackData } from "../../types/Support/FeedbackData";
import { AppThunkAction, AppThunkDispatch, GetState } from "../../types";

export const sendFeedBack = (
  data: FeedbackData
): AppThunkAction<{ data: boolean }> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<{ data: boolean }> => {
  try {
    console.debug("[Action] sendFeedBack", { data });

    const { user } = getState();
    //Prevent doing comment in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't feedback in Guest Mode.");
    }

    await _sendFeedBack(data);
    return { data: true };
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
