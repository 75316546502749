import { CustomFileType } from "../../../types/Chat/CustomUserMessage";
import { SettingConfigData } from "../../../types";
import { runFunction } from "../../../services/http";
import { logException } from "../../../lib/utils";
import _ from "underscore";

export const resolveFile = async (
  file: CustomFileType,
  path: string,
  config: SettingConfigData
): Promise<{ fileUrl?: string; thumbUrl?: string }> => {
  try {
    const appFolder = path || config.S3PostPath;

    let result = undefined;

    const fileProgress: any = {};

    const progressCallback = (
      name: string,
      transferred: number,
      total: number,
      cancelUpload?: boolean
    ): void => {
      fileProgress[name] = { transferred, total };
      const _transferred = _.reduce(
        fileProgress,
        (memo, progress: any) => memo + progress.transferred,
        0
      );
      const _total = _.reduce(
        fileProgress,
        (memo, progress: any) => memo + progress.total,
        0
      );
      if (file.progressCallback) {
        file.progressCallback(
          name,
          fileProgress,
          _transferred,
          _total,
          cancelUpload
        );
      }
    };

    if (file.file) {
      const uploadFile: File = file.file;
      result = await uploadToAmazon(
        uploadFile,
        appFolder,
        config,
        progressCallback
      );
    }

    let thumbResult = undefined;

    if (file.fileThumb) {
      const fileThumb: File = file.fileThumb;
      thumbResult = await uploadToAmazon(fileThumb, appFolder, config);
    }

    return { fileUrl: result, thumbUrl: thumbResult };
  } catch (e) {
    logException(e);
    return {};
  }
};

type AmazonPolicy = {
  key: string;
  policy: string;
  signature: string;
};
export const uploadToAmazon = (
  file: File,
  appFolder: string,
  config: SettingConfigData,
  progressCallback?: (
    name: string,
    transferred: number,
    total: number,
    cancelUpload?: boolean
  ) => void
): Promise<string | undefined> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const amazonPolicy: AmazonPolicy = await runFunction(
      "generateAmazonPolicy",
      { bucket: config.s3Bucket }
    );

    const customName =
      new Date().getTime() + "_" + file.name.split(" ").join("_");
    const awsFilePath = appFolder + customName;

    const options = {
      keyPrefix: awsFilePath,
      bucket: config.s3Bucket,
      accessKey: config.amazonKey,
      secretKey: config.amazonSecret,
      successActionStatus: 201,
      s3Url: `https://${config.s3Bucket}.s3.amazonaws.com/`,
    };

    const fd = new FormData();
    fd.append("key", options.keyPrefix);
    fd.append("acl", "public-read");
    fd.append("Content-Type", file.type);
    fd.append("AWSAccessKeyId", amazonPolicy.key);
    fd.append("policy", amazonPolicy.policy);
    fd.append("signature", amazonPolicy.signature);
    fd.append("file", file);

    //callback
    const uploadComplete = (): void => {
      resolve(options.s3Url + options.keyPrefix);
    };
    const uploadFailed = (error: any): void => {
      reject({
        code: error.code,
        message: "Error uploading " + error.source + " to " + error.target,
      });
    };
    const uploadProgress = (progress: {
      loaded: number;
      total: number;
    }): void => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (window.canceledUploading) {
        xhr.abort();
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        window.canceledUploading = false;
        progressCallback?.(file.name, 0, 0);
        reject({ message: null });
      } else {
        progressCallback?.(file.name, progress.loaded, progress.total);
      }
    };

    const xhr = new XMLHttpRequest();
    xhr.addEventListener("load", uploadComplete, false);
    xhr.addEventListener("error", uploadFailed, false);
    xhr.upload.addEventListener("progress", uploadProgress, false);
    xhr.open("POST", options.s3Url, true);
    xhr.send(fd);
  });
};
