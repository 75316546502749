export const i18nDictionary = {
  "en-AU": {},
  en: {
    App: {
      "Index.Toast.Internet.Title": "No internet connection.",
      "Index.Alert.Internet.Title": "Please check your internet!",
      "Index.Alert.Internet.Text": "Press OK to restart app!",
      "Index.Alert.Server.Title": "Could not connect to server.",
      "Index.Alert.Server.Text":
        "Please try again later or contact administrator.",
      "Index.Alert.Button.OK": "$t(Common:Button.Ok)",
      "Index.Alert.Button.Retry": "$t(Common:Button.Retry)",
      "Index.Alert.Button.Ignore": "Ignore",
      "Index.Alert.Button.Reset": "Reset App",
      "Index.Alert.Button.Wait": "Wait",
      "Index.Alert.Button.Close": "$t(Common:Button.Close)",
      "Index.Alert.Crash.Title": "Unexpected error occurred.",
      "Index.Alert.Crash.Text":
        "Please try these solutions and let us know if they can't resolve your issue! \nWe'll fix this as soon as possible. \nThanks for your patience.",
      "App.Update.Text.Force":
        "We’ve changed, improved actually! \nDownload the latest version from the App Store to see everything that’s new.",
      "App.Update.Text.1": "Installing - Newness.",
      "App.Update.Text.2": "2-4-6-8 This app’s now up to date.",
      "App.Update.Text.3": "Ch-Ch-Changes!",
      "App.Update.Failed": "App update failed. Try it later.",
      "App.Button.Download": "Download now!",
      "App.ViewAsGuest.Text":
        'You’re in "View As" mode.  Any actions you take will register for this user, such as RSVP, comments, etc.',
      "App.ViewAsGuest.Exit": "Exit now!",
      "App.ViewAsGuest.Toast":
        'Exiting "View As" mode and return to your account...',
    },
    Admin: {
      "Index.Heading.Announcement": "Announcements",
      "Index.Note.Announcement": "Publishing/Draft",
      "Index.Heading.Events": "Events",
      "Index.Note.Events": "Publishing/Draft",
      "Index.Heading.Add.Member": "Add Member",
      "Index.Note.Add.Member": "Add new",
      "Index.Heading.Groups": "Groups",
      "Index.Note.Groups": "Create & Manage",
      "Index.Heading.Communication": "Communication",
      "Index.Note.Communication": "SMS/Email/Push/Popup",
      "Index.Heading.Custom.Theme": "Custom Theme",
      "Index.Note.Custom.Theme": "Basic/Advanced",
    },
    Announcement: {
      "Container.Admin.Draft.Callback": "Deleted announcement successfully!",
      "Container.Admin.NewEdit.Callback.Submitted.Successfully":
        "Submitted your announcement successfully!",
      "Container.Admin.NewEdit.Callback.Deleted.Successfully":
        "Deleted announcement successfully!",
      "Container.Admin.NewEdit.Option.App.Popup": "In-App Popup",
      "Container.Admin.NewEdit.Option.Push": "Push Notification",
      "Container.Admin.NewEdit.Option.Sms": "SMS",
      "Container.Admin.NewEdit.Option.Email": "Email",
      "Container.Admin.NewEdit.Option.All.Targeted.Members":
        "All targeted members",
      "Container.Admin.NewEdit.Option.Only.Members":
        "Only members without the app (who cannot receive a push notification)",
      "Container.Admin.NewEdit.Option.Text": "Text",
      "Container.Admin.NewEdit.Option.URL": "URL",
      "Container.Admin.NewEdit.Option.Everyone": "Everyone",
      "Container.Admin.NewEdit.Option.Specific.Groups": "Specific Groups",
      "Container.Admin.Published.Callback":
        "Deleted announcement successfully!",
      "Admin.Card.Title.Confirm.Deletion": "Confirm Deletion",
      "Admin.Card.Message.Confirm.Deletion":
        "Do you want to delete this announcement?",
      "Admin.Card.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Card.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.Card.Title.Event": "Event",
      "Admin.Card.Title.Announcement": "Announcement",
      "Admin.Card.Note.Saved.On": "Saved on:",
      "Admin.Card.Note.Published.On": "Published on:",
      "Admin.Card.Label.Title.Event": "Event",
      "Admin.Card.Note.Audience": "Audience: {{target}}",
      "Admin.Card.Option.Edit": "Edit",
      "Admin.Card.Option.Remove": "Remove",
      "Admin.Card.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Index.Heading.Publishing": "Publishing",
      "Admin.Index.Heading.New.Announcement": "New Announcement",
      "Admin.Index.Heading.Draft": "Draft",
      "Admin.Index.Title.New": "New",
      "Admin.NewEdit.Validator": "is required",
      "Admin.NewEdit.Validator.Multiple": "is required",
      "Admin.NewEdit.Validator.Length": "should be less than 120 characters",
      "Admin.NewEdit.Option.Photo": "My Photos",
      "Admin.NewEdit.Option.Video": "My Video",
      "Admin.NewEdit.Option.Search.Photo": "Gifs & Stock Photos",
      "Admin.NewEdit.Option.Search.Video": "Search Video",
      "Admin.NewEdit.Option.Add.Video": "Add Video Link",
      "Admin.NewEdit.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.NewEdit.Title.Add.Video": "Add video link",
      "Admin.NewEdit.Hint.Add.Video": "Youtube, Vimeo, etc...",
      "Admin.NewEdit.Title.Confirm.Deletion": "Confirm Deletion",
      "Admin.NewEdit.Message.Confirm.Deletion":
        "Do you want to delete this announcement?",
      "Admin.NewEdit.Button.Choose.Photo.Video": "Choose Photo/Video",
      "Admin.NewEdit.Heading.Details": "1. DETAILS",
      "Admin.NewEdit.Title.Title": "Title",
      "Admin.NewEdit.Hint.Title": "Write title",
      "Admin.NewEdit.Title.Sub.Header": "Sub header",
      "Admin.NewEdit.Hint.Sub.Header": "Write sub header",
      "Admin.NewEdit.Title.Category": "Category",
      "Admin.NewEdit.Hint.Category": "Select category",
      "Admin.NewEdit.Title.Author": "Author",
      "Admin.NewEdit.Hint.Author": "Select author",
      "Admin.NewEdit.Heading.Announcement": "2. ANNOUNCEMENT TYPE",
      "Admin.NewEdit.Title.Type": "Type",
      "Admin.NewEdit.Hint.Type": "Select type",
      "Admin.NewEdit.Title.Body.Text": "Body Text",
      "Admin.NewEdit.Hint.Body.Text": "Write something",
      "Admin.NewEdit.Title.Url": "URL",
      "Admin.NewEdit.Hint.Url": "Write url",
      "Admin.NewEdit.Title.Attachments": "Attachments",
      "Admin.NewEdit.Button.Add": "Add",
      "Admin.NewEdit.Heading.Audience": "3. AUDIENCE",
      "Admin.NewEdit.Note.Target":
        "Who is the target audience of this announcement?",
      "Admin.NewEdit.Title.Target": "Select target",
      "Admin.NewEdit.Note.Groups":
        "Select the groups that can view this announcement.",
      "Admin.NewEdit.Title.Groups": "Select groups",
      "Admin.NewEdit.Hint.Groups": "Select groups",
      "Admin.NewEdit.Heading.Notification": "4. NOTIFICATIONS",
      "Admin.NewEdit.Info.Notification":
        "No notifications have been sent for this announcement. A new notification may be sent by making a selection below.",
      "Admin.NewEdit.Info.Notification.Sent":
        "A push notification was sent on {{data.lastNotifyAt}}. A new notification may be sent by making a selection below.",
      "Admin.NewEdit.Title.Channels": "Channels",
      "Admin.NewEdit.Hint.Channels": "Select channels",
      "Admin.NewEdit.Note.Notification": "Send Email/SMS notifications to.",
      "Admin.NewEdit.Title.Notification": "Notifications to",
      "Admin.NewEdit.Heading.Schedule": "5. SCHEDULE",
      "Admin.NewEdit.Note.Schedule":
        "Schedule this announcement for future posting",
      "Admin.NewEdit.Title.Schedule": "Schedule",
      "Admin.NewEdit.Title.Publish.Date": "Publish Date",
      "Admin.NewEdit.Title.Publish.Time": "Publish Time",
      "Admin.NewEdit.Title.Timezone": "Timezone",
      "Admin.NewEdit.Button.Publish": "Publish Announcement",
      "Admin.NewEdit.Button.Publish.Update": "Publish Announcement Update",
      "Admin.NewEdit.Button.Update.Draft": "Update Draft",
      "Admin.NewEdit.Button.Save.Draft": "Save As Draft",
      "Admin.NewEdit.Button.Delete.Announcement": "Delete Announcement",
      "Card.Header.Title.Video": "Video announcement",
      "Card.Header.Title.Announcement": "Announcement",
      "Card.Header.Title.Event": "Announcement of event",
      "Card.Button.Read.More": "Read more",
      "Card.Button.See.Recap": "See Recap",
      "Card.Text.Posted.By": "Posted by",
      "Detail.Toast.Text": "Copied text successfully!",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Heading.Attachments.Text": "Attachments",
      "Index.Member.Name": "Member",
      "Index.Heading.Updates": "Updates",
      "Pins.Pin.Title": "Pinned {{setting.announcementLabel}}",
      "Pins.Pin.Title.Announcement": "Announcement",
      "Pins.Button.View.Announcement": "View Announcement",

      "No Announcement found": "No Announcement found",
    },
    Chat: {
      "Container.Action.Message.Start.Chat": "Start a chat",
      "Container.Action.Author.You": "You: ",
      "Container.Action.Text.Sent": "Sent",
      "Container.Action.Title.New.Message": "You have a new message.",
      "Container.Action.Typing.Singular": "is typing...",
      "Container.Action.Typing.Plural": "are typing...",
      "Container.Action.People.Typing": "Some people are typing ...",
      "Container.Action.Reject.1":
        "There are some users not available now. Please try later!",
      "Container.Action.Reject.2":
        "There was a problem processing your request. Please try again.",
      "Container.Action.Reject.3": "Channel not found.",
      "Container.Action.Reject.4": "This group chat is not visible to you.",
      "Container.Index.Callback.Update.Settings":
        "Update chat settings successfully!",
      "Container.Index.Callback.Delete": "Deleted the message successfully!",
      "Container.Index.Callback.Update.Chat.Name":
        "Updated the group chat name successfully!",
      "Container.Index.Callback.Added.To.Favourite":
        "Added to favourite list successfully!",
      "Container.Index.Callback.Removed.From.Favourite":
        "Removed from favorite list successfully!",
      "Container.Settings.Option.All.Messages": "All new messages",
      "Container.Settings.Option.Just.Mentions": "Just mentions",
      "Container.Settings.Option.Nothing": "Nothing",
      "Container.Setting.Callback.Update.Chat.Name":
        "Updated the group chat name successfully!",
      "Card.Button.Ok": "$t(Common:Button.Ok)",
      "Card.Title.Confirm.Deletion": "Confirm Deletion",
      "Card.Message.Confirm.Deletion": "Do you want to delete this message?",
      "Card.Button.Deletion.Cancel": "$t(Common:Button.Cancel)",
      "Card.Chat.Name.Change": "Change group chat name.",
      "Card.Chat.New.Name": "Type new name",
      "ConversationEditMessage.Button.Cancel": "Cancel",
      "ConversationEditMessage.Button.Save": "Save",
      "Detail.Toast.Text": "Copied text successfully!",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Message.Action.Copy": "Copy text",
      "Detail.Message.Action.Edit": "Edit",
      "Detail.Message.Action.Delete": "Delete",
      "Detail.Message.Action.Cancel": "$t(Common:Button.Cancel)",
      "Index.Member.Name": "Member",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Option.Remove": "Remove",
      "Index.Option.Cancel": "$t(Common:Button.Cancel)",
      "Index.Toast.Open.Chat": "Starting chat...",
      "Index.Alert.Guest.Mode": "Can't see chat in Guest Mode.",
      "ListChat.Placeholder.Search.Box": "Search for a chat",
      "ListChat.Info":
        "Start a conversation by using the plus button below. You can also add a contact as a favorite by using the empty buttons above.",
      "Mixed.Heading.Chat": "Chat",
      "Mixed.Heading.Event": "Event",
      "Mixed.Heading.Feed": "Feed",
      "Mixed.Heading.Image": "Image",
      "Mixed.Heading.File": "File",
      "Mixed.Heading.Members": "Members",
      "Mixed.Button.Close": "Close",
      "Mixed.Heading.Settings": "Settings",
      "Mixed.Post.To.Community": "Post to your community",
      "Settings.Button.Ok": "$t(Common:Button.Ok)",
      "Settings.Button.Cancel": "$t(Common:Button.Cancel)",
      "Settings.Toast.Leave.Chat": "Left the chat successfully!",
      "Settings.Toast.Delete.Message": "Deleted the message successfully!", //I18N this is for chat/channel, not message
      "Settings.Toast.Add.Member": "Added members successfully!",
      "Settings.Toast.Remove.Member": "Removed members successfully!",
      "Settings.Title.Change.Group.Name": "Change group chat name.",
      "Settings.Placeholder.New.Name": "Type new name",
      "Settings.Title.Messages": "Messages",
      "Settings.Option.Add.Members": "Add members",
      "Settings.Option.Remove.Members": "Remove members",
      "Settings.Option.Edit.Chat.Name": "Edit chat name",
      "Settings.Option.Leave": "Leave",
      "Settings.Option.Delete": "Delete",
      "Settings.Title.Notifications": "Notifications",
      "Settings.Option.Mute.Channel": "Mute entire channel",
      "Settings.Ignore.Notification":
        "Ignore notifications for @channel and @here",
      "Settings.Mobile.Notification": "Mobile Notification",
      "Settings.Desktop.Notification": "Desktop Notification",
    },
    Comment: {
      "Bar.Hint.Comment": "Comment",
      "Bar.Title.Comment": "Comment",
      "Bar.Button.Like": "Like",
      "Bar.Button.Liked": "Liked",
      "Card.Button.Edit": "Edit comment",
      "Card.Button.Delete": "Delete comment",
      "Index.Toast.Text": "Copied text successfully!",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Action.Copy": "Copy text",
      "Index.Action.Edit": "Edit",
      "Index.Action.Delete": "Delete",
      "Index.Action.Cancel": "$t(Common:Button.Cancel)",
      "Index.Button.More.Comments": "View more comments",
      "Input.Button.Ok": "$t(Common:Button.Ok)",
      "Input.Placeholder.Message": "Type caption here",
      "Input.Button.Upload": "Upload",
      "Input.Button.Send": "Send",
      "Input.Placeholder.Comment": "Type your comment here",
      "Input.Button.Cancel": "$t(Common:Button.Cancel)",
      "Input.Button.Save": "Save",
      "Input.Button.Edit.Send": "Send",
      "List.Button.Load.More": "View more",
      "List.Button.Hide.Comments": "Hide comments",
      "Reaction.Seen.By": "Seen by",
    },
    Event: {
      "Container.Admin.Draft.Callback.Deleted": "Deleted event successfully!",
      "Container.Admin.NewEdit.Callback.Submitted":
        "Submitted your event successfully!",
      "Container.Admin.NewEdit.Callback.Deleted": "Deleted event successfully!",
      "Container.Admin.NewEdit.Option.App.Popup": "In-App Popup",
      "Container.Admin.NewEdit.Option.Push": "Push Notification",
      "Container.Admin.NewEdit.Option.Sms": "SMS",
      "Container.Admin.NewEdit.Option.Email": "Email",
      "Container.Admin.NewEdit.Option.All.Targeted.Members":
        "All targeted members",
      "Container.Admin.NewEdit.Option.Only.Members":
        "Only members without the app (who cannot receive a push notification)",
      "Container.Admin.NewEdit.Option.Everyone": "Everyone",
      "Container.Admin.NewEdit.Option.Specific.Groups": "Specific Groups",
      "Container.Admin.NewEdit.Option.Expires.In":
        "Expires in 24 hours after the events end",
      "Container.Admin.NewEdit.Option.Expires.On":
        "Expires on specific date/time",
      "Container.Admin.NewEdit.Option.Never.Expires": "Never Expires",
      "Container.Admin.Published.Callback.Deleted":
        "Deleted event successfully!",
      "Container.Action.Message.No.More.Registration":
        "No more registrations are being accepted for this event.",
      "Container.Action.Message.Added.As.Guest":
        "You were added as guest, so you can't change information.",
      "Container.Detail.Note.Rsvp.Changed": "RSVP was changed by",
      "Container.Detail.Note.Rsvp.Added": "RSVP was added by",
      "Container.Detail.Error.Card.Declined":
        "Your credit card is declined. Please try another one.",
      "Admin.Card.Title.Confirm.Deletion": "Confirm Deletion",
      "Admin.Card.Message.Confirm.Deletion":
        "Do you want to delete this event?",
      "Admin.Card.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Card.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.Card.Title.Event": "Event",
      "Admin.Card.Note.Event.Date": "Event date: {{eventDate}}",
      "Admin.Card.Note.Audience": "Audience: {{target}}",
      "Admin.Card.Label.Title.Announcement": "Announcement",
      "Admin.Card.Option.Edit": "Edit",
      "Admin.Card.Option.Remove": "Remove",
      "Admin.Card.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Index.Heading.Publishing": "Publishing",
      "Admin.Index.Heading.New.Event": "New Event",
      "Admin.Index.Heading.Draft": "Draft",
      "Admin.Index.Title.New": "New",
      "Admin.NewEdit.Validator": "is required",
      "Admin.NewEdit.Validator.Multiple": "is required",
      "Admin.NewEdit.Validator.Length": "should be less than 120 characters",
      "Admin.NewEdit.Title.Item": "Item",
      "Admin.NewEdit.Title.Maximum": "Maximum",
      "Admin.NewEdit.Title.Required": "Required",
      "Admin.NewEdit.Heading.Option": "Option",
      "Admin.NewEdit.Heading.Available": "Available",
      "Admin.NewEdit.Heading.Price": "Price ($)",
      "Admin.NewEdit.Option.Photo": "My Photos",
      "Admin.NewEdit.Option.Video": "My Video",
      "Admin.NewEdit.Option.Search.Photo": "Gifs & Stock Photos",
      "Admin.NewEdit.Option.Search.Video": "Search Video",
      "Admin.NewEdit.Option.Add.Video": "Add Video Link",
      "Admin.NewEdit.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.NewEdit.Title.Add.Video": "Add video link",
      "Admin.NewEdit.Hint.Add.Video": "Youtube, Vimeo, etc...",
      "Admin.NewEdit.Title.Confirm.Deletion": "Confirm Deletion",
      "Admin.NewEdit.Message.Confirm.Deletion":
        "Do you want to delete this event?",
      "Admin.NewEdit.Button.Choose.Photo.Video": "Choose Photo/Video",
      "Admin.NewEdit.Heading.Details": "1. DETAILS",
      "Admin.NewEdit.Title.Title": "Title",
      "Admin.NewEdit.Hint.Title": "Write title",
      "Admin.NewEdit.Title.Organizer": "Organizer",
      "Admin.NewEdit.Hint.Organizer": "Select organizer",
      "Admin.NewEdit.Title.Speaker": "Speaker",
      "Admin.NewEdit.Hint.Speaker": "Write name of speaker",
      "Admin.NewEdit.Title.Speaker.Bio": "Speaker biography",
      "Admin.NewEdit.Hint.Speaker.Bio": "Paste url here",
      "Admin.NewEdit.Heading.Rsvp": "2. RSVP",
      "Admin.NewEdit.Note.Rsvp":
        "Allow members to RSVP to events from the app?",
      "Admin.NewEdit.Title.Allow.Rsvp": "Allow RSVP",
      "Admin.NewEdit.Title.Maximum.Attendees":
        "Maximum # of Attendees (Members and Guests)",
      "Admin.NewEdit.Title.Max.Guests":
        "Max # of Guests Per Member (Not including member)",
      "Admin.NewEdit.Title.Create.Chat": "Create chat room for this event?",
      "Admin.NewEdit.Heading.Location": "3. LOCATION",
      "Admin.NewEdit.Title.Venue": "Venue Name",
      "Admin.NewEdit.Title.Street.Address.1": "Street Address 1",
      "Admin.NewEdit.Title.Street.Address.2": "Street Address 2",
      "Admin.NewEdit.Title.City": "City",
      "Admin.NewEdit.Title.State": "State",
      "Admin.NewEdit.Title.County": "County",
      "Admin.NewEdit.Title.Country": "Country",
      "Admin.NewEdit.Title.ZipCode": "ZipCode",
      "Admin.NewEdit.Heading.Date.Time": "4. DATE & TIME",
      "Admin.NewEdit.Title.All.Day.Event": "This is an all day event",
      "Admin.NewEdit.Note.Event.Start": "Event starts at",
      "Admin.NewEdit.Title.Event.Start.Date": "Start Date",
      "Admin.NewEdit.Title.Event.Start.Time": "Start Time",
      "Admin.NewEdit.Note.Event.End": "Event ends at",
      "Admin.NewEdit.Title.Event.End.Date": "End Date",
      "Admin.NewEdit.Title.Event.End.Time": "End Time",
      "Admin.NewEdit.Title.RSVP.Deadline": "RSVP Deadline",
      "Admin.NewEdit.Title.Timezone": "Timezone",
      "Admin.NewEdit.Heading.Description": "5. DESCRIPTION",
      "Admin.NewEdit.Title.Body.Text": "Body Text",
      "Admin.NewEdit.Hint.Body.Text": "Write something",
      "Admin.NewEdit.Title.Attachments": "Attachments",
      "Admin.NewEdit.Button.Add": "Add",
      "Admin.NewEdit.Heading.Audience": "6. AUDIENCE",
      "Admin.NewEdit.Note.Event.Who.Is.Target":
        "Who is the target audience of this event?",
      "Admin.NewEdit.Title.Event.Select.Target": "Select target",
      "Admin.NewEdit.Note.Event.Select.Groups":
        "Select the groups that can view this event.",
      "Admin.NewEdit.Title.Event.Select.Groups": "Select groups",
      "Admin.NewEdit.Hint.Event.Select.Groups": "Select groups",
      "Admin.NewEdit.Heading.Announcement": "7. ANNOUNCEMENT",
      "Admin.NewEdit.Note.Publish.Announcement":
        "Publish this announcement in the Announcement Feed in addition to the Event Calendar (Recommended)",
      "Admin.NewEdit.Title.Publish.Announcement": "Publish Announcement",
      "Admin.NewEdit.Note.Announcement.Who.Is.Target":
        "Who is the target audience of this announcement?",
      "Admin.NewEdit.Title.Announcement.Select.Target": "Select target",
      "Admin.NewEdit.Note.Announcement.Select.Groups":
        "Select the groups that can view this announcement.",
      "Admin.NewEdit.Title.Announcement.Select.Groups": "Select groups",
      "Admin.NewEdit.Hint.Announcement.Select.Groups": "Select groups",
      "Admin.NewEdit.Note.Expiration":
        "Expiration - Removes Event Announcement from Announcement List when expiration is set. Does not affect event list.",
      "Admin.NewEdit.Title.Expiration": "Expiration",
      "Admin.NewEdit.Title.Expiration.Date": "Expiration Date",
      "Admin.NewEdit.Title.Expiration.Time": "Expiration Time",
      "Admin.NewEdit.Note.Schedule": "Schedule this announcement",
      "Admin.NewEdit.Title.Schedule": "Schedule",
      "Admin.NewEdit.Title.Publish.Date": "Publish Date",
      "Admin.NewEdit.Title.Publish.Time": "Publish Time",
      "Admin.NewEdit.Note.Notification": "Notification for this announcement",
      "Admin.NewEdit.Note.No.Notification.Sent":
        "No notifications have been sent for this announcement. A new notification may be sent by making a selection below.",
      "Admin.NewEdit.Note.Push.Notification.Sent":
        "A push notification was sent on {{announcement.lastNotifyAt}}. A new notification may be sent by making a selection below.",
      "Admin.NewEdit.Title.Announcement.Channels": "Channels",
      "Admin.NewEdit.Hint.Announcement.Channels": "Select channels",
      "Admin.NewEdit.Note.Announcement.Notification.To":
        "Send Email/SMS notifications to.",
      "Admin.NewEdit.Title.Announcement.Notification.To": "Notifications to",
      "Admin.NewEdit.Heading.Reminder.Notification":
        "8. REMINDER NOTIFICATIONS",
      "Admin.NewEdit.Note.Reminder":
        "Send a reminder to members who have not yet responded to an event",
      "Admin.NewEdit.Title.Reminder.Channels": "Channels",
      "Admin.NewEdit.Hint.Reminder.Channels": "Select channels",
      "Admin.NewEdit.Note.Reminder.Notification.To":
        "Send Email/SMS notifications to.",
      "Admin.NewEdit.Title.Reminder.Notification.To": "Notifications to",
      "Admin.NewEdit.Title.First.Reminder": "First reminder",
      "Admin.NewEdit.Title.First.Days.Before": "day(s) before at",
      "Admin.NewEdit.Title.Second.Reminder": "Second reminder",
      "Admin.NewEdit.Title.Second.Days.Before": "day(s) before at",
      "Admin.NewEdit.Heading.Get.Notified": "9. GET NOTIFIED OF MEMBER RSVP'S",
      "Admin.NewEdit.Note.Receive.When.Member.Respond":
        "Receive notifications when members respond to the event",
      "Admin.NewEdit.Title.Notify.Me": "Notify me by",
      "Admin.NewEdit.Hint.Notified.Channels": "Select channels",
      "Admin.NewEdit.Heading.Payments": "10. PAYMENTS",
      "Admin.NewEdit.Title.Enable.Payment":
        "Enable payment items for this event?",
      "Admin.NewEdit.Title.Add.Processing.Fee": "Add Processing Fee?",
      "Admin.NewEdit.Title.Due.Date": "Due Date",
      "Admin.NewEdit.Heading.Items": "Items",
      "Admin.NewEdit.Button.Publish": "Publish Event",
      "Admin.NewEdit.Button.Publish.Update": "Publish Event Update",
      "Admin.NewEdit.Button.Update.Draft": "Update Draft",
      "Admin.NewEdit.Button.Save.Draft": "Save As Draft",
      "Admin.NewEdit.Button.Delete.Event": "Delete Event",
      "Attendee.Toast.Placed.On.Waitlist":
        "You have been placed on the waitlist. If space becomes available you will be added to the event rsvp list and notified by email.",
      "Attendee.Toast.Submitted.Rsvp": "Submitted your RSVP successfully!",
      "Attendee.Button.Ok": "$t(Common:Button.Ok)",
      "Attendee.Title.Alert": "Alert",
      "Attendee.Message.Items.Have.Sold.Out":
        "All the required items have sold out. \nPlease contact your administrator to solve your attending.",
      "Attendee.Toast.Pay.To.Complete":
        "We're sending invoice for you, please pay it to complete your RSVP, thanks!",
      "Attendee.Heading.Add.On.Instructions": "Add on instructions",
      "Attendee.Note.Select.Items":
        "Select the items you wish to purchase and pay the total amount displayed below with a credit card.",
      "Attendee.Text.Item.Options": "Item/Options",
      "Attendee.Text.Item.Quantity": "Quantity",
      "Attendee.Text.Item.Subtotal": "Subtotal",
      "Attendee.Button.Pay": "Pay $",
      "Attendee.Button.Save": "Save",
      "Attendee.Add.Non.Member": "Add a non-member guest",
      "Attendee.Placeholder.Enter.Name": "Enter name",
      "Attendee.Button.Cancel": "$t(Common:Button.Cancel)",
      "Attendee.Additional.Guests": "Additional Guests",
      "Attendee.Button.Done": "Done",
      "Attendee.Option.Directory": "Directory",
      "Attendee.Option.Non-member": "Enter a non-member",
      "Attendee.Title.Add.Guest": "Add a guest",
      "Attendee.Text.Add.Guest": "Add a guest",
      "Attendee.Text.Additional.Guests.Not.Allowed":
        "Additional guests are not allowed.",
      "Attendee.Text.Additional.Guests":
        "Additional guests (Bring up to {{maxGuestCount}} more guests)",
      "Attendee.Button.Edit": "Edit",
      "Attendee.Button.Add": "Add",
      "Attendee.Button.Reply.By": "Reply by {{attendeeDeadline}}",
      "Attendee.Button.Will.Attend": "Will Attend",
      "Attendee.Button.Joining.WaitList": "Joining WaitList",
      "Attendee.Button.You.Are.Attending": "You're attending",
      "Attendee.Button.Join.WaitList": "Join WaitList",
      "Attendee.Button.Will.Not.Attend": "Will Not Attend",
      "Attendee.Text.Spouse.Partner.Rsvp": "Spouse / Partner RSVP", //TODO: interpolation. Couldn't find in app
      "Attendee.Text.Rsvp.Note.Special.Requests":
        "RSVP Note / Special Requests",
      "Attendee.Placeholder.Note": "Note",
      "Attendee.Text.Notify.Me.When.RSVP": "Notify me when other members RSVP!",
      "Attendee.Button.Continue": "Continue",
      "Attendee.Button.Saving": "Saving",
      "Attendee.Warning1":
        "There is a waitlist for this event. If there becomes an opening you will be added and notified.",
      "Attendee.Warning2":
        "The deadline to RSVP for this event has already passed. Please reach out to {{author.firstName}} {{author.lastName}} directly.",
      "Attendee.Warning3":
        "Please contact your administrator to change your attendance status for this event.",
      "Attendee.Warning4":
        "The due date to RSVP for this event has already passed. Please reach out to {{author.firstName}} {{author.lastName}} directly.",
      "Attendees.Text.No.Members.Signed":
        "No members signed up for this event.",
      "Calendar.Text.No.Events": "There are no events today",
      "Card.Button.Rsvp": "RSVP",
      "Card.Button.Attending": "You're attending",
      "Card.Button.Not.Attending": "You're not attending",
      "Card.Button.Joined.WaitList": "Joined WaitList",
      "Detail.Toast.Add.Event.To.Calendar":
        "Added this event to your calendar successfully!",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Toast.Error.Calendar":
        "There was a problem adding your event. ({{error}})",
      "Detail.Toast.Copied.Text": "Copied text successfully!",
      "Detail.Text.Spouse.Partner.Attending": "* Spouse/Partner is attending",
      "Detail.Text.Spouse.Partner.Not.Attending":
        "* Spouse/Partner is not attending",
      "Detail.Button.Reply.By": "Reply by {{attendeeDeadline}}",
      "Detail.Button.Change.Rsvp": "Change RSVP",
      "Detail.Text.Joined.Waitlist": "* You joined the waitlist",
      "Detail.Text.Attending": "* You're attending",
      "Detail.Button.Join.WaitList": "Join WaitList",
      "Detail.Text.You.Not.Attending": "* You're not attending",
      "Detail.Text.Attachments": "Attachments",
      "Detail.Heading.Details": "Details",
      "Detail.Button.Options.Upgrade": "Options/Upgrade",
      "Detail.Button.Add.To.Calendar": "Add To Calendar",
      "Detail.Button.Message.Guests": "Message Guests",
      "Detail.Text.Organizer": "Organizer",
      "Detail.Text.Attendee": "Attendee",
      "Detail.Text.Attendee.Plural": "Attendees",
      "Detail.Text.Member": "{{attendees.total}} attendee",
      "Detail.Text.Member.Plural": "{{attendees.total}} attendees",
      "Detail.Text.Speaker": "Speaker",
      "Detail.Text.About.Event": "About This Event",
      "Detail.Heading.Chat": "Chat",
      "Detail.Heading.Photos": "Photos",
      "Detail.Heading.Attendees": "Attendees",
      "EventInfoHeader.Option.Get.Directions": "Get directions",
      "EventInfoHeader.Option.Display.On.Map": "Display on our map",
      "EventInfoHeader.Option.Cancel": "$t(Common:Button.Cancel)",
      "Index.Button.Close": "Close",
      "Index.Button.Choose.Group": "Choose Group",
      "Index.Heading.Filter.Events": "Filter events",
      "Index.Heading.Sort.By": "Sort by:",
      "Index.Button.Closest.Date": "Closest Date",
      "Index.Button.Furthest.Date": "Furthest Date",
      "Index.Heading.Event.View": "Event view:",
      "Index.Button.In.Progress.Upcoming": "In progress & Upcoming",
      "Index.Button.Past": "Past",
      "Index.Heading.Filter.By.Group": "Filter by group:",
      "Index.Button.Reset.To.All.Events": "Reset to all groups",
      "Index.Button.All.Events": "All groups",
      "Index.Heading.List": "List",
      "Index.Heading.Event": "event",
      "Index.Heading.Event.Plural": "events",
      "Index.Text.No.Filter.Events": "There are no {{filter}} events",
      "Index.Text.In": "in",
      "Index.Heading.Calendar": "Calendar",
      "EventLocation.Button.Groupfire.Map": "Open in Groupfire map",
      "EventLocation.Button.Google.Maps": "Open in Google maps",
      "EventLocation.Button.Cancel": "$t(Common:Button.Cancel)",

      "No events found": "No events found",
    },
    Form: {
      "Index.Placeholder.Write.Something": "Write something ...",
      "Index.Placeholder.Search": "Search",
      "Modal.Button.Close": "Close",
    },
    Group: {
      "Container.Admin.Index.Callback": "Deleted group successfully!",
      "Container.Admin.NewEdit.Callback": "Submitted your group successfully!",
      "Container.Admin.NewEdit.Option.Yes": "Yes",
      "Container.Admin.NewEdit.Option.No": "No",
      "Container.Admin.NewEdit.Option.Admin": "Admin",
      "Container.Admin.NewEdit.Option.Private": "Private",
      "Container.Admin.NewEdit.Option.Public": "Public",
      "Container.Admin.NewEdit.Option.All.Org":
        "All org members can instantly join",
      "Container.Admin.NewEdit.Option.Request":
        "Request access and wait for approval by admin",
      "Container.Admin.NewEdit.Option.Added.By":
        "Added by admin only, no request available",
      "Container.Admin.NewEdit.Option.Administrators": "Administrators",
      "Container.Admin.NewEdit.Option.Moderators": "Moderators",
      "Container.Admin.NewEdit.Option.Group.Members": "Group Members",
      "Container.Admin.NewEdit.Option.Anyone": "Anyone",
      "Container.Action.More": "More",
      "Container.Action.My.Groups": "My Groups",
      "Container.Action.Open": "Open",
      "Container.Action.Request": "Request",
      "Container.Detail.Info":
        "This group is not visible for you. \nDo you want to join it now?",
      "Container.Detail.Callback": "You have joined the group {{name}}",
      "Container.Detail.Callback.2":
        "Your request to join this group has been sent to a group administrator for approval.",
      "Container.Detail.Callback.3": "You have left the group {{name}}",
      "Container.Setting.Option.All": "All new posts",
      "Container.Setting.Option.Just": "Just mentions",
      "Container.Setting.Option.Nothing": "Nothing",
      "Admin.Card.Title.Confirm.Deletion": "Confirm Deletion", //I18N withTranslation doesn't work
      "Admin.Card.Message.Confirm.Deletion":
        "Do you want to delete this group?",
      "Admin.Card.Button.Deletion.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Card.Button.Deletion.Ok": "$t(Common:Button.Ok)",
      "Admin.Card.Text.Type": "Type: {{type.name}}",
      "Admin.Card.Text.Visibility": "Visibility:",
      "Admin.Card.Text.Admin": "Admin",
      "Admin.Card.Text.Private": "Private",
      "Admin.Card.Text.Public": "Public",
      "Admin.Card.Text.Members": "Members: {{memberCount}}",
      "Admin.Card.Text.SubGroups": "SubGroups: {{item.children.length}}",
      "Admin.Card.Option.Edit": "Edit",
      "Admin.Card.Option.Remove": "Remove",
      "Admin.Card.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Index.Placeholder.Search": "Search",
      "Admin.Index.New.Group": "New Group",
      "Admin.NewEdit.Validator": "is required",
      "Admin.NewEdit.Validator.Length": "should be less than 120 characters",
      "Admin.NewEdit.Option.Photo": "My Photos",
      "Admin.NewEdit.Option.Search.Photo": "Gifs & Stock Photos",
      "Admin.NewEdit.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Toast.Submitted.Group":
        "Submitted your group successfully!",
      "Admin.NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.NewEdit.Button.Choose.Photo": "Choose Photo",
      "Admin.NewEdit.Heading.Details": "1. DETAILS",
      "Admin.NewEdit.Title.Name": "Name",
      "Admin.NewEdit.Hint.Name": "Write name",
      "Admin.NewEdit.Title.Parent.Group": "Parent group",
      "Admin.NewEdit.Hint.Select.Group": "Select group",
      "Admin.NewEdit.Title.Group.Type": "Group type",
      "Admin.NewEdit.Hint.Select.Group.Type": "Select group type",
      "Admin.NewEdit.Title.Description": "Description",
      "Admin.NewEdit.Hint.Write.Description": "Write description",
      "Admin.NewEdit.Heading.Group.Feed": "2. GROUP FEED",
      "Admin.NewEdit.Note.Will.Feed.Available":
        "Will a feed of posts be available for this group?",
      "Admin.NewEdit.Title.Enable": "Enable",
      "Admin.NewEdit.Note.Who.Can.Post": "Who can post to the feed?",
      "Admin.NewEdit.Title.Post.By": "Post by",
      "Admin.NewEdit.Heading.Group.Messaging": "3. GROUP MESSAGING",
      "Admin.NewEdit.Note.Create.Messaging.Room":
        "Create a Messaging Room for this group?",
      "Admin.NewEdit.Heading.Privacy": "4. PRIVACY",
      "Admin.NewEdit.Title.Privacy": "Privacy",
      "Admin.NewEdit.Heading.Visibility": "5. VISIBILITY",
      "Admin.NewEdit.Note.Hide.Group": "Hide this group from non-member?",
      "Admin.NewEdit.Heading.Joinability": "6. JOINABILTY",
      "Admin.NewEdit.Note.Who.Can.Join": "Who can join this group?",
      "Admin.NewEdit.Title.Access": "Access",
      "Admin.NewEdit.Button.Save.Group": "Save Group",
      "Detail.Button.Close": "Close",
      "Detail.Heading.Settings": "Settings",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Button.Join": "Join {{name}}",
      "Detail.Button.Post.To.Community": "Post to your community",
      "GroupDetail.Tab.Chat": "Chat",
      "GroupDetail.Tab.Members": "Members",
      "GroupDetail.Tab.Info": "Info",
      "GroupDetail.Tab.Feed": "Feed",
      "Detail.Text.About.Group": "About this group",
      "Detail.Button.Group.Photos": "Group Photos",
      "Detail.Button.Group.Files": "Group Files",
      "Detail.Button.Leave": "Leave {{name}}",
      "GroupDetailModal.Heading": "Settings",
      "ListGroupsByStatus.Placeholder.Search": "Search",
      "ListGroupsByType.Title.My": "My",
      "ListGroupsByType.Title.More": "More",
      "ListGroupsByType.Title.Groups": "Groups",
      "ListGroupsByType.Title.All": "All",
      "ListGroupsByType.Title.Industries": "Industries",
      "ListGroupsByType.Placeholder.Search": "Search",
      "ListGroupsByStatus.GroupCard.PendingGroup": "Pending",
      "Mixed.Button.Post.To.Community": "Post to your community",
      "Mixed.Heading.My.Feed": "My Feed",
      "PostingGroups.Placeholder.Search": "Search",
      "Setting.Heading.Mute.Group": "Mute entire group",
      "Setting.Heading.Mobile.Notification": "Mobile Notification",
      "Setting.Heading.Desktop.Notification": "Desktop Notification",

      "No groups found": "No groups found",
    },
    Map: {
      "Container.Action.Message": "Missing bounds",
      "Index.Button.Close": "Close",
      "Index.Button.Back": "Back",
    },
    Media: {
      "Media.Crop.Image.Zoom": "Zoom:",
      "Media.Crop.Image.Save.New.Avatar.Button": "Save new avatar",
      "Container.Action.Message": "Please input a valid video link",
      "Index.Message.Not.Found.Media": "Not found media.",
      "Picker.Error.Camera.Not.Available": "Camera not available.",
      "Picker.Error.Cancel": "Cancel",
      "Picker.FallBack.General":
        "There was a problem processing your request. Please try again or contact help desk for support!",
      "Picker.Error.Photos.Not.Available": "Photos not available.",
      "Picker.FallBack.Video.Greater.Than":
        "Your video is greater than {{maxSize}}MB. Please choose another video or contact administrator for support!",
      "Picker.FallBack.File.Greater.Than":
        "Your file is greater than {{maxSize}}MB. Please choose another one or contact help desk for support!",
      "Provider.Placeholder.Search": "Search",
      "Provider.Button.Searching": "Searching",
      "Provider.Button.Search": "Search",
      "SearchImage.Button.Close": "Close",
      "SearchImage.Button.Search.Photo": "Gifs & Stock Photos",
      "SearchVideo.Button.Close": "Close",
      "SearchVideo.Button.Search.Video": "Search Video",
    },
    Member: {
      "Container.Admin.New.Error.1":
        "Please input a valid phone number in this format +12025555555",
      "Container.Admin.New.Error.2": "Please input a valid email address!",
      "Container.Admin.New.Callback": "Added new member successfully!",
      "Container.Action.Error": "User not found.",
      "Container.Detail.Callback.1":
        "This member is now added to your network! You’ll see their posts in your feed.",
      "Container.Detail.Callback.2":
        "This member is now removed from your network!",
      "Container.Index.Name": "Name",
      "Container.Index.Title": "Title",
      "Container.Index.Company": "Company",
      "Container.Index.Industry": "Industry",
      "Container.Index.Location": "Location",
      "Container.Index.Bio": "Biography",
      "Container.Index.Interests & Hobbies": "Interests & Hobbies",
      "Container.Index.Interests": "Interests & Hobbies",
      "Container.Index.Summary": "Summary",
      "Container.Mixed.Name": "Name",
      "Container.Mixed.Location": "Location",
      "Container.Mixed.Industry": "Industry",
      "Container.Mixed.Directory": "Directory",
      "Container.Mixed.Business.Map": "Business Map",
      "Admin.New.Validator.Required": "is required",
      "Admin.New.Validator.Invalid": "is invalid",
      "Admin.New.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.New.Button.Choose.Photo": "Choose Photo",
      "Admin.New.Heading.Details": "1. DETAILS",
      "Admin.New.Title.First.Name": "First name",
      "Admin.New.Hint.First.Name": "Write first name",
      "Admin.New.Title.Last.Name": "Last name",
      "Admin.New.Hint.Last.Name": "Write last name",
      "Admin.New.Title.Email": "Email",
      "Admin.New.Title.Phone.Number": "Phone number",
      "Admin.New.Placeholder.Phone.Number": "Phone number",
      "Admin.New.Placeholder.Required": " (required)",
      "Admin.New.Heading.Group.Memberships": "2. GROUP MEMBERSHIPS",
      "Admin.New.Title.Group.For.User": "Groups for user",
      "Admin.New.Hint.Select.Groups": "Select groups",
      "Admin.New.Heading.Setting": "3. SETTING",
      "Admin.New.Title.Send.Welcome.Email": "Send welcome email",
      "Admin.New.Title.Administrative.Access": "Administrative access",
      "Admin.New.Button.Add.Member": "Add Member",
      "Card.Title.Professional.Info": "Professional Info",
      "Card.Title.Member": "Member",
      "Detail.Title.User.Has.Not.Shared.Information":
        "The user has not shared any information",
      "Detail.Header.Button.Text": "Edit Profile",
      "Detail.Title.Professional.Info": "Professional Info",
      "Detail.Title.Personal.Info": "Personal Info",
      "Detail.Title.Executive.Assistant": "Executive Assistant",
      "Detail.Title.Social.Media": "Social Media",
      "Detail.Title.Google.Plus": "Google Plus",
      "Detail.Title.Company.Name": "Company Name",
      "Detail.Title.Phone.Other": "Phone (Other)",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Title.Company.History": "Company History",
      "Detail.Title.Summary": "Summary",
      "Detail.Title.Phone": "Phone",
      "Detail.Title.Location": "Location",
      "Detail.Title.Business Address": "Business Address",
      "Detail.Title.Bio": "Bio",
      "Detail.Title.Spouse": "Spouse",
      "Detail.Title.Children": "Children",
      "Detail.Title.Email": "Email",
      "Detail.Title.Birthday": "Birthday",
      "Detail.Title.Invalid.Date": "Invalid date",
      "Detail.Title.Home.Address": "Home Address",
      "Detail.Title.Joined": "Joined on", //interpolation?
      "Detail.Button.Loading": "Loading",
      "Detail.Button.Chat": "Chat",
      "Detail.Heading.Info": "Info",
      "Detail.Button.LoginAsGuest": "Login as user",
      "Detail.Heading.Someone.Post": "{{data.firstName}}'s Posts",
      "Detail.Heading.Posts": "Posts",
      "Detail.Heading.Groups": "Groups",
      "Detail.Text.Current": "current",
      "Detail.Heading.Professional.Information": "Professional Information",
      "Detail.Label.Name": "Name",
      "Detail.Label.Phone": "Phone",
      "Detail.Label.Email": "Email",
      "Detail.Heading.Personal.Information": "Personal Information",
      "Detail.Label.Spouse.Partner.Significant.Other":
        "Spouse/Partner/Significant Other",
      "Detail.Label.About.Me": "About Me",
      "Detail.Heading.Lets.Connect": "Let's Connect",
      "DetailBB.Title.User.Has.Not.Shared.Information":
        "The user has not shared any information",
      "DetailBB.Title.Personal.Info": "Personal Info",
      "DetailBB.Title.Professional.Info": "Professional Info",
      "DetailBB.Title.Social.Media": "Social Media",
      "DetailBB.Title.Google.Plus": "Google Plus",
      "DetailBB.Title.Company.Name": "Company Name",
      "DetailBB.Title.Phone.Other": "Phone (Other)",
      "DetailBB.Button.Ok": "$t(Common:Button.Ok)",
      "DetailBB.Button.Loading": "Loading",
      "DetailBB.Button.Chat": "Chat",
      "DetailBB.Heading.Someone.Post": "{{data.firstName}}'s Posts",
      "DetailBB.Heading.Groups": "Groups",
      "DetailBB.Heading.Lets.Connect": "Let's Connect",
      "DetailBB.Heading.Current.Company": "Current Company",
      "Group.Placeholder.Search": "Search",
      "GroupMemberList.Text.No.Members.Found": "No members found.",
      "GroupMemberList.Button.Load.More": "View more",
      "GroupMemberList.Placeholder.Search.Member": "Search member",
      "CollapsingMemberFilter.Select": "Select",
      "Index.Alert.Guest.Mode": "Quick view disabled in Guest Mode.",
      "Index.Text.Member": "Member",
      "Index.Text.Member.Plural": "Members",
      "Index.Text.My.Network": "My Network",
      "Index.Button.Cancel": "$t(Common:Button.Cancel)",
      "Index.Placeholder.Filter.By.Group.Type":
        "Filter by {{mainGroupType.name}}",
      "Index.Title.Filter.Members": "Filter Members",
      "Index.Title.Filter.Members.By.Text": "Filter by {{filterSecondaryText}}",
      "Index.Placeholder.Filter.By.Name": "Filter by {{item.name}}",
      "Index.Placeholder.Search.By.Location":
        "Search by location, name or keyword",
      "Index.Button.Clear.All.Filters": "Clear All Filters",
      "Index.Button.Apply": "Apply",
      "Index.Button.Filter.Members": "Filter members",
      "Index.Button.Reset": "Reset",
      "MembersList.Button.Load.More": "View more",
      "MembersListFilters.Button.Member": "Member",
      "MembersListFilters.Button.Map": "Map",
      "MembersListFilters.Button.Filter": "Filter",
      "MembersListFilters.Button.Clear.Filters": "Clear filters",
      "MembersPicker.Button.Close": "Close",
      "MembersPicker.Button.Save": "Save",
      "MembersPicker.Button.Choose.Member": "Choose Member",
      "MembersPicker.Button.Done": "Done",
      "MembersPicker.Placeholder.Search": "Search",
      "MembersPicker.Button.Load.More": "View more",
      "SocialMedia.Label.Instagram": "Instagram",
      "SocialMedia.Label.Instagram.Lower.Case": "instagram",
      //Profile keys
      "Alma Mater": "College/University",
      "Company History": "Company History",
      "Business Address": "Business Address",
      "From Date": "From Date",
      "To Date": "To Date",
      "Current Company": "Current Company",
      "Company Name": "Company Name",
      "Google Plus": "Google Plus",
      "Interests & Hobbies": "Interests & Hobbies",
      "Phone (Other)": "Phone (Other)",
      Name: "Name",
      Bio: "Bio",
      Birthday: "Birthday",
      Children: "Children",
      Email: "Email",
      Facebook: "Facebook",
      Industry: "Industry",
      Instagram: "Instagram",
      Linkedin: "Linkedin",
      Location: "Location",
      Phone: "Phone",
      Spouse: "Spouse",
      Summary: "Summary",
      Title: "Title",
      Twitter: "Twitter",
      Website: "Website",
    },
    Mention: {
      "Container.Action.Resources": "Resources",
      "Container.Action.Groups": "Groups",
      "Container.Action.Events": "Events",
      "Container.Action.Announcements": "Announcements",
    },
    MixedFeed: {
      "Index.Heading.Post": "Post to your community",
    },
    Modal: {
      "Invoice.Modal.Button.Payment": "Payment required",
      "Invoice.Modal.Details": "See Details",
    },
    Navbar: {
      "Navbar.SignIn": "Sign In",
      "Navbar.SignUp": "Sign Up",
      "Navbar.My.Profile": "My Profile",
      "Navbar.Edit.Profile": "Edit Profile",
      "Navbar.Change.Passcode": "Change Passcode",
      "Navbar.Notifications": "Notifications",
      "Navbar.Chat": "Chat",
      "Navbar.Search": "Search",
      "Navbar.More": "View More",
      "Navbar.Edit.Notifications": "Edit Notifications",
      "Navbar.Settings": "Settings",
      "Navbar.Subscription": "My Plans",
      "Navbar.Logout": "Logout",
      "Navbar.Admin.Panel": "Admin Panel",
      "Navbar.ViewAsGuest.Text":
        'You’re in "View As" mode.  Any actions you take will register for this user, such as RSVP, comments, etc.',
      "Navbar.ViewAsGuest.Exit": "Exit now!",
    },
    Notification: {
      "Admin.New.Less.Characters": " should be less than 120 characters",
      "Admin.New.Title.Required": " is required",
      "Admin.New.Toast.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.New.Heading.Photo": "Photo on In-App popup",
      "Admin.New.Heading.Button.Link": "Link for button on In-App popup",
      "Admin.New.Placeholder.Write.Link": "Write a link",
      "Admin.New.Placeholder.Write.Title.Link": "Write title of link",
      "Admin.New.Heading.Details": "1. Details",
      "Admin.New.Heading.Title": "Title",
      "Admin.New.Heading.Write.Title": "Write title",
      "Admin.New.Delivery": "Delivery methods",
      "Admin.New.Content": "Content",
      "Admin.New.Content.Hint": "Write content",
      "Admin.New.Heading.Audience": "2. Audience",
      "Admin.New.Heading.Notification": "Who can see this notification?",
      "Admin.New.Heading.Notification.Target": "Select target",
      "Admin.New.Heading.Notification.Groups": "Select groups",
      "Admin.New.Heading.Notification.Members": "Select members",
      "Admin.New.Notification.Target": "Target attendees who have responded.",
      "Admin.New.Notification.Select.Respond": "Select responded",
      "Admin.New.Notification.Receive":
        "Should only some of these members receive the notification?",
      "Admin.New.Notification.To": "Notifications to",
      "Admin.New.Heading.Schedule": "3. SCHEDULE",
      "Admin.New.Schedule.Notification": "Schedule notification",
      "Admin.New.Publish.Date": "Publish Date",
      "Admin.New.Publish.Time": "Publish Time",
      "Admin.New.Timezone": "Timezone",
      "Admin.New.Publish.Notification": "Publish Notification",
      "Index.Alert.Guest.Mode": "Can't see notifications in Guest Mode.",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Button.Unread.Notification": "No unread notifications",
      "Index.Button.Unread.Marking": "Marking all notifications read",
      "Index.Button.Mark": "Mark all",
      "Index.Button.Read.Notifications": " notifications read",
      "NotificationLanding.Button.Read.More": "Read more",
      "NotificationLanding.Button.Open.Twitter": "Open on Twitter",
      "NotificationCard.Button.View.Announcement": "View announcement",
      "NotificationCard.Button.View.Event": "View event",
      "NotificationCard.Button.View.Profile": "View profile",
      "NotificationCard.Button.View.Invoice": "View invoice",
      "NotificationCard.Button.View.Post": "View post",
      "NotificationCard.Button.View": "View",
      "NotificationCard.Button.View.Url": "View url",
      "NotificationCard.Button.View.Link": "View link",

      "Container.Index.Cleared": "Cleared",
      "Container.Index.Notification": " notification(s) successfully.",
      "Container.Admin.New.Notification":
        "Created new notification successfully!",
      "Container.Admin.New.Popup": "In-App Popup",
      "Container.Admin.New.Push.Notification": "Push Notification",
      "Container.Admin.New.All": "All",
      "Container.Admin.New.Logged.In.Members":
        "Only those members who have not logged in to the app",
      "Container.Admin.New.Specific.Member": "Specific Members",
      "Container.Admin.New.Specific.Groups": "Specific Groups",
      "Container.Admin.New.Yes": "Yes",
      "Container.Admin.New.No": "No",
      "Container.Admin.New.Not.Yet": "Not yet responded",
    },
    Onboarding: {
      "Index.Skip.Button": "Skip",
      "Slide.GetStarted.Button": "Get Started",
    },
    Payment: {
      "Detail.Toast.Export.Ready": "Export file ready to open.",
      "Detail.Toast.Text": "Paid your invoice successfully!",
      "Detail.Toast.Ok": "$t(Common:Button.Ok)",
      "Detail.Heading.Checks.Payable": "Make checks payable to",
      "Detail.Heading.Checks.Send": "Send checks to",
      "Detail.Heading.Checks.Send.By": "Send payment by",
      "Detail.Heading.Total": "Total",
      "Detail.Button.Paying": "Paying",
      "Detail.Button.Pay": "Pay",
      "Detail.Button.Paid": "Paid",
      "Detail.Button.By.Credit.Card": "By Credit Card",
      "Detail.Button.Return.To.Payments": "Back",
      "Detail.Button.Download.Invoice.Pdf": "Download Invoice",
      "Index.Heading.Invoices": "Invoices",
      "Index.Heading.Receipts": "Receipts",
      "Invoice.List.Not.Found": "No invoice found",
      "Pay.Error":
        "There was a problem with configuration. Please contact help desk.",
      "Pay.Fees": "Included fees",
      "Pay.Card.Heading": "I'm paying with",
      "Pay.Credit.Card": "Credit Card",
      "Pay.Credit.Card.Details": "Credit card details",
      "Pay.Check": "Check",
      "Pay.Pay": "Pay",
      "Pay.Paying": "Paying",
      "Pay.Heading.Pay.Instruction": "Payment instructions",
      "Pay.Heading.Checks.To": "Make checks payable to",
      "Pay.Heading.Checks.Send.To": "Send checks to",
      "Pay.Heading.Checks.Send.By": "Send payment by",
      "Pay.Checks.Confirm": "Confirm to send your payment!",
      "Pay.Checks.Confirming": "Confirming!",
      "Pay.Subscribe.Confirm": "Confirm",
      "Pay.Placeholder.Text.Card": "Card Number",
      "Pay.Placeholder.Text.Valid": "Valid Thru",
      "Pay.Placeholder.Text.Cvc": "CVC",
      "Receipt.List.Not.Found": "No receipt found",
      "Container.Action.Message": "Not found invoice.",
    },
    Plugin: {
      "Mentions.Placeholder.Write.Comments": "Write a comment...",
    },
    Post: {
      "Container.NewEdit.Error":
        "You don't have permission to post to this group.",
      "Container.NewEdit.Submit.Post": "Submitted your post successfully!",
      "Container.Index.Delete": "Deleted your post successfully!",
      "Container.Action.Messege": "Invalid data, missing group or user",
      "Container.Action.Messege.EmptyGroup":
        "Please select a group before posting.",
      "Container.Action.Messege.EmptyText":
        "Please add text/video/file before posting.",
      "Container.Action.Link": "Getting link ...",
      "Card.Toast.Text": "Copied text successfully!",
      "Card.Text.Attachments": "Attachments",
      "Card.Alert.Title": "Confirm Deletion",
      "Card.Alert.Text": "Do you want to delete this post?",
      "Card.Option.Edit": "Edit",
      "Card.Option.Remove": "Remove",
      "Card.Option.Cancel": "Cancel",
      "Card.Alert.Button.Ok": "$t(Common:Button.Ok)",
      "Card.Alert.Button.Cancel": "$t(Common:Button.Cancel)",
      "Card.Text.Shared.In": "Shared in",
      "Card.Button.Read.More": "Read more",
      "NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "NewEdit.Button.Close": "Close",
      "NewEdit.Heading.Choose.Group": "Choose Group",
      "NewEdit.Button.Update.Post": "Update Post",
      "NewEdit.Button.New.Post": "New post",
      "NewEdit.Button.Post": "Post",
      "NewEdit.Heading.Message": "Enter your message:",
      "NewEdit.Heading.Text": "Post in:",
      "NewEdit.Heading.Groups.Text": "Browse more groups",
      "NewEdit.Placeholder.Group.Post": "I would like to post",
      "NewEdit.Placeholder.Group.Choose":
        "Please choose your group before writing!",
      "NewEdit.Heading.Tag": "Tags",
      "NewEdit.PhotoOptions.TakePhoto": "Take photo",
      "NewEdit.PhotoOptions.Photo": "My photos",
      "NewEdit.PhotoOptions.Search": "Gifs & Stock Photos",
      "NewEdit.PhotoOptions.Cancel": "Cancel",
      "NewEdit.Tab.Video": "Video",
      "NewEdit.Tab.Photos.Or.Files": "Photos/Files",
      "NewEdit.VideoOptions.CaptureVideo": "Capture video",
      "NewEdit.VideoOptions.Video": "My video",
      "NewEdit.VideoOptions.Search": "Search video",
      "NewEdit.VideoOptions.AddLink": "Add Video Link",
      "NewEdit.VideoOptions.AddLink.Title": "Add video link",
      "NewEdit.VideoOptions.AddLink.Hint": "Youtube, Vimeo, etc...",
      "NewEdit.Remove.Video.Tip": "To add a video, remove the current one",

      "No posts found": "No posts found",
    },
    Resource: {
      "Index.Button.Heading.Text": "Video library",
      "ResourceDetail.Button.Back": "Back",
    },
    Routes: {
      "Testing Board": "Testing Board",
      "Account Settings": "Account Settings",
      "Profile Settings": "Profile Settings",
      Profile: "Profile",
      Announcement: "Announcement",
      Event: "Event",
      Attendee: "Attendee",
      Group: "Group",
      "Payment Instructions": "Payment Instructions",
      Resource: "Resource",
      Videos: "Videos",
      Video: "Video",
      Posting: "Posting",
      Post: "Post",
      "Search Groups": "Search Groups",
      Map: "Map",
      Comments: "Comments",
      "Reacted By": "Reacted By",
      "Seen By": "Seen By",
      "Group Photos": "Group Photos",
      "Group Files": "Group Files",
      Message: "Message",
      Change: "Change",
      Password: "Password",
      Passcode: "Passcode",
      "Bugs & Feedback": "Bugs & Feedback",
      Onboarding: "Onboarding",
      Modal: "Modal",
      Search: "Search",
      "Control Panel": "Control Panel",
      "Manage Announcements": "Manage Announcements",
      "New Announcement": "New Announcement",
      "Edit Announcement": "Edit Announcement",
      "Manage Events": "Manage Events",
      "New Event": "New Event",
      "Edit Event": "Edit Event",
      "Manage Groups": "Manage Groups",
      "New Group": "New Group",
      "Edit Group": "Edit Group",
      "New Member": "New Member",
      "New Notification": "New Notification",
      "Custom Theme": "Custom Theme",
      "Sign In": "Sign In",
      "Sign Up": "Sign Up",
      "Forgot Password": "Forgot Password",
      Support: "Support",
      "Pick Community": "Pick Community",
    },
    Search: {
      "Index.Search.Box.Text": "Search keyword or #tag",
      "Index.Button.Close": "Close",
      "Recent.Search.Heading": "Recent searches",
      "Container.Index.Announcement.News": "News",
      "Container.Index.Announcement.Events": "Events",
      "Container.Index.Announcement.Posts": "Posts",
    },
    Support: {
      "Feedback.Heading.Bugs.Feedback": "Bugs & Feedback",
      "Feedback.Placeholder.Issue.Type": "Choose your issue type",
      "Feedback.Placeholder.Description": "Enter description",
      "Feedback.Button.Submit": "Submit",
      "Feedback.Button.Loading": "Loading",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Placeholder.Email": "Enter your email",
      "Index.Placeholder.Phone": "Phone number",
      "Index.Placeholder.Name": "Enter your name",
      "Index.Placeholder.Issue.Type": "Choose your issue type",
      "Index.Placeholder.Description": "Enter description",
      "Index.Button.Loading": "Loading",
      "Index.Button.Submit": "Submit",
      "Container.Index.Feedback": "General Feedback",
      "Container.Index.Bug": "Report a Bug",
      "Container.Index.Suggestion": "Feature Suggestion",
      "Container.Index.Email": "I can't remember the email.",
      "Container.Index.Password": "I can't remember my password.",
      "Container.Index.Email.New": "I have a new email address.",
      "Container.Index.Feedback.Share": "I have feedback to share!",
      "Container.Index.Other": "Other",
      "Container.Index.Mobile": "I can't remember the mobile number.",
      "Container.Index.Pin": "I can't remember my PIN code.",
      "Container.Index.Mobile.Number": "I have a new mobile number.",
      "Container.Index.Error.Issue.Type": "Please input your issue type.",
      "Container.Index.Error.Issue.Description":
        "Please input your description.",
      "Container.Index.Feedback.Thanks":
        "Thanks for sharing feedback! We will get back to you as soon as possible.",
      "Container.Index.Email.Input": "Please input your email.",
      "Container.Index.Phone.Input": "Please input your phone.",
      "Container.Index.Name.Input": "Please input your name.",
      "Container.Index.Support.Thanks":
        "Thanks for contacting support! We will get back to you as soon as possible.",
      "SupportModal.Header.Text": "Support",
    },
    Tag: {
      "Tags.Placeholder.Input.Text": "Type the text and press enter",
    },
    TestingBoard: {
      "Index.Heading.Main.Features": "Main features",
      "Index.Heading.Home": "Home",
      "Index.Heading.Settings": "Settings",
      "Index.Heading.Notifications": "Notifications",
      "Index.Heading.News": "News",
      "Index.Heading.Events": "Events",
      "Index.Heading.Groups.Feed": "Groups Feed",
      "Index.Heading.Groups.List": "Groups List",
      "Index.Heading.Members": "Members",
      "Index.Heading.Chat.Room": "Chat room",
      "Index.Heading.Payments": "Payments",
      "Index.Heading.Resources": "Resources",
      "Index.Heading.Video.Library": "Video Library",
      "Index.Heading.Bugs.Feedback": "Bugs & Feedback",
      "Index.Heading.Admin": "Admin",
      "Index.Heading.Manage.Announcements": "Manage Announcements",
      "Index.Heading.Manage.Events": "Manage Events",
      "Index.Heading.Manage.Groups": "Manage Groups",
      "Index.Heading.New.Member": "New Member",
      "Index.Heading.New.Notification": "New Notification",
      "Index.Heading.Custom.Theme": "Custom Theme",
    },
    Theme: {
      "Index.Toast.Text.Reset": "Reset the theme successfully!",
      "Index.Toast.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Toast.Text.Save": "Saved the new theme successfully!",
      "Index.General": "General",
      "Index.General.Background.Color": "Background color",
      "Index.General.Updates": "'Updates' text color",
      "Index.General.Text.Color": "Text color",
      "Index.Navigation": "Navigation",
      "Index.Navigation.Background.Color": "Background color",
      "Index.Button": "Button",
      "Index.Button.Background.Color": "Background color",
      "Index.Tab": "Tab",
      "Index.Bottom.Tab.Color": "Bottom tab color",
      "Index.Directory.Tab.Color": "Tab color (Directory)",
      "Index.Comment.Like": "Comment/Like",
      "Index.Comment.Button": "Comment button",
      "Index.Like.Button": "Like button",
      "Index.Login.Register": "Login/Register",
      "Index.Login.Register.Button": "Button",
      "Index.Login.Register.Gradient.Color": "Gradient color",
      "Index.Events": "Events RSVP Buttons",
      "Index.Events.Rsvp": "RSVP",
      "Index.Events.Rsvp.Yes": "RSVP Yes",
      "Index.Events.Rsvp.No": "RSVP No",
      "Index.Events.Rsvp.Wait.List": "RSVP wait-list",
      "Index.Boarding": "On-boarding",
      "Index.Boarding.Top.Color": "Top color",
      "Index.Chat": "Chat",
      "Index.Chat.Background.Color": "Background color",
      "Index.Chat.Left.Bubble": "Left bubble",
      "Index.Chat.Right.Bubble": "Right bubble",
      "Index.Badge.Unread": "Badge unread",
      "Index.Badge.Unread.Background.Color": "Background color",
      "Index.Pinned.News": "Pinned news",
      "Index.Pinned.News.Background.Color": "Background color",
      "Index.Reset": "Reset to default",
      "Index.Button.Basic": "Basic",
      "Index.Button.Advanced": "Advanced",
      "Index.Save.Theme": "Save theme",
      "Container.Index.Warning":
        "Warning! You need to know stylesheet rules before editing these items, otherwise it causes a crashing. In that case to restore app you need to reset data in database.",
      "Container.Index.Alert": "Please use hex value only for color.",
    },
    UI: {
      "DragAndDrop.Text.Drop.Here": "Drop here",
      "DropDown.Placeholder": "Select one",
      "DropDown.Cancel": "$t(Common:Button.Cancel)",
      "Empty.No.Data": "No data found.",
      "Error.Title": "Uh oh",
      "Error.Link.Text": "Go Home",
      "Error.Content": "An unexpected error came up",
      "Foorer.Text": "",
      "LoadingBar.Text": "Loading",
      "Messages.Message": "An unexpected error came up",
      "ReadMore.See.More": "See more",
      "ReadMore.See.Less": "See less",
      "SearchBox.Placeholder": "Search",
      "SearchBox.Submit.Button.Text": "Search",
      "VideoProcessing.Video": "Processing video...",
      "VideoProcessing.Large.Video":
        "Video is a little large (>100MB) so it will take up to 30 minutes to process before being viewable.",
    },
    Uploading: {
      "Index.Uploading": "Uploading {{name}}....",
    },
    User: {
      "Change.Password.Button.Ok": "$t(Common:Button.Ok)",
      "Change.Password.Passcode.Current": "Enter current passcode (4 digits)",
      "Change.Password.Passcode.New": "Enter new passcode (4 digits)",
      "Change.Password.Passcode.Confirm": "Enter confirm passcode (4 digits)",
      "Change.Password.Current": "Enter current password (8+ chars)",
      "Change.Password.New": "Enter new password (8+ chars)",
      "Change.Password.Confirm": "Enter confirm password (8+ chars)",
      "Change.Password.Loading": "Loading",
      "Change.Password.Submit": "Submit",
      "Change.Password.Cancel": "Cancel",
      "Forgot.Password.Button.Ok": "$t(Common:Button.Ok)",
      "Forgot.Password.Heading":
        "Did you forget your password? \nPlease input your email to receive a recovery email.",
      "Forgot.Password.Email.Address": "Email address",
      "Forgot.Password.Loading": "Loading",
      "Forgot.Password.Submit": "Submit",
      "Login.Toast.Button.Ok": "$t(Common:Button.Ok)",
      "Login.Email": "Email",
      "Login.Phone": "phone",
      "Login.Phone.Number": "Phone number",
      "Login.Heading.Please": "Please use your",
      "Login.Heading.To.Login": "to login.",
      "Login.Heading.And": "and",
      "Login.Heading.Phone": "phone",
      "Login.Placeholder.Email": "Email address",
      "Login.Button.Loading": "Loading",
      "Login.Button.Sign.In": "Sign in",
      "Login.Account": "Don't have an account?",
      "Login.Button.Sign.Up": "Sign up",
      "Login.Forgot.Password": "Forgot password!",
      "Login.Forgot.New.Passcode": "Request new passcode",
      "Login.Choose.Another.Community": "Choose another community",
      "Login.Sign.Up": "I'm new, sign me up",
      "Login.Contact.Support": "Contact Support",
      "Login.Page.Title": "Login",
      "Register.Toast.Ok": "$t(Common:Button.Ok)",
      "Register.Heading.Text": "Enter login details to complete registration",
      "Register.Placeholder.First.Name": "Enter first name (required)",
      "Register.Label.First.Name": "First Name",
      "Register.Placeholder.Last.Name": "Enter last name (required)",
      "Register.Label.Last.Name": "Last Name",
      "Register.Placeholder.Enter.Phone": "Enter phone number",
      "Register.Label.Phone": "Phone Number",
      "Register.Placeholder.Required": " (required)",
      "Register.Placeholder.Enter.Email": "Enter email address",
      "Register.Label.Email": "Email",
      "Register.Placeholder.Password": "Enter password (8+ chars) (required)",
      "Register.Label.Password": "Password",
      "Register.Placeholder.Password.Reenter":
        "Re-enter password (8+ chars) (required)",
      "Register.Label.Password.Confirm": "Confirm Password",
      "Register.Button.Loading": "Loading",
      "Register.Button.Signup": "Sign Up",
      "Register.Account.Exists": "Already have an account?",
      "Register.Account.Login": "Login",
      "Setting.Toast.Copy": "Copied text successfully!",
      "Setting.Toast.Ok": "$t(Common:Button.Ok)",
      "Setting.Account.Settings": "Account Settings",
      "Setting.Heading.Subscription": "Plans",
      "Setting.Heading.Subscription.Change": "My plans",
      "Setting.Heading.Notifications": "Notifications",
      "Setting.Heading.Push.Notifications": "Send me push notifications",
      "Setting.Heading.Email.Notifications":
        "Send me email notifications for group posts ",
      "Setting.Heading.Email.Notifications.Posts":
        "When you're busy or not online, we can send you email notifications for any new messages",
      "Setting.Heading.Notifications.Message":
        "Send me email notifications for messages",
      "Setting.Heading.Media": "Media",
      "Setting.Heading.App": "App Support",
      "Setting.Heading.About": "About This App",
      "Setting.Heading.Version": "App Version",
      "Setting.Sign.Out": "Sign out",
      "Update.Profile.Avatar.Crop.Modal.Heading": "Edit your avatar",
      "Update.Profile.Personal.Info.Alma.Mater": "College/University",
      "Update.Profile.Personal.Info.Birthday": "Birthday",
      "Update.Profile.Personal.Info.Email": "Email",
      "Update.Profile.Personal.Info.Website": "Website",
      "Update.Profile.Personal.Info.Invalid.Email": "Email is invalid",
      "Update.Profile.Personal.Info.Location": "Location",
      "Update.Profile.Personal.Info.Phone": "Phone",
      "Update.Profile.Personal.Info.Invalid.Phone":
        "Please type valid phone number (+12025555555)",
      "Update.Profile.Personal.Info.Phone.Other": "Phone (Other)",
      "Update.Profile.Personal.Info.Bio": "Bio",
      "Update.Profile.Personal.Info.Interests.And.Hobbies":
        "Interests & Hobbies",
      "Update.Profile.Personal.Info.Children": "Children",
      "Update.Profile.Personal.Info.Child.Name": "Name",
      "Update.Profile.Personal.Info.Child.Birthday": "Birthday",
      "Update.Profile.Personal.Info.Spouse":
        "Spouse/ Partner/ Significant other",
      "Update.Profile.Professional.Info.Title": "Title",
      "Update.Profile.Professional.Info.Summary": "Summary",
      "Update.Profile.Professional.Info.Company.Name": "Company Name",
      "Update.Profile.Professional.Info.Location": "Location",
      "Update.Profile.Professional.Info.Search.Location": "Search Location",
      "Update.Profile.Professional.Info.Phone": "Phone",
      "Update.Profile.Professional.Info.Website": "Website",
      "Update.Profile.Professional.Info.Invalid.Website": "Website is invalid",
      "Update.Profile.Professional.Info.Industry": "Industry",
      "Update.Profile.Professional.Info.Region": "Region",
      "Update.Profile.Professional.Info.Company.History": "Company History",
      "Update.Profile.Professional.Info.Company.History.Company": "Company",
      "Update.Profile.Professional.Info.Company.History.Name": "Name",
      "Update.Profile.Professional.Info.Company.History.From": "From",
      "Update.Profile.Professional.Info.Company.History.To": "To",
      "Update.Profile.Professional.Info.Company.History.Current.Company":
        "Current Company",
      "Update.Profile.Executive.Info.Name": "Name",
      "Update.Profile.Executive.Info.Phone": "Phone",
      "Update.Profile.Executive.Info.Email": "Email",
      "Update.Profile.Executive.Info.Invalid.Email": "Email is invalid",
      "Update.Profile.Social.Media.Facebook": "Facebook",
      "Update.Profile.Social.Media.Linkedin": "LinkedIn",
      "Update.Profile.Social.Media.Instagram": "Instagram",
      "Update.Profile.Social.Media.Twitter": "Twitter",
      "Update.Profile.Social.Media.Google.Plus": "Google Plus",
      "Update.Profile.Social.Media.Type.Your.Username.Only":
        "Type your username only",
      "Update.Profile.Button.Ok": "$t(Common:Button.Ok)",
      "Update.Profile.Hint": "Type your username only",
      "Update.Profile.Toast.Update": "Updated your profile successfully!",
      "Update.Profile.Toast.Fill": "Please fill all required fields!",
      "Update.Profile.Heading.Partner": "Spouse/Partner/Significant Other",
      "Update.Profile.Button.Saving": "Saving",
      "Update.Profile.Button.Save": "Save",
      "Update.Profile.Validator.Title": " is required",
      "Update.Profile.Validator.Title.Invalid": " is invalid",
      "Update.Profile.Validator.Title.Input": "Please type valid ",
      "Update.Profile.Validator.Title.Input.Number": "(+12025555555)",
      "Update.Profile.Title.Company": "Company",
      "Update.Profile.Title.From": "From",
      "Update.Profile.Title.To": "To",
      "Update.Profile.Title.Current.Company": "Current Company",
      "Update.Profile.Successfully":
        "You've successfully signed up for the {{organizationName}} application.",
      "Update.Profile.User.Not.Activated": "User isn't activated.",
      "Setting.ExportData": "Export Data",
      "Setting.ExportData.Ready": "Export file ready to open",
      "Setting.ExportData.Members": "Members",
      "Setting.Account": "Account",
      "Setting.Edit.Profile": "Edit Profile",
      "Setting.Change.Passcode": "Change Passcode",
      "Setting.Heading.Change": "Change",
      "Setting.Password": "Password",
      "Setting.Passcode": "Passcode",
      "Setting.Auto.Play": "Auto play video on feed",
      "Container.Index.Option.1": "All posts and activity",
      "Container.Index.Option.2": "Only when my posts are commented on",
      "Container.Index.Option.3":
        "Receive push notifications only for @mentions",
      "Container.Index.Option.4": "Once every 10 minutes",
      "Container.Index.Option.5": "Once every hour",
      "Container.Index.Option.Daily": "Daily",
      "Container.Index.Option.Never": "Never",
      "Container.Index.Option.Weekly": "Weekly",
      "Container.Index.Option.Always": "Always",
      "Container.UpdateProfile.State.Info":
        "The organization administrator is verifying your membership and you will be notified when this has been completed. \nIn the meantime please fill out your profile.",
      "Container.Update.Profile.Template.Info": "Personal Info",
      "Container.Register.First.Name": "firstName",
      "Container.Register.Successfully":
        "You've successfully signed up for the {{setting.organizationName}} application.",
      "Container.Register.Error": "Signing up is temporarily blocked.",
      "Container.Register.Password": "Password",
      "Container.Register.Passcode": "Passcode",
      "Container.Login.Password": "Password",
      "Container.Login.Passcode": "Passcode",
      "Container.Login.Error.Phone.Email": "Please input your phone/email.",
      "Container.Login.Error.Phone": "Please input your phone number.",
      "Container.Login.Error.Phone.Correct":
        "Please input a valid phone number.",
      "Container.Login.Error.Email.Correct": "Please input a valid email.",
      "Container.Login.Sms": "An SMS with your new passcode has been sent.",
      "Container.Login.Email": "Please input your email.",
      "Container.Login.Email.Correct": "Please input a valid email",
      "Container.Login.Email.Recovery":
        "Please look at the recovery email and follow the instructions.",
      "Container.Login.Error.All.Fields": "Please input all fields.",
      "Container.Login.Error.Correct.Password":
        "Please correct your password (8+ chars)",
      "Container.Login.Error.Confirm.Password":
        "Please correct your confirm password",
      "Container.Login.Error.Correct.Passcode":
        "Please correct your passcode (4 digits)",
      "Container.Login.Error.Confirm.Passcode":
        "Please correct your confirm passcode",
      "Container.Login.Update": "Updated your ",
      "Container.Login.Successful": "successfully!",
      "Crop.Image.Avatar.Modal.Header": "Change your avatar",
    },
    VideoLibrary: { "VideoDetail.Alert.Text": "Video not found!" },
    Subscription: {
      "Product.Description": "Description:",
      "Product.Prices": "Options:",
      "Product.Subscribe": "Press to subscribe",
      "Subscription.Canceling": "Ending",
      "Subscription.Plan": "Plan:",
      "Subscription.Description": "Description:",
      "Subscription.Price": "Option:",
      "Subscription.Resume": "Resume Subscription",
      "Subscription.End": "End Subscription",
      "Subscription.PaymentMethod.Update": "Update Payment Method",
      "Subscription.MyPlans": "My Plans",
      "Subscription.Exists.Alert.Title": "Subscription Alert",
      "Subscription.Exists.Alert.Description":
        'You are already subscribed to "{{plan}}". Do you want to continue?',
    },
    WorkSpace: {
      "Index.Heading.Select": "Select your community to continue",
      "Index.Label.Community.Name": "Community name",
      "Index.Heading.Phone":
        "Please enter phone number or email address to continue",
      "Index.Placeholder.Phone.Email": "Enter phone or email",
      "Index.Button.Loading": "Loading",
      "Index.Button.Find": "Find Community",
      "Index.Button.Existing.Community": "Sign in to exsisting community",
      "Index.Button.Next.Step": "Next",
      "Index.Heading.Account": "Don't have an account?",
      "Index.Sign.Up": "Sign up",
      "Index.Heading.Community": "Please enter your community to continue",
      "Index.Placeholder.Community": "Enter community name",
      "Index.Button.Next": "Next",
      "Index.Heading.Already.Account": "Already have an account?",
      "Index.Button.Sign.In": "Sign in",
      "Index.Enter.Phone.Number.Info": "Phone number must include country code",
      "Index.Page.Title": "Pick Community",
      "Container.Index.Error":
        "Please input your phone number or email address.",
      "Container.Action.Message": "Community not found.",
      "Container.Action.Message.Not.Found": "No community found for",
      "Container.Action.Message.Not.Provided": "Username not provided",
    },
    More: {
      "More.Heading.Payments": "Payments",
      "More.Heading.Resources": "Resources",
      "More.Heading.Video": "Video Library",
      "More.Heading.Control.Panel": "Control Panel",
      "More.Heading.Introducing": "App Tour",
      "More.Heading.Report": "Report bugs & feedback",
      "More.Heading.Help.Desk": "Help Desk",
      "More.Heading.Account": "Account Settings",
    },
    Common: {
      "Button.Ok": "OK",
      "Button.Cancel": "Cancel",
      "Button.Close": "Close",
      "Button.Retry": "Retry",
      "Button.Yes": "Yes",
    },
  },
  fr: {
    App: {
      "Index.Toast.Internet.Title": "Pas de connexion internet.",
      "Index.Alert.Internet.Title": "Vérifiez votre connexion internet !",
      "Index.Alert.Internet.Text":
        "Appuyez sur OK pour relancer l'application !",
      "Index.Alert.Server.Title": "N'a pas pu être connecté au serveur.",
      "Index.Alert.Server.Text":
        "Merci d'essayer ultérieurement ou contactez votre administrateur.",
      "Index.Alert.Button.OK": "$t(Common:Button.Ok)",
      "Index.Alert.Button.Retry": "$t(Common:Button.Retry)",
      "Index.Alert.Button.Ignore": "Ignorer",
      "Index.Alert.Button.Reset": "Réinitialiser l'application",
      "Index.Alert.Button.Wait": "Attendez",
      "Index.Alert.Button.Close": "$t(Common:Button.Close)",
      "Index.Alert.Crash.Title": "Une erreur inattendue s'est produite.",
      "Index.Alert.Crash.Text":
        "Merci d'essayer ces solutions et contactez-nous si elles ne fonctionnent pas dans la résolution de votre problème ! \nNous ferons de notre possible pour régler ce problème au plus vite. \nNous vous remercions de votre patience.",
      "App.Update.Text.Force":
        "Nous avons changé, en fait nous nous sommes améliorés ! \nTéléchargez la dernière version depuis l'App Store pour voir toutes les nouveautés.",
      "App.Update.Text.1": "Installation en cours - Nouveauté.",
      "App.Update.Text.2": "2-4-6-8 Cette application est maintenant à jour.",
      "App.Update.Text.3": "Ch-Ch-Changements !",
      "App.Update.Failed":
        "Échec de la mise à jour de l’application. Essayez-le plus tard.",
      "App.Button.Download": "Téléchargez maintenant !",
      "App.ViewAsGuest.Text":
        'Vous êtes dans le mode "Voir en tant que". Chaque action que vous allez réaliser sera enregistrée pour cet utilisateur, telles que RSVP, commentaires, etc.',
      "App.ViewAsGuest.Exit": "Quitter maintenant !",
      "App.ViewAsGuest.Toast":
        'Sortir du mode "Voir en tant que" et retourner sur votre compte...',
    },
    Admin: {
      "Index.Heading.Announcement": "Annonces",
      "Index.Note.Announcement": "Publications/Brouillons",
      "Index.Heading.Events": "Evènements",
      "Index.Note.Events": "Publications/Brouillons",
      "Index.Heading.Add.Member": "Ajouter un membre",
      "Index.Note.Add.Member": "Ajouter Nouveau",
      "Index.Heading.Groups": "Groupes",
      "Index.Note.Groups": "Créer & Gérer",
      "Index.Heading.Communication": "Communication",
      "Index.Note.Communication": "SMS/E-mail/Push/Pop-up",
      "Index.Heading.Custom.Theme": "Thème personnalisé",
      "Index.Note.Custom.Theme": "Basique/Avancé",
    },
    Announcement: {
      "Container.Admin.Draft.Callback":
        "L'annonce a été supprimée avec succès !",
      "Container.Admin.NewEdit.Callback.Submitted.Successfully":
        "Votre annonce a été soumise avec succès !",
      "Container.Admin.NewEdit.Callback.Deleted.Successfully":
        "L'annonce a été supprimée avec succès !",
      "Container.Admin.NewEdit.Option.App.Popup": "Pop-up dans l'application",
      "Container.Admin.NewEdit.Option.Push": "Envoyer une notification push",
      "Container.Admin.NewEdit.Option.Sms": "SMS",
      "Container.Admin.NewEdit.Option.Email": "E-mail",
      "Container.Admin.NewEdit.Option.All.Targeted.Members":
        "Tous les membres sélectionnés",
      "Container.Admin.NewEdit.Option.Only.Members":
        "Uniquement les membres sans l'application (qui ne peuvent recevoir une notification push)",
      "Container.Admin.NewEdit.Option.Text": "Texte",
      "Container.Admin.NewEdit.Option.URL": "Lien",
      "Container.Admin.NewEdit.Option.Everyone": "Tout le monde",
      "Container.Admin.NewEdit.Option.Specific.Groups": "Groupes spécifiques",
      "Container.Admin.Published.Callback":
        "L'annonce a été supprimée avec succès !",
      "Admin.Card.Title.Confirm.Deletion": "Confirmer la suppression",
      "Admin.Card.Message.Confirm.Deletion":
        "Souhaitez-vous supprimer cette annonce ?",
      "Admin.Card.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Card.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.Card.Title.Event": "Evènement",
      "Admin.Card.Title.Announcement": "Annonce",
      "Admin.Card.Note.Saved.On": "Sauvegardé sur :",
      "Admin.Card.Note.Published.On": "Publié sur :",
      "Admin.Card.Label.Title.Event": "Evènement",
      "Admin.Card.Note.Audience": "Audience: {{target}}",
      "Admin.Card.Option.Edit": "Modifier",
      "Admin.Card.Option.Remove": "Retirer",
      "Admin.Card.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Index.Heading.Publishing": "Publication en cours",
      "Admin.Index.Heading.New.Announcement": "Nouvelle Annonce",
      "Admin.Index.Heading.Draft": "Brouillon",
      "Admin.Index.Title.New": "Nouveau",
      "Admin.NewEdit.Validator": "est requis",
      "Admin.NewEdit.Validator.Multiple": "est requis",
      "Admin.NewEdit.Validator.Length": "doit être inférieur à 120 caractères",
      "Admin.NewEdit.Option.Photo": "Mes Photos",
      "Admin.NewEdit.Option.Video": "Mes Vidéos",
      "Admin.NewEdit.Option.Search.Photo": "Gifs & Stock de Photos",
      "Admin.NewEdit.Option.Search.Video": "Rechercher une vidéo",
      "Admin.NewEdit.Option.Add.Video": "Ajouter un lien vidéo",
      "Admin.NewEdit.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.NewEdit.Title.Add.Video": "Ajouter un lien vidéo",
      "Admin.NewEdit.Hint.Add.Video": "YouTube, Vimeo, etc...",
      "Admin.NewEdit.Title.Confirm.Deletion": "Confirmer la suppression",
      "Admin.NewEdit.Message.Confirm.Deletion":
        "Souhaitez-vous supprimer cette annonce ?",
      "Admin.NewEdit.Button.Choose.Photo.Video": "Choisir une Photo/Vidéo",
      "Admin.NewEdit.Heading.Details": "1. DETAILS",
      "Admin.NewEdit.Title.Title": "Titre",
      "Admin.NewEdit.Hint.Title": "Ecrire un titre",
      "Admin.NewEdit.Title.Sub.Header": "Sous-titre",
      "Admin.NewEdit.Hint.Sub.Header": "Ecrire un sous-titre",
      "Admin.NewEdit.Title.Category": "Catégorie",
      "Admin.NewEdit.Hint.Category": "Sélectionner une catégorie",
      "Admin.NewEdit.Title.Author": "Auteur",
      "Admin.NewEdit.Hint.Author": "Sélectionner un auteur",
      "Admin.NewEdit.Heading.Announcement": "2. TYPE D'ANNONCE",
      "Admin.NewEdit.Title.Type": "Type",
      "Admin.NewEdit.Hint.Type": "Sélectionner un type",
      "Admin.NewEdit.Title.Body.Text": "Corps du texte",
      "Admin.NewEdit.Hint.Body.Text": "Ecrivez quelque chose",
      "Admin.NewEdit.Title.Url": "Lien",
      "Admin.NewEdit.Hint.Url": "Ecrivez le lien",
      "Admin.NewEdit.Title.Attachments": "Pièces-jointes",
      "Admin.NewEdit.Button.Add": "Ajouter",
      "Admin.NewEdit.Heading.Audience": "3. AUDIENCE",
      "Admin.NewEdit.Note.Target":
        "Qui est l'audience cible de cette annonce ?",
      "Admin.NewEdit.Title.Target": "Sélectionner une cible",
      "Admin.NewEdit.Note.Groups":
        "Sélectionner les groupes qui peuvent voir cette annonce.",
      "Admin.NewEdit.Title.Groups": "Sélectionner des groupes",
      "Admin.NewEdit.Hint.Groups": "Sélectionner des groupes",
      "Admin.NewEdit.Heading.Notification": "4. NOTIFICATIONS",
      "Admin.NewEdit.Info.Notification":
        "Aucune notification n'a été envoyée pour cette annonce. Une nouvelle notification peut être envoyée en faisant une sélection ci-dessous.",
      "Admin.NewEdit.Info.Notification.Sent":
        "Une notification push a été envoyée le {{data.lastNotifyAt}}. Une nouvelle notification peut être envoyée en faisant une sélection ci-dessous.",
      "Admin.NewEdit.Title.Channels": "Canal",
      "Admin.NewEdit.Hint.Channels": "Sélectionner un canal",
      "Admin.NewEdit.Note.Notification":
        "Envoyer un E-mail/SMS de notification à.",
      "Admin.NewEdit.Title.Notification": "Envoyer des notifications à",
      "Admin.NewEdit.Heading.Schedule": "5. PROGRAMME",
      "Admin.NewEdit.Note.Schedule":
        "Programmer cette annonce pour une publication ultérieure",
      "Admin.NewEdit.Title.Schedule": "Programmer",
      "Admin.NewEdit.Title.Publish.Date": "Date de publication",
      "Admin.NewEdit.Title.Publish.Time": "Heure de publication",
      "Admin.NewEdit.Title.Timezone": "Fuseau horaire",
      "Admin.NewEdit.Button.Publish": "Publier une annonce",
      "Admin.NewEdit.Button.Publish.Update":
        "Publier une mise à jour d'annonce",
      "Admin.NewEdit.Button.Update.Draft": "Mettre à jour le Brouillon",
      "Admin.NewEdit.Button.Save.Draft": "Enregistrer en tant que Brouillon",
      "Admin.NewEdit.Button.Delete.Announcement": "Supprimer l'annonce",
      "Card.Header.Title.Video": "Annonce Vidéo",
      "Card.Header.Title.Announcement": "Annonce",
      "Card.Header.Title.Event": "Annonce d'un évènement",
      "Card.Button.Read.More": "Lire plus",
      "Card.Button.See.Recap": "Voir le récapitulatif",
      "Card.Text.Posted.By": "Posté par",
      "Detail.Toast.Text": "Texte copié avec succès !",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Heading.Attachments.Text": "Pièces-jointes",
      "Index.Member.Name": "Membre",
      "Index.Heading.Updates": "Mises à jour",
      "Pins.Pin.Title": "Epinglé {{setting.announcementLabel}}",
      "Pins.Pin.Title.Announcement": "Annonce",
      "Pins.Button.View.Announcement": "Voir l'annonce",

      "No Announcement found": "Aucune annonce trouvée",
    },
    Chat: {
      "Container.Action.Message.Start.Chat": "Démarrer une discussion",
      "Container.Action.Author.You": "Vous : ",
      "Container.Action.Text.Sent": "Envoyé",
      "Container.Action.Title.New.Message": "Vous avez un nouveau message.",
      "Container.Action.Typing.Singular": "est en train d'écrire...",
      "Container.Action.Typing.Plural": "sont en train d'écrire...",
      "Container.Action.People.Typing":
        "Quelques personnes sont en train d'écrire ...",
      "Container.Action.Reject.1":
        "Certains utilisateurs ne sont pas disponibles maintenant. Merci de réessayer ultérieurement !",
      "Container.Action.Reject.2":
        "Il y a eu un problème dans le traitement de votre demande. Merci de réessayer.",
      "Container.Action.Reject.3": "Canal non trouvé.",
      "Container.Action.Reject.4":
        "Ce groupe de conversation n'est pas visible par vous.",
      "Container.Index.Callback.Update.Settings":
        "Mise à jour des paramètres du chat réalisée avec succès !",
      "Container.Index.Callback.Delete":
        "Le message a été supprimé avec succès !",
      "Container.Index.Callback.Update.Chat.Name":
        "Le nom de la conversation de groupe a été mis à jour avec succès !",
      "Container.Index.Callback.Added.To.Favourite":
        "Ajouté à la liste de favoris avec succès !",
      "Container.Index.Callback.Removed.From.Favourite":
        "Supprimé de la liste de favoris avec succès !",
      "Container.Settings.Option.All.Messages": "Tous les nouveaux messages",
      "Container.Settings.Option.Just.Mentions": "Uniquement les mentions",
      "Container.Settings.Option.Nothing": "Aucun",
      "Container.Setting.Callback.Update.Chat.Name":
        "Le nom de la conversation de groupe a été mis à jour avec succès !",
      "Card.Button.Ok": "$t(Common:Button.Ok)",
      "Card.Title.Confirm.Deletion": "Confirmer la Suppression",
      "Card.Message.Confirm.Deletion": "Souhaitez-vous supprimer ce message ?",
      "Card.Button.Deletion.Cancel": "$t(Common:Button.Cancel)",
      "Card.Chat.Name.Change": "Modifier le nom de la conversation de groupe.",
      "Card.Chat.New.Name": "Ecrivez le nouveau nom",
      "ConversationEditMessage.Button.Cancel": "Cancel",
      "ConversationEditMessage.Button.Save": "Sauvegarder",
      "Detail.Toast.Text": "Le texte a été copié avec succès !",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Message.Action.Copy": "Copier le texte",
      "Detail.Message.Action.Edit": "Modifer",
      "Detail.Message.Action.Delete": "Supprimer",
      "Detail.Message.Action.Cancel": "$t(Common:Button.Cancel)",
      "Index.Member.Name": "Membre",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Option.Remove": "Retirer",
      "Index.Option.Cancel": "$t(Common:Button.Cancel)",
      "Index.Toast.Open.Chat": "Démarrer la conversation...",
      "Index.Alert.Guest.Mode": "Impossible de voir le chat en Mode Invité.",
      "ListChat.Placeholder.Search.Box": "Rechercher une conversation",
      "ListChat.Info":
        "Démarrer une conversation en utilisant le bouton plus ci-dessous. Vous pouvez également ajouter un contact en favori en utilisant les boutons vides au-dessus.",
      "Mixed.Heading.Chat": "Conversation",
      "Mixed.Heading.Event": "Evènement",
      "Mixed.Heading.Feed": "Fil",
      "Mixed.Heading.Image": "Image",
      "Mixed.Heading.File": "Dossier",
      "Mixed.Heading.Members": "Membres",
      "Mixed.Button.Close": "Fermer",
      "Mixed.Heading.Settings": "Paramètres",
      "Mixed.Post.To.Community": "Poster à votre communauté",
      "Settings.Button.Ok": "$t(Common:Button.Ok)",
      "Settings.Button.Cancel": "$t(Common:Button.Cancel)",
      "Settings.Toast.Leave.Chat": "Le chat a été quitté avec succès !",
      "Settings.Toast.Delete.Message":
        "Le message a été supprimé avec succès !", //I18N C'est pour le chat/le canal, ce n'est pas un message
      "Settings.Toast.Add.Member": "Les membres ont été ajoutés avec succès !",
      "Settings.Toast.Remove.Member":
        "Les membres ont été retirés avec succès !",
      "Settings.Title.Change.Group.Name":
        "Modifier le nom de la conversation de groupe.",
      "Settings.Placeholder.New.Name": "Ecrivez le nouveau nom",
      "Settings.Title.Messages": "Messages",
      "Settings.Option.Add.Members": "Ajouter des membres",
      "Settings.Option.Remove.Members": "Retirer des membres",
      "Settings.Option.Edit.Chat.Name": "Modifier le nom du chat",
      "Settings.Option.Leave": "Partir",
      "Settings.Option.Delete": "Supprimer",
      "Settings.Title.Notifications": "Notifications",
      "Settings.Option.Mute.Channel": "Mettre en sourdine tout le canal",
      "Settings.Ignore.Notification":
        "Ignorer les notifications pour @canal et @ici",
      "Settings.Mobile.Notification": "Notification Mobile",
      "Settings.Desktop.Notification": "Notification sur le bureau",
    },
    Comment: {
      "Bar.Hint.Comment": "Commenter",
      "Bar.Title.Comment": "Commenter",
      "Bar.Button.Like": "Aimer",
      "Bar.Button.Liked": "Aimé",
      "Card.Button.Edit": "Modifier le commentaire",
      "Card.Button.Delete": "Supprimer le commentaire",
      "Index.Toast.Text": "Le texte a été copié avec succès",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Action.Copy": "Copier le texte",
      "Index.Action.Edit": "Modifier",
      "Index.Action.Delete": "Supprimer",
      "Index.Action.Cancel": "$t(Common:Button.Cancel)",
      "Index.Button.More.Comments": "Voir plus de commentaires",
      "Input.Button.Ok": "$t(Common:Button.Ok)",
      "Input.Placeholder.Message": "Ecrire la légende ici",
      "Input.Button.Upload": "Télécharger",
      "Input.Button.Send": "Envoyer",
      "Input.Placeholder.Comment": "Ecrire votre commentaire ici",
      "Input.Button.Cancel": "$t(Common:Button.Cancel)",
      "Input.Button.Save": "Sauvegarder",
      "Input.Button.Edit.Send": "Envoyer",
      "List.Button.Load.More": "Voir plus",
      "List.Button.Hide.Comments": "Masquer les commentaires",
      "Reaction.Seen.By": "Vu par",
    },
    Event: {
      "Container.Admin.Draft.Callback.Deleted":
        "L'évènement a été supprimé avec succès !",
      "Container.Admin.NewEdit.Callback.Submitted":
        "Votre évènement a été soumis avec succès !",
      "Container.Admin.NewEdit.Callback.Deleted":
        "Votre évènement a été supprimé avec succès !",
      "Container.Admin.NewEdit.Option.App.Popup": "Pop-up dans l'application",
      "Container.Admin.NewEdit.Option.Push": "Notification push",
      "Container.Admin.NewEdit.Option.Sms": "SMS",
      "Container.Admin.NewEdit.Option.Email": "E-mail",
      "Container.Admin.NewEdit.Option.All.Targeted.Members":
        "Tous les membres ciblés",
      "Container.Admin.NewEdit.Option.Only.Members":
        "Uniquement les membres sans l'application (qui ne peuvent recevoir une notification push)",
      "Container.Admin.NewEdit.Option.Everyone": "Tout le monde",
      "Container.Admin.NewEdit.Option.Specific.Groups": "Groupes spécifiques",
      "Container.Admin.NewEdit.Option.Expires.In":
        "Expire 24 heures après la fin de l'évènement",
      "Container.Admin.NewEdit.Option.Expires.On":
        "Expire à une date/heure spécifique",
      "Container.Admin.NewEdit.Option.Never.Expires": "N'expire jamais",
      "Container.Admin.Published.Callback.Deleted":
        "Evènement supprimé avec succès !",
      "Container.Action.Message.No.More.Registration":
        "Plus aucune inscription n'est acceptée pour cet évènement.",
      "Container.Action.Message.Added.As.Guest":
        "Vous avez été ajouté(e) comme invité(e), donc vous ne pouvez modifier l'information.",
      "Container.Detail.Note.Rsvp.Changed": "RSVP a été modifié par",
      "Container.Detail.Note.Rsvp.Added": "RSVP a été ajouté par",
      "Container.Detail.Error.Card.Declined":
        "Votre carte de crédit a été refusée. Veuillez en essayer une autre.",
      "Admin.Card.Title.Confirm.Deletion": "Confirmer la Suppression",
      "Admin.Card.Message.Confirm.Deletion":
        "Souhaitez-vous supprimer cet évènement ?",
      "Admin.Card.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Card.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.Card.Title.Event": "Evènement",
      "Admin.Card.Note.Event.Date": "Date de l'évènement: {{eventDate}}",
      "Admin.Card.Note.Audience": "Audience: {{target}}",
      "Admin.Card.Label.Title.Announcement": "Annonce",
      "Admin.Card.Option.Edit": "Modifier",
      "Admin.Card.Option.Remove": "Retirer",
      "Admin.Card.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Index.Heading.Publishing": "Publication en cours",
      "Admin.Index.Heading.New.Event": "Nouvel évènement",
      "Admin.Index.Heading.Draft": "Brouillon",
      "Admin.Index.Title.New": "Nouveau",
      "Admin.NewEdit.Validator": "est requis",
      "Admin.NewEdit.Validator.Multiple": "est requis",
      "Admin.NewEdit.Validator.Length": "doit être inférieur à 120 caractères",
      "Admin.NewEdit.Title.Item": "Objet",
      "Admin.NewEdit.Title.Maximum": "Maximum",
      "Admin.NewEdit.Title.Required": "Requis",
      "Admin.NewEdit.Heading.Option": "Option",
      "Admin.NewEdit.Heading.Available": "Disponible",
      "Admin.NewEdit.Heading.Price": "Prix ($)",
      "Admin.NewEdit.Option.Photo": "Mes Photos",
      "Admin.NewEdit.Option.Video": "Mes Vidéos",
      "Admin.NewEdit.Option.Search.Photo": "Gifs & Stock de Photos",
      "Admin.NewEdit.Option.Search.Video": "Rechercher une vidéo",
      "Admin.NewEdit.Option.Add.Video": "Ajouter le lien de la vidéo",
      "Admin.NewEdit.Button.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.NewEdit.Title.Add.Video": "Ajouter le lien de la vidéo",
      "Admin.NewEdit.Hint.Add.Video": "YouTube, Vimeo, etc...",
      "Admin.NewEdit.Title.Confirm.Deletion": "Confirmer la Suppression",
      "Admin.NewEdit.Message.Confirm.Deletion":
        "Souhaitez-vous supprimer cet évènement ?",
      "Admin.NewEdit.Button.Choose.Photo.Video": "Choisir une Photo/Vidéo",
      "Admin.NewEdit.Heading.Details": "1. DETAILS",
      "Admin.NewEdit.Title.Title": "Titre",
      "Admin.NewEdit.Hint.Title": "Ecrire le titre",
      "Admin.NewEdit.Title.Organizer": "Organisateur",
      "Admin.NewEdit.Hint.Organizer": "Sélectionner l'organisateur",
      "Admin.NewEdit.Title.Speaker": "Intervenant",
      "Admin.NewEdit.Hint.Speaker": "Ecrire le nom de l'intervenant",
      "Admin.NewEdit.Title.Speaker.Bio": "Biographie de l'intervenant",
      "Admin.NewEdit.Hint.Speaker.Bio": "Coller le lien ici",
      "Admin.NewEdit.Heading.Rsvp": "2. RSVP",
      "Admin.NewEdit.Note.Rsvp":
        "Autoriser les membres à répondre aux évènements depuis l'application ?",
      "Admin.NewEdit.Title.Allow.Rsvp": "Autoriser RSVP",
      "Admin.NewEdit.Title.Maximum.Attendees":
        "Nombre Maximum de participants (Membres et Invités)",
      "Admin.NewEdit.Title.Max.Guests":
        "Nombre Maximum d'Invités par Membre (Sans inclure le Membre)",
      "Admin.NewEdit.Title.Create.Chat":
        "Créer un espace de discussion pour cet évènement ?",
      "Admin.NewEdit.Heading.Location": "3. LIEU",
      "Admin.NewEdit.Title.Venue": "Nom du Site",
      "Admin.NewEdit.Title.Street.Address.1": "Adresse 1",
      "Admin.NewEdit.Title.Street.Address.2": "Adresse 2",
      "Admin.NewEdit.Title.City": "Ville",
      "Admin.NewEdit.Title.State": "Etat",
      "Admin.NewEdit.Title.County": "Département",
      "Admin.NewEdit.Title.Country": "Pays",
      "Admin.NewEdit.Title.ZipCode": "Code Postal",
      "Admin.NewEdit.Heading.Date.Time": "4. DATE & HORAIRE",
      "Admin.NewEdit.Title.All.Day.Event":
        "C'est un évènement d'une journée complète",
      "Admin.NewEdit.Note.Event.Start": "L'évènement commence à",
      "Admin.NewEdit.Title.Event.Start.Date": "Date de début",
      "Admin.NewEdit.Title.Event.Start.Time": "Horaire de début",
      "Admin.NewEdit.Note.Event.End": "L'évènement se termine à",
      "Admin.NewEdit.Title.Event.End.Date": "Date de fin",
      "Admin.NewEdit.Title.Event.End.Time": "Horaire de fin",
      "Admin.NewEdit.Title.RSVP.Deadline":
        "Date limite de réponse des participants",
      "Admin.NewEdit.Title.Timezone": "Fuseau horaire",
      "Admin.NewEdit.Heading.Description": "5. DESCRIPTION",
      "Admin.NewEdit.Title.Body.Text": "Corps du texte",
      "Admin.NewEdit.Hint.Body.Text": "Ecrivez quelque chose",
      "Admin.NewEdit.Title.Attachments": "Pièces-jointes",
      "Admin.NewEdit.Button.Add": "Ajouter",
      "Admin.NewEdit.Heading.Audience": "6. AUDIENCE",
      "Admin.NewEdit.Note.Event.Who.Is.Target":
        "Qui est l'audience cible de cet évènement ?",
      "Admin.NewEdit.Title.Event.Select.Target": "Sélectionner une cible",
      "Admin.NewEdit.Note.Event.Select.Groups":
        "Sélectionner les groupes qui peuvent voir cet évènement.",
      "Admin.NewEdit.Title.Event.Select.Groups": "Sélectionner les groupes",
      "Admin.NewEdit.Hint.Event.Select.Groups": "Sélectionner les groupes",
      "Admin.NewEdit.Heading.Announcement": "7. ANNONCE",
      "Admin.NewEdit.Note.Publish.Announcement":
        "Publier cette annonce dans le fil d'Annonces en complément du Calendrier d'Evènement (Recommandé)",
      "Admin.NewEdit.Title.Publish.Announcement": "Publier l'Annonce",
      "Admin.NewEdit.Note.Announcement.Who.Is.Target":
        "Qui est l'audience cible de cette annonce ?",
      "Admin.NewEdit.Title.Announcement.Select.Target": "Sélectionner la cible",
      "Admin.NewEdit.Note.Announcement.Select.Groups":
        "Sélectionner les groupes qui peuvent voir cette annonce.",
      "Admin.NewEdit.Title.Announcement.Select.Groups":
        "Sélectionner les groupes",
      "Admin.NewEdit.Hint.Announcement.Select.Groups":
        "Sélectionner les groupes",
      "Admin.NewEdit.Note.Expiration":
        "Expiration - Retirer l'annonce de l'évènement de la liste d'annonces, une fois arrivée à expiration. Cela n'affecte pas la liste d'évènements.",
      "Admin.NewEdit.Title.Expiration": "Expiration",
      "Admin.NewEdit.Title.Expiration.Date": "Date d'expiration",
      "Admin.NewEdit.Title.Expiration.Time": "Horaire d'expiration",
      "Admin.NewEdit.Note.Schedule": "Programmer cette annonce",
      "Admin.NewEdit.Title.Schedule": "Programme",
      "Admin.NewEdit.Title.Publish.Date": "Date de publication",
      "Admin.NewEdit.Title.Publish.Time": "Horaire de publication",
      "Admin.NewEdit.Note.Notification": "Notification pour cette annonce",
      "Admin.NewEdit.Note.No.Notification.Sent":
        "Aucune notification n'a été envoyée pour cette annonce. Une nouvelle notification peut être envoyée en faisant une sélection ci-dessous.",
      "Admin.NewEdit.Note.Push.Notification.Sent":
        "Une notification push a été envoyée le {{announcement.lastNotifyAt}}. Une nouvelle notification peut être envoyée en faisant une sélection ci-dessous.",
      "Admin.NewEdit.Title.Announcement.Channels": "Canaux",
      "Admin.NewEdit.Hint.Announcement.Channels": "Sélectionner les canaux",
      "Admin.NewEdit.Note.Announcement.Notification.To":
        "Envoyer des notifications E-mail/SMS à.",
      "Admin.NewEdit.Title.Announcement.Notification.To": "Notifications à",
      "Admin.NewEdit.Heading.Reminder.Notification":
        "8. RAPPEL DES NOTIFICATIONS",
      "Admin.NewEdit.Note.Reminder":
        "Envoyer un rappel aux membres qui n'ont pas encore répondu à un évènement",
      "Admin.NewEdit.Title.Reminder.Channels": "Canaux",
      "Admin.NewEdit.Hint.Reminder.Channels": "Sélectionner les canaux",
      "Admin.NewEdit.Note.Reminder.Notification.To":
        "Envoyer des notifications E-mail/SMS à",
      "Admin.NewEdit.Title.Reminder.Notification.To": "Notifications à",
      "Admin.NewEdit.Title.First.Reminder": "Premier rappel",
      "Admin.NewEdit.Title.First.Days.Before": "jour(s) avant",
      "Admin.NewEdit.Title.Second.Reminder": "Second rappel",
      "Admin.NewEdit.Title.Second.Days.Before": "jour(s) avant",
      "Admin.NewEdit.Heading.Get.Notified":
        "9. ÊTRE NOTIFIE DES REPONSES DES MEMBRES",
      "Admin.NewEdit.Note.Receive.When.Member.Respond":
        "Recevoir des notifications quand les membres répondent à un évènement",
      "Admin.NewEdit.Title.Notify.Me": "Me notifier par",
      "Admin.NewEdit.Hint.Notified.Channels": "Selectionner les canaux",
      "Admin.NewEdit.Heading.Payments": "10. PAIEMENTS",
      "Admin.NewEdit.Title.Enable.Payment":
        "Autoriser des outils de paiement pour cet évènement ?",
      "Admin.NewEdit.Title.Add.Processing.Fee":
        "Ajouter des frais de traitement ?",
      "Admin.NewEdit.Title.Due.Date": "Echéance",
      "Admin.NewEdit.Heading.Items": "Eléments",
      "Admin.NewEdit.Button.Publish": "Publier l'évènement",
      "Admin.NewEdit.Button.Publish.Update":
        "Publier une mise à jour d'évènement",
      "Admin.NewEdit.Button.Update.Draft": "Mise à jour Brouillon",
      "Admin.NewEdit.Button.Save.Draft": "Sauvegarder en tant que Brouillon",
      "Admin.NewEdit.Button.Delete.Event": "Supprimer l'évènement",
      "Attendee.Toast.Placed.On.Waitlist":
        "Vous avez été placé(e) en liste d'attente. Si une place se libère, vous serez ajouté(e) à la liste rsvp de l'évènement et vous serez informé(e) par e-mail.",
      "Attendee.Toast.Submitted.Rsvp":
        "Vous avez soumis votre RSVP avec succès !",
      "Attendee.Button.Ok": "$t(Common:Button.Ok)",
      "Attendee.Title.Alert": "Alerte",
      "Attendee.Message.Items.Have.Sold.Out":
        "Tous les éléments demandés ont été vendus. \nMerci de contacter votre administrateur pour résoudre votre participation.",
      "Attendee.Toast.Pay.To.Complete":
        "Nous vous envoyons une facture, merci de la payer pour terminer votre RSVP, merci !",
      "Attendee.Heading.Add.On.Instructions": "Ajouter des instructions",
      "Attendee.Note.Select.Items":
        "Sélectionner les éléments que vous souhaitez acheter et payer le montant total affiché ci-dessous avec une carte de crédit.",
      "Attendee.Text.Item.Options": "Element/Options",
      "Attendee.Text.Item.Quantity": "Quantité",
      "Attendee.Text.Item.Subtotal": "Sous-total",
      "Attendee.Button.Pay": "Payer $",
      "Attendee.Button.Save": "Sauvegarder",
      "Attendee.Add.Non.Member": "Ajouter un invité non-membre",
      "Attendee.Placeholder.Enter.Name": "Entrer le nom",
      "Attendee.Button.Cancel": "$t(Common:Button.Cancel)",
      "Attendee.Additional.Guests": "Invités supplémentaires",
      "Attendee.Button.Done": "Terminé",
      "Attendee.Option.Directory": "Annuaire",
      "Attendee.Option.Non-member": "Entrer un non-membre",
      "Attendee.Title.Add.Guest": "Ajouter un invité",
      "Attendee.Text.Add.Guest": "Ajouter un invité",
      "Attendee.Text.Additional.Guests.Not.Allowed":
        "Invités supplémentaires ne sont pas autorisés.",
      "Attendee.Text.Additional.Guests":
        "Invités supplémentaires (Jusqu'à {{maxGuestCount}} invités supplémentaires)",
      "Attendee.Button.Edit": "Modifier",
      "Attendee.Button.Add": "Ajouter",
      "Attendee.Button.Reply.By": "Répondre avant le {{attendeeDeadline}}",
      "Attendee.Button.Will.Attend": "Participera",
      "Attendee.Button.Joining.WaitList": "Rejoint la liste d'attente",
      "Attendee.Button.You.Are.Attending": "Vous participez",
      "Attendee.Button.Join.WaitList": "Rejoint la liste d'attente",
      "Attendee.Button.Will.Not.Attend": "Ne participera pas",
      "Attendee.Text.Spouse.Partner.Rsvp": "Epouse / Conjoint RSVP", //A réaliser : interpolation. N'a pas pu être trouvé dans l'application.
      "Attendee.Text.Rsvp.Note.Special.Requests":
        "RSVP Note / Demandes spéciales",
      "Attendee.Placeholder.Note": "Note",
      "Attendee.Text.Notify.Me.When.RSVP":
        "Informez-moi lorsque d'autres membres répondent RSVP!",
      "Attendee.Button.Continue": "Poursuivre",
      "Attendee.Button.Saving": "Enregistrement en cours",
      "Attendee.Warning1":
        "Il y a une liste d'attente pour cet évènement. Si une place se libère, vous serez ajouté(e) et informé(e).",
      "Attendee.Warning2":
        "La date limite de réponse à cet évènement est déjà dépassée. Merci de contacter {{author.firstName}} {{author.lastName}} directement.",
      "Attendee.Warning3":
        "Merci de contacter votre administrateur pour modifier votre participation à cet évènement.",
      "Attendee.Warning4":
        "La date limite de réponse à cet évènement est déjà dépassée. Merci de contacter {{author.firstName}} {{author.lastName}} directement.",
      "Attendees.Text.No.Members.Signed":
        "Aucun membre ne s'est inscrit pour cet évènement.",
      "Calendar.Text.No.Events": "Il n'y a pas d'évènement aujourd'hui",
      "Card.Button.Rsvp": "RSVP",
      "Card.Button.Attending": "Vous participez",
      "Card.Button.Not.Attending": "Vous ne participez pas",
      "Card.Button.Joined.WaitList": "Vous avez rejoint la liste d'attente",
      "Detail.Toast.Add.Event.To.Calendar":
        "Cet évènement a été ajouté à votre calendrier avec succès !",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Toast.Error.Calendar":
        "Il y a eu un problème avec l'ajout de votre évènement. ({{error}})",
      "Detail.Toast.Copied.Text": "Le texte a été copié avec succès !",
      "Detail.Text.Spouse.Partner.Attending": "* Epouse/Conjoint participe",
      "Detail.Text.Spouse.Partner.Not.Attending":
        "* Epouse/Conjoint ne participe pas",
      "Detail.Button.Reply.By": "Répondre d'ici {{attendeeDeadline}}",
      "Detail.Button.Change.Rsvp": "Changer RSVP",
      "Detail.Text.Joined.Waitlist": "* Vous avez rejoint la liste d'attente",
      "Detail.Text.Attending": "* Vous participez",
      "Detail.Button.Join.WaitList": "Rejoindre la liste d'attente",
      "Detail.Text.You.Not.Attending": "* Vous ne participez pas",
      "Detail.Text.Attachments": "Pièces-jointes",
      "Detail.Heading.Details": "Détails",
      "Detail.Button.Options.Upgrade": "Options/Mise à jour",
      "Detail.Button.Add.To.Calendar": "Ajouter au calendrier",
      "Detail.Button.Message.Guests": "Contacter les Invités",
      "Detail.Text.Organizer": "Organisateur",
      "Detail.Text.Attendee": "Participant",
      "Detail.Text.Attendee.Plural": "Participants",
      "Detail.Text.Member": "{{attendees.total}} participant",
      "Detail.Text.Member.Plural": "{{attendees.total}} participants",
      "Detail.Text.Speaker": "Intervenant",
      "Detail.Text.About.Event": "A propos de cet évènement",
      "Detail.Heading.Chat": "Chat",
      "Detail.Heading.Photos": "Photos",
      "Detail.Heading.Attendees": "Participants",
      "EventInfoHeader.Option.Get.Directions": "Obtenir l'itinéraire",
      "EventInfoHeader.Option.Display.On.Map": "Afficher sur notre carte",
      "EventInfoHeader.Option.Cancel": "$t(Common:Button.Cancel)",
      "Index.Button.Close": "Fermer",
      "Index.Button.Choose.Group": "Choisir un groupe",
      "Index.Heading.Filter.Events": "Filtrer les évènements",
      "Index.Heading.Sort.By": "Trier par :",
      "Index.Button.Closest.Date": "Date la plus proche",
      "Index.Button.Furthest.Date": "Date la plus lointaine",
      "Index.Heading.Event.View": "Evènements:",
      "Index.Button.In.Progress.Upcoming": "En cours & A venir",
      "Index.Button.Past": "Passés",
      "Index.Heading.Filter.By.Group": "Filtrer par groupe :",
      "Index.Button.Reset.To.All.Events": "Réinitialiser à tous les groupes",
      "Index.Button.All.Events": "Tous les groupes",
      "Index.Heading.List": "Liste",
      "Index.Heading.Event": "Evènement",
      "Index.Heading.Event.Plural": "Evènements",
      "Index.Text.No.Filter.Events": "Il n'y a pas de {{filter}} évènements",
      "Index.Text.In": "dans",
      "Index.Heading.Calendar": "Calendrier",
      "EventLocation.Button.Groupfire.Map": "Ouvrir sur Groupfire",
      "EventLocation.Button.Google.Maps": "Ouvrir sur Google maps",
      "EventLocation.Button.Cancel": "$t(Common:Button.Cancel)",

      "No events found": "Aucun évènement trouvé",
    },
    Form: {
      "Index.Placeholder.Write.Something": "Ecrire quelque chose ...",
      "Index.Placeholder.Search": "Rechercher",
      "Modal.Button.Close": "Fermer",
    },
    Group: {
      "Container.Admin.Index.Callback":
        "Le groupe a été supprimé avec succès !",
      "Container.Admin.NewEdit.Callback":
        "Votre groupe a été soumis avec succès !",
      "Container.Admin.NewEdit.Option.Yes": "Oui",
      "Container.Admin.NewEdit.Option.No": "Non",
      "Container.Admin.NewEdit.Option.Admin": "Administrateur",
      "Container.Admin.NewEdit.Option.Private": "Privé",
      "Container.Admin.NewEdit.Option.Public": "Public",
      "Container.Admin.NewEdit.Option.All.Org":
        "Tous les membres organisateurs peuvent joindre instantanément",
      "Container.Admin.NewEdit.Option.Request":
        "Demander l'accès et attendre l'acceptation par un administrateur",
      "Container.Admin.NewEdit.Option.Added.By":
        "Ajouté uniquement par un administrateur, aucune demande disponible",
      "Container.Admin.NewEdit.Option.Administrators": "Administrateurs",
      "Container.Admin.NewEdit.Option.Moderators": "Modérateurs",
      "Container.Admin.NewEdit.Option.Group.Members": "Membres du groupe",
      "Container.Admin.NewEdit.Option.Anyone": "N'importe qui",
      "Container.Action.More": "Plus",
      "Container.Action.My.Groups": "Mes Groupes",
      "Container.Action.Open": "Ouvrir",
      "Container.Action.Request": "Demande",
      "Container.Detail.Info":
        "Ce groupe n'est pas visible par vous. \nSouhaitez-vous le rejoindre maintenant ?",
      "Container.Detail.Callback": "Vous avez rejoint le groupe {{name}}",
      "Container.Detail.Callback.2":
        "Votre demande de rejoindre ce groupe a été envoyée à un administrateur de groupe pour acceptation.",
      "Container.Detail.Callback.3": "Vous avez quitté le groupe {{name}}",
      "Container.Setting.Option.All": "Tous les nouveaux posts",
      "Container.Setting.Option.Just": "Uniquement les mentions",
      "Container.Setting.Option.Nothing": "Aucun",
      "Admin.Card.Title.Confirm.Deletion": "Confirmer la Suppression", //I18N withTranslation ne fonctionne pas
      "Admin.Card.Message.Confirm.Deletion":
        "Souhaitez-vous supprimer ce groupe ?",
      "Admin.Card.Button.Deletion.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Card.Button.Deletion.Ok": "$t(Common:Button.Ok)",
      "Admin.Card.Text.Type": "Type: {{type.name}}",
      "Admin.Card.Text.Visibility": "Visibilité:",
      "Admin.Card.Text.Admin": "Administrateur",
      "Admin.Card.Text.Private": "Privé",
      "Admin.Card.Text.Public": "Public",
      "Admin.Card.Text.Members": "Membres: {{memberCount}}",
      "Admin.Card.Text.SubGroups": "Sous-groupes: {{item.children.length}}",
      "Admin.Card.Option.Edit": "Modifier",
      "Admin.Card.Option.Remove": "Retirer",
      "Admin.Card.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.Index.Placeholder.Search": "Rechercher",
      "Admin.Index.New.Group": "Nouveau groupe",
      "Admin.NewEdit.Validator": "est requis",
      "Admin.NewEdit.Validator.Length":
        "devrait être inférieur à 120 caractères",
      "Admin.NewEdit.Option.Photo": "Mes Photos",
      "Admin.NewEdit.Option.Search.Photo": "Gifs & Stock de Photos",
      "Admin.NewEdit.Option.Cancel": "$t(Common:Button.Cancel)",
      "Admin.NewEdit.Toast.Submitted.Group":
        "Votre groupe a été soumis avec succès !",
      "Admin.NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.NewEdit.Button.Choose.Photo": "Choisir une photo",
      "Admin.NewEdit.Heading.Details": "1. DETAILS",
      "Admin.NewEdit.Title.Name": "Nom",
      "Admin.NewEdit.Hint.Name": "Ecrire le nom",
      "Admin.NewEdit.Title.Parent.Group": "Groupe Initial",
      "Admin.NewEdit.Hint.Select.Group": "Sélectionner un groupe",
      "Admin.NewEdit.Title.Group.Type": "Typologie de groupe",
      "Admin.NewEdit.Hint.Select.Group.Type":
        "Sélectionner une typologie de groupe",
      "Admin.NewEdit.Title.Description": "Description",
      "Admin.NewEdit.Hint.Write.Description": "Ecrire une description",
      "Admin.NewEdit.Heading.Group.Feed": "2. FIL DU GROUPE",
      "Admin.NewEdit.Note.Will.Feed.Available":
        "Des flux de posts seront-ils disponibles pour ce groupe ?",
      "Admin.NewEdit.Title.Enable": "Autoriser",
      "Admin.NewEdit.Note.Who.Can.Post": "Qui peut poster sur le fil ?",
      "Admin.NewEdit.Title.Post.By": "Posté par",
      "Admin.NewEdit.Heading.Group.Messaging": "3. MESSAGERIE DE GROUPE",
      "Admin.NewEdit.Note.Create.Messaging.Room":
        "Créer une salle de messagerie pour ce groupe ?",
      "Admin.NewEdit.Heading.Privacy": "4. VIE PRIVEE",
      "Admin.NewEdit.Title.Privacy": "VIE PRIVEE",
      "Admin.NewEdit.Heading.Visibility": "5. VISIBILITE",
      "Admin.NewEdit.Note.Hide.Group": "Masquer ce groupe aux non-membres ?",
      "Admin.NewEdit.Heading.Joinability": "6. ACCESSIBILITE",
      "Admin.NewEdit.Note.Who.Can.Join": "Qui peut joindre ce groupe ?",
      "Admin.NewEdit.Title.Access": "Accès",
      "Admin.NewEdit.Button.Save.Group": "Sauvegarder le groupe",
      "Detail.Button.Close": "Fermer",
      "Detail.Heading.Settings": "Paramètres",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Button.Join": "Rejoindre {{name}}",
      "Detail.Button.Post.To.Community": "Poster à votre communauté",
      "GroupDetail.Tab.Chat": "Chat",
      "GroupDetail.Tab.Members": "Membres",
      "GroupDetail.Tab.Info": "Info",
      "GroupDetail.Tab.Feed": "Flux",
      "Detail.Text.About.Group": "Informations sur le groupe",
      "Detail.Button.Group.Photos": "Photos du Groupe",
      "Detail.Button.Group.Files": "Fichiers du Groupe",
      "Detail.Button.Leave": "Quitter {{name}}",
      "GroupDetailModal.Heading": "Paramètres",
      "ListGroupsByStatus.Placeholder.Search": "Rechercher",
      "ListGroupsByType.Title.My": "Mes",
      "ListGroupsByType.Title.More": "Plus",
      "ListGroupsByType.Title.Groups": "Groupes",
      "ListGroupsByType.Title.All": "Tout",
      "ListGroupsByType.Title.Industries": "Industries",
      "ListGroupsByType.Placeholder.Search": "Rechercher",
      "ListGroupsByStatus.GroupCard.PendingGroup": "En attente",
      "Mixed.Button.Post.To.Community": "Poster à votre communauté",
      "Mixed.Heading.My.Feed": "Mon Fil",
      "PostingGroups.Placeholder.Search": "Rechercher",
      "Setting.Heading.Mute.Group": "Mettre en sourdine l'ensemble du groupe",
      "Setting.Heading.Mobile.Notification": "Notification Mobile",
      "Setting.Heading.Desktop.Notification": "Notification sur le bureau",

      "No groups found": "Aucun groupe trouvé",
    },
    Map: {
      "Container.Action.Message": "Limites manquantes",
      "Index.Button.Close": "Fermer",
      "Index.Button.Back": "Retour",
    },
    Media: {
      "Media.Crop.Image.Zoom": "Zoom:",
      "Media.Crop.Image.Save.New.Avatar.Button": "Sauvegarder le nouvel avatar",
      "Container.Action.Message": "Merci d'insérer un lien viéo valide",
      "Index.Message.Not.Found.Media": "Aucun media trouvé.",
      "Picker.Error.Camera.Not.Available": "Caméra non disponible.",
      "Picker.Error.Cancel": "Annuler",
      "Picker.FallBack.General":
        "Il y a eu un problème dans le traitement de votre demande. Merci de réessayer ou contacter le centre d'aide !",
      "Picker.Error.Photos.Not.Available": "Photos non disponibles.",
      "Picker.FallBack.Video.Greater.Than":
        "Votre vidéo est supérieure à {{maxSize}}MB. Merci de choisir une autre vidéo ou contacter un administrateur pour vous venir en aide !",
      "Picker.FallBack.File.Greater.Than":
        "Votre fichier est supérieur à {{maxSize}}MB. Merci de choisir un autre fichier ou contacter le centre d'aide !",
      "Provider.Placeholder.Search": "Rechercher",
      "Provider.Button.Searching": "Recherche en cours",
      "Provider.Button.Search": "Rechercher",
      "SearchImage.Button.Close": "Fermer",
      "SearchImage.Button.Search.Photo": "Gifs & Stock de Photos",
      "SearchVideo.Button.Close": "Fermer",
      "SearchVideo.Button.Search.Video": "Rechercher une Vidéo",
    },
    Member: {
      "Container.Admin.New.Error.1":
        "Merci d'indiquer un numéro de téléphone valide sous le format suivant +12025555555",
      "Container.Admin.New.Error.2":
        "Merci d'indiquer une adresse e-mail valide !",
      "Container.Admin.New.Callback":
        "Un nouveau membre a été ajouté avec succès !",
      "Container.Action.Error": "Utilisateur non trouvé.",
      "Container.Detail.Callback.1":
        "Ce membre fait maintenant partie de votre réseau ! Vous verrez leurs posts sur votre fil.",
      "Container.Detail.Callback.2":
        "Ce membre ne fait désormais plus partie de votre réseau !",
      "Container.Index.Name": "Nom",
      "Container.Index.Title": "Titre",
      "Container.Index.Company": "Entreprise",
      "Container.Index.Industry": "Industrie",
      "Container.Index.Location": "Lieu",
      "Container.Index.Bio": "Biographie",
      "Container.Index.Interests & Hobbies": "Centres d'Intérêts & Hobbies",
      "Container.Index.Interests": "Intérêts & Hobbies",
      "Container.Index.Summary": "Résumé",
      "Container.Mixed.Name": "Nom",
      "Container.Mixed.Location": "Lieu",
      "Container.Mixed.Industry": "Industrie",
      "Container.Mixed.Directory": "Annuaire",
      "Container.Mixed.Business.Map": "Carte d'entreprise",
      "Admin.New.Validator.Required": "est requis",
      "Admin.New.Validator.Invalid": "est invalide",
      "Admin.New.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.New.Button.Choose.Photo": "Choisir une photo",
      "Admin.New.Heading.Details": "1. DETAILS",
      "Admin.New.Title.First.Name": "Prénom",
      "Admin.New.Hint.First.Name": "Ecrire le prénom",
      "Admin.New.Title.Last.Name": "Nom",
      "Admin.New.Hint.Last.Name": "Ecrire le nom",
      "Admin.New.Title.Email": "E-mail",
      "Admin.New.Title.Phone.Number": "Numéro de téléphone",
      "Admin.New.Placeholder.Phone.Number": "Numéro de téléphone",
      "Admin.New.Placeholder.Required": " (requis)",
      "Admin.New.Heading.Group.Memberships": "2. GROUPES D'APPARTENANCE",
      "Admin.New.Title.Group.For.User": "Groupes pour l'utilisateur",
      "Admin.New.Hint.Select.Groups": "Sélectionner des groupes",
      "Admin.New.Heading.Setting": "3. PARAMETRE",
      "Admin.New.Title.Send.Welcome.Email": "Envoyer un e-mail de bienvenue",
      "Admin.New.Title.Administrative.Access": "Accès administratif",
      "Admin.New.Button.Add.Member": "Ajouter un membre",
      "Card.Title.Professional.Info": "Information professionnelle",
      "Card.Title.Member": "Membre",
      "Detail.Title.User.Has.Not.Shared.Information":
        "L'utilisateur n'a partagé aucune information",
      "Detail.Header.Button.Text": "Editer le profil",
      "Detail.Title.Professional.Info": "Information professionnelle",
      "Detail.Title.Personal.Info": "Information personnelle",
      "Detail.Title.Executive.Assistant": "Assistant exécutif",
      "Detail.Title.Social.Media": "Réseaux sociaux",
      "Detail.Title.Google.Plus": "Google Plus",
      "Detail.Title.Company.Name": "Nom de l'entreprise",
      "Detail.Title.Phone.Other": "Téléphone (Autre)",
      "Detail.Button.Ok": "$t(Common:Button.Ok)",
      "Detail.Title.Company.History": "Expériences professionnelles",
      "Detail.Title.Summary": "Résumé",
      "Detail.Title.Phone": "Téléphone",
      "Detail.Title.Location": "Lieu",
      "Detail.Title.Business Address": "Adresse de l'entreprise",
      "Detail.Title.Bio": "Description",
      "Detail.Title.Spouse": "Epouse",
      "Detail.Title.Children": "Enfants",
      "Detail.Title.Email": "E-mail",
      "Detail.Title.Birthday": "Date de naissance",
      "Detail.Title.Invalid.Date": "Date non valide",
      "Detail.Title.Home.Address": "Adresse du domicile",
      "Detail.Title.Joined": "a rejoint le", //interpolation?
      "Detail.Button.Loading": "Chargement en cours",
      "Detail.Button.Chat": "Chat",
      "Detail.Heading.Info": "Info",
      "Detail.Button.LoginAsGuest": "Se connecter comme utilisateur",
      "Detail.Heading.Someone.Post": "Posts de {{data.firstName}} ",
      "Detail.Heading.Posts": "Posts",
      "Detail.Heading.Groups": "Groupes",
      "Detail.Text.Current": "actuels",
      "Detail.Heading.Professional.Information": "Information professionnelle",
      "Detail.Label.Name": "Nom",
      "Detail.Label.Phone": "Téléphone",
      "Detail.Label.Email": "E-mail",
      "Detail.Heading.Personal.Information": "Information Personnelle",
      "Detail.Label.Spouse.Partner.Significant.Other":
        "Epouse/Conjoint/Partenaire",
      "Detail.Label.About.Me": "A propos de moi",
      "Detail.Heading.Lets.Connect": "Connectons-nous",
      "DetailBB.Title.User.Has.Not.Shared.Information":
        "L'utilisateur n'a partagé aucune information",
      "DetailBB.Title.Personal.Info": "Information personnelle",
      "DetailBB.Title.Professional.Info": "Information Professionnelle",
      "DetailBB.Title.Social.Media": "Réseaux sociaux",
      "DetailBB.Title.Google.Plus": "Google Plus",
      "DetailBB.Title.Company.Name": "Nom d'entreprise",
      "DetailBB.Title.Phone.Other": "Téléphone (Autre)",
      "DetailBB.Button.Ok": "$t(Common:Button.Ok)",
      "DetailBB.Button.Loading": "Chargement en cours",
      "DetailBB.Button.Chat": "Chat",
      "DetailBB.Heading.Someone.Post": "Posts de {{data.firstName}}",
      "DetailBB.Heading.Groups": "Groupes",
      "DetailBB.Heading.Lets.Connect": "Connectons-nous",
      "DetailBB.Heading.Current.Company": "Entreprise actuelle",
      "Group.Placeholder.Search": "Rechercher",
      "GroupMemberList.Text.No.Members.Found": "Aucun membre trouvé.",
      "GroupMemberList.Button.Load.More": "Voir plus",
      "GroupMemberList.Placeholder.Search.Member": "Rechercher un membre",
      "CollapsingMemberFilter.Select": "Sélectionner",
      "Index.Alert.Guest.Mode": "L'aperçu rapide est désactivé en Mode Invité.",
      "Index.Text.Member": "Membre",
      "Index.Text.Member.Plural": "Membres",
      "Index.Text.My.Network": "Mon réseau",
      "Index.Button.Cancel": "$t(Common:Button.Cancel)",
      "Index.Placeholder.Filter.By.Group.Type":
        "Filtrer par {{mainGroupType.name}}",
      "Index.Title.Filter.Members": "Filtrer les Membres",
      "Index.Title.Filter.Members.By.Text":
        "Filtrer par {{filterSecondaryText}}",
      "Index.Placeholder.Filter.By.Name": "Filtrer par {{item.name}}",
      "Index.Placeholder.Search.By.Location":
        "Rechercher par lieu, nom ou mot-clé",
      "Index.Button.Clear.All.Filters": "Effacer tous les filtres",
      "Index.Button.Apply": "Appliquer",
      "Index.Button.Filter.Members": "Filtrer les membres",
      "Index.Button.Reset": "Réinitialiser",
      "MembersList.Button.Load.More": "Voir plus",
      "MembersListFilters.Button.Member": "Membre",
      "MembersListFilters.Button.Map": "Carte",
      "MembersListFilters.Button.Filter": "Filtre",
      "MembersListFilters.Button.Clear.Filters": "Supprimer tous les filtres",
      "MembersPicker.Button.Close": "Fermer",
      "MembersPicker.Button.Save": "Sauvegarder",
      "MembersPicker.Button.Choose.Member": "Choisir un Membre",
      "MembersPicker.Button.Done": "Terminé",
      "MembersPicker.Placeholder.Search": "Rechercher",
      "MembersPicker.Button.Load.More": "Voir plus",
      "SocialMedia.Label.Instagram": "Instagram",
      "SocialMedia.Label.Instagram.Lower.Case": "instagram",
      //Profils clés
      "Alma Mater": "Ecole/Université",
      "Company History": "Expériences professionnelles",
      "Business Address": "Adresse de l'entreprise",
      "From Date": "Depuis Date",
      "To Date": "A Date",
      "Current Company": "Entreprise actuelle",
      "Company Name": "Nom de l'entreprise",
      "Google Plus": "Google Plus",
      "Interests & Hobbies": "Centres d'intérêts & Hobbies",
      "Phone (Other)": "Téléphone (Autre)",
      Name: "Nom",
      Bio: "Description",
      Birthday: "Date de naissance",
      Children: "Enfants",
      Email: "E-mail",
      Facebook: "Facebook",
      Industry: "Industrie",
      Instagram: "Instagram",
      Linkedin: "Linkedin",
      Location: "Lieu",
      Phone: "Téléphone",
      Spouse: "Epouse",
      Summary: "Résumé",
      Title: "Titre",
      Twitter: "Twitter",
      Website: "Site internet",
    },
    Mention: {
      "Container.Action.Resources": "Ressources",
      "Container.Action.Groups": "Groupes",
      "Container.Action.Events": "Evènements",
      "Container.Action.Announcements": "Annonces",
    },
    MixedFeed: {
      "Index.Heading.Post": "Poster à votre communauté",
    },
    Modal: {
      "Invoice.Modal.Button.Payment": "Paiement requis",
      "Invoice.Modal.Details": "Voir les détails",
    },
    Navbar: {
      "Navbar.SignIn": "Se connecter",
      "Navbar.SignUp": "S'inscrire",
      "Navbar.My.Profile": "Mon profil",
      "Navbar.Edit.Profile": "Modifier le profil",
      "Navbar.Change.Passcode": "Changer Code",
      "Navbar.Notifications": "Notifications",
      "Navbar.Chat": "Chat",
      "Navbar.Search": "Rechercher",
      "Navbar.More": "Voir plus",
      "Navbar.Edit.Notifications": "Modifier les notifications",
      "Navbar.Settings": "Paramètres",
      "Navbar.Subscription": "Mes Projets",
      "Navbar.Logout": "Deconnexion",
      "Navbar.Admin.Panel": "Panneau administrateur",
      "Navbar.ViewAsGuest.Text":
        'Vous êtes en mode "Voir en tant que". Chaque action que vous allez réaliser sera enregistrée pour cet utilisateur, telles que RSVP, commentaires, etc.',
      "Navbar.ViewAsGuest.Exit": "Quitter maintenant !",
    },
    Notification: {
      "Admin.New.Less.Characters": " devrait être inférieur à 120 caractères",
      "Admin.New.Title.Required": " est requis",
      "Admin.New.Toast.Button.Ok": "$t(Common:Button.Ok)",
      "Admin.New.Heading.Photo": "Photo dans la pop up de l'application",
      "Admin.New.Heading.Button.Link":
        "Lien pour le bouton sur la pop-up de l'application",
      "Admin.New.Placeholder.Write.Link": "Ecrivez un lien",
      "Admin.New.Placeholder.Write.Title.Link": "Ecrivez le titre du lien",
      "Admin.New.Heading.Details": "1. Détails",
      "Admin.New.Heading.Title": "Titre",
      "Admin.New.Heading.Write.Title": "Ecrire le titre",
      "Admin.New.Delivery": "Méthodes de livraison",
      "Admin.New.Content": "Contenu",
      "Admin.New.Content.Hint": "Ecrire le contenu",
      "Admin.New.Heading.Audience": "2. Audience",
      "Admin.New.Heading.Notification": "Qui peut voir cette notification ?",
      "Admin.New.Heading.Notification.Target": "Sélectionner une cible",
      "Admin.New.Heading.Notification.Groups": "Sélectionner des groupes",
      "Admin.New.Heading.Notification.Members": "Sélectionner des membres",
      "Admin.New.Notification.Target": "Participants cibles ayant répondu.",
      "Admin.New.Notification.Select.Respond": "Sélectionner a répondu",
      "Admin.New.Notification.Receive":
        "Seuls certains de ces membres doivent recevoir la notification ?",
      "Admin.New.Notification.To": "Notifications à",
      "Admin.New.Heading.Schedule": "3. PROGRAMMATION",
      "Admin.New.Schedule.Notification": "Programmer une notification",
      "Admin.New.Publish.Date": "Date de publication",
      "Admin.New.Publish.Time": "Heure de publication",
      "Admin.New.Timezone": "Fuseau horaire",
      "Admin.New.Publish.Notification": "Publier la notification",
      "Index.Alert.Guest.Mode":
        "Impossible de voir les notifications en Mode Invité.",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Button.Unread.Notification": "Aucune notification non-lue",
      "Index.Button.Unread.Marking":
        "Marquer toutes les notifications comme lues",
      "Index.Button.Mark": "Marquer tout",
      "Index.Button.Read.Notifications": " notifications lues",
      "NotificationLanding.Button.Read.More": "Lire plus",
      "NotificationLanding.Button.Open.Twitter": "Ouvrir sur Twitter",
      "NotificationCard.Button.View.Announcement": "Voir l'annonce",
      "NotificationCard.Button.View.Event": "Voir l'évènement",
      "NotificationCard.Button.View.Profile": "Voir le profil",
      "NotificationCard.Button.View.Invoice": "Voir la facture",
      "NotificationCard.Button.View.Post": "Voir le post",
      "NotificationCard.Button.View": "Voir",
      "NotificationCard.Button.View.Url": "Voir l'url",
      "NotificationCard.Button.View.Link": "Voir le lien",

      "Container.Index.Cleared": "Effacé",
      "Container.Index.Notification": " notification(s) avec succès.",
      "Container.Admin.New.Notification":
        "Nouvelle notification créée avec succès !",
      "Container.Admin.New.Popup": "Popup dans l'application",
      "Container.Admin.New.Push.Notification": "Notification push",
      "Container.Admin.New.All": "Tous",
      "Container.Admin.New.Logged.In.Members":
        "Seuls les membres qui ne se sont pas connectés à l'application",
      "Container.Admin.New.Specific.Member": "Membres spécifiques",
      "Container.Admin.New.Specific.Groups": "Groupes spécifiques",
      "Container.Admin.New.Yes": "Oui",
      "Container.Admin.New.No": "Non",
      "Container.Admin.New.Not.Yet": "N'a pas encore répondu",
    },
    Onboarding: {
      "Index.Skip.Button": "Passer",
      "Slide.GetStarted.Button": "Démarrer",
    },
    Payment: {
      "Detail.Toast.Export.Ready": "Fichier d'exportation prêt à être ouvert.",
      "Detail.Toast.Text": "Votre facture a été payée avec succès !",
      "Detail.Toast.Ok": "$t(Common:Button.Ok)",
      "Detail.Heading.Checks.Payable": "Faire un chèque à l'ordre de",
      "Detail.Heading.Checks.Send": "Envoyer le chèque à",
      "Detail.Heading.Checks.Send.By": "Envoyer le paiement par",
      "Detail.Heading.Total": "Total",
      "Detail.Button.Paying": "Paiement en cours",
      "Detail.Button.Pay": "Payer",
      "Detail.Button.Paid": "Payé",
      "Detail.Button.By.Credit.Card": "avec une carte de crédit",
      "Detail.Button.Return.To.Payments": "Retour",
      "Detail.Button.Download.Invoice.Pdf": "Télécharger Facture",
      "Index.Heading.Invoices": "Factures",
      "Index.Heading.Receipts": "Reçus",
      "Invoice.List.Not.Found": "Aucune facture trouvée",
      "Pay.Error":
        "Il y a eu un problème avec la configuration. Merci de contacter le centre d'aide.",
      "Pay.Fees": "Frais inclus",
      "Pay.Card.Heading": "Je paye avec",
      "Pay.Credit.Card": "Une carte de crédit",
      "Pay.Credit.Card.Details": "Détails de la carte de crédit",
      "Pay.Check": "Chèque",
      "Pay.Pay": "Payer",
      "Pay.Paying": "Paiement en cours",
      "Pay.Heading.Pay.Instruction": "Instructions de paiement",
      "Pay.Heading.Checks.To": "Faire un chèque à l'ordre de",
      "Pay.Heading.Checks.Send.To": "Envoyer un chèque à",
      "Pay.Heading.Checks.Send.By": "Envoyer le paiement par",
      "Pay.Checks.Confirm": "Confirmer pour envoyer votre paiement !",
      "Pay.Checks.Confirming": "Confirmation en cours !",
      "Pay.Subscribe.Confirm": "Confirmer",
      "Pay.Placeholder.Text.Card": "Numéro de carte",
      "Pay.Placeholder.Text.Valid": "Validité",
      "Pay.Placeholder.Text.Cvc": "CVC",
      "Receipt.List.Not.Found": "Aucun reçu trouvé",
      "Container.Action.Message": "Aucune facture trouvée.",
    },
    Plugin: {
      "Mentions.Placeholder.Write.Comments": "Ecrire un commentaire...",
    },
    Post: {
      "Container.NewEdit.Error":
        "Vous n'avez pas la permission de poster sur ce groupe.",
      "Container.NewEdit.Submit.Post": "Votre post a été soumis avec succès !",
      "Container.Index.Delete": "Votre post a été supprimé avec succès !",
      "Container.Action.Messege":
        "Information invalide, groupe ou utilisateur manquant",
      "Container.Action.Messege.EmptyGroup":
        "Merci de sélectionner un groupe avant de poster.",
      "Container.Action.Messege.EmptyText":
        "Merci d'ajouter du texte/vidéo/fichier avant de poster.",
      "Container.Action.Link": "Lien en cours d'obtention ...",
      "Card.Toast.Text": "Texte copié avec succès !",
      "Card.Text.Attachments": "Pièces-jointes",
      "Card.Alert.Title": "Confirmer la suppression",
      "Card.Alert.Text": "Souhaitez-vous supprimer ce post ?",
      "Card.Option.Edit": "Modifier",
      "Card.Option.Remove": "Retirer",
      "Card.Option.Cancel": "Annuler",
      "Card.Alert.Button.Ok": "$t(Common:Button.Ok)",
      "Card.Alert.Button.Cancel": "$t(Common:Button.Cancel)",
      "Card.Text.Shared.In": "Partagé sur",
      "Card.Button.Read.More": "Lire plus",
      "NewEdit.Button.Ok": "$t(Common:Button.Ok)",
      "NewEdit.Button.Close": "Fermer",
      "NewEdit.Heading.Choose.Group": "Choisir le groupe",
      "NewEdit.Button.Update.Post": "Mettre à jour le post",
      "NewEdit.Button.New.Post": "Nouveau post",
      "NewEdit.Button.Post": "Post",
      "NewEdit.Heading.Message": "Ecrivez votre message:",
      "NewEdit.Heading.Text": "Poster sur :",
      "NewEdit.Heading.Groups.Text": "Chercher d'autres groupes",
      "NewEdit.Placeholder.Group.Post": "J'aimerais poster",
      "NewEdit.Placeholder.Group.Choose":
        "Merci de choisir votre groupe avant d'écrire !",
      "NewEdit.Heading.Tag": "Tags",
      "NewEdit.PhotoOptions.TakePhoto": "Prendre une photo",
      "NewEdit.PhotoOptions.Photo": "Mes photos",
      "NewEdit.PhotoOptions.Search": "Gifs & Stock de Photos",
      "NewEdit.PhotoOptions.Cancel": "Annuler",
      "NewEdit.Tab.Video": "Vidéo",
      "NewEdit.Tab.Photos.Or.Files": "Photos/Fichiers",
      "NewEdit.VideoOptions.CaptureVideo": "Enregistrer une vidéo",
      "NewEdit.VideoOptions.Video": "Ma video",
      "NewEdit.VideoOptions.Search": "Rechercher vidéo",
      "NewEdit.VideoOptions.AddLink": "Ajouter le lien vidéo",
      "NewEdit.VideoOptions.AddLink.Title": "Ajouter le lien vidéo",
      "NewEdit.VideoOptions.AddLink.Hint": "YouTube, Vimeo, etc...",
      "NewEdit.Remove.Video.Tip":
        "Pour ajouter une vidéo, retirer la vidéo actuelle",

      "No posts found": "Aucun post trouvé",
    },
    Resource: {
      "Index.Button.Heading.Text": "Librairie de vidéos",
      "ResourceDetail.Button.Back": "Retour",
    },
    Routes: {
      "Testing Board": "Tableau de tests",
      "Account Settings": "Paramètres du compte",
      "Profile Settings": "Paramètres du profil",
      Profile: "Profil",
      Announcement: "Annonce",
      Event: "Evènement",
      Attendee: "Participant",
      Group: "Groupe",
      "Payment Instructions": "Instructions de paiement",
      Resource: "Ressource",
      Videos: "Vidéos",
      Video: "Vidéo",
      Posting: "Publication en cours",
      Post: "Post",
      "Search Groups": "Rechercher des groupes",
      Map: "Carte",
      Comments: "Commentaires",
      "Reacted By": "Réagi par",
      "Seen By": "Vu par",
      "Group Photos": "Photos du groupe",
      "Group Files": "Fichiers du groupe",
      Message: "Message",
      Change: "Modifier",
      Password: "Mot de passe",
      Passcode: "Code",
      "Bugs & Feedback": "Bugs & Retours",
      Onboarding: "Onboarding",
      Modal: "Modal",
      Search: "Recherche",
      "Control Panel": "Panneau de contrôle",
      "Manage Announcements": "Gérer les annonces",
      "New Announcement": "Nouvelle Annonce",
      "Edit Announcement": "Modifier une Annonce",
      "Manage Events": "Gérer les évènements",
      "New Event": "Nouvel évènement",
      "Edit Event": "Modifier l'évènement",
      "Manage Groups": "Gérer les groupes",
      "New Group": "Nouveau groupe",
      "Edit Group": "Modifier Groupe",
      "New Member": "Nouveau membre",
      "New Notification": "Nouvelle Notification",
      "Custom Theme": "Thème personnalisé",
      "Sign In": "Se connecter",
      "Sign Up": "S'inscrire",
      "Forgot Password": "Mot de passe oublié",
      Support: "Aide",
      "Pick Community": "Choisir une communauté",
    },
    Search: {
      "Index.Search.Box.Text": "Recherche un mot clé ou un #tag",
      "Index.Button.Close": "Fermer",
      "Recent.Search.Heading": "Recherches récentes",
      "Container.Index.Announcement.News": "Nouveautés",
      "Container.Index.Announcement.Events": "Evènements",
      "Container.Index.Announcement.Posts": "Posts",
    },
    Support: {
      "Feedback.Heading.Bugs.Feedback": "Bugs & Retours",
      "Feedback.Placeholder.Issue.Type":
        "Choisissez le type de problème rencontré",
      "Feedback.Placeholder.Description": "Ecrivez une description",
      "Feedback.Button.Submit": "Soumettre",
      "Feedback.Button.Loading": "Chargement en cours",
      "Index.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Placeholder.Email": "Ecrivez votre e-mail",
      "Index.Placeholder.Phone": "Numéro de téléphone",
      "Index.Placeholder.Name": "Ecrivez votre nom",
      "Index.Placeholder.Issue.Type":
        "Choisissez le type de problème rencontré",
      "Index.Placeholder.Description": "Ecrivez une description",
      "Index.Button.Loading": "Chargement en cours",
      "Index.Button.Submit": "Soumettre",
      "Container.Index.Feedback": "Feedback général",
      "Container.Index.Bug": "Remonter un bug",
      "Container.Index.Suggestion": "Suggestion de fonctionnalité",
      "Container.Index.Email": "Je ne me souviens plus de l'e-mail.",
      "Container.Index.Password": "J'ai oublié mon mot de passe.",
      "Container.Index.Email.New": "J'ai une nouvelle adresse e-mail.",
      "Container.Index.Feedback.Share": "J'ai un retour à partager !",
      "Container.Index.Other": "Autre",
      "Container.Index.Mobile": "J'ai oublié le numéro de téléphone.",
      "Container.Index.Pin": "J'ai oublié mon code PIN.",
      "Container.Index.Mobile.Number": "J'ai un nouveau numéro de téléphone.",
      "Container.Index.Error.Issue.Type":
        "Merci d'entrer la typologie de problème rencontré.",
      "Container.Index.Error.Issue.Description": "Ecrivez une description.",
      "Container.Index.Feedback.Thanks":
        "Merci d'avoir partagé votre retour ! Nous reviendrons vers vous dès que possible.",
      "Container.Index.Email.Input": "Entrez votre e-mail.",
      "Container.Index.Phone.Input": "Entrez votre numéro de téléphone.",
      "Container.Index.Name.Input": "Entrez votre nom.",
      "Container.Index.Support.Thanks":
        "Merci d'avoir contacté le support ! Nous reviendrons vers vous dès que possible.",
      "SupportModal.Header.Text": "Support",
    },
    Tag: {
      "Tags.Placeholder.Input.Text": "Ecrire le texte et appuyez sur Entrer",
    },
    TestingBoard: {
      "Index.Heading.Main.Features": "Principales fonctionnalités",
      "Index.Heading.Home": "Accueil",
      "Index.Heading.Settings": "Paramètres",
      "Index.Heading.Notifications": "Notifications",
      "Index.Heading.News": "Nouveautés",
      "Index.Heading.Events": "Evènements",
      "Index.Heading.Groups.Feed": "Fil des Groupes",
      "Index.Heading.Groups.List": "Listes des Groupes",
      "Index.Heading.Members": "Membres",
      "Index.Heading.Chat.Room": "Salle de discussion",
      "Index.Heading.Payments": "Paiements",
      "Index.Heading.Resources": "Ressources",
      "Index.Heading.Video.Library": "Librairie de vidéos",
      "Index.Heading.Bugs.Feedback": "Bugs & Retours",
      "Index.Heading.Admin": "Administrateur",
      "Index.Heading.Manage.Announcements": "Gérer les annonces",
      "Index.Heading.Manage.Events": "Gérer les évènements",
      "Index.Heading.Manage.Groups": "Gérer les Groupes",
      "Index.Heading.New.Member": "Nouveau Membre",
      "Index.Heading.New.Notification": "Nouvelle Notification",
      "Index.Heading.Custom.Theme": "Thème personnalisé",
    },
    Theme: {
      "Index.Toast.Text.Reset": "Le thème a été réinitialisé avec succès !",
      "Index.Toast.Button.Ok": "$t(Common:Button.Ok)",
      "Index.Toast.Text.Save":
        "Le nouveau thème a été sauvegardé avec succès !",
      "Index.General": "Général",
      "Index.General.Background.Color": "Couleur de fond",
      "Index.General.Updates": "'Mises à jour' couleur du texte",
      "Index.General.Text.Color": "Couleur du texte",
      "Index.Navigation": "Navigation",
      "Index.Navigation.Background.Color": "Couleur de fond",
      "Index.Button": "Bouton",
      "Index.Button.Background.Color": "Couleur de fond",
      "Index.Tab": "Onglet",
      "Index.Bottom.Tab.Color": "Couleur du bas de l'onglet",
      "Index.Directory.Tab.Color": "Couleur de l'onglet (Direction)",
      "Index.Comment.Like": "Commentaire/J'aime",
      "Index.Comment.Button": "Bouton Commentaires",
      "Index.Like.Button": "Bouton J'aime",
      "Index.Login.Register": "Se connecter/S'inscrire",
      "Index.Login.Register.Button": "Bouton",
      "Index.Login.Register.Gradient.Color": "Couleur dégradée",
      "Index.Events": "Evènements boutons RSVP",
      "Index.Events.Rsvp": "RSVP",
      "Index.Events.Rsvp.Yes": "RSVP Oui",
      "Index.Events.Rsvp.No": "RSVP Non",
      "Index.Events.Rsvp.Wait.List": "RSVP liste d'attente",
      "Index.Boarding": "On-boarding",
      "Index.Boarding.Top.Color": "Couleur du haut",
      "Index.Chat": "Chat",
      "Index.Chat.Background.Color": "Couleur de fond",
      "Index.Chat.Left.Bubble": "Bulle de gauche",
      "Index.Chat.Right.Bubble": "Bulle de droite",
      "Index.Badge.Unread": "Badge non-lu",
      "Index.Badge.Unread.Background.Color": "Couleur de fond",
      "Index.Pinned.News": "Nouvelles épinglées",
      "Index.Pinned.News.Background.Color": "Couleur de fond",
      "Index.Reset": "Réinitialiser les réglages par défaut",
      "Index.Button.Basic": "Basique",
      "Index.Button.Advanced": "Avancé",
      "Index.Save.Theme": "Sauvegarder le thème",
      "Container.Index.Warning":
        "Attention ! Vous devez savoir les règles des feuilles de style avant de modifier ces éléments. Dans le cas contraire, cela cause un crash. Dans ce dernier cas, pour restaurer l'application vous devez réinitialiser les données dans la base de données.",
      "Container.Index.Alert":
        "Merci d'utiliser les valeurs décimales uniquement pour les couleurs.",
    },
    UI: {
      "DragAndDrop.Text.Drop.Here": "Déposer ici",
      "DropDown.Placeholder": "Selectionner un",
      "DropDown.Cancel": "$t(Common:Button.Cancel)",
      "Empty.No.Data": "Aucune donnée trouvée.",
      "Error.Title": "Uh oh",
      "Error.Link.Text": "Retourner à l'accueil",
      "Error.Content": "Une erreur inattendue est survenue",
      "Foorer.Text": "",
      "LoadingBar.Text": "Chargement en cours",
      "Messages.Message": "Une erreur inattendue est survenue",
      "ReadMore.See.More": "Voir plus",
      "ReadMore.See.Less": "Voir moins",
      "SearchBox.Placeholder": "Rechercher",
      "SearchBox.Submit.Button.Text": "Rechercher",
      "VideoProcessing.Video": "Vidéo en cours de traitement...",
      "VideoProcessing.Large.Video":
        "La vidéo est un peu volumineuse (>100MB) donc le traitement peut prendre jusqu'à 30 minutes avant qu'elle ne soit visualisable.",
    },
    Uploading: {
      "Index.Uploading": "Téléchargement en cours de {{name}}....",
    },
    User: {
      "Change.Password.Button.Ok": "$t(Common:Button.Ok)",
      "Change.Password.Passcode.Current": "Entrer le code actuel (4 chiffres)",
      "Change.Password.Passcode.New": "Entrer le nouveau code (4 chiffres)",
      "Change.Password.Passcode.Confirm":
        "Entrer le code de confirmation (4 codes)",
      "Change.Password.Current": "Entrer le mot de passe actuel (8+ carac.)",
      "Change.Password.New": "Entrer le nouveau mot de passe (8+ carac.)",
      "Change.Password.Confirm":
        "Entrer le mot de passe de confirmation (8+ carac.)",
      "Change.Password.Loading": "Chargement en cours",
      "Change.Password.Submit": "Soumettre",
      "Change.Password.Cancel": "Annuler",
      "Forgot.Password.Button.Ok": "$t(Common:Button.Ok)",
      "Forgot.Password.Heading":
        "Avez-vous oublié votre mot de passe ? \nMerci d'entrer votre adresse e-mail pour recevoir un e-mail de récupération.",
      "Forgot.Password.Email.Address": "Adresse e-mail",
      "Forgot.Password.Loading": "Chargement en cours",
      "Forgot.Password.Submit": "Soumettre",
      "Login.Toast.Button.Ok": "$t(Common:Button.Ok)",
      "Login.Email": "E-mail",
      "Login.Phone": "Téléphone",
      "Login.Phone.Number": "Numéro de téléphone",
      "Login.Heading.Please": "Merci d'utiliser votre",
      "Login.Heading.To.Login": "pour vous connecter.",
      "Login.Heading.And": "et",
      "Login.Heading.Phone": "téléphone",
      "Login.Placeholder.Email": "Adresse e-mail",
      "Login.Button.Loading": "Chargement en cours",
      "Login.Button.Sign.In": "Se connecter",
      "Login.Account": "Pas de compte ?",
      "Login.Button.Sign.Up": "S'inscrire",
      "Login.Forgot.Password": "Mot de passe oublié !",
      "Login.Forgot.New.Passcode": "Demander un nouveau code",
      "Login.Choose.Another.Community": "Choisir une autre communauté",
      "Login.Sign.Up": "Je suis nouveau, inscrivez-moi",
      "Login.Contact.Support": "Contacter le Support",
      "Login.Page.Title": "Se connecter",
      "Register.Toast.Ok": "$t(Common:Button.Ok)",
      "Register.Heading.Text":
        "Entrer les informations de connexion pour terminer votre inscription",
      "Register.Placeholder.First.Name": "Entrer Prénom (requis)",
      "Register.Label.First.Name": "Prénom",
      "Register.Placeholder.Last.Name": "Entrer nom (requis)",
      "Register.Label.Last.Name": "Nom",
      "Register.Placeholder.Enter.Phone": "Entrer numéro de téléphone",
      "Register.Label.Phone": "Numéro de téléphone",
      "Register.Placeholder.Required": " (requis)",
      "Register.Placeholder.Enter.Email": "Entrer adresse e-mail",
      "Register.Label.Email": "E-mail",
      "Register.Placeholder.Password":
        "Entrer mot de passe (8+ carac.) (requis)",
      "Register.Label.Password": "Mot de passe",
      "Register.Placeholder.Password.Reenter":
        "Entrer de nouveau le mot de passe (8+ carac.) (requis)",
      "Register.Label.Password.Confirm": "Confirmer le mot de passe",
      "Register.Button.Loading": "Chargement en cours",
      "Register.Button.Signup": "S'inscrire",
      "Register.Account.Exists": "Vous avez déjà un compte ?",
      "Register.Account.Login": "Se connecter",
      "Setting.Toast.Copy": "Texte copié avec succès !",
      "Setting.Toast.Ok": "$t(Common:Button.Ok)",
      "Setting.Account.Settings": "Paramètres du compte",
      "Setting.Heading.Subscription": "Projets",
      "Setting.Heading.Subscription.Change": "Mes projets",
      "Setting.Heading.Notifications": "Notifications",
      "Setting.Heading.Push.Notifications": "M'envoyer des notifications push",
      "Setting.Heading.Email.Notifications":
        "M'envoyer des notifications par e-mail pour les publications du groupe ",
      "Setting.Heading.Email.Notifications.Posts":
        "Quand vous êtes occupé(e) ou hors-ligne, nous pouvons vous envoyer des notifications par e-mail pour chaque nouveau post",
      "Setting.Heading.Notifications.Message":
        "M'envoyer des notifications par e-mail pour les messages",
      "Setting.Heading.Media": "Media",
      "Setting.Heading.App": "Support de l'application",
      "Setting.Heading.About": "Concernant cette application",
      "Setting.Heading.Version": "Version sur l'application",
      "Setting.Sign.Out": "Se déconnecter",
      "Update.Profile.Avatar.Crop.Modal.Heading": "Modifier votre avatar",
      "Update.Profile.Personal.Info.Alma.Mater": "Ecole/Université",
      "Update.Profile.Personal.Info.Birthday": "Date de naissance",
      "Update.Profile.Personal.Info.Email": "E-mail",
      "Update.Profile.Personal.Info.Website": "Site internet",
      "Update.Profile.Personal.Info.Invalid.Email": "E-mail non valide",
      "Update.Profile.Personal.Info.Location": "Lieu",
      "Update.Profile.Personal.Info.Phone": "Téléphone",
      "Update.Profile.Personal.Info.Invalid.Phone":
        "Merci d'indiquer un numéro de téléphone valide (+12025555555)",
      "Update.Profile.Personal.Info.Phone.Other": "Numéro de téléphone (Autre)",
      "Update.Profile.Personal.Info.Bio": "Description",
      "Update.Profile.Personal.Info.Interests.And.Hobbies":
        "Centres d'Intérêts & Hobbies",
      "Update.Profile.Personal.Info.Children": "Enfants",
      "Update.Profile.Personal.Info.Child.Name": "Nom",
      "Update.Profile.Personal.Info.Child.Birthday": "Date de naissance",
      "Update.Profile.Personal.Info.Spouse": "Epouse/ Conjoint/ Partenaire",
      "Update.Profile.Professional.Info.Title": "Titre",
      "Update.Profile.Professional.Info.Summary": "Résumé",
      "Update.Profile.Professional.Info.Company.Name": "Nom de l'entreprise",
      "Update.Profile.Professional.Info.Location": "Lieu",
      "Update.Profile.Professional.Info.Search.Location": "Rechercher un lieu",
      "Update.Profile.Professional.Info.Phone": "Téléphone",
      "Update.Profile.Professional.Info.Website": "Site Internet",
      "Update.Profile.Professional.Info.Invalid.Website":
        "Le site internet est invalide",
      "Update.Profile.Professional.Info.Industry": "Industrie",
      "Update.Profile.Professional.Info.Region": "Région",
      "Update.Profile.Professional.Info.Company.History":
        "Expériences professionnelles",
      "Update.Profile.Professional.Info.Company.History.Company": "Entreprise",
      "Update.Profile.Professional.Info.Company.History.Name": "Nom",
      "Update.Profile.Professional.Info.Company.History.From": "Depuis",
      "Update.Profile.Professional.Info.Company.History.To": "A",
      "Update.Profile.Professional.Info.Company.History.Current.Company":
        "Entreprise actuelle",
      "Update.Profile.Executive.Info.Name": "Nom",
      "Update.Profile.Executive.Info.Phone": "Téléphone",
      "Update.Profile.Executive.Info.Email": "E-mail",
      "Update.Profile.Executive.Info.Invalid.Email": "E-mail invalide",
      "Update.Profile.Social.Media.Facebook": "Facebook",
      "Update.Profile.Social.Media.Linkedin": "LinkedIn",
      "Update.Profile.Social.Media.Instagram": "Instagram",
      "Update.Profile.Social.Media.Twitter": "Twitter",
      "Update.Profile.Social.Media.Google.Plus": "Google Plus",
      "Update.Profile.Social.Media.Type.Your.Username.Only":
        "Ecrivez uniquement votre nom d'utilisateur",
      "Update.Profile.Button.Ok": "$t(Common:Button.Ok)",
      "Update.Profile.Hint": "Ecrivez uniquement votre nom d'utilisateur",
      "Update.Profile.Toast.Update":
        "Votre profil a été mis à jour avec succès !",
      "Update.Profile.Toast.Fill":
        "Merci de compléter tous les champs requis !",
      "Update.Profile.Heading.Partner": "Epouse / Conjoint / Partenaire",
      "Update.Profile.Button.Saving": "Enregistrement en cours",
      "Update.Profile.Button.Save": "Enregistrer",
      "Update.Profile.Validator.Title": " est requis",
      "Update.Profile.Validator.Title.Invalid": " est invalide",
      "Update.Profile.Validator.Title.Input":
        "Merci d'écrire un caractère valide ",
      "Update.Profile.Validator.Title.Input.Number": "(+12025555555)",
      "Update.Profile.Title.Company": "Entreprise",
      "Update.Profile.Title.From": "Depuis",
      "Update.Profile.Title.To": "A",
      "Update.Profile.Title.Current.Company": "Entreprise actuelle",
      "Update.Profile.Successfully":
        "Vous vous êtes inscrits avec succès aux candidatures de {{organizationName}}.",
      "Update.Profile.User.Not.Activated": "L'utilisateur n'est pas activé.",
      "Setting.ExportData": "Exporter les données",
      "Setting.ExportData.Ready": "Exporter le fichier prêt à être ouvert",
      "Setting.ExportData.Members": "Membres",
      "Setting.Account": "Compte",
      "Setting.Edit.Profile": "Modifier le Profil",
      "Setting.Change.Passcode": "Modifier le code",
      "Setting.Heading.Change": "Changer",
      "Setting.Password": "Mot de passe",
      "Setting.Passcode": "Code",
      "Setting.Auto.Play": "Lire automatiquement les vidéos sur le fil",
      "Container.Index.Option.1": "Tous les posts et les activités",
      "Container.Index.Option.2": "Uniquement quand mes posts sont commentés",
      "Container.Index.Option.3":
        "Recevoir des notifications push seulement pour les @mentions",
      "Container.Index.Option.4": "Une fois toutes les 10 minutes",
      "Container.Index.Option.5": "Une fois toutes les heures",
      "Container.Index.Option.Daily": "Quotidiennement",
      "Container.Index.Option.Never": "Jamais",
      "Container.Index.Option.Weekly": "Hebdomadairement",
      "Container.Index.Option.Always": "Toujours",
      "Container.UpdateProfile.State.Info":
        "L'administrateur est en train de vérifier votre adhésion. Vous serez notifié(e) lorsque cela sera réalisé. \nEn attendant, merci de compléter votre profil.",
      "Container.Update.Profile.Template.Info": "Information personnelle",
      "Container.Register.First.Name": "Prénom",
      "Container.Register.Successfully":
        "Vous vous êtes inscrit(e) avec succès aux candidatures pour {{setting.organizationName}}.",
      "Container.Register.Error":
        "Les inscriptions sont temporairement bloquées.",
      "Container.Register.Password": "Mot de passe",
      "Container.Register.Passcode": "Code",
      "Container.Login.Password": "Mot de passe",
      "Container.Login.Passcode": "Code",
      "Container.Login.Error.Phone.Email":
        "Merci de compléter votre numéro de téléphone/e-mail.",
      "Container.Login.Error.Phone":
        "Merci de compléter votre numéro de téléphone.",
      "Container.Login.Error.Phone.Correct":
        "Merci d'entrer un numéro de téléphone valide.",
      "Container.Login.Error.Email.Correct":
        "Merci d'entrer une adresse e-mail valide.",
      "Container.Login.Sms":
        "Un SMS contenant votre nouveau code vous a été envoyé.",
      "Container.Login.Email": "Merci d'entrer votre e-mail.",
      "Container.Login.Email.Correct":
        "Merci d'entrer une adresse e-mail valide",
      "Container.Login.Email.Recovery":
        "Merci de regarder l'e-mail de récupération et suivez les instructions.",
      "Container.Login.Error.All.Fields": "Merci de compléter tous les champs.",
      "Container.Login.Error.Correct.Password":
        "Merci de corriger votre mot de passe (8+ carac.)",
      "Container.Login.Error.Confirm.Password":
        "Merci de corriger votre mot de passe de confirmation",
      "Container.Login.Error.Correct.Passcode":
        "Merci de corriger votre code (4 chiffres)",
      "Container.Login.Error.Confirm.Passcode":
        "Merci de corriger votre code de confirmation",
      "Container.Login.Update": "Nous avons mis à jour votre ",
      "Container.Login.Successful": "avec succès !",
      "Crop.Image.Avatar.Modal.Header": "Changer votre avatar",
    },
    VideoLibrary: { "VideoDetail.Alert.Text": "Vidéo non trouvée !" },
    Subscription: {
      "Product.Description": "Description:",
      "Product.Prices": "Options:",
      "Product.Subscribe": "Appuyer pour s'abonner",
      "Subscription.Canceling": "Finalisation",
      "Subscription.Plan": "Projet :",
      "Subscription.Description": "Description :",
      "Subscription.Price": "Option :",
      "Subscription.Resume": "Poursuivre l'inscription",
      "Subscription.End": "Terminer l'inscription",
      "Subscription.PaymentMethod.Update":
        "Mettre à jour les méthodes de paiement",
      "Subscription.MyPlans": "Mes projets",
      "Subscription.Exists.Alert.Title": "Alerte l'inscription",
      "Subscription.Exists.Alert.Description":
        'Vous êtes déjà inscrit à "{{plan}}". Voulez-vous continuer?',
    },
    WorkSpace: {
      "Index.Heading.Select": "Sélectionner votre communauté pour continuer",
      "Index.Label.Community.Name": "Nom de la communauté",
      "Index.Heading.Phone":
        "Merci d'entrer un numéro de téléphone ou une adresse e-mail pour continuer",
      "Index.Placeholder.Phone.Email":
        "Entrer un numéro de téléphone ou une adresse e-mail",
      "Index.Button.Loading": "Chargement en cours",
      "Index.Button.Find": "Trouver une communauté",
      "Index.Button.Existing.Community":
        "Se connecter à une communauté existante",
      "Index.Button.Next.Step": "Suivant",
      "Index.Heading.Account": "Pas de compte ?",
      "Index.Sign.Up": "S'inscrire",
      "Index.Heading.Community":
        "Merci d'indiquer votre communauté pour continuer",
      "Index.Placeholder.Community": "Entrer le nom de la communauté",
      "Index.Button.Next": "Suivant",
      "Index.Heading.Already.Account": "Déjà un compte ?",
      "Index.Button.Sign.In": "Se connecter",
      "Index.Enter.Phone.Number.Info":
        "Le numéro de téléphone doit inclure le code du pays",
      "Index.Page.Title": "Choisir une communauté",
      "Container.Index.Error":
        "Merci d'entrer votre numéro de téléphone ou votre adresse e-mail.",
      "Container.Action.Message": "Communauté non trouvée.",
      "Container.Action.Message.Not.Found": "Aucune communauté trouvée pour",
      "Container.Action.Message.Not.Provided": "Nom d'utilisateur non écrit",
    },
    More: {
      "More.Heading.Payments": "Paiements",
      "More.Heading.Resources": "Ressources",
      "More.Heading.Video": "Librairie Vidéos",
      "More.Heading.Control.Panel": "Tableau de commandes",
      "More.Heading.Introducing": "Visite de l'application",
      "More.Heading.Report": "Remonter des bugs & retours",
      "More.Heading.Help.Desk": "Centre d'aide",
      "More.Heading.Account": "Paramètres du compte",
    },
    Common: {
      "Button.Ok": "OK",
      "Button.Cancel": "Annuler",
      "Button.Close": "Fermer",
      "Button.Retry": "Réessayer",
      "Button.Yes": "Oui",
    },
  },
};
