import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { contains, filter } from "underscore";

import { parseString } from "../../../lib/utils";
import { EventItem } from "../../../types/Event";

import { EventListSection } from "./EventListSection";

const localizer = momentLocalizer(moment);

export type EventCalendarProps = {
  data: EventItem[];
  changeMonth: (date: Date) => void;
};

export const EventCalendar: React.FC<EventCalendarProps> = ({
  data,
  changeMonth,
}) => {
  const history = useHistory();
  const [activeEvent, setActiveEvent] = useState(false);
  const [eventId, setEventId] = useState("");

  const handleEventClick = (): void => {
    history.push(`/event-detail/${eventId}`);
  };
  const getEventHref = (): string => `/event-detail/${eventId}`;

  const handleEventSelect = (event: { id: string }): void => {
    setEventId(event.id);
    setActiveEvent(true);
  };

  /**@FIXME events in calendar don't load in some cases if event begin in previous or ends next month (also on native and ionic). */
  const onChangeMonth = (date: Date): void => {
    changeMonth(moment(date).toDate());
    setActiveEvent(false);
  };

  const handleDoubleClick = (event: { id: string }): void => {
    history.push(`/event-detail/${event.id}`);
  };

  /**@TODO Is it possible to simplify (e.g. make double map in one command)?*/
  const events = data.map(item => item.event);
  const singleEvents = events.map(item => ({
    id: item.id,
    start: parseString(item.startAt),
    end: parseString(item.endAt),
    title: item.title,
    allDay: item.allDayEvent,
  }));

  const filterEvents = filter(data, item => {
    return contains(item.event, eventId);
  });

  return (
    <div id={"calendarView"}>
      <Calendar
        localizer={localizer}
        events={singleEvents}
        onNavigate={onChangeMonth}
        onDoubleClickEvent={handleDoubleClick}
        onSelectEvent={handleEventSelect}
        views={["month"]}
        popup
        messages={{
          next: <i className="icon-arrow-right" />,
          previous: <i className="icon-arrow-left" />,
        }}
      />
      {activeEvent && (
        <EventListSection
          eventsNumber={false}
          onEventClick={handleEventClick}
          getEventHref={getEventHref}
          data={filterEvents}
        />
      )}
    </div>
  );
};
