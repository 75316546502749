import React, { Component } from "react";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import {
  MatchProps,
  ResourceItem,
  ResourceUrlItem,
  RootState,
  SettingState,
  UserDataState,
} from "../../types";

import { getResource, getResources } from "./action";

export type ResourceDetailOwnProps = {
  Layout: React.ReactType<ResourceDetailLayoutProps>;
} & MatchProps;

export type ResourceDetailLayoutProps = {
  error: null;
  item: ResourceItem | ResourceUrlItem | null | undefined;
  refreshing: boolean;
  setting: SettingState | {};
  user: UserDataState | {};

  reFetch: (refresh: boolean, callback?: () => void) => void;
};

export type ResourceDetailDispatchProps = {
  getResource: (
    id: string,
    refresh: boolean,
    callback: (data: ResourceItem) => void
  ) => Promise<void>;
  getResources: (next: boolean, refresh: boolean) => Promise<void>;
};

export type ResourceDetailStateProps = {
  user: UserDataState | {};
  item: ResourceItem | ResourceUrlItem | undefined;
  setting: SettingState | {};
};
export type ResourceDetailProps = ResourceDetailOwnProps &
  ResourceDetailStateProps &
  ResourceDetailDispatchProps;
export type ResourceDetailState = {
  error: null;
  refreshing: boolean;
};

class ResourceDetail extends Component<
  ResourceDetailProps,
  ResourceDetailState
> {
  state = {
    error: null,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false);
    track("View Screen", {
      Screen: "text-content-pages",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (refresh: boolean, callback?: () => any): void => {
    const { getResource, match, getResources } = this.props;
    const { refreshing } = this.state;
    const url = match.params?.url;

    if (refreshing || url) {
      return;
    }

    this.setState({ refreshing: true }, async () => {
      try {
        const id = match.params?.id;
        if (!url && id) {
          await getResources(false, false);
          await getResource(id, refresh, ({ id, name, fileUrl }) => {
            track("View Content Page", {
              Id: id,
              Name: name,
              URL: fileUrl,
            });
          });
          this.setState({
            refreshing: false,
            error: null,
          });
          callback && callback();
        }
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, user, item, setting } = this.props;
    const { error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        refreshing={refreshing}
        user={user}
        setting={setting}
        item={item}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: ResourceDetailOwnProps
): ResourceDetailStateProps => {
  const id = ownProps.match.params?.id as string;
  const url =
    ownProps.match && ownProps.match.params && ownProps.match.params.url;

  const { resource } = state;
  const { list } = resource;
  const { cached } = list;

  return {
    user: state.user.data || {},
    setting: state.setting || {},
    item: url ? { url } : cached[id],
  };
};

const mapDispatchToProps = {
  getResource,
  getResources,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDetail);
