import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { getVideo } from "./action";
import { track } from "../../lib/track";
import { UserDataState } from "../../types/User/UserDataState";
import { SettingSettingData } from "../../types/Setting/SettingSettingData";
import { Video } from "../../types/VideoLibrary/Video";
import { MatchProps, RootState } from "../../types";

type StateProps = {
  user: UserDataState | {};
  setting: SettingSettingData | {};
  item: Video | null;
};

export type VideoDetailLayout = {
  error: null;
  item: Video | null;
  refreshing: boolean;
  setting: SettingSettingData | {};
  user: UserDataState | {};

  reFetch: (refresh: boolean, callback?: () => void) => boolean | void;
};

type DispatchProps = {
  getVideo: (
    id: string,
    refresh: boolean,
    callback: (data: Video) => void
  ) => Promise<void>;
};

type Props = {
  Layout: ComponentType<VideoDetailLayout>;
} & StateProps &
  DispatchProps &
  MatchProps;

type State = {
  error: null;
  refreshing: boolean;
};

class VideoDetail extends Component<Props, State> {
  state: State = {
    error: null,
    refreshing: false,
  };

  componentDidMount(): void {
    this.fetchData(false);

    track("View Screen", {
      Screen: "video-detail",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = (refresh: boolean, callback?: () => void): void => {
    const { getVideo, match } = this.props;
    const { refreshing } = this.state;
    if (refreshing) {
      return;
    }

    this.setState({ refreshing: true }, async () => {
      try {
        const id = match.params?.id;
        if (id) {
          await getVideo(id, refresh, ({ id, title }) => {
            track("View video in the library", {
              Id: id,
              Title: title,
            });
          });
          this.setState({ refreshing: false, error: null });
          callback && callback();
        }
      } catch (error) {
        this.setState({ refreshing: false, error: error });
      }
    });
  };

  render = (): JSX.Element => {
    const { Layout, user, item, setting } = this.props;
    const { error, refreshing } = this.state;
    return (
      <Layout
        error={error}
        refreshing={refreshing}
        user={user}
        setting={setting}
        item={item}
        reFetch={this.fetchData}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: MatchProps
): StateProps => {
  const id = ownProps.match.params?.id as string;
  const { video } = state;
  const { list } = video;
  const { items, cached } = list;
  let item = (id && ((items && items[id]) || (cached && cached[id]))) || null;
  if (item && !item.cloudinary && item.serviceName === "Cloudinary") {
    item = {
      ...item,
      cloudinary: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        public_id: item.serviceId as string,
        // eslint-disable-next-line @typescript-eslint/camelcase
        cloud_name: "groupfire",
      },
    };
  }

  return {
    user: state.user.data || {},
    setting: state.setting || {},
    item,
  };
};

const mapDispatchToProps = {
  getVideo: getVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetail);
