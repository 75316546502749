import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { MentionSearchLinkInfo } from "../../../types";

import { runQuery } from "../../http";

export const searchAnnouncement = async (
  text: RegExp
): Promise<MentionSearchLinkInfo[] | undefined> => {
  const parseResponse = await runQuery("Announcement", (query: Parse.Query) => {
    query.matches("title", text, "i");
    //@FIXME this is weird: ts says that keys must be string type here when in parse index.d.ts keys has a K[] type
    // select<K extends (keyof T['attributes'] | keyof BaseAttributes)>(...keys: K[]): this;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    query.select<string>([
      "objectId",
      "title",
      "thumbUrl",
      "thumbImage",
      "imageUrl",
      "image",
    ]);
    query.limit(10);
    return query.find();
  });

  if (!_.isEmpty(parseResponse)) {
    return convertObject(parseResponse).map(
      (item: { id: string; title: string; thumbUrl: string }) => {
        return {
          id: item.id,
          name: item.title,
          imageUrl: item.thumbUrl,
          url: "/announcement/" + item.id,
        };
      }
    );
  } else return undefined;
};
