import { defaultTheme } from "../../constants";

import { SettingState } from "../../types";

export const settingStateInit: SettingState = {
  error: null,
  setting: null,
  config: null,
  localConfig: null,
  theme: defaultTheme,
};

export default settingStateInit;
