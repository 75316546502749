import React, { CSSProperties, SyntheticEvent } from "react";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { isEmpty } from "underscore";

import { textThemingWorkaround } from "../../../../constants";
import MediaPlayerContainer from "../../../../containers/Media";
import { DocumentTitle } from "../../../../containers/routers/DocumentTitle";

import { fromNow, toCapitalizeCase, displayDate } from "../../../../lib/utils";

import {
  AnnouncementItem,
  CommentRequest,
  CommentType,
  EmptyObject,
  ReactionType,
  UserDataState,
} from "../../../../types";

import { useTheme } from "../../../hooks/useTheme";

import {
  CommentBar,
  EventBar,
  EventCardAttendees,
  Heading,
  HTML,
  Media,
  MediaFileList,
  MediaLink,
  TagList,
} from "../..";

export type AnnouncementCardProps = {
  disabledCommentBar?: boolean;
  className?: string;
  href?: string;
  item: AnnouncementItem;
  screenInActive?: boolean;
  setting: {
    ClientHostName?: string;
    confettiEffectActions: [];
  };
  showAttachementsList?: boolean;
  showAuthor?: boolean;
  showBody?: boolean;
  showHeader?: boolean;
  showReadMoreButton?: boolean;
  showSubTitle?: boolean;
  style?: CSSProperties;
  user?: UserDataState | EmptyObject;

  addComment?: (
    id: string,
    type: CommentType,
    data: CommentRequest,
    beforeAdding?: (callback?: () => void) => void
  ) => Promise<void>;
  doReaction?: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  onClick?: (e: SyntheticEvent) => void;
};

export const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
  className,
  /** Disables any reaction/comment/link in CommentBar */
  disabledCommentBar,
  item: {
    announcement,
    attendees,
    comments,
    isLiked,
    readHeadlineBy,
    reaction,
  },
  href,
  screenInActive,
  setting,
  showAttachementsList,
  showAuthor,
  showBody,
  showHeader = true,
  showReadMoreButton,
  showSubTitle,
  style,
  user,

  addComment,
  doReaction,
  onClick,
}) => {
  const {
    button,
    card,
    image,
    text: { subHeader, cardText, listText, header },
  } = useTheme();

  const history = useHistory();

  const { t } = useTranslation("Announcement");

  const {
    author,
    body,
    embedDetails,
    event,
    linksMentions,
    mentions,
    publishOn,
    strapLine,
    tags,
    thumbUrl,
    timezone,
    title,
    type,
    url,
    video,
  } = announcement;

  const isEvent = event && announcement.for === "event";

  const { attachments, commentCount, likeCount, reactionCount, objectId } =
    event && announcement.for === "event" ? event : announcement;
  const commentType = isEvent ? "event" : "announcement";

  const handleClick = (e: SyntheticEvent): void => {
    e.preventDefault();

    console.debug(`AnnouncementCard handleClick ${objectId} type: ${type}`);

    onClick?.(e);
  };

  const handleTotalAttendeesClick = (): void => {
    href && history.push(href, { activeTab: "Attendees" });
  };

  const announcementCardStyles = {
    authorDate: {
      ...listText,
      ...textThemingWorkaround,
      paddingLeft: 0,
    },
    authorHeading: {
      ...cardText,
      ...textThemingWorkaround,
      display: "inline-block",
      marginRight: 5,
    },
    card: { ...style, backgroundColor: card.backgroundColor },
    cardBody: {
      backgroundColor: "transparent",
      paddingLeft: 0,
      paddingRight: 0,
    },
    cardText: { ...cardText, ...textThemingWorkaround },
    headerCardTypeName: {
      ...subHeader,
      ...textThemingWorkaround,
      opacity: 0.8,
    },
    headerDate: { ...subHeader, ...textThemingWorkaround },
    icon: {
      fontSize: 20,
      marginLeft: -5,
      marginTop: -2,
      width: 25,
    },
    readMoreAnchor: {
      ...listText,
      ...textThemingWorkaround,
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: 500,
      color: button.backgroundColor,
    },
    titleHeading: { ...header, ...textThemingWorkaround },
  } as const;

  const hasBody = !isEmpty(body) && body !== "<p><br></p>";
  const hasSubTitle = event ? false : !isEmpty(strapLine);

  const headerCardTypeName = event
    ? t("Card.Header.Title.Event")
    : video
    ? t("Card.Header.Title.Video")
    : t("Card.Header.Title.Announcement");

  const headerElement = showHeader && (
    <CardBody className="d-flex p-3" style={announcementCardStyles.cardBody}>
      <div
        id={`announcementHeaderType${objectId}`}
        className="flex-fill text-left"
        style={announcementCardStyles.headerCardTypeName}>
        {headerCardTypeName}
      </div>
      <div
        id={`announcementHeaderPublishTime${objectId}`}
        className="flex-fill text-right"
        style={announcementCardStyles.headerDate}>
        {fromNow(publishOn)}
      </div>
    </CardBody>
  );

  const announcementTitle = toCapitalizeCase(title);

  const eventElement = event && (
    <>
      <EventBar className="mt-3 small text-muted mb-3" event={event} />
      <EventCardAttendees
        attendees={attendees}
        onTotalAttendeesClick={handleTotalAttendeesClick}
      />
    </>
  );

  const linkElement = type === "url" && url && (
    <MediaLink metadata={embedDetails} url={url} imageUrl={thumbUrl} />
  );

  const mediaElement = (
    <MediaPlayerContainer
      Layout={Media}
      alt={announcementTitle}
      href={href}
      image={{ thumbUrl }}
      pauseWhenInvisible={true}
      screenInActive={screenInActive}
      style={image}
      video={video}
      onClick={handleClick}
    />
  );

  const titleElement = (
    <Heading style={announcementCardStyles.titleHeading}>
      <a
        id={`announcementTitleLink${objectId}`}
        href={href}
        onClick={handleClick}
        title={announcementTitle}
        className="text-body font-weight-normal">
        {announcementTitle}
      </a>
    </Heading>
  );

  const authorElement = showAuthor && author && (
    <CardBody className="pt-1 pb-0" style={announcementCardStyles.cardBody}>
      <Heading
        id={`announcementAuthor${objectId}`}
        style={announcementCardStyles.authorHeading}
        text={`Posted by ${author.firstName} ${author.lastName} (${displayDate(
          publishOn,
          "MMM D, YYYY",
          timezone
        )})`}
      />
    </CardBody>
  );

  const subtitleElement = showSubTitle && hasSubTitle && (
    <CardBody className="pt-1 pb-0" style={announcementCardStyles.cardBody}>
      <Heading
        className="py-2 mb-0 small"
        text={strapLine}
        style={announcementCardStyles.cardText}></Heading>
    </CardBody>
  );

  const bodyElement = showBody && hasBody && (
    <HTML
      body={body}
      className="px-3 pt-0 pb-3 mt-3"
      mentions={mentions}
      linksMentions={linksMentions}
    />
  );

  const attachementsListElement = showAttachementsList && (
    <CardBody className="pt-1 pb-0" style={announcementCardStyles.cardBody}>
      <MediaFileList
        data={attachments}
        header={t("Detail.Heading.Attachments.Text")}
      />
    </CardBody>
  );

  const readMoreElement = showReadMoreButton &&
    (hasBody || announcement.for === "event_recap") && (
      <CardBody
        className="pb-2 px-3 pt-2 small"
        style={announcementCardStyles.cardBody}>
        <a
          id={
            announcement.for === "event_recap"
              ? `SeeRecap${objectId}`
              : `announcementReadMoreLink${objectId}`
          }
          onClick={handleClick}
          href={href}
          className="text-primary"
          style={announcementCardStyles.readMoreAnchor}>
          {/**  @TODO Fill missing translations */}
          {announcement.for === "event_recap" ? "See Recap" : "Read more"}
        </a>
      </CardBody>
    );

  const tagsElement = (
    <TagList
      id={objectId}
      data={tags}
      type="announcement"
      className="pt-2 px-3"
    />
  );

  return (
    <>
      <DocumentTitle pageTitle={announcementTitle} />
      <Card
        id={`announcementCard${objectId}`}
        className={classnames("announcement-card mb-3", className)}
        style={announcementCardStyles.card}>
        {headerElement}

        <CardBody className="py-0" style={announcementCardStyles.cardBody}>
          {mediaElement}
        </CardBody>
        <CardBody
          className="pt-3 pb-0 px-3"
          style={announcementCardStyles.cardBody}>
          {titleElement}
        </CardBody>
        {(eventElement || linkElement || subtitleElement || authorElement) && (
          <CardBody className="pb-0 pt-0 px-3">
            {eventElement}
            {authorElement}
            {subtitleElement}
            {linkElement}
          </CardBody>
        )}

        {bodyElement}
        {tagsElement}
        {attachementsListElement}
        {readMoreElement}

        <CommentBar
          addComment={addComment}
          comments={comments}
          commentCount={commentCount}
          disabled={disabledCommentBar}
          doReaction={doReaction}
          id={objectId}
          isLiked={isLiked}
          likeCount={likeCount}
          reaction={reaction}
          reactionCount={reactionCount}
          seenCount={readHeadlineBy || announcement.readHeadlineCount}
          setting={setting}
          type={commentType}
          user={user}
        />
      </Card>
    </>
  );
};
