import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import _ from "underscore";

import { localConfig } from "../constants/config";

import { i18nDictionary } from "./i18nDictionary";

let language;
if (!_.isEmpty(localConfig.language)) {
  language = localConfig.language;
} else {
  language = navigator.language;
}

i18next.use(initReactI18next).init({
  debug: false,
  resources: i18nDictionary,
  fallbackLng: "en",
  lng: language,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

/**
 * @TODO
 * Investigate is it necessary to export i18next instance here.
 * Probably not and following exports could be removed and all
 * imports in app changed to `import i18next from "i18next";`
 * instead.
 */
export { i18next, i18next as i18n };

export default i18next;
