import { toTitleCase } from "./utils";

export function track(event, data) {
  window.track && window.track(event, data);
}

export function trackComment(event, data, params) {
  let { id, type } = data;
  if (type === "post") {
    type = "groupPost";
  }
  switch (type) {
    case "event":
      track(
        toTitleCase(event),
        Object.assign(
          {
            "Event ID": id,
            "Object Type": type,
            "Object ID": id,
          },
          params
        )
      );
      break;
    default:
      track(
        toTitleCase(event),
        Object.assign(
          {
            "Announcement ID": id,
            "Object Type": type,
            "Object ID": id,
          },
          params
        )
      );
      break;
    case "groupPost":
      track(
        toTitleCase(event.replace("post", "Group Post")),
        Object.assign(
          {
            "Post ID": id,
            "Object Type": type,
            "Object ID": id,
          },
          params
        )
      );
      break;
    case "eventPhoto":
      track(
        toTitleCase(event.replace("eventPhoto", "Event Photo")),
        Object.assign(
          {
            "Event Photo ID": id,
            "Object Type": type,
            "Object ID": id,
          },
          params
        )
      );
      break;
  }
}
