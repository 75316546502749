import {
  getComments as _getComments,
  addComment as _addComment,
  doReaction as _doReaction,
  updateComment as _updateComment,
  deleteComment as _deleteComment,
  getReactions as _getReactions,
  getSeens as _getSeens,
} from "../../services/api";
import { logException } from "../../lib/utils";
import { page as _page } from "../../constants";
import {
  AppThunkAction,
  AppThunkDispatch,
  GetState,
  ReactionType,
} from "../../types";
import {
  ANNOUNCEMENT_COMMENT_SET,
  ANNOUNCEMENT_REACTION_SET,
} from "../Announcement/constants";
import { POST_COMMENT_SET, POST_REACTION_SET } from "../Post/constants";

import {
  COMMENT_DELETE,
  COMMENT_SET,
  COMMENT_UPDATE,
  REACTION_SET,
  SEEN_SET,
} from "./constants";
import { EVENT_COMMENT_SET, EVENT_REACTION_SET } from "../Event/constants";
import { CommentRequest } from "../../types/Comment/CommentRequest";
import { CommentResponse, CommentType } from "../../types/Comment";
import _ from "underscore";
import { UserDataState } from "../../types/User/UserDataState";
import { CommentResponseDict } from "./types";

export const getComments = (
  id: string,
  type: CommentType,
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] getComments");

    const { comment } = getState();
    const { commentsList } = comment;
    let { page = 0 } = commentsList[id] || {};
    const { items = {} } = commentsList[id] || {};

    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
      }
      if (next) {
        page++;
      }
      const options = {
        id,
        type,
        per: _page.comment,
        page,
        direction: "desc" as const,
      };

      let data = await _getComments(options);

      const hasMore = Object.keys(data).length === _page.comment;

      data = {
        ...data,
        ...(refresh ? {} : items),
      };

      dispatch({
        type: COMMENT_SET,
        data: {
          id: id,
          data: {
            items: data,
            page,
            hasMore,
          },
        },
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const syncComment = (
  id: string,
  type: CommentType,
  data: CommentResponse[],
  groupId: string | undefined,
  /** This value is temporarily necessary to update UserPosts */
  postAuthorId?: string
): AppThunkAction => async (dispatch: AppThunkDispatch): Promise<void> => {
  console.debug("[Action] syncComment", type);

  switch (type) {
    case "announcement":
      dispatch({
        type: ANNOUNCEMENT_COMMENT_SET,
        data: { id: id, items: data },
      });
      break;
    case "post":
      dispatch({
        type: POST_COMMENT_SET,
        data: { id: id, items: data, groupId: groupId as string, postAuthorId },
      });
      break;
    case "event":
      dispatch({
        type: EVENT_COMMENT_SET,
        data: { id: id, items: data },
      });
      dispatch({
        type: ANNOUNCEMENT_COMMENT_SET,
        data: { id: id, items: data },
      });
      break;
    case "eventPhoto":
      break;
    default:
  }
};

export const syncReaction = (
  id: string,
  type: CommentType,
  reaction: ReactionType,
  groupId: string,
  authorId?: string
): AppThunkAction => async (dispatch: AppThunkDispatch): Promise<void> => {
  console.debug("[Action] syncReaction", type);

  switch (type) {
    case "announcement":
      dispatch({
        type: ANNOUNCEMENT_REACTION_SET,
        data: { id: id, reaction: reaction },
      });
      break;
    case "post":
      dispatch({
        type: POST_REACTION_SET,
        data: { id: id, reaction: reaction, groupId, authorId },
      });
      break;
    case "event":
      dispatch({
        type: ANNOUNCEMENT_REACTION_SET,
        data: { id: id, reaction: reaction },
      });
      dispatch({
        type: EVENT_REACTION_SET,
        data: { id: id, reaction: reaction },
      });
      break;
    case "eventPhoto":
      break;
    default:
  }
};

export const addComment = (
  id: string,
  type: CommentType,
  data: CommentRequest,
  beforeAdding?: (callback?: () => void) => void,
  /** This value is temporarily necessary to update UserPosts */
  postAuthorId?: string
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] addComment");
    const { user } = getState();

    //Prevent doing comment in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't comment in Guest Mode.");
    }

    const { commentsList } = getState().comment;
    let { items = {} } = commentsList[id] || {};
    const { page = 0, hasMore = false } = commentsList[id] || {};

    //add a temp new comment
    const onAddTemp = (): void => {
      /**
       * @FIXME It is good idea to add comment to store before it is persisted.
       * But we should not reuse the same action for initial comment store and update comment after API save.
       * Also keeping all comments here to save it again to store after saving to API is problematic
       * and can cause performance problems, unnecessary refreshes, etc.
       *
       * Ideally we have separate actions for request (also saving "temp" comment to store),
       * success and failure updating accordingly store.
       */

      //To make app has feel a quick response so we need to display the unsent comment on UI asap
      //Solution here is create a temp comment then add to list
      const tempComments: CommentResponseDict = {
        ...items,
        tempComment: {
          ...data,
          id: "tempComment",
          objectId: "tempComment",
          author: user.data as UserDataState,
          createdAt: new Date(),
          body: data.text as string,
        },
      };

      dispatch({
        type: COMMENT_SET,
        data: {
          id,
          data: {
            items: tempComments,
            page,
            hasMore,
          },
        },
      });
    };

    //add a real new comment
    const onAdd = async (): Promise<void> => {
      const options = {
        ...data,
        id,
        type,
      };
      const resp = await _addComment(options);

      const { group, comment } = resp;

      const groupId = group && group.id;

      items = {
        ...items,
        [comment.id]: comment,
      };

      dispatch({
        type: COMMENT_SET,
        data: {
          id,
          data: {
            items,
            page,
            hasMore,
          },
        },
      });

      dispatch(syncComment(id, type, [comment], groupId, postAuthorId));
    };

    //Fistly, add a temp data onAddTemp to update UI
    onAddTemp();
    //Secondly, handle beforeAdding (display a confetti effect) then add a real data
    if (beforeAdding) {
      beforeAdding(onAdd);
    } else {
      onAdd();
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const updateComment = (
  id: string,
  type: CommentType,
  data: CommentRequest,
  groupId?: string,
  postAuthorId?: string
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] updateComment", { id, type, data });

    const { user } = getState();
    //Prevent doing comment in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't comment in Guest Mode.");
    }

    const options = {
      ...data,
      type,
    };
    const resp = await _updateComment(options);

    dispatch({
      type: COMMENT_UPDATE,
      data: { id, data: resp, type, groupId, postAuthorId },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const deleteComment = (
  id: string,
  type: CommentType,
  data: CommentRequest,
  groupId?: string,
  postAuthorId?: string
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] deleteComment", { id, type, data });

    const { user } = getState();
    //Prevent doing comment in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't comment in Guest Mode.");
    }

    const options = {
      ...data,
      type,
    };
    dispatch({
      type: COMMENT_DELETE,
      data: { id, data, type, groupId, postAuthorId },
    });

    await _deleteComment(options);
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const doReaction = (
  id: string,
  type: CommentType,
  reaction: ReactionType,
  authorId?: string
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] doReaction", reaction);

    const { user } = getState();
    //Prevent doing reaction in Guest mode
    if (user.viewingAsGuest) {
      throw new Error("Can't react in Guest Mode.");
    }

    const options = {
      id,
      type,
      reaction,
    };
    const resp = await _doReaction(options);

    if (resp) {
      const { group } = resp;
      const groupId = group && group.id;

      dispatch(syncReaction(id, type, reaction, groupId, authorId));
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getReactions = (
  id: string,
  type: CommentType,
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    console.debug("[Action] doReaction");

    const { comment } = getState();
    const { reactionsList } = comment;
    let { page = 0 } = reactionsList[id] || {};
    const { items = {} } = reactionsList[id] || {};
    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
      }
      if (next) {
        page++;
      }
      const options = {
        id,
        type,
        per: _page.reaction,
        page,
      };

      let data = await _getReactions(options);

      const hasMore = Object.keys(data).length === _page.reaction;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: REACTION_SET,
        data: {
          id: id,
          data: {
            items: data,
            page,
            hasMore,
          },
        },
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const getSeens = (
  id: string,
  type: CommentType,
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    const { comment } = getState();
    const { seensList } = comment;
    let { page = 0 } = seensList[id] || {};
    const { items = {} } = seensList[id] || {};
    if (!_.isEmpty(items) && !refresh && !next) {
      return;
    } else {
      if (refresh) {
        page = 0;
      }
      if (next) {
        page++;
      }
      const options = {
        id,
        type,
        per: _page.reaction,
        page,
      };

      let data = await _getSeens(options);

      const hasMore = Object.keys(data).length === _page.reaction;

      data = {
        ...(refresh ? {} : items),
        ...data,
      };

      dispatch({
        type: SEEN_SET,
        data: {
          id: id,
          data: {
            items: data,
            page,
            hasMore,
          },
        },
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};
