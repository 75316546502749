import { getItemWithCommentsSet } from "../../lib/utils/Comment";
import { getNextReactedItem } from "../../lib/utils/Reaction";
import { getUpsertedItemList } from "../../lib/utils/store";

import {
  EVENT_ALL_SET,
  EVENT_COMMENT_SET,
  EVENT_PUBLISHED_SET,
  EVENT_REACTION_SET,
  EVENT_RECAP_SET,
  EVENT_RESET,
  EVENT_SEARCH_HISTORY_SET,
  EVENT_SEARCH_SET,
  EVENT_SET,
  EVENT_DRAFT_SET,
} from "./constants";
import Store from "./store";
import { EventAction, EventState } from "./types";

export const initialState = Store;

export default function reducer(
  state: EventState = initialState,
  action: EventAction
): EventState {
  switch (action.type) {
    case EVENT_RESET:
      return initialState;

    case EVENT_ALL_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        list: {
          ...state.list,
          ...action.data,
        },
      };

    case EVENT_SEARCH_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        searchList: action.data,
      };

    case EVENT_SEARCH_HISTORY_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        history: {
          ...state.history,
          [action.data]: true,
        },
      };

    case EVENT_SET:
      if (action.data) {
        return {
          ...state,
          list: {
            ...state.list,
            // items can be null due to display a placeholder loading on the feed
            // so only set data into items if items had value already
            items: state.list.items
              ? {
                  ...state.list.items,
                  [action.data.id]: action.data,
                }
              : null,
            // always set data to cached data to display detail properly
            cached: {
              ...state.list.cached,
              [action.data.id]: action.data,
            },
          },
        };
      }

      return state;

    case EVENT_RECAP_SET:
      if (action.data) {
        return {
          ...state,
          recaps: {
            ...state.recaps,
            [action.data.eventId]: action.data.data,
          },
        };
      }

      return state;

    case EVENT_COMMENT_SET:
      if (
        !state.list.items?.[action.data.id] &&
        !state.list.cached?.[action.data.id]
      ) {
        return state;
      }

      return {
        ...state,
        list: getUpsertedItemList({
          newItem: getItemWithCommentsSet({
            comments: action.data.items,
            dataKeyName: "event",
            item: state.list.items?.[action.data.id],
          }),
          newItemCached: getItemWithCommentsSet({
            comments: action.data.items,
            dataKeyName: "event",
            item: state.list.cached?.[action.data.id],
          }),
          dataKeyName: "event",
          itemList: state.list,
        }),
      };

    case EVENT_REACTION_SET:
      if (
        !state.list.items?.[action.data.id] &&
        !state.list.cached?.[action.data.id]
      ) {
        return state;
      }

      return {
        ...state,
        list: getUpsertedItemList({
          newItem: getNextReactedItem({
            item: state.list.items?.[action.data.id],
            newReaction: action.data.reaction,
            dataKeyName: "event",
          }),
          newItemCached: getNextReactedItem({
            item: state.list.cached?.[action.data.id],
            newReaction: action.data.reaction,
            dataKeyName: "event",
          }),
          dataKeyName: "event",
          itemList: state.list,
        }),
      };

    case EVENT_PUBLISHED_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        publishedList: action.data,
      };

    case EVENT_DRAFT_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        draftList: action.data,
      };

    default:
      return state;
  }
}
