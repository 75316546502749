import React from "react";
import { Col } from "reactstrap";
import { useRouteMatch, matchPath } from "react-router-dom";

import { DocumentTitle } from "../../../containers/routers/DocumentTitle";

import { Footer } from "../UI";

export type TemplateMainContentProps = {
  disabledLeftColPaths?: string[];
  disabledRightColPaths?: string[];
  disabledFooterPaths?: string[];

  children: React.ReactNode;
};

export const TemplateMainContent: React.FC<TemplateMainContentProps> = ({
  disabledLeftColPaths,
  disabledRightColPaths,
  disabledFooterPaths,

  children,
}) => {
  const { url } = useRouteMatch();

  const isLeftColEnabled = !matchPath(url, { path: disabledLeftColPaths });
  const isRightColEnabled = !matchPath(url, { path: disabledRightColPaths });
  const isFooterEnabled = !matchPath(url, { path: disabledFooterPaths });

  const mainContentLargeSize = 12 - (isRightColEnabled ? 4 : 0);

  const mainContentXLargeSize =
    12 - (isLeftColEnabled ? 3 : 0) - (isRightColEnabled ? 3 : 0);

  const footerElement = isFooterEnabled && <Footer />;

  return (
    <>
      <DocumentTitle />
      <Col lg={mainContentLargeSize} xl={mainContentXLargeSize}>
        <div id="TemplateMainContent" className="gf-main-content mx-auto pt-2">
          {children}
        </div>
        {footerElement}
      </Col>
    </>
  );
};
