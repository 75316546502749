import React from "react";

import { useTranslation } from "react-i18next";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";

import MediaPlayerContainer from "../../../containers/Media";
import { displayDate } from "../../../lib/utils";
import { Video } from "../../../types/VideoLibrary/Video";

import { HTML } from "../UI";
import { Media } from "../Media";

export type VideoDetailProps = {
  item: Video | null;
};

export const VideoDetail: React.FC<VideoDetailProps> = ({ item }) => {
  const { t } = useTranslation("VideoLibrary");
  if (!item) {
    return <Alert>{t("VideoDetail.Alert.Text")}</Alert>;
  }
  const time = displayDate(item?.date, "MMM YYYY");
  const name = `by ${item.author.name ??
    item.author.firstName + " " + item.author.lastName}`;
  const description = item.description && <HTML body={item.description} />;

  const videoInfo = item.cloudinary && {
    serviceId: item.cloudinary.public_id,
    cloudName: item.cloudinary.cloud_name,
  };

  return (
    <Card>
      <MediaPlayerContainer
        video={{
          ...item,
          ...videoInfo,
        }}
        pauseWhenInvisible={true}
        screenInActive={true}
        Layout={Media}
      />
      <CardBody id={`videoDetailCardBody${item.id}`}>
        <Row>
          <Col>
            <p
              id={`videoDetailAuthor${name.replace(/\s/g, "")}`}
              className="text-left">
              {name}
            </p>
          </Col>
          <Col>
            <p className="text-right">{time}</p>
          </Col>
        </Row>
        <Row className={"px-3 small"}>{description}</Row>
      </CardBody>
    </Card>
  );
};
