import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";

import { groupBy, map, sortBy, isEmpty } from "underscore";

import { track } from "../../lib/track";
import { moment } from "../../lib/utils";
import { getStateWhenGoback } from "../../lib/utils/checkGoBack";

import {
  Group,
  RootState,
  EventFilterType,
  EventFilters,
  SortTypes,
  GetEventsOption,
  UserDataState,
  EventItem,
} from "../../types";

import { getEvents } from "./action";
import { getCurrentUserState } from "../User/selector";
import {
  getEventsSettingState,
  getEventsHasMoreState,
  getEventsState,
} from "./selector";

type StateProps = {
  user: UserDataState | {};
  setting: { cover: string };
  data: EventItem[] | null;
  hasMore: boolean;
};

type DispatchProps = {
  getEvents: (
    option: GetEventsOption,
    next?: boolean,
    refresh?: boolean,
    changeMonth?: boolean,
    refreshWhenReactive?: boolean
  ) => Promise<void>;
};

export type ChangeEventFilterValue = {
  sort: SortTypes;
  filter: EventFilters;
  group?: Group | null;
};

export type OnEventFilterChange = <T extends EventFilterType>(
  filter: T,
  value: ChangeEventFilterValue[T],
  options?: { forceListReset?: boolean }
) => void;

export type EventsLayoutProps = {
  data: EventItem[] | null;
  dataByMonth?: {
    title: string;
    data: EventItem[];
  }[];
  dateSelected: Date;
  error: string | null;
  filter: EventFilters;
  group?: Group;
  hasMore: boolean;
  loading: boolean;
  refreshing: boolean;
  renderActions?: boolean;
  renderActionsDone?: boolean;
  setting: { cover: string };
  sort: SortTypes;
  user: UserDataState | {};
  changeDate: (date: Date) => void;
  changeFilter: OnEventFilterChange;
  resetFilter: () => void;
  changeMonth: (date: Date) => void;
  changeTab: (tab: { i: number }) => void;
  reFetch: (
    next: boolean,
    refresh: boolean,
    changeMonth?: boolean | undefined,
    refreshWhenReactive?: boolean
  ) => boolean | void;
};

type Props = {
  Layout: ComponentType<EventsLayoutProps>;
  match?: {
    params: { [key: string]: string };
    isExact: boolean;
    path?: string;
    url?: string;
  };
  renderActions?: boolean;
  renderActionsDone?: boolean;
} & StateProps &
  DispatchProps;

type State = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
  filter: EventFilters;
  sort: SortTypes;
  date: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  group: any; // @TODO add typing while typing group container
};

class Events extends Component<Props, State> {
  state: State = {
    error: null,
    loading: false,
    refreshing: false,
    filter: "upcoming",
    sort: "ascending",
    date: new Date(),
    group: null,
  };

  componentDidMount = async (): Promise<void> => {
    const lastState = await getStateWhenGoback();
    if (lastState !== "events") {
      this.fetchData(false, true, false);
    }

    track("View Screen", {
      Screen: "events",
      Params: this.props.match && this.props.match.params,
    });
  };

  fetchData = (
    next: boolean,
    refresh: boolean,
    changeMonth?: boolean,
    refreshWhenReactive?: boolean
  ): void => {
    const { getEvents, data } = this.props;
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }

    this.setState(
      { refreshing: refresh && !isEmpty(data), loading: next },
      async () => {
        try {
          const { filter, sort, date, group } = this.state;
          const options = { filter, sort, date, group };

          await getEvents(
            options,
            next,
            refresh,
            changeMonth,
            refreshWhenReactive
          );
          this.setState({
            loading: false,
            refreshing: false,
            error: null,
          });
        } catch (error) {
          this.setState({
            loading: false,
            refreshing: false,
            error: error,
          });
        }
      }
    );
  };

  onChangeFilter: OnEventFilterChange = <T extends EventFilterType>(
    filter: T,
    value: State[T],
    options?: { forceListReset?: boolean }
  ): void => {
    this.setState({ [filter]: value } as Pick<State, T>, () => {
      this.fetchData(false, true, false, options?.forceListReset);
    });

    track("Filter Events", {
      [filter]: value,
    });
  };

  onResetFilter = (): void => {
    this.setState(
      { filter: "upcoming", group: null, sort: "ascending" },
      () => {
        this.fetchData(false, true, false);
      }
    );
  };

  onChangeDate = (date: Date): void => {
    this.setState({ date });
  };

  onChangeMonth = (date: Date): void => {
    this.setState({ date, filter: "all" }, () => {
      this.fetchData(false, false, true);
    });
  };

  onChangeTab = (tab: { i: number }): void => {
    let filter: EventFilters;
    if (tab.i === 0) {
      filter = "upcoming";
    } else {
      filter = "all";
    }
    if (filter !== this.state.filter) {
      this.setState({ filter }, () => {
        /**
         * @PROPOSAL
         * I think that resetting data when changing EventList is
         * not user friendly.
         */
        if (filter === "all") {
          this.fetchData(false, false, true);
        } else {
          this.fetchData(false, true);
        }
      });
    }
  };

  render = (): JSX.Element => {
    const {
      Layout,
      user,
      data,
      setting,
      hasMore,
      renderActions,
      renderActionsDone,
    } = this.props;
    const {
      loading,
      error,
      refreshing,
      date,
      filter,
      sort,
      group,
    } = this.state;
    //display events on sections list correctly
    let dataByMonth;
    if (data) {
      dataByMonth = groupBy(
        sortBy(data, ({ month }) => {
          if (filter === "upcoming") {
            return sort === "ascending"
              ? moment(month, "MMMM YYYY")
              : -moment(month, "MMMM YYYY");
          } else {
            return sort === "ascending"
              ? -moment(month, "MMMM YYYY")
              : moment(month, "MMMM YYYY");
          }
        }),
        "month"
      );
      dataByMonth = map(dataByMonth, (item, index) => {
        return { title: index, data: item };
      });
    }

    return (
      <Layout
        error={error}
        loading={loading}
        refreshing={refreshing}
        hasMore={hasMore}
        user={user}
        setting={setting}
        data={data}
        dataByMonth={dataByMonth}
        dateSelected={date}
        filter={filter}
        group={group}
        sort={sort}
        changeDate={this.onChangeDate}
        changeMonth={this.onChangeMonth}
        changeTab={this.onChangeTab}
        changeFilter={this.onChangeFilter}
        resetFilter={this.onResetFilter}
        reFetch={this.fetchData}
        renderActions={renderActions}
        renderActionsDone={renderActionsDone}
      />
    );
  };
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    user: getCurrentUserState(state),
    setting: getEventsSettingState(state),
    data: getEventsState(state),
    hasMore: getEventsHasMoreState(state),
  };
};

const mapDispatchToProps = {
  getEvents: getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
