import {
  PAYMENT_INVOICE_SET,
  PAYMENT_RECEIPT_SET,
  PAYMENT_RESET,
} from "./constants";
import Store from "./store";
import { PaymentAction, PaymentState } from "./types";

export const initialState = Store;

export default function reducer(
  state: PaymentState = initialState,
  action: PaymentAction
): PaymentState {
  switch (action.type) {
    case PAYMENT_RESET:
      return initialState;

    case PAYMENT_INVOICE_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          ...action.data,
        },
      };

    case PAYMENT_RECEIPT_SET:
      if (!action.data) {
        return state;
      }

      return {
        ...state,
        receiptList: {
          ...state.receiptList,
          ...action.data,
        },
      };

    default:
      return state;
  }
}
