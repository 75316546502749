import _ from "underscore";
import { runQuery } from "../../http";
import { convertObject } from "../../../lib/utils";
import { InvoiceData } from "../../../types/Payment/InvoiceData";

export const getInvoice = async (
  id: string,
  userId?: string
): Promise<InvoiceData> => {
  const requireUser = _.isEmpty(userId);
  let user = Parse.User.current();
  if (!_.isEmpty(userId)) {
    user = new Parse.User({ id: userId });
  }
  const parseResponse = await runQuery(
    "Invoice",
    (query: Parse.Query) => {
      query.equalTo("user", user);
      query.include(["payment"]);
      query.equalTo("objectId", id);
      return query.first();
    },
    { spinner: false, requireUser: requireUser }
  );

  return convertObject(parseResponse);
};
