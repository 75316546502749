import React, { ChangeEvent, FC } from "react";

import classnames from "classnames";
import { Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

export type SearchBoxProps = {
  autoFocus?: boolean;
  className?: string;
  id?: string;
  loading?: boolean | null;
  placeholder?: string;
  submitButtonText?: string;
  value?: string;

  onSubmit?: () => void;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

/**
 * @FIXME There is another component called Search. In Search directory.
 * Reconsider rename to SearchBox (as in native?)
 */
export const SearchBox: FC<SearchBoxProps> = ({
  autoFocus,
  className,
  /** Identifier should be unique so there is no sense for setting default value */
  id = "search",
  loading,
  placeholder,
  submitButtonText,
  value,

  onSubmit,
  onValueChange,
}) => {
  const { t } = useTranslation("UI");

  const submitButton = onSubmit && (
    <InputGroupAddon addonType="append">
      <Button color="primary" onClick={onSubmit} disabled={!!loading}>
        {submitButtonText ?? t("SearchBox.Submit.Button.Text")}
      </Button>
    </InputGroupAddon>
  );

  return (
    <InputGroup
      className={classnames(
        "pl-3",
        "pr-1",
        "py-1",
        "bg-white",
        "rounded-sm",
        className
      )}>
      <InputGroupAddon addonType="prepend">
        <i className="icon-magnifier justify-content-center align-self-center mr-2" />
      </InputGroupAddon>
      <Input
        autoFocus={autoFocus}
        className="border-0 mr-1 text-small"
        disabled={!!loading}
        type="text"
        /** @FIXME Do we need name here? */
        name="Search"
        id={id}
        placeholder={placeholder ?? t("SearchBox.Placeholder")}
        value={value}
        onChange={onValueChange}
      />
      {submitButton}
    </InputGroup>
  );
};
