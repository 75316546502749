import { UserDataState, SettingState } from "../../../types";

import { registerMixPanel } from ".";

export function registerAuthenticatedServices(
  user: UserDataState,
  setting: SettingState
): void {
  const { localConfig } = setting;
  registerMixPanel(user, localConfig);
}
