import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { ChannelsSidebar } from "../Chat";
import ChatDetailContainer from "../../../containers/Chat/Detail";
import { ChatConversation } from "../Chat/Conversation";
import NotificationLandingContainer from "../../../containers/Notification/Landing";
import { NotificationLandingSideBar } from "../Notification";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ChatMutatedChannel } from "../../../containers/Chat/chatTypes";
import { useTheme } from "../../hooks";

type Props = {};

export const TemplateRightSidebar: React.FC<Props> = () => {
  const [chatChannel, setChatChannel] = useState(
    (null as unknown) as ChatMutatedChannel
  );

  const {
    navigation: {
      backgroundColor: navigationBg,
      icon: { color: navigationColor },
    },
    sideBar: {
      right: { backgroundColor, subBackgroundColor, color, button },
    },
  } = useTheme();

  const styles = {
    button: {
      backgroundColor: button?.backgroundColor || subBackgroundColor,
      color: button?.color || color,
    },
  };

  const [showChatBar, setChatBarShowing] = useState(false);

  const openChat = (channel: ChatMutatedChannel): void => {
    setChatChannel(channel);
  };

  const notificationBar = (
    <div
      className="mr-2 p-2 mt-3 rounded"
      style={{ height: "50%", backgroundColor }}>
      <Link to="/notifications">
        <Button
          color="link"
          size="md"
          block
          className="border mb-1"
          style={styles.button}>
          Updates
        </Button>
      </Link>

      <div
        className="border rounded right-side-bar-notifications"
        style={{
          backgroundColor: subBackgroundColor,
          height: "85%",
        }}>
        <NotificationLandingContainer
          Layout={NotificationLandingSideBar}></NotificationLandingContainer>
      </div>
    </div>
  );

  const chatBar = (
    <div
      className="mt-2 mr-2 p-2 rounded"
      style={{ height: "45%", backgroundColor }}>
      <Link to="/chat-room">
        <Button
          color="link"
          size="md"
          block
          className="border mb-1"
          style={styles.button}>
          Chat
        </Button>
      </Link>
      {!showChatBar && (
        <Button
          className="mt-2"
          block
          outline
          size="sm"
          onClick={(): void => setChatBarShowing(true)}>
          Load Chat Messages
        </Button>
      )}

      {showChatBar && (
        <div
          className="border rounded"
          id="right-side-bar-chat"
          style={{ backgroundColor: subBackgroundColor, height: "85%" }}>
          <ChannelsSidebar simpleMode={true} onClick={openChat} />
        </div>
      )}
    </div>
  );

  const quickChatWindow = (
    <CSSTransition in={!!chatChannel} timeout={100} unmountOnExit>
      <div className="chat-pop-up">
        <div
          className="chat-pop-up-header"
          style={{ backgroundColor: navigationBg }}>
          <Link
            to={`/chat-room-detail/${chatChannel?.url}`}
            className="text-decoration-none">
            <h6 style={{ color: navigationColor }}>
              {chatChannel?.displayName}
            </h6>
          </Link>
          <Button
            outline
            color="transparent"
            onClick={(): void =>
              openChat((null as unknown) as ChatMutatedChannel)
            }>
            <i className="icon-arrow-down" style={{ color: navigationColor }} />
          </Button>
        </div>
        <div className="chat-pop-up-body">
          <ChatDetailContainer
            match={{
              params: { channel: chatChannel?.url, text: "" },
            }}
            Layout={ChatConversation}
          />
        </div>
      </div>
    </CSSTransition>
  );
  return (
    <>
      <div className="h-100 overflow-hidden">
        {chatBar}
        {notificationBar}
        {quickChatWindow}
      </div>
    </>
  );
};
