import { convertObject } from "../../../lib/utils";
import { Group } from "../../../types";

import { runQuery } from "../../http";

export const getGroupOfChat = async (groupChatId: string): Promise<Group> =>
  convertObject(
    (await runQuery(
      "Group",
      (query: any) => query.equalTo("groupChatId", groupChatId).first(),
      { requireUser: true }
    )) as Parse.Object<Group>
  );
