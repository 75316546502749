import { store } from "../store/configureStore";
import { track } from "./track";

export function logApiTimeMetric(
  name: string,
  options: { time: number; params?: {} }
): void {
  const eventName = "Instrumentation";
  const apiTimeType = "apiTime";
  if (!store.getState().setting.config?.instrumentationEnabled) {
    return;
  }
  track &&
    track(eventName, { type: apiTimeType, apiFunction: name, ...options });
}
