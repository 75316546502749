import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import { textThemingWorkaround } from "../../../constants/Theme";

import { useTheme } from "../../hooks";

export type UserLoginBlockProps = {};

export const UserLoginBlock: React.FC<UserLoginBlockProps> = () => {
  /**
   * @TODO
   * Temporarily partly copied here. Needs deep refactor.
   */
  const userRegisterStyle = {
    text: {
      color: "#fff",
      textAlign: "center",
    },
  } as const;

  const history = useHistory();

  const {
    button: buttonStyle,
    text: { listTitle: listTitleStyle },
  } = useTheme();

  const { t } = useTranslation("User");

  const redirectToLogin = (): void => {
    history.push("/login");
  };

  return (
    <div className="mb-3 mt-3">
      <p
        className="mb-3"
        style={{
          ...listTitleStyle,
          ...textThemingWorkaround,
          ...userRegisterStyle.text,
        }}>
        {t("Register.Account.Exists")}
      </p>
      <Button
        block
        id="SignUpButton"
        outline
        style={{
          color: "#fff",
          borderColor: "#fff",
          borderRadius: buttonStyle.borderRadius,
        }}
        onClick={redirectToLogin}>
        {t("Register.Account.Login")}
      </Button>
    </div>
  );
};
