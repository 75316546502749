import React, { SyntheticEvent } from "react";

import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Card, Media, Badge } from "reactstrap";
import { isEmpty } from "underscore";

import { Group } from "../../../types";
import { toCapitalizeCase } from "../../../lib/utils";

import { Avatar } from "../UI";
import { useTheme } from "../../hooks";

export type GroupCardProps = {
  id?: string;
  className?: string;
  pending?: boolean;
  item: Group;
  query?: string;
  disablePressDetail?: boolean;
  href?: string;
  onClick?: (item: Group) => void;
};

/**
 * @TODO This component is very similar to MemberCard.
 */
export const GroupCard: React.FC<GroupCardProps> = ({
  id: testId,
  className,
  pending,
  href,
  item,
  query,
  disablePressDetail,
  onClick,
}) => {
  const { t } = useTranslation("Group");
  const { name, thumbUrl } = item;
  const {
    list: { backgroundColor },
    general: { highlightColor },
  } = useTheme();
  if (
    query &&
    !isEmpty(query) &&
    (name || "").toLowerCase().indexOf(query.toLowerCase()) === -1
  ) {
    return null;
  }

  const handleClick = onClick
    ? (e: SyntheticEvent): void => {
        e.preventDefault();

        !pending && onClick(item);
      }
    : undefined;

  const { id, objectId, description } = item;

  const cardStyle = pending
    ? { backgroundColor: highlightColor }
    : onClick && { cursor: "pointer", backgroundColor };

  const capitalizedName = toCapitalizeCase(name);

  const headerElement = <h6 className="mb-0">{capitalizedName}</h6>;

  const finalHeaderElement =
    href && !pending ? (
      <a href={href} className="stretched-link">
        {headerElement}
      </a>
    ) : (
      headerElement
    );

  return (
    <Card
      id={testId}
      className={classnames(
        "group-card border-bottom p-2 mb-1 rounded",
        className
      )}
      style={cardStyle}
      onClick={handleClick}>
      <Media>
        <Media left bottom className="mr-2">
          <Avatar
            size={"80"}
            name={capitalizedName}
            src={thumbUrl}
            id={id || objectId}
            pending={pending}
            round={false}
            disablePressDetail={disablePressDetail}
            type={"group"}
          />
        </Media>
        <Media body className="px-2 pt-2 text-truncate">
          {finalHeaderElement}
          <p className="text-muted text-small mt-2 mb-0 text-truncate">
            {description}
          </p>
          {pending && (
            <Badge pill className={"font-weight-light"} color="warning">
              {t("ListGroupsByStatus.GroupCard.PendingGroup")}
            </Badge>
          )}
        </Media>
      </Media>
    </Card>
  );
};
