import React from "react";

import { CommentUserListCardPlaceholder } from ".";

export type CommentReactByPlaceholderProps = {};

export const CommentReactByPlaceholder: React.FC<CommentReactByPlaceholderProps> = () => (
  <>
    <CommentUserListCardPlaceholder showRight={true} />
    <CommentUserListCardPlaceholder showRight={true} />
    <CommentUserListCardPlaceholder showRight={true} />
  </>
);
