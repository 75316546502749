import { adminGet } from "../../http";
import { ExternalVideo } from "../../../types/Media/ExternalVideo";
import { getEmbededVideoLink } from "../../../lib/utils/video";

export function getVideoMetaData(
  url: string,
  config: { api_key: string; sessionToken: string; adminURL: string }
): Promise<ExternalVideo> {
  const validUrl = getEmbededVideoLink(url);
  console.debug("validUrl", validUrl);

  return adminGet(
    "videos/metadata",
    { url: validUrl },
    { requireUser: true, config }
  );
}
