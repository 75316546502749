/* eslint-disable no-async-promise-executor */
// @TODO remove line from above and fix eslint warnings
import _ from "underscore";
import { init, listChannels } from "../../lib/sendbird";
import {
  getNameOfFileType,
  convertObject,
  logException,
  parseJSONToObject,
  isPhoto,
} from "../../lib/utils";
import {
  uploadFile,
  updateUserSendBird,
  addFilesToGroup as _addFilesToGroup,
  searchEventByChatChannel as _searchEventByChatChannel,
  setHasMessages,
} from "../../services/api";

import {
  getGroupOfChat as _getGroupOfChat,
  getFavoriteChatContacts,
  removeMembersFromGroupChat as _removeMembersFromGroupChat,
  getChatNotificationSetting as _getChatNotificationSetting,
  saveChatNotificationSettings as _saveChatNotificationSettings,
  sendChatNotification,
  addToFavoriteChatContact,
  removeFromFavoriteChatContact,
} from "../../services/api/Chat";
import { getGroup } from "../Group/action";

import { getCurrentUser } from "../User/action";

import {
  AdminMessage,
  FileMessage,
  GroupChannel,
  GroupChannelStatic,
  OpenChannel,
  OpenChannelStatic,
  SendBirdError,
  SendBirdInstance,
  UserMessage,
} from "sendbird";
import {
  CHAT_DETAIL_ACTIVE_SET,
  CHAT_DETAIL_SET,
  CHAT_FAVORITE_ALL_SET,
  CHAT_INPUT_FIELD_SAVE,
  CHAT_ROOM_ALL_SET,
  CHAT_SETTING_SET,
} from "./constants";
import { ChatRoomAllSetAction } from "./types";
import { ChatSetting } from "../../types/Chat/ChatSetting";
import {
  AppThunkDispatch,
  Dict,
  GetState,
  Group,
  AppThunkAction,
} from "../../types";
import { SettingConfigData } from "../../types/Setting/SettingConfigData";
import {
  NOTIFICATION_ALERT_SET,
  NOTIFICATION_UNREAD_SET,
  NOTIFICATION_UPLOAD_SET,
} from "../Notification/constants";
import { ChatTypeData } from "../../types/Chat/ChatTypeData";
import { UserDataState } from "../../types/User/UserDataState";
import {
  ChatMutatedMessage,
  CustomFileType,
} from "../../types/Chat/ChatMutatedMessage";
import { EventSingle } from "../../types/Event/EventSingle";
import { NotificationFileProgress } from "../../types/Notification/NotificationFileProgress";
import { ChatReadStatus } from "../../types/Chat/ChatReadStatus";
import { ChatDetailsData } from "../../types/Chat/ChatDetailsData";
import { page as _page } from "../../constants";
import i18n from "../../middlewares/i18next";
import { UserProfileData } from "../../types/User";
import { MentionUser, MentionLink } from "../../types/Mention";
import {
  SendMessageParams,
  ChatListDetailData,
  ChatSender,
  ChatInputField,
} from "../../types/Chat";
import {
  ChatAdminMessage,
  ChatFileMessage,
  ChatMutatedChannel,
  ChatMutatedLastMessage,
  ChatPage,
  ChatUserMessage,
  MutatedGroupChanneListQuery,
  MutatedOpenChannelListQuery,
} from "./chatTypes";

const page: ChatPage = {
  chatRoom: {},
  chatDetail: {},
};
const per = {
  chatRoom: _page.chatRoom,
  chatDetail: _page.chatDetail,
};

let SendBird: SendBirdInstance;

type SendBirdConfig = {
  sendBirdToken: string;
  sendBirdKey: string;
  sendBirdForBigApp: boolean;
};

function isAdminMessage(
  msg: AdminMessage | UserMessage | FileMessage
): msg is AdminMessage {
  // return ("_sender" in msg === false);
  return msg.isAdminMessage();
}

function isFileMessage(
  msg: AdminMessage | UserMessage | FileMessage
): msg is ChatFileMessage {
  return msg.isFileMessage();
}

function isUserMessage(
  msg: AdminMessage | UserMessage | FileMessage
): msg is ChatUserMessage {
  return msg.isUserMessage();
}

function isUserOrFileMessage(
  msg: AdminMessage | UserMessage | FileMessage
): msg is ChatFileMessage | ChatUserMessage {
  return isUserMessage(msg) || isFileMessage(msg);
}

function isOpenChannel(chl: OpenChannel | GroupChannel): chl is OpenChannel {
  return chl.isOpenChannel();
}

export function isGroupChannel(
  chl: OpenChannel | GroupChannel
): chl is GroupChannel {
  return chl.isGroupChannel();
}

/**
 * init SendBird from keys in DB
 */
function initSendBird() {
  return (dispatch: AppThunkDispatch, getState: GetState): void => {
    const { setting } = getState();
    if (setting.config) {
      SendBird = init(setting.config.sendBirdKey);
    }
  };
}
/**
 * Add more info into a last message to display in list
 * @param channel
 * @param sendBirdUserId
 */
function formatLastMessage(
  channel: GroupChannel | OpenChannel,
  sendBirdUserId: string
): ChatMutatedLastMessage {
  //workaround cause by object mutation, I don't find it as a good pattern, do not copy it (object mutation)
  const mutatedChannel: ChatMutatedChannel = channel as ChatMutatedChannel;
  if (!mutatedChannel.lastMessage) {
    mutatedChannel.lastMessage = {};
  }
  if (_.isString(mutatedChannel.lastMessage)) {
    try {
      mutatedChannel.lastMessage = JSON.parse(mutatedChannel.lastMessage);
    } catch (error) {
      mutatedChannel.lastMessage = {};
    }
  }
  if (mutatedChannel.lastMessage && !mutatedChannel.lastMessage._sender) {
    mutatedChannel.lastMessage._sender = mutatedChannel.lastMessage.user;
  }
  if (!mutatedChannel.lastMessage || !mutatedChannel.lastMessage._sender) {
    return { message: i18n.t("Chat:Container.Action.Message.Start.Chat") };
  }
  let author = "",
    text = "";
  if (mutatedChannel.lastMessage._sender.userId === sendBirdUserId) {
    author = i18n.t("Chat:Container.Action.Author.You");
  } else {
    author =
      !mutatedChannel.is1Vs1Chat && mutatedChannel.lastMessage
        ? mutatedChannel.lastMessage._sender.nickname + ": "
        : "";
  }
  if (
    _.isEmpty(mutatedChannel.lastMessage.message) &&
    !_.isEmpty(mutatedChannel.lastMessage.url)
  ) {
    text = [
      i18n.t("Chat:Container.Action.Text.Sent"),
      getNameOfFileType(mutatedChannel.lastMessage.url),
    ].join(" ");
  } else {
    text = mutatedChannel.lastMessage.message as string;
  }
  mutatedChannel.lastMessage.newMessage = author + text;
  return mutatedChannel.lastMessage;
}

/**
 * Add more info into every channel in the list for displaying / searching / filtering
 * @param channel
 * @param sendBirdUserId
 */
function formatChannel(
  channel: GroupChannel | OpenChannel,
  sendBirdUserId: string
): Promise<ChatMutatedChannel> {
  return new Promise<ChatMutatedChannel>(resolve => {
    //workaround cause by object mutation, I don't find it as a good pattern, do not copy it (object mutation)
    const mutatedChannel: ChatMutatedChannel = channel as ChatMutatedChannel;
    mutatedChannel.isSystemChat =
      mutatedChannel.data === "event" ||
      mutatedChannel.data === "past-event" ||
      mutatedChannel.data === "group";
    mutatedChannel.isGroupChat = mutatedChannel.data === "group";
    mutatedChannel.isEventChat =
      mutatedChannel.data === "event" || mutatedChannel.data === "past-event";
    mutatedChannel.is1Vs1Chat =
      mutatedChannel.members && mutatedChannel.members.length === 2;
    mutatedChannel.showCover = mutatedChannel.isSystemChat;
    mutatedChannel.showName = mutatedChannel.isSystemChat
      ? true
      : mutatedChannel.showCover ||
        (mutatedChannel.name.length > 0 &&
          !_.contains(["Private Chat", "Group Chat"], mutatedChannel.name));
    mutatedChannel.filteredMembers =
      mutatedChannel.members &&
      _.take(
        _.filter(mutatedChannel.members, m => {
          return m.userId !== sendBirdUserId;
        }),
        3
      );
    if (mutatedChannel.showName) {
      mutatedChannel.displayName = mutatedChannel.name;
    } else if (
      mutatedChannel.members &&
      mutatedChannel.memberCount === 1 &&
      mutatedChannel.name === "Private Chat"
    ) {
      mutatedChannel.displayName = mutatedChannel.members[0].nickname;
      mutatedChannel.coverUrl = mutatedChannel.members[0].profileUrl;
    } else if (
      mutatedChannel.memberCount === 2 &&
      mutatedChannel.filteredMembers &&
      mutatedChannel.filteredMembers.length === 1
    ) {
      mutatedChannel.user = mutatedChannel.filteredMembers[0];
      mutatedChannel.coverUrl = mutatedChannel.filteredMembers[0].profileUrl;
      mutatedChannel.displayName = !_.contains(
        ["Private Chat", "Group Chat"],
        mutatedChannel.name
      )
        ? mutatedChannel.name
        : mutatedChannel.filteredMembers[0].nickname;
    } else if (
      mutatedChannel.filteredMembers &&
      mutatedChannel.filteredMembers.length > 1
    ) {
      mutatedChannel.displayName = _.map(
        mutatedChannel.filteredMembers,
        member => {
          return member.nickname.split(" ")[0];
        }
      ).join(", ");
      if (!mutatedChannel.showCover) {
        mutatedChannel.coverUrl = "";
      }
    } else {
      mutatedChannel.displayName = mutatedChannel.name;
    }
    if (mutatedChannel.data === "past-event") {
      mutatedChannel.displayName = "[Ended] " + mutatedChannel.displayName;
    }
    //only use GroupFire avatar not Sendbird
    if (mutatedChannel.coverUrl.indexOf("mobilize") === -1) {
      mutatedChannel.coverUrl = "";
    }
    mutatedChannel.lastMessage = formatLastMessage(
      mutatedChannel,
      sendBirdUserId
    );
    resolve(mutatedChannel);
  });
}

/**
 * Convert userId from DB into SendBird e.g. abcdefgh >> [Tenant Name]_abcdefgh
 * @param userId
 */
export function toSendBirdUserId(userId: string) {
  return (dispatch: AppThunkDispatch, getState: GetState): string => {
    const { setting } = getState();
    if (setting.config && userId.indexOf(setting.config.subDomain) === -1) {
      return `${setting.config.subDomain}_${userId}`;
    }
    return userId;
  };
}

/**
 * Convert userId from SendBird into DB e.g. [Tenant Name]_abcdefgh >> abcdefgh
 * @param sendBirdUserId
 */
export function fromSendBirdUserId(sendBirdUserId: string) {
  return (dispatch: AppThunkDispatch, getState: GetState): string => {
    const { setting } = getState();
    if (setting.config) {
      return sendBirdUserId.replace(`${setting.config.subDomain}_`, "");
    } else {
      return sendBirdUserId;
    }
  };
}

/**
 * Add more info into every messages for displaying e.g. text / image / file
 * @param message
 * @param sendBirdUserId
 */
function customUserMessage(
  message: UserMessage | FileMessage,
  sendBirdUserId?: string
): ChatMutatedMessage {
  const data = parseJSONToObject<{
    text?: string;
    thumbUrl?: string;
    mentions?: MentionUser[];
    linksMentions?: MentionLink[];
  }>(message.data);
  const fileData: {
    image: CustomFileType | null;
    file: CustomFileType | null;
  } = {
    image: null,
    file: null,
  };
  //workaround cause by object mutation, I don't find it as a good pattern, do not copy it (object mutation)
  const mutatedMessage: ChatMutatedMessage = message as ChatMutatedMessage;
  const isMine =
    (mutatedMessage._sender && mutatedMessage._sender.userId) ===
    sendBirdUserId;

  if (isFileMessage(message)) {
    const file = {
      fileUrl: message.url,
      thumbUrl: (data && data.thumbUrl) || message.url,
      name: message.name,
      text: data && data.text,
    };

    if (isPhoto(message.type)) {
      fileData.image = file;
    } else {
      fileData.file = file;
    }
  }
  return {
    ...message,
    ...fileData,
    messageType: message.messageType as "file" | "user",
    mentions: data && data.mentions,
    linksMentions: data && data.linksMentions,
    isMine: isMine,
    isEdited: message.updatedAt > 0,
  };
}

/**
 * Display read receipts for messages
 * @param channel
 * @param messages
 * @param sendBirdUserId
 */
function updateReadReceipt(
  channel: GroupChannel | OpenChannel,
  messages: Dict<ChatMutatedMessage | ChatAdminMessage, number>,
  sendBirdUserId: string
): Dict<ChatMutatedMessage | ChatAdminMessage, number> {
  if (!messages || isOpenChannel(channel)) {
    return messages;
  }

  let messagesArray = Object.values(messages);
  let readReceipts: ChatSender[] = [];
  messagesArray = messagesArray.reverse();
  //get users alrady read any messages in channel
  const readStatus = _.filter(
    channel.getReadStatus() as ChatReadStatus[],
    function(member) {
      return member.last_seen_at > 0;
    }
  );

  //assign above users into paricular messages
  _.forEach(messagesArray, message => {
    message.readReceipts = readStatus
      .filter(seenStatus => {
        return (
          !_.contains(
            _.map(readReceipts, user => user?.userId),
            seenStatus.user?.userId
          ) && seenStatus.last_seen_at >= message.createdAt
        );
      })
      .map(member => member.user);
    const senderId = message._sender && message._sender.userId;
    if (
      senderId &&
      senderId !== sendBirdUserId &&
      !_.contains(
        _.map(readReceipts, user => user?.userId),
        senderId
      )
    ) {
      if (
        message._sender &&
        message.readReceipts &&
        !message.readReceipts.find(user => user?.userId === senderId)
      ) {
        message.readReceipts.push(message._sender as ChatSender);
      }
    }
    if (message.readReceipts) {
      readReceipts = readReceipts.concat(message.readReceipts);
    }
  });

  //revert messages to display on UI in a correct direction
  messagesArray = messagesArray.reverse();

  return _.object(
    _.map(messagesArray, value => {
      return [value.messageId, value];
    })
  );
}

/**
 * Get notification settings e.g. mute / notificaiton which was located in DB
 * @param channelUrl
 * @param refresh
 */
export const getChatNotificationSetting = (
  channelUrl: string,
  refresh?: boolean
): AppThunkAction<{ data: ChatSetting }> => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<{ data: ChatSetting }> => {
  console.debug("getChatNotificationSetting", { channelUrl, refresh });

  try {
    const { user } = getState();
    const { id } = user.data as UserDataState;
    const { chat } = getState();
    const cachedData = chat.setting[channelUrl];
    if (!refresh && cachedData) {
      return { data: cachedData };
    } else {
      const resp = await _getChatNotificationSetting([channelUrl], id);
      const item = resp.length > 0 ? convertObject(resp[0]) : null;
      const data = Object.assign({}, chat.setting, {
        [channelUrl]: item,
      });

      dispatch({
        type: CHAT_SETTING_SET,
        data,
      });

      return { data: item };
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get unread counter to display in badges (feature tab or chat tab)
 */
export const getUnreadChat = (): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  try {
    if (!SendBird) {
      await dispatch(initSendBird());
    }
    SendBird.getTotalUnreadChannelCount((response, error) => {
      if (error) {
        throw error;
      } else {
        const chat = new Array(response);

        dispatch({
          type: NOTIFICATION_UNREAD_SET,
          data: {
            "chat-room": chat,
          },
        });
      }
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * We have 3 types of chat (Converstation / Group / Event)
 * We have 2 designs for list tab (Converstation / Group / Event) or single list (mixed)
 * @param allChannels
 */
function getChannelTypes(
  allChannels: Dict<ChatMutatedChannel, number>
): ChatTypeData {
  const allChannelsList = Object.values(allChannels);
  return {
    UNREAD: _.filter(allChannelsList, channel => {
      return channel.unreadMessageCount
        ? channel.unreadMessageCount > 0
        : false;
    }),
    "GROUP ROOMS": _.filter(
      allChannelsList,
      channel =>
        (!channel.unreadMessageCount || channel.unreadMessageCount === 0) &&
        channel.data === "group"
    ),
    "GROUP ROOMS FULL": _.filter(
      allChannelsList,
      channel => channel.data === "group"
    ),
    EVENTS: _.filter(
      allChannelsList,
      channel =>
        !channel.unreadMessageCount &&
        (channel.data === "event" || channel.data === "past-event")
    ),
    "EVENTS FULL": _.filter(
      allChannelsList,
      channel => channel.data === "event" || channel.data === "past-event"
    ),
    CONVERSATIONS: _.filter(
      allChannelsList,
      channel =>
        !!(
          channel.unreadMessageCount === 0 &&
          _.isEmpty(channel.data) &&
          channel.lastMessage
        )
    ),
    "CONVERSATIONS FULL": _.filter(
      allChannelsList,
      channel => !!(_.isEmpty(channel.data) && channel.lastMessage)
    ),
  };
}

/**
 * Sync read receipts/ unread counter when someone read / text message
 * @param channel
 * @param notReadYet
 */
export const syncChatRoom = (
  channel: OpenChannel | GroupChannel,
  notReadYet?: boolean
) => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<boolean> => {
  console.debug("[Action] syncChatRoom");

  try {
    const { chat, user } = getState();
    const { url } = channel;
    const { userChatId } = user.data as UserDataState;
    const cachedData = chat.items || {};
    let data = cachedData[url];
    if (!_.isEmpty(data)) {
      data = await formatChannel(channel, userChatId as string);
      if (!notReadYet) {
        data.unreadMessageCount = 0;
      }
      const allChannels = Object.assign({}, cachedData, { [url]: data });
      const channelTypes = getChannelTypes(allChannels);
      await dispatch(getUnreadChat());
      dispatch({
        type: CHAT_ROOM_ALL_SET,
        data: { items: allChannels, type: channelTypes },
      });
    }
    return true;
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * This is hander of SendBird listeners
 * onMessageReceived
 * onMessageDeleted
 * onMessageUpdated
 * onTypingStatusUpdated
 * onReadReceiptUpdated
 *
 * @param dispatch
 * @param getState
 */
function implementChannelHandler(
  dispatch: AppThunkDispatch,
  getState: GetState
): void {
  const ChannelHandler = new SendBird.ChannelHandler();

  ChannelHandler.onMessageReceived = async (
    channel,
    message
  ): Promise<void | boolean> => {
    console.debug("onMessageReceived", { message });

    const { chat, setting, user } = getState();
    const { config } = setting;
    const { url, customType } = channel;
    const { userChatId } = user.data as UserDataState;

    //if it's a open channel and doesn't belong to this app, we should block it
    if (isOpenChannel(channel) && config && customType !== config.subDomain) {
      return false;
    }
    //this may is admin message
    // if (!message._sender) {
    // we check if field "_sender" doesn't exists in object message, which indicates it is AdminMessage type
    if ("_sender" in message === false) {
      return false;
    } else if (isUserOrFileMessage(message)) {
      //update channel
      dispatch(syncChatRoom(channel, true));
      //update chat
      message.isNewMessage = true;
      let chatData = {
        [message.messageId]: customUserMessage(message, userChatId),
      };
      const { data, hasMore } = chat.details[url] || {};
      if (!_.isEmpty(data)) {
        chatData = Object.assign({}, chatData, data);
        dispatch({
          type: CHAT_DETAIL_SET,
          data: { id: url, data: chatData, hasMore },
        });
      }

      //display alert
      let notificationSetting: ChatSetting | Dict<ChatSetting> = {};
      if (!chat.setting) {
        const resp = await dispatch(getChatNotificationSetting(url));
        if (resp && resp.data) {
          notificationSetting = resp.data;
        }
      } else {
        notificationSetting = chat.setting[url];
      }
      if (
        !notificationSetting ||
        (notificationSetting.mute !== true &&
          !notificationSetting.ignoreMuteHereChannel)
      ) {
        if ((message._sender && message._sender.userId) === userChatId) {
          return false;
        }
        let truncatedText = "";
        if (isFileMessage(message)) {
          truncatedText = `Sent ${getNameOfFileType(message.url)}`;
        } else {
          truncatedText =
            message.message.length > 100
              ? `${message.message.substr(0, 99)}...`
              : message.message;
        }

        dispatch({
          type: NOTIFICATION_ALERT_SET,
          data: {
            id: "CHAT",
            data: {
              id: url,
              url: `/chat-room-detail?channel=${url}`,
              text: `${message._sender.nickname}: ${truncatedText}`,
              title: i18n.t("Chat:Container.Action.Title.New.Message"),
            },
          },
        });
      }
    }
  };

  ChannelHandler.onMessageDeleted = (channel, messageId): void | boolean => {
    const { chat, setting } = getState();
    const { config } = setting;
    const { url, customType } = channel;
    //if it's a open channel and doesn't belong to this app, we should block it
    if (isOpenChannel(channel) && config && customType !== config.subDomain) {
      return false;
    }

    const { data, hasMore } = chat.details[url] || {};
    if (!_.isEmpty(data)) {
      delete data[parseInt(messageId)];
    }
    dispatch({
      type: CHAT_DETAIL_SET,
      data: { id: url, data, hasMore },
    });
  };

  ChannelHandler.onMessageUpdated = (channel, message): void | boolean => {
    const { chat, setting, user } = getState();
    const { userChatId } = user.data as UserDataState;

    const { config } = setting;
    const { url, customType } = channel;
    //if it's a open channel and doesn't belong to this app, we should block it
    if (isOpenChannel(channel) && config && customType !== config.subDomain) {
      return false;
    }

    if (isUserOrFileMessage(message)) {
      const { data, hasMore } = chat.details[url] || {};
      if (!_.isEmpty(data) && !_.isEmpty(data[message.messageId])) {
        data[message.messageId] = customUserMessage(message, userChatId);
      }
      dispatch({
        type: CHAT_DETAIL_SET,
        data: { id: url, data, hasMore },
      });
    } else {
      return false;
    }
  };

  ChannelHandler.onTypingStatusUpdated = (channel: GroupChannel): void => {
    const { chat } = getState();
    const { url } = channel;
    const mutatedActive = chat.active as ChatDetailsData;

    if (mutatedActive.id === url) {
      const users = channel.getTypingMembers();
      if (users.length === 0) {
        mutatedActive.typing = "";
      } else if (users.length > 0 && users.length < 3) {
        const multiple = users.length > 1;
        mutatedActive.typing = [
          _.map(users, user => user.nickname).join(multiple ? ", " : ""),
          multiple
            ? i18n.t("Chat:Container.Action.Typing.Plural")
            : i18n.t("Chat:Container.Action.Typing.Singular"),
        ].join(" ");
      } else {
        mutatedActive.typing = i18n.t("Chat:Container.Action.People.Typing");
      }
      dispatch({
        type: CHAT_DETAIL_ACTIVE_SET,
        data: mutatedActive,
      });
    }
  };

  ChannelHandler.onReadReceiptUpdated = (channel: GroupChannel): void => {
    const { chat, user } = getState();
    const { active, details } = chat;
    const { userChatId } = user.data as UserDataState;
    const { url } = channel;
    if ((active as ChatDetailsData).id === url) {
      const { data } = details[url] || {};
      const chatData = updateReadReceipt(channel, data, userChatId);
      dispatch({
        type: CHAT_DETAIL_ACTIVE_SET,
        data: { id: url, data: chatData },
      });
    }
  };

  SendBird.addChannelHandler("channel", ChannelHandler);
}

/**
 * chat doesn't support text of file
 * or open chat (event) doesn't support lastMessage
 * so we have to set it into metadata
 * @param channel
 * @param data
 */
function updateMetaData(
  channel: ChatMutatedChannel,
  data: Partial<ChatMutatedChannel>
): Promise<ChatMutatedChannel> {
  return new Promise<ChatMutatedChannel>(resolve => {
    if (!channel.getMetaData) return resolve(channel);
    channel.updateMetaData(
      data,
      true,
      (response: Partial<ChatMutatedChannel>, error: SendBirdError) => {
        if (error) {
          logException(error);
          return resolve();
        } else {
          //@FIXME this is a workaround for now
          return resolve(response as ChatMutatedChannel);
        }
      }
    );
  });
}

/**
 * chat doesn't support text of file
 * or open chat (event) doesn't support lastMessage
 * so we have to get it in metadata
 * @param channel
 * @param key
 */
function getMetaData<K extends keyof ChatMutatedChannel>(
  channel: ChatMutatedChannel,
  key: K
): Promise<ChatMutatedChannel> {
  return new Promise<ChatMutatedChannel>(resolve => {
    if (!channel.getMetaData) return resolve(channel);
    channel.getMetaData([key], function(
      response: Partial<ChatMutatedChannel>,
      error: SendBirdError
    ) {
      if (error) {
        logException(error);
        return resolve(channel);
      } else {
        //@FIXME this is a workaround for now
        channel[key] = (response as ChatMutatedChannel)[key];
        return resolve(channel);
      }
    });
  });
}

/**
 * resolving group chat or open chat bases on channelUrl
 * @param channelUrl
 */
function resolveChannelType(
  channelUrl: string
): OpenChannelStatic | GroupChannelStatic | null {
  if (channelUrl?.indexOf("sendbird_group_channel") === 0) {
    return SendBird.GroupChannel;
  } else if (channelUrl?.indexOf("sendbird_open_channel") === 0) {
    return SendBird.OpenChannel;
  }
  return null;
}

/**
 * add more info for SendBird admin messages
 * @param adminMessage
 */
function customAdminMessage(adminMessage: AdminMessage): ChatAdminMessage {
  adminMessage.message = adminMessage.message.split("invited").join("added");
  adminMessage.message = adminMessage.message.split("cover_url").join("cover");
  return adminMessage as ChatAdminMessage;
}

/**
 * Connect chat whenever app needs e.g.
 * stating app with User#hasMessages is true
 * opening chat screens
 *
 * @param required
 * @param updateProfile
 */
export function connectChat(
  required?: boolean,
  updateProfile?: boolean,
  willNotGetUnread?: boolean
) {
  return (dispatch: AppThunkDispatch, getState: GetState): Promise<string> =>
    new Promise<string>(async (resolve, reject) => {
      if (!SendBird) {
        await dispatch(initSendBird());
      }

      console.debug("[Action] connectChat", {
        required,
        updateProfile,
        willNotGetUnread,
        status: SendBird.getConnectionState(),
      });

      const { user, setting } = getState();
      if (!user.data) {
        return reject({});
      }
      let { userChatId } = user.data;
      const { hasMessages, firstName, lastName, profile, id } = user.data;

      if (!required && hasMessages) {
        required = true;
      }
      if (required) {
        if (SendBird.getConnectionState() === "OPEN") {
          if (!willNotGetUnread) {
            dispatch(getUnreadChat());
          }
          return resolve(userChatId);
        } else {
          const name = `${firstName} ${lastName}`;
          const { thumbUrl } = (profile || {}) as UserProfileData;

          if (_.isEmpty(userChatId) && setting.config) {
            userChatId = `${setting.config.subDomain}_${id}`;
            await updateUserSendBird(userChatId);
            await getCurrentUser();
          }

          SendBird.connect(userChatId, (user, error) => {
            if (error) {
              return reject(error);
            } else {
              if (updateProfile) {
                SendBird.updateCurrentUserInfo(name, thumbUrl ?? "");
              }
              implementChannelHandler(dispatch, getState);
              if (!willNotGetUnread) {
                dispatch(getUnreadChat());
              }
              return resolve(userChatId);
            }
          });
        }
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

export function disConnectChat() {
  return (): Promise<void> =>
    new Promise<void>(() => {
      console.debug("[Action] disConnectChat");

      if (SendBird) {
        SendBird.removeConnectionHandler("channel");
        SendBird.disconnect();
        //set SendBird null once disconecting due to  logging out or starting app from cold
        //to update new Sendbird key from DB if have
        //@FIXME It should not be set to null, there should be another way to deinitialize it.
        //eslint-disable-next-line
        //@ts-ignore
        SendBird = null;
      }

      page.chatRoom = {};
      page.chatDetail = {};
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Get allowSettingHasMessages
 */

export const allowSettingHasMessages = () => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<boolean> => {
  if (!SendBird) {
    return false;
  }

  const { notification } = getState();

  if (notification && notification?.unread?.["chat-room"]) {
    return notification.unread["chat-room"].length === 0;
  }
  return false;
};

/**
 * For app has a big groups (10ksb) loading channels will very slow because JS lib doesn't handle this issue
 * So if AppConfig.sendBirdForBigApp is true, we fetch channels from API not JS lib
 * see more GFC-4852
 * @param object
 * @param isOpenChannels
 * @param sendBirdSetting
 */
function getMoreChannels(
  object: MutatedGroupChanneListQuery | MutatedOpenChannelListQuery,
  isOpenChannels: boolean,
  sendBirdSetting: SendBirdConfig
): Promise<ChatMutatedChannel[]> {
  return new Promise<ChatMutatedChannel[]>((resolve, reject) => {
    console.debug("[Action] getMoreChannels");

    if (sendBirdSetting.sendBirdForBigApp) {
      listChannels(sendBirdSetting, isOpenChannels, object, object.token).then(
        ({ channels, error, token }) => {
          if (error) {
            reject(error);
          } else {
            object.token = token;
            object.hasNext = !_.isEmpty(token);
            resolve(channels);
          }
        }
      );
    } else {
      object.next(
        (channels: GroupChannel[] | OpenChannel[], error: SendBirdError) => {
          if (error) {
            reject(error);
          } else {
            resolve(channels as ChatMutatedChannel[]);
          }
        }
      );
    }
  });
}

/**
 * Use it for getting all chat notification settings to use on the list inread of getting each one
 * @param channelUrls
 */
export const getMultipleChatNotificationSetting = (
  channelUrls: string[]
) => async (dispatch: AppThunkDispatch, getState: GetState): Promise<void> => {
  console.debug("[Action] getMultipleChatNotificationSetting", channelUrls);

  try {
    const { user } = getState();
    const { id } = user.data as UserDataState;
    const { chat } = getState();

    const resp = await _getChatNotificationSetting(channelUrls, id);
    let data = _.object(
      _.map(resp, (value: Parse.Object) => {
        return [value.get("channel"), convertObject(value)];
      })
    );

    data = Object.assign({}, chat.setting, data);

    dispatch({
      type: CHAT_SETTING_SET,
      data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * attaching group info into group channels
 * getting notifications settings of group channels
 * @param privateChannels
 */
export function attachGroupInfoIntoGroupChannels(
  privateChannels: ChatMutatedChannel[]
) {
  return (dispatch: AppThunkDispatch): Promise<ChatMutatedChannel[]> =>
    new Promise<ChatMutatedChannel[]>(async (resolve, reject) => {
      console.debug("[Action] attachGroupInfoIntoGroupChannels");
      let groupChatNotificationSettings: string[] = [];

      await Promise.all(
        privateChannels.map(
          async (channel: ChatMutatedChannel, index: number) => {
            if (channel.data === "group") {
              privateChannels[index] = await getMetaData(channel, "groupId");
              groupChatNotificationSettings = [
                ...groupChatNotificationSettings,
                channel.url,
              ];
            }
          }
        )
      );

      dispatch(
        getMultipleChatNotificationSetting(groupChatNotificationSettings)
      );

      return resolve(privateChannels);
    });
}

/**
 * Get all channels to buid the list (tab or single list)
 * @param next
 * @param refresh
 */
export function getChatRooms(next: boolean, refresh?: boolean) {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<ChatRoomAllSetAction> =>
    new Promise<ChatRoomAllSetAction>(async (resolve, reject) => {
      console.debug("[Action] getChatRooms");

      const userChatId = await dispatch(connectChat(true, true));

      const { chat, setting, user } = getState();
      if (user.viewingAsGuest) {
        reject(new Error("Can't see chat in Guest Mode."));
      }
      const {
        subDomain,
        sendBirdToken,
        sendBirdKey,
        sendBirdForBigApp,
        allowToChangeMuteFromListChat,
      } = setting.config as SettingConfigData;
      const sendBirdSettings = {
        sendBirdToken,
        sendBirdKey,
        sendBirdForBigApp,
      };
      let cachedData = chat.items;
      if (!_.isEmpty(cachedData) && !refresh && !next) {
        return resolve();
      } else {
        let allChannels: Dict<ChatMutatedChannel> = {},
          channelTypes: Partial<ChatTypeData> = {};

        //building querys for every channels
        //openChannels and privateChannels for normal app
        if (refresh) {
          page.chatRoom.openChannels = SendBird.OpenChannel.createOpenChannelListQuery();
          page.chatRoom.openChannels.limit = per.chatRoom;
          page.chatRoom.openChannels.includeEmpty = true;
          page.chatRoom.openChannels.customType = subDomain;
          //new field "customTypes" without type define
          //eslint-disable-next-line
          //@ts-ignore
          page.chatRoom.openChannels.customTypes = [subDomain];
          page.chatRoom.openChannels.userId = userChatId;

          page.chatRoom.privateChannels = SendBird.GroupChannel.createMyGroupChannelListQuery();
          page.chatRoom.privateChannels.limit = per.chatRoom;
          page.chatRoom.privateChannels.includeEmpty = true;
          page.chatRoom.privateChannels.showReadReceipt = false;
          page.chatRoom.privateChannels.showMember = true;
          page.chatRoom.privateChannels.customType = subDomain;
          //new field "customTypes" without type define
          //eslint-disable-next-line
          //@ts-ignore
          page.chatRoom.privateChannels.customTypes = [subDomain];
          page.chatRoom.privateChannels.userId = userChatId;

          //add more groupChannels and groupSystemChannels for big app
          //see more getMoreChannels
          if (sendBirdForBigApp) {
            page.chatRoom.privateChannels.name = "Private Chat";

            page.chatRoom.groupChannels = SendBird.GroupChannel.createMyGroupChannelListQuery();
            page.chatRoom.groupChannels.limit = per.chatRoom;
            page.chatRoom.groupChannels.includeEmpty = true;
            page.chatRoom.groupChannels.showReadReceipt = false;
            page.chatRoom.groupChannels.showMember = false;
            page.chatRoom.groupChannels.name = "Group Chat";
            page.chatRoom.groupChannels.customType = subDomain;
            page.chatRoom.groupChannels.userId = userChatId;

            page.chatRoom.groupSystemChannels = SendBird.GroupChannel.createMyGroupChannelListQuery();
            page.chatRoom.groupSystemChannels.limit = per.chatRoom;
            page.chatRoom.groupSystemChannels.includeEmpty = true;
            page.chatRoom.groupSystemChannels.showReadReceipt = false;
            page.chatRoom.groupSystemChannels.showMember = false;
            page.chatRoom.groupSystemChannels.customType = subDomain;
            page.chatRoom.groupSystemChannels.userId = userChatId;
          }

          cachedData = {};
          dispatch(getUnreadChat());
        }

        const getChannels = async (): Promise<void> => {
          try {
            let openChannels: ChatMutatedChannel[] = [],
              groupSystemChannels: ChatMutatedChannel[] = [],
              groupChannels: ChatMutatedChannel[] = [],
              privateChannels: ChatMutatedChannel[] = [];

            //get channels from open and private
            if (page.chatRoom.openChannels && page.chatRoom.privateChannels) {
              [openChannels = [], privateChannels = []] = await Promise.all([
                getMoreChannels(
                  page.chatRoom.openChannels,
                  true,
                  sendBirdSettings
                ),
                getMoreChannels(
                  page.chatRoom.privateChannels,
                  false,
                  sendBirdSettings
                ),
              ]);
            }

            //Correct open channels
            if (page.chatRoom.openChannels) {
              //excluding invaild channels, only get events channels
              openChannels = _.filter(openChannels, channel => {
                return (
                  !_.isEmpty(channel.name) &&
                  !_.isEmpty(channel.customType) &&
                  channel.customType === subDomain &&
                  (channel.data === "event" || channel.data === "group")
                );
              });

              //re-formating for events channels
              //because they're open channels so we need to handle metadata to get needed info
              await Promise.all(
                openChannels.map(
                  async (channel: ChatMutatedChannel, index: number) => {
                    openChannels[index] = await getMetaData(
                      channel,
                      "lastMessage"
                    );
                  }
                )
              );
            }

            // if any channels has more chats, turn hasMore on to UI call load-more event
            let hasMore =
              (page.chatRoom.privateChannels &&
                page.chatRoom.privateChannels.hasNext) ||
              (page.chatRoom.openChannels &&
                page.chatRoom.openChannels.hasNext);

            //for big app case
            //handle more queries which defined above e.g. groupChannels, groupSystemChannels
            //we also redifined hasMore in here
            if (
              sendBirdForBigApp &&
              page.chatRoom.groupChannels &&
              page.chatRoom.groupSystemChannels
            ) {
              [
                groupChannels = [],
                groupSystemChannels = [],
              ] = await Promise.all([
                getMoreChannels(
                  page.chatRoom.groupChannels,
                  false,
                  sendBirdSettings
                ),
                getMoreChannels(
                  page.chatRoom.groupSystemChannels,
                  false,
                  sendBirdSettings
                ),
              ]);
              hasMore =
                hasMore ||
                (page.chatRoom.groupChannels &&
                  page.chatRoom.groupChannels.hasNext) ||
                (page.chatRoom.groupSystemChannels &&
                  page.chatRoom.groupSystemChannels.hasNext);
            }

            //attaching group info and getting notification settings for displaying mute buttons on chat rooms
            if (allowToChangeMuteFromListChat) {
              privateChannels = await dispatch(
                attachGroupInfoIntoGroupChannels(privateChannels)
              );
            }

            //joining all channels for a one-time formating then assign these formatted channels into allChannels
            const allChannelsTemp = groupChannels
              .concat(groupSystemChannels)
              .concat(privateChannels)
              .concat(openChannels);

            await Promise.all(
              allChannelsTemp.map(async (channel: ChatMutatedChannel) => {
                allChannels[channel.url] = await formatChannel(
                  channel,
                  userChatId
                );
              })
            );

            //assigning new channels for current channels if it's loading more channels
            allChannels = { ...cachedData, ...allChannels };

            //dividing all channels into correct types converstation, group or event to display on tab
            channelTypes = getChannelTypes(allChannels);

            return resolve(
              dispatch({
                type: CHAT_ROOM_ALL_SET,
                data: { items: allChannels, type: channelTypes },
                hasMore,
              })
            );
          } catch (error) {
            return reject({});
          }
        };
        getChannels();
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Getting chat channel
 * @param url
 * @param noStore
 */
export function getChannel(url: string, noStore?: boolean) {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<ChatMutatedChannel> =>
    new Promise<ChatMutatedChannel>(async (resolve, reject) => {
      console.debug("[Action] getChannel", { url, noStore });

      const { user } = getState();
      if (user.viewingAsGuest) {
        return reject(new Error("Can't see chat in Guest Mode."));
      }

      const userChatId = await dispatch(connectChat(true, false, true));
      const channel = resolveChannelType(url);
      if (channel) {
        channel.getChannel(
          url,
          async (channel: OpenChannel | GroupChannel, error: SendBirdError) => {
            if (error) {
              return reject(error);
            }

            if (channel.isOpenChannel()) {
              //@FIXME
              //@Phuong you didn't provide a callback func in enter(), but this argument is necessary
              //eslint-disable-next-line
              //@ts-ignore
              (channel as OpenChannel).enter();
            }

            if (!noStore) {
              const data = await formatChannel(channel, userChatId);

              dispatch({
                type: CHAT_DETAIL_ACTIVE_SET,
                data: { id: url, ...data },
              });

              return resolve(channel as ChatMutatedChannel);
            } else {
              return resolve(channel as ChatMutatedChannel);
            }
          }
        );
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * marking chat as read if already see the chat
 * @param channel
 */
export function markAsRead(channel: OpenChannel | GroupChannel) {
  return (dispatch: AppThunkDispatch): Promise<boolean> =>
    new Promise<boolean>(async resolve => {
      dispatch(syncChatRoom(channel));

      if (isGroupChannel(channel)) {
        channel.markAsRead();
      }
      return resolve(true);
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * check unread messages to update User#hasMessages
 */
export function checkHasMessages() {
  return (dispatch: AppThunkDispatch): Promise<void> =>
    new Promise<void>(async resolve => {
      SendBird.getTotalUnreadChannelCount((count, error) => {
        if (error) {
          return resolve();
        } else {
          setHasMessages(count > 0);
          return resolve();
        }
      });
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Get messages on a channel
 * @param channel
 * @param next
 * @param refresh
 */
export const getChat = (
  channel: ChatMutatedChannel,
  next: boolean,
  refresh: boolean
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getChat", { channel, next, refresh });

  try {
    const { chat } = getState();
    const { url } = channel;
    const { data } = chat.details[url] || {};
    if (!_.isEmpty(data) && !refresh && !next) {
      return;
    } else {
      const userChatId = await dispatch(connectChat(true));

      //getting unread counter on this chat, so that get all unread messages on the first call
      const unreadMessageCount = (channel && channel.unreadMessageCount) || 0;

      //building SendBird query
      if (refresh || !page.chatDetail[url]) {
        page.chatDetail[url] = channel.createPreviousMessageListQuery();
        page.chatDetail[url].limit =
          unreadMessageCount > per.chatDetail
            ? unreadMessageCount
            : per.chatDetail;
        page.chatDetail[url].reverse = false;
      } else {
        page.chatDetail[url].limit = per.chatDetail;
      }

      //preveting a reqeusting more data if has no any more messages
      if (
        !page.chatDetail[url] ||
        (page.chatDetail[url] && !page.chatDetail[url].hasMore)
      ) {
        return;
      }
      //executing the query
      page.chatDetail[url].load((messages, error) => {
        if (error) {
          throw error;
        } else {
          //re-fromat for every messages e.g. admin messages, text messages or image messages
          const listData = _.map(messages, function(message) {
            if (isAdminMessage(message)) {
              return customAdminMessage(message);
            } else {
              return customUserMessage(message, userChatId);
            }
          });

          //indicating all unread messages with 'new' label
          //marking a channel as read to clear unread couter badges
          if (refresh) {
            _.forEach(
              _.last(listData, unreadMessageCount),
              (message, index) => {
                if (index === 0) {
                  //@FIXME this is temporary workaround
                  (message as ChatAdminMessage).isNewMessage = true;
                }
              }
            );
            dispatch(markAsRead(channel));
          }

          //converting array messages from SendBird into object
          let newData: Dict<
            ChatMutatedMessage | ChatAdminMessage,
            number
          > = _.object(
            _.map(listData, value => {
              return [value.messageId, value];
            })
          );

          //adding more value into object if it's loading more
          //otherwise it's a first loading or pull-to-refresh
          if (next) {
            newData = Object.assign({}, data, newData);
          }

          //attaching read receipts for every messages
          newData = updateReadReceipt(channel, newData, userChatId);

          dispatch({
            type: CHAT_DETAIL_SET,
            data: {
              id: url,
              data: newData,
              hasMore: page.chatDetail[url].hasMore,
            },
          });
        }
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Create new channel
 * @param users
 * @param name
 * @param coverFile
 */
export function createChannel(
  users: { id: string }[],
  name?: string,
  coverFile?: string | File
) {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<{ data: string }> =>
    new Promise<{ data: string }>(async (resolve, reject) => {
      console.debug("[Action] createChannel", { users, name });

      await dispatch(connectChat(true, false, true));

      const { setting } = getState();
      if (_.isEmpty(name)) {
        // eslint-disable-next-line require-atomic-updates
        name = "Private Chat";
      }
      const userIds = _.map(users, ({ id }) => dispatch(toSendBirdUserId(id)));

      SendBird.GroupChannel.createChannelWithUserIds(
        userIds,
        true,
        name ? name : "",
        coverFile ? coverFile : "",
        "",
        setting.config ? setting.config.subDomain : "",
        (createdChannel, error) => {
          if (error) {
            if (error.code === 400201) {
              return reject(i18n.t("Chat:Container.Action.Reject.1"));
            } else {
              return reject(i18n.t("Chat:Container.Action.Reject.2"));
            }
          } else {
            dispatch(getChatRooms(false, true));
            return resolve({ data: createdChannel.url });
          }
        }
      );
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Add more members to existing channel
 * @param channelUrl
 * @param users
 */
export function addMembersToChannel(
  channelUrl: string,
  users: { id: string }[]
) {
  return (dispatch: AppThunkDispatch): Promise<boolean | { data: string }> =>
    new Promise<boolean | { data: string }>(async (resolve, reject) => {
      console.debug("[Action] addMembersToChannel");

      const channel = await dispatch(getChannel(channelUrl, true));

      if (
        "members" in channel &&
        channel.members &&
        channel.members.length === 2
      ) {
        users = users.concat(
          channel.members.map(member => {
            return { id: member.userId };
          })
        );
        return resolve(dispatch(createChannel(users)));
      } else {
        const userIds = _.map(users, ({ id }) =>
          dispatch(toSendBirdUserId(id))
        );

        if ("inviteWithUserIds" in channel) {
          channel.inviteWithUserIds(userIds, (response, error) => {
            if (error) {
              if (error.code === 400201) {
                return reject(i18n.t("Chat:Container.Action.Reject.1"));
              } else {
                return reject(i18n.t("Chat:Container.Action.Reject.2"));
              }
            } else {
              return resolve(true);
            }
          });
        }
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Remove members from existing chat
 * @param channelUrl
 * @param users
 */
export function removeMembersFromChannel(
  channelUrl: string,
  users: { id: string }[]
) {
  return (dispatch: AppThunkDispatch): Promise<boolean> =>
    new Promise<boolean>(async (resolve, reject) => {
      console.debug("[Action] removeMembersFromChannel");

      const userIds = _.map(users, ({ id }) => dispatch(toSendBirdUserId(id)));

      _removeMembersFromGroupChat(userIds, channelUrl)
        .then(() => {
          return resolve(true);
        })
        .catch(error => reject(error));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Leave a channel
 * 'exit' for open channels
 * but 'leave` for groups channels
 * @param channelUrl
 */
export function leaveChannel(channelUrl: string) {
  return (): Promise<OpenChannel | Partial<GroupChannel>> =>
    new Promise<OpenChannel | Partial<GroupChannel>>(
      async (resolve, reject) => {
        console.debug("[Action] leaveChannel");

        const channel = resolveChannelType(channelUrl);
        if (channel) {
          channel.getChannel(
            channelUrl,
            (channel: OpenChannel | GroupChannel, error: SendBirdError) => {
              if (error) {
                return reject(error);
              }
              if (isOpenChannel(channel)) {
                channel.exit((response, error) => {
                  if (error) {
                    return reject(error);
                  } else {
                    return resolve(response);
                  }
                });
              } else {
                channel.leave((response, error) => {
                  if (error) {
                    return reject(error);
                  } else {
                    return resolve(response);
                  }
                });
              }
            }
          );
        } else {
          return reject(i18n.t("Chat:Container.Action.Reject.3"));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Delete a channel
 * 'exit' for open channels
 * but 'hide' for group channels
 * @param channelUrl
 */
export function deleteChannel(channelUrl: string) {
  return (): Promise<OpenChannel | Partial<GroupChannel>> =>
    new Promise<OpenChannel | Partial<GroupChannel>>(
      async (resolve, reject) => {
        console.debug("[Action] deleteChannel");

        const channel = resolveChannelType(channelUrl);
        if (channel) {
          channel.getChannel(
            channelUrl,
            (channel: OpenChannel | GroupChannel, error: SendBirdError) => {
              if (error) {
                return reject(error);
              }
              if (isOpenChannel(channel)) {
                channel.exit((response, error) => {
                  if (error) {
                    return reject(error);
                  } else {
                    return resolve(response);
                  }
                });
              } else {
                channel.hide((response, error) => {
                  if (error) {
                    return reject(error);
                  } else {
                    return resolve(response);
                  }
                });
              }
            }
          );
        } else {
          return reject(i18n.t("Chat:Container.Action.Reject.3"));
        }
      }
    ).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Update an existing channel by new name
 * @param channelUrl
 * @param name
 */
export function updateChannel(channelUrl: string, name: string) {
  return (dispatch: AppThunkDispatch): Promise<OpenChannel> =>
    new Promise<OpenChannel>(async (resolve, reject) => {
      console.debug("[Action] updateChannel");

      const channel = resolveChannelType(channelUrl);
      if (channel) {
        channel.getChannel(
          channelUrl,
          (channel: OpenChannel | GroupChannel, error: SendBirdError) => {
            if (error) {
              return reject(error);
            }
            //@TODO need more investigation, why ts is having a problem with existing func (params are now correct type)
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            channel.updateChannel(
              name,
              null,
              null,
              (response: OpenChannel, error: SendBirdError) => {
                if (error) {
                  reject(error);
                } else {
                  dispatch({
                    type: CHAT_DETAIL_ACTIVE_SET,
                    data: response as any,
                  });
                  resolve(response);
                }
              }
            );
          }
        );
      } else {
        return reject(i18n.t("Chat:Container.Action.Reject.3"));
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Because we don't save group info for group chat in SendBird
 * So we need to retrieve a group info from DB by Group#groupChatId
 * @param groupChatId
 * @param channel
 */
export function getGroupOfChat(
  groupChatId: string,
  channel: ChatMutatedChannel
) {
  return (dispatch: AppThunkDispatch): Promise<{ data: Group }> =>
    new Promise<{ data: Group }>(async (resolve, reject) => {
      console.debug("[Action] getGroupOfChat");

      let group: Group | undefined;

      if (channel.groupId) {
        const groupResp = await dispatch(getGroup(channel.groupId));
        group = groupResp && groupResp.data;
      } else {
        group = await _getGroupOfChat(groupChatId);
      }

      if (group && !group.hasGroupChat) {
        reject({ message: i18n.t("Chat:Container.Action.Reject.4") });
      } else if (group && group.hasGroupChat) {
        // Update groupId as metadata of channel
        if (!channel.groupId) {
          updateMetaData(channel, { groupId: group.id });
        }
        resolve({ data: group });
      }
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Because we don't save event info for event chat in SendBird
 * So we need to retrieve a event info from DB by Event#eventChatId
 * @param eventChatId
 */
export function getEventOfChat(eventChatId: string) {
  return (): Promise<{ data: EventSingle }> =>
    new Promise<{ data: EventSingle }>(async (resolve, reject) => {
      console.debug("[Action] getEventOfChat");

      _searchEventByChatChannel(eventChatId)
        .then(resp => {
          const event: EventSingle = convertObject(resp);
          if (event) {
            return resolve({ data: event });
          } else {
            return reject({});
          }
        })
        .catch(err => reject(err));
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Save chat notification into DB
 * @param channelUrl
 * @param setting
 */
export const saveChatNotificationSettings = (
  channelUrl: string,
  setting: ChatSetting
) => async (dispatch: AppThunkDispatch, getState: GetState): Promise<void> => {
  console.debug("[Action] saveChatNotificationSettings");

  try {
    const { chat } = getState();
    const resp = await _saveChatNotificationSettings(channelUrl, setting);
    const data = Object.assign({}, chat.setting, {
      [channelUrl]: convertObject(resp),
    });

    dispatch({
      type: CHAT_SETTING_SET,
      data,
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Get members for sending notification when have a new chat
 * on open chat only send notification for mentioned people
 * on group chat send for all members in group
 * it has 'online' option if member want to send for 'here'
 * but this sending notification also depends on setting for particular member
 * @param channel
 * @param mentionedUserIds
 * @param online
 */
function getMembersForNotification(
  channel: GroupChannel | OpenChannel,
  mentionedUserIds: string[],
  online: boolean
) {
  return (dispatch: AppThunkDispatch, getState: GetState): string[] => {
    console.debug("[Action] getMembersForNotification");

    const { user } = getState();
    const { userChatId } = user.data as UserDataState;

    if (isOpenChannel(channel)) {
      return mentionedUserIds;
    }

    return channel.members
      .filter(
        ({ userId, connectionStatus }) =>
          userId !== userChatId &&
          (online ? connectionStatus === "online" : true)
      )
      .map(member => dispatch(fromSendBirdUserId(member.userId)));
  };
}

/**
 * Prepare data for sending notification, e.g. members, text and data
 * @param channel
 * @param params
 */
export const sendNotification = (
  channel: ChatMutatedChannel,
  params: SendMessageParams
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] sendNotification");
  try {
    const { user } = getState();
    const { firstName, lastName } = user.data as UserDataState;
    const { text, mentions, files } = params;
    const { url, name } = channel;
    const mentionedUserIds = _.map(mentions, item => item.id);
    const isSendToChannel = _.contains(mentionedUserIds, "channel");
    const isSendToHere = _.contains(mentionedUserIds, "here");
    const isGroupChat = channel.data === "event" || channel.data === "group";
    const isFileOrPhoto = files && files.length > 0;
    const targetUserIds = dispatch(
      getMembersForNotification(channel, mentionedUserIds, isSendToHere)
    );
    const mentionTargetUserIds = _.filter(mentionedUserIds, mentionedUserId =>
      _.contains(targetUserIds, mentionedUserId)
    );
    const message = !isFileOrPhoto
      ? text
      : getNameOfFileType(
          null,
          (files as CustomFileType[])[0].file &&
            ((files as CustomFileType[])[0].file as {
              name: string;
              type: string;
              size: number;
            }).type
        );
    if (!_.isEmpty(targetUserIds)) {
      const data = {
        targets: targetUserIds,
        mentionTargets: mentionTargetUserIds,
        channel: url,
        author: `${firstName} ${lastName}`,
        channelTitle: isGroupChat ? name : "",
        message,
        isGroupChat,
        isSendToChannel,
        isSendToHere,
        isFileOrPhoto,
      };
      await sendChatNotification(data);
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * When members send files/images on group chat, this function handles adding these files/images into group
 * @param files
 * @param group
 */
export function addFilesToGroup(files: CustomFileType[], group: Group) {
  return (
    dispatch: AppThunkDispatch,
    getState: GetState
  ): Promise<{ data: boolean }> =>
    new Promise<{ data: boolean }>((resolve, reject) => {
      console.debug("[Action] addFilesToGroup");

      const { user } = getState();
      const { id } = user.data as UserDataState;
      if (group && group.id) {
        _addFilesToGroup(files, group.id, id)
          .then(() => {
            resolve({ data: true });
          })
          .catch(error => reject(error));
      } else {
        resolve({ data: true });
      }
    }).catch(err => {
      throw err && err.message;
    });
}

export const setInputField = (
  channel: ChatMutatedChannel,
  params: ChatInputField
) => async (dispatch: AppThunkDispatch): Promise<void> => {
  console.debug("[Action] setInputField");

  try {
    const { url } = channel;
    const { text, mentions, linksMentions } = params;

    dispatch({
      type: CHAT_INPUT_FIELD_SAVE,
      data: {
        id: url,
        text: text,
        mentions: mentions,
        linksMentions: linksMentions,
      },
    });
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Handle sending text/file message, only one message per time
 * @param channel
 * @param params
 * @param group
 */
export const sendMessage = (
  channel: ChatMutatedChannel,
  params: SendMessageParams,
  group: Group
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] sendMessage");
  try {
    const { chat, setting, user } = getState();
    const { userChatId, firstName, lastName } = user.data as UserDataState;
    const { url } = channel;
    const { data, hasMore } = chat.details[url] || {};

    const { text, mentions, linksMentions, files, fileText } = params;

    //To make app has feel a quick response so we need to display the unsent message on UI asap
    //Solution here is create a temp message then add to list
    const tempMessage = {
      messageId: "tempMessage",
      message: text,
      _sender: { userId: userChatId, nickname: `${firstName} ${lastName}` },
      createdAt: new Date().getTime(),
      mentions,
      linksMentions,
      isMine: true,
    };
    const tempData = {
      tempMessage,
      ...data,
    };

    dispatch({
      type: CHAT_DETAIL_SET,
      data: { id: url, data: tempData, hasMore },
    });

    //creating funtion to handle a success response
    const handleSuccess = (resp: UserMessage | FileMessage): void => {
      const newData = {
        [resp.messageId]: customUserMessage(resp, userChatId),
        ...data,
      } as ChatListDetailData;

      dispatch({
        type: CHAT_DETAIL_SET,
        data: { id: url, data: newData, hasMore },
      });

      //updating myLastRead for this channel to update the list channels
      //the expect result is this channel should be on the top list
      channel.myLastRead = new Date().getTime();
      dispatch(syncChatRoom(channel));
      dispatch(markAsRead(channel));
      dispatch(sendNotification(channel, params));
    };

    //Use sendUserMessage for a text message with mentions
    //Use sendFileMessage for file or image, we also add this file/image to group if this is a group chat
    if (!_.isEmpty(text)) {
      //Handling a sending text to SendBird
      const metadataJson = JSON.stringify({
        mentions,
        linksMentions,
      });
      channel.sendUserMessage(
        text,
        metadataJson,
        channel.customType,
        [],
        (resp: any, error) => {
          if (error) {
            throw error;
          } else {
            return handleSuccess(resp);
          }
        }
      );
    } else if (!_.isEmpty(files)) {
      const { file } = (files as CustomFileType[])[0];
      const { name, type, size } = file as {
        name: string;
        type: string;
        size: number;
      };

      //Updating uploading file status on UI
      const progressCallback = (
        name: string,
        fileProgress: Dict<NotificationFileProgress>,
        transferred: number,
        total: number,
        cancel: boolean
      ): void => {
        dispatch({
          type: NOTIFICATION_UPLOAD_SET,
          data: { name, transferred, total, fileProgress, cancel },
        });
      };

      //Uploading file to S3
      const { fileUrl, thumbUrl } = await uploadFile(
        files && files[0],
        `uploads/chat/`,
        setting.config,
        progressCallback
      );
      //Adding file to GroupFile on DB
      dispatch(addFilesToGroup([{ fileUrl, thumbUrl, file }], group));

      //Handling a sending file to SendBird
      const data = JSON.stringify({ thumbUrl: thumbUrl, text: fileText });

      if (fileUrl) {
        channel.sendFileMessage(
          fileUrl,
          name,
          type,
          size,
          data,
          channel.customType,
          [],
          (resp: any, error) => {
            if (error) {
              throw error;
            } else {
              return handleSuccess(resp);
            }
          }
        );
      }
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Update an exsisting text message with new text
 * No apply for sending file
 * @param channel
 * @param params
 */
export const updateMessage = (
  channel: ChatMutatedChannel,
  params: SendMessageParams & { id: number }
): AppThunkAction => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] updateMessage");
  try {
    const { chat, user } = getState();
    const { userChatId } = user.data as UserDataState;
    const { url } = channel;
    const { data, hasMore } = chat.details[url] || {};

    const { text, mentions, linksMentions, id } = params;

    //creating funtion to handle a success response
    const handleSuccess = (resp: UserMessage | FileMessage): void => {
      let newData = { [resp.messageId]: customUserMessage(resp, userChatId) };
      newData = Object.assign({}, data, newData);
      dispatch({
        type: CHAT_DETAIL_SET,
        data: { id: url, data: newData, hasMore },
      });

      dispatch(markAsRead(channel));
    };

    const metadataJson = JSON.stringify({
      mentions,
      linksMentions,
    });

    //Only update text and metadata for existing message
    channel.updateUserMessage(
      id,
      text,
      metadataJson,
      //@Phoung check if this is ok, null was not a correct type here
      "",
      (
        resp: AdminMessage | UserMessage | FileMessage,
        error: SendBirdError
      ) => {
        if (error) {
          throw error;
        } else {
          return handleSuccess(resp as UserMessage | FileMessage);
        }
      }
    );
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Handle deleting a message
 * @param channel
 * @param message
 */
export function deleteMessage(
  channel: ChatMutatedChannel,
  message: FileMessage | UserMessage
) {
  return (): Promise<boolean> =>
    new Promise<boolean>(async (resolve, reject) => {
      console.debug("[Action] deleteMessage");

      channel.deleteMessage(message, (response, error) => {
        if (error) {
          return reject(error);
        } else {
          return resolve(true);
        }
      });
    }).catch(err => {
      logException(err);
      throw err && err.message;
    });
}

/**
 * Getting a favorite contacts from DB to display on top on list channels
 * @param refresh
 */
export const getFavoriteContacts = (refresh: boolean) => async (
  dispatch: AppThunkDispatch,
  getState: GetState
): Promise<void> => {
  console.debug("[Action] getFavoriteContacts");
  try {
    const { chat } = getState();
    const { favorites } = chat;
    if (!refresh && !favorites) {
      return;
    } else {
      const data = await getFavoriteChatContacts();
      dispatch({
        type: CHAT_FAVORITE_ALL_SET,
        data,
      });
    }
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Save more a contact to favorite list on DB
 * @param members
 */
export const addToFavoriteContact = (members: UserDataState[]) => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] addToFavoriteContact");
  try {
    const userIds = _.map(members, ({ id }) => id);
    await addToFavoriteChatContact(userIds);
    setTimeout(() => {
      dispatch(getFavoriteContacts(true));
    }, 3000);
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

/**
 * Remove a contact from current list
 * @param members
 */
export const removeFromFavoriteContact = (members: UserDataState[]) => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] removeFromFavoriteContact");
  try {
    const userIds = _.map(members, ({ id }) => id);
    await removeFromFavoriteChatContact(userIds);
    setTimeout(() => {
      dispatch(getFavoriteContacts(true));
    }, 3000);
  } catch (err) {
    logException(err);
    throw err && err.message;
  }
};

export const clearNotificationAlert = () => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  console.debug("[Action] clearNotificationAlert");
  dispatch({
    type: NOTIFICATION_ALERT_SET,
    data: {
      id: "CHAT",
      data: {},
    },
  });
};
