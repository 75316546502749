import React from "react";

import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Media } from "reactstrap";

import { textThemingWorkaround } from "../../../constants";

import { useTheme } from "../../hooks";

import { Heading } from "../UI";

export type EventCardPlaceholderProps = {};

export const EventCardPlaceholder: React.FC<EventCardPlaceholderProps> = () => {
  const {
    card,
    text: { cardTitle, listText },
  } = useTheme();

  const eventCardStyles = {
    card: { ...card, padding: 0 },
    cardBody: { backgroundColor: "transparent" },
    titleHeading: { ...cardTitle, ...textThemingWorkaround },
    listText: {
      ...listText,
      ...textThemingWorkaround,
      paddingLeft: 0,
    },
  } as const;

  return (
    <Card className="my-2" style={eventCardStyles.card}>
      <CardBody style={eventCardStyles.cardBody}>
        <Media>
          <Media
            left
            className="d-flex align-items-center mr-3"
            style={{
              width: "135px",
              maxHeight: "135px",
            }}>
            <Skeleton width={135} height={135} />
          </Media>
          <Media body>
            <Heading style={eventCardStyles.titleHeading}>
              <h5>
                <Skeleton />
              </h5>
            </Heading>
            <Skeleton className="ml-2" style={eventCardStyles.listText} />
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};
