import { DictStrict } from "../../../types";

import { filterDefinedCallback } from ".";

export function getDictDefinedValues(dict: null): null;
export function getDictDefinedValues(dict: undefined): undefined;
export function getDictDefinedValues<T>(dict: DictStrict<T>): T[];
export function getDictDefinedValues<T>(dict: DictStrict<T> | null): T[] | null;
export function getDictDefinedValues<T>(
  dict: DictStrict<T> | null | undefined
): Exclude<T, undefined>[] | null | undefined;
export function getDictDefinedValues(dict: any): any {
  return dict && Object.values(dict).filter(filterDefinedCallback);
}
