import React from "react";

import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import { Callback, MediaImageBasic } from "../../../../types";

import { LoadingSpinner, SearchBox } from "../..";

import { MediaImageTiles } from "..";

export type MediaSearchProviderProps = {
  data: MediaImageBasic[] | null;
  error: string | null;
  loading?: boolean;
  // refreshing: boolean;

  onChangeText: (query: string) => void;
  onSearch?: (callback?: Callback) => void;
  onSelect: (item: { thumbUrl?: string; fileUrl: string }) => void;
  /**
   * @FIXME Is first argument related to refreshing? or next?
   * Should we implement refresh handling on web?
   */
  // reFetch: (refresh?: boolean) => void;
};

export const MediaSearchProvider: React.FC<MediaSearchProviderProps> = ({
  data,
  error,
  loading,

  onSelect,
  onSearch,
  onChangeText,
}) => {
  const { t } = useTranslation("Media");

  if (!data) {
    if (error) {
      return <Alert color="danger">{error}</Alert>;
    }

    return <LoadingSpinner />;
  }

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChangeText(e.target.value);
  };

  const searchSubmitButtonText = loading
    ? t("Provider.Button.Searching")
    : t("Provider.Button.Search");

  const searchBoxElement = onSearch && (
    <SearchBox
      className="my-2 border"
      loading={loading}
      placeholder={t("Provider.Placeholder.Search")}
      onValueChange={handleChangeText}
      onSubmit={onSearch}
      submitButtonText={searchSubmitButtonText}
    />
  );

  return (
    <>
      {searchBoxElement}
      <MediaImageTiles data={data} onImageClick={onSelect} />
    </>
  );
};
