import React, { useState } from "react";

import { Button, ButtonGroup } from "reactstrap";

import {
  EventFiltersDisplayed,
  EventViews,
  Group,
  SortTypes,
} from "../../../types";

import { useTheme } from "../../hooks";

import { IconFontAwesome, StickyNavbar } from "../UI";

import { OnEventFilterChange } from "./EventList";
import { EventListFilterCollapse } from "./EventListFilterCollapse";

export type EventListActionBarProps = {
  activeView: EventViews;
  filterBy: EventFiltersDisplayed;
  group?: Group;
  sortBy: SortTypes;

  onCalendarViewButtonClick: () => void;
  onFilterChange: OnEventFilterChange;
  onListViewButtonClick: () => void;
};

export const EventListActionBar: React.FC<EventListActionBarProps> = ({
  activeView,
  onCalendarViewButtonClick,
  onListViewButtonClick,
  ...props
}) => {
  const [isFilterCollapseOpen, setSortOptionsOpen] = useState(false);

  const { button, tabTop } = useTheme();

  const toggleFilterOptions = (): void => {
    setSortOptionsOpen(!isFilterCollapseOpen);
  };

  const handleCalendarViewButtonClick = (): void => {
    onCalendarViewButtonClick();
    setSortOptionsOpen(false);
  };

  const styles = {
    bar: {
      backgroundColor: tabTop.active.backgroundColor,
      padding: 10,
      marginBottom: 20,
    },
    inactiveButton: {
      border: 0,
      height: 30,
      width: 40,
      borderRadius: 0,
      marginRight: 5,
      backgroundColor: button.backgroundColor,
      color: button.text.color,
    },
    activeButton: {
      backgroundColor: button.text.color,
      color: button.backgroundColor,
    },
    filterButton: {
      bottom: 2,
      width: 80,
      lineHeight: 1,
    },
  };

  const filterButton = activeView === "List" && (
    <Button
      id="eventListFilterButton"
      className="float-right border"
      style={{
        ...styles.inactiveButton,
        ...styles.filterButton,
        position: "relative",
      }}
      onClick={toggleFilterOptions}>
      Filter
    </Button>
  );

  return (
    <StickyNavbar>
      <div className="flex-fill" style={styles.bar}>
        <ButtonGroup className="d-inline-flex">
          <Button
            id={"eventListViewButton"}
            className="d-flex align-self-center shadow-none"
            style={{
              ...styles.inactiveButton,
              ...(activeView === "List" && styles.activeButton),
            }}
            onClick={onListViewButtonClick}>
            <IconFontAwesome name={"list"} className="align-self-center" />
          </Button>
          <Button
            id={"eventCalendarViewButton"}
            className="d-flex align-self-center shadow-none"
            style={{
              ...styles.inactiveButton,
              ...(activeView === "Calendar" && styles.activeButton),
            }}
            onClick={handleCalendarViewButtonClick}>
            <IconFontAwesome
              name={"calendar-alt"}
              className="align-self-center"
            />
          </Button>
        </ButtonGroup>
        {filterButton}
      </div>
      <EventListFilterCollapse isOpen={isFilterCollapseOpen} {...props} />
    </StickyNavbar>
  );
};
