import { ChatState } from "./types";

export const chatStateInit: ChatState = {
  active: {},
  details: {},
  error: null,
  favorites: {},
  inputField: {},
  items: null,
  setting: {},
  type: {},
};

export default chatStateInit;
