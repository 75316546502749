import { MediaPhoto } from "../../../types";
import { logException } from "../../../lib/utils";

import { getFileName } from ".";

export const downloadImage = async (photo: MediaPhoto): Promise<File> => {
  try {
    console.debug("[Service] downloadImage");

    const { type, uri } = photo;

    const name = getFileName(uri) || photo.name;

    let httpResponse: Response;

    try {
      httpResponse = await fetch(uri);
    } catch {
      httpResponse = await fetch(
        `https://simple-proxy-for-cors.herokuapp.com/${uri}`
      );
    }

    const blob = await httpResponse.blob();

    return new File([blob], name, { type });
  } catch (e) {
    logException(e);

    throw new Error(`Couldn't download an image.`);
  }
};
