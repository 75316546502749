import React, { FormEvent } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Form,
  Alert,
  Input,
  Button,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
// import { isEmpty } from "underscore";

// import { imageUpload } from "../../../constants";
import { store } from "../../../store/configureStore";
import { textThemingWorkaround } from "../../../constants/Theme";

import {
  UserRegisterDataKey,
  UserRegisterDataState,
} from "../../../containers/User/Register";

// import { isPhoto } from "../../../lib/utils";

import { Override } from "../../../types";

import { useTheme } from "../../hooks";

// import { processInputFiles } from "../../services";

import { SupportButtonWModal } from "../Support";

// import DragAndDrop from "../UI/DragAndDrop";

import { UserLoginBlock } from ".";

export type UserRegisterProps = {
  data: UserRegisterDataState;
  dataError: {
    firstName?: boolean;
    lastName?: boolean;
    phoneNumber?: boolean;
    email?: boolean;
    password?: boolean;
    confirmPassword?: boolean;
  };
  disabled?: boolean;
  error: string | null;
  sending: boolean;
  setting: {
    backgroundImage: string;
    logo: string;
    organizationName: string;
    signInByEmail?: boolean;
    signUpEnabled?: boolean;
  };

  handleChange: <K extends UserRegisterDataKey>(
    name: K,
    val: UserRegisterDataState[K]
  ) => void;
  register: (
    callback: (text: string, fun: () => void) => void
  ) => Promise<void>;
};

export const UserRegister: React.FC<UserRegisterProps> = ({
  data,
  dataError,
  disabled,
  error,
  sending,
  setting,

  handleChange,
  register,
}) => {
  const userRegisterStyle = {
    text: {
      color: "#fff",
      textAlign: "center",
    },
    input: {
      paddingRight: 15,
      paddingLeft: 15,
      height: 45,
    },
    item: {
      borderBottomWidth: 0,
      marginLeft: 0,
      backgroundColor: "#fff",
      borderRadius: 10,
      marginBottom: 10,
    },
    /**
     * @TODO
     * Is this red background necessary? I think it doesn't
     * look nice. Bootstrap default is red borders.
     *
     * Temporarily I omitted its implementation
     */
    // error: {
    //   backgroundColor: "red",
    // },
    mainButton: {
      height: 50,
    },
  } as const;

  const {
    button: buttonStyle,
    login: { button: loginButtonStyle },
    text: { listTitle: listTitleStyle },
  } = useTheme();

  const { t } = useTranslation("User");

  const organizationName = store.getState().setting.config?.organizationName;

  const statusModal = (
    text: string,
    closeToast?: () => void,
    callbackFn?: () => void
  ): JSX.Element => (
    <Modal isOpen={true}>
      <ModalHeader className="align-self-center">
        {organizationName}
      </ModalHeader>
      <ModalBody style={{ textAlign: "center" }}>
        <p>{text}</p>
        <p>{t("Container.UpdateProfile.State.Info")}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          block
          style={{
            ...buttonStyle,
            ...loginButtonStyle,
            border: 0,
            ...{
              ...buttonStyle.text,
              ...textThemingWorkaround,
              textAlign: undefined,
            },
          }}
          onClick={(): void => {
            callbackFn && callbackFn();
            closeToast && closeToast();
          }}>
          {t("Update.Profile.Button.Ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    register((text: string, callbackFn: () => void) => {
      toast(({ closeToast }) => statusModal(text, closeToast, callbackFn), {
        autoClose: false,
        className: "toast-hide",
      });
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      Override<HTMLInputElement, { name: UserRegisterDataKey }>
    >
  ): void => {
    handleChange(e.target.name, e.target.value);
  };

  // This only handles single file drop, since we want to upload an avatar
  // is user drop new file here it will overwrite the previous one
  // also this function allows only photo drop
  // const handleDrop = (files: FileList): void => {
  //   for (let i = 0; i < files.length; i++) {
  //     if (!files[i] || !isPhoto(files[i].type)) return;
  //     const file = files[i];
  //     processInputFiles(
  //       [file],
  //       response => {
  //         handleChange("avatar", response[0] as any);
  //       },
  //       msg => {
  //         console.debug("Error", msg);
  //       }
  //     );
  //   }
  // };

  // const removeAvatar = (): void => {
  //   // @FIXME there is some mistake in container typing, avatar is definitely not a string
  //   // also this is not required field so there should be a way to remove it after uploading
  //   handleChange("avatar", undefined as any);
  // };

  const {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    phoneNumber,
    // avatar,
  } = data;

  const { signInByEmail } = setting;

  const errorElement = error && <Alert color="danger">{error}</Alert>;

  const combinedInputStyle = {
    ...userRegisterStyle.item,
    ...userRegisterStyle.input,
    borderRadius: buttonStyle.borderRadius,
  };

  return (
    <Form onSubmit={handleSubmit}>
      <p
        className="mb-4"
        style={{
          ...listTitleStyle,
          ...textThemingWorkaround,
          ...userRegisterStyle.text,
        }}>
        {t("Register.Heading.Text")}
      </p>
      {errorElement}
      {/* <DragAndDrop handleDrop={handleDrop}>
          <div
            className="border border-light mb-2"
            style={{ height: "25%", width: "100%" }}>
            {!avatar ? (
              <>
                <img src={imageUpload} className="w-100" alt="avatar" />
                <p className="text-small text-black-50 text-center">
                  Upload your profile picture image
                </p>
              </>
            ) : (
              <>
                <Button
                  className="float-right"
                  onClick={removeAvatar}
                  color="primary"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}>
                  <i className="icon icon-close " />
                </Button>
                {/* <MediaImage
                        src={

                            // typeof avatar === "string" ? avatar : avatar.fileUrl

                        }
                        height={300}
                        resizeMode="cover"
                      />
              </>
            )}
          </div>
        </DragAndDrop> */}
      <FormGroup>
        <Input
          disabled={sending}
          id="firstName"
          invalid={dataError["firstName"]}
          name="firstName"
          placeholder={t("Register.Placeholder.First.Name")}
          style={combinedInputStyle}
          type="text"
          valid={"firstName" in dataError && !dataError["firstName"]}
          value={firstName}
          onChange={handleInputChange}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          name="lastName"
          id="lastName"
          placeholder={t("Register.Placeholder.Last.Name")}
          disabled={sending}
          value={lastName}
          onChange={handleInputChange}
          invalid={dataError["lastName"]}
          valid={"lastName" in dataError && !dataError["lastName"]}
          style={combinedInputStyle}
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="email"
          name="email"
          id="email"
          /**
           * @FIXME
           * Email is not always required. Fix in native too.
           */
          placeholder={`${t("Register.Placeholder.Enter.Email")} ${t(
            "Register.Placeholder.Required"
          )}`}
          disabled={sending}
          value={email}
          onChange={handleInputChange}
          invalid={dataError["email"]}
          valid={"email" in dataError && !dataError["email"]}
          style={combinedInputStyle}
        />
      </FormGroup>

      {!signInByEmail && (
        <FormGroup>
          {/**
           * @TODO
           * Implement country picker with focus on phone input after change
           */}
          <Input
            type="tel"
            name="phoneNumber"
            id="PhoneNumber"
            placeholder={`${t("Register.Placeholder.Enter.Phone")} ${
              /**
               * @FIXME
               * signInByEmail will be always false here. Copied from native.
               */
              signInByEmail ? "" : t("Register.Placeholder.Required")
            }`}
            disabled={sending}
            value={phoneNumber}
            onChange={handleInputChange}
            invalid={dataError["phoneNumber"]}
            valid={"phoneNumber" in dataError && !dataError["phoneNumber"]}
            style={userRegisterStyle.item}
          />
        </FormGroup>
      )}

      {signInByEmail && (
        <FormGroup>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder={t("Register.Placeholder.Password")}
            disabled={sending}
            value={password}
            onChange={handleInputChange}
            invalid={dataError["password"]}
            valid={"password" in dataError && !dataError["password"]}
            style={combinedInputStyle}
          />
        </FormGroup>
      )}
      {signInByEmail && (
        <FormGroup>
          <Input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder={t("Register.Placeholder.Password.Reenter")}
            disabled={sending}
            value={confirmPassword}
            onChange={handleInputChange}
            invalid={dataError["confirmPassword"]}
            valid={
              "confirmPassword" in dataError && !dataError["confirmPassword"]
            }
            style={combinedInputStyle}
          />
        </FormGroup>
      )}
      <Button
        block
        type="submit"
        color="primary"
        disabled={sending || disabled}
        style={{
          ...buttonStyle,
          ...loginButtonStyle,
          border: 0,
          ...{
            ...buttonStyle.text,
            ...textThemingWorkaround,
            textAlign: undefined,
          },
        }}>
        {sending ? t("Register.Button.Loading") : t("Register.Button.Signup")}
      </Button>
      <UserLoginBlock />
      <SupportButtonWModal block />
    </Form>
  );
};
