import { Member } from "../../../types";

import { getCurrentUser } from ".";
import { convertObject } from "../../../lib/utils";

export const logInAsGuest = async (
  sessionToken: string
): Promise<Member | void> => {
  await Parse.User.become(sessionToken);

  const parseRespose = await getCurrentUser();
  return convertObject(parseRespose);
};
