import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { NotificationLandingMessageWithAuthor } from "../../../types/Notification";
import { displayDate } from "../../../lib/utils";

export type NotificationLandingCardProps = {
  item: NotificationLandingMessageWithAuthor;
};

export const NotificationLandingCard: React.FC<NotificationLandingCardProps> = ({
  item,
}) => {
  const { t } = useTranslation("Notification");

  const history = useHistory();

  const openNotification = (): void => {
    const { screen, id } = item.target;
    history.push(`${screen}/${id}`);
  };

  const dates = {
    time: displayDate(item.date, "h:mm A"),
    day: displayDate(item.date, "DD"),
    month: displayDate(item.date, "MMM"),
    year: displayDate(item.date, "YYYY"),
  };

  const name = item.author && item.author.name;

  return (
    <Card id={`notificationLandingCard${item.target.id}`} className="mb-3">
      <CardBody className={"p-0"}>
        <Row className={"mx-auto"}>
          <Col
            className={"text-center p-0"}
            style={{
              maxWidth: "150px",
              borderRadius: "0.25rem 0 0 0.25rem",
              backgroundColor: "#E6EAF3",
              color: "#2954a3",
            }}>
            <p className={"m-0 pt-1"}>{dates.day}</p>
            <p className={"m-0"}>{dates.month}</p>
            <p className={"m-0"}>{dates.year}</p>
          </Col>
          <Col className={"text-truncate"}>
            <p className={"mt-2"}>{`By ${name} - ${dates.time}`}</p>
            <p className={"mb-2 text-truncate"}>{item.title}</p>
          </Col>
          <Col style={{ maxWidth: "100px" }}>
            <span
              className={"anchor-color m-2"}
              style={{
                cursor: "pointer",
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
              onClick={openNotification}>
              {t("NotificationLanding.Button.Read.More")}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
