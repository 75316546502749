import React from "react";

import { useTranslation } from "react-i18next";
import { CardBody, Row, Col, Button, Input, CustomInput } from "reactstrap";

import { RSVPData } from "../../../../containers/Event/containerTypes";

import {
  EventSingle,
  EventAttendee as EventAttendeeType,
  EventAttendeeAdditionalData,
  UserResponse,
  EventAttendeeGuest,
} from "../../../../types";
import { textThemingWorkaround } from "../../../../constants/Theme";

import { useTheme } from "../../../hooks";

import { Heading } from "../..";

import { EventInfoHeader } from "..";

import { EventAttendeeGuests } from ".";

export type EventAttendeeEventInfoProps = {
  attendee: EventAttendeeAdditionalData;
  currentPartner: { firstName?: string; lastName: string };
  event: EventSingle;
  guest: {
    guestMembers: {
      id: string;
      firstName?: string;
      objectId?: string;
    }[];
    guests: string[];
  };
  myAttendee: EventAttendeeType | undefined;
  partner: unknown;
  passAttendeeDeadline: boolean;
  rsvpData: RSVPData;
  sending: boolean | undefined;
  willBeInWaitingList: unknown;

  addMemberGuest: (members: { id: string }[]) => void;
  addNonMemberGuest: (name: string) => void;
  changeAttendee: <T extends keyof EventAttendeeAdditionalData>(
    name: T,
    value: EventAttendeeAdditionalData[T]
  ) => void;
  changePartner: (partnerResponse: UserResponse) => void;
  onSubmit: () => void;
  removeGuest: (guest: EventAttendeeGuest) => void;
};

export const EventAttendeeEventInfo: React.FC<EventAttendeeEventInfoProps> = ({
  attendee,
  currentPartner,
  event,
  guest,
  myAttendee,
  rsvpData,
  sending,
  willBeInWaitingList,

  addMemberGuest,
  addNonMemberGuest,
  changeAttendee,
  changePartner,
  onSubmit,
  removeGuest,
}) => {
  const { t } = useTranslation("Event");

  const {
    card,
    button,
    event: eventStyle,
    text: { cardText, header, listTitle },
  } = useTheme();

  const { title, disableAttendees, attendeeDeadline, partnersAllowed } = event;

  const { note, invoice } = attendee;

  const {
    isPast,
    isAttended,
    rsvpEnabled,
    passAttendeeDeadline,
    isPartnerAttended,
    hasPayment,
  } = rsvpData;

  const eventAttendeeEventInfoStyles = {
    buttonText: {
      ...button.text,
      ...textThemingWorkaround,
      fontSize: 16,
      textAlign: undefined,
    },
    saveButton: {
      ...button,
      borderColor: button.backgroundColor,
      boxShadow: `${button.shadowOffset.width}px ${
        button.shadowOffset.height
      }px ${button.shadowRadius * 3}px   rgb(0, 0, 0, 0.3)`,
    },
    header: {
      ...header,
      ...textThemingWorkaround,
    },
    listTitle: {
      ...listTitle,
      ...textThemingWorkaround,
    },
    attendingButton: {
      ...button.roundLeft,
      backgroundColor: isAttended ? button.backgroundColor : "#fff",
    },
    attendingText: {
      ...eventStyle.rsvp.text,
      ...textThemingWorkaround,
      fontSize: 16,
      color: isAttended ? "#fff" : button.backgroundColor,
    },
    willNotButton: {
      ...button.roundRight,
      backgroundColor: !isAttended ? button.backgroundColor : "#fff",
    },
    willNotText: {
      ...eventStyle.rsvp.text,
      ...textThemingWorkaround,
      fontSize: 16,
      color: !isAttended ? "#fff" : button.backgroundColor,
    },
    partnerAttendingButton: {
      ...button.roundLeft,
      backgroundColor: isPartnerAttended ? button.backgroundColor : "#fff",
    },
    partnerAttendingText: {
      ...eventStyle.rsvp.text,
      ...textThemingWorkaround,
      fontSize: 16,
      color: isPartnerAttended ? "#fff" : button.backgroundColor,
    },
    partnerWillNotButton: {
      ...button.roundRight,
      backgroundColor: !isPartnerAttended ? button.backgroundColor : "#fff",
    },
    partnerWillNotText: {
      ...eventStyle.rsvp.text,
      ...textThemingWorkaround,
      fontSize: 16,
      color: !isPartnerAttended ? "#fff" : button.backgroundColor,
    },
    rsvpNoteInput: {
      ...cardText,
      ...textThemingWorkaround,
      width: "100%",
      backgroundColor: "#F5F7FA",
      borderRadius: button.borderRadius,
      marginVertical: 10,
      paddingLeft: 15,
      border: "none",
    },
  } as const;

  let rsvpText = disableAttendees
    ? t("Attendee.Button.Reply.By", { attendeeDeadline })
    : t("Attendee.Button.Will.Attend");

  const rsvpPartnerButton = t("Attendee.Button.Will.Attend");

  if (isAttended && myAttendee && myAttendee.response === "yes") {
    rsvpText = myAttendee.isWaiting
      ? t("Attendee.Button.Joining.WaitList")
      : t("Attendee.Button.You.Are.Attending");
  } else if (willBeInWaitingList) {
    rsvpText = t("Attendee.Button.Join.WaitList");
  }

  const noAttendeeButton = t("Attendee.Button.Will.Not.Attend");

  const disabled =
    !rsvpEnabled ||
    disableAttendees ||
    (isAttended && passAttendeeDeadline) ||
    sending;

  const titleElement = title && (
    <h5 style={eventAttendeeEventInfoStyles.header} id="eventAttendeeTitle">
      {title}
    </h5>
  );

  const rsvpButtons = !isPast && (
    <Row noGutters={true} className="mt-4">
      <Col>
        <Button
          id="eventRsvpAttendingButton"
          block={true}
          style={eventAttendeeEventInfoStyles.attendingButton}
          color="primary"
          disabled={!rsvpEnabled || disableAttendees}
          onClick={(): void => changeAttendee("response", "yes")}>
          <span style={eventAttendeeEventInfoStyles.attendingText}>
            {rsvpText}
          </span>
        </Button>
      </Col>
      <Col>
        <Button
          id="eventRsvpNotAttendingButton"
          block={true}
          color="primary"
          style={eventAttendeeEventInfoStyles.willNotButton}
          onClick={(): void => changeAttendee("response", "no")}
          disabled={!rsvpEnabled || disableAttendees}>
          <span style={eventAttendeeEventInfoStyles.willNotText}>
            {t("Attendee.Button.Will.Not.Attend")}
          </span>
        </Button>
      </Col>
    </Row>
  );

  const partnerRSVPButtons = !isPast &&
    !!isAttended &&
    partnersAllowed &&
    currentPartner && (
      <div className="mt-4">
        <Heading
          className="mb-3"
          style={eventAttendeeEventInfoStyles.listTitle}
          text={`${t(
            "Attendee.Text.Spouse.Partner.Rsvp"
          )} ( ${currentPartner.firstName || ""} ${currentPartner.lastName ||
            ""} )`}
        />

        <Row noGutters={true}>
          <Col>
            <Button
              id="eventPartnerAttendingButton"
              block={true}
              style={eventAttendeeEventInfoStyles.partnerAttendingButton}
              disabled={!rsvpEnabled || disableAttendees}
              onClick={(): void => changePartner("yes")}>
              <span style={eventAttendeeEventInfoStyles.partnerAttendingText}>
                {rsvpPartnerButton}
              </span>
            </Button>
          </Col>
          <Col>
            <Button
              block={true}
              onClick={(): void => changePartner("no")}
              style={eventAttendeeEventInfoStyles.partnerWillNotButton}
              disabled={!rsvpEnabled || disableAttendees}>
              <span style={eventAttendeeEventInfoStyles.partnerWillNotText}>
                {noAttendeeButton}
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );

  const rsvpInputs = !!isAttended && (
    <div className="mt-4">
      <Heading
        className="mb-3"
        id="eventAttendeeNoteText"
        style={eventAttendeeEventInfoStyles.listTitle}
        text={t("Attendee.Text.Rsvp.Note.Special.Requests")}
      />
      <Input
        id="eventAttendeeNoteInput"
        type="textarea"
        style={eventAttendeeEventInfoStyles.rsvpNoteInput}
        disabled={!isAttended}
        value={note}
        placeholder={t("Attendee.Placeholder.Note")}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          changeAttendee("note", e.target.value);
        }}
      />
    </div>
  );

  const rsvpToogle = !!isAttended && (
    <CustomInput
      type="switch"
      className="mt-4"
      id="eventAttendeeInfoNotifySwitch"
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
        changeAttendee("notifyMe", e.target.checked)
      }>
      <span style={eventAttendeeEventInfoStyles.listTitle}>
        {t("Attendee.Text.Notify.Me.When.RSVP")}
      </span>
    </CustomInput>
  );

  const guestsElement = !!isAttended && (
    <EventAttendeeGuests
      guests={guest.guests}
      guestMembers={guest.guestMembers}
      maxGuestCount={event.maxGuestCount}
      addMemberGuest={addMemberGuest}
      addNonMemberGuest={addNonMemberGuest}
      removeGuest={removeGuest}
    />
  );

  const saveButton = (
    <span style={eventAttendeeEventInfoStyles.buttonText}>
      {hasPayment && isAttended && !willBeInWaitingList && !invoice
        ? t("Attendee.Button.Continue")
        : sending
        ? t("Attendee.Button.Saving")
        : t("Attendee.Button.Save")}
    </span>
  );

  return (
    <CardBody style={card} className="px-3">
      {titleElement}

      <EventInfoHeader className="mt-4 mb-3 pl-2" event={event} />

      {rsvpButtons}

      {partnerRSVPButtons}

      {rsvpInputs}

      {rsvpToogle}

      {guestsElement}

      <Button
        id="eventAttendeeSaveButton"
        color="primary"
        block={true}
        style={eventAttendeeEventInfoStyles.saveButton}
        disabled={disabled}
        onClick={onSubmit}
        className="mt-4">
        {saveButton}
      </Button>
    </CardBody>
  );
};
