export const uploadWithProgress = (
  url: string,
  formData: FormData,
  headers: { [key: string]: string },
  onProgress?: (progress: { total: number; loaded: number }) => any,
  onAbort?: () => any,
  uploadCancelled?: () => boolean
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("POST", url);

    for (const header in headers)
      request.setRequestHeader(header, headers[header]);

    request.upload.onprogress = (event): void => {
      if (uploadCancelled?.()) {
        request.abort();
      } else {
        onProgress?.({ total: event.total, loaded: event.loaded });
      }
    };

    request.onabort = (event): void => {
      onAbort?.();
      onProgress?.({ total: 0, loaded: 0 });
      reject({ message: "Upload cancelled" });
    };

    request.onerror = (error): void => {
      reject({
        message: "Error uploading " + error,
      });
    };

    request.onload = (e): void => {
      resolve(request.response);
    };
    request.send(formData);
  });
};
