import { logException } from "../../../lib/utils";

export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file); // converts the blob to base64 and calls onload

      reader.onload = (): void => {
        const uri = reader.result;
        if (typeof uri === "string") {
          resolve(uri);
        }
        reject(new Error("URI is not a string"));
      };
    } catch (e) {
      logException(e);
      reject(e);
    }
  });
