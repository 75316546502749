import React from "react";

import { RouteComponentProps } from "react-router-dom";

import PostDetailContainer from "../../../containers/Post/Detail";

import { PostDetail } from "../../components/Post";

export type RoutePostDetailProps = {} & RouteComponentProps;

export const RoutePostDetail: React.FC<RoutePostDetailProps> = props => (
  <PostDetailContainer {...props} Layout={PostDetail} />
);
