import React, { Component, ComponentType } from "react";

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";

import { track } from "../../lib/track";

import {
  CommentType,
  EmptyObject,
  MatchProps,
  PostGroup,
  PostItem,
  ReactionType,
  RootState,
  UserDataState,
} from "../../types";

import { doReaction } from "../Comment/action";

import { getPost, removePost, GetPostActionReturn } from "./action";

export type PostDetailStateProps = {
  item: PostItem | undefined;
  setting: {
    ClientHostName?: string;
    confettiEffectActions: [];
  };
  user: UserDataState | EmptyObject;
};

export type PostDetailDispatchProps = {
  getPost: (
    id: string,
    options: { uid?: string; showComments?: boolean },
    refresh: boolean
  ) => Promise<GetPostActionReturn>;
  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>; //@TODO this is assumption, compare with comment container typing
  removePost: (post: {
    id: string;
    group: PostGroup & { id?: string };
  }) => Promise<{ data: boolean }>;
};

export type PostDetailLayoutProps = {
  error: string | null;
  item: PostItem | undefined;
  loading: boolean;
  refreshing: boolean;
  setting: {
    ClientHostName?: string;
    confettiEffectActions: [];
  };
  user: UserDataState | EmptyObject;

  doReaction: (
    id: string,
    type: CommentType,
    reaction: ReactionType
  ) => Promise<void>;
  reFetch: (refresh: boolean, callback?: () => void) => Promise<void>;
  removePost: (
    post: { id: string; group: PostGroup & { id?: string } },
    callback?: (text: string) => void
  ) => Promise<void>;
};

export type PostDetailOwnProps = {
  Layout: ComponentType<PostDetailLayoutProps>;
} & MatchProps;

export type PostDetailProps = PostDetailStateProps &
  PostDetailDispatchProps &
  PostDetailOwnProps &
  WithTranslation;

export type PostDetailState = {
  error: string | null;
  loading: boolean;
  refreshing: boolean;
};

class PostDetail extends Component<PostDetailProps, PostDetailState> {
  constructor(props: PostDetailProps) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      refreshing: false,
    };
  }

  componentDidMount(): void {
    this.fetchData(false);
    track("View Screen", {
      Screen: "post-detail",
      Params: this.props.match && this.props.match.params,
    });
  }

  fetchData = async (
    refresh: boolean,
    callback?: () => void
  ): Promise<void> => {
    const { getPost, match } = this.props;
    const { refreshing, loading } = this.state;
    const id = match.params?.postId || match.params?.id; //We'll remove it once ionic web was removed

    if (refreshing || loading || !id) {
      return;
    }

    this.setState({ refreshing: refresh, loading: !refresh }, async () => {
      try {
        const userId = match.params?.userId;
        const options = {
          uid: userId, //@FIXME in action there is uid expected, this is probably mistake to send it as userId instead
          userId,
          showComments: true,
        };
        await getPost(id, options, refresh);

        this.setState({ loading: false, refreshing: false, error: null });

        callback?.();

        track("View Post", {
          "Post ID": id,
        });
      } catch (error) {
        this.setState({ loading: false, error: error });
      }
    });
  };

  onRemovePost = async (
    post: {
      id: string;
      group: PostGroup & { id?: string };
    },
    callback?: (text: string) => void
  ): Promise<void> => {
    const { refreshing, loading } = this.state;
    if (refreshing || loading) {
      return;
    }

    try {
      await this.props.removePost(post);

      this.setState({ error: null });
      callback?.(this.props.t("Container.Index.Delete"));
      track("Delete Post", {
        "Post ID": post.id,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };

  render = (): JSX.Element => {
    const { Layout, user, item, setting, doReaction } = this.props;
    const { loading, error, refreshing } = this.state;

    return (
      <Layout
        error={error}
        item={item}
        loading={loading}
        refreshing={refreshing}
        setting={setting}
        user={user}
        doReaction={doReaction}
        reFetch={this.fetchData}
        removePost={this.onRemovePost}
      />
    );
  };
}

const mapStateToProps = (
  state: RootState,
  ownProps: PostDetailOwnProps
): PostDetailStateProps => {
  const id = (ownProps.match.params?.postId ||
    ownProps.match.params?.id) as string;
  const { localConfig, setting } = state.setting;
  const { mixedList } = state.post;
  return {
    setting: {
      ClientHostName: localConfig?.client_url,
      confettiEffectActions: setting?.confetti_effect_actions || [],
    },
    user: state.user.data || {},
    item:
      (mixedList.items && mixedList.items[id]) ||
      (mixedList.cached && mixedList.cached[id]),
  };
};

const mapDispatchToProps = {
  getPost: getPost,
  removePost: removePost,
  doReaction: doReaction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("Post")(PostDetail));
