import React from "react";

import { RouteComponentProps } from "react-router-dom";

import PaymentDetailContainer from "../../../containers/Payment/Detail";

import { PaymentMixed } from "../../components/Payment";

export type RoutePaymentMixedProps = {} & RouteComponentProps;

export const RoutePaymentMixed: React.FC<RoutePaymentMixedProps> = props => (
  <PaymentDetailContainer {...props} Layout={PaymentMixed} />
);
