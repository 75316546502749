import React from "react";

import { RouteComponentProps } from "react-router-dom";

import EventMapContainer from "../../containers/Event/EventMapContainer";

export type RouteEventMapProps = {} & RouteComponentProps<{
  eventId: string;
  location: string;
}>;

export const RouteEventMap: React.FC<RouteEventMapProps> = props => (
  <EventMapContainer {...props} />
);
