import { isWebPlatform } from "../../web/services/isWebPlatform";

let path = "";
export function getStateWhenGoback(state?: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!isWebPlatform()) {
      resolve("");
    } else {
      window.onpopstate = (): void => {
        const paths = window.location.pathname
          .split("/")
          .filter(state => state.length > 0);
        path = paths.length > 0 ? paths[0] : "";
      };

      setTimeout(() => {
        resolve(path);
      }, 100);
    }
  });
}

export function listenGoBackEvent(): void {
  getStateWhenGoback();
}
