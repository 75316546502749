import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button, Alert } from "reactstrap";

import { CommentCard, CommentInput, LoadingSpinner } from "..";
// import { CommentListContainerLayoutProps } from "../../containers";
import { CommentProps } from "../../../containers/Comment/propsTypes";

/**
 * @TODO
 * A transition should be added during showing/hiding
 * comments. Now CommentList's height changes immediately
 * and it looks very heavy, non-user-friendly and "vintage" ;)
 */
export const CommentList: FC<CommentProps> = ({
  commentsAmountOnOnit = 5,
  commentsAmountOnShowMore = 5,
  data,
  disabled,
  error,
  hasMore,
  loading: isLoading,
  newCommentInputRef,
  refreshing: isRefreshing,
  sending,
  setting,
  user,

  addComment,
  deleteComment,
  reFetch,
  updateComment,
}) => {
  const { t } = useTranslation("Comment");

  const [page, setPage] = useState(0);

  const maxDisplayedCommentsAmount =
    commentsAmountOnOnit + page * commentsAmountOnShowMore;

  const filteredData = data.filter(comment => comment.author !== undefined);

  const displayedData =
    filteredData.length > maxDisplayedCommentsAmount
      ? filteredData.slice(-maxDisplayedCommentsAmount)
      : filteredData;

  const showMoreComments =
    (hasMore || filteredData.length > maxDisplayedCommentsAmount) &&
    ((): void => {
      const newPage = page + 1;
      const newCommentsToDisplayAmount =
        commentsAmountOnOnit + newPage * commentsAmountOnShowMore;

      if (filteredData.length < newCommentsToDisplayAmount && hasMore) {
        if (newCommentsToDisplayAmount > 10) {
          reFetch(true, false);
        } else {
          reFetch(false, true);
        }
      }

      setPage(newPage);
    });

  const hideComments =
    page > 0 &&
    ((): void => {
      setPage(0);
    });

  const errorAlertElement = error && <Alert color="warning">{error}</Alert>;

  const loadMoreActionElement =
    isRefreshing || isLoading ? (
      <LoadingSpinner size="sm" />
    ) : (
      showMoreComments && (
        <div className="text-center">
          <Button
            color="link"
            className="text-decoration-none"
            disabled={disabled}
            onClick={showMoreComments}>
            <span style={{ fontSize: "13px" }}>
              {t("List.Button.Load.More")}
            </span>
            <i className="icon icon-arrow-up ml-2" />
          </Button>
        </div>
      )
    );

  const hideCommentsButtonElement = hideComments && (
    <div className="text-center pb-2">
      <Button
        color="link"
        className="text-decoration-none"
        disabled={disabled}
        onClick={hideComments}>
        <span style={{ fontSize: "13px" }}>
          {t("List.Button.Hide.Comments")}
        </span>
        <i className="icon icon-arrow-up pl-3" />
      </Button>
    </div>
  );

  const commentCardElements = displayedData.map(
    comment =>
      "id" in user && (
        <CommentCard
          key={comment.id}
          comment={comment}
          disabled={disabled}
          setting={setting}
          style={{
            paddingRight: 10,
            paddingLeft: 10,
            boxShadow: "none",
          }}
          deleteComment={
            user.id === comment.author.profile?.user?.objectId
              ? deleteComment
              : undefined
          }
          updateComment={
            user.id === comment.author.profile?.user?.objectId
              ? updateComment
              : undefined
          }
        />
      )
  );

  return (
    <div>
      {errorAlertElement}

      {loadMoreActionElement}

      {commentCardElements}

      {hideCommentsButtonElement}

      <CommentInput
        addComment={addComment}
        disabled={disabled}
        newCommentInputRef={newCommentInputRef}
        user={"avatar" in user ? user : undefined}
        sending={sending}
      />
    </div>
  );
};
