import _ from "underscore";
import _moment from "moment";
import { extendMoment } from "moment-range";

import { tzMap, DefaultDatetimeFormat } from "../../constants/timezone";
import { allDialCodeUSCountries } from "../../constants";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { FileTypeIcons } from "../../constants";
import { DEV } from "../../constants/config";
import { getFileType } from "./getFileType";
// eslint-disable-next-line no-unused-vars
const momentTimeZone = require("moment-timezone");
const Moment = extendMoment(_moment);
const _pluralize = require("pluralize");

export * from "./getFileType";

export function log(data) {
  console.debug(data);
}

export function logException(ex) {
  if (!DEV && ex) {
    if (window.Sentry && ex) {
      window.Sentry.captureException(ex);
    }
  } else {
    _.isString(ex) ? console.debug(ex) : console.debug(ex.message);
  }
}

export function printExceptionMessage(ex) {
  console.debug(ex);
  return _.isString(ex) ? ex : ex.message || "Unknown";
}

export const pattern = {
  file: `(?:${Object.keys(FileTypeIcons).join("|")})$`,
  mention: /([@][a-zA-Z0-9 ]+)/g,
  linkInApp: /([\^][a-zA-Z0-9 ]+)/g,
  url: /[-a-zA-Z0-9@:%_+~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi,
  urlWeb: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
  hashTag: /#(\w+)/,
  phone: /([+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,10})/im,
  phoneForRegister: /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,10}$/im,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
  emailWeb: /([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?))/i,
  emoji: /(:[^\s:]+(?:::skin-tone-[2-6])?:)/,
  nativeEmoji: /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
};

export function pluralize(text, number) {
  return _pluralize(text, number);
}

export function isValidPhone(
  string = "",
  canEmpty = false,
  forRegistration = false
) {
  string = string.split(" ").join("");
  if (canEmpty && (_.isEmpty(string) || string === "+1")) {
    return true;
  }
  if (forRegistration) {
    return new RegExp(pattern.phoneForRegister).test(string);
  }
  return new RegExp(pattern.phone).test(string);
}

export function isValidEmail(string = "", canEmpty = false) {
  string = string.split(" ").join("");
  if (canEmpty && _.isEmpty(string)) {
    return true;
  }
  return new RegExp(pattern.email).test(string);
}

export function parsePhoneNumber(string) {
  if (_.isEmpty(string)) {
    return {};
  }
  return parsePhoneNumberFromString(string);
}

export function delayPromise(delay) {
  //return a function that accepts a single variable
  return data => {
    //this function returns a promise.
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(data), delay);
    });
  };
}

export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export function convertObject(object) {
  if (_.isEmpty(object)) {
    return object;
  }
  if (_.has(object, "className")) {
    try {
      return _.extendOwn(
        { id: object.id, objectId: object.id },
        JSON.parse(JSON.stringify(object))
      );
    } catch (error) {
      console.debug(error);
      return {};
    }
  } else {
    try {
      if (_.isArray(object)) {
        return _.map(object, function(obj) {
          return convertObject(obj);
        });
      } else if (_.isObject(object)) {
        return _.mapObject(object, function(obj, key) {
          return convertObject(obj);
        });
      } else {
        return JSON.parse(JSON.stringify(object));
      }
    } catch (error) {
      console.debug(error);
      return {};
    }
  }
}

export function parseString(object) {
  if (object && object.__type === "Date") {
    return new Date(object.iso);
  } else {
    return object || "";
  }
}

export function parseToDate(object, format = "MM/DD/YYYY") {
  if (_.isEmpty(object)) {
    return null;
  } else if (object && object.__type === "Date") {
    return new Date(object.iso);
  } else if (_.isString(object)) {
    //Temporary fix for bad data.
    if ("Invalid date" === object) {
      return null;
    }
    return Moment(object, [
      format,
      "YYYY-MM-DD",
      "MM/DD/YYYY",
      "MMMM DD",
    ]).toDate();
  }
  return object;
}

export function formatDate(dt, format, timezone, utcTime) {
  if (!format) {
    format = DefaultDatetimeFormat;
  }
  dt = parseString(dt);
  if (!utcTime) {
    timezone = Moment.tz.guess();
    timezone = tzMap[timezone] || timezone;
    return Moment(new Date(dt))
      .tz(timezone)
      .format(format);
  } else {
    return Moment.utc(new Date(dt), "MM-DD-YYYY")
      .local()
      .format(format);
  }
}

export function displayDate(dt, format, timezone, utcTime) {
  if (!dt) {
    return "";
  }
  return formatDate(dt, format, timezone, utcTime);
}

export function isBeforeNow(date1) {
  return Moment(date1).isBefore(Moment.utc());
}

export function isToday(data) {
  const today = new Date();
  const otherDay = new Date(data);
  return (
    otherDay.getDate() === today.getDate() &&
    otherDay.getMonth() === today.getMonth() &&
    otherDay.getFullYear() === today.getFullYear()
  );
}

export function fromNow(date) {
  const parsedDate = parseString(date);
  return Moment(new Date(parsedDate)).fromNow();
}

export function isPastEvent(date) {
  return Moment().isAfter(new Date(displayDate(date)));
}

export function getEventDate(
  startAt,
  endAt,
  allDayEvent,
  timezone,
  hideYear,
  hideTimezone,
  longFormat
) {
  // we always override the event timezone with the user's local timezone,
  // but this is easy to change in the future if we want to (by deleting
  // the line below)
  timezone = Moment.tz.guess();
  timezone = tzMap[timezone] || timezone;
  // determine the number of days and number of years between the dates
  var startDate = Moment(parseString(startAt))
    .tz(timezone)
    .startOf("day");
  var endDate = Moment(parseString(endAt))
    .tz(timezone)
    .endOf("day");
  var days = endDate.diff(startDate, "days");
  var years = endDate.diff(startDate, "years");
  // create the date formats
  var startFormat = [];
  var endFormat = [];
  if (longFormat) {
    startFormat.push("MMM DD");
  } else {
    startFormat.push("MM/DD");
  }
  if (days === 0 || years !== 0) {
    if (!hideYear) {
      startFormat.push("YYYY");
    }
  }
  if (days !== 0) {
    if (longFormat) {
      endFormat.push("MMM DD");
    } else {
      endFormat.push("MM/DD");
    }
    if (!hideYear) {
      endFormat.push("YYYY");
    }
  }
  if (!allDayEvent) {
    startFormat.push("h:mmA");
    endFormat.push("h:mmA");
  }
  // build the date string
  var eventDate = displayDate(startAt, startFormat.join(", "), timezone);
  if (days !== 0 || !allDayEvent) {
    eventDate += displayDate(endAt, " - " + endFormat.join(", "), timezone);
  }
  if (!allDayEvent && !hideTimezone) {
    eventDate += Moment()
      .tz(timezone)
      .format(" z");
  }
  return eventDate;
}

export function enumerateDaysBetweenDates(
  startDate,
  endDate,
  format,
  timezone
) {
  var dates = [],
    start = displayDate(startDate, format, timezone),
    end = displayDate(endDate, format, timezone);
  var range = Moment().range(start, end);
  for (let day of range.by("day")) {
    dates.push(day.format(format));
  }
  return dates;
}

export function convertDate(string, defaultDate, datetime) {
  if (_.isString(string)) {
    if (datetime) {
      return Moment(string, [
        "MM/DD/YYYY h:mm a",
        "YYYY-MM-DD h:mm a",
      ]).toDate();
    }
    return Moment(string, ["MM/DD/YYYY", "YYYY-MM-DD"]).toDate();
  }
  if (_.isDate(string)) {
    return string;
  }
  return defaultDate;
}

export function currencyFormat(number) {
  return (number / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function convertDateToTimeZone(date, format, timezone) {
  if (_.isUndefined(date) || _.isNull(date)) {
    return null;
  }
  return Moment(parseString(date))
    .tz(tzMap[timezone] || Moment.tz.guess())
    .format(format);
}

export function convertDateByTimeZone(date, timezone) {
  if (_.isUndefined(date) || _.isNull(date)) {
    return null;
  }
  var d;
  if (_.isString(date)) {
    d = Moment(date, ["YYYY-MM-DD HH:mm:ss A"]).format("YYYY-MM-DD HH:mm:ss A");
  } else {
    d = Moment(parseString(date)).format("YYYY-MM-DD HH:mm:ss A");
  }
  var z = Moment()
    .tz(tzMap[timezone] || Moment.tz.guess())
    .format("Z");
  return d + z;
}

export function moment(date, format) {
  return Moment(date, format);
}

export function getCalendarDate(date) {
  return Moment.utc(parseToDate(date, "LLLL")).format(
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
  );
}

export function cleanHTMLTags(string = "") {
  return string.replace(/<\/?[^>]+(>|$)/g, "");
}

export * from "./getEventLocation";
export * from "./getEventLocationType";

export function convertProfileObject(object, section) {
  var fields = {};
  _.each(object, (value, key) => {
    fields[key] = { key: key, value };
  });
  if (section === "Personal Info") {
    if (!fields.Spouse) {
      fields.Spouse = { key: "Spouse", value: {} };
    }
    if (!fields.Children) {
      fields.Children = { key: "Children", value: [] };
    }
  }
  if (section === "Professional Info") {
    if (!fields["Company History"]) {
      fields["Company History"] = { key: "Company History", value: [] };
    }
  }
  return fields;
}

export * from "./isPhoto";

export function getHostName(url) {
  if (_.isEmpty(url)) {
    return null;
  }
  var match = url.match(new RegExp("://(www[0-9]?\\.)?(.[^/:]+)", "i"));
  if (
    match &&
    match.length > 2 &&
    typeof match[2] === "string" &&
    match[2].length > 0
  ) {
    return match[2];
  } else {
    return null;
  }
}

export function linkBelongToThisApp(link = "", appLink = "") {
  //Check url like: https://robots.groupfire.com/#/announcement/4HBgnIIVZW
  const validkUniversalLink = getHostName(link) === getHostName(appLink);
  if (validkUniversalLink) {
    return true;
  } else {
    //Check url like: robots://#/announcement/4HBgnIIVZW
    const validkDeepLink =
      getHostName(appLink)?.indexOf(link.split("://")[0]) > -1;
    return validkDeepLink;
  }
}

export function getLink($string = "") {
  var result = "";
  //we should use this regex (contains . char) for detecting correct link instead of r.__urlRegex (is used for displaying link, view more here https://robots-and-rockets.atlassian.net/browse/GFC-2280)
  var urlRegex = new RegExp(
    "[-a-zA-Z0-9@:%_\\+.~#?&//=]{2,256}\\.[a-z]{2,4}\\b(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?",
    "gi"
  );
  $string = $string.replace(/\n\r?/g, " <br/> ");
  var $strings = $string.split(" ");
  _.forEach($strings, function(text) {
    var link = text.match(urlRegex);
    if (!pattern.email.test(link)) {
      if (!_.isEmpty(link)) {
        result = link;
      }
    }
  });
  return result;
}

export function getLinkInApp(link, appLink) {
  if (linkBelongToThisApp(link, appLink)) {
    return link?.replace(appLink, "")?.replace("#", "");
  }
  return null;
}

export function extractRouterParams(linkInApp) {
  if (linkInApp) {
    let string = linkInApp.split("/").filter(item => item.length > 1);
    let param = "id";
    if (string.length === 1) {
      string = string.join("").split("?id=");
    }
    if (string.length === 1) {
      string = string.join("").split("?channel=");
      param = "channel";
    }
    if (string[0] === "profile") {
      param = "profileId";
    }
    if (string.length === 1) {
      return {
        router: string[0],
        id: null,
        param: null,
      };
    } else if (string.length > 1) {
      return {
        router: string[0],
        //remove params "?uid", "#topComment" from ionic
        id: string[1]
          ?.split("?")[0]
          .split("#")[0]
          .split("%23")[0],
        param,
      };
    }
  }
  return null;
}

export function extractRouterParamsFromID(options, appLink) {
  var links = [];
  if (options) {
    if (_.has(options, "event_id")) {
      links.push({
        router: "event-detail",
        param: "id",
        id: options.event_id,
        name: "Event",
      });
    }
    if (_.has(options, "event")) {
      links.push({
        router: "event-detail",
        param: "id",
        id: options.event,
        name: "Event",
      });
    }
    if (_.has(options, "announcement_id")) {
      links.push({
        router: "announcement",
        param: "id",
        id: options.announcement_id,
        name: "Announcement",
      });
    }
    if (_.has(options, "announcement")) {
      links.push({
        router: "announcement",
        param: "id",
        id: options.announcement,
        name: "Announcement",
      });
    }
    if (_.has(options, "post_id")) {
      links.push({
        router: "group-feed-detail",
        param: "postId",
        id: options.post_id,
        name: "Post",
      });
    }
    if (_.has(options, "post")) {
      links.push({
        router: "group-feed-detail",
        param: "postId",
        id: options.post,
        name: "Post",
      });
    }
    if (_.has(options, "profile_id")) {
      links.push({
        router: "profile",
        param: "profileId",
        id: options.profile_id,
        name: "Profile",
      });
    }
    if (_.has(options, "invoice_id")) {
      links.push({
        router: "payment-receipt-detail",
        param: "id",
        id: options.invoice_id,
        name: "Invoice",
      });
    }
    if (_.has(options, "url") && _.isString(options.url)) {
      const linkInApp = extractRouterParams(getLinkInApp(options.url, appLink));
      if (linkInApp) {
        links.push(Object.assign({}, linkInApp, { name: "Link" }));
      } else {
        links.push({ url: options.url, name: "Link" });
      }
    }
    if (_.has(options, "link") && !_.isEmpty(options.link)) {
      const linkInApp = extractRouterParams(
        getLinkInApp(options.link.url, appLink)
      );
      if (linkInApp) {
        links.push(
          Object.assign({}, linkInApp, { name: options.link.title || "Link" })
        );
      } else {
        links.push({
          url: options.link.url,
          name: options.link.title || "Link",
        });
      }
    }
  }
  return links;
}

export function removeWhiteSpaces(text = "") {
  return text
    .split(" ")
    .join("")
    .split("-")
    .join("");
}

export function correctPhoneNumberBeforeSaving(phoneNumber) {
  const prefixUSPhone = phoneNumber.substring(0, 5);
  if (_.contains(allDialCodeUSCountries, prefixUSPhone)) {
    return phoneNumber.substring(2);
  }
  return phoneNumber;
}

export function getSocialMediaSchema(name) {
  switch (name) {
    case "Facebook":
      return {
        ios: "fbapi://",
        android: "com.facebook.katana",
        url: "http://mobile.facebook.com/",
        appURL: "fb://profile/",
      };
    case "Instagram":
      return {
        ios: "instagram://",
        android: "com.instagram.android",
        url: "http://instagram.com/",
        appURL: "instagram://user?username=",
      };
    case "Twitter":
      return {
        ios: "twitter://",
        android: "com.twitter.android",
        url: "https://twitter.com/",
        appURL: "twitter:///user?screen_name=",
      };
    case "Linkedin":
      return {
        ios: "linkedin://",
        android: "com.linkedin.android",
        url: "https://www.linkedin.com/in/",
        appURL: "linkedin://#profile/",
      };
    default:
      return null;
  }
}

export * from "./parseJSONToObject";

export function parseToArray(object) {
  try {
    if (_.isString(object) && !_.isEmpty(object)) {
      var newObject = JSON.parse(object);
      return parseToArray(newObject);
    }
    return object;
  } catch (error) {
    return null;
  }
}

export function toCapitalizeCase(string) {
  if (_.isEmpty(string)) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toTitleCase(str) {
  if (str) {
    return str.replace(/\w\S*!/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return str || "";
}

export function toInitialName(str = "") {
  let initialName = "";
  const array = str
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "")
    .split(" ");
  if (array.length === 1) {
    initialName = array[0].substr(0, 3).toUpperCase();
  } else {
    initialName = _.take(array, 3)
      .join(" ")
      .toUpperCase();
  }
  return _.isEmpty(initialName) ? "Avatar" : initialName;
}

export function getExtensionFromFileName(fileName) {
  var parts = fileName?.split(".") || [];
  if (parts.length > 0) {
    return parts[parts.length - 1].toLowerCase();
  }
  return "";
}

export function getNameOfFileType(fileName, type) {
  var fileType = type || getExtensionFromFileName(fileName);
  if (
    _.contains(["jpg", "jpeg", "png", "bmp"], fileType?.replace("image/", ""))
  ) {
    return "a photo";
  } else if (_.contains(["gif"], fileType)) {
    return "a GIF";
  } else if (
    _.contains(["3gp", "asf", "avi", "flv", "mov", "mp4", "wmv"], fileType)
  ) {
    return "a video";
  }
  return "a file";
}

export function getExtensionOfFile(fileName) {
  if (!fileName) {
    return "";
  }
  var fileExtension = null;
  if (fileName.indexOf(".") === -1) {
    fileExtension = getFileType(fileName).split("/")[1];
  } else {
    var parts = fileName.split(".");
    fileExtension = parts[parts.length - 1];
  }
  return fileExtension;
}

export * from "./shouldResizeFile";

export function isGif(contentType, url) {
  if (contentType && contentType.indexOf("gif") > -1) {
    return true;
  }
  var fileExtension = getExtensionOfFile(url);
  return fileExtension.indexOf("gif") > -1;
}

export * from "./getFileName";

export async function asyncForEach(data, callback) {
  for (let index = 0; index < data.length; index++) {
    await callback(data[index], index);
  }
}

export * from "./getRandomInt";

export function shadeColor(color, percent) {
  if (!color) return null;
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export function convertHex(hex, opacity) {
  if (typeof hex === "string" && hex.indexOf("rgb") === -1) {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
  }
  return hex;
}

export function extractYouTubeId(url) {
  var regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  var match = url.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }
}

export function getFileInfoFromPath(url) {
  if (_.isEmpty(url)) {
    return {};
  }
  if (url.indexOf("http") === -1 && url.indexOf("file://") === -1) {
    url = `http://${url}`;
  }
  const name = url.replace(/^.*(\\|\/|:)/, "");
  const parts = name.split(".");
  if (parts.length > 0) {
    const extension = parts[parts.length - 1].toLowerCase();
    let type = "file";
    if (_.contains(["jpeg", "jpg", "gif", "png"], extension)) {
      type = "photo";
    } else if (
      _.contains(["avi", "mp4", "flv", "mov", "mpg", "wmv"], extension)
    ) {
      type = "film";
    } else if (_.contains(["wma", "wav", "mp3"], extension)) {
      type = "music";
    } else if (_.contains(["doc", "docx", "ppt", "pptx", "pdf"], extension)) {
      type = "file-text";
    }
    return {
      name,
      extension,
      type,
      url,
    };
  }
  return {
    url,
  };
}

export function startAnimation(callback) {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      callback();
    });
  });
}

function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export function shallowCompare(instance, nextProps, nextState) {
  return (
    !shallowEqual(instance.props, nextProps) ||
    !shallowEqual(instance.state, nextState)
  );
}

export function getObjectByLevel(p, o) {
  return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
}

export function reverseString(str) {
  return str
    .split("")
    .reverse()
    .join("");
}
