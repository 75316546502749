import _ from "underscore";

import { convertObject } from "../../../lib/utils";
import { DictStrict, PostItem } from "../../../types";

import { runFunction } from "../../http";

import { parseMentionsInPostItem } from "../ParseMentionsHelper";

type Options = {
  skipComments: boolean;
  per: number;
  page: number;
  author: string;
  followingEnabled?: boolean;
};

export const getUserPosts = async (
  options: Options
): Promise<DictStrict<PostItem>> => {
  console.debug("[Service] getUserPosts", options);
  const type = options.followingEnabled ? "mixedFeedV2" : "mixedFeed";
  const parseResponse = await runFunction(type, options, { requireUser: true });

  return _.object(
    _.map(parseResponse, (value: PostItem) => {
      return [value.post.id, parseMentionsInPostItem(convertObject(value))];
    })
  );
};
