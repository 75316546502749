import { AppItem, CommentResponse } from "../../../types";

type GetItemWithCommentsSet = {
  <
    DataKey extends string,
    ItemType extends AppItem<DataKey> | undefined
  >(options: {
    comments: CommentResponse[];
    /**
     * Name of property storing nested entity data (and optional
     * reactionCount property)
     *
     * @FIXME
     * We have to specify this key name because of inconsistency in
     * all available Items shapes (nested data is stored under entity
     * name property). Unifing that property name and setting it
     * for example to "data" would simplify app data structures.
     */
    dataKeyName: DataKey;
    /** Item to update */
    item: ItemType;
  }): ItemType;
  <DataKey extends string, ItemType extends AppItem<DataKey>>(options: {
    comments: CommentResponse[];
    /**
     * Name of property storing nested entity data (and optional
     * reactionCount property)
     *
     * @FIXME
     * We have to specify this key name because of inconsistency in
     * all available Items shapes (nested data is stored under entity
     * name property). Unifing that property name and setting it
     * for example to "data" would simplify app data structures.
     */
    dataKeyName: DataKey;
    /** Item to update */
    item: ItemType;
  }): ItemType;
  <DataKey extends string>(options: {
    comments: CommentResponse[];
    /**
     * Name of property storing nested entity data (and optional
     * reactionCount property)
     *
     * @FIXME
     * We have to specify this key name because of inconsistency in
     * all available Items shapes (nested data is stored under entity
     * name property). Unifing that property name and setting it
     * for example to "data" would simplify app data structures.
     */
    dataKeyName: DataKey;
    /** Item to update */
    item: undefined;
  }): undefined;
};

export const getItemWithCommentsSet: GetItemWithCommentsSet = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  item,
  comments,
  dataKeyName,
}: {
  /** Item to update */
  item: any;
  comments: CommentResponse[];
  /**
   * Name of property storing nested entity data (and optional
   * reactionCount property)
   *
   * @FIXME
   * We have to specify this key name because of inconsistency in
   * all available Items shapes (nested data is stored under entity
   * name property). Unifing that property name and setting it
   * for example to "data" would simplify app data structures.
   */
  dataKeyName: any;
}): ItemType | undefined =>
  item
    ? {
        ...item,
        comments: [...item.comments, ...comments],
        [dataKeyName]: {
          ...item[dataKeyName],
          commentCount: item[dataKeyName].commentCount + 1,
        },
      }
    : undefined;
