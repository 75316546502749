import { AppItem, AppItemList, DictStrict } from "../../../types";
import { getFilteredDict } from "./getFilteredDict";

export const getFilteredNestedItemList = <
  DataKey extends string,
  ItemType extends AppItem<DataKey>
>({
  dataKeyName,
  removeId,
  groupedByValue,
  nestedItemList,
}: {
  removeId: string;
  groupedByValue?: string;
  /**
   * Name of property storing nested entity data (and optional
   * reactionCount property)
   *
   * @FIXME
   * We have to specify this key name because of inconsistency in
   * all available Items shapes (nested data is stored under entity
   * name property). Unifing that property name and setting it
   * for example to "data" would simplify app data structures.
   */
  dataKeyName: DataKey;
  nestedItemList: DictStrict<AppItemList<DataKey, ItemType>>;
}): DictStrict<AppItemList<DataKey, ItemType>> => {
  if (!groupedByValue) return nestedItemList;

  const listToFilter = nestedItemList[groupedByValue];

  if (!listToFilter?.items?.[removeId]) return nestedItemList;

  return {
    ...nestedItemList,
    [groupedByValue]: {
      ...listToFilter,
      items: getFilteredDict({
        itemsDict: listToFilter?.items,
        dataKeyName: dataKeyName,
        removeId: removeId,
      }),
    },
  };
};
