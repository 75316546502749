import React from "react";

import classnames from "classnames";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";

export type TagListProps = {
  id?: string;
  className?: string;
  data: string[] | undefined;
  type?: string;
} & (
  | {
      onRemove?: undefined;
      onClick?: undefined;
    }
  | {
      onRemove: (index: number) => void;
      onClick?: (index: number) => void;
    }
);

export const TagList: React.FC<TagListProps> = ({
  id,
  className,
  type = "",
  data = [],

  onClick,
  onRemove,
}) => {
  if (data.length === 0) {
    return null;
  }

  return (
    <div id={`${type}TagList${id}`} className={classnames("mb-1", className)}>
      {data.map((tag, index) => {
        const handleBadgeClick = (e: React.SyntheticEvent): void => {
          e.preventDefault();

          if (onClick) {
            onClick(index);
          }
        };

        const icon = onRemove ? (
          <i
            className={"icon-minus ml-1 cursor-pointer"}
            onClick={(e): void => {
              e.stopPropagation();

              onRemove(index);
            }}
          />
        ) : null;

        let tagElement;
        if (onRemove) {
          tagElement = (
            <Badge
              pill
              className={"mr-2 text-body font-weight-light"}
              color="light"
              key={index}
              onClick={handleBadgeClick}>
              {tag}
              {icon}
            </Badge>
          );
        } else {
          tagElement = (
            <Link
              id={`tagLink${tag.replace(" ", "")}`}
              className={"mr-2"}
              key={index}
              to={{
                pathname: `/search`,
                state: {
                  searchType: type,
                  query: `#${tag}`,
                  triggerSearch: true,
                },
              }}>
              <Badge
                id={`tagLinkText${tag.replace(" ", "")}`}
                pill
                className={"text-body font-weight-light"}
                color="light"
                key={index}>
                {tag}
              </Badge>
            </Link>
          );
        }

        return tagElement;
      })}
    </div>
  );
};

export default TagList;
